const InfoCircleSmall = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5C15.9 5 19 8.1 19 12C19 15.9 15.9 19 12 19C8.1 19 5 15.9 5 12C5 8.1 8.1 5 12 5ZM12 4C7.6 4 4 7.6 4 12C4 16.4 7.6 20 12 20C16.4 20 20 16.4 20 12C20 7.6 16.4 4 12 4Z"
        fill="#1C2E45"
        fillOpacity="0.6"
      />
      <path d="M11 10H13V17H11V10Z" fill="#1C2E45" fillOpacity="0.6" />
      <path d="M11 7H13V9H11V7Z" fill="#1C2E45" fillOpacity="0.6" />
    </svg>
  );
};

export default InfoCircleSmall;
