/* eslint-disable no-unused-vars */
import Button from "components/ui/Button";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";
// import { SelectField } from "components/ui/Inputs";
import { Input } from "@ui";
import PropTypes from "prop-types";
import { useState } from "react";
import { RoleSuccessModal } from "./RoleConfirmModal";
import {
  updatePermission,
  fetchResources,
} from "@slices/roles-permissions-slice";
import { SuccessResponse } from "config/status-code";
import { addRole } from "@slices/roles-permissions-slice";

const Role = ({ updated_permissions }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [final_permissions, setPermission] = useState([]);
  const [open, setOpen] = useState(false);
  const permissions = useSelector((state) => state.roles_permissions);
  const [showSuccess, setShowSuccess] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchResources());
  }, [dispatch]);

  let [data, setData] = useState({
    name: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  useEffect(() => {
    if (permissions?.resources) {
      setPermission(permissions?.resources);
    }
  }, [permissions?.resources]);

  useEffect(() => {
    if (updated_permissions) {
      setPermission(updated_permissions);
    }
  }, [updated_permissions]);

  function createRole() {
    let formData = {
      name: data.name,
      resource: final_permissions,
    };
    setIsLoading(true);
    dispatch(addRole(formData)).then((response) => {
      if (response.payload.status === 200 || response.payload.status === 201) {
        setIsLoading(false);
        setOpen(true);
        return;
      }else {
        setIsLoading(false);
      }
    });
  }

  return (
    <>
      <RoleSuccessModal open={open} setOpen={setOpen} name={data?.name} />
      <div className="bg-white pt-5 rounded-md mb-5">
        <div className="flex justify-between px-5 mb-4">
          <h1>Role</h1>
          <Button
            onClick={() => {
              createRole();
              //   setOpen(!open);
            }}
          >
            {isLoading ? "Processing..." : "Save"}
          </Button>
        </div>
        <Divider />
        <div className="flex justify-center py-8">
          <div className=" w-10/12">
            {/* <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
      Role Title
    </p> */}
            <Input
              type="text"
              id="role_title"
              name="name"
              label="Role Title"
              onChange={handleInputChange}
            />
            {/* <SelectField
      items={["Intern", "Accountant", "MineOfficer", "Technical Officer"]}
      name="role"
      value={values.role}
      onChange={handleChange}
    /> */}
          </div>
        </div>
      </div>
    </>
  );
};

Role.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Role;
