/* eslint-disable react/prop-types */
const Close = ({ close }) => {
  return (
    <svg
      onClick={close}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.92 8.42L15.5 7L11.96 10.55L8.42 7L7 8.42L10.55 11.96L7 15.5L8.42 16.92L11.96 13.37L15.5 16.92L16.92 15.5L13.37 11.96L16.92 8.42Z"
        fill="#1C2E45"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default Close;
