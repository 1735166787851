import { Fragment, useRef} from 'react'; 
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { get_user_messages } from 'store/slices/messages.slice';
// import { useDispatch } from "react-redux";

const MessageList=({items, type, onSelected})=> {
  MessageList.propTypes = {
    items: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired
}

const selected = useRef(-1)
const getUserMessages=( userid, row_index)=>{
 selected.current = row_index
  console.log(selected.current)
  if(type==='all')
     onSelected(get_user_messages({userid:userid,type:'all'}))
  else if(type==="unread")
     onSelected(get_user_messages({userid:userid,type:'unread'}))
  else if(type==="archived")
      onSelected(get_user_messages({userid:userid,type:'archived'}))
}

const siriusblack = createTheme({
  typography: {
    nameTitle:{
      fontFamily: 'Associate Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 18,
      color: '#192434'
    },
    timer:{
      fontFamily: 'Associate Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 13,
      color: 'rgba(28, 48, 74, 0.5)'
    }
   
  },
});

let unread = 0;
let date_list = [];
let updated_time ="";
let number_badge = null;

const content_list = items.map((item, indx)=>{
date_list = item.last_update_date.split(" ");
updated_time = date_list[1] + " " + date_list[2];
unread = 0;
item.chats.map((chat)=>{
    if(chat.status==='UNREAD' && item.userid===chat.userid)
        unread+=1;
    })
number_badge = (unread > 0)?  ( <div className="circle">
  <span className="circle__content">{unread}</span>
</div>):(<div></div>);
    return (
      // <div key={indx} onClick={()=>console.log("I just got clicked")}>
      <ListItem key={indx} alignItems="flex-start" className={(selected.current===indx)?'message-list-item-selected':'message-list-item'} onClick={()=>getUserMessages(item.userid, indx)}>
      <ListItemAvatar>
        <Avatar alt={item.name} src={item.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={ <Fragment>
        <ThemeProvider theme={siriusblack}>
          <Typography
            sx={{ display: 'inline' }}
            component="span"
            variant="nameTitle"
          >
            {item.name}
          </Typography>
          <Typography
            sx={{ display: 'inline', float: 'right' }}
            component="span"
            variant="timer"
          >
            {updated_time}
          </Typography>
        </ThemeProvider>
        </Fragment>}
        secondary={
          <Fragment>
            <Typography
              sx={{ display: 'inline' }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {item.chats[0].message.substring(0,21)} ...
            </Typography>
            {number_badge}
          </Fragment>
        }
      />
    </ListItem>
    // </div>
    )
  })
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {content_list}
    </List>
  );
}
export default MessageList;
