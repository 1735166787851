/* eslint-disable no-unused-vars */
import { Button, Search, TableSkeleton, TableEmptyState } from "@ui";
import { AddIcon, SearchIcon, DropDownIcon } from "@ui/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { directoryTableHeaders, statusColorScheme } from "constants/index";
import { fetchDirectory, searchAllDirectory } from "@slices/admin-slice";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Map from "./map";

const Directory = () => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const [directory_details, setDirectoryDetails] = useState({});
  const directory = useSelector((state) => state.admin);
  const [searchParams, setParams] = useState();
  const { loading } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  const handleClose = () => setOpen(false);
  const handleViewDetails = (record) => {
    setDirectoryDetails(directory?.directory.find((m) => m._id === record._id));
    setOpen(true);
  };

  useEffect(() => {
    dispatch(
      fetchDirectory({
        page: 1,
        limit: limit,
      })
    );
  }, [dispatch]);

  const handleSetPerPage = (e) => {
    setLimit(e.target.value);
    dispatch(
      fetchDirectory({
        page: 1,
        limit: e.target.value,
      })
    );
  };

  function handleChangePage(page) {
    dispatch(
      fetchDirectory({
        page: page,
        limit: limit,
      })
    );
  }

  const handleSearch = (e) => {
    if (e.target.value == "") {
      dispatch(
        fetchDirectory({
          page: 1,
          limit: 10,
        })
      );
    }

    setParams(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    dispatch(
      searchAllDirectory({
        search: searchParams,
      })
    );
  };

  return (
    <div className="mt-2">
      <div className="w-40">
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/admin/directory/add")}
          startIcon={<AddIcon />}
        >
          Add new directory
        </Button>
      </div>
      {directory && directory?.directory.length > 0 && (
        <div className="w-full mt-3">
          <div className="h-96 my-4 ">
            <Map
              location={{
                address: directory?.directory[0]?.address,
                lat: directory?.directory[0]?.location.latitude,
                lng: directory?.directory[0]?.location.longitude,
              }}
              zoomLevel={10}
              directory={directory?.directory}
            />
          </div>
        </div>
      )}

      <div className="mt-4 flex overflow-x-auto scrollbar-hide gap-3">
        <div className="min-w-[1024px] lg:w-full overflow-x-hidden ">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-hidden sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full max-w-[1020.270px] sm:px-6 lg:px-8">
                <div className="shadow overflow-auto  border-gray-200 sm:rounded-lg bg-white">
                  <div className="flex justify-between p-4">
                    <form
                      onSubmit={(e) => handleSearchSubmit(e)}
                      className="flex gap-2"
                    >
                      <div className="w-[250px] lg:w-[538px]">
                        <Search
                          placeholder="Search directory"
                          onChange={(e) => handleSearch(e)}
                        />
                      </div>
                      <Button type="submit" color="primary">
                        <SearchIcon color="#ffff" opacity="1" />
                      </Button>
                    </form>
                  </div>
                  {loading && <TableSkeleton />}
                  {!loading && directory.directory.length > 0 && (
                    <>
                      <table className="min-w-full divide-y divide-[#1A38601A] border-b border-[#1A38601A]">
                        <thead className="bg-[#EFF0FE] text-center">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                            >
                              s/n
                            </th>
                            {directoryTableHeaders.tableHeaders.map(
                              (header, index) => (
                                <th
                                  key={index + 1}
                                  scope="col"
                                  className="px-6 py-3 text-left text-sm font-normal text-primary uppercase tracking-wider"
                                >
                                  {!header.hasIcon && header.title}
                                  {header.hasIcon && (
                                    <div className="flex  items-center">
                                      <span>{header.title}</span>
                                    </div>
                                  )}
                                </th>
                              )
                            )}
                            {/* <th scope="col" className="relative px-6 py-3">
                              <span className="sr-only"></span>
                            </th> */}
                            <th scope="col" className="px-3 py-3"></th>
                          </tr>
                        </thead>
                        <tbody className="bg-white font-light divide-y divide-[#1A38601A] capitalize">
                          {directory?.directory?.length > 0 &&
                            directory.directory.map((record, index) => {
                              const statusObject =
                                directoryTableHeaders.fields.find(
                                  (field) => field.name === "status"
                                );
                              const statusValue = statusObject?.isFunction
                                ? statusObject.func(record)
                                : record.status;
                              return (
                                <tr key={index} className="text-[#18273AF0]">
                                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                                    {index + 1}
                                  </td>
                                  {directoryTableHeaders.fields.map(
                                    (field, index) =>
                                      field.name === "status" ? (
                                        <td
                                          key={index}
                                          className="px-6 py-4 whitespace-nowrap"
                                        >
                                          <span
                                            className={`py-1 px-5 inline-flex capitalize text-sm leading-5 font-light rounded bg-[${statusColorScheme[statusValue].bg}] text-[${statusColorScheme[statusValue].text}] `}
                                          >
                                            {statusValue}
                                          </span>
                                        </td>
                                      ) : (
                                        <td
                                          key={index}
                                          className={`px-6 py-4 whitespace-nowrap text-sm ${
                                            field.name === "location" &&
                                            "text-center"
                                          }`}
                                        >
                                          {field.isFunction
                                            ? field.func(record)
                                            : record[field.name]}
                                        </td>
                                      )
                                  )}
                                  <td
                                    className="px-6 py-4 whitespace-nowrap text-sm text-[#6166B6] underline cursor-pointer"
                                    onClick={() => {
                                      handleViewDetails(record);
                                    }}
                                  >
                                    View details
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      <div className="flex justify-start lg:justify-end items-center w-full py-4 px-8 text-[13px] uppercase text-[#18273AF0]">
                        <div className="flex justify-between gap-2 items-center">
                          <p>Rows per page: {directory?.pagination?.limit}</p>
                          <FormControl sx={{ height: "30px", border: 0 }}>
                            <Select
                              sx={{ height: "30px", border: 0 }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              onChange={(e) => handleSetPerPage(e)}
                            >
                              <MenuItem value={10}>10</MenuItem>
                              <MenuItem value={20}>20</MenuItem>
                              <MenuItem value={30}>30</MenuItem>
                              <MenuItem value={50}>50</MenuItem>
                              <MenuItem value={100}>100</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="mx-6">
                          1 -{" "}
                          {Math.ceil(
                            directory?.pagination?.totalDocs /
                              directory?.pagination?.limit
                          )}{" "}
                          of {directory?.pagination?.page}
                        </div>
                        <div className="flex justify-between gap-10">
                          {directory?.pagination?.prevPage && (
                            <div
                              className="rotate-90 cursor-pointer"
                              onClick={() =>
                                handleChangePage(
                                  directory?.pagination?.prevPage
                                )
                              }
                            >
                              <DropDownIcon />
                            </div>
                          )}
                          {directory?.pagination?.page >=
                          Math.ceil(
                            directory?.pagination?.totalDocs /
                              directory?.pagination?.limit
                          )
                            ? null
                            : directory?.pagination?.page + 1 && (
                                <div
                                  className="-rotate-90 cursor-pointer"
                                  onClick={() =>
                                    handleChangePage(
                                      directory?.pagination?.nextPage
                                    )
                                  }
                                >
                                  <DropDownIcon />
                                </div>
                              )}
                        </div>
                      </div>
                    </>
                  )}
                  {directory?.directory !== null &&
                    !directory?.directory?.length > 0 &&
                    !loading && <TableEmptyState text="directory" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute top-2/4 left-2/4 -translate-y-1/2 -translate-x-1/2 w-auto outline-0 bg-white shadow-lg px-8 py-10 capitalize">
          <span
            className="icon-close absolute right-5 top-4"
            onClick={handleClose}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.92 8.42L15.5 7L11.96 10.55L8.42 7L7 8.42L10.55 11.96L7 15.5L8.42 16.92L11.96 13.37L15.5 16.92L16.92 15.5L13.37 11.96L16.92 8.42Z"
                fill="#1C2E45"
                fillOpacity="0.6"
              />
            </svg>
          </span>
          <h2 className="flex lg:flex flex-rols-1 gap-3">Directory details</h2>
          <div id="modal-modal-description" className="mt-2">
            <div className="grid grid-cols-6 gap-12 mb-6">
              <div className="col-span-3 flex flex-col">
                <span className="font-light text-ts text-sm">Name</span>
                <span className="text-light">{directory_details?.name}</span>
              </div>
              <div className="col-span-2 flex flex-col">
                <span className="font-light text-ts text-sm">Type</span>
                <span className="text-light">{directory_details?.type}</span>
              </div>
            </div>

            <div className="grid grid-cols-6 gap-10 mb-6">
              <div className="col-span-2 flex flex-col">
                <span className="font-light text-ts text-sm">Address</span>
                <span className="text-light">{directory_details?.address}</span>
              </div>
            </div>
            <div className="grid grid-cols-6 gap-12 mb-6">
              <div className="col-span-3 flex flex-col ">
                <span className="font-light text-ts text-sm">Location</span>
                <span className="text-light">
                  {directory_details?.location?.latitude.toFixed(2)}&deg;N
                  {directory_details?.location?.longitude.toFixed(2)}&deg;W
                </span>
              </div>
            </div>

            <div className="mb-4">
              <span className="font-light text-ts text-sm">Product type</span>
              <span className="flex gap-2">
                {directory_details?.product_type?.map((mineral, index) => (
                  <span
                    key={index}
                    className="px-2 py-1 rounded whitespace-nowrap text-[#6166B6] bg-primary-light font-extralight text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease"
                  >
                    {mineral}
                  </span>
                ))}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Directory;
