/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { EyeIcon } from "@heroicons/react/outline";
import { EyeOffIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Btn as Button } from "@atoms/button/button";
import { Input } from "@atoms/input";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePassword, deleteAccount } from "@slices/settings-slice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "@config/app-url";
import { SignOut } from "store/slices/user-slice";

import * as yup from "yup";

const schema = yup.object().shape({
  old_password: yup
    .string()
    .min(8)
    .max(16)
    .required("Enter a password of a minimum of 8 characters"),
  new_password: yup
    .string()
    .min(8)
    .max(16)
    .required("Enter a password of a minimum of 8 characters")
    .matches(
      RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/
      ),
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: yup.string().oneOf([yup.ref("new_password"), null]),
});

const Security = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [saved, setSaved] = useState(false);
  const [passValue, setPassValue] = useState({
    password: "",
    showPassword: false,
  });

  const [newPassValue, setnewPassValue] = useState({
    password: "",
    showPassword: false,
  });

  const [confirmnewPassValue, setconfirmnewPassValue] = useState({
    password: "",
    showPassword: false,
  });

  const handlePasswordChange = (prop) => (event) => {
    setPassValue({ ...passValue, [prop]: event.target.value });
  };

  const handleClickShowPassword = () =>
    setPassValue({ ...passValue, showPassword: !passValue.showPassword });
  
  const handleClickShowNewPassword = () => 
    setnewPassValue({ ...newPassValue, showPassword: !newPassValue.showPassword });

  const handleClickShowconfirmNewPassword = () => 
    setconfirmnewPassValue({ ...confirmnewPassValue, showPassword: !confirmnewPassValue.showPassword });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  function removeAccount() {
    dispatch(deleteAccount())
      .then((response) => {
        if (response.payload.status === 200) {
          toast.success(response.payload.data.message);
          // dispatch(SignOut());
          localStorage.clear();
          navigate(BaseUrl);
        }
      })
      .catch((error) => toast.error(error.message));
  }

  const onSubmit = (data) => {
    // setSaved(true);
    dispatch(changePassword(data))
      .then((response) => {
        if (response.payload.status === 200) {
          toast.success(response.payload.data.message);
          reset();

          // window.location.reload();
          // setSaved(true);
        } else {
          toast.error(response.payload.msg);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <div>
      {/* Changed succesfully */}
      {saved && (
        <div className="flex justify-start font-default items-center w-[400px] absolute right-0 top-[87px] gap-2.5 px-4 py-[9px] rounded-md bg-[#f6ffed] border border-[#b7eb8f]">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406V5.71406Z"
              fill="#52C41A"
            ></path>
          </svg>
          <p className="flex-grow w-[308px] text-sm font-light text-left text-neutral-800">
            Password changed successfully
          </p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
            preserveAspectRatio="none"
          >
            <path
              d="M16.92 8.42L15.5 7L11.96 10.55L8.42 7L7 8.42L10.55 11.96L7 15.5L8.42 16.92L11.96 13.37L15.5 16.92L16.92 15.5L13.37 11.96L16.92 8.42Z"
              fill="#1C2E45"
              fillOpacity="0.6"
            ></path>
          </svg>
        </div>
      )}
      {/* End change successfully */}

      <div className="flex flex-row bg-white py-10 pl-10 w-[1125px]">
        <div className="flex flex-col justify-start items-start mr-4">
          <div
            className="flex-grow-0 flex-shrink-0 text-base font-light text-left mb-6 text-[#1b2b41]/[0.72] cursor-pointer"
            onClick={() => {
              navigate("/admin/settings");
            }}
          >
            Profile
          </div>

          <div
            className="flex-grow-0 flex-shrink-0 text-base font-light text-left mb-6 text-[#1b2b41]/[0.72] cursor-pointer"
            onClick={() => {
              navigate("/admin/settings/notification");
            }}
          >
            Notification settings
          </div>

          <div
            className="flex-grow-0 flex-shrink-0 text-base text-left mb-6 text-primary cursor-pointer"
            onClick={() => {
              navigate("/admin/settings/security");
            }}
          >
            Security
          </div>
        </div>

        <svg
          width="1"
          height="1374"
          viewBox="0 0 1 1374"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-32"
          preserveAspectRatio="xMidYMid meet"
        >
          <line
            x1="0.375"
            y1="-1.63918e-8"
            x2="0.37506"
            y2="1374"
            stroke="#1C3454"
            strokeOpacity="0.26"
            strokeWidth="0.75"
          ></line>
        </svg>

        <div className=" my-4">
          <h2 className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434]">
            Change password
          </h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="password_2 block mt-4 relative">
              <label className="block text-sm  mb-1 text-lightgray">
                Current password
              </label>
              <div className="eye_div">
                <Input
                  name="old_password"
                  {...register("old_password")}
                  type={passValue.showPassword ? "text" : "password"}
                />
                <p className="h-2 text-primary flex justify-center align-center  text-xs">
                  {errors.old_password?.message}
                </p>
                <div
                  className="icon_button absolute right-4 top-8"
                  onClick={handleClickShowPassword}
                >
                  {passValue.showPassword ? (
                    <EyeIcon className="h-6 font-extralight cursor-pointer" />
                  ) : (
                    <EyeOffIcon className="h-6 font-extralight cursor-pointer" />
                  )}
                </div>
              </div>
            </div>

            <Link
              className="flex justify-end align-baseline text-sm mt-2 text-primary hover:text-gray"
              to="/ForgotPassword"
            >
              Forgot Password?
            </Link>

            <div className="password_2 block mt-4 relative">
              <label className="block text-sm  mb-1 text-lightgray">
                New password
              </label>
              <div className="eye_div">
                <Input
                  name="new_password"
                  type={newPassValue.showPassword ? "text" : "password"}
                  {...register("new_password")}
                />
                <p className="h-2 text-primary flex justify-center align-center  text-xs">
                  {errors.new_password?.message}
                </p>
                <div
                  className="icon_button absolute right-4 top-8"
                  onClick={handleClickShowNewPassword}
                >
                  {newPassValue.showPassword ? (
                    <EyeIcon className="h-6 font-extralight cursor-pointer" />
                  ) : (
                    <EyeOffIcon className="h-6 font-extralight cursor-pointer" />
                  )}
                </div>
              </div>
            </div>

            <div className="password_2 block mt-4 relative">
              <label className="block text-sm  mb-1 text-lightgray">
                Confirm new password
              </label>
              <div className="eye_div">
                <Input
                  name="confirmPassword"
                  type={confirmnewPassValue.showPassword ? "text" : "password"}
                  {...register("confirmPassword")}
                />
                <p className="h-2 text-primary flex justify-center align-center  text-xs">
                  {errors.confirmPassword && "Passwords should match"}
                </p>
                <div
                  className="icon_button absolute right-4 top-8"
                  onClick={handleClickShowconfirmNewPassword}
                >
                  {confirmnewPassValue.showPassword ? (
                    <EyeIcon className="h-6 font-extralight cursor-pointer" />
                  ) : (
                    <EyeOffIcon className="h-6 font-extralight cursor-pointer" />
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                disabled={!isDirty || !isValid}
                size="small"
                type="submit"
              >
                Change password
              </Button>
            </div>
          </form>

          <p className="flex-grow-0 mt-8 flex-shrink-0 text-[22px] text-left text-[#192434]">
            Delete account?
          </p>

          <p className="flex-grow-0 flex-shrink-0 w-[504px] text-base font-light text-left text-black">
            Do you want to permanently delete your account? Note that this
            action is irreversible
          </p>

          <div className="flex justify-end">
            <Button
              size="small"
              onClick={() => {
                removeAccount();
              }}
              error
            >
              Delete account
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
