/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Modal, Step, StepLabel, Stepper } from "@mui/material";
import Padlock from "@ui/icons/PadLock";
import { checkPermission } from "shared/utility";
import { getServiceOptions } from "store/slices/miners/vendor-slice";

import { Card, Button, Checkbox } from "@ui";
import PaperClip from "@ui/icons/PaperClip";

import DiplomaScroll from "@ui/icons/DiplomaScroll";
import { getSingleTransaction, makePayment } from "store/slices/offtaker-slice";
import OffTakerShippingForm from "./OffTakerShippingForm";
import SelectPaymentMethod from "./selectPaymentMethod.jsx";
import OffTakerStripePayment from "./OffTakerStripePayment";
import { OfftakerTransactionShippingSchema } from "@validations/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { getProfile, fetchAllNotifications } from "@slices/settings-slice";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import OffTakerInTransit from "./offTakerIntransit";
import StepperCheckIcon from "@ui/icons/StepperCheckIcon";
import { toast } from "react-toastify";
import { SuccessResponse } from "config/status-code";

const TransactionDetails = () => {
  const [action, setAction] = useState("review");
  const { data } = useSelector((state) => state.settings);
  const [paymentType, setPaymentType] = useState("bank");
  const [isOpen, setIsOpen] = useState(false);
  const [isCard, setIsCard] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const location = useLocation();
  const [inventoryStatus, setInventoryStatus] = useState("pending");
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const { singleTransaction, loading } = useSelector(
    (store) => store?.offtakers
  );
 
  const { offtaker_transaction_charge } = useSelector((store) => store.miner_vendors);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state && location.state.status) {
      const { status = [] } = singleTransaction;
      setInventoryStatus(status[status?.length - 1]?.status);
    }
  }, [location, singleTransaction, inventoryStatus]);

  useEffect(() => {
    dispatch(getSingleTransaction(location.state.id));
    dispatch(getServiceOptions());
  }, []);

  const _FormData = new FormData();

  const steps = [
    "Pending",
    "Awaiting POP",
    "Awaiting POF",
    "Awaiting POC",
    "In Transit",
    "Delivered",
    "Completed",
  ];
  const indexOf = steps.findIndex((el) => {
    return el == inventoryStatus;
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    minHeight: 350,
    bgcolor: "background.paper",
    borderRadius: "0.25rem",
    boxShadow: 24,
    p: 4,
    outline: 0,
    padding: "22px",
  };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(OfftakerTransactionShippingSchema),
    mode: "all",
  });
  const onSubmit = handleSubmit(async (data) => {
    if (paymentType == "wallet") {
      try {
        const {
          contactPerson,
          long,
          lat,
          address,
          postal,
          country,
          state,
          city,
          terms,
          code,
          number,
          iso_code,
          signature = isSigned,
        } = data;
        const body = {
          action: "confirm_request",
          contact_name: contactPerson,
          contact_number: {
            code,
            number,
            iso_code,
          },
          amount: singleTransaction?.product?.product_total_value,
          location: {
            latitude: lat,
            longitude: long,
            address,
            postal_code: postal,
          },
          country,
          state,
          city,
          payment_method: "wallet",
          payment_method_id: "",
          terms,
          signature,
        };

        _FormData.append("data", JSON.stringify(body));

        await dispatch(
          makePayment({ id: singleTransaction._id, formdata: _FormData })
        ).then(({ payload }) => {
          if (SuccessResponse(payload.status)) {
            if (payload.data.code == 400 || payload.data.code == 422) {
              setPaymentType("bank");
              toast.error(payload.data.message);
              setIsOpen(false);
            } else {
              toast.success(payload.data.message);
              setIsOpen(false);
              dispatch(getProfile());
              dispatch(fetchAllNotifications({ limit: 10 }));
              return navigate("/off-taker/interactions/transactions");
            }
          }
        });
      } catch (error) {
        console.error(error, "OTI RED");
      }
    } else {
      try {
        setIsOpen(true);
        const user = JSON.parse(localStorage.getItem("user"));
        if (!stripe || !elements) {
          return;
        }

        const result = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
            name: user.name,
            email: user.email,
          },
        });

        if (!result?.paymentMethod) {
          toast.error(result.error.message);
        }

        const {
          contactPerson,
          long,
          lat,
          address,
          postal,
          country,
          state,
          city,
          terms,
          code,
          number,
          iso_code,
          signature = isSigned,
        } = data;
        const body = {
          action: "confirm_request",
          contact_name: contactPerson,
          contact_number: {
            code,
            number,
            iso_code,
          },
          amount: singleTransaction?.product?.product_total_value,
          location: {
            latitude: lat,
            longitude: long,
            address,
            postal_code: postal,
          },
          country,
          state,
          city,
          payment_method: "stripe",
          payment_method_id: result.paymentMethod.id,
          terms,
          signature,
        };

        _FormData.append("data", JSON.stringify(body));

        await dispatch(
          makePayment({ id: singleTransaction._id, formdata: _FormData })
        ).then(({ payload }) => {
          if (SuccessResponse(payload.status)) {
            setIsOpen(false);
            dispatch(getProfile());
            dispatch(fetchAllNotifications({ limit: 10 }));
            return navigate("/off-taker/interactions/transactions");
          }
        });
      } catch (error) {
        console.error(error, "OTI RED");
      }
    }
  });

  const handleSetIsOpen = () => {
    setIsCard(false);
    setPaymentType("bank");
  };
  const checkStatus = ["Awaiting POC", "Awaiting POF"].includes(
    inventoryStatus
  );

  return (
    <>
      {["Pending", "Awaiting POP", "Awaiting POC", "Awaiting POF"].includes(
        inventoryStatus
      ) && (
        <>
          {["review", "sign-contract"].includes(action) ? (
            <>
              <Card noPadding>
                <div className="border-b-[1px] border-[#1C345442] h-[258px] mx-[-1.5rem]">
                  <div className="divide-x divide-disabled mx-[2.5rem]">
                    <div className="text-default bg-white pt-[30px]">
                      <div className="flex items-center justify-between">
                        <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[24px] text-left text-[#192434]">
                          Main Info
                        </p>
                        <div className="ml-[8px] bg-[#6166B6] rounded-[3px]">
                          <Button
                            variant="outlined"
                            color="text-[#ffffff]"
                            fontWeight="font-light"
                            disabled={
                              checkStatus ||
                              singleTransaction?.seller?.signed !== true
                            }
                            onClick={() => {
                              setAction("csr");
                            }}
                          >
                            View Contract
                          </Button>
                        </div>
                      </div>
                      <div className="text-default  w-[796px] bg-white">
                        <div className="flex">
                          <div className="w-[50%]">
                            <div className="flex mb-[16px]">
                              <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                                Transaction ID:
                              </p>
                              <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                                {singleTransaction?.transaction_id}
                              </p>
                            </div>
                            <div className="flex mb-[16px]">
                              <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                                Product name:
                              </p>
                              <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                                {singleTransaction?.product?.product_name}
                              </p>
                            </div>
                            <div className="flex mb-[16px]">
                              <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                                Name of seller:
                              </p>
                              <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                                <span className="flex -ml-2 text-[#6166B6]">
                                  {singleTransaction?.seller?.name}
                                </span>
                              </p>
                            </div>
                            <div className="flex mb-[16px]">
                              <p className="flex-grow-0 mr-[100px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                                Status:
                              </p>
                              <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                                <span
                                  className={`py-1 px-5 inline-flex capitalize text-sm leading-5 font-light rounded bg-[#FDF2E7] text-[#F2994A] `}
                                >
                                  {inventoryStatus}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="mb-[16px]">
                              <p>Transaction value</p>
                              <p className="font-medium text-lg">
                                {singleTransaction?.product?.product_total_value}{" "}USD
                              </p>
                            </div>
                            <div>
                              <p>Commission value ({offtaker_transaction_charge}% of transaction value)</p>
                              <p className="font-medium text-lg">
                                {((singleTransaction?.product?.product_total_value * offtaker_transaction_charge / 100)?.toFixed(2)) ?? "N/A"}{" "}
                                {singleTransaction?.product?.product_total_value && "USD"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Document Card */}
                {singleTransaction?.documents?.length > 0 ? (
                  <div className="divide-x divide-disabled">
                    <div className="text-default bg-white pt-[30px]">
                      <div className="flex items-center justify-between">
                        <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[24px] text-left text-[#192434]">
                          Documents
                        </p>
                      </div>
                      <div className="pb-2">
                        {singleTransaction?.documents[0].map((document, i) => (
                          <p
                            key={i}
                            className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] ml-[19px]"
                          >
                            <span>{<PaperClip />}</span>
                            <span>{document?.name ?? "name"}</span>
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* End of Document Card */}
              </Card>

              <div className="mt-[30px]">
                <Card noPadding>
                  <div className="">
                    <div className="divide-x divide-disabled">
                      <div className="text-default bg-white pt-[30px]">
                        <div className="flex items-center justify-between">
                          <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[16px] text-left text-[#192434]">
                            Timeline
                          </p>
                        </div>
                        <div>
                          <Stepper orientation="vertical">
                            {singleTransaction?.timeline?.map((step, i) => (
                              <Step key={i}>
                                <StepLabel
                                  StepIconComponent={() => <DiplomaScroll />}
                                >
                                  <div className="flex font-normal items-center">
                                    {/* <img
                          className="max-w-[24px] mr-[8px]"
                          src={ProfilePicture}
                          alt="Miley Cyrus"
                        /> */}
                                    <span className="font-[300] text-[#1b2b41a3]">
                                      <span className="text-black mr-2">
                                        {" "}
                                        {step?.message}
                                      </span>

                                      {step?.created_at
                                        ? new Date(
                                            step?.created_at
                                          ).toLocaleString()
                                        : null}
                                    </span>
                                  </div>
                                </StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>

              <div className="pt-[40px]">
                <Stepper alternativeLabel orientation="horizontal">
                  {steps.map((step, i) => {
                    return (
                      <Step key={i}>
                        <StepLabel
                          StepIconComponent={
                            i <= indexOf ? StepperCheckIcon : Padlock
                          }
                        >
                          {step}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </div>
            </>
          ) : null}
          <>
            {action === "csr" ? (
              <>
                <div>
                  <Modal
                    open={isOpen}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      {isCard ? (
                        <OffTakerStripePayment
                          handleSetIsOpen={handleSetIsOpen}
                          onSubmit={onSubmit}
                          singleTransaction={singleTransaction}
                          loading={loading}
                        />
                      ) : (
                        <SelectPaymentMethod
                          paymentType={paymentType}
                          data={data?.wallet_balance}
                          onSubmit={onSubmit}
                          setPaymentType={setPaymentType}
                          setIsOpen={setIsOpen}
                          setIsCard={setIsCard}
                        />
                      )}
                    </Box>
                  </Modal>
                </div>
                <div className="flex flex-row justify-between mb-[20px]">
                  <p className="text-[22px] text-left text-black">
                    Review contract
                  </p>
                  <button
                    type="button"
                    disabled={checkPermission("Interactions")}
                    className="py-2 px-3 bg-[#6166B6] rounded-[3px] text-white text-sm"
                    // size="small"
                    onClick={onSubmit}
                  >
                    Confirm request and Pay
                  </button>
                </div>

                <Card noPadding>
                  <div className="border-b-[1px] border-[#1C345442] mx-[-1.5rem]">
                    <div className="divide-x divide-disabled mx-[2.5rem]">
                      <div className="text-default bg-white">
                        <div className="grid gap-[2.5rem] grid-cols-2">
                          <div className="border-r-[1px] border-[#1C345442]  py-[30px]">
                            <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[24px] text-left text-[#192434]">
                              Seller
                            </p>
                            <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#1b2b41]/[0.72]">
                              {singleTransaction?.seller?.name}
                            </p>
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              {singleTransaction?.seller?.company_name}
                            </p>
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              {singleTransaction?.seller?.location?.address}
                            </p>
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              {singleTransaction?.seller?.phone_number?.number}
                            </p>
                          </div>
                          <div className="py-[30px]">
                            <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[24px] text-left text-[#192434]">
                              Buyer
                            </p>
                            <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#1b2b41]/[0.72]">
                              {singleTransaction?.buyer?.name}
                            </p>
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              {singleTransaction?.buyer?.company_name}
                            </p>
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              {singleTransaction?.buyer?.location?.address}
                            </p>
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              {singleTransaction?.buyer?.phone_number?.number}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-b-[1px] border-[#1C345442] mx-[-1.5rem]">
                    <div className="divide-x divide-disabled mx-[2.5rem]">
                      <div className="text-default bg-white">
                        <div className="grid grid-cols-8">
                          <div className="pt-[30px] pb-[10px] col-span-1">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              Description:
                            </p>
                          </div>
                          <div className="pt-[30px] pb-[10px] col-span-7">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              {singleTransaction?.product?.product_description}
                            </p>
                          </div>
                        </div>

                        <div className="grid grid-cols-8">
                          <div className="pb-[10px] col-span-1">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              Quantity:
                            </p>
                          </div>
                          <div className="pb-[10px] col-span-7">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              {singleTransaction?.product?.product_quantity}{" "}
                              {
                                singleTransaction?.product
                                  ?.product_unit_of_measurement?.id
                              }
                            </p>
                          </div>
                        </div>

                        <div className="grid grid-cols-8">
                          <div className="pb-[10px] col-span-1">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              TC:
                            </p>
                          </div>
                          <div className="pb-[10px] col-span-7">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              {
                                singleTransaction?.product
                                  ?.product_treatment_cost
                              }{" "}
                              USD/
                              {
                                singleTransaction?.product
                                  ?.product_unit_of_measurement?.id
                              }
                            </p>
                          </div>
                        </div>

                        <div className="grid grid-cols-8">
                          <div className="pb-[10px] col-span-1">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              Price:
                            </p>
                          </div>
                          <div className="pb-[10px] col-span-7">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              {singleTransaction?.product?.product_price} USD/
                              {
                                singleTransaction?.product
                                  ?.product_unit_of_measurement?.id
                              }
                            </p>
                          </div>
                        </div>

                        <div className="grid grid-cols-8">
                          <div className="pb-[10px] col-span-1">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              Total value:
                            </p>
                          </div>
                          <div className="pb-[10px] col-span-7">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              {singleTransaction?.product?.product_total_value}{" "}
                              USD
                            </p>
                          </div>
                        </div>

                        <div className="grid grid-cols-8">
                          <div className="pb-[10px] col-span-1">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              Country:
                            </p>
                          </div>
                          <div className="pb-[10px] col-span-7">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              {
                                singleTransaction?.product?.product_mine
                                  ?.country?.name
                              }
                            </p>
                          </div>
                        </div>

                        <div className="grid grid-cols-8">
                          <div className="pb-[10px] col-span-1">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              Payment
                            </p>
                          </div>
                          <div className="pb-[10px] col-span-7">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              100% payment to Escrow, payment processed after
                              confirmed delivery
                            </p>
                          </div>
                        </div>
                        {/* OffTakers shipping form */}
                        <div className="grid grid-cols-8 content-center">
                          <div className="pb-[30px] col-span-1">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              Drop-Off details:
                            </p>
                          </div>
                          <div className="pb-[30px] col-span-7 mt-10">
                            <OffTakerShippingForm
                              register={register}
                              setValue={setValue}
                              handleSubmit={handleSubmit}
                              errors={errors}
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-8">
                          <div className="pb-[30px] col-span-1">
                            <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                              Signature:
                            </p>
                          </div>
                          <div className="pb-[30px] col-span-7">
                            <div className="mb-3 xl:w-full">
                              <label
                                htmlFor={
                                  "In place of your signature, type your full legal name:"
                                }
                                className="form-label inline-block mb-2 font-light text-sm text-[#1B2B41B8]"
                              >
                                In place of your signature, type your full legal
                                name{" "}
                                <span className="!text-[#6166B6]">
                                  {singleTransaction?.buyer?.name}
                                </span>
                              </label>
                              <div className="relative flex items-center">
                                <input
                                  name="signature"
                                  type="text"
                                  className={`w-full h-[42px] max-h-[42px] p-[10px] border-none font-light text-gray-700 bg-[#1A38601A] rounded z-0 focus:shadow focus:outline-none focus:ring-primary  placeholder:text-[#1C345442] placeholder:font-extralight placeholder:text-base`}
                                  onChange={({ target: { value } }) => {
                                    if (
                                      value === singleTransaction?.buyer?.name
                                    ) {
                                      setIsSigned(true);
                                    } else {
                                      setIsSigned(false);
                                    }
                                  }}
                                  disabled={isSigned ? true : false}
                                />
                              </div>
                              <div>
                                <div className="flex gap-2 mt-[10px] items-center ">
                                  <Checkbox
                                    onChange={(e) => {
                                      setValue("terms", e.target.checked);
                                    }}
                                    disabled={!isSigned}
                                  />
                                  <p className="font-light text-ts mb-">
                                    I agree to the Terms that this is signed by
                                    me.
                                  </p>
                                </div>
                                {errors.terms?.message && (
                                  <p className="paragraph text-red text-xs mt-1">
                                    {" "}
                                    * {errors.terms?.message}{" "}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </>
            ) : null}
          </>
        </>
      )}

      {/* Intransit Section */}
      {inventoryStatus == "In Transit" && (
        <OffTakerInTransit
          inventoryStatus={inventoryStatus}
          singleTransaction={singleTransaction}
        />
      )}

      {/* Delivered Section */}
      {inventoryStatus == "Delivered" && (
        <OffTakerInTransit
          inventoryStatus={inventoryStatus}
          singleTransaction={singleTransaction}
        />
      )}

      {/* Completed Section */}
      {inventoryStatus == "Completed" && (
        <OffTakerInTransit
          inventoryStatus={inventoryStatus}
          singleTransaction={singleTransaction}
        />
      )}
    </>
  );
};

export default TransactionDetails;
