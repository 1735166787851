/* eslint-disable no-unused-vars */
import React from "react";
import { useNavigate } from "react-router-dom";
import Table from "components/Table";
import { Button } from "@ui";
import { OffTakerProspectInfoUrl } from 'config/app-url';
import { useSelector, useDispatch } from 'react-redux';
import { getAllProspects, fetchPaginatedProspects, setProspectsSearchValue } from 'store/slices/offtaker-slice';
import { OffTakerProspectsTableHeaders } from '@constants';
import InterationsStats from '@ui/InterationsStats';

const DemandsList = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();


	const { loading,
		prospects: { data, pagination, search, filter, counts } }
		= useSelector((state) => state.offtakers);

	const handleViewDetails = (record) => {
		navigate(OffTakerProspectInfoUrl, {
			state: {
				prospect: record
			}
		})
	};
	const handleChangePage = (option) => {
		dispatch(
			fetchPaginatedProspects({
				page: pagination[option],
				per_page: pagination.limit,
				search: search,
				filter: filter,
			})
		);
	};
	const handleSetPerPage = (e) => {
		dispatch(
			fetchPaginatedProspects({
				page: 1,
				per_page: e.target.value,
				search: search,
				filter: filter,
			})
		);
	};
	const handleSearch = (e) => {
		dispatch(setProspectsSearchValue(e.target.value));
	};

	const handleSearchSubmit = (e) => {
		e.preventDefault();
		if (!search) {
			dispatch(getAllProspects());
		}
		dispatch(setProspectsSearchValue(search));
		dispatch(
			fetchPaginatedProspects({
				page: 1,
				per_page: pagination.limit,
				search: search,
				filter: filter,
			})
		);
	};

	const handleTabChange = (e, tab) => {
		e.preventDefault();
		dispatch(
			fetchPaginatedProspects({
				page: 1,
				per_page: pagination.limit,
				search: search,
				filter: tab.toLowerCase(),
			})
		);
	};

	return (
		<div>
			<Button onClick={() => navigate(`/off-taker/invest/find_prospects`)}>
				Find prospects
			</Button>
			<div className="mt-6 grid grid-cols-3 lg:grid gap-2 sm:grid">
				<InterationsStats
					counts={counts}
					type="prospects"
				/>
			</div>

			<div className="mt-4">
				<Table
					tableInfo={OffTakerProspectsTableHeaders}
					isToggle={false}
					isViewDetails={true}
					isStatus={true}
					handleViewDetails={handleViewDetails}
					navigate={navigate}
					data={data}
					pagination={pagination}
					handleSetPerPage={handleSetPerPage}
					handleSearchSubmit={handleSearchSubmit}
					handleChangePage={handleChangePage}
					handleTabChange={handleTabChange}
					loading={loading}
					handleSearch={handleSearch}
					fetchDefaultData={getAllProspects}
					search={search}
				/>
			</div>
		</div>
	);
};

export default DemandsList;
