/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import axios from "@config/axios-instance";
import * as API from "@config/url";
// const POST = 'post';
import { toast } from "react-toastify";

// eslint-disable-next-line no-unused-vars
const GET = "get";
const POST = "post";

export const config = (method, url, data = null) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      "Authorization": "bearer "+ token
    },
    data,
  };
};

const getCreateDemandData = createAsyncThunk(
  "offtaker/demands/create/data",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchAllDemandsData);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPaginatedDemands = createAsyncThunk(
  "offtaker/fetchPaginatedDemandsData",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.fetchPaginatedDemandsData(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

// Post demand data
const submit_offtaker_demand = createAsyncThunk(
  "offtaker/submit_offtaker_demand",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(POST, API.postOffTakerDemandData, data)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const search_all_demands = createAction(
  "search_demands",
  function searchDemands(action) {
    if (action.search_term.length > 0) {
      const data = [].filter(
        (demand) =>
          demand.product_name
            .toLowerCase()
            .indexOf(action.search_term.toLowerCase()) >= 0
      );
      return {
        payload: {
          data,
        },
      };
    }
  }
);

const getAllOfftakersDemands = createAsyncThunk(
  "offtaker/demands",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.fetchDemandsData));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getSingleOfftakerDemand = createAsyncThunk(
  "offtaker/demand",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(GET, API.getSingleOffTakerDemandDetails(id))
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getSingleOfftakerDemandInterests = createAsyncThunk(
  "offtaker/demands/interests",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(GET, API.getSingleOffTakerDemand(payload.id,payload.search))
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getSingleOfftakerDemandInterestDetails = createAsyncThunk(
  "offtaker/demands/interest/detail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(GET, API.getSingleOffTakerDemandInterest(id))
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const awardDemandInterest = createAsyncThunk(
  "offtaker/demands/interest/award",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(POST, API.postSingleOffTakerDemandInterest(id), {
          status: "awarded",
        })
      );

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

/** create slice */
const demands_slice = createSlice({
  name: "offtaker_demands",
  initialState: {
    status: false,
    loading: false,
    error: null,
    message: null,
    demands: {
      counts: {},
      data: [],
      pagination: null,
      search: "",
      filter: "",
    },
    demand: {},
    demandInterests: {
      data: [],
      counts: {},
      pagination: null,
      search: "",
      filter: "",
    },
    demandInterestDetails: {},
  },
  reducers: {
    setDemandSearchValue: (state, action) => {
      state.demands.search = action.payload;
    },
  },
  extraReducers: {
    [submit_offtaker_demand.pending]: (state) => {
      state.loading = true;
    },
    [submit_offtaker_demand.fulfilled]: (state, { payload }) => {
      state.status = payload.status;
      //toast.success(payload.data.message);
      state.loading = false;
    },
    [submit_offtaker_demand.rejected]: (state, { payload }) => {
      state.message = payload.data.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },
    [getAllOfftakersDemands.pending]: (state) => {
      state.loading = true;
    },
    [getAllOfftakersDemands.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.demands.data = payload.data.demands;
      state.demands.counts = payload.data.counts;
      state.demands.pagination = payload.data.pagination;
    },
    [getAllOfftakersDemands.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },
    [fetchPaginatedDemands.pending]: (state) => {
      state.loading = true;
    },
    [fetchPaginatedDemands.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.demands.data = payload.data.demands;
      state.demands.counts = payload.data.counts;
      state.demands.pagination = payload.data.pagination;
    },
    [fetchPaginatedDemands.rejected]: (state, action) => {
      state.error = true;
      // toast.error(payload.message);
    },

    [getSingleOfftakerDemand.pending]: (state) => {
      state.loading = true;
    },
    [getSingleOfftakerDemand.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.demand = payload.demand[0];
    },
    [getSingleOfftakerDemand.rejected]: (state, action) => {
      state.error = true;

      // toast.error(payload.message);
    },
    [getSingleOfftakerDemandInterests.pending]: (state) => {
      state.loading = true;
    },
    [getSingleOfftakerDemandInterests.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.demandInterests.data = payload.interests;
      state.demandInterests.count = payload.interests.length;
      state.demandInterests.pagination = payload.pagination;
    },
    [getSingleOfftakerDemandInterests.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },

    // Get single demand interest
    [getSingleOfftakerDemandInterestDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSingleOfftakerDemandInterestDetails.fulfilled]: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.member_since = payload.member_since;
      state.demandInterestDetails = payload.interest;
    },
    [getSingleOfftakerDemandInterestDetails.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },

    // Get single demand interest
    [awardDemandInterest.pending]: (state) => {
      state.loading = true;
    },
    [awardDemandInterest.fulfilled]: (state, { payload }) => {
      state.loading = false;
      toast.success(payload.message);
    },
    [awardDemandInterest.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },
  },
});

export const { setDemandSearchValue } = demands_slice.actions;

export {
  submit_offtaker_demand,
  getAllOfftakersDemands,
  getSingleOfftakerDemand,
  getCreateDemandData,
  search_all_demands,
  getSingleOfftakerDemandInterests,
  getSingleOfftakerDemandInterestDetails,
  awardDemandInterest,
};
export default demands_slice.reducer;
