/* eslint-disable no-unused-vars */
import Button from "components/ui/Button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";
import Skeleton from "@components/auctions/Skeleton";
import { useEffect, useState } from "react";
import { useAuth } from "@hooks";
import { fetchAdminRole } from "@slices/admin-slice";
// import { SelectField } from "components/ui/Inputs";
import { Input } from "@ui";
import PropTypes from "prop-types";
import { RoleSuccessModal } from "../add/RoleConfirmModal";
import { SuccessResponse } from "config/status-code";
import { useParams } from "react-router-dom";
import { addRole } from "@slices/roles-permissions-slice";
import { useNavigate } from "react-router-dom";

const TeamMember = (id) => {
  const profiles = useAuth();
  const [open, setOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const teams = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAdminRole({ id: profiles?.account?._id, _id: id.id }));
  }, [dispatch]);
  let [data, setData] = useState({
    name: "",
  });
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  return teams?.team_member?.length == 0 ? (
    <Skeleton />
  ) : (
    <>
      <RoleSuccessModal open={open} setOpen={setOpen} name={data?.name} />
      <div className="bg-white pt-5 rounded-md mb-5">
        <div className="flex justify-between px-5 mb-4">
          <h1>Team Members</h1>
          <Button
            onClick={() => navigate("../../../../admin/teams/member/add")}
            // onClick={() => {
            //   createRole();
            //   //   setOpen(!open);
            // }}
          >
            Add Team Member
          </Button>
        </div>
        <Divider />
        <div className="col-start-5 col-end-7 pt-4">
          <div className="w-2/3">
            {teams?.team_member?.docs[0]?.users.map((user, index) => (
              <div
                className="flex mb-3 p-5"
                key={index + 1}
                style={{ marginLeft: "11px" }}
              >
                <>
                  <img
                    className="w-[21.33px] h-[21.33px] rounded-circle "
                    src="https://cdn.smehost.net/2020sonymusiccouk-ukprod/wp-content/uploads/2019/10/Miley-Cyrus-03_MidnightSky_WhiteLight_2302.jpg"
                    alt=""
                  />
                  &nbsp;&nbsp;&nbsp;
                  <h3>{user?.name}</h3>
                </>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

TeamMember.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default TeamMember;
