/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { Select, Input } from '@ui';
import { FileUpload } from 'pages/onboarding/index';
import ErrorMessage from 'components/ErrorMessage';
import PaperClip from '@ui/icons/PaperClip';

const ProductInformation = ({
	errors,
	register,
	productImage,
	setProductImage,
	uploadProductImage,
	handleChange,
	handleSelectChange,
	setMine,
	setValue,
	minerals,
	mines,
	mineral,
	isSeeking,
	watch
}) => {
	const { createInterestData, seekFundingData } = useSelector(
		(store) => store?.investors
	);
	const handleMineChange = (e, { name }) => {
		setMine(e);
		setValue(name, e._id, {
			shouldValidate: true,
			shouldDirty: true
		});
	};
	let files = watch("product_image");

	const removeFile = (id) => {
		setValue('product_image', []);
		files = files.filter((file) => file.id !== id);
		// setProductImage((prevState) => prevState.filter((file) => file.id !== id));
		setProductImage(files);
	};

	return (
		<div className="text-default  w-[796px] h-[798px] bg-white px-[34px]">
			<p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434]">
				Product information
			</p>
			<p className="flex-grow-0 flex-shrink-0 w-[796px] text-base font-light text-left text-[#1b2b41]/[0.72]">
				Enter the details of the mineral you require funding for.
			</p>

			<div className="mt-4">
				<Input
					label="Product name"
					id="product_name"
					{...register('product_name')}
					name="product_name"
					onChange={handleChange}
				/>
				{errors.product_name && (
					<ErrorMessage>{errors.product_name?.message}</ErrorMessage>
				)}
			</div>

			{isSeeking ? (
				<div className="mt-4">
					<Select
						name="mineral"
						label="Select mineral"
						options={minerals}
						onChange={handleSelectChange}
					/>
					{errors.mine && (
						<ErrorMessage>{errors.minerals?.message}</ErrorMessage>
					)}
				</div>
			) : (
				<div className="mt-4">
					<Input
						label="Mineral / Metal"
						id="mineral"
						name="mineral"
						onChange={handleChange}
						value={mineral?.name}
						readOnly
					/>
				</div>
			)}

			<div className="mt-4 flex gap-7">
				<div className="flex-1">
					<Input
						className="pl-4"
						label="Quantity"
						postfix="dmT"
						id="quantity"
						type="number"
						{...register('quantity')}
						name="quantity"
						onChange={handleChange}
					/>
					{errors.quantity && (
						<ErrorMessage>{errors.quantity?.message}</ErrorMessage>
					)}
				</div>
				<div className="flex-1">
					<Input
						isMoneyInput
						label="Price"
						postfix="/dmT"
						type="number"
						id="price"
						{...register('price')}
						name="price"
						onChange={handleChange}
					/>
					{errors.price && <ErrorMessage>{errors.price?.message}</ErrorMessage>}
				</div>
			</div>
			<div className="mt-4">
				<Select
					name="mine"
					label="Select a mine"
					options={
						mines?.map((key) => {
							return {
									name: key?.mine_name,
									slug: key.mine_name,
									...key
								}	 
							})	
						}
					// options={mines}
					onChange={handleMineChange}
				/>
				{errors.mine && <ErrorMessage>{errors.mine?.message}</ErrorMessage>}
			</div>

			{/* file upload */}
			<div className="mt-4">
				<FileUpload
					source="Product image (20mb max)"
					handleChange={uploadProductImage}
					label="Upload image ..."
					dropZoneLabel="Drop image here"
					allowedTypes={['png', 'jpg', 'jpeg']}
				/>
			</div>
			<div>
			<p className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] align-center">
				<span>{<PaperClip />}</span>
				<span>
					{" "}
					{productImage && productImage[0]
					? `${productImage[0]?.name}`
					: "no files uploaded"}
				</span>
				<button className="text-red text-[16px] px-2" onClick={() => removeFile(productImage[0].id)}>
					{" "}x{" "}
				</button>
			</p>
				{/* {productImage.map((file) => {
					return (
						<p
							key={file.id}
							className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] align-center"
						>
							<span>{<PaperClip />}</span>
							<span>{file ? `${file.name}` : 'no files uploaded'}</span>
							<button
								className="text-red text-[16px] px-2"
								onClick={() => removeFile(file.id)}
							>
								{' '}
								x{' '}
							</button>
						</p>
					);
				})} */}
			</div>

			{errors.product_image && (
				<ErrorMessage>{errors.product_image?.message}</ErrorMessage>
			)}
		</div>
	);
};

export default ProductInformation;
