/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Skeleton from "@components/investors/Skeleton";
import { Btn as Button } from "@atoms/button/button";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import { DropDownIcon } from "@ui/icons";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Card } from "@ui";
import { getInitials } from "shared/utility";
import { useSelector, useDispatch } from "react-redux";
import { getInvestmentOffers } from "@slices/miners/investor-slice";
import { priceSplitter } from "shared/utility";

const Investors = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [searchParams, setParams] = useState();
  const {
    loading,
    investmentOffers: { data: offers, pagination },
  } = useSelector((store) => store?.investors);

  const handleSetPerPage = (e) => {
    setLimit(e.target.value);
    dispatch(
		getInvestmentOffers({
        page: 1,
        limit: e.target.value,
      })
    );
  };

  function handleChangePage(page) {
    dispatch(
		getInvestmentOffers({
        page: page,
        limit: limit,
      })
    );
  }

  React.useEffect(() => {
    dispatch(getInvestmentOffers({ page: 1, limit: limit }));
  }, []);

  return (
    <div className="font-default h-full flex flex-col">
      <div className="flex flex-row justify-between">
        <p className="text-[22px] text-left text-black mb-3">
          Available investment offers
        </p>
      </div>
      <div className="flex-1 grid lg:grid-cols-3 sm:grid gap-[30px] font-default">
        {loading ? (
          <>
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <Skeleton key={index} />
              ))}
          </>
        ) : (
          offers?.map((offer) => (
            <Card key={offer._id}>
              <div className="flex flex-col justify-start items-start  left-[23px] top-[23px] gap-4">
                <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 w-[206px] h-[66px]">
                  <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
                    {offer?.user_image !== null && offer?.user_image ? (
                      <img
                        className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                        src={`${offer?.user_image?.url}`}
                        alt=""
                      />
                    ) : (
                      `${getInitials(offer?.investor.name)}`
                    )}
                    {/* {`${offer?.investor?.name[0]}`} */}
                  </div>
                  <div className="flex flex-col justify-start items-start flex-grow-0 ml-4 flex-shrink-0 ">
                    <p className="flex-grow-0 flex-shrink-0 mb-1 text-base text-left text-primary">
                      {offer?.investor?.name}
                    </p>
                    <p className="flex-grow-0 flex-shrink-0 mb-1 text-sm text-left text-[#18273a]/[0.94]">
                      {offer?.investor?.company}
                    </p>
                    <p className="flex-grow-0 flex-shrink-0 text-sm font-light text-left text-[#1b2b41]/[0.72]">
                      {offer?.investor?.country?.name}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[307px]  ">
                  <div className="grid grid-cols-3 mb-[15px]  w-full h-[26px]">
                    <p className=" left-0 top-[3.5px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
                      Mineral:
                    </p>
                    <p className="col-span-2  text-base font-light text-left text-[#18273a]/[0.94]">
                      {offer?.mineral?.name}
                    </p>
                  </div>
                  <div className="grid grid-cols-3 mb-[15px]  w-full h-[26px]">
                    <p className="text-sm font-light text-left text-[#1b2b41]/[0.72]">
                      Phase:
                    </p>
                    <p className="text-base col-span-2 font-light text-left text-[#18273a]/[0.94]">
                      {offer?.production_phase?.slug}
                    </p>
                  </div>
                  <div className="grid grid-cols-3 mb-[15px] w-full h-[26px]">
                    <p className="text-sm font-light text-left text-[#1b2b41]/[0.72]">
                      Type:
                    </p>
                    <p className="top-[68px] col-span-2  text-base font-light text-left text-[#18273a]/[0.94]">
                      {offer?.investment_type?.slug}
                    </p>
                  </div>
                  <div className="grid grid-cols-3 mb-[15px] w-full h-[26px]">
                    <p className="text-sm font-light text-left text-[#1b2b41]/[0.72]">
                      Amount:
                    </p>
                    <p className="text-base col-span-2  font-light text-left text-[#18273a]/[0.94]">
                      {`USD ${priceSplitter(offer?.investment_amount)}`}
                    </p>
                  </div>
                </div>
              </div>
              <Button
                onClick={() =>
                  navigate("/miner/investors/offerings/details", {
                    state: {
                      id: offer._id,
                    },
                  })
                }
              >
                View offering
              </Button>
            </Card>
          ))
        )}
      </div>
      {pagination && (
        <div className="flex justify-start lg:justify-end items-center w-full py-4 px-8 text-[13px] uppercase text-[#18273AF0]">
          <div className="flex justify-between gap-2 items-center">
            <p>Rows per page: {pagination[0]?.limit}</p>
            <FormControl sx={{ height: "30px", border: 0 }}>
              <Select
                sx={{ height: "30px", border: 0 }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => handleSetPerPage(e)}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="mx-6">
            1 -{" "}
            {Math.ceil(
              pagination[0]?.total / pagination[0]?.limit
            )}{" "}
            of {pagination[0]?.page}
          </div>
          <div className="flex justify-between gap-10">
            {pagination[0]?.prevPage && (
              <div
                className="rotate-90 cursor-pointer"
                onClick={() =>
                  handleChangePage(pagination[0]?.prevPage)
                }
              >
                <DropDownIcon />
              </div>
            )}
            {pagination[0]?.page >=
            Math.ceil(
              pagination[0]?.total / pagination[0]?.limit
            )
              ? null
              : pagination[0]?.page + 1 && (
                  <div
                    className="-rotate-90 cursor-pointer"
                    onClick={() =>
                      handleChangePage(pagination[0]?.page + 1)
                    }
                  >
                    <DropDownIcon />
                  </div>
                )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Investors;
