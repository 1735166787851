/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useEffect, useState, useContext } from "react";
import GoogleMapReact from "google-map-react";
import { Button } from "@ui";
import { LocationIcon } from "@ui/icons";
import axios from "axios";
import { LongLatContext } from "context/LongLat";
import dayjs from "dayjs";

const LocationPin = ({ text }) => {
  return (
    <div>
      <LocationIcon />
      <p>{text}</p>
    </div>
  );
};

const Marker = ({ text }) => {
  const infoWindowStyle = {
    borderRadius: "10px",
    height: "124px",
    position: "relative",
    bottom: "158px",
    left: "-90px",
    width: 180,
    backgroundColor: "white",
    boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
    padding: 10,
    fontSize: 14,
    zIndex: 100,
  };
  const gmstyle = {
    filter: "drop-shadow(0 4px 2px rgba(178,178,178,.4))",
    height: "12px",
    left: "7px",
    position: "absolute",
    top: "-10px",
    background: "#fff",
    clipPath: "polygon(0% 0%,50% 100%,100% 0%)",
    content: "",
    zIndex: "100",
    width: "25px",
    transform: "translateX(-50%)",
  };

  return (
    <div>
      <LocationIcon />
      <div style={infoWindowStyle}>
        <div style={{ fontSize: 14, textAlign: "center", fontWeight: "bold" }}>
          {text.location}
        </div>
        <br></br>
        <div style={{ fontSize: 12, textAlign: "center" }}>
          {text.tracking_detail}
        </div>
        <br></br>
        <div style={{ fontSize: 12, textAlign: "center" }}>
          <span style={{ fontWeight: "bold" }}>ETA :</span>{" "}
          {dayjs(text?.checkpoint_date).format("MMMM D, YYYY")}
        </div>
      </div>
      <div style={gmstyle}></div>
    </div>
  );
};

// const handleToggleAction = (text) => {
//   console.log("TEXT", text);
// };
let tracking_map = {};
let tracking_maps = {};
let geocodePolyLine = [];
let multiple_waypoint = [];
let allwaypoints = [];
let multiple_paths = [];
var paths = [];
let showPoly = false;
var directionsService;
var directionsRenderer;
const TrackingMap = ({
  location,
  zoomLevel,
  setValue,
  shipment,
  setReadonly,
  setCountryReadonly,
  setCountryPrompt,
  setAddressPrompt,
}) => {
  const handleApiLoaded = (map, maps) => {
    tracking_map = map;
    tracking_maps = maps;
    if (map) {
      map.setOptions({
        gestureHandling: "greedy",
        mapTypeControl: false,
        minZoom: 4,
      });
    }
    // if (shipment.active_data.length > 0) {
    //   // for pickup
    //   shipment.active_data.forEach((x) => {
    //     let marker = new maps.Marker({
    //       position: { lat: x.pickup?.latitude, lng: x?.pickup?.longitude },
    //       map,
    //     });
    //     return marker;
    //   });
    //   // for dropoff
    //   shipment.active_data.forEach((x) => {
    //     let marker = new maps.Marker({
    //       position: {
    //         lat: x.dropoff?.location?.latitude,
    //         lng: x.dropoff?.location?.longitude,
    //       },
    //       map,
    //     });
    //     return marker;
    //   });
    // }

    //     const routes = [{origin: "Pune", destination: "Mumbai"}];
    //     const rendererOptions = {
    //       preserveViewport: true,
    //       suppressMarkers:true
    //   };
    //   const directionsService = new google.maps.DirectionsService();
    //   routes.map(function(route){
    //     var request = {
    //         origin: route.origin,
    //         destination: route.destination,
    //         travelMode: google.maps.TravelMode.DRIVING
    //     };

    //     const directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions);
    //     directionsDisplay.setMap(map);

    //     directionsService.route(request, function(result, status) {
    //         console.log(result);

    //         if (status == google.maps.DirectionsStatus.OK) {
    //             directionsDisplay.setDirections(result);
    //         }
    //     });
    // });

    // use map and maps objects
    // console.log("map -------------------- \n", maps);
  };

  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();
  const [longLat, setLongLat] = useContext(LongLatContext);
  const [newLocation, setNewLocation] = useState();

  const [currentLocation, setCurrentLocation] = useState({
    loaded: false,
    coordinates: { lat: "", lng: "" },
  });

  const onSuccess = (location) => {
    const gpsLocation = {
      lat: location.coords.latitude,
      lng: location.coords.longitude,
    };
    setCurrentLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });
    onClick(gpsLocation);
  };

  const onError = (error) => {
    setCurrentLocation({
      loaded: true,
      error: {
        code: error.code,
        message: error.message,
      },
    });
  };

  const getLocation = () => {
    if (!("geolocation" in navigator)) {
      onError({
        code: 0,
        message: "Geolocation not supported",
      });
    }
    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  };

  useEffect(() => {
    if (currentLocation.loaded === true) {
      setLatitude(currentLocation.coordinates.lat);
      setLongitude(currentLocation.coordinates.lng);
      setLongLat({
        ...longLat,
        lat: currentLocation.coordinates.lat,
        lng: currentLocation.coordinates.lng,
      });
      setNewLocation({
        ...newLocation,
        lat: currentLocation.coordinates.lat,
        lng: currentLocation.coordinates.lng,
      });
    } else {
      setLatitude(location.lat);
      setLongitude(location.lng);
      setNewLocation({ ...newLocation, lat: location.lat, lng: location.lng });
    }
  }, [currentLocation]);

  const onClick = async (rest) => {
    // console.log("map values -============= \n", rest);
    const address = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${rest.lat},${rest.lng}&key=AIzaSyDA4S3ph7PmMHFQ6mFjN05OWr2t7_i2k18`
    );

    let place = address.data.results[0];
    let address1 = "";
    let postcode = "";
    let country = "";
    let state = "";
    let city = "";
    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case "route": {
          address1 += component.short_name;
          break;
        }

        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case "country": {
          country = component.short_name;
          break;
        }

        case "administrative_area_level_1": {
          state = component.long_name;
          break;
        }

        case "administrative_area_level_2": {
          city = component.long_name;
          break;
        }
      }
    }
    address1 === "" ? setAddressPrompt(true) : setAddressPrompt(false);
    address1 === "" ? setReadonly(false) : setReadonly(true);
    country === "" ? setCountryPrompt(true) : setCountryPrompt(false);
    country === "" ? setCountryReadonly(false) : setCountryReadonly(true);

    const lng = place.geometry.location.lng;

    const lat = place.geometry.location.lat;
    // console.log("address", address1);
    setValue("address", address1);
    setValue("postal", postcode);
    setValue("state", state);
    setValue("city", city);
    setValue("country", country);
    setValue("long", lng);
    setValue("lat", lat);
  };

  function drawRoad() {
    let waypoints = multiple_waypoint;
    waypoints = waypoints.slice(1, -1);
    directionsService = new google.maps.DirectionsService();
    directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
    });
    directionsRenderer.setMap(tracking_map);
    // const origin = { lat: 40.756795, lng: -73.954298 };
    // const destination = { lat: 41.756795, lng: -78.954298 };

    directionsService.route(
      {
        origin: multiple_waypoint[0].location,
        destination: multiple_waypoint[multiple_waypoint.length - 1].location,
        waypoints: waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(result);
          if (multiple_paths.length > 0) {
            drawPolyLIne(paths);
          }
        } else {
          paths = [allwaypoints];
          drawPolyLIne(paths);
          // console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  function drawPolyLIne(paths) {
    const lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    };
    paths.forEach((x, index) => {
      geocodePolyLine[index] = new tracking_maps.Polyline({
        path: x,
        geodesic: true,
        icons: [
          {
            icon: lineSymbol,
            offset: "100%",
          },
        ],
        strokeColor: "#00a1e1",
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });

      geocodePolyLine[index].setMap(tracking_map);

      var bounds = new tracking_maps.LatLngBounds();
      for (let marker of x) {
        bounds.extend(new tracking_maps.LatLng(marker.lat, marker.lng));
      }
      tracking_map.fitBounds(bounds);
    });
  }

  function reloadParams() {
    multiple_waypoint = [];
    multiple_paths = [];
    paths = [];
    allwaypoints = [];
  }

  if (Object.keys(tracking_map).length > 0) {
    if (shipment.data) {
      reloadParams();
      if (shipment?.data?.geolocation.length > 0) {
        shipment.data.geolocation.forEach((x) => {
          allwaypoints.push(x.coordinates);
          if (
            x.types == "locality" ||
            x.types == "postal_code" ||
            x.types == "political"
          ) {
            multiple_waypoint.push({
              location: { lat: x.coordinates.lat, lng: x.coordinates.lng },
              stopover: true,
            });
          } else {
            multiple_paths.push(x.coordinates);
          }
        });
        paths = [multiple_paths];
        if (multiple_waypoint.length > 1) {
          if (directionsRenderer != null) {
            directionsRenderer.setMap(null);
            directionsRenderer = null;
          }
          drawRoad();
        } else {
          multiple_paths.push(multiple_waypoint[0].location);
        }

        // if (multiple_paths.length > 0) {
        //   drawPolyLIne(paths);
        // }
      }
    }
  }

  // console.log("SHIPMENT", shipment);
  return (
    <div className="w-full h-full relative">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDA4S3ph7PmMHFQ6mFjN05OWr2t7_i2k18",
          libraries: ["places"],
        }}
        center={newLocation}
        defaultZoom={zoomLevel}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onClick={onClick}
      >
        <LocationPin lat={latitude} lng={longitude} text="" />
        {shipment.data &&
          shipment.data.geolocation.map((shipment, index) => (
            <Marker
              lat={shipment?.coordinates.lat}
              lng={shipment?.coordinates.lng}
              key={index + 1}
              text={shipment}
            />
          ))}
      </GoogleMapReact>

      <div className="absolute w-[156px] bottom-3 left-4">
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={getLocation}
        >
          Get Location
        </Button>
      </div>
    </div>
  );
};

export default TrackingMap;
