import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as API from "@config/url";
import { toast } from "react-toastify";

// Fetch verification data
const get_vendors_verification_data = createAsyncThunk("vendor_verification/get_vendor_verification_data", async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
        return await axios({
            method: "get",
            url: API.getVendorVerificationData,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer "+ token
            },
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

const get_vendor_subscription = createAsyncThunk("vendor_verification/get_vendor_subscription", async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
        return await axios({
            method: "get",
            url: API.getVendorSubscription,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer "+ token
            },
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

const search_directory = createAsyncThunk("miner_verification/search_directory", async (payload, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
        return await axios({
            method: "get",
            url: API.searchDirectory(payload?.search,payload?.type),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer "+ token
            },
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

// Post verification data
const submit_vendor_verification = createAsyncThunk("vendor_verification/submit_vendor_verification", async (payload, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
        return await axios({
            method: "post",
            url: API.createAccount(payload.id),
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "bearer "+ token
            },
            data:payload.formdata,
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

/** create slice */
const slice = createSlice({
    name: "vendor_verification",
    initialState: {
        data: {},
        general:[],
        directory:[],
        status: false,
        loading: false,
        error: null,
        message: null,
    },
    reducers: {},
    extraReducers: {
        // verify otp code
        [submit_vendor_verification.pending]: (state) => {
            state.loading = true;
        },
        [submit_vendor_verification.fulfilled]: (state, { payload }) => {
            state.status = payload.status;
            state.loading = false;
            localStorage.clear()
        },
        [submit_vendor_verification.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },

        [get_vendors_verification_data.pending]: (state) => {
            state.loading = true;
        },
        [get_vendors_verification_data.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.loading = false;
        },
        [get_vendors_verification_data.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
        [get_vendor_subscription.pending]: (state) => {
            state.loading = true;
        },
        [get_vendor_subscription.fulfilled]: (state, { payload }) => {
            state.general = payload.data.docs[0];
            state.loading = false;
        },
        [get_vendor_subscription.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
        [search_directory.pending]: (state) => {
            state.loading = true;
        },
        [search_directory.fulfilled]: (state, { payload }) => {
            state.directory = payload.data;
            state.loading = false;
        },
        [search_directory.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
    },
});



export { submit_vendor_verification, get_vendors_verification_data,get_vendor_subscription,search_directory }
export default slice.reducer;