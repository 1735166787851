const Ban = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4.5C7.6 4.5 4 8.1 4 12.5C4 16.9 7.6 20.5 12 20.5C16.4 20.5 20 16.9 20 12.5C20 8.1 16.4 4.5 12 4.5ZM12 6.5C13.3 6.5 14.5 6.9 15.5 7.6L7.1 16C6.4 15 6 13.8 6 12.5C6 9.2 8.7 6.5 12 6.5ZM12 18.5C10.7 18.5 9.5 18.1 8.5 17.4L16.9 9C17.6 10 18 11.2 18 12.5C18 15.8 15.3 18.5 12 18.5Z"
        fill="#D9E8FC"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default Ban;
