/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useEffect, useState, useContext } from "react";
import GoogleMapReact from "google-map-react";
import { Button } from "@ui";
import { LocationIcon } from "@ui/icons";
import axios from "axios";
import { toast } from "react-toastify";

const LocationPin = () => {
    return (<div> <LocationIcon />  </div>);
};

const Map = ({ location: default_location, zoomLevel, setValue }) => {
    const [currentLocation, setCurrentLocation] = useState({ loaded: false, lat: default_location.lat, lng: default_location.lng });

    /** Handle Longitude Latitude search  */
    const longitude_latitude_search = async (rest) => {
        /** check if rest object has lat and lng */
        if (!rest.lat || !rest.lng) {
            return console.log(rest.lat, rest.lng)
        }
        /** set current location state */
        setCurrentLocation({ loaded: true, lat: rest.lat, lng: rest.lng });
        try {
            /** call goolge to for geocoding */
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${rest.lat},${rest.lng}&key=AIzaSyDA4S3ph7PmMHFQ6mFjN05OWr2t7_i2k18`);
            if (response.data.results.length < 1) {
                throw new Error('No Address found')
            }
            let place = response.data.results[0];
            if (place) {
                let address = "";
                let postcode = "";
                let country = "";
                let state = "";
                let city = "";
                for (const component of place.address_components) {
                    const componentType = component.types[0];
                    switch (componentType) {
                        case "street_number": {
                            address = `${component.long_name} ${address}`;
                            break;
                        }
                        case "route": {
                            address += component.short_name;
                            break;
                        }

                        case "postal_code": {
                            postcode = `${component.long_name}${postcode}`;
                            break;
                        }

                        case "postal_code_suffix": {
                            postcode = `${postcode}-${component.long_name}`;
                            break;
                        }

                        case "country": {
                            country = component.short_name;
                            break;
                        }

                        case "administrative_area_level_1": {
                            state = component.long_name;
                            break;
                        }

                        case "administrative_area_level_2": {
                            city = component.long_name;
                            break;
                        }
                    }
                }
                const lng = place.geometry.location.lng;
                const lat = place.geometry.location.lat;

                setValue("address", address, { shouldValidate: true, shouldDirty: true });
                setValue("postal", postcode === undefined ? '' : postcode, { shouldValidate: true, shouldDirty: true });
                setValue("state", state, { shouldValidate: true, shouldDirty: true });
                setValue("city", city, { shouldValidate: true, shouldDirty: true });
                setValue("country", country, { shouldValidate: true, shouldDirty: true });
                setValue("long", lng, { shouldValidate: true, shouldDirty: true });
                setValue("lat", lat, { shouldValidate: true, shouldDirty: true });
            } else {
                throw 'No Address found'
            }

        } catch (error) {
            return toast.error(error.message);
        }
    }

    /** get Location from browser navigator */
    const getLocationFromBrowserNavigator = () => {
        if (!("geolocation" in navigator)) {
            return toast.error('Geolocation not supported');
        }
        navigator.geolocation.getCurrentPosition((location) => {
            setCurrentLocation({ loaded: true, lat: location.coords.latitude, lng: location.coords.longitude });
            longitude_latitude_search({ lat: location.coords.latitude, lng: location.coords.longitude });
        }, (error) => {
            return toast.error('Geolocation not allowed');
        });
    };

    return (
        <div className="w-full h-full relative">
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyDA4S3ph7PmMHFQ6mFjN05OWr2t7_i2k18", libraries: ["places"] }}
                center={default_location}
                defaultZoom={zoomLevel}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => console.log(map)}
                onClick={longitude_latitude_search}
            >
                <LocationPin lat={currentLocation.lat} lng={currentLocation.lng} text="" />
            </GoogleMapReact>
            <div className="absolute w-[156px] bottom-3 left-4">
                <Button variant="contained" color="primary" type="button" onClick={getLocationFromBrowserNavigator}> Get Location  </Button>
            </div>
        </div>
    );
};

export default Map;
