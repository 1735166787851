/* eslint-disable react/prop-types */
import React, { createContext, useState } from "react";

export const AddressContext = createContext();

export const AddressProvider = ({ children }) => {
	const [addressData, setAddressData] = useState("");
	return (
		<AddressContext.Provider value={[addressData, setAddressData]}>{children}</AddressContext.Provider>
	);
};
