import * as React from "react";

const SvgCheckmail = (props) => (
  <svg
    width={105}
    height={84}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M101.25.75H3.75A3.746 3.746 0 0 0 0 4.5v75a3.746 3.746 0 0 0 3.75 3.75h97.5A3.746 3.746 0 0 0 105 79.5v-75a3.746 3.746 0 0 0-3.75-3.75ZM90.176 9.2 52.5 38.483 14.824 9.2h75.352Zm6.386 4.534v61.078H8.439V13.734l-3.235-2.52 3.223 2.509 40.031 31.125a6.562 6.562 0 0 0 8.05 0l40.056-31.114 3.234-2.52-4.606-5.917h.012l4.606 5.918-3.246 2.52Z"
      fill="#6166B6"
    />
  </svg>
);

export default SvgCheckmail;
