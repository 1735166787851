/* eslint-disable no-unused-vars */
import * as React from "react";
const SvgCopyIcon = (props) => (
  <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 h-[42px] w-[42px] relative gap-1 px-[9px] py-1.5 rounded bg-primary">
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
      preserveAspectRatio="none"
    >
      <path
        d="M17.5 7.30426H14.5L11.5 4.17383H4.5V17.739H10.5V20.8695H20.5V10.4347L17.5 7.30426ZM11.5 5.21731L13.5 7.30426H11.5V5.21731ZM5.5 16.6956V5.21731H10.5V8.34774H13.5V16.6956H5.5ZM19.5 19.826H11.5V17.739H14.5V8.34774H16.5V11.4782H19.5V19.826ZM17.5 10.4347V8.34774L19.5 10.4347H17.5Z"
        fill="white"
      ></path>
    </svg>
  </div>
);

export default SvgCopyIcon;
