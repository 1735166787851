/* eslint-disable react/prop-types */
import * as React from "react";

const Hamburger = ({ onClick }) => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      d="M0.00805664 1.008C0.00805664 0.456 0.440057 0 0.992057 0H15.0081C15.5601 0 15.9921 0.432 15.9921 1.008C15.9921 1.56 15.5601 1.992 15.0081 1.992H0.992057C0.440057 1.992 0.00805664 1.56 0.00805664 1.008ZM0.00805664 6C0.00805664 5.448 0.440057 4.992 0.992057 4.992H15.0081C15.5601 4.992 15.9921 5.448 15.9921 6C15.9921 6.552 15.5601 7.008 15.0081 7.008H0.992057C0.440057 7.008 0.00805664 6.552 0.00805664 6ZM0.00805664 10.992C0.00805664 10.44 0.440057 10.008 0.992057 10.008H15.0081C15.5601 10.008 15.9921 10.44 15.9921 10.992C15.9921 11.544 15.5601 12 15.0081 12H0.992057C0.440057 12 0.00805664 11.544 0.00805664 10.992Z"
      fill="#1C2E45"
      fillOpacity="0.6"
    />
  </svg>
);

export default Hamburger;
