const CogIcon = ({color}) => {
  const iconColor = color ? color : "#D9E8FC";
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 13.5V11.5L18.3 10.9C18.1 10.3 17.9 9.7 17.6 9.1L18.4 7.5L17 6.1L15.4 6.9C14.9 6.6 14.3 6.3 13.6 6.2L13 4.5H11L10.4 6.2C9.8 6.4 9.2 6.6 8.7 6.9L7.1 6.1L5.6 7.6L6.4 9.2C6.1 9.7 5.9 10.3 5.7 10.9L4 11.5V13.5L5.7 14.1C5.9 14.7 6.1 15.3 6.4 15.9L5.6 17.5L7 18.9L8.6 18.1C9.1 18.4 9.7 18.7 10.4 18.8L11 20.5H13L13.6 18.8C14.2 18.6 14.8 18.4 15.4 18.1L17 18.9L18.4 17.5L17.6 15.9C17.9 15.4 18.2 14.8 18.3 14.1L20 13.5V13.5ZM12 16.5C9.8 16.5 8 14.7 8 12.5C8 10.3 9.8 8.5 12 8.5C14.2 8.5 16 10.3 16 12.5C16 14.7 14.2 16.5 12 16.5Z"
        fill={iconColor}
        fillOpacity="0.7"
      />
      <path
        d="M14.6 12.4C14.6 13.781 13.481 14.9 12.1 14.9C10.719 14.9 9.59998 13.781 9.59998 12.4C9.59998 11.019 10.719 9.89999 12.1 9.89999C13.481 9.89999 14.6 11.019 14.6 12.4Z"
        fill={iconColor}
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default CogIcon;
