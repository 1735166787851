/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";

const Timer = ({ children }) => (
  <div className="flex flex-col items-center justify-center bg-secondary py-2 px-4 max-w-[53px] text-white">
    {children}
  </div>
);

const Countdown = ({ date }) => {
  const [timerDays, setTimerDays] = useState();
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();

  let interval;

  const startTimer = () => {
    const countDownDate = new Date(date).getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();

      const distance = countDownDate - now;

      const days = Math.floor(distance / (24 * 60 * 60 * 1000));
      const hours = Math.floor(
        (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);

      if (distance < 0) {
        // Stop Timer
        clearInterval(interval.current);
      } else {
        // Update Timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    });
  };

  useEffect(() => {
    startTimer();
  }, [timerDays,timerHours,timerMinutes,timerSeconds]);
  return (
    <div className="flex gap-2 mt-2 mb-6">
      <Timer>
        <span className="text-2xl">{timerDays}</span>
        <span className="text-[11px] font-extralight">days</span>
      </Timer>
      <Timer>
        <span className="text-2xl">{timerHours}</span>
        <span className="text-[11px] font-extralight">hrs</span>
      </Timer>
      <Timer>
        <span className="text-2xl">{timerMinutes}</span>
        <span className="text-[11px] font-extralight">mins</span>
      </Timer>
      <Timer>
        <span className="text-2xl">{timerSeconds}</span>
        <span className="text-[11px] font-extralight">secs</span>
      </Timer>
    </div>
  );
};

export default Countdown;
