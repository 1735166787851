import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "@config/url";
import { toast } from "react-toastify";
import axios from "@config/axios-instance";

const fetchDashboardRecordsCard = createAsyncThunk(
  "offtaker/fetchDashboardRecordsCard",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchOfftakerDashboardRecordsCard);

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchDashboardActivities = createAsyncThunk(
  "offtaker/fetchDashboardActivities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchDashboardActivities);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchDashboardAnalyticsChart = createAsyncThunk(
  "offtaker/fetchDashboardAnalyticsChart",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchOfftakerAnalyticsChart);

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const analyticsSlice = createSlice({
  name: "offtaker_analytics",
  initialState: {
    record_card: {
      total_transactions: 0,
      total_bids: 0,
      total_bids_won: 0,
      total_offers: 0,
    },
    activities: [],
    analytics: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    //fetch records card
    [fetchDashboardRecordsCard.pending]: (state) => {
      state.loading = true;
    },
    [fetchDashboardRecordsCard.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.record_card.total_transactions = payload.data.total_transactions;
      state.record_card.total_bids = payload.data.total_bids;
      state.record_card.total_bids_won = payload.data.total_bids_won;
      state.record_card.total_offers = payload.data.total_offers;
    },
    [fetchDashboardRecordsCard.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    //fetch dashboard activities
    [fetchDashboardActivities.pending]: (state) => {
      state.loading = true;
    },
    [fetchDashboardActivities.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.activities = payload.data.activities;
    },
    [fetchDashboardActivities.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    //fetch analytics
    [fetchDashboardAnalyticsChart.pending]: (state) => {
      state.loading = true;
    },
    [fetchDashboardAnalyticsChart.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.analytics = payload.data.data;
    },
    [fetchDashboardAnalyticsChart.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
  },
});

export {
  fetchDashboardRecordsCard,
  fetchDashboardActivities,
  fetchDashboardAnalyticsChart,
};

export default analyticsSlice.reducer;
