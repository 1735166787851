/* eslint-disable react/prop-types */
import HandIcon from "@ui/icons/HandIcon";
import { Stat } from "components/ui/InterationsStats";

const FundingStats = ({ counts }) => {
  return (
    <>
      <Stat>
        <div>
          <p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
            ALL FUNDINGS
          </p>
          <p className="text-2xl text-[#374146]">{counts?.all_fundings}</p>
        </div>
        <div>
          <HandIcon width="41" height="41" fill="#6166B6" opacity="1" />
        </div>
      </Stat>
      <Stat>
        <div>
          <p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
            ACTIVE FUNDINGS
          </p>
          <p className="text-2xl text-[#374146]">{counts?.active_fundings}</p>
        </div>
        <div>
          <HandIcon width="41" height="41" fill="#6166B6" opacity="1" />
        </div>
      </Stat>
      <Stat>
        <div>
          <p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
            FUNDINGS TODAY
          </p>
          <p className="text-2xl text-[#374146]">{counts?.fundings_today}</p>
        </div>
        <div>
          <HandIcon width="41" height="41" fill="#6166B6" opacity="1" />
        </div>
      </Stat>
    </>
  );
};
export default FundingStats;
