/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { OffTakerTransactionInfoUrl } from "config/app-url";
import {
  fetchPaginatedTransactions,
  getAllOfftakerTransactions,
  setTransactionSearchValue,
} from "store/slices/offtaker-slice";
import OfftakerStats from "components/ui/OfftakerStats";
import { offtakersTransactionsTableInfo } from "constants/index";
import Table from "components/Table";

const Transactions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loading,
    transactions: { data, counts, pagination, search, filter },
  } = useSelector((state) => state.offtakers);
  const handleViewDetails = (record) => {
    navigate(OffTakerTransactionInfoUrl, {
      state: { status: record?.status[0]?.status, id: record?._id },
    });
  };
  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedTransactions({
        page: pagination[option + "Page"],
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedTransactions({
        page: 1,
        per_page: e.target.value,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(setTransactionSearchValue(e.target.value));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(getAllOfftakerTransactions());
    }
    dispatch(setTransactionSearchValue(search));
    dispatch(
      fetchPaginatedTransactions({
        page: 1,
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    if (tab == "All") {
      if (search == "") {
        dispatch(getAllOfftakerTransactions());
      } else {
        dispatch(
          fetchPaginatedTransactions({
            page: 1,
            per_page: pagination.limit,
            search: search,
            filter: "",
          })
        );
      }
    } else {
      dispatch(
        fetchPaginatedTransactions({
          page: 1,
          per_page: pagination.limit,
          search: search,
          filter: tab,
        })
      );
    }
  };
  useEffect(() => {
    dispatch(getAllOfftakerTransactions());
  }, []);

  return (
    <div>
      <div className="grid grid-cols-3 lg:grid gap-2 sm:grid">
        <OfftakerStats statData={counts} />
      </div>
      {/* tabs */}

      <div className="mt-4">
        <Table
          tableInfo={offtakersTransactionsTableInfo}
          isViewDetails={true}
          isStatus={true}
          handleViewDetails={handleViewDetails}
          navigate={navigate}
          data={data}
          pagination={pagination}
          handleSetPerPage={handleSetPerPage}
          handleSearchSubmit={handleSearchSubmit}
          handleChangePage={handleChangePage}
          handleTabChange={handleTabChange}
          loading={loading}
          handleSearch={handleSearch}
          fetchDefaultData={getAllOfftakerTransactions}
          search={search}
        />
      </div>
    </div>
  );
};

export default Transactions;
