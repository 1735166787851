/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import { Input, Select, TextArea, Checkbox, DatePicker } from "@ui";
import { FileUploader } from "react-drag-drop-files";
import { Upload } from "@atoms/icons";
import PaperClip from "@ui/icons/PaperClip";
import { unit_of_measurement, shipment_options } from "constants/index";
import {
  get_mineral_data,
  get_sdgGoal_data,
  get_mineType_data,
} from "@slices/onboarding/miner.slice";
import { ErrorMessage } from "@components";
import { getServiceOptions } from "store/slices/miners/vendor-slice";
import {
  fetchAllActiveMines,
  //  searchMineByNameOrType,
} from "@slices/miners/mines-slice";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { productSchema } from "@validations/schema";

import { storeInventoryProduct } from "@slices/miners/inventory-slice";
import { toast } from "react-toastify";

const fileTypes = ["JPG", "PNG"];

const FORM_DATA_KEY = "formData";

const defaultValues = {
  product_name: "",
  product_image: [],
  mine_id: "",
  mineral_slug: "",
  product_description: "",
  unit_of_measurement: "",
  product_quantity: "",
  product_treatment_cost: "",
  product_price: "",
  // product_total_value: "",
  duration: [null, null],
  product_shipment_options: [],
};

export const usePersistForm = ({ value, localStorageKey }) => {
  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value, localStorageKey]);
};

const Description = ({ childFunc, setDisable, handleCheck, termsChecked }) => {
  const dispatch = useDispatch();
  const { inventory: inventoryData, inventoryProduct } = useSelector(
    (state) => state.inventories
  );

  const [productImage, setProductImage] = useState([]);

  const getSavedData = () => {
    let formData = localStorage.getItem(FORM_DATA_KEY);
    if (formData) {
      try {
        formData = JSON.parse(formData);
      } catch (err) {
        console.log(err);
      }
      return formData;
    }
    return defaultValues;
  };
  const {
    control,
    register,
    trigger,
    formState: { errors, isDirty, isValid },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(productSchema),
    defaultValues: getSavedData(),
    mode: "all",
  });

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const unitOfMeasurement = watch("unit_of_measurement");

  const [product_treatment_cost, product_quantity, product_price] = watch([
    "product_treatment_cost",
    "product_quantity",
    "product_price",
  ]);

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const productTotalValue =
    product_treatment_cost * product_quantity +
    product_price * product_quantity;

  // console.log(getValues(), "values")
  useEffect(() => {
    // console.log({isDirty, isValid})
    setDisable(!isValid);
  }, [isDirty, isValid]);
  useEffect(() => {
    dispatch(fetchAllActiveMines());
    dispatch(getServiceOptions());
    dispatch(get_mineral_data());
    dispatch(get_sdgGoal_data());
    dispatch(get_mineType_data());
    if (Object.keys(inventoryProduct).length > 0) {
      setProductImage(inventoryProduct?.product_image);
    }
  }, [dispatch]);

  const { minerals } = useSelector((state) => state.miner_verification);
  const { inventory_rate } = useSelector((store) => store.miner_vendors);

  const { mines } = useSelector((state) => state.mines);
  let minesData = [];
  if (mines?.length > 0) {
    mines?.forEach((y) => {
      minesData.push({
        name: y.mine_name,
        id: y._id,
        slug: y.mine_name.toLowerCase(),
      });
    });
  }

  useEffect(() => {
    childFunc.current = () => {
      const updatedData = {
        ...getSavedData(),
        product_image: productImage,
        product_price: Number(getSavedData().product_price),
        product_quantity: Number(getSavedData().product_quantity),
        product_treatment_cost: Number(getSavedData().product_treatment_cost),
        product_total_value: productTotalValue,
      };
      trigger();
      dispatch(storeInventoryProduct(updatedData));
      return updatedData;
    };
  });

  useEffect(() => {
    let formData = JSON.parse(localStorage.getItem(FORM_DATA_KEY));
    if (formData !== null) {
      if (formData.duration) {
        const [startDate, endDate] = formData.duration;
        if (startDate !== null && endDate !== null)
          setDateRange([new Date(startDate), new Date(endDate)]);
      }
    }
  }, [inventoryData]);

  usePersistForm({ value: watch(), localStorageKey: FORM_DATA_KEY });

  const handleFileChange = async (FileList) => {
    /** Declare form data */
    const MAX_FILE_SIZE = 30720; // 30MB
    const fileSizeKiloBytes = FileList.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("File size is greater than maximum limit");
      return;
    }
    const _FormData = new FormData();
    _FormData.append("file", FileList);
    let files = [];
    files.push(FileList);
    setValue("product_image", files);
    setProductImage(files);
    /** set upload type */
    _FormData.append("type", "add_inventory");
    /** Dispatch files to backend */
  };

  const handleCheckboxChange = (event, data) => {
    /* check if the box has already been selected */
    let optionExists = data.find((opt) => opt === event.target.value);
    /* if the box has already been seleced */
    if (optionExists) {
      /* remove option from checkbox list */
      const updatedOptions = data.filter((opt) => opt !== optionExists);
      setValue("product_shipment_options", updatedOptions);
      return;
    }
    /* add option to checkbox list if it hasn't been selected already */
    setValue("product_shipment_options", [...data, event.target.value]);
  };

  let files = watch("product_image");

  /** Used to remove a file after upload from state */
  const deleteTag = (id) => {
    setValue("product_image", []);
    files = files.filter((file) => file.name !== id);
    setProductImage([]);
  };

  return (
    <>
      <div className="max-w-[796px] mx-auto">
        <h2 className="text-heading text-2xl mb-2">Description</h2>
        <p className="text-ts font-light my-2">
          Enter the details for the mineral resources as specified below.
        </p>
        <div className="my-6">
          <h3 className="my-2 text-lg text-heading">Product details</h3>
          {/* form */}
          <Input
            type="text"
            label="Product name"
            name="product_name"
            {...register("product_name")}
          />
          {errors.product_name && (
            <ErrorMessage>{errors.product_name?.message}</ErrorMessage>
          )}
          {/* image uploader here */}
          <div>
            <p className="font-light text-sm text-ts mb-2">
              Product image (30mb max)
            </p>
            <FileUploader
              className="upload tab"
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
              accept="image/*"
              children={
                <div className="flex items-center gap-32 border border-dashed rounded-lg border-gray-light mb-4">
                  <span className="text-primary bg-dark-gray-300 py-2.5 px-4 rounded m-4">
                    Upload image
                  </span>
                  <div className="flex items-center gap-2">
                    <Upload className="mr-2" />
                    <span className="font-light text-ts">Drop image here</span>
                  </div>
                </div>
              }
            />
            {errors.product_image && (
              <ErrorMessage>{errors.product_image?.message}</ErrorMessage>
            )}
            <div>
              {/* {product_image && ( */}
              <p className="h-2 flex items-center text-primary my-2 text-xs mb-6 lg:mb-[16px] align-center">
                <span>{<PaperClip />}</span>
                <span>
                  {productImage && productImage[0]
                    ? `${productImage[0]?.name}`
                    : "no files uploaded"}
                </span>
                <button
                  className="text-red text-[16px] px-2"
                  onClick={() => deleteTag(productImage[0].name)}
                >
                  {" "}
                  x{" "}
                </button>
              </p>
              {/* )} */}
            </div>
          </div>

          <div className="flex flex-col lg:grid grid-cols-2 gap-4">
            <div>
              <Controller
                name="mine_id"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Source Mine"
                    placeholder="Select source"
                    options={minesData}
                    {...field}
                  />
                )}
              />
              {errors.mine_id && (
                <ErrorMessage>{errors.mine_id?.message}</ErrorMessage>
              )}
            </div>
            <div>
              <Controller
                name="mineral_slug"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Mineral type"
                    placeholder="Select mineral type"
                    options={minerals}
                    {...field}
                  />
                )}
              />
              {errors.mineral_slug && (
                <ErrorMessage>{errors.mineral_slug?.message}</ErrorMessage>
              )}
            </div>
          </div>
          <TextArea
            type="text"
            label="Description"
            name="product_description"
            {...register("product_description")}
          />
          {errors.product_description && (
            <ErrorMessage>{errors.product_description?.message}</ErrorMessage>
          )}
          <div className="flex flex-col lg:grid grid-cols-2 gap-4">
            <div>
              <Controller
                name="unit_of_measurement"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Unit of measurement"
                    placeholder="Select unit"
                    options={unit_of_measurement}
                    getOptionLabel={(option) => option.slug}
                    getOptionValue={(option) => option.id}
                    {...field}
                  />
                )}
              />
              {errors.unit_of_measurement && (
                <ErrorMessage>
                  {errors.unit_of_measurement?.message}
                </ErrorMessage>
              )}
              <Input
                type="number"
                label="Treatment cost"
                onKeyPress={preventMinus}
                name="product_treatment_cost"
                isMoneyInput={true}
                postfix={`/${unitOfMeasurement?.id ?? ""}`}
                {...register("product_treatment_cost")}
              />
              {errors.product_treatment_cost && (
                <ErrorMessage>
                  {errors.product_treatment_cost?.message}
                </ErrorMessage>
              )}
              <Input
                type="number"
                label="Total value"
                onKeyPress={preventMinus}
                name="product_total_value"
                isMoneyInput={true}
                value={productTotalValue}
                readOnly
              />
            </div>
            <div>
              <Input
                type="number"
                label="Quantity"
                onKeyPress={preventMinus}
                name="product_quantity"
                {...register("product_quantity")}
              />
              {errors.product_quantity && (
                <ErrorMessage>{errors.product_quantity?.message}</ErrorMessage>
              )}
              <Input
                type="number"
                name="product_price"
                onKeyPress={preventMinus}
                label="Price"
                isMoneyInput={true}
                postfix={`/${unitOfMeasurement?.id ?? ""}`}
                {...register("product_price")}
              />
              {errors.product_price && (
                <ErrorMessage>{errors.product_price?.message}</ErrorMessage>
              )}

              <Controller
                name="duration"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label="Duration"
                    placeholder="Select duration"
                    dateFormat="d MMM yyyy"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(data) => {
                      setDateRange(data);
                      field.onChange(data);
                    }}
                  />
                )}
              />
            </div>
          </div>
          {/* shippment options starts here */}
          <h2 className="font-sans font-normal text-heading text-lg mt-8 mb-4">
            Shipment options
          </h2>
          <p className="font-light text-ts text-sm mb-4">
            Shipment method (Select all that apply)
          </p>
          <ul>
            {shipment_options?.map((option) => (
              <li key={option.id} className="flex items-center gap-2">
                {/* <Checkbox
                  name="product_shipment_options"
                  onChange={handleCheckboxChange}
                  value={option.id}
                  size="large"
                  checked={
                    !!data.product_shipment_options.find(
                      (opt) => opt === option.id
                    )
                  }
                /> */}
                <Controller
                  name="product_shipment_options"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        value={option.id}
                        {...register("product_shipment_options")}
                        onChange={(e) =>
                          handleCheckboxChange(e, watch(field.name))
                        }
                        size="large"
                        checked={
                          !!watch(field.name).find((opt) => opt === option.id)
                        }
                        // {...field}
                      />
                    );
                  }}
                />
                <span className="text-light font-light">{option.slug}</span>
              </li>
            ))}
            {errors.product_shipment_options && (
              <ErrorMessage>
                {errors.product_shipment_options?.message}
              </ErrorMessage>
            )}
          </ul>
          {/* shippment options ends here*/}

          {/* terms and conditions starts here */}
          <h2 className="font-sans font-normal text-heading text-lg mt-8 mb-4">
            Terms and conditions
          </h2>
          <div className="flex gap-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4C7.6 4 4 7.6 4 12C4 16.4 7.6 20 12 20C16.4 20 20 16.4 20 12C20 7.6 16.4 4 12 4ZM13 17H11V10H13V17ZM13 9H11V7H13V9Z"
                fill="#1C3454"
                fillOpacity="0.26"
              />
            </svg>
            <p className="font-light text-ts text-sm mb-4">
              Adding a new product listing attracts a listing fee charge of{" "}
              <span className="text-heading text-base font-normal">${inventory_rate}</span>
            </p>
          </div>

          {/* ends here */}
        </div>
      </div>
    </>
  );
};

export default Description;
