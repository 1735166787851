import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as API from "@config/url";
import { toast } from "react-toastify";

/** Verify OTP Code */
const verify_otp_code = createAsyncThunk("otp/verify_otp_code", async (data, { rejectWithValue }) => {
    try {
        return await axios({
            method: "post",
            url: API.postVerifyOtp,
            headers: {
                "Content-Type": "application/json",
            },
            data,
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

/** Resend OTP Code */
const resend_otp_code = createAsyncThunk("otp/resend_otp_code", async (data, { rejectWithValue }) => {
    try {
        return await axios({
            method: "post",
            url: API.postRendOtp,
            headers: { "Content-Type": "application/json" },
            data,
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

/** create slice */
const slice = createSlice({
    name: "otp",
    initialState: {
        data: {},
        status: false,
        loading: false,
        error: null,
        message: null,
    },
    reducers: {},
    extraReducers: {
        // verify otp code
        [verify_otp_code.pending]: (state) => {
            state.loading = true;
        },
        [verify_otp_code.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.loading = false;
            // localStorage.setItem("token", JSON.stringify(payload.data.token.token));
        },
        [verify_otp_code.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },

        //resend otp code
        [resend_otp_code.pending]: (state) => {
            state.loading = true;
        },
        [resend_otp_code.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.loading = false;
            toast.info(payload.data.message);
        },
        [resend_otp_code.rejected]: (state, { payload }) => {
            localStorage.clear();
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
    },
});

export { verify_otp_code, resend_otp_code }
export default slice.reducer;