/* eslint-disable react/prop-types */
import { Gavel, Megaphone, MoneyIcon, ScrollIcon } from "./icons";

const Stat = ({ bgColor, children }) => {
  const backgroundColor = bgColor ? bgColor : "bg-white";
  const boxShadow = bgColor && "shadow-Stat";
  return (
    <div
      className={`flex items-center justify-between border border-solid border-dark-gray-100 px-6 py-5 rounded-lg ${backgroundColor} ${boxShadow}`}
    >
      {children}
    </div>
  );
};

const StatsOfftaker = ({ record_card }) => {
  return (
    <>
      <Stat>
        <div>
          <p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
            TOTAL BIDS
          </p>
          <p className="text-2xl text-[#374146]">{record_card.total_bids}</p>
        </div>
        <div>
          <Gavel h="41" w="41" color="#6166B6" opacity="1" />
        </div>
      </Stat>
      <Stat>
        <div>
          <p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
            BIDS WON
          </p>
          <p className="text-2xl text-[#374146]">
            {record_card.total_bids_won}
          </p>
        </div>
        <div>
          <ScrollIcon h="41" w="41" color="#6166B6" opacity="1" />
        </div>
      </Stat>
      <Stat>
        <div>
          <p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
            All Transactions
          </p>
          <p className="text-2xl text-[#374146]">
            {record_card.total_transactions}
          </p>
        </div>
        <div>
          <MoneyIcon />
        </div>
      </Stat>

      <Stat>
        <div>
          <p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
            OFFERS
          </p>
          <p className="text-2xl text-[#374146]">{record_card.total_offers}</p>
        </div>
        <div>
          <Megaphone h="41" w="41" color="#6166B6" opacity="1" />
        </div>
      </Stat>
    </>
  );
};

export default StatsOfftaker;
