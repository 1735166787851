const BellIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7 15.4C16.2 15.2 16 14.7 16 14.2V9C16 9 16 6.6 13 6.1V5C13 5 13.1 4 12 4C10.9 4 11 5 11 5V6.1C8 6.6 8 9 8 9V14.2C8 14.7 7.7 15.2 7.3 15.4L6 16V18H10C10 18 9.9 20 12 20C14.1 20 14 18 14 18H18V16L16.7 15.4ZM17 17H7V16.6L7.7 16.2C8.5 15.9 9 15.1 9 14.2V9C9 8.9 9 7.4 11.2 7.1L12 6.9L12.8 7C14.8 7.4 15 8.7 15 9V14.2C15 15.1 15.5 15.9 16.3 16.3L17 16.7V17Z"
        fill="#1C2E45"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default BellIcon;
