import React, { useState } from "react";
import InterationsStats from "@ui/InterationsStats";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPaginatedBids,
  getBids,
  setBidSearchValue,
} from "store/slices/offtaker-slice";
import { offtakersBidsTableInfo } from "constants/index.js";
import Table from "components/Table";

const Bids = () => {
  const [isVerified] = useState(true);
  const [underReview] = useState(false);
  const dispatch = useDispatch();

  const {
    loading,
    bids: { data: bids, counts, pagination, search, filter },
  } = useSelector((store) => store?.offtakers);

  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedBids({
        page: pagination[option + "Page"],
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };

  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedBids({
        page: 1,
        per_page: e.target.value,
        search: search,
        filter: filter,
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(setBidSearchValue(e.target.value));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(getBids());
    }
    dispatch(setBidSearchValue(search));
    dispatch(
      fetchPaginatedBids({
        page: 1,
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    if (tab == "All") {
      if (search == "") {
        dispatch(getBids());
      } else {
        dispatch(
          fetchPaginatedBids({
            page: 1,
            per_page: pagination.limit,
            search: search,
            filter: "",
          })
        );
      }
    } else {
      dispatch(
        fetchPaginatedBids({
          page: 1,
          per_page: pagination.limit,
          search: search,
          filter: tab.toLowerCase(),
        })
      );
    }
  };

  return (
    <div>
      <div className="grid grid-cols-3 lg:grid gap-2 sm:grid">
        <InterationsStats
          isVerified={isVerified}
          underReview={underReview}
          won={true}
          counts={counts}
        />
      </div>
      {/* tabs */}
      <div className="mt-4">
        <Table
          tableInfo={offtakersBidsTableInfo}
          isViewDetails={false}
          isStatus={true}
          data={bids}
          pagination={pagination}
          handleSetPerPage={handleSetPerPage}
          handleSearchSubmit={handleSearchSubmit}
          handleChangePage={handleChangePage}
          handleTabChange={handleTabChange}
          loading={loading}
          handleSearch={handleSearch}
          fetchDefaultData={getBids}
          search={search}
        />
      </div>
    </div>
  );
};

export default Bids;
