/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/** @format */

import React, { useState } from "react";
import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import { SuccessModal } from "./successModal";
import { useDispatch } from "react-redux";
import { cancelSubscription } from "@slices/wallet-slice";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

// eslint-disable-next-line react/prop-types
export const CancelSubscription = ({ setCancel, open = null, data = null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const removeSubscription = async () => {
    try {
      setLoading(true);
      const response = await dispatch(cancelSubscription());
      if (response.payload.status === 201) {
        if (
          response.payload?.data?.code == 422 ||
          response?.payload?.data.code == 400
        ) {
          setLoading(false);
          setCancel(false);
          toast.error(response.payload.data.message);
        } else {
          setLoading(false);
          setCancel(false);
          setIsLoading(true);
        }
      } else {
        setLoading(false);
        setCancel(false);
        toast.error(response.payload.data.message);
        console.log("error from then", response);
      }
    } catch (error) {
      setCancel(false);
      toast.error(error);
      // console.log("error", error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center mb-4">
            <h1 className="text-xl mb-4 text-[#FF4E44]">
              Cancel Subscription ?
            </h1>

            {/* <h1 className="text-xl mb-4">Role creation successfull</h1> */}
            <p className="font-light mt-4">
              We're sorry to see you go. if there's anything we can do to make
              your experience better, please let us know. We'll be here if you
              ever want to reactivate your subscription.
            </p>
            <div className="flex items-center justify-between mt-8">
              <button
                onClick={() => setCancel(!open)}
                className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
              >
                Stay with us
              </button>
              <div>
                <Button
                  color="danger"
                  onClick={() => {
                    removeSubscription();
                  }}
                >
                  {" "}
                  {loading ? "Processing..." : "Continue cancellation"}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <div>
        <SuccessModal open={isLoading} showStatus={setIsLoading} type="Cancel" />
      </div>
    </>
  );
};
