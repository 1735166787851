/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectField } from "components/ui/Inputs";
import Skeleton from "@components/auctions/Skeleton";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  getadminmineral_data,
  get_sdgGoal_data,
  get_mineType_data,
} from "@slices/onboarding/miner.slice";
import { Formik, FieldArray } from "formik";
import MenuItem from "@mui/material/MenuItem";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { mineSchema } from "@validations/schema";
import { Map, LongLat, SearchLocation } from "@pages/onboarding";
import {
  fetchAdminMineByID,
  fetchAdminMineData,
  updateAdminMines,
} from "@slices/admin-slice";
import { Card, Input, TextArea, Button } from "@ui";
import Select from "@mui/material/Select";
import { ErrorMessage } from "@components";

const EditMine = () => {
  const navigate = useNavigate();
  const { funding } = useSelector((state) => state.admin);
  const { loading, mineData } = useSelector((state) => state.admin);
  const location = useLocation();
  const [location_type, setLocationType] = useState("");
  const [gps, setGps] = useState(false);
  const [address, setAddress] = useState(false);
  const [longitude_latitude, setLongitudeLatitude] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(mineSchema),
    mode: "all",
  });

  const handleChangee = (event, arrayHelpers, locations) => {
    const {
      target: { value },
    } = event;

    const isFound = locations.some((element) => {
      return element.name === value.name;
    });

    if (!isFound) {
      arrayHelpers.push(value);
    }
  };

  const { minerals, sdg_goals } = useSelector(
    (state) => state.miner_verification
  );

  useEffect(() => {
    dispatch(getadminmineral_data());
    dispatch(get_sdgGoal_data());
    dispatch(get_mineType_data());
  }, [dispatch]);

  /** Update on Location type */
  useEffect(() => {
    /** switch location type */
    switch (location_type) {
      case "address":
        setAddress(true);
        setLongitudeLatitude(false);
        setGps(false);
        break;
      case "longitude_latitude":
        setLongitudeLatitude(true);
        setAddress(false);
        setGps(false);
        break;
      case "gps":
        setGps(true);
        setAddress(false);
        setLongitudeLatitude(false);
        break;
      default:
        break;
    }
  }, [location_type]);

  /** Onchange function of Location Type */
  const onChangeLocationType = (location_type) => {
    setValue("address", "", { shouldValidate: true, shouldDirty: true });
    setValue("postal", "", { shouldValidate: true, shouldDirty: true });
    setValue("state", "", { shouldValidate: true, shouldDirty: true });
    setValue("city", "", { shouldValidate: true, shouldDirty: true });
    setValue("long", "", { shouldValidate: true, shouldDirty: true });
    setValue("lat", "", { shouldValidate: true, shouldDirty: true });
    setValue("country", "", { shouldValidate: true, shouldDirty: true });
    return setLocationType(location_type);
  };

  useEffect(() => {
    dispatch(
      fetchAdminMineByID({
        id: location?.state?.id,
      })
    );
    dispatch(fetchAdminMineData());
  }, []);

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const initialValues = {
    mine_name: !funding ? "" : funding?.mine_name,
    mine_type: !funding ? "" : funding?.mine_type,
    description: !funding ? "" : funding.description,
    company_name: !funding ? "" : funding.company_name,
    country: !funding ? "" : funding?.country?.iso_code,
    state: !funding ? "" : funding?.state,
    location: {
      address: !funding ? "" : funding?.location?.address,
      long: !funding ? "" : funding?.location?.longitude,
      lat: !funding ? "" : funding?.location?.latitude,
      postal_code: !funding ? "" : funding?.location?.postal_code,
    },
    reserves: !funding ? "" : funding.reserves,
    status: !funding ? "" : funding.status,
    minerals: !funding ? [] : funding.mineral_type,
    sdg_goals: !funding ? [] : funding.sdg_goals,
  };

  return Object.keys(funding).length == 0 ? (
    <Skeleton />
  ) : (
    <Card>
      <div className="max-w-[796px] mx-auto">
        <h2 className="text-heading text-2xl my-2">Edit mine</h2>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            mineSchema={mineSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              try {
                const { ...payload } = values;
                const response = await dispatch(
                  updateAdminMines({ id: location?.state?.id, data: payload })
                );

                if (response.payload.status === 200) {
                  navigate("/admin/listings/mines");
                  toast.success(response.payload.data.message);
                } else {
                  toast.error(response.payload.msg);
                  console.log("error from then", response);
                }
              } catch (error) {
                toast.error("An error occured");
                console.log(error);
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              handleChange,
              isSubmitting,
              handleSubmit,
              setFieldValue,
              errors,
            }) => (
              <div className="my-6">
                <h3 className="my-2 text-lg text-heading">Mine details</h3>
                {/* form */}
                <form onSubmit={handleSubmit}>
                  <div className="grid lg:grid-cols-2 sm:grid gap-1">
                    <div>
                      <Input
                        label="Mine name"
                        type="text"
                        name="mine_name"
                        value={values.mine_name}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mt-1 w-full mr-4">
                      <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                        Mine type
                      </p>
                      <SelectField
                        items={[
                          "Open Surface",
                          "In-situ",
                          "Underground",
                          "Placer",
                        ]}
                        name="mine_type"
                        value={values.mine_type}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <TextArea
                    label="Description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  />
                  <Input
                    label="Company name"
                    type="text"
                    name="company_name"
                    value={values.company_name}
                    onChange={handleChange}
                  />

                  <div className="w-full mt-2">
                    <label
                      className="block text-xs mb-2  text-lightgray"
                      htmlFor="address"
                    >
                      Location type
                    </label>
                    <select
                      className="bg-fill cursor-pointer border-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded text-input py-2 px-4 text-sm"
                      id="address"
                      type="dropdown"
                      onChange={(e) => onChangeLocationType(e.target.value)}
                    >
                      <option
                        className="bg-fill cursor-pointer border-none"
                        value="address"
                        name="type"
                      >
                        Address
                      </option>
                      <option value="longitude_latitude" name="type">
                        Longitude and Latitude
                      </option>
                      <option value="gps" name="type">
                        GPS
                      </option>
                    </select>
                  </div>

                  {gps && (
                    <div className="w-full mt-3">
                      <div className="h-96 my-4 ">
                        <Map
                          location={{
                            address:
                              "1600 Amphitheatre Parkway, Mountain View, california.",
                            lat: 37.42216,
                            lng: -122.08427,
                          }}
                          zoomLevel={17}
                          setValue={setValue}
                        />
                      </div>
                    </div>
                  )}

                  {longitude_latitude && (
                    <div className="flex flex-wrap -mx-2">
                      <LongLat setValue={setValue} errors={errors} />
                    </div>
                  )}

                  {/* Address */}
                  <div className="w-full mt-3">
                    <label
                      className="block text-xs mb-2  text-lightgray"
                      htmlFor="address"
                    >
                      Address
                    </label>
                    {values.location.address == "" ? (
                      <SearchLocation setValue={setValue} />
                    ) : (
                      <Input
                        type="text"
                        name="address"
                        value={values.location.address}
                        onChange={handleChange}
                      />
                    )}
                    {errors.address && (
                      <ErrorMessage>{errors.address?.message}</ErrorMessage>
                    )}
                  </div>

                  {/* Longitude && Latitude display this if longitude and latitude is not selected */}
                  {!longitude_latitude && (
                    <div className="flex flex-wrap -mx-2">
                      {/** Longitude */}
                      <div className=" media mt-4 px-3">
                        <label
                          className="block text-xs text-lightgray"
                          htmlFor="longitude"
                        >
                          {" "}
                          Longitude{" "}
                        </label>
                        <Input
                          type="text"
                          name="long"
                          value={values.location.long}
                          {...register("long")}
                          readOnly
                        />
                        {errors.long && (
                          <ErrorMessage>{errors.long?.message}</ErrorMessage>
                        )}
                      </div>
                      {/** Latitude */}
                      <div className=" media mt-4 px-3">
                        <label
                          className="block text-xs text-lightgray"
                          htmlFor="latitude"
                        >
                          Latitude
                        </label>
                        <Input
                          type="text"
                          name="lat"
                          value={values.location.lat}
                          {...register("lat")}
                          readOnly
                        />
                        {errors.lat && (
                          <ErrorMessage>{errors.lat?.message}</ErrorMessage>
                        )}
                      </div>
                    </div>
                  )}

                  {/** Country && Postal code */}
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <Input
                        id="country"
                        label="Country"
                        type="text"
                        placeholder=""
                        name="country"
                        value={values.country}
                        {...register("country")}
                        readOnly
                      />
                      <Input
                        id="state"
                        type="text"
                        label="State"
                        name="state"
                        value={values.state}
                        {...register("state")}
                      />
                      <Input
                        label="Reserves (in dry metric tonnes)"
                        type="number"
                        postfix="dmT"
                        name="reserves"
                        value={values.reserves}
                        onChange={handleChange}
                      />
                      {errors.reserves && (
                        <ErrorMessage>{errors.reserves?.message}</ErrorMessage>
                      )}
                    </div>
                    <div>
                      <Input
                        label="Postal code"
                        type="text"
                        value={values.location.postal_code}
                        {...register("postal")}
                      />
                      <Input
                        id="city"
                        type="text"
                        placeholder=""
                        label="City"
                        name="city"
                        value={funding.city}
                        {...register("city")}
                      />
                      {/* minerals muli select */}

                      <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                        Minerals, Metals & Gemstone
                      </p>
                      <div className="relative w-full">
                        <FieldArray name="minerals">
                          {(arrayHelpers) => (
                            <div>
                              <Select
                                sx={{
                                  width: "100%",
                                }}
                                displayEmpty
                                value={minerals}
                                onChange={(e) =>
                                  handleChangee(
                                    e,
                                    arrayHelpers,
                                    values.minerals
                                  )
                                }
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  if (selected.length === 0) {
                                    return <em>Select product</em>;
                                  }
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                {/* <MenuItem disabled value="">
                                <em>Placeholder</em>
                              </MenuItem> */}
                                {minerals?.map((mineral) => (
                                  <MenuItem
                                    key={mineral.slug}
                                    value={mineral}
                                    // style={getStyles(name, personName, theme)}
                                  >
                                    {mineral.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              <div className="flex flex-wrap w-full text-[#6166B6] font-light text-[14px] mt-3">
                                {values.minerals.map((option, index) => (
                                  <div
                                    key={index}
                                    className="bg-vendoroptionbg flex justify-between px-2 mr-5"
                                  >
                                    <span className="mr-2">{option.name}</span>
                                    <span
                                      className="cursor-pointer"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      x
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </FieldArray>
                      </div>

                      {/* <Controller
                        name="minerals"
                        control={control}
                        render={({ field }) => (
                          <Select
                            label="Minerals, Metals & Gemstone"
                            placeholder="Select product"
                            isMulti
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.slug}
                            options={mineData.minerals}
                            {...field}
                          />
                        )}
                      /> */}

                      {/* chips */}
                      {/* <div className="flex gap-2">
                        <Chips
                          id="minerals"
                          chips={watch("minerals")}
                          onClick={removeMultiSelectOption}
                        />
                      </div> */}
                    </div>
                  </div>

                  {/* <Controller
                    name="sdgGoals"
                    control={control}
                    render={({ field }) => (
                      <Select
                        id="sdgGoals-chips"
                        label="SDG goals"
                        placeholder="Select SDG goals"
                        isMulti
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.slug}
                        options={mineData.sdg_goals}
                        {...field}
                      />
                    )}
                  /> */}
                  <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                    SDG goals
                  </p>
                  <div className="relative w-full">
                    <FieldArray name="sdg_goals">
                      {(arrayHelpers) => (
                        <div>
                          <Select
                            sx={{
                              width: "100%",
                            }}
                            displayEmpty
                            value={values.sdg_goals}
                            onChange={(e) =>
                              handleChangee(e, arrayHelpers, values.sdg_goals)
                            }
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Select sdg goals</em>;
                              }
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            {/* <MenuItem disabled value="">
                                <em>Placeholder</em>
                              </MenuItem> */}
                            {sdg_goals?.map((mineral) => (
                              <MenuItem
                                key={mineral.name}
                                value={mineral}
                                // style={getStyles(name, personName, theme)}
                              >
                                {mineral.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <div className="flex flex-wrap w-full text-[#6166B6] font-light text-[14px] mt-3">
                            {values?.sdg_goals.map((option, index) => (
                              <div
                                key={index}
                                className="bg-vendoroptionbg flex justify-between px-2 mr-5"
                              >
                                <span className="mr-2">{option.name}</span>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  x
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>

                  <div className="flex justify-between mt-20 ">
                    <Button
                      type="button"
                      variant="contained"
                      color={"neutral"}
                      onClick={() => navigate("/admin/listings/mines")}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color={"primary"}
                      loading={loading}
                      disabled={loading}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            )}
          </Formik>
        </div>
        {/* <p className="text-ts font-light my-2">
          Mine ID:
          <span>324kkh34</span>
        </p> */}
      </div>
    </Card>
  );
};

export default EditMine;
