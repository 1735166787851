//import DocumentIcon from "components/ui/icons/DocumentIcon";
import React, { useCallback, useEffect, useState } from "react";
//import { Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  connectToLead,
  getProject,
} from "store/slices/vendors/interactions-slice";
import Popup from "./PopUp";
import { checkPermission } from "shared/utility";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import Button from "components/ui/Button";
import { getInitials } from "@shared/utility";
import { toast } from 'react-toastify';

function ProjectDetails() {
  const params = useParams();
  const { id } = params;
  const { project } = useSelector((store) => store.vendorInteractions);
  const dispatch = useDispatch();
  //eslint-disable-next-line
  const [isOpen, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const handleConnectToLead = useCallback(async (title) => {
    
    const response =  await dispatch(connectToLead({id:id,title:title}));
    if (response.payload.status === 200 || response.payload.status === 201) {
      if(response.payload.data.code == 422 || response.payload.data.code == 400) {
        toast.error(response.payload.data.message);
        setOpen(close);
      }else {
        toast.success(`Your contact has been forwarded to the prospect for the offer ${title}.`);
        setOpen(close);
        navigate("/vendor/dashboard");
      }
    }
  
  }, []);

 

  useEffect(() => {
    dispatch(getProject(id));
  }, []);

  return (
    <div>
      <div className="bg-white rounded-sm flex justify-center py-6">
        {/* <pre>{JSON.stringify(vendor, null, 2)}</pre> */}
        {project && (
          <div>
            <div>
              <div className="flex  justify-between">
                <div className="flex">
                {project?.client_image !== null ? (
                      <img
                      className="rounded-circle w-[60px] h-[60px] mr-3"
                        src={`${project?.client_image?.url}`}
                        alt=""
                      />
                    ) : (
                      <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill mr-3">
                          {getInitials(project?.client?.name)}
                      </div>
                    
                    )}
                  <div>
                    <h4 className="text-primary">{project.client.company}</h4>
                    <h4 className="text-[14px] text-vendor">
                      {project.client?.name}
                    </h4>
                  </div>
                </div>
                <div>
                  <Button
                    disabled={checkPermission("Interactions")}
                    onClick={() => setOpen(true)}
                  >
                    {" "}
                    Connect to lead
                  </Button>
                </div>
              </div>
            </div>
            <div className="mt-3 mb-7">
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project title:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {project.title}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project type:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {project.type}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Category:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {project.category.name}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project description:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4 break-words">
                  {project.description}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project duration:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {project.duration}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Country:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {project.country.name}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Budget:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {project.budget} USD
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project start date:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {dayjs(project.start_date).format("DD/MM/YYYY")}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Additional notes:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4 break-words">
                  {project.additional_notes}
                </h4>
              </div>
            </div>
          </div>
        )}

        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="absolute top-44 w-full flex justify-center">
            <div className="bg-white rounded-lg py-8 w-1/2 flex justify-center ">
              <div className="w-3/4">
                <Popup />
                <div className="flex justify-center gap-8 mt-8">
                  <Button onClick={() => handleConnectToLead(project?.title)}>
                    Agree and confirm
                  </Button>
                  <Button
                    variant="contained"
                    color="neutral"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default ProjectDetails;
