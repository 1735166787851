/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { mineSchema } from "@validations/schema";
import { get_mineral_data,get_sdgGoal_data,get_mineType_data,search_directory } from "@slices/onboarding/miner.slice";
import { Map, LongLat, SearchLocation } from "@pages/onboarding";
import { Card, Input, Select, TextArea, Chips, Button } from "@ui";
import { toast } from "react-toastify";
import { ErrorMessage } from "@components";
import { SuccessResponse } from "config/status-code";
import { postCreateMine } from "@slices/miners/mines-slice";

const AddMine = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.mines);
  const { directory } = useSelector((state) => state.miner_verification);
  const [location_type, setLocationType] = useState("");
  const [gps, setGps] = useState(false);
  const [address, setAddress] = useState(true);
  const [longitude_latitude, setLongitudeLatitude] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(mineSchema),
    mode: "all",
  });

  useEffect(() => {
    dispatch(get_mineral_data());
    dispatch(get_sdgGoal_data());
    dispatch(get_mineType_data());
  }, [dispatch]);

  const { minerals ,mine_types ,sdg_goals} = useSelector((state) => state.miner_verification);

  /** Update on Location type */
  useEffect(() => {
    /** switch location type */
    switch (location_type) {
      case "address":
        setAddress(true);
        setLongitudeLatitude(false);
        setGps(false);
        break;
      case "longitude_latitude":
        setLongitudeLatitude(true);
        setAddress(false);
        setGps(false);
        break;
      case "gps":
        setGps(true);
        setAddress(false);
        setLongitudeLatitude(false);
        break;
      default:
        break;
    }
  }, [location_type]);

  const handleSearch = (e) => {
    if (e.target.value.length > 1) {
      let wordSearch = e.target.value;
      const timer = setTimeout(() => {
        if (wordSearch == e.target.value) {
          if (e.target.value) {
            dispatch(
              search_directory({ search: e.target.value, type: "miner" })
            );
          }
        }
      }, 1500);
      return () => clearTimeout(timer);
    } else {
      if (e.target.value === "") {
        dispatch(search_directory({ search: "", type: null }));
      }
    }
  };

  const setCompany_ID = (data) => {
    setValue("company_name", data.name);
    dispatch(search_directory({ search: "", type: null }));
  };
  /** Onchange function of Location Type */
  const onChangeLocationType = (location_type) => {
    setValue("address", "", { shouldValidate: true, shouldDirty: true });
    setValue("postal", "", { shouldValidate: true, shouldDirty: true });
    setValue("state", "", { shouldValidate: true, shouldDirty: true });
    setValue("city", "", { shouldValidate: true, shouldDirty: true });
    setValue("long", "", { shouldValidate: true, shouldDirty: true });
    setValue("lat", "", { shouldValidate: true, shouldDirty: true });
    setValue("country", "", { shouldValidate: true, shouldDirty: true });
    return setLocationType(location_type);
  };

  const removeMultiSelectOption = (selectedItem, id) => {
    const selectedOptions = getValues(id);
    let newSelect = selectedOptions.filter(
      (item) => item.slug !== selectedItem
    );
    setValue(id, newSelect);
  };

  const onSubmit = (data) => {
    const mine_type = data.mine_type.slug;
    let mineral_type = data.mineral_type.map((item) => item.slug);
    let sdg_goals = data.sdgGoals.map((item) => item.slug);

    // this allows me to ommit address and postal code fields from the data object
    // eslint-disable-next-line no-unused-vars
    const { address, postal, long, lat, reserves, sdgGoals, ...updatedData } =
      data;
    let formData = {
      ...updatedData,
      mine_type,
      location: {
        longitude: Number(data.long),
        latitude: Number(data.lat),
        address: data.address,
        postal_code: data.postal,
      },
      reserves: Number(data.reserves),
      mineral_type,
      sdg_goals,
    };

    dispatch(postCreateMine(formData)).then((response) => {

      if (response.payload.status && SuccessResponse(response.payload.status)) {
        if(response?.payload?.data?.code == 422) {
          toast.error(response.payload.data.message);
        }else {
          navigate("/miner/mines", {
            state: {
              message: response.payload.data.message,
            },
          });
        }
       
      }
    });
  };

  return (
    <Card>
      <div className="max-w-[796px] mx-auto">
        <h2 className="text-heading text-2xl my-2">Add new mine</h2>
        <p className="text-ts font-light my-2">
          Enter the details for the mine as specified below.
        </p>
        <div className="my-6">
          <h3 className="my-2 text-lg text-heading">Mine details</h3>
          {/* form */}
          <form
            onSubmit={handleSubmit((data) => {
              onSubmit(data);
            })}
          >
            <div className="grid lg:grid-cols-2 sm:grid gap-1">
              <div>
                <Input
                  label="Mine name"
                  type="text"
                  {...register("mine_name")}
                />
                {errors.mine_name && (
                  <ErrorMessage>{errors.mine_name?.message}</ErrorMessage>
                )}
              </div>

              <div>
                <Controller
                  name="mine_type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      label="Mine type"
                      placeholder="Select mine type"
                      options={mine_types}
                      {...field}
                    />
                  )}
                />
                {errors.mine_type && (
                  <ErrorMessage>{errors.mine_type?.message}</ErrorMessage>
                )}
              </div>
            </div>
            <TextArea label="Description" {...register("description")} />
            {errors.description && (
              <ErrorMessage>{errors.description?.message}</ErrorMessage>
            )}
            <Input
              label="Company name"
              type="text"
              {...register("company_name")}
              onChange={(e) => handleSearch(e)}
            />
            {directory?.length > 0 && (
              <Card className="shadow-md">
                <div className="h-48 align-centre rounded-lg overflow-scroll cursor-pointer">
                  {directory.map((data, index) => (
                    <p
                      className="h-10 text-sm text-light font-light mb-2 box-company"
                      onClick={() => {
                        setCompany_ID(data);
                      }}
                      key={index + 1}
                    >
                      {" "}
                      {data?.name} - {data?.country}
                    </p>
                  ))}
                </div>
              </Card>
            )}

            {errors.company_name && (
              <ErrorMessage>{errors.company_name?.message}</ErrorMessage>
            )}

            <div className="w-full mt-2">
              <label
                className="block text-xs mb-2  text-lightgray"
                htmlFor="address"
              >
                Location type
              </label>
              <select
                className="bg-fill cursor-pointer border-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded text-input py-2 px-4 text-sm"
                id="address"
                type="dropdown"
                onChange={(e) => onChangeLocationType(e.target.value)}
              >
                <option
                  className="bg-fill cursor-pointer border-none"
                  value="address"
                  name="type"
                >
                  Address
                </option>
                <option value="longitude_latitude" name="type">
                  Longitude and Latitude
                </option>
                <option value="gps" name="type">
                  GPS
                </option>
              </select>
            </div>

            {gps && (
              <div className="w-full mt-3">
                <div className="h-96 my-4 ">
                  <Map
                    location={{
                      address:
                        "1600 Amphitheatre Parkway, Mountain View, california.",
                      lat: 37.42216,
                      lng: -122.08427,
                    }}
                    zoomLevel={17}
                    setValue={setValue}
                  />
                </div>
              </div>
            )}

            {longitude_latitude && (
              <div className="flex flex-wrap -mx-2">
                <LongLat setValue={setValue} errors={errors} />
              </div>
            )}

            {/* Address */}
            <div className="w-full mt-3">
              <label
                className="block text-xs mb-2  text-lightgray"
                htmlFor="address"
              >
                Address
              </label>
              {address ? (
                <SearchLocation setValue={setValue} />
              ) : (
                <Input type="text" name="address" {...register("address")} />
              )}
              {errors.address && (
                <ErrorMessage>{errors.address?.message}</ErrorMessage>
              )}
            </div>

            {/* Longitude && Latitude display this if longitude and latitude is not selected */}
            {!longitude_latitude && (
              <div className="flex flex-wrap -mx-4">
                {/** Longitude */}
                <div className=" media mt-4 px-3">
                  <label
                    className="block text-xs mb-2 text-lightgray"
                    htmlFor="longitude"
                  >
                    {" "}
                    Longitude{" "}
                  </label>
                  <Input
                    type="text"
                    name="long"
                    {...register("long")}
                    readOnly
                  />
                  {errors.long && (
                    <ErrorMessage>{errors.long?.message}</ErrorMessage>
                  )}
                </div>
                {/** Latitude */}
                <div className=" media mt-4 px-3">
                  <label
                    className="block text-xs mb-2 text-lightgray"
                    htmlFor="latitude"
                  >
                    Latitude
                  </label>
                  <Input type="text" name="lat" {...register("lat")} readOnly />
                  {errors.lat && (
                    <ErrorMessage>{errors.lat?.message}</ErrorMessage>
                  )}
                </div>
              </div>
            )}

            {/** Country && Postal code */}
            <div className="grid lg:grid-cols-2 sm:grid gap-1">
              <div>
                <Input
                  id="country"
                  label="Country"
                  type="text"
                  placeholder=""
                  name="country"
                  {...register("country")}
                  readOnly
                />
                <Input
                  id="state"
                  type="text"
                  label="State"
                  name="state"
                  {...register("state")}
                />
                <Input
                  label="Reserves (in dry metric tonnes)"
                  type="number"
                  postfix="dmT"
                  name="reserves"
                  {...register("reserves")}
                />
                {errors.reserves && (
                  <ErrorMessage>{errors.reserves?.message}</ErrorMessage>
                )}
              </div>
              <div>
                <Input
                  label="Postal code"
                  type="text"
                  {...register("postal")}
                />
                <Input
                  id="city"
                  type="text"
                  placeholder=""
                  label="City"
                  name="city"
                  {...register("city")}
                />
                {/* minerals muli select */}
                <Controller
                  name="mineral_type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      label="Minerals, Metals & Gemstone"
                      placeholder="Select product"
                      isMulti
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.slug}
                      options={minerals}
                      {...field}
                    />
                  )}
                />
                {errors.mineral_type && (
                  <ErrorMessage>{errors.mineral_type?.message}</ErrorMessage>
                )}

                {/* chips */}
                <div className="flex gap-2">
                  <Chips
                    id="mineral_type"
                    chips={watch("mineral_type")}
                    onClick={removeMultiSelectOption}
                  />
                </div>
              </div>
            </div>

            <Controller
              name="sdgGoals"
              control={control}
              render={({ field }) => (
                <Select
                  id="sdgGoals-chips"
                  label="SDG goals"
                  placeholder="Select SDG goals"
                  isMulti
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.slug}
                  options={sdg_goals}
                  {...field}
                />
              )}
            />

            {errors.sdgGoals && (
              <ErrorMessage>{errors.sdgGoals?.message}</ErrorMessage>
            )}
            <div className="flex gap-2">
              <Chips
                id="sdgGoals"
                chips={watch("sdgGoals")}
                onClick={removeMultiSelectOption}
              />
            </div>

            <div className="flex justify-end mt-20">
              <div className="min-w-[80px]">
                <Button
                  type="submit"
                  variant="contained"
                  color={"primary"}
                  loading={loading}
                  disabled={loading}
                  fullWidth
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Card>
  );
};

export default AddMine;
