//import DocumentIcon from "components/ui/icons/DocumentIcon";
import React, { useEffect } from "react";
//import { Divider } from "@mui/material";
import { getTransaction } from "store/slices/vendors/vendor-slice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "components/ui/Button";
import { Link } from "react-router-dom";

function TransactionDetails() {
  const params = useParams();
  const { id } = params;
  const { transaction } = useSelector((store) => store.vendor);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTransaction(id));
  }, []);
  return (
    <div className="bg-white p-6 rounded-sm">
      {/* <pre>{JSON.stringify(vendor, null, 2)}</pre> */}

      {transaction && (
        <div>
          <div>
            <div className="flex justify-between">
              <div className="flex">
                <img
                  className="rounded-circle w-[60px] h-[60px] mr-3"
                  src="https://cdn.smehost.net/2020sonymusiccouk-ukprod/wp-content/uploads/2019/10/Miley-Cyrus-03_MidnightSky_WhiteLight_2302.jpg"
                  alt=""
                />
                <div>
                  <h1 className="text-[18px] text-[#192434] mb-1">
                    {transaction.client.name}
                  </h1>
                  <h4 className="text-primary">{transaction.client.name}</h4>
                  <h4 className="text-[14px] text-vendor">
                    {transaction.client.name}
                  </h4>
                </div>
              </div>
              <div>
                <Button>
                  {" "}
                  <Link
                    to={`/vendor/vendors/find_a_vendor/contactvendor/${transaction.id}`}
                  >
                    Connect to lead
                  </Link>
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-7">
            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Project title:
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">
                {transaction.project_title}
              </h4>
            </div>
            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Project type:
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">
                {transaction.project_type}
              </h4>
            </div>
            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Category:
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">
                {transaction.project_category}
              </h4>
            </div>
            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Project description:
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">
                {transaction.project_description}
              </h4>
            </div>

            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Project duration:
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">
                {transaction.project_duration}
              </h4>
            </div>

            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Country:
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">
                {transaction.country.name}
              </h4>
            </div>
            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Budget:
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">200 000 USD</h4>
            </div>
            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Project start date:
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">
                {transaction.project_start_date}
              </h4>
            </div>
            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Additional notes:
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">
                {transaction.additional_notes}
              </h4>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TransactionDetails;
