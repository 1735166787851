import React, { Children, cloneElement, forwardRef } from "react";
import Slider from "react-slick";

const CustomSlider = forwardRef(({ children }, ref) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider {...settings} ref={ref}>
      {Children.map(children, (child) => {
        return cloneElement(child);
      })}
    </Slider>
  );
});

CustomSlider.displayName = "CustomSlider";

export default CustomSlider;
