/* eslint-disable no-unused-vars */
import Button from "components/ui/Button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { getInitials } from "@shared/utility";
import { useAuth } from "@hooks";
import { getTeamMemberBYID } from "@slices/roles-permissions-slice";
// import { SelectField } from "components/ui/Inputs";
import { Input } from "@ui";
import PropTypes from "prop-types";
import { RoleSuccessModal } from "../add/RoleConfirmModal";
import { SuccessResponse } from "config/status-code";
import { useParams } from "react-router-dom";
import { addRole } from "@slices/roles-permissions-slice";
import { useNavigate } from "react-router-dom";

const TeamMember = (id) => {
  const profiles = useAuth();
  const [open, setOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const teams = useSelector((state) => state.roles_permissions);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getTeamMemberBYID({
        id: profiles?.account?._id,
        _id: id.id,
      })
    );
  }, [dispatch]);
  let [data, setData] = useState({
    name: "",
  });
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  return (
    <>
      <RoleSuccessModal open={open} setOpen={setOpen} name={data?.name} />
      <div className="bg-white pt-5 rounded-md mb-5">
        <div className="flex justify-between px-5 mb-4">
          <h1>Team Members</h1>
          <Button
            onClick={() => navigate("../../../../off-taker/teams/member/add")}
            // onClick={() => {
            //   createRole();
            //   //   setOpen(!open);
            // }}
          >
            Add Team Member
          </Button>
        </div>
        <Divider />
        <div className="col-start-5 col-end-7 pt-4">
          <div className="w-2/3">
            {teams.team_member[0]?.users.map((user, index) => (
              <div
                className="flex mb-3 p-5"
                key={index + 1}
                style={{ marginLeft: "11px" }}
              >
                <>
                  {user?.accounts[0]?.profile_img?.length > 0 ? (
                    <img
                      className="w-[25px] h-[25px] rounded-circle "
                      key={index}
                      src={`${user?.accounts[0]?.profile_img[0]?.url}`}
                      alt=""
                    />
                  ) : (
                    <div className="min-h-[25px] min-w-[25px] flex justify-center align-center items-center text-1xl text-primary rounded-full bg-fill">
                      {getInitials(user?.name)}
                    </div>
                  )}
                  &nbsp;&nbsp;&nbsp;
                  <h3>{user?.name}</h3>
                </>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

TeamMember.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default TeamMember;
