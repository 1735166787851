// eslint-disable-next-line no-unused-vars
import { Card, Input, Select, TextArea, Button } from "@ui";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "@slices/onboard-slice";
import { toast } from "react-toastify";
import { CourierUrl } from "config/app-url";
import {
  getCarrierByID,
  updateCarrierByID,
} from "@slices/miners/interaction-slice";
const EditCarrier = () => {
  const _FormData = new FormData();
  const navigate = useNavigate();

  const countries = useSelector((state) => state.onboard);
  const carrierByID = useSelector((state) => state.shipment);
  const [state, setState] = useState({
    name: carrierByID.docs?.name,
    slug: carrierByID.docs?.slug,
    other_name: carrierByID.docs?.other_name,
    web_url: carrierByID.docs?.web_url,
    service_from_country: carrierByID.docs?.service_from_country,
    is_active: true,
  });
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCountries(), (error) => {
      toast.error(error);
    });
  }, [dispatch]);
  //   let [data, setData] = useState({
  //     slug: "",
  //     name: "",
  //     other_name: "",
  //     web_url: "",
  //     // tracking_api_url: "",
  //     // api_token: "",
  //     service_from_country: "",
  //     is_active: true,
  //   });

  useEffect(() => {
    if (location.state && location.state.id) {
      dispatch(getCarrierByID(location.state.id));
    }
  }, [location]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSelectChange = (e, { name }) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: e.name,
      };
    });
  };

  const submitDetails = async () => {
    _FormData.append("data", state);
    try {
      const response = await dispatch(
        updateCarrierByID({ id: location.state.id, data: state })
      );
      if (response.payload.status === 200) {
        navigate(CourierUrl);
        toast.success("Carrier Details Updated");
        window.location.reload();
      } else {
        toast.error(response.payload.msg);

        // console.log("error from then", response);
      }
    } catch (error) {
      toast.error(error);
      // console.log("error", error);
    }
  };

  return (
    <Card>
      <div className="max-w-[796px] mx-auto pt-10 pb-[108px]">
        <h2 className="text-heading text-2xl mb-2">Edit Carrier</h2>
        <p className="text-ts font-light mt-2 mb-[30px]">
          Enter the details for the carrier as specified below.
        </p>

        <div className="grid grid-cols-2 gap-4">
          <Input
            label="Name"
            name="name"
            defaultValue={carrierByID?.docs?.name || ""}
            onChange={handleInputChange}
          />
          <Input
            label="Other Name"
            name="other_name"
            defaultValue={carrierByID?.docs?.other_name || ""}
            onChange={handleInputChange}
          />
        </div>

        <Input
          label="Web Url"
          defaultValue={carrierByID?.docs?.web_url || ""}
          name="web_url"
          onChange={handleInputChange}
        />

        <Input
          label="Slug"
          name="slug"
          defaultValue={carrierByID?.docs?.slug || ""}
          onChange={handleInputChange}
        />
        <div className="grid grid-cols-2 gap-4">
          {/* <Input
            label="Tracking Api Url"
            name="tracking_api_url"
            onChange={handleInputChange}
          /> */}
        </div>
        {/* 
        <Input
          label="Token"
          type="text"
          name="api_token"
          onChange={handleInputChange}
        /> */}

        <div>
          <Select
            label="Country"
            name="service_from_country"
            options={countries.countries}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            onChange={handleSelectChange}
          />
        </div>
      </div>
      <div className="flex justify-between gap-8 mt-8">
        <Button
          variant="contained"
          onClick={() => navigate(CourierUrl)}
          color="neutral"
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            submitDetails();
          }}
          // onClick={() => navigate("/offtaker-vendors/service")}
        >
          Confirm
        </Button>
      </div>
    </Card>
  );
};

export default EditCarrier;
