/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import { Button } from '@atoms/button';
import { Input } from '@atoms/input';
import { useForm } from 'react-hook-form';
import { Formik } from 'formik';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { PhoneInputFormatter } from '@shared/formatter';
import { postProposalInterest } from '@store/slices/offtaker-slice';
import ErrorMessage from 'components/ErrorMessage';
import { IndicateInterestSuccessModal } from './IndicateInterestSuccess';

const schema = yup.object().shape({
	name: yup.string().required('Name is required'),
	email: yup.string().required('Business email is required'),
	password: yup.string().required('Password is required'),
	additional_notes: yup.string(),
	phone: yup.object().required('Phone is required'),
	confirm_password: yup
		.string()
		.oneOf([yup.ref('password'), null], 'Passwords must match')
});

const IndicateInterest = () => {
	const [openSubmitModal, setOpenSubmitModal] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const { id } = location.state;
	const {
		register,
		setValue,
		handleSubmit,
		getValues,
		formState: { errors, isValid, isDirty }
	} = useForm({
		resolver: yupResolver(schema),
		mode: 'all'
	});
	const dispatch = useDispatch();
	const [productName, setProductName] = useState();
	const [password, setPassword] = useState();
	const onSubmit = async (data) => {
		setProductName(getValues('name'));
		setPassword(getValues('password'));
		try {
			const response = await dispatch(postProposalInterest({ id, data }));
			if (response.payload.status === 200 || response.payload.status === 201) {
				setProductName(data.name);
				setOpenSubmitModal(true);
			} else {
				console.log("error from then", response);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const [, setPhoneInput] = useState({
		code: '',
		number: '',
		iso_code: ''
	});

	/** Format phone then set to state */
	const getFormattedNumber = (value, data) => {
		const input = PhoneInputFormatter(value, data);
		setPhoneInput({
			code: input.phone_code,
			number: input.phone_number,
			iso_code: input.iso_code
		});
		setValue(
			'phone',
			{
				code: input.phone_code,
				number: input.phone_number
			},
			{ shouldValidate: true, shouldDirty: true }
		);
	};
	const initialValues = {
		id: id,
		name: '',
		email: '',
		phone: '',
		password: '',
		confirm_password: ''
	};
	return (
		<div className="text-default  w-[1125px] h-[972px] bg-white px-[164px] pt-[40px]">
			<p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434]">
				Indicate interest
			</p>
			<p className="flex-grow-0 flex-shrink-0 w-[796px] text-base font-light text-left text-[#1b2b41]/[0.72]">
				The information provided will be accessed by the miner in order to
				contact you is selected.
			</p>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				handleSubmit={handleSubmit}
			>
				{({ handleChange, isSubmitting }) => (
					<form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
						<div>
							<label
								className="block text-xs mb-2  text-lightgray"
								htmlFor="name"
							>
								Name
							</label>
							<Input id="name" type="text" name="name" {...register('name')} />
							{errors.name && (
								<ErrorMessage>{errors.name?.message}</ErrorMessage>
							)}
						</div>

						<div className="mt-4">
							<label
								className="block text-xs mb-2  text-lightgray"
								htmlFor="name"
							>
								Business email address
							</label>
							<Input
								id="name"
								type="email"
								name="email"
								{...register('email')}
							/>
							{errors.email && (
								<ErrorMessage>{errors.email?.message}</ErrorMessage>
							)}
						</div>

						<div className="mt-2">
							<label className="block text-sm mb-2 text-lightgray">
								Phone Number
							</label>
							<PhoneInput
								isValid={(value) => {
									if (value.length < 10 && value.length > 1) {
										return '* valid phone number is required';
									} else if (value.length < 10) {
										return false;
									} else {
										return true;
									}
								}}
								type="text"
								country="us"
								value=""
								onChange={(value, data) => getFormattedNumber(value, data)}
								inputProps={{ name: 'phone', required: true }}
								masks={{ fr: '(...) ..-..-..', at: '(....) ...-....' }}
							/>
						</div>

						<div className="mt-4">
							<label
								className="block text-xs mb-2  text-lightgray"
								htmlFor="name"
							>
								Additional notes
							</label>
							<Input
								id="additional_notes"
								type="textarea"
								name="additional_notes"
								{...register('additional_notes')}
							/>
							{errors.additional_notes && (
								<ErrorMessage>{errors.additional_notes?.message}</ErrorMessage>
							)}
						</div>

						<div className="mt-4">
							<label
								className="block text-xs mb-2  text-lightgray"
								htmlFor="name"
							>
								Create unique password
							</label>
							<Input
								id="password"
								type="password"
								name="password"
								{...register('password')}
							/>
							<span className="text-xs text-primary font-extralight">
								You are required to request this password upon being contacted
								with the miner. Do not share this password with a third-party.
							</span>
							{errors.name && (
								<ErrorMessage>{errors.password?.message}</ErrorMessage>
							)}
						</div>

						<div className="mt-4">
							<label
								className="block text-xs mb-2  text-lightgray"
								htmlFor="name"
							>
								Verify unique password
							</label>
							<Input
								id="confirm_password"
								type="password"
								name="confirm_password"
								{...register('confirm_password')}
							/>
							{errors.confirm_password && (
								<ErrorMessage>{errors.confirm_password?.message}</ErrorMessage>
							)}
						</div>

						<div className="mt-8 flex justify-between">
							<button
								onClick={() => navigate('/off-taker/invest/proposals')}
								className="bg-dark-gray-300 text-primary w-[fit-content] flex justify-center py-2 mb-3 mt-4 px-4 rounded"
							>
								Cancel
							</button>

							<Button
								type="submit"
								variant="contained"
								color="primary"
								size="small"
								loading={isSubmitting}
								disabled={!isDirty || !isValid}
							>
								Submit
							</Button>
						</div>
					</form>
				)}
			</Formik>
			<IndicateInterestSuccessModal
				open={openSubmitModal}
				setOpenSubmitModal={setOpenSubmitModal}
				productName={productName}
				password={password}
			/>
		</div>
	);
};

export default IndicateInterest;
