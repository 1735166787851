const ChevronUpIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50511 14.663C7.13401 14.999 6.5634 14.9676 6.23061 14.5929C5.89783 14.2183 5.92889 13.6422 6.29999 13.3062L11.2566 8.71415C11.5994 8.40372 12.1188 8.40372 12.4617 8.71415L17.5334 13.4104C17.9045 13.7464 17.9355 14.3225 17.6027 14.6971C17.27 15.0718 16.6993 15.1032 16.3282 14.7672L11.8591 10.6165L7.50511 14.663Z"
        fill="#D9E8FC"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default ChevronUpIcon;
