/* eslint-disable no-unused-vars */
import { Input } from "components/ui/Inputs";
import React, { useState } from "react";
import { Select } from "@ui";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { PhoneInputFormatter } from "@shared/formatter";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect } from "react";
import { useAuth } from "@hooks";
import { fetchAdminRoles, addteamMember } from "@slices/admin-slice";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik } from "formik";
import Button from "components/ui/Button";
import { toast } from "react-toastify";

function AddMemberAdmin() {
  const profiles = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const roles = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchAdminRoles({
        id: profiles?.account?._id,
        page: 1,
        limit: 100,
      })
    );
  }, [dispatch]);
  /** Format phone then set to state */
  const getFormattedNumber = (value, data) => {
    const input = PhoneInputFormatter(value, data);
    setPhoneInput({
      ...phone_input,
      phone_code: input.phone_code,
      phone_number: input.phone_number,
      iso_code: input.iso_code,
    });
  };
  const [phone_input, setPhoneInput] = useState({
    phone_code: "",
    phone_number: "",
    iso_code: "",
  });

  const [valuese, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
    terms: true,
    notifications: false,
  });

  const handleChangee = (prop) => (event) => {
    setValues({ ...valuese, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...valuese,
      showPassword: !valuese.showPassword,
    });
  };

  let [data, setData] = useState({
    role: "",
    name: "",
    email: "",
  });

  const handleSelectChange = (e, { name, _id }) => {
    setData((prevState) => {
      return {
        ...prevState,
        [name]: e._id,
      };
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function createMember() {
    let formData = {
      name: data.name,
      email: data.email,
      phone_code: phone_input.phone_code,
      phone_number: phone_input.phone_number,
      iso_code: phone_input.iso_code,
      password: valuese.password,
      terms: valuese.terms,
      notifications: valuese.notifications,
      role: data.role,
    };
    setIsLoading(true);
    dispatch(addteamMember(formData)).then((response) => {
      if (response.payload.status === 200 || response.payload.status === 201) {
        if(response?.payload?.data?.code == 422 || response?.payload?.data?.code == 400) {
          setIsLoading(false);
          toast.error(response.payload.data.message);
        }else {
          navigate("/admin/teams/member");
          toast.success("Team Member Added");
        }
        
      
        // setOpen(true);
        // return;
      } else {
        setIsLoading(false);
      }
    });
  }
  return (
    <div className="flex justify-center">
      <div className="w-1/2 bg-white p-6 rounded-sm">
        <h1 className="mb-5">Add Team member</h1>
        <Input label="Full name" name="name" onChange={handleInputChange} />
        <Input
          label="Business email address"
          name="email"
          onChange={handleInputChange}
        />
        <div className="password_2 block mt-4 relative">
          <div className="mt-4">
            <label className="block text-sm mb-1 text-lightgray">
              {" "}
              Phone Number{" "}
            </label>
            <PhoneInput
              isValid={(value) => {
                if (value.length < 7 && value.length > 1) {
                  return "* valid phone number is required";
                } else if (value.length < 7) {
                  return false;
                } else {
                  return true;
                }
              }}
              type="text"
              country="us"
              value=""
              onChange={(value, data) => getFormattedNumber(value, data)}
              inputProps={{ name: "phone", required: true }}
              masks={{ fr: "(...) ..-..-..", at: "(....) ...-...." }}
            />
          </div>
          {/* password */}
          <div className="mt-6 w-full">
            <label className="block text-sm  mb-2 text-lightgray">
              Create Password
            </label>

            <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                sx={{
                  width: "100%",
                  backgroundColor: "#1A38601A",
                }}
                id="outlined-adornment-password"
                type={valuese.showPassword ? "text" : "password"}
                value={valuese.password}
                onChange={handleChangee("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {valuese.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </div>
        </div>
        <div className="mt-3 w-full">
          <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
            Category
          </p>
          {/* <SelectField
                  items={[
                    "Intern",
                    "Accountant",
                    "MineOfficer",
                    "Technical Officer",
                  ]}
                  name="project_category"
                  value={values.role}
                  onChange={handleChangee}
                /> */}
          <Select
            name="role"
            options={roles?.docs?.docs}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option._id}
            onChange={handleSelectChange}
            placeholder="Select Role"
          />
        </div>
        <div className="mt-4">
          <Button
            onClick={() => {
              createMember();
              //   setOpen(!open);
            }}
            fullWidth={true}
          >
            {isLoading ? "Processing..." : "Create Account"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddMemberAdmin;
