/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Card, Button, Checkbox } from "@ui";
import { useNavigate } from "react-router-dom";
import { Box, Modal, Step, StepLabel, Stepper } from "@mui/material";
import Padlock from "@ui/icons/PadLock";
import StepperCheckIcon from "@ui/icons/StepperCheckIcon";
import DiplomaScroll from "@ui/icons/DiplomaScroll";
import ConfirmAndSendRequest from "./ConfirmAndSendRequest";
import { useDispatch } from "react-redux";
import { checkPermission } from "shared/utility";

import { postTransactionConfirm } from "@store/slices/miners/transaction-slice";

const Pending = ({ transaction, status, transactionId }) => {
  const [action, setAction] = useState("review");
  const [inventoryStatus, setInventoryStatus] = useState("Pending");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const _FormData = new FormData();
  const navigate = useNavigate();
  const steps = [
    "Pending",
    "Awaiting POP",
    "Awaiting POF",
    "Awaiting POC",
    "In Transit",
    "Delivered",
    "Completed",
  ];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 448,
    bgcolor: "background.paper",
    borderRadius: "0.25rem",
    boxShadow: 24,
    p: 4,
    outline: 0,
    padding: "22px",
  };
  const indexOf = steps.findIndex((el) => {
    return el == inventoryStatus;
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [state, setState] = React.useState({
    clause: "",
    signature: "",
    agreed_term: false,
    signed_term: false,
  });

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const handleSubmit = () => {
    try {
      const payload = {
        action: "confirm_request",
        additional_clause: state.clause,
        signature: state.signed_term,
        terms: state.agreed_term,
      };
      const formData = {
        id: transactionId,
        payload,
      };
       _FormData.append("data",JSON.stringify(payload));
      dispatch(postTransactionConfirm({formdata:_FormData,id:transactionId}));
      navigate(`/miner/interactions/transactions`);
    } catch (error) {
      // toast.error(error.message);
      console.error(error);
    }
  };
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      {["review", "sign-contract"].includes(action) ? (
        <>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434]">
                  Signature
                </p>
                <div className="mb-3 xl:w-full">
                  <label
                    htmlFor={
                      "In place of your signature, type your full legal name:"
                    }
                    className="form-label inline-block mb-2 font-light text-base text-[#1B2B41B8]"
                  >
                    In place of your signature, type your name
                    <span className="text-[#3bd373]"> {user?.name}</span>
                  </label>
                  <div className="relative flex flex-col items-center">
                    <input
                      type="text"
                      className={`w-full h-[42px] max-h-[42px] p-[10px] border-none font-light text-gray-700 bg-[#1A38601A] rounded z-0 focus:shadow focus:outline-none focus:ring-primary  placeholder:text-[#1C345442] placeholder:font-extralight placeholder:text-base`}
                      onChange={handleChange}
                      name="signature"
                    />
                    {!(user.name == state.signature) && (
                      <span className="text-[#ed313d] text-xs mt-1">
                        This message will go away once your name is correctly
                        spelt
                      </span>
                    )}
                  </div>
                  <div className="flex gap-2 mt-[10px]">
                    <Checkbox name="signed_term" onChange={handleChange} />
                    <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                      I agree to the Terms that this is signed by me.
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <Button
                    size="small"
                    onClick={handleSubmit}
                    disabled={
                      !(user.name == state.signature && state.signed_term)
                    }
                  >
                    Confirm and send request
                  </Button>
                </div>
              </Box>
            </Modal>
          </div>

          <Card noPadding>
            <div className="border-b-[1px] border-[#1C345442] h-[258px] mx-[-1.5rem]">
              <div className="divide-x divide-disabled mx-[2.5rem]">
                <div className="text-default bg-white pt-[30px]">
                  <div className="flex items-center justify-between">
                    <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[24px] text-left text-[#192434]">
                      Main Info
                    </p>
                    <div className="ml-[8px] bg-[#6166B6] rounded-md">
                      <Button
                        variant="outlined"
                        color="text-[#ffffff]"
                        disabled={checkPermission("Interactions")}
                        fontWeight="font-light"
                        onClick={() => {
                          if (action === "sign-contract") {
                            handleOpen();
                          } else {
                            setAction("csr");
                          }
                        }}
                      >
                        {action === "sign-contract"
                          ? "Sign Contract"
                          : "Review Contract"}
                      </Button>
                    </div>
                  </div>
                  <div className="text-default  w-[796px] bg-white">
                    <div className="flex">
                      <div className="w-[50%]">
                        <div className="flex mb-[16px]">
                          <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            Transaction ID:
                          </p>
                          <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            {transaction?.transaction_id ?? "N/A"}
                          </p>
                        </div>
                        <div className="flex mb-[16px]">
                          <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            Product name:
                          </p>
                          <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            {transaction?.product?.product_name ?? "N/A"}
                          </p>
                        </div>
                        <div className="flex mb-[16px]">
                          <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            Name of buyer:
                          </p>
                          <p className="flex-grow- flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            <span className="flex items-center text-[#6166B6] text-left">
                              {transaction?.buyer?.name ?? "N/A"}
                            </span>
                          </p>
                        </div>
                        <div className="flex mb-[16px]">
                          <p className="flex-grow-0 mr-[100px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            Status:
                          </p>
                          <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            <span
                              className={`py-1 px-5 inline-flex capitalize text-sm leading-5 font-light rounded bg-[#FDF2E7] text-[#F2994A] `}
                            >
                              {status ?? "N/A"}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="mb-[16px]">
                          <p>Transaction value</p>
                          {transaction?.product?.product_total_value ?? "N/A"}
                          {transaction?.product?.product_total_value && "  USD"}
                        </div>
                        <div className="">
                          <p>Commission value (5% of transaction value)</p>
                          <p className="font-medium text-lg">
                            {(
                              transaction?.product?.product_total_value * 0.05
                            )?.toFixed(2) ?? "N/A"}{" "}
                            {transaction?.product?.product_total_value && "USD"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[100px]">
              <div className="divide-x divide-disabled">
                <div className="text-default bg-white pt-[30px]">
                  <div className="flex items-center justify-between">
                    <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[24px] text-left text-[#192434]">
                      Documents
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <div className="mt-[30px]">
            <Card noPadding>
              <div className="flex items-center justify-between mt-5">
                <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[16px] text-left text-[#192434]">
                  Timeline
                </p>
              </div>
              <Stepper orientation="vertical">
                {transaction?.timeline?.map((step, i) => (
                  <Step key={i}>
                    <StepLabel
                      StepIconComponent={() => (
                        <DiplomaScroll fill_="black" h_="16px" w="16px" />
                      )}
                    >
                      <div className="flex font-normal items-center">
                        {/* <img
                          className="max-w-[24px] mr-[8px]"
                          src={ProfilePicture}
                          alt="Miley Cyrus"
                        /> */}
                        <span className="font-[300] text-[#1b2b41a3]">
                          <span className="text-black mr-2">
                            {" "}
                            {step?.message}
                          </span>

                          {step?.created_at
                            ? new Date(step?.created_at).toLocaleString()
                            : null}
                        </span>
                      </div>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Card>
          </div>

          <div className="pt-[40px]">
            <Stepper alternativeLabel orientation="horizontal">
              {steps.map((step, i) => (
                <Step key={i}>
                  <StepLabel
                    StepIconComponent={
                      i <= indexOf ? StepperCheckIcon : Padlock
                    }
                  >
                    {step}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </>
      ) : null}
      <>
        {action === "csr" ? (
          <ConfirmAndSendRequest
            setAction={setAction}
            state={state}
            handleChange={handleChange}
            transaction={transaction}
          />
        ) : null}
      </>
    </>
  );
};

export default Pending;
