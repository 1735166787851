import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import ChatBubble from './chat_bubble'
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';



const ChatContainer=({top_content, chat_list})=> {
    ChatBubble.propTypes = {
        top_content: PropTypes.array.isRequired,
        chat_list: PropTypes.array.isRequired,
}

const siriusblack = createTheme({
    typography: {
      nameTitle:{
        fontFamily: 'Associate Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 18,
        color: '#192434'
      },
      timer:{
        fontFamily: 'Associate Sans',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: 13,
        color: 'rgba(28, 48, 74, 0.5)'
      }
      
    },
  });
 let bubble_class = ""


  return (
    <div>
      <div className="chat-top-content">
      <ListItem  alignItems="flex-start" className='message-list-item' >
      <ListItemAvatar>
        <Avatar alt={top_content.name} src={top_content.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={ <Fragment>
        <ThemeProvider theme={siriusblack}>
          <Typography
            sx={{ display: 'inline' }}
            component="span"
            variant="nameTitle"
          >
            {top_content.name}
          </Typography>
        </ThemeProvider>
        </Fragment>}
        secondary={
          <Fragment>
            <Typography
              sx={{ display: 'inline' }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              Active 3 minutes ago
            </Typography>
           
          </Fragment>
        }
      />
    </ListItem>

      </div>
      <div>
        {chat_list.map((chat, indx)=>{
            bubble_class = (top_content.userid===chat.userid)?"chat-bubble":"chat-bubble-blue";
            console.log(top_content.userid + "===" + chat.userid)
            return (<ChatBubble key={indx} message={chat.message} time={chat.message_date} bgclass={bubble_class} />)

        })}
      </div>
    </div>
  );
}
export default ChatContainer;
