import React from 'react'
import { createSlice, createAction } from "@reduxjs/toolkit";
import { ReactComponent as BubbleIcon } from '../../assets/images/bubble.svg'
// import axios from "axios";
// import * as API from "@config/url";
// import { toast } from "react-toastify";
let selected_chat = {}


const all_messages = [
    {
        'userid': '4',
        'avatar': 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
        'name': 'Ali Connors',
        'last_update_date': '15-06-2022 10:20 AM',
        'chats': [
            {'userid':'4', 'message':'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'message_date':'15-06-2022 10:20 AM', 'status':'UNREAD'},
            {'userid':'1', 'message':'Donec vehicula ante vel porta rhoncus. Sed ultrices mattis tempor. Mauris suscipit tincidunt dapibus. ', 'message_date':'15-06-2022 11:30 AM', 'status':'UNREAD'},
            {'userid':'4', 'message':'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ', 'message_date':'15-06-2022 01:25 PM', 'status':'UNREAD'},
            {'userid':'4', 'message':'Contrary to popular belief, Lorem Ipsum is not simply random text. ', 'message_date':'14-06-2022 11:30 AM', 'status':'UNREAD'},
        ]
    },
    {
        'userid': '5',
        'avatar': 'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80',
        'name': 'Travis Howard',
        'last_update_date': '11-06-2022 04:35 PM',
        'chats': [
            {'userid':'5', 'message':'Donec vehicula ante vel porta rhoncus. Sed ultrices mattis tempor. Mauris suscipit tincidunt dapibus.', 'message_date':'15-06-2022 11:30 AM', 'status':'UNREAD'},
            {'userid':'5', 'message':'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'message_date':'15-06-2022 10:20 AM', 'status':'UNREAD'},
            {'userid':'5', 'message':'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ', 'message_date':'15-06-2022 01:25 PM', 'status':'UNREAD'},
            {'userid':'1', 'message':'Contrary to popular belief, Lorem Ipsum is not simply random text. ', 'message_date':'14-06-2022 11:30 AM', 'status':'UNREAD'},
        ]
    },
    {
        'userid': '7',
        'avatar': 'https://cdn.pixabay.com/photo/2013/07/12/15/24/woman-149861_1280.png',
        'name': 'Cindy Baker',
        'last_update_date': '08-06-2022 09:00 AM',
        'chats': [
            {'userid':'7', 'message':'Contrary to popular belief, Lorem Ipsum is not simply random text. ', 'message_date':'14-06-2022 11:30 AM', 'status':'ARCHIVED'},
            {'userid':'7', 'message':'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'message_date':'15-06-2022 10:20 AM', 'status':'READ'},
            {'userid':'1', 'message':'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ', 'message_date':'15-06-2022 11:30 AM', 'status':'READ'},
            {'userid':'7', 'message':'Donec vehicula ante vel porta rhoncus. Sed ultrices mattis tempor. Mauris suscipit tincidunt dapibus.', 'message_date':'15-06-2022 11:30 AM', 'status':'UNREAD'},
        ]
    },
    {
        'userid': '8',
        'avatar': 'https://img.freepik.com/free-photo/self-portrait-beautiful-chinese-girl_1262-18293.jpg?w=1800&t=st=1657398363~exp=1657398963~hmac=8720740c29c20a1ed396102c6311e4267b495e5155702da6f040b8ff03f527aa',
        'name': 'Sandra Adams',
        'last_update_date': '05-06-2022 06:00 PM',
        'chats': [
            {'userid':'8', 'message':'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'message_date':'15-06-2022 10:20 AM', 'status':'ARCHIVED'},
            {'userid':'8', 'message':'Donec vehicula ante vel porta rhoncus. Sed ultrices mattis tempor. Mauris suscipit tincidunt dapibus.', 'message_date':'15-06-2022 11:30 AM', 'status':'ARCHIVED'},
            {'userid':'8', 'message':'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ', 'message_date':'15-06-2022 01:25 PM', 'status':'UNREAD'},
            {'userid':'1', 'message':'Contrary to popular belief, Lorem Ipsum is not simply random text. ', 'message_date':'14-06-2022 11:30 AM', 'status':'READ'},
        ]
    },

]

const archived_messages = [
    {
        'userid': '4',
        'avatar': 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
        'name': 'Ali Connors',
        'last_update_date': '15-06-2022 10:20 AM',
        'chats': [
            {'userid':'4', 'message':'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'message_date':'15-06-2022 10:20 AM', 'status':'UNREAD'},
            {'userid':'1', 'message':'Donec vehicula ante vel porta rhoncus. Sed ultrices mattis tempor. Mauris suscipit tincidunt dapibus. ', 'message_date':'15-06-2022 11:30 AM', 'status':'UNREAD'},
            {'userid':'4', 'message':'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ', 'message_date':'15-06-2022 01:25 PM', 'status':'UNREAD'},
            {'userid':'1', 'message':'Contrary to popular belief, Lorem Ipsum is not simply random text. ', 'message_date':'14-06-2022 11:30 AM', 'status':'UNREAD'},
        ]
    },
    {
        'userid': '5',
        'avatar': 'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80',
        'name': 'Travis Howard',
        'last_update_date': '11-06-2022 04:35 PM',
        'chats': [
            {'userid':'5', 'message':'Donec vehicula ante vel porta rhoncus. Sed ultrices mattis tempor. Mauris suscipit tincidunt dapibus.', 'message_date':'15-06-2022 11:30 AM', 'status':'UNREAD'},
            {'userid':'1', 'message':'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'message_date':'15-06-2022 10:20 AM', 'status':'UNREAD'},
            {'userid':'1', 'message':'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ', 'message_date':'15-06-2022 01:25 PM', 'status':'UNREAD'},
            {'userid':'5', 'message':'Contrary to popular belief, Lorem Ipsum is not simply random text. ', 'message_date':'14-06-2022 11:30 AM', 'status':'UNREAD'},
        ]
    },
    {
        'userid': '7',
        'avatar': 'https://cdn.pixabay.com/photo/2013/07/12/15/24/woman-149861_1280.png',
        'name': 'Cindy Baker',
        'last_update_date': '08-06-2022 09:00 AM',
        'chats': [
            {'userid':'7', 'message':'Contrary to popular belief, Lorem Ipsum is not simply random text. ', 'message_date':'14-06-2022 11:30 AM', 'status':'ARCHIVED'},
            {'userid':'1', 'message':'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'message_date':'15-06-2022 10:20 AM', 'status':'READ'},
            {'userid':'7', 'message':'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ', 'message_date':'15-06-2022 11:30 AM', 'status':'READ'},
            {'userid':'7', 'message':'Donec vehicula ante vel porta rhoncus. Sed ultrices mattis tempor. Mauris suscipit tincidunt dapibus.', 'message_date':'15-06-2022 11:30 AM', 'status':'UNREAD'},
        ]
    }

]

const unread_messages = [
    {
        'userid': '7',
        'avatar': 'https://cdn.pixabay.com/photo/2013/07/12/15/24/woman-149861_1280.png',
        'name': 'Cindy Baker',
        'last_update_date': '08-06-2022 09:00 AM',
        'chats': [
            {'userid':'7', 'message':'Contrary to popular belief, Lorem Ipsum is not simply random text. ', 'message_date':'14-06-2022 11:30 AM', 'status':'ARCHIVED'},
            {'userid':'7', 'message':'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'message_date':'15-06-2022 10:20 AM', 'status':'READ'},
            {'userid':'1', 'message':'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ', 'message_date':'15-06-2022 11:30 AM', 'status':'READ'},
            {'userid':'7', 'message':'Donec vehicula ante vel porta rhoncus. Sed ultrices mattis tempor. Mauris suscipit tincidunt dapibus.', 'message_date':'15-06-2022 11:30 AM', 'status':'UNREAD'},
        ]
    },
    {
        'userid': '8',
        'avatar': 'https://img.freepik.com/free-photo/self-portrait-beautiful-chinese-girl_1262-18293.jpg?w=1800&t=st=1657398363~exp=1657398963~hmac=8720740c29c20a1ed396102c6311e4267b495e5155702da6f040b8ff03f527aa',
        'name': 'Sandra Adams',
        'last_update_date': '05-06-2022 06:00 PM',
        'chats': [
            {'userid':'8', 'message':'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'message_date':'15-06-2022 10:20 AM', 'status':'ARCHIVED'},
            {'userid':'8', 'message':'Donec vehicula ante vel porta rhoncus. Sed ultrices mattis tempor. Mauris suscipit tincidunt dapibus.', 'message_date':'15-06-2022 11:30 AM', 'status':'ARCHIVED'},
            {'userid':'1', 'message':'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ', 'message_date':'15-06-2022 01:25 PM', 'status':'UNREAD'},
            {'userid':'8', 'message':'Contrary to popular belief, Lorem Ipsum is not simply random text. ', 'message_date':'14-06-2022 11:30 AM', 'status':'READ'},
        ]
    },

]



const get_all_messages = createAction('get_all_messages', function getAllMessages() {
const user_data = JSON.parse(localStorage.getItem('user'))

    const data = {
        "all_messages": all_messages,
        "unread_messages": unread_messages,
        "archived_messages": archived_messages,
        "displayed_messages": (<div className='message-right-side-div-child'>
            <BubbleIcon className="bubble-style" />
            <div className="hello-div">Hello {user_data.name},</div>
            <div className="hello-sub-div">click on the messages to view your conversation</div></div>)
    }
    return {
      payload: {
        data
      },
    }
  })

const get_user_messages = createAction('get_user_messages', function getUserMessages (action){
    if(action.type==='all'){
        const data = all_messages.filter(message=>message.userid===action.userid)
        selected_chat = data
        return {
            payload: { 
                data
            },
        }
    }else if(action.type==='unread'){
        const data = unread_messages.filter(message=>message.userid===action.userid)
        selected_chat = data
        return {
            payload: { 
                data
            },
        }
    }else if(action.type==='archived'){
        const data = archived_messages.filter(message=>message.userid===action.userid)
        selected_chat = data
        return {
            payload: { 
                data
            },
        }
    }
})

const get_selected_chat = createAction('get_user_messages', ()=>{
    return selected_chat;
})

/** create slice */
const message_slice = createSlice({
    name: "messages_data",
    initialState: {
        data: {
            "all_messages": all_messages, 
            "unread_messages": unread_messages, 
            "archived_messages": archived_messages,
            "displayed_messages": (<div><BubbleIcon /></div>)
        },
        
    },
    reducer: {
        selected_chat,
    },
    // extraReducers: {
    //     [get_user_messages]: (state, action) => {
    //         if(action.payload.type==='all')
    //             return state.data.all_messages.filter(message=>message.userid===action.payload.userid)
    //         else if(action.payload.type==='unread')
    //             return state.data.unread_messages.filter(message=>message.userid===action.payload.userid)
    //         else if(action.payload.type==='archived')
    //             return state.data.archived_messages.filter(message=>message.userid===action.payload.userid)
     //     },
        
   // },
});



export { get_user_messages, get_all_messages, get_selected_chat}
export default message_slice.reducer;