/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
/*eslint no-mixed-spaces-and-tabs: "error"*/
import DiplomaScroll from "@ui/icons/DiplomaScroll";
import ProfilePicture from "@images/profile-picture.png";
import FileIcon from "@ui/icons/FileIcon";
import MoneyDepositIcon from "@ui/icons/MoneyDepositIcon";
import TruckIcon from "@ui/icons/TruckIcon";
import MoneyWithdrawIcon from "@ui/icons/MoneyWithdrawIcon";
import dayjs from "dayjs";
import { checkNumberOfDaysLeft, priceSplitter } from "shared/utility";
import MoneyIcon from "components/ui/icons/MoneyIcon";
import PiggyBankIcon from "components/ui/icons/PiggyBankIcon";

export const tableHeaders = [
  { title: "product Id", hasIcon: false },

  { title: "product name", hasIcon: true },
  { title: "quantity", hasIcon: false },
  { title: "value", hasIcon: true },
  { title: "status", hasIcon: true },
  { title: "duration", hasIcon: true },
  { title: "bids", hasIcon: true },
];

export const OffTakerBidtableHeaders = [
  // { title: "S/N", hasIcon: false },
  { title: "auction Id", hasIcon: false },
  { title: "product name", hasIcon: true },
  { title: "date initiated", hasIcon: false },
  { title: "duration", hasIcon: true },
  { title: "bid", hasIcon: true },
  { title: "status", hasIcon: true },
];

export const OffTakerInvestmenttableHeaders = [
  { title: "S/N", hasIcon: false },
  { title: "investment Id", hasIcon: false },
  { title: "product name", hasIcon: true },
  { title: "phase", hasIcon: false },
  { title: "amount", hasIcon: true },
  { title: "type", hasIcon: true },
  { title: "status", hasIcon: true },
];

export const suspHeaders = {
  tabData: {
    data: ["All"],
    summary: "Total:",
  },
  searchInfo: {
    params: "Search suspensions",
  },
  tableHeaders: [
    { title: "User Id", hasIcon: true },
    { title: "name", hasIcon: true },
    { title: "business email", hasIcon: true },
    { title: "profile(s)", hasIcon: true },
  ],
  fields: [
    {
      name: "_id",
      isFunction: false,
    },
    {
      name: "name",
      isFunction: false,
    },
    {
      name: "email",
      isFunction: false,
    },
    {
      name: "roles",
      isFunction: false,
    },
  ],
};

export const tickHeaders = [
  { title: "Ticket No", hasIcon: false },

  { title: "title", hasIcon: true },
  { title: "priority", hasIcon: false },
  { title: "category", hasIcon: true },
  { title: "assigned to", hasIcon: true },
  { title: "date", hasIcon: true },
  { title: "status", hasIcon: true },
];

export const paymentHeaders = {
  tableHeaders: [
    { title: "name", hasIcon: true },
    { title: "reference no.", hasIcon: true },
    { title: "paid on", hasIcon: true },
    { title: "amount", hasIcon: true },
    { title: "channel", hasIcon: true },
    { title: "remark", hasIcon: true },
    { title: "status", hasIcon: true },
  ],
  fields: [
    // {
    //   name: "_id",
    //   func: (record) => {
    //     return record?._id?.slice(0, 8).toUpperCase();
    //   },
    //   isFunction: true,
    // },
    {
      name: "name",
      isFunction: false,
    },
    // {
    //   name: "location",
    //   func: (record) => {
    //     return `${record?.location.latitude} , ${record?.location?.longitude}`;
    //   },
    //   isFunction: true,
    // },
    {
      name: "payment_id",
      func: (record) => {
        return record?.payment_id?.slice(0, 8).toUpperCase();
      },
      isFunction: true,
    },

    {
      name: "created_at",
      func: (record) => {
        return dayjs(record?.created_at).format("DD/MM/YYYY");
      },
      isFunction: true,
    },
    {
      name: "amount",
      func: (record) => {
        return priceSplitter(record?.amount) + " USD";
      },
      isFunction: true,
    },
    {
      name: "payment_mode",
      isFunction: false,
    },
    {
      name: "remark",
      isFunction: false,
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};

export const curierHeader = [
  // { title: "slug", hasIcon: true },
  { title: "name", hasIcon: true },
  // { title: "phone", hasIcon: true },
  // { title: "other name", hasIcon: true },
  { title: "website", hasIcon: true },
  // { title: "tracking api url", hasIcon: true },
  { title: "service from country", hasIcon: true },
];

export const unit_of_measurement = [
  { id: "mT", slug: "metric tonne" },

  { id: "g", slug: "gramms" },

  { id: "oz", slug: "ounces" },

  { id: "kg", slug: "kilograms" },

  { id: "dmT", slug: "dry metric tonnes" },

  { id: "lb", slug: "pounds" },

  { id: "T", slug: "tonnes" },
];

export const shipment_options = [
  { id: "CFR", slug: "CFR (Cost and Freight)" },

  { id: "FAS", slug: "FAS (Free Alongside Ship)" },

  { id: "FOB", slug: "FOB (Free On Board)" },

  { id: "CIF", slug: "CIF (Cost, Insurance, and Freight)" },
];

export const inventories = [
  {
    name: "Zinc (Zn) Concentrate",
    id: "FE234TY21",
    quantity: "20dmT",
    price: "$500USD/dmT",
    status: "active",
    duration: "20 days left",
    bids: 2,
  },
  {
    name: "Zinc (Zn) Concentrate",
    id: "FE234TY21",
    quantity: "20dmT",
    price: "$500USD/dmT",
    status: "active",
    duration: "20 days left",
    bids: 2,
  },
  {
    name: "Zinc (Zn) Concentrate",
    id: "FE234TY21",
    quantity: "20dmT",
    price: "$500USD/dmT",
    status: "active",
    duration: "20 days left",
    bids: 2,
  },
  {
    name: "Zinc (Zn) Concentrate",
    id: "FE234TY21",
    quantity: "20dmT",
    price: "$500USD/dmT",
    status: "active",
    duration: "20 days left",
    bids: "N/A",
  },
  {
    name: "Zinc (Zn) Concentrate",
    id: "FE234TY21",
    quantity: "20dmT",
    price: "$500USD/dmT",
    status: "active",
    duration: "20 days left",
    bids: 2,
  },
  {
    name: "Zinc (Zn) Concentrate",
    id: "FE234TY21",
    quantity: "20dmT",
    price: "$500USD/dmT",
    status: "active",
    duration: "20 days left",
    bids: 2,
  },
  {
    name: "Zinc (Zn) Concentrate",
    id: "FE234TY21",
    quantity: "20dmT",
    price: "$500USD/dmT",
    status: "active",
    duration: "20 days left",
    bids: 2,
  },
  {
    name: "Zinc (Zn) Concentrate",
    id: "FE234TY21",
    quantity: "20dmT",
    price: "$500USD/dmT",
    status: "active",
    duration: "20 days left",
    bids: 2,
  },
  {
    name: "Zinc (Zn) Concentrate",
    id: "FE234TY21",
    quantity: "20dmT",
    price: "$500USD/dmT",
    status: "active",
    duration: "20 days left",
    bids: 2,
  },
  {
    name: "Zinc (Zn) Concentrate",
    id: "FE234TY21",
    quantity: "20dmT",
    price: "$500USD/dmT",
    status: "active",
    duration: "20 days left",
    bids: 2,
  },
];

export const tendersTableHeaders = [
  { title: "product Id", hasIcon: true },
  { title: "product name", hasIcon: true },
  { title: "date initiated", hasIcon: true },
  { title: "duration", hasIcon: true },
  { title: "value", hasIcon: true },
  { title: "bids", hasIcon: true },
  { title: "status", hasIcon: true },
];

export const tenderData = [
  {
    id: "FE234TY21",
    name: "Copper (Cu) Concentrate",
    date: "21 Mar 2022",
    duration: "20 days left",
    value: "2000 USD",
    bids: 10,
    status: "active",
  },
  {
    id: "FE234TY21",
    name: "Copper (Cu) Concentrate",
    date: "21 Mar 2022",
    duration: "20 days left",
    value: "2000 USD",
    bids: 10,
    status: "archived",
  },
  {
    id: "FE234TY21",
    name: "Copper (Cu) Concentrate",
    date: "21 Mar 2022",
    duration: "20 days left",
    value: "2000 USD",
    bids: 10,
    status: "active",
  },
  {
    id: "FE234TY21",
    name: "Copper (Cu) Concentrate",
    date: "21 Mar 2022",
    duration: "20 days left",
    value: "2000 USD",
    bids: 10,
    status: "archived",
  },
  {
    id: "FE234TY21",
    name: "Copper (Cu) Concentrate",
    date: "21 Mar 2022",
    duration: "20 days left",
    value: "2000 USD",
    bids: 10,
    status: "active",
  },
  {
    id: "FE234TY21",
    name: "Copper (Cu) Concentrate",
    date: "21 Mar 2022",
    duration: "20 days left",
    value: "2000 USD",
    bids: 10,
    status: "archived",
  },
  {
    id: "FE234TY21",
    name: "Copper (Cu) Concentrate",
    date: "21 Mar 2022",
    duration: "20 days left",
    value: "2000 USD",
    bids: 10,
    status: "active",
  },
  {
    id: "FE234TY21",
    name: "Copper (Cu) Concentrate",
    date: "21 Mar 2022",
    duration: "20 days left",
    value: "2000 USD",
    bids: 10,
    status: "active",
  },
  {
    id: "FE234TY21",
    name: "Copper (Cu) Concentrate",
    date: "21 Mar 2022",
    duration: "20 days left",
    value: "2000 USD",
    bids: 10,
    status: "active",
  },
];

export const transactionsTableHeaders = [
  { title: "transaction id", hasIcon: true },
  { title: "product name", hasIcon: true },
  { title: "name of seller", hasIcon: true },
  { title: "date", hasIcon: true },
  { title: "value", hasIcon: true },
  { title: "status", hasIcon: true },
];

export const transactionsData = [
  {
    id: "FE234TY21",
    name: "Zinc (Zn) Concentrate",
    date: "21 Mar 2022",
    buyer: "Carol Denvers",
    value: "2000 USD",
    status: "pending",
  },
  {
    id: "FE234TY21",
    name: "Zinc (Zn) Concentrate",
    date: "21 Mar 2022",
    buyer: "Carol Denvers",
    value: "2000 USD",
    status: "pending",
  },
  {
    id: "FE234TY21",
    name: "Zinc (Zn) Concentrate",
    date: "21 Mar 2022",
    buyer: "Carol Denvers",
    value: "2000 USD",
    status: "awaiting POP",
  },
  {
    id: "FE234TY21",
    name: "Zinc (Zn) Concentrate",
    date: "21 Mar 2022",
    buyer: "Carol Denvers",
    value: "2000 USD",
    status: "awaiting POC",
  },
  {
    id: "FE234TY21",
    name: "Zinc (Zn) Concentrate",
    date: "21 Mar 2022",
    buyer: "Carol Denvers",
    value: "2000 USD",
    status: "in transit",
  },
  {
    id: "FE234TY21",
    name: "Zinc (Zn) Concentrate",
    date: "21 Mar 2022",
    buyer: "Carol Denvers",
    value: "2000 USD",
    status: "archived",
  },
  {
    id: "FE234TY21",
    name: "Zinc (Zn) Concentrate",
    date: "21 Mar 2022",
    buyer: "Carol Denvers",
    value: "2000 USD",
    status: "pending",
  },
  {
    id: "FE234TY21",
    name: "Zinc (Zn) Concentrate",
    date: "21 Mar 2022",
    buyer: "Carol Denvers",
    value: "2000 USD",
    status: "pending",
  },
  {
    id: "FE234TY21",
    name: "Zinc (Zn) Concentrate",
    date: "21 Mar 2022",
    buyer: "Carol Denvers",
    value: "2000 USD",
    status: "pending",
  },
];

export const timelineSteps = [
  {
    icon: <DiplomaScroll fill_="black" h_="16px" w="16px" />,
    label: (
      <div className="flex font-normal items-center">
        <img
          className="max-w-[24px] mr-[8px]"
          src={ProfilePicture}
          alt="Miley Cyrus"
        />
        <span className="font-[300] text-[#1b2b41a3]">
          <span className="text-black">You</span> awarded bid to{" "}
          <span className="text-black">Gary Simpson</span> on 19 Mar, 2022
        </span>
      </div>
    ),
  },
  {
    icon: <FileIcon fill_="black" h_="16px" w="16px" />,
    label: (
      <div className="flex font-normal items-center">
        <img
          className="max-w-[24px] mr-[8px]"
          src={ProfilePicture}
          alt="Miley Cyrus"
        />
        <span className="font-[300] text-[#1b2b41a3]">
          <span className="text-black">You</span> awarded bid to{" "}
          <span className="text-black">Gary Simpson</span> on 19 Mar, 2022
        </span>
      </div>
    ),
  },
  {
    icon: <MoneyDepositIcon fill_="black" h_="16px" w="16px" />,
    label: (
      <div className="flex font-normal items-center">
        <img
          className="max-w-[24px] mr-[8px]"
          src={ProfilePicture}
          alt="Miley Cyrus"
        />
        <span className="font-[300] text-[#1b2b41a3]">
          <span className="text-black">You</span> awarded bid to{" "}
          <span className="text-black">Gary Simpson</span> on 19 Mar, 2022
        </span>
      </div>
    ),
  },
  {
    icon: <FileIcon fill_="black" h_="16px" w="16px" />,
    label: (
      <div className="flex font-normal items-center">
        <img
          className="max-w-[24px] mr-[8px]"
          src={ProfilePicture}
          alt="Miley Cyrus"
        />
        <span className="font-[300] text-[#1b2b41a3]">
          <span className="text-black">You</span> awarded bid to{" "}
          <span className="text-black">Gary Simpson</span> on 19 Mar, 2022
        </span>
      </div>
    ),
  },
  {
    icon: <TruckIcon fill_="black" h_="16px" w="16px" />,
    label: (
      <div className="flex font-normal items-center">
        <img
          className="max-w-[24px] mr-[8px]"
          src={ProfilePicture}
          alt="Miley Cyrus"
        />
        <span className="font-[300] text-[#1b2b41a3]">
          <span className="text-black">You</span> awarded bid to{" "}
          <span className="text-black">Gary Simpson</span> on 19 Mar, 2022
        </span>
      </div>
    ),
  },
  {
    icon: <TruckIcon fill_="black" h_="16px" w="16px" />,
    label: (
      <div className="flex font-normal items-center">
        <img
          className="max-w-[24px] mr-[8px]"
          src={ProfilePicture}
          alt="Miley Cyrus"
        />
        <span className="font-[300] text-[#1b2b41a3]">
          <span className="text-black">You</span> awarded bid to{" "}
          <span className="text-black">Gary Simpson</span> on 19 Mar, 2022
        </span>
      </div>
    ),
  },
  {
    icon: <TruckIcon fill_="black" h_="16px" w="16px" />,
    label: (
      <div className="flex font-normal items-center">
        <img
          className="max-w-[24px] mr-[8px]"
          src={ProfilePicture}
          alt="Miley Cyrus"
        />
        <span className="font-[300] text-[#1b2b41a3]">
          <span className="text-black">You</span> awarded bid to{" "}
          <span className="text-black">Gary Simpson</span> on 19 Mar, 2022
        </span>
      </div>
    ),
  },
  {
    icon: <TruckIcon fill_="black" h_="16px" w="16px" />,
    label: (
      <div className="flex font-normal items-center">
        <img
          className="max-w-[24px] mr-[8px]"
          src={ProfilePicture}
          alt="Miley Cyrus"
        />
        <span className="font-[300] text-[#1b2b41a3]">
          <span className="text-black">You</span> awarded bid to{" "}
          <span className="text-black">Gary Simpson</span> on 19 Mar, 2022
        </span>
      </div>
    ),
  },
  {
    icon: <MoneyWithdrawIcon fill_="black" h_="16px" w="16px" />,
    label: (
      <div className="flex font-normal items-center">
        <img
          className="max-w-[24px] mr-[8px]"
          src={ProfilePicture}
          alt="Miley Cyrus"
        />
        <span className="font-[300] text-[#1b2b41a3]">
          <span className="text-black">You</span> awarded bid to{" "}
          <span className="text-black">Gary Simpson</span> on 19 Mar, 2022
        </span>
      </div>
    ),
  },
];

export const projectsTableHeaders = [
  { title: "PROJECT ID", hasIcon: true },
  { title: "JOB TITLE", hasIcon: true },
  { title: "NAME OF CLIENT", hasIcon: true },
  { title: "PROJECT TYPE", hasIcon: true },
  { title: "CATEGORY", hasIcon: true },
  { title: "STATUS", hasIcon: true },
];
export const offersTableHeaders = [
  { title: "OFFER ID", hasIcon: true },
  { title: "JOB TITLE", hasIcon: true },
  { title: "PROJECT TYPE", hasIcon: true },
  { title: "CATEGORY", hasIcon: true },
  { title: "INTERESTS", hasIcon: true },
  { title: "STATUS", hasIcon: true },
];

export const directoryTableHeaders = {
  tableHeaders: [
    { title: "Name", hasIcon: true },
    { title: "TYPE", hasIcon: true },
    // { title: "Location", hasIcon: true },
    { title: "Address", hasIcon: true },
    { title: "Country", hasIcon: true },
    // { title: "Product Type", hasIcon: true },
    { title: "Status", hasIcon: true },
  ],
  fields: [
    {
      name: "name",
      isFunction: false,
    },
    {
      name: "type",
      isFunction: false,
    },
    // {
    //   name: "location",
    //   func: (record) => {
    //     return `${record?.location.latitude} , ${record?.location?.longitude}`;
    //   },
    //   isFunction: true,
    // },
    {
      name: "address",

      isFunction: false,
    },

    {
      name: "country",
      isFunction: false,
    },
    // {
    //   name: "product_type",
    //   isFunction: false,
    // },

    {
      name: "status",
      func: (record) => {
        if (record.status == "Yes") {
          return "Connected";
        } else {
          return "Disconnected";
        }
      },
      isFunction: true,
    },
  ],
};

export const adminAuctions = {
  tabData: {
    data: ["All", "Archived"],
  },
  searchInfo: {
    params: "Search Auctions",
  },
};

export const adminOffers = {
  tabData: {
    data: ["All", "Archived"],
  },
  searchInfo: {
    params: "Search Investment offers",
  },
};

export const adminVendors = {
  tabData: {
    data: ["All", "Archived"],
  },
  searchInfo: {
    params: "Search Vendors",
  },
};

export const adminMineralInfo = {
  tabData: {
    data: ["All", "active", "inactive"],
    summary: "Total Minerals:",
  },
  searchInfo: {
    params: "Search minerals",
  },
  tableHeaders: [
    // { title: "S/N", hasIcon: false },
    { title: "MINERAL NAME", hasIcon: true },
    { title: "DESCRIPTION", hasIcon: true },
    { title: "DATE ADDED", hasIcon: true },
    { title: "STATUS", hasIcon: true },
  ],
  fields: [
    {
      name: "name",
      isFunction: false,
    },
    {
      name: "description",
      isFunction: false,
    },
    {
      name: "date",
      func: (record) => {
        return dayjs(record?.created_at).format("DD/MM/YYYY");
      },
      isFunction: true,
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};


export const adminmineTableInfo = {
  tabData: {
    data: ["All", "Active", "Suspended"],
    summary: "Total Mines:",
  },
  searchInfo: {
    params: "Search mines by mine name",
  },
  tableHeaders: [
    // { title: "S/N", hasIcon: false },
    { title: "MINE Name", hasIcon: true },
    { title: "MINE TYPE", hasIcon: true },
    { title: "LOCATION", hasIcon: true },
    { title: "DATE ADDED", hasIcon: true },
    { title: "STATUS", hasIcon: true },
  ],
  fields: [
    {
      name: "mine_name",
      isFunction: false,
    },
    {
      name: "mine_type",
      isFunction: false,
    },
    {
      name: "country",
      func: (record) => {
        return record?.country?.name;
      },
      isFunction: true,
    },
    {
      name: "date",
      func: (record) => {
        return dayjs(record?.created_at).format("DD/MM/YYYY");
      },
      isFunction: true,
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};

export const adminInteractionTendersTable = {
  tabData: {
    data: ["All", "Active", "Archived"],
    // summary: "Total Tenders:",
  },
  searchInfo: {
    params: "Search tenders",
  },
  tableHeaders: [
    // { title: "S/N", hasIcon: false },
    { title: "PRODUCT ID", hasIcon: true },
    { title: "PRODUCT NAME", hasIcon: true },
    // { title: "BY", hasIcon: false },
    { title: "DATE INITIATED", hasIcon: true },
    { title: "VALUE", hasIcon: true },
    { title: "BIDS", hasIcon: true },
    { title: "STATUS", hasIcon: true },
  ],
  fields: [
    {
      name: "product_id",
      isFunction: false,
    },
    {
      name: "product_name",
      isFunction: false,
    },
    {
      name: "date",
      func: (record) => {
        return dayjs(record?.created_at).format("DD/MM/YYYY");
      },
      isFunction: true,
    },
    {
      name: "total_value",
      isFunction: false,
    },
    {
      name: "bids",
      isFunction: false,
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};

export const adminInteractionInvestmentTable = {
  tabData: {
    data: ["All", "Active", "Archived"],
    // summary: "Total Tenders:",
  },
  searchInfo: {
    params: "Search Investments",
  },
  tableHeaders: [
    // { title: "S/N", hasIcon: false },
    { title: "Investment ID", hasIcon: true },
    { title: "PRODUCT NAME", hasIcon: true },
    // { title: "BY", hasIcon: false },
    { title: "PHASE", hasIcon: true },
    { title: "AMOUNT", hasIcon: true },
    { title: "TYPE", hasIcon: true },
    { title: "STATUS", hasIcon: true },
  ],
  fields: [
    {
      name: "id",
      isFunction: true.valueOf,
      func: (record) => {
        return record?._id?.slice(0, 8).toUpperCase();
      },
    },
    {
      name: "product_name",
      isFunction: true.valueOf,
      func: (record) => {
        return record?.proposal?.product_name;
      },
    },
    {
      name: "production_phase",
      func: (record) => {
        return record?.proposal?.production_phase?.slug;
      },
      isFunction: true,
    },
    {
      name: "amount",
      func: (record) => {
        return priceSplitter(record?.proposal?.amount) + " USD";
      },
      isFunction: true,
    },
    {
      name: "TYPE",
      func: (record) => {
        return record?.proposal?.investment_type?.slug;
      },
      isFunction: true,
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};

export const adminInteractionTransactionsTable = {
  tabData: {
    data: [
      "All",
      "Completed",
      "Pending",
      "Awaiting POP",
      "Awaiting POC",
      "Awaiting POF",
      "In Transit",
      "Delivered",
    ],
    summary: "Total Transactions:",
  },
  searchInfo: {
    params: "Search transactions",
  },
  tableHeaders: [
    { title: "TRANSACTION ID", hasIcon: true },
    { title: "PRODUCT NAME", hasIcon: true },
    { title: "FROM", hasIcon: false },
    { title: "TO", hasIcon: false },
    { title: "VALUE", hasIcon: true },
    { title: "STATUS", hasIcon: true },
  ],
  fields: [
    {
      name: "transaction_id",
      isFunction: false,
    },
    {
      name: "product_name",
      func: (record) => {
        return record?.product?.product_name;
      },
      isFunction: true,
    },
    {
      name: "from",
      func: (record) => {
        return record?.seller?.name;
      },
      isFunction: true,
    },
    {
      name: "to",
      func: (record) => {
        return record?.buyer?.name;
      },
      isFunction: true,
    },
    {
      name: "value",
      func: (record) => {
        return record?.product?.product_total_value;
      },
      isFunction: true,
    },
    {
      name: "status",
      func: (record) => {
        return record?.status[record?.status?.length - 1]?.status;
      },
      isFunction: true,
    },
  ],
};

export const rolesTableHeaders = [
  { title: "TITLE", hasIcon: true, col: "col-start-2 col-end-4" },
  { title: "PERMISSIONS", hasIcon: true, col: "col-start-4 col-end-6" },
  { title: "TEAM MEMBERS", hasIcon: true, col: "col-start-6 col-end-8" },
];

export const rolesTableHeadersForAdmin = [
  { title: "TITLE", hasIcon: true, col: "col-start-2 col-end-4" },
  { title: "PERMISSIONS", hasIcon: true, col: "col-start-4 col-end-6" },
  { title: "TEAM MEMBERS", hasIcon: true, col: "col-start-6 col-end-8" },
];

export const fundingTableHeaders = [
  { title: "funding id", hasIcon: true },
  { title: "product name", hasIcon: true },
  { title: "phase", hasIcon: true },
  { title: "type", hasIcon: true },
  { title: "status", hasIcon: true },
];
export const projectTableHeaders = [
  { title: "project id", hasIcon: true },
  { title: "product category", hasIcon: true },
  { title: "name of vendor", hasIcon: true },
  { title: "date started", hasIcon: true },
  { title: "amount", hasIcon: true },
  { title: "status", hasIcon: true },
];

export const offeringsTableHeaders = [
  { title: "Offering ID", hasIcon: true },
  { title: "JOB TITLE", hasIcon: false },
  { title: "OFFERING TYPE", hasIcon: true },
  { title: "CATEGORY", hasIcon: true },
  { title: "VALUE", hasIcon: true },
  { title: "LOCATIONS", hasIcon: true },
];

export const walletInfo = {
  tabData: {
    data: ["All"],
    summary: "Total:",
  },
  statInfo: [
    {
      name: "WALLET BALANCE",
      icon: <MoneyIcon />,
      key: "wallet_balance",
    },
    {
      name: "TOTAL PAYMENTS",
      icon: <MoneyIcon />,
      key: "total_debit",
    },
    {
      name: "TOTAL DEPOSITS",
      icon: <MoneyIcon />,
      key: "total_credit",
    },
  ],
  searchInfo: {
    params: "Search wallet log",
  },
  tableHeaders: [
    { title: "payment id", hasIcon: true },
    { title: "payment for", hasIcon: true },
    { title: "paid on", hasIcon: true },
    { title: "amount (usd)", hasIcon: true },
    { title: "status", hasIcon: true },
  ],
  fields: [
    {
      name: "payment_id",
      isFunction: false,
    },
    {
      name: "type",
      isFunction: false,
    },
    {
      name: "created_at",
      isFunction: true,
      func: (record) => {
        return dayjs(record?.created_at).format("DD/MM/YYYY");
      },
    },
    {
      name: "amount",
      isFunction: true,
      func: (record) => {
        return parseFloat(record.amount).toFixed(2);
      },
    },

    {
      name: "status",
      isFunction: false,
    },
  ],
};

export const inventoryTableInfo = {
  // statInfo: [
  //   {
  //     name: "ALL TIME OFFERS",
  //     icon: <MoneyIcon />,
  //     key: "all_time_offers",
  //   },
  //   {
  //     name: "OFFERS TODAY",
  //     icon: <PiggyBankIcon />,
  //     key: "offers_today",
  //   },
  //   {
  //     name: "ACCEPTED OFFERS",
  //     icon: <MoneyWithdrawIcon />,
  //     key: "accepted_offers",
  //   },
  // ],
  tabData: {
    data: ["All", "Active", "Inactive", "Closed", "Ongoing"],
    summary: "Total items in Inventory:",
  },

  searchInfo: {
    params: "Search inventory",
  },
  tableHeaders: [
    { title: "product id", hasIcon: false },
    { title: "product name", hasIcon: true },
    { title: "quantity", hasIcon: false },
    { title: "value", hasIcon: true },
    { title: "status", hasIcon: true },
    { title: "duration", hasIcon: true },
    { title: "bids", hasIcon: true },
  ],
  fields: [
    {
      name: "product_id",
      isFunction: false,
    },
    {
      name: "product_name",
      isFunction: false,
    },
    {
      name: "quantity",
      isFunction: true,
      func: (record) => {
        return `${record.quantity} ${record.unit_of_measurement?.id}`;
      },
    },

    {
      name: "total_value",
      isFunction: true,
      func: (record) => {
        return priceSplitter(record?.total_value) + " USD";
      },
    },
    {
      name: "status",
      isFunction: false,
    },
    {
      name: "duration",
      isFunction: true,
      func: (record) => {
        return checkNumberOfDaysLeft(record.end_date, record.start_date);
      },
    },
    {
      name: "bids",
      isFunction: false,
    },
  ],
};

export const vendorProjectsTableInfo = {
  tabData: {
    data: ["All", "Completed", "Pending", "Ongoing"],
    summary: "Total Projects:",
  },
  statInfo: [
    {
      name: "PROJECTS TODAY",
      icon: <MoneyIcon />,
      key: "project_today",
    },
    {
      name: "ALL TIME PROJECTS",
      icon: <PiggyBankIcon />,
      key: "all_time_projects",
    },
    {
      name: "COMPLETED PROJECTS",
      icon: <MoneyWithdrawIcon />,
      key: "completed_projects",
    },
  ],
  searchInfo: {
    params: "Search projects",
  },
  tableHeaders: [
    { title: "project id", hasIcon: true },
    { title: "job title", hasIcon: true },
    { title: "name of client", hasIcon: true },
    { title: "project type", hasIcon: true },
    { title: "category", hasIcon: true },
    { title: "status", hasIcon: true },
  ],
  fields: [
    {
      name: "project_id",
      isFunction: false,
    },
    {
      name: "title",
      isFunction: false,
    },
    {
      name: "client name",
      func: (record) => {
        return record?.client?.name;
      },
      isFunction: true,
    },
    {
      name: "type",
      isFunction: false,
    },
    {
      name: "category",
      isFunction: true,
      func: (record) => {
        return record?.category?.name;
      },
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};

export const adminProjectsTableInfo = {
  tabData: {
    data: ["All", "Completed", "Pending", "Archived"],
    summary: "Total Projects:",
  },
  searchInfo: {
    params: "Search projects",
  },
  tableHeaders: [
    { title: "project id", hasIcon: true },
    { title: "job title", hasIcon: true },
    { title: "name of vendor", hasIcon: true },
    { title: "project type", hasIcon: true },
    { title: "category", hasIcon: true },
    { title: "status", hasIcon: true },
  ],
  fields: [
    {
      name: "project_id",
      isFunction: false,
    },
    {
      name: "title",
      isFunction: false,
    },
    {
      name: "client name",
      func: (record) => {
        return record?.vendor?.name;
      },
      isFunction: true,
    },
    {
      name: "type",
      isFunction: false,
    },
    {
      name: "category",
      isFunction: true,
      func: (record) => {
        return record?.category?.name;
      },
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};

export const minerProjectsTableInfo = {
  tabData: {
    data: ["All", "Completed", "Pending", "Ongoing"],
    summary: "Total Projects:",
  },
  statInfo: [
    {
      name: "PROJECTS TODAY",
      icon: <MoneyIcon />,
      key: "project_today",
    },
    {
      name: "ALL TIME PROJECTS",
      icon: <PiggyBankIcon />,
      key: "all_time_projects",
    },
    {
      name: "COMPLETED PROJECTS",
      icon: <MoneyWithdrawIcon />,
      key: "completed_projects",
    },
  ],
  searchInfo: {
    params: "Search projects",
  },
  tableHeaders: [
    { title: "project id", hasIcon: true },
    { title: "job title", hasIcon: true },
    { title: "name of vendor", hasIcon: true },
    { title: "project type", hasIcon: true },
    { title: "category", hasIcon: true },
    { title: "status", hasIcon: true },
  ],
  fields: [
    {
      name: "project_id",
      isFunction: false,
    },
    {
      name: "title",
      isFunction: false,
    },
    {
      name: "client name",
      func: (record) => {
        return record?.vendor?.name;
      },
      isFunction: true,
    },
    {
      name: "type",
      isFunction: false,
    },
    {
      name: "category",
      isFunction: true,
      func: (record) => {
        return record?.category?.name;
      },
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};
export const offtakersProjectsTableInfo = {
  tabData: {
    data: ["All", "Completed", "Pending", "Archived"],
    summary: "Total Projects:",
  },
  statInfo: [
    {
      name: "PROJECTS TODAY",
      icon: <MoneyIcon />,
      key: "project_today",
    },
    {
      name: "ALL TIME PROJECTS",
      icon: <PiggyBankIcon />,
      key: "all_time_projects",
    },
    {
      name: "COMPLETED PROJECTS",
      icon: <MoneyWithdrawIcon />,
      key: "completed_projects",
    },
  ],
  searchInfo: {
    params: "Search projects",
  },
  tableHeaders: [
    { title: "project id", hasIcon: true },
    { title: "job title", hasIcon: true },
    { title: "name of vendor", hasIcon: true },
    { title: "project type", hasIcon: true },
    { title: "category", hasIcon: true },
    { title: "status", hasIcon: true },
  ],
  fields: [
    {
      name: "project_id",
      isFunction: false,
    },
    {
      name: "title",
      isFunction: false,
    },
    {
      name: "vendor name",
      func: (record) => {
        return record?.vendor?.name;
      },
      isFunction: true,
    },
    {
      name: "type",
      isFunction: false,
    },
    {
      name: "category",
      isFunction: true,
      func: (record) => {
        return record?.category?.name;
      },
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};

//offerings
export const offeringsTableInfo = {
  tabData: {
    data: ["All"],
    summary: "Total Offerings:",
  },
  searchInfo: {
    params: "Search offerings by category and type",
  },
  tableHeaders: [
    { title: "Offering ID", hasIcon: true },
    { title: "JOB TITLE", hasIcon: false },
    { title: "OFFERING TYPE", hasIcon: true },
    { title: "CATEGORY", hasIcon: true },
    { title: "VALUE", hasIcon: true },
    { title: "LOCATIONS", hasIcon: true },
  ],
  fields: [
    {
      name: "offering_id",
      isFunction: false,
      // func: (record) => {
      //   return truncate(record.offering_id, 10);
      // },
    },
    {
      name: "job_title",
      isFunction: false,
    },
    {
      name: "type",
      isFunction: false,
    },
    {
      name: "category",
      isFunction: false,
    },
    {
      name: "price",
      func: (record) => {
        if (record.price) {
          return `${record.price} USD`;
        }
      },
      isFunction: true,
    },
    {
      name: "location",
      isFunction: true,
      func: (record) => {
        return record?.location.length;
      },
    },
  ],
};

export const offtakersBidsTableInfo = {
  tabData: {
    data: ["All", "Active", "Awarded", "Archived"],
    summary: "Total Bids:",
  },

  searchInfo: {
    params: "Search bids",
  },
  tableHeaders: [
    { title: "auction Id", hasIcon: false },
    { title: "product name", hasIcon: true },
    { title: "date initiated", hasIcon: true },
    { title: "duration", hasIcon: true },
    { title: "amount", hasIcon: true },
    { title: "status", hasIcon: true },
  ],
  fields: [
    {
      name: "transaction_id",
      isFunction: true,
      func: (record) => {
        return record?.product?.product_id;
      },
    },
    {
      name: "product_name",
      isFunction: true,
      func: (record) => {
        return record?.product?.product_name;
      },
    },
    {
      name: "date",
      func: (record) => {
        return dayjs(record?.product.start_date).format("DD/MM/YYYY");
      },
      isFunction: true,
    },
    {
      name: "duration",
      func: (record) => {
        return record.product.end_date && record.product.start_date
          ? checkNumberOfDaysLeft(
              record.product.end_date,
              record.product.start_date
            )
          : "N/A";
      },
      isFunction: true,
    },
    {
      name: "amount",
      func: (record) => {
        return (priceSplitter(record?.amount) ?? 0) + " USD";
      },
      isFunction: true,
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};

export const offtakersTransactionsTableInfo = {
  tabData: {
    data: [
      "All",
      "Completed",
      "Pending",
      "Awaiting POP",
      "Awaiting POC",
      "Awaiting POF",
      "In Transit",
      "Delivered",
    ],
    summary: "Total Transactions:",
  },

  searchInfo: {
    params: "Search transactions",
  },
  tableHeaders: [
    { title: "transaction id", hasIcon: true },
    { title: "product name", hasIcon: true },
    { title: "name of seller", hasIcon: true },
    { title: "date", hasIcon: true },
    { title: "value", hasIcon: true },
    { title: "status", hasIcon: true },
  ],
  fields: [
    {
      name: "transaction_id",
      isFunction: false,
    },
    {
      name: "product_name",
      isFunction: true,
      func: (record) => {
        return record?.product?.product_name;
      },
    },
    {
      name: "seller name",
      func: (record) => {
        return record?.seller?.name;
      },
      isFunction: true,
    },

    {
      name: "date",
      func: (record) => {
        return dayjs(record?.created_at).format("DD/MM/YYYY");
      },
      isFunction: true,
    },
    {
      name: "product_total_value",
      func: (record) => {
        return priceSplitter(record?.product?.product_total_value) + " USD";
      },
      isFunction: true,
    },
    {
      name: "status",
      func: (record) => {
        return record?.status[record.status.length - 1].status;
      },
      isFunction: true,
    },
  ],
};
export const tendersTableInfo = {
  tabData: {
    data: ["All"],
    summary: "Total Tenders:",
  },

  searchInfo: {
    params: "Search tenders",
  },
  tableHeaders: [
    { title: "product Id", hasIcon: false },
    { title: "product name", hasIcon: true },
    { title: "quantity", hasIcon: false },
    { title: "value", hasIcon: true },
    { title: "status", hasIcon: true },
    { title: "duration", hasIcon: true },
    { title: "bids", hasIcon: true },
  ],
  fields: [
    {
      name: "product_id",
      isFunction: false,
    },
    {
      name: "product_name",
      isFunction: false,
    },
    {
      name: "quantity",
      isFunction: true,
      func: (record) => {
        return `${record.quantity} ${record.unit_of_measurement?.id}`;
      },
    },
    {
      name: "product_total_value",
      isFunction: true,
      func: (record) => {
        return `${record.total_value} USD/${record.unit_of_measurement?.id}`;
      },
    },
    {
      name: "status",

      isFunction: false,
    },
    {
      name: "duration",
      func: (record) => {
        return record.end_date && record.start_date
          ? checkNumberOfDaysLeft(record.end_date, record.start_date)
          : "N/A";
      },
      isFunction: true,
    },
    {
      name: "bids",
      isFunction: false,
    },
  ],
};

export const offersTableInfo = {
  statInfo: [
    {
      name: "ALL TIME OFFERS",
      icon: <MoneyIcon />,
      key: "all_time_offers",
    },
    {
      name: "OFFERS TODAY",
      icon: <PiggyBankIcon />,
      key: "offers_today",
    },
    {
      name: "ACCEPTED OFFERS",
      icon: <MoneyWithdrawIcon />,
      key: "accepted_offers",
    },
  ],
  tabData: {
    data: ["All", "Pending", "Ongoing", "Closed"],
    summary: "Total Offers:",
  },

  searchInfo: {
    params: "Search offers",
  },
  tableHeaders: [
    { title: "offer id", hasIcon: true },
    { title: "job title", hasIcon: true },
    { title: "project type", hasIcon: true },
    { title: "category", hasIcon: true },
    { title: "interests", hasIcon: true },
    { title: "status", hasIcon: true },
  ],
  fields: [
    {
      name: "service_request_id",
      isFunction: false,
    },
    {
      name: "title",
      isFunction: false,
    },
    {
      name: "type",
      isFunction: false,
    },
    {
      name: "category",
      isFunction: true,
      func: (record) => {
        return record?.category?.name;
      },
    },
    {
      name: "interest",
      isFunction: false,
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};
export const offtakerOffersTableInfo = {
  statInfo: [
    {
      name: "ALL TIME OFFERS",
      icon: <MoneyIcon />,
      key: "all_time_offers",
    },
    {
      name: "OFFERS TODAY",
      icon: <PiggyBankIcon />,
      key: "offers_today",
    },
    {
      name: "ACCEPTED OFFERS",
      icon: <MoneyWithdrawIcon />,
      key: "accepted_offers",
    },
  ],
  tabData: {
    data: ["All", "Closed", "Pending", "Ongoing"],
    summary: "Total Offers:",
  },

  searchInfo: {
    params: "Search offers",
  },
  tableHeaders: [
    { title: "offer id", hasIcon: true },
    { title: "job title", hasIcon: true },
    { title: "project type", hasIcon: true },
    { title: "category", hasIcon: true },
    { title: "interests", hasIcon: true },
    { title: "status", hasIcon: true },
  ],
  fields: [
    {
      name: "service_request_id",
      isFunction: false,
    },
    {
      name: "title",
      isFunction: false,
    },
    {
      name: "type",
      isFunction: false,
    },
    {
      name: "category",
      isFunction: true,
      func: (record) => {
        return record?.category.name;
      },
    },
    {
      name: "interest",
      isFunction: false,
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};
export const vendorOffersTableInfo = {
  statInfo: [
    {
      name: "ALL TIME OFFERS",
      icon: <MoneyIcon />,
      key: "all_time_offers",
    },
    {
      name: "OFFERS TODAY",
      icon: <PiggyBankIcon />,
      key: "offers_today",
    },
    {
      name: "ACCEPTED OFFERS",
      icon: <MoneyWithdrawIcon />,
      key: "accepted_offers",
    },
  ],
  tabData: {
    data: ["All", "Completed", "Pending", "Archived"],
    summary: "Total Offers:",
  },

  searchInfo: {
    params: "Search offers",
  },
  tableHeaders: [
    { title: "offer id", hasIcon: true },
    { title: "job title", hasIcon: true },
    { title: "project type", hasIcon: true },
    { title: "category", hasIcon: true },
    { title: "interests", hasIcon: true },
    { title: "status", hasIcon: true },
  ],
  fields: [
    {
      name: "service_request_id",
      isFunction: false,
    },
    {
      name: "title",
      isFunction: false,
    },
    {
      name: "type",
      isFunction: false,
    },
    {
      name: "category",
      isFunction: true,
      func: (record) => {
        return record?.category.name;
      },
    },
    {
      name: "interest",
      isFunction: false,
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};

export const transactionsTableInfo = {
  tabData: {
    data: [
      "All",
      "Completed",
      "Pending",
      "Awaiting POP",
      "Awaiting POC",
      "Awaiting POF",
      "In Transit",
      "Delivered",
    ],
    summary: "Total Transactions:",
  },

  searchInfo: {
    params: "Search transactions",
  },
  tableHeaders: [
    { title: "transaction id", hasIcon: true },
    { title: "product name", hasIcon: true },
    { title: "name of buyer", hasIcon: true },
    { title: "date", hasIcon: true },
    { title: "value", hasIcon: true },
    { title: "status", hasIcon: true },
  ],
  fields: [
    {
      name: "transaction_id",
      isFunction: false,
    },
    {
      name: "product_name",
      isFunction: true,
      func: (record) => {
        return record?.product?.product_name;
      },
    },
    {
      name: "buyer name",
      func: (record) => {
        return record?.buyer?.name;
      },
      isFunction: true,
    },

    {
      name: "date",
      func: (record) => {
        return dayjs(record?.created_at).format("DD/MM/YYYY");
      },
      isFunction: true,
    },
    {
      name: "product_total_value",
      func: (record) => {
        return priceSplitter(record?.product?.product_total_value) + " USD";
      },
      isFunction: true,
    },
    {
      name: "status",
      func: (record) => {
        return record?.status[record.status.length - 1].status;
      },
      isFunction: true,
    },
  ],
};

export const mineTableInfo = {
  tabData: {
    data: ["All", "Active", "Suspended"],
    summary: "Total Mines:",
  },
  searchInfo: {
    params: "Search mines by mine name",
  },
  tableHeaders: [
    // { title: "S/N", hasIcon: false },
    { title: "MINE NAME", hasIcon: true },

    { title: "MINE TYPE", hasIcon: true },
    { title: "LOCATION", hasIcon: true },
    { title: "DATE ADDED", hasIcon: true },
    { title: "STATUS", hasIcon: true },
  ],
  fields: [
    {
      name: "mine_name",
      isFunction: false,
    },
    {
      name: "mine_type",
      isFunction: false,
    },
    {
      name: "country",
      func: (record) => {
        return record?.country?.name;
      },
      isFunction: true,
    },
    {
      name: "date",
      func: (record) => {
        return dayjs(record?.created_at).format("DD/MM/YYYY");
      },
      isFunction: true,
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};

export const MinerInvestmentTableHeaders = {
  tabData: {
    data: ["All", "Pending", "Completed", "Ongoing"],
    summary: "Total Investments:",
  },
  searchInfo: {
    params: "Search investments",
  },
  tableHeaders: [
    { title: "investment Id", hasIcon: false },
    { title: "product name", hasIcon: true },
    { title: "phase", hasIcon: false },
    { title: "amount", hasIcon: true },
    { title: "type", hasIcon: true },
    { title: "status", hasIcon: true },
  ],
  fields: [
    {
      name: "id",
      isFunction: true.valueOf,
      func: (record) => {
        return record?._id?.slice(0, 8).toUpperCase();
      },
    },
    {
      name: "product_name",
      isFunction: true.valueOf,
      func: (record) => {
        return record?.proposal?.product_name;
      },
    },
    {
      name: "production_phase",
      func: (record) => {
        return record?.proposal?.production_phase?.slug;
      },
      isFunction: true,
    },
    {
      name: "amount",
      func: (record) => {
        return priceSplitter(record?.proposal?.amount) + " USD";
      },
      isFunction: true,
    },
    {
      name: "TYPE",
      func: (record) => {
        return record?.proposal?.investment_type?.slug;
      },
      isFunction: true,
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};

export const statusColorScheme = {
  Pending: {
    bg: "#FDF2E7",
    text: "#F2994A",
    //text: "ongoing",
  },
  Ongoing: {
    bg: "#FDF2E7",
    text: "#F2994A",
    //text: "ongoing",
  },
  Suspended: {
    bg: "#FFE7E5",
    text: "#F52419",
  },
  Inactive: {
    bg: "#FFE7E5",
    text: "#F52419",
  },
  inactive: {
    bg: "#FFE7E5",
    text: "#F52419",
  },
  Blocked: {
    bg: "#FFE7E5",
    text: "#F52419",
  },
  Successful: {
    bg: "#D9F2E4",
    text: "#27AE60",
  },
  Completed: {
    bg: "#D9F2E4",
    text: "#27AE60",
    //text: "completed",
  },
  Verified: {
    bg: "#D9F2E4",
    text: "#00A344",
    //text: "completed",
  },
  Connected: {
    bg: "#D9F2E4",
    text: "#43BE77",
  },
  Active: {
    bg: "#D9F2E4",
    text: "#43BE77",
    //text: "completed",
  },
  active: {
    bg: "#D9F2E4",
    text: "#43BE77",
    //text: "completed",
  },
  "Mine Active": {
    bg: "#D9F2E4",
    text: "#43BE77",
    //text: "completed",
  },
  "Product Active": {
    bg: "#D9F2E4",
    text: "#43BE77",
    //text: "completed",
  },
  Disconnected: {
    bg: "#FFE7E5",
    text: "#FF4238",
  },
  Failed: {
    bg: "#FFE7E5",
    text: "#FF4238",
    //text: "failed",
  },
  "Awaiting POP": {
    bg: "#FFE7E5",
    text: "#F52419",
  },
  "Awaiting POC": {
    bg: "#FFE7E5",
    text: "#F52419",
  },
  "Awaiting POF": {
    bg: "#FFE7E5",
    text: "#F52419",
  },
  "In Transit": {
    bg: "#EAE6FF",
    text: "#7B61FF",
  },
  Delivered: {
    bg: "#FDF2E7",
    text: "#F2994A",
  },
  Awarded: {
    bg: "#D9F2E4",
    text: "#43BE77",
  },
  Archived: {
    bg: "#FFE7E5",
    text: "#F52419",
  },
  archived: {
    bg: "#FFE7E5",
    text: "#F52419",
  },
  "Bid Active": {
    bg: "#FDF2E7",
    text: "#F2994A",
  },
  "Demand Pending": {
    bg: "#FDF2E7",
    text: "#F2994A",
  },
  "Investment Pending": {
    bg: "#FDF2E7",
    text: "#F2994A",
  },
  "Funding Active": {
    bg: "#FDF2E7",
    text: "#F2994A",
  },
  Closed: {
    bg: "#FFE7E5",
    text: "#F52419",
  },
};

export const offtakersDemandsTableInfo = {
  tabData: {
    data: ["All", "Active", "Closed"],
    summary: "Total Transactions:",
  },

  searchInfo: {
    params: "Search demands",
  },
  tableHeaders: [
    { title: "Request Id", hasIcon: false },
    { title: "product name", hasIcon: false },
    { title: "bids", hasIcon: false },
    { title: "highest offer", hasIcon: false },
    { title: "date published", hasIcon: false },
    { title: "status", hasIcon: false },
    { title: "", hasIcon: false },
    { title: "", hasIcon: false },
  ],
  fields: [
    {
      name: "request_id",
      isFunction: true,
      func: (record) => {
        return record?.demand_id ?? record?.id;
      },
    },
    {
      name: "product_name",
      isFunction: true,
      func: (record) => {
        return record?.mineral?.name;
      },
    },
    {
      name: "bids",
      func: (record) => {
        return record?.bids;
      },
      isFunction: true,
    },
    {
      name: "highest_offer",
      func: (record) => {
        return record?.price;
      },
      isFunction: true,
    },
    {
      name: "date_published",
      func: (record) => {
        return dayjs(record?.created_at).format("DD/MM/YYYY");
      },
      isFunction: true,
    },
    {
      name: "status",
      func: (record) => {
        return record?.status ? record?.status : "Active";
      },
      isFunction: true,
    },
  ],
};

export const MinerFundingTableInfo = {
  tabData: {
    data: [],
    summary: "Total Fundings:",
  },
  searchInfo: {
    params: "Search fundings",
  },
  tableHeaders: [
    { title: "mineral", hasIcon: false },
    { title: "phase", hasIcon: false },
    { title: "amount", hasIcon: true },
    { title: "type", hasIcon: true },
    { title: "status", hasIcon: false },
  ],
  fields: [
    {
      name: "mineral",
      isFunction: true.valueOf,
      func: (record) => {
        return record?.mineral?.name;
      },
    },
    {
      name: "production_phase",
      func: (record) => {
        return record?.production_phase?.slug;
      },
      isFunction: true,
    },
    {
      name: "amount",
      func: (record) => {
        return priceSplitter(record?.amount) + " USD";
      },
      isFunction: true,
    },
    {
      name: "type",
      func: (record) => {
        return record?.investment_type?.slug;
      },
      isFunction: true,
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};

export const OffTakerProspectsTableHeaders = {
  tabData: {
    data: [],
    summary: "Total Prospects:",
  },
  searchInfo: {
    params: "Search prospects",
  },
  tableHeaders: [
    { title: "mineral", hasIcon: false },
    { title: "phase", hasIcon: false },
    { title: "amount", hasIcon: true },
    { title: "type", hasIcon: false },
    { title: "status", hasIcon: false },
  ],
  fields: [
    {
      name: "mineral",
      isFunction: true.valueOf,
      func: (record) => {
        return record?.mineral?.name;
      },
    },
    {
      name: "production_phase",
      func: (record) => {
        return record?.production_phase?.slug;
      },
      isFunction: true,
    },
    {
      name: "amount",
      func: (record) => {
        return priceSplitter(record?.investment_amount) + " USD";
      },
      isFunction: true,
    },
    {
      name: "type",
      func: (record) => {
        return record?.investment_type?.slug;
      },
      isFunction: true,
    },
    {
      name: "status",
      isFunction: false,
    },
  ],
};
