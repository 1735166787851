/** @format */

import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import { placeBid } from "store/slices/offtaker-slice";
import { useDispatch } from "react-redux";
import SuccessModal from "./SuccessModal";
import { SuccessResponse } from "config/status-code";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 528,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

// eslint-disable-next-line react/prop-types
const ConfirmationModal = ({
  open,
  handleClose,
  amount,
  shipmentOption,
  setShippmentOption,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [showSuccess, setShowSuccess] = useState(false);
  const handlePlaceBid = () => {
    dispatch(placeBid({ id: params.id, amount: +amount, shipmentOption })).then(
      ({ payload }) => {
        if (SuccessResponse(payload.status)) {
          setShowSuccess(true);
          setShippmentOption(null);
        }
        handleClose(true);
        setShippmentOption(null);
      }
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center mb-4">
            <h1 className="text-xl mb-4">Confirm action</h1>
            <p className="font-light">
              You are about to place a bid for this product. Kindly confirm to
              proceed.
            </p>
          </div>
          <div className="flex justify-between">
            <Button color="neutral" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handlePlaceBid}>Confirm</Button>
          </div>
        </Box>
      </Modal>

      <div>
        <SuccessModal open={showSuccess} />
      </div>
    </div>
  );
};

export default ConfirmationModal;
