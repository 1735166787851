/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import Sidebar from "./sidebar/sidebar";
import SideBar from "./sidebar/sidebarResponsive";
import Navbar from "./navbar";
import Breadcrumb from "./breadcrumb";
import { getPermissions } from "shared/utility";
import Footer from "./footer";
import {
  AreaSelectIcon,
  Money,
  CogIcon,
  User,
  DatabaseIcon,
  DirectoryIcon,
  CourierIcon,
  ListIcon,
  Dashboard,
  Sitemap,
  Ban,
} from "@ui/icons";
import { SignInUrl } from "@config/app-url";
import DiamondIcon from '@mui/icons-material/Diamond';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllPermissions } from "@slices/roles-permissions-slice";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';


const LayoutAdmin = ({ children }) => {
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const [loading, setloading] = useState(true);
  const roles = useSelector((state) => state.roles_permissions);
  const dispatch = useDispatch();
  const profiles = JSON.parse(localStorage.getItem("account"));
  useEffect(() => {
    dispatch(fetchAllPermissions({id:profiles[0]?.role?._id,role:"admin"}));
  }, [dispatch]);

  getPermissions(roles);

  function checkPermission(page) {
    let Permission = "read & write";

    roles?.all_permissions?.forEach((x) => {
      if (x.resource.name == page) {
        if (
          x.method.add === true &&
          x.method.delete === true &&
          x.method.edit === true &&
          x.method.search === true &&
          x.method.view === true
        ) {
          Permission = "read & write";
        } else if (
          x.method.add === false &&
          x.method.delete === false &&
          x.method.edit === false &&
          x.method.search === false &&
          x.method.view === true
        ) {
          Permission = "read";
        } else {
          Permission = "restricted";
        }
      }
    });

    if (page == "Member") {
      if (profiles[0]?.parent_id) {
        Permission = "restricted";
      }
    }

    return Permission;
  }
  

  function checkSubListPermission(page, action) {
    let Permission = "read & write";

    roles?.all_permissions?.forEach((x) => {
      if (x.resource.name == page) {
        x.actions.forEach((y) => {
          if (y?.name == action) {
            if (y?.allowed == true) {
              Permission = "read & write";
            } else {
              Permission = "restricted";
            }
          }
        });
      }
    });

    return Permission;
  }

  const MENU_ITEMS = [
    {
      label: "Dashboard",
      url: "/admin/dashboard",
      icon: <Dashboard />,
      
    },
  
    {
      label: "Approval requests",
      url: "/admin/approval-requests",
      icon: <DatabaseIcon />,
      permission: checkPermission("Verification"),
    },
    {
      label: "Users",
      url: "/admin/users",
      icon: <User />,
      permission: checkPermission("Users"),
    },
    {
      label: "Directory",
      url: "/admin/directory",
      icon: <DirectoryIcon />,
      permission: checkPermission("Directory"),
    },
    {
      label: "Teams",
      icon: <Sitemap />,
      permission: checkPermission("Member"),
      subList: [
        {
          label: "Roles",
          path: "/admin/teams/role",
          permission: checkSubListPermission("Member", "Role"),
        },
        {
          label: "Members",
          path: "/admin/teams/member",
          permission: checkSubListPermission("Member", "Member"),
        },
      ],
    },
    {
      label: "Listings",
      url: "/admin/listings",
      icon: <ListIcon />,
      permission: checkPermission("Listings"),
      subList: [
        {
          label: "Mines",
          path: "/admin/listings/mines",
          permission: checkSubListPermission("Listings", "Mine"),
        },
        {
          label: "Auctions",
          path: "/admin/listings/auctions",
          permission: checkSubListPermission("Listings", "Auction"),
        },
        {
          label: "Investment offers",
          path: "/admin/listings/investment-offers",
          permission: checkSubListPermission("Listings", "Offer"),
        },
        // {
        //   label: "Investment proposals",
        //   path: "/admin/listings/investment-proposals",
        // },
        {
          label: "Vendors",
          path: "/admin/listings/vendors",
          permission: checkSubListPermission("Listings", "Vendor"),
        },
      ],
    },
    {
      label: "Interactions",
      url: "/admin/interaction",
      icon: <AreaSelectIcon />,
      permission: checkPermission("Interaction"),
      hasDot: true,
      subList: [
        {
          label: "Tenders",
          path: "/admin/interactions/tenders",
          permission: checkSubListPermission("Interaction", "Tender"),
        },
        {
          label: "Transactions",
          path: "/admin/interactions/transactions",
          permission: checkSubListPermission("Interaction", "Transaction"),
          
        },
        {
          label: "Investments",
          path: "/admin/interactions/investments",
          permission: checkSubListPermission("Interaction", "Investment"),
        },
        {
          label: "Projects",
          path: "/admin/interactions/projects",
          permission: checkSubListPermission("Interaction", "Project"),
        },
      ],
    },
    {
      label: "Minerals",
      url: "/admin/minerals",
      icon: <CourierIcon />,
      permission: checkPermission("Mineral"),
    },
    {
      label: "Tariff",
      url: "/admin/tariff",
      icon: <LocalAtmIcon />,
      permission: checkPermission("Tariff")
    },
  
    {
      label: "Payments",
      url: "/admin/payments",
      icon: <Money />,
      permission: checkPermission("Payment"),
    },
    {
      label: "Suspensions",
      url: "/admin/suspensions",
      icon: <Ban />,
      permission: checkPermission("Suspension"),
    },
    {
      label: "Carrier",
      url: "/admin/carrier",
      icon: <CourierIcon />,
      permission: checkPermission("Carrier"),
    },
    // {
    //   label: "Messages",
    //   url: "/admin/messages",
    //   icon: <MailOutlineIcon />,
    // },
  
    // {
    //   label: "Support",
    //   url: "/admin/support",
    //   icon: <HeadsetIcon />,
    // },
    {
      label: "Settings",
      url: "/admin/settings",
      icon: <CogIcon />,
      permission: checkPermission("Settings"),
    },
  ];
  

  useEffect(() => {
    setloading(true);
    if (!user) {
      return navigate(SignInUrl, { replace: true });
    }
    setloading(false);
  }, [navigate]);

  if (loading) {
    return null;
  }

  return (
    <div className="xl:grid xl:grid-cols-6">
      {/*side bar  */}
      <div className="hidden xl:grid xl:col-span-1 sticky top-0 h-full">
        <Sidebar items={MENU_ITEMS} sliceRange={9} />
      </div>
      {/* main content */}
      <div className="col-span-6 lg:col-span-5 relative">
        <Navbar userType="Admin" />
        {/* responsiveness */}
        <div className="xl:hidden">
          <SideBar items={MENU_ITEMS} sliceRange={9} />
        </div>
        <Breadcrumb />
        <main className="px-4 lg:px-8 min-h-screen">{children}</main>
        <div className="relative">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LayoutAdmin;
