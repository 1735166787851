/* eslint-disable react/prop-types */
const Gavel = ({ color, opacity, h, w }) => {
  const iconColor = color ? color : "#6166B6";
  const fillOpacity = opacity ? opacity : "0.6";
  const height = h ? h : "24";
  const width = w ? w : "24";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.392 8.025C9.99197 7.625 9.99197 7.125 10.292 6.825L12.892 4.225C13.192 3.925 13.692 3.925 14.092 4.225L14.192 4.325C14.492 4.625 14.492 5.125 14.192 5.525L11.592 8.025C11.292 8.325 10.692 8.325 10.392 8.025Z"
        fill={iconColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M15.992 13.625C15.592 13.225 15.592 12.725 15.892 12.325L18.492 9.725C18.792 9.425 19.292 9.425 19.692 9.725L19.792 9.825C20.092 10.125 20.092 10.625 19.792 11.025L17.192 13.625C16.792 13.925 16.292 13.925 15.992 13.625Z"
        fill={iconColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M13.992 11.625L12.292 9.92499C11.892 9.52499 11.892 8.92499 12.292 8.52499L14.592 6.22499C14.992 5.82499 15.592 5.82499 15.992 6.22499L17.692 7.92499C18.092 8.32499 18.092 8.92499 17.692 9.32499L15.392 11.625C14.992 12.025 14.392 12.025 13.992 11.625Z"
        fill={iconColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M7.992 18.125C8.592 17.525 11.992 12.525 12.492 12.825C12.892 13.025 13.492 12.325 13.492 12.325L11.592 10.425C11.592 10.425 10.892 11.025 11.092 11.425C11.392 11.925 6.392 15.325 5.792 15.925C5.792 15.925 2.992 18.125 4.392 19.525C5.792 20.925 7.992 18.125 7.992 18.125Z"
        fill={iconColor}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export default Gavel;
