/* eslint-disable react/prop-types */
import React from "react";
import Autocomplete from "react-google-autocomplete";

function GoogleAutoComplete2({ handleGoogleAutoComplete }) {
  const type = {
    types: ["geocode"],
  };
  const selectedPlace = (place) => {
    if (handleGoogleAutoComplete) {
      handleGoogleAutoComplete(place);
    }
  };

  return (
    <div>
      <Autocomplete
        className="bg-fill border-none focus:outline-none break-all focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded cursor-text text-input text-sm py-3 px-4"
        apiKey={"AIzaSyDA4S3ph7PmMHFQ6mFjN05OWr2t7_i2k18"}
        options={type}
        onPlaceSelected={(place) => selectedPlace(place)}
      />
    </div>
  );
}

export default GoogleAutoComplete2;
