/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Box } from '@mui/material';
import { Button } from '@ui';
import Success from 'assets/images/success.svg';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 582,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3
};

const Complete = ({
    demand_commission_charge,openSubmitModal, setOpenSubmitModal,demandInterestDetails, submitted, onSubmit, loading
}) => {
    const navigate = useNavigate()

    return (
        <Modal
            open={openSubmitModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="text-center mb-4 py-10 px-16">
                    {submitted && (
                        <div className="grid justify-items-center">
                            <img src={Success} />
                        </div>
                    )}
                    <h1 className="text-[22px] text-head my-4">{!submitted ? 'Confirm Supply Award?' : 'Supply awarded successfully'}</h1>
                    <p className="font-light text-vendorsecondary">
                        {!submitted ? `By clicking “Agree and confirm” you agree to be charged the total value of this transaction and an additional ${demand_commission_charge}% commission fee.` :
                            `You have successfully awarded the supply of  ${ demandInterestDetails?.demand.product?.product_name} to ${demandInterestDetails?.account?.name}. You will be notified when the supplier pays the acceptance fee.`}
                    </p>

                    <div className="flex items-center gap-x-6 justify-center mt-8">

                        {!submitted ? (
                            <>
                                <Button onClick={() => onSubmit()} loading={loading} >
                                    Agree and confirm
                                </Button>
                                <Button onClick={() => setOpenSubmitModal(false)} color="neutral">
                                    Cancel
                                </Button>
                            </>) : (
                            <Button onClick={() => navigate('/off-taker/demands')}>
                                Go to demands
                            </Button>)}

                    </div>
                </div>
            </Box>
        </Modal>
    );
};
export default Complete;
