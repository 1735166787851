import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "components/Table";
import { checkPermission } from "shared/utility";
import { MinerFundingTableInfo } from "constants/index";
import {
  fetchPaginatedFundings,
  setFundingsSearchValue,
  getFundings
} from "@slices/miners/investor-slice";
import FundingStats from "./FundingStats";
import { Btn as Button } from "@atoms/button/button";

const Fundings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleViewDetails = (record) => {
    navigate(`/miner/investors/fundings/details`, {
      state: {
        funding: record,
      },
    });
  };
  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedFundings({
        page: pagination[option + "Page"],
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedFundings({
        page: 1,
        per_page: e.target.value,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(setFundingsSearchValue(e.target.value));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(getFundings());
    }
    dispatch(setFundingsSearchValue(search));
    dispatch(
      fetchPaginatedFundings({
        page: 1,
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    dispatch(
      fetchPaginatedFundings({
        page: 1,
        per_page: pagination.limit,
        search: search,
        filter: tab.toLowerCase(),
      })
    );
  };

  const {
    loading,
    fundings: { fundings, counts, pagination, search, filter },
  } = useSelector((state) => state.investors);

  return (
    <div>
      <Button
        disabled={checkPermission("Investor")}
        onClick={() => navigate("/miner/investors/fundings/seek-funding")}
        size="small"
      >
        Seek funding
      </Button>
      <div className="grid grid-cols-3 lg:grid gap-2 sm:flex overflow-x-auto scrollbar-hide">
        <FundingStats counts={counts} />
      </div>
      {/* tabs */}
      <div className="mt-4 sm:flex overflow-x-auto scrollbar-hide">
        <Table
          tableInfo={MinerFundingTableInfo}
          isViewDetails={true}
          isStatus={true}
          handleViewDetails={handleViewDetails}
          navigate={navigate}
          data={fundings}
          pagination={pagination}
          handleSetPerPage={handleSetPerPage}
          handleSearchSubmit={handleSearchSubmit}
          handleChangePage={handleChangePage}
          handleTabChange={handleTabChange}
          loading={loading}
          handleSearch={handleSearch}
          fetchDefaultData={getFundings}
          search={search}
        />
      </div>
    </div>
  );
};

export default Fundings;
