/* eslint-disable no-unused-vars */
import React from "react";
import Skeleton from "@components/auctions/Skeleton";
import { Search, Button,Tab,AdminAuction } from "@ui";
import { SearchIcon } from "@ui/icons";

const AdminAuctionTabs = ({
  tableInfo,
  handleTabChange,
  pagination,
  loading,
  handleOpen,
  data,
  handleSearch,
  handleSearchSubmit,
}) => {

  return (
    <>
      <div className="flex justify-between p-4">
        <form onSubmit={(e) => handleSearchSubmit(e)} className="flex gap-2">
          <div className="w-[250px] lg:w-[538px]">
            <Search
              placeholder="Search Auctions"
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <Button type="submit" color="primary">
            <SearchIcon color="#ffff" opacity="1" />
          </Button>
        </form>
      </div>
      <Tab tabData={tableInfo.tabData} handleTabChange={handleTabChange} />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-hidden sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full max-w-[1020.270px] sm:px-6 lg:px-8">
            <div className="grid lg:grid-cols-3 sm:grid gap-[30px] mt-5 font-default">
              {loading ? (
                <>
                  {Array(3)
                    .fill(null)
                    .map((_, index) => (
                      <Skeleton key={index} />
                    ))}
                </>
              ) : (
                data.map((auction) => (
                  <AdminAuction auction={auction} key={auction.id} handleOpen={handleOpen} />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAuctionTabs;
