/* eslint-disable react/prop-types */
import { useContext } from "react";
import MenuItems from "./menu/Items";
import { UserContext } from "@context/index";

const Sidebar = ({ items, sliceRange }) => {
  const { sidebar } = useContext(UserContext);

  return (
    <div className="lg:h-full  bg-secondary">
      {sidebar && (
        <div className="  lg:h-full">
          <div className="sticky top-6">
            <MenuItems menuItems={items} sliceRange={sliceRange} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
