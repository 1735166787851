import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PropTypes from "prop-types";
import MessageList from "./message_list";

export default function MessageTabs({
  all_messages,
  unread_messages,
  archived_messages,
  chat_change,
}) {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  MessageTabs.propTypes = {
    all_messages: PropTypes.array.isRequired,
    unread_messages: PropTypes.array.isRequired,
    archived_messages: PropTypes.array.isRequired,
    chat_change: PropTypes.func.isRequired,
  };
  const messages_count = `All(${all_messages?.length})`;
  // const unread_messages_count = `Unread(${unread_messages.length})`;
  // const archived_messages_count = `Archive(${archived_messages.length})`;
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label={messages_count}
              value="1"
              style={{ textTransform: "none" }}
            />
            {/* <Tab
              label={unread_messages_count}
              value="2"
              style={{ textTransform: "none" }}
            />
            <Tab
              label={archived_messages_count}
              value="3"
              style={{ textTransform: "none" }}
            /> */}
          </TabList>
        </Box>
        <TabPanel value="1">
          <MessageList
            items={all_messages}
            // type="all"
            onSelected={chat_change}
          />
        </TabPanel>
        <TabPanel value="2">
          <MessageList
            items={unread_messages}
            type="unread"
            onSelected={chat_change}
          />
        </TabPanel>
        <TabPanel value="3">
          <MessageList
            items={archived_messages}
            type="archived"
            onSelected={chat_change}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}