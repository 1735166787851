import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams,useNavigate } from "react-router-dom";
import Button from "components/ui/Button";
import { getOffer } from "store/slices/offtaker-slice";
import { getInitials, priceSplitter } from "shared/utility";
import dayjs from "dayjs";

function OfferDetails() {
  const params = useParams();
  const { id } = params;
  const { offer } = useSelector((store) => store.offtakers);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getOffer(id));
  }, []);

  return (
    <div>
      <div className="bg-white rounded-sm flex justify-center py-6">
        {/* <pre>{JSON.stringify(vendor, null, 2)}</pre> */}
        {offer && (
          <div>
            <div>
              <div className="flex justify-between ml-7 mr-5">
                <div className="flex">
                  <div className="min-h-[60px] min-w-[60px] mr-3 flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
                    {offer?.user_image !== null ? (
                      <img
                        className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                        src={`${offer?.user_image?.url}`}
                        alt=""
                      />
                    ) : (
                      `${getInitials(offer?.account?.name)}`
                    )}
                  </div>
                  {/* <img
                    className="rounded-circle w-[60px] h-[60px] mr-3"
                    src="https://cdn.smehost.net/2020sonymusiccouk-ukprod/wp-content/uploads/2019/10/Miley-Cyrus-03_MidnightSky_WhiteLight_2302.jpg"
                    alt=""
                  /> */}
                  <div>
                    <h4 className="text-primary mr-5">
                      {offer?.account?.company}
                    </h4>
                    <h4 className="text-[14px] text-vendor">
                      {offer?.account?.name}
                    </h4>
                  </div>
                </div>
                <div>
                  <Button onClick={() => navigate("interests")}>
                    {" "}
                    Manage Interests
                  </Button>
                </div>
              </div>
            </div>
            <div className="mt-3 mb-7 ml-7">
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Job title:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {offer.title}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project type:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {offer.type}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Category:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {offer?.category?.name}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Description:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-2 break-words">
                  {offer?.description}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project duration:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {offer?.duration}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Country:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {offer?.country?.name}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Budget:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {priceSplitter(offer?.budget) + " USD"}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project start date:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {dayjs(offer?.start_date).format("DD/MM/YYYY")}
                </h4>
              </div>
              {offer.additional_notes && (
                <div className="grid grid-cols-3 mb-8 font-light">
                  <h4 className="text-vendorsecondary col-start-1 col-end-2">
                    Additional notes:
                  </h4>
                  <h4 className="text-vendor col-start-2 col-end-4">
                    {offer?.additional_notes}
                  </h4>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OfferDetails;
