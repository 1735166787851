/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
//eslint-disable-next-line
import { useParams } from "react-router-dom";
// import { Tab } from "@ui";
import { Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/ui/Button";
import Modal from "@mui/material/Modal";
import Complete from "./Complete";
import {
  awardInterest,
  getInterests,
  setAwardedInterestSub,
} from "store/slices/vendors/interactions-slice";
import TableEmptyState from "components/ui/TableEmptyState";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Interests = () => {
  const [value, setValue] = React.useState(0);
  const params = useParams();
  const { id } = params;
  const options = ["pending", "awarded", "archived"];
  // const tabData = {
  //   data: ["Interests", "Accepted", "Archived"],
  //   summary: "Total Interests:",
  // };
  const {
    interests: { data, pagination },
  } = useSelector((store) => store.interactions);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSelectVendor = () => {
    dispatch(awardInterest());
    handleClose();
  };

  const handlePopUp = (id) => {
    handleOpen();
    dispatch(setAwardedInterestSub(id));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(getInterests({ id: id, status: options[newValue] }));
    // setFilterKey(options[newValue]);
  };

  useEffect(() => {
    dispatch(getInterests({ id: id, status: "pending" }));
  }, []);
  return (
    <div className="flex justify-center">
      <div className="w-[97.5%]">
        <h1 className="text-[22px] text-black">Mineral Transport</h1>
        <div>
          <div className="border-b border-gray-light">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor="secondary"
            >
              <Tab label="Interests" {...a11yProps(0)} />
              <Tab label="Awarded" {...a11yProps(1)} />
              <Tab label="Archived" {...a11yProps(2)} />
            </Tabs>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-1 gap-8 mt-6">
            {data?.interests?.map((record, index) => (
              <div key={index} className="p-6 bg-white">
                <div className="flex justify-between">
                  <div className="flex">
                    <img
                      className="rounded-circle w-[60px] h-[60px] mr-3"
                      src="https://cdn.smehost.net/2020sonymusiccouk-ukprod/wp-content/uploads/2019/10/Miley-Cyrus-03_MidnightSky_WhiteLight_2302.jpg"
                      alt=""
                    />
                    <div>
                      <h4 className="text-primary">{record.account.company}</h4>
                      <h4 className="text-[14px] text-vendor">
                        {record?.account.industry_name}
                      </h4>
                    </div>
                  </div>
                  <div>
                    <Button onClick={() => handlePopUp(record._id)}>
                      Select Vendor
                    </Button>
                  </div>
                </div>
                <div className="font-light text-[14px]">
                  <h2 className="text-vendorsecondary mb-2.5">Description</h2>
                  <p>{record.account.role.description}</p>
                </div>
                {/* <div className="my-3 flex flex-wrap text-[#50AAA7] font-light text-[14px]">
                  {record?.keywords.map((keyword, index) => (
                    <div
                      key={index}
                      className="bg-vendoroptionbg flex justify-between px-2 mr-2 mt-2"
                    >
                      <span className="mr-2">{keyword}</span>
                    </div>
                  ))}
                </div> */}
              </div>
            ))}
          </div>
          {data !== null && !data?.interests?.length > 0 && (
            <TableEmptyState text="interest" />
          )}
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute top-44 w-full flex justify-center">
          <div className="bg-white rounded-lg py-8 w-1/2 flex justify-center ">
            <div className="w-3/4">
              <Complete />
              <div className="flex justify-center gap-8 mt-8">
                <Button
                  onClick={handleSelectVendor}
                  variant="contained"
                  color="neutral"
                >
                  Confirm
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Interests;
