import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as API from "@config/url";
import { toast } from "react-toastify";

// Fetch verification data
const get_offtakers_verification_data = createAsyncThunk("offtaker_verification/get_offtaker_verification_data", async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
        return await axios({
            method: "get",
            url: API.getOffTakerVerificationData,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer "+ token
            },
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

// Post verification data
const submit_offtaker_verification = createAsyncThunk("offtaker_verification/submit_offtaker_verification", async (payload, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
        return await axios({
            method: "post",
            url: API.createAccount(payload.id),
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "bearer "+ token
            },
            data:payload.formdata,
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

/** create slice */
const slice = createSlice({
    name: "offtaker_verification",
    initialState: {
        data: {},
        status: false,
        loading: false,
        error: null,
        message: null,
    },
    reducers: {},
    extraReducers: {
        // verify otp code
        [submit_offtaker_verification.pending]: (state) => {
            state.loading = true;
        },
        [submit_offtaker_verification.fulfilled]: (state, { payload }) => {
            state.status = payload.status;
            toast.success(payload.data.message);
            state.loading = false;
            localStorage.clear()
        },
        [submit_offtaker_verification.rejected]: (state, { payload }) => {
            state.message = payload.data.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.data.message);
        },

        [get_offtakers_verification_data.pending]: (state) => {
            state.loading = true;
        },
        [get_offtakers_verification_data.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.loading = false;
        },
        [get_offtakers_verification_data.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
    },
});



export { submit_offtaker_verification, get_offtakers_verification_data }
export default slice.reducer;