import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Chips,Select } from "@ui";
import { checkPermission } from "shared/utility";
import dayjs from "dayjs";
import { getSingleOfftakerAuctions } from "store/slices/offtaker-slice";
import Button from "components/ui/Button";
import Quantity from "components/atoms/input/quantity";
import ConfirmationModal from "./ConfirmationModal";
import Countdown from "@components/auctions/Countdown";
import LoadingState from "@components/auctions/auction/LoadingState";
import { toast } from "react-toastify";

const OfftakersAuctionDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [shipmentOption, setShippmentOption] = useState(null);
  const { data, loading } = useSelector((state) => state?.offtakers);
  const [checkShipment, setCheckShippment] = useState(true);
  const [amount, setAmount] = useState(0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    if (!shipmentOption) {
      setCheckShippment(true);
    } else {
      setCheckShippment(false);
    }
  }, [shipmentOption]);

  const handleSetAmount = (amount) => {
    setAmount(amount);
  };

  const options = data?.product?.shipment_options;

  const productData = [
    {
      label: `Token ID`,
      value: `${data?.product?.product_id}`,
    },
    {
      label: `Description`,
      value: `${data?.product?.description}`,
    },
    {
      label: `Quantity`,
      value: `${data?.product?.quantity} ${data?.product?.unit_of_measurement.id}`,
    },
    {
      label: `TC`,
      value: `${data?.product?.treatment_cost} USD/${data?.product?.unit_of_measurement.id}`,
    },
    {
      label: `Price`,
      value: `${data?.product?.price} USD/${data?.product?.unit_of_measurement.id} `,
    },
    {
      label: `Total value`,
      value: `${data?.product?.total_value} USD`,
    },
    {
      label: `Shipment Method`,
      value: `${options?.map((a) => a.slug)}`,
    },

    {
      label: "Payment",
      value:
        "100% payment to Escrow, payment processed after confirmed delivery",
    },
  ];

  const handleSelectChange = (e) => {
    setShippmentOption(e.slug);
  };

  useEffect(() => {
    if (params) {
      let id = params?.id;
      dispatch(getSingleOfftakerAuctions(id));
    }
  }, [params]);

  return (
    <>
      {loading ? (
        <LoadingState />
      ) : (
        <div className="grid grid-cols-3 gap-7">
          <>
            <Card className="col-span-2">
              <div className="flex gap-8 pt-1">
                <div className="h-[217px] rounded-lg overflow-hidden">
                  <img
                    src={data?.product?.image[0]?.url}
                    className="h-full w-full object-cover"
                    alt=""
                  />
                </div>
                <div>
                  <h3 className="text-2xl text-heading">
                    {data?.product?.product_name}
                  </h3>
                  <p className="text-sm text-ts font-light mt-1"></p>
                  <div className="flex gap-2 mt-6 mb-6">
                    <Countdown date={data?.product?.end_date} />
                  </div>
                </div>
              </div>
              {/* card information */}
              <div className="grid grid-cols-4 gap-y-4 mt-6">
                {productData.map((information, index) => (
                  <Fragment key={index + 1}>
                    <div className="col-span-1 font-light text-ts text-sm">
                      <p>{information.label}:</p>
                    </div>
                    <div className="col-span-3 font-light text-light">
                      <p>{information.value}</p>
                    </div>
                  </Fragment>
                ))}
              </div>
              {/* auction's activity */}
              <h3 className="text-heading mt-[41px] mb-6">
                Activity on this auction
              </h3>
              <div className="flex gap-[51px] pb-[75px]">
                <div className="font-light text-ts">
                  <p className="mb-4">Bids:</p>
                  <p>Last Viewed:</p>
                </div>
                <div className="font-light text-light">
                  <p className="mb-4">{data?.activity?.bids}</p>
                  <p>{dayjs(data?.product?.updated_at)?.fromNow()}</p>
                </div>
              </div>
            </Card>
            <div className="col-span-1">
              <div>
                <Card className="py-[20px]">
                  <h2 className="mb-5">Make your bid</h2>
                  <Select
                    label="Shipment Method"
                    name="mine_id"
                    placeholder="Select shipment method"
                    options={data?.product?.shipment_options}
                    getOptionLabel={(option) => option.slug}
                    onChange={handleSelectChange}
                  />
                  <div className="mb-3"></div>
                  <div className="mb-3">
                    <label htmlFor="amount" className=" text-sm font-light">
                      Amount to bid
                    </label>
                    <Quantity
                      unit={data?.product?.unit_of_measurement.id}
                      handleSetAmount={handleSetAmount}
                    />
                  </div>
                  <Button
                    type="button"
                    fullWidth
                    disabled={checkPermission("Auction")}
                    onClick={() => {
                      if (checkShipment) {
                        console.log("ERROR");
                        toast.error("Shipment method is required.");
                        return;
                      }
                      handleOpen();
                    }}
                    // disabled={checkShipment}
                  >
                    Place bid
                  </Button>
                  <p className="text-sm font-light text-center mt-2">
                    Payment is made after seller approval
                  </p>
                </Card>
              </div>
              <br />

              <Card className="py-[30px]">
                <h3 className="text-heading mb-[18px]">About the seller</h3>
                <div className="grid grid-cols-2 mb-4 font-light">
                  <p className="text-base text-ts mb-4 whitespace-nowrap">
                    Member since:
                  </p>
                  <p className="text-light">
                    {dayjs(data?.member_since?.member_since)?.format("MMMM YYYY")}
                  </p>
                </div>
                <div className="grid grid-cols-2 mb-4 font-light">
                  <p className="text-base text-ts mb-4">Sells:</p>
                  <span className="flex flex-wrap gap-2">
                    <Chips noIcons chips={data.seller?.sells} />
                  </span>
                </div>
                <div className="grid grid-cols-2 mb-4 font-light">
                  <p className="text-base text-ts mb-4">Locaton:</p>
                  <p className="text-light whitespace-nowrap">
                    {data?.seller?.country?.name}
                  </p>
                </div>
                <div className="grid grid-cols-2 mb-4 font-light">
                  <p className="text-base text-ts mb-4">Auctions:</p>
                  <p className="text-light">{data?.seller?.auctions} active</p>
                </div>
                <div className="grid grid-cols-2 mb-4 font-light">
                  <p className=" text-base text-ts mb-4">Deals:</p>
                  <p className="text-light">{data?.seller?.deals}</p>
                </div>
                <div className="grid grid-cols-4 gap-2 mt-8">
                  {data?.seller?.sdg_goals.map((sdg_goal) => (
                    <img
                      key={sdg_goal?.id}
                      src={sdg_goal?.image_url}
                      alt={sdg_goal?.name}
                    />
                  ))}
                </div>
              </Card>
            </div>
          </>
        </div>
      )}
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        amount={amount}
        shipmentOption={shipmentOption}
        setShippmentOption={setShippmentOption}
      />
    </>
  );
};

export default OfftakersAuctionDetails;
