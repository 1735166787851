import React from "react";
import { Header } from "@atoms/header";
import { Button } from "@atoms/button/index";
import Success from "@assets/images/success.png";
import { Vector } from "@atoms/icons";
import { useNavigate } from "react-router-dom";


const SubmissionSuccessful = () => {
  const navigate = useNavigate();


  const onClick = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/");
  }

  return (
    <div className=" font-default">
      <Header />
      <div className="bg-white rounded p-8 lg:mt-6 mt-[113px]">
        <div className=" bg-no-repeat  w-full h-26  flex justify-center align-center">
          <img src={Success} alt="success" />
        </div>

        <div className=" my-4 ">
          <h2 className="font-bold text-2xl text-head pt-4 flex justify-center align-center">
            Submission successful
          </h2>

          <p className="text-lightgray pb-4 text-base font-light mb-2 flex justify-center align-center">
            You will be notified upon successful verification of your account.
          </p>
          <Button onClick={onClick}>
            Continue to dashboard
          </Button>
        </div>
        <div className="flex  align-center pb-2 mt-20">
          <Vector />
          <p className="text-lightgray text-xs ml-2">
            You can not post auctions, buy, invest or offer services until your
            account has been verified.
          </p>
        </div>
      </div>
    </div>
  );
};
export default SubmissionSuccessful;
