import { VerifiedIcon } from "@ui/icons";

const Complete = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <VerifiedIcon />
      <div className="text-center mt-9">
        <h3 className="text-heading text-2xl">Action confirmed.</h3>
        <span className="text-ts font-light">
          Your application for the service request has been sent successfully.
          You will be notified upon selection.
        </span>
      </div>
    </div>
  );
};

export default Complete;
