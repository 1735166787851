/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Input } from "@atoms/input";
import axios from "axios";
import { toast } from "react-toastify";

function GoogleLongLat({ setValue, errors }) {
    const [long, setLong] = useState(null);
    const [lat, setLat] = useState(null);
    const setLongitude = (e) => {
        setLong(e.target.value);
        onClick(e.target.value, lat);
    };
    const setLatitude = (e) => {
        setLat(e.target.value);
        onClick(long, e.target.value);
    };

    const onClick = async (long, lat) => {
        if (!long || !lat) {
            return console.log(long, lat)
        }

        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyDA4S3ph7PmMHFQ6mFjN05OWr2t7_i2k18`
            );
            if (response.data.results.length < 1) {
                throw new Error('No Address found')
            }
            let place = response.data.results[0];

            if (place) {
                let address = "";
                let postcode = "";
                let country = "";
                let state = "";
                let city = "";
                for (const component of place.address_components) {
                    const componentType = component.types[0];
                    switch (componentType) {
                        case "street_number": {
                            address = `${component.long_name} ${address}`;
                            break;
                        }
                        case "route": {
                            address += component.short_name;
                            break;
                        }

                        case "postal_code": {
                            postcode = `${component.long_name}${postcode}`;
                            break;
                        }

                        case "postal_code_suffix": {
                            postcode = `${postcode}-${component.long_name}`;
                            break;
                        }

                        case "country": {
                            country = component.short_name;
                            break;
                        }

                        case "administrative_area_level_1": {
                            state = component.long_name;
                            break;
                        }

                        case "administrative_area_level_2": {
                            city = component.long_name;
                            break;
                        }
                    }
                }

                setValue("address", address, { shouldValidate: true, shouldDirty: true });
                setValue("postal", postcode  === undefined ? '' : postcode, { shouldValidate: true, shouldDirty: true });
                setValue("state", state, { shouldValidate: true, shouldDirty: true });
                setValue("city", city, { shouldValidate: true, shouldDirty: true });
                setValue("country", country, { shouldValidate: true, shouldDirty: true });
                setValue("long", long, { shouldValidate: true, shouldDirty: true });
                setValue("lat", lat, { shouldValidate: true, shouldDirty: true });
            } else {
                throw 'No Address found'
            }
        } catch (error) {
            return toast.error(error.message);
        }
    };

    return (
        <>
            <div className=" media mt-4 px-3">
                <label className="block text-xs mb-2  text-lightgray" htmlFor="longitude">  Longitude  </label>
                <Input id="longitude" type="text" placeholder="40" name="long" onChange={setLongitude} />
                {errors?.long?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors?.long?.message} </p>)}
            </div>

            <div className=" media mt-4 px-3">
                <label className="block text-xs mb-2  text-lightgray" htmlFor="latitude" > Latitude </label>
                <Input id="lat" type="text" placeholder="112" name="longLat" onChange={setLatitude} />
                {errors?.lat?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors?.lat?.message} </p>)}
            </div>
        </>
    );
}

export default GoogleLongLat;
