/* eslint-disable no-unused-vars */

import GoogleMapReact from "google-map-react";
import { LocationIcon } from "@ui/icons";
// import Marker from "./marker"

const LocationPin = () => {
    return (<div> <LocationIcon />  </div>);
};

const Map = ({ directory,location: default_location, zoomLevel }) => {
    
    return (
        <div className="w-full h-full relative">
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyDA4S3ph7PmMHFQ6mFjN05OWr2t7_i2k18", libraries: ["places"] }}
                center={default_location}
                defaultZoom={zoomLevel}
                yesIWantToUseGoogleMapApiInternals
                // onGoogleApiLoaded={({ map, maps }) => console.log(map)}
                // onClick={longitude_latitude_search}
            >
                {directory?.map((dir) => {
                    return (<LocationPin key={dir._id} lat={dir.location.latitude} lng={dir.location.longitude} text={dir.location.address} />);
                })}
            </GoogleMapReact>

        </div>
    );
};

export default Map;
