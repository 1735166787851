/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useState } from "react";
import Plus from "@assets/images/plus.svg";
import Minus from "@assets/images/minus.svg";
import { useSelector } from "react-redux";

const Input = forwardRef(function Input(
  {
    type,
    placeholder,
    name,
    id,
    disabled,
    value,
    additionalFunc,
    // onChange,
    handleSetAmount,
    onKeyDown,
    unit,
    onKeyUp,
    ...props
  },
  ref
) {
  const { make_bid } = useSelector((state) => state.offtakers);
  const minAmount = make_bid?.make_bid?.min_amount;
  const [quantity, setQuantity] = useState(+minAmount);

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity((prevCount) => +prevCount - 1);
    }
  };

  const handleIncrement = () => {
    setQuantity((prevCount) => +prevCount + 1);
  };

  useEffect(() => {
    handleSetAmount(quantity);
  }, [quantity]);

  return (
    <React.Fragment>
      <div className="bg-fill border-none flex justify-between focus:outline-none break-all focus:border-primary focus:ring-1  focus:ring-primary w-full appearance-none rounded cursor-text text-input text-sm py-2 px-4">
        <button onClick={handleDecrement} disabled={quantity <= minAmount}>
          <img src={Minus} alt="minus-icon" />
        </button>
        <div className="flex justify-center">
          <p className="mr-2 text-gray">USD</p>
          <input
            style={{ minWidth: "40px", width: "auto", textAlign: "center" }}
            className="bg-transparent border-0"
            type={"number"}
            id={id}
            name={"quantity"}
            onChange={(e) => setQuantity(e.target.value)}
            value={quantity}
            size={20}
          />
          <p className="text-gray ml-3"> /{unit}</p>
        </div>
        <button onClick={handleIncrement}>
          <img src={Plus} alt="plus-icon" />
        </button>
      </div>
    </React.Fragment>
  );
});
export default Input;
