/* eslint-disable no-unused-vars */
/** @format */

import React, { useState } from "react";

import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import { useAuth } from "@hooks";
import { deleteAccount } from "@slices/settings-slice";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "@config/app-url";
import { SignOut } from "store/slices/user-slice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

// eslint-disable-next-line react/prop-types
export const DeleteAccModal = ({ setOpen, open = null, data = null }) => {
  const profiles = useAuth();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const removeAccount = async () => {
    try {
      setIsLoading(true);
      dispatch(deleteAccount())
        .then((response) => {
          if (response.payload.status === 200) {
            toast.success(response.payload.data.message);
            // dispatch(SignOut());
            localStorage.clear();
            navigate(BaseUrl);
          }
        })
        .catch((error) => toast.error(error.message));
    } catch (error) {
      //   toast.error(error);
      // console.log("error", error);
    }
  };
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center mb-4">
            <h1 className="text-xl mb-4 text-[#FF4E44]">Warning !</h1>

            <p className="font-light mt-4">
              Are you sure you want to delete this account?
            </p>
            <div className="flex items-center justify-between mt-8">
              <button
                onClick={() => setOpen(!open)}
                className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
              >
                Cancel
              </button>
              <div
                onClick={() => {
                  removeAccount();
                }}
              >
                <Button> {isLoading ? "Processing..." : "Delete"}</Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
