const VendorIcon = () => {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.705078 0V6C0.710078 6.732 1.10608 7.37 1.69608 7.715L1.70508 14H10.7051V9H13.7051V14H15.7051V7.72C16.3041 7.37 16.7001 6.732 16.7051 6.001V0H0.705078V0ZM4.70508 2H6.70508V6C6.70508 6.552 6.25708 7 5.70508 7C5.15308 7 4.70508 6.552 4.70508 6V2ZM2.70508 7C2.15308 7 1.70508 6.552 1.70508 6V2H3.70508V6C3.70508 6.552 3.25708 7 2.70508 7ZM8.70508 12H3.70508V9H8.70508V12ZM9.70508 6C9.70508 6.552 9.25708 7 8.70508 7C8.15308 7 7.70508 6.552 7.70508 6V2H9.70508V6ZM12.7051 6C12.7051 6.552 12.2571 7 11.7051 7C11.1531 7 10.7051 6.552 10.7051 6V2H12.7051V6ZM15.7051 6C15.7051 6.552 15.2571 7 14.7051 7C14.1531 7 13.7051 6.552 13.7051 6V2H15.7051V6Z"
        fill="#1C304A"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default VendorIcon;
