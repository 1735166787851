/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Popover, Paper } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { statusColorScheme } from "constants/index";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Search, Button, TableEmptyState, TableSkeleton, Tab } from "@ui";
import {
  TrashIcon,
  EclipseDotsIcon,
  DropDownIcon,
  SearchIcon,
  Sort,
  FilterIcon,
} from "@ui/icons";

export default function TenderTabs({
  tableInfo,
  handleTabChange,
  pagination,
  handleSetPerPage,
  handleSearchSubmit,
  handleChangePage,
  handleSearch,
  data,
  actions,
  isToggle,
  isDelete,
  loading,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event, record) => {
    setSub(record._id);
    setSubSet(record.status);
    setAnchorEl(event.currentTarget);
  };
  const [page, setPage] = useState("");
  const [sub, setSub] = useState("");
  const [subset, setSubSet] = useState("");

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Tab
        tabData={tableInfo.tabData}
        handleTabChange={handleTabChange}
        // total={pagination?.total}
      />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-hidden sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full max-w-[1020.270px] sm:px-6 lg:px-8">
            <div className="shadow overflow-auto  border-gray-200 sm:rounded-lg bg-white">
              {/* input field goes here */}
              {
                <div className="flex justify-between p-4">
                  <form
                    onSubmit={(e) => handleSearchSubmit(e)}
                    className="flex gap-2 w-full"
                  >
                    <div className="flex justify-between w-full">
                      <div className="flex gap-2">
                        <div className="w-[250px] lg:w-[538px]">
                          <Search
                            placeholder={tableInfo?.searchInfo?.params}
                            onChange={(e) => handleSearch(e)}
                          />
                        </div>
                        <Button type="submit" color="primary">
                          <SearchIcon color="#ffff" opacity="1" />
                        </Button>
                      </div>

                      <FilterIcon />
                    </div>
                  </form>
                </div>
              }
              {loading && <TableSkeleton />}
              {!loading && data?.length > 0 && (
                <>
                  <table className="min-w-full divide-y divide-[#1A38601A] border-b border-[#1A38601A]">
                    <thead className="bg-[#EFF0FE] text-center">
                      <tr>
                        {tableInfo.tableHeaders.map((header, index) => (
                          <th
                            key={index + 1}
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-normal text-primary uppercase tracking-wider"
                          >
                            {!header.hasIcon && header.title}
                            {header.hasIcon && (
                              <div className="flex  items-center">
                                <span>{header.title}</span>
                                <Sort />
                              </div>
                            )}
                          </th>
                        ))}

                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Delete</span>
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Options</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white font-light divide-y divide-[#1A38601A] capitalize">
                      {data.length > 0 &&
                        data.map((record, index) => {
                          const statusObject = tableInfo.fields.find(
                            (field) => field.name === "status"
                          );
                          const statusValue = statusObject?.isFunction
                            ? statusObject.func(record)
                            : record.status;
                          return (
                            <tr key={index} className="text-[#18273AF0]">
                              {tableInfo.fields.map((field, index) =>
                                field.name === "status" ? (
                                  <td
                                    key={index}
                                    className="px-6 py-4 whitespace-nowrap"
                                  >
                                    <span
                                      className={`py-1 px-5 inline-flex capitalize text-sm leading-5 font-light rounded bg-[${statusColorScheme[statusValue]?.bg}] text-[${statusColorScheme[statusValue]?.text}] `}
                                    >
                                      {statusValue}
                                    </span>
                                  </td>
                                ) : field.name === "product_quantity" ? (
                                  <td
                                    key={index}
                                    className={`px-6 py-4 whitespace-nowrap text-sm ${
                                      field.name === "location" && "text-center"
                                    }`}
                                  >
                                    {record[field.name] +
                                      " " +
                                      record["product_unit_of_measurement"]?.id}
                                  </td>
                                ) : (
                                  <td
                                    key={index}
                                    className={`px-6 py-4 whitespace-nowrap text-sm ${
                                      field.name === "location" && "text-center"
                                    }`}
                                  >
                                    {field.isFunction
                                      ? field.func(record)
                                      : record[field.name]}
                                  </td>
                                )
                              )}
                              {isDelete && (
                                <td
                                  className="cursor-pointer"
                                  onClick={() => actions.delete(record.id)}
                                >
                                  <TrashIcon />
                                </td>
                              )}
                              {isToggle && (
                                <td className="select-none">
                                  <span
                                    aria-describedby={id}
                                    onClick={(event) =>
                                      handleClick(event, record)
                                    }
                                  >
                                    <EclipseDotsIcon />
                                  </span>
                                  <div className="shadow-2xl">
                                    <Popover
                                      elevation={0}
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                    >
                                      <Paper>
                                        <div className="grid  z-10  rounded-md w-[188px] border-2 border-lightgray500 border-opacity-20 text-[14px] font-light text-vendor cursor-pointer">
                                          {actions.toggle.fields.map(
                                            (field, index) => (
                                              <h1
                                                onClick={() =>
                                                  actions.toggle.action(
                                                    field,
                                                    sub,
                                                    subset
                                                  )
                                                }
                                                key={index}
                                                className=" hover:bg-primary hover:bg-opacity-10 pl-6 py-2.5"
                                              >
                                                {field}
                                              </h1>
                                            )
                                          )}
                                          {/* <span
                                            className=" hover:bg-primary hover:bg-opacity-10 pl-6 py-2.5"
                                            onClick={() =>
                                              navigate("../mine-details")
                                            }
                                          >
                                            View details
                                          </span>
                                          <span
                                            className=" hover:bg-primary hover:bg-opacity-10 pl-6 py-2.5"
                                            onClick={() =>
                                              navigate("../edit-mine")
                                            }
                                          >
                                            Edit Mine
                                          </span>
                                          <span className="hover:bg-primary hover:bg-opacity-10 pl-6 py-2.5">
                                            Suspend
                                          </span> */}
                                        </div>
                                      </Paper>
                                    </Popover>
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="flex justify-start lg:justify-end items-center w-full py-4 px-8 text-[13px] uppercase text-[#18273AF0]">
                    <div className="flex justify-between gap-2 items-center">
                      <p>Rows per page:{pagination.limit} </p>
                      <FormControl sx={{ height: "30px", border: 0 }}>
                        <Select
                          sx={{ height: "30px", border: 0 }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={page}
                          onChange={(e) => handleSetPerPage(e)}
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={30}>30</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="mx-6">
                      {pagination.current_page} of 1 -{" "}
                      {Math.ceil(pagination.total / pagination.limit)}
                    </div>
                    <div className="flex justify-between gap-10">
                      {pagination.hasPrevPage && (
                        <div
                          className="rotate-90 cursor-pointer"
                          onClick={() => handleChangePage("prev")}
                        >
                          <DropDownIcon />
                        </div>
                      )}
                      {pagination.hasNextPage && (
                        <div
                          className="-rotate-90 cursor-pointer"
                          onClick={() => handleChangePage("next")}
                        >
                          <DropDownIcon />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {data !== null && !data?.length > 0 && !loading && (
                <TableEmptyState text="investments" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
