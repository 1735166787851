/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { adminRegisterUserSchema } from "@validations/schema";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PhoneInputFormatter } from "@shared/formatter";
import { registerUser } from "@slices/admin-slice";
import { toast } from "react-toastify";
import { Card, Input, Button } from "@ui";

const RegisterUser = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(adminRegisterUserSchema),
    mode: "all",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [phone_input, setPhoneInput] = useState({
    phone_code: "",
    phone_number: "",
    iso_code: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /** Format phone then set to state */
  const getFormattedNumber = (value, data) => {
    const input = PhoneInputFormatter(value, data);
    setPhoneInput({
      ...phone_input,
      phone_code: input.phone_code,
      phone_number: input.phone_number,
      iso_code: input.iso_code,
    });
  };

  const onSubmit = (data) => {
    let formData = {
      name: data.fullname,
      email: data.email,
      phone_code: phone_input.phone_code,
      phone_number: phone_input.phone_number,
      iso_code: phone_input.iso_code,
    };
    setIsLoading(true);
    dispatch(registerUser(formData)).then((response) => {
      if (response.payload.status === 200) {
        navigate("/admin/users");
        toast.success(response?.payload?.data.message);
        // setOpen(true);
        // return;
      }else {
        setIsLoading(false);
      }
    });
  };
  return (
    <Card>
      <div className="max-w-[796px] mx-auto">
        <h2 className="text-heading text-2xl my-2">Register new user</h2>

        <div className="my-6">
          {/* form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Input label="Name" type="text" {...register("fullname")} />
              {errors.fullname?.message && (
                <p className="paragraph text-red text-xs mt-1">
                  * {errors.fullname?.message}
                </p>
              )}
            </div>

            <div>
              <Input
                label="Business email address"
                type="text"
                {...register("email")}
              />
              {errors.email?.message && (
                <p className="paragraph text-red text-xs mt-1">
                  * {errors.email?.message}
                </p>
              )}
            </div>

            <div className="mt-4 mb-6">
              <label className="block text-sm mb-1 text-lightgray">
                {" "}
                Phone Number{" "}
              </label>
              <PhoneInput
                isValid={(value) => {
                  if (value.length < 7 && value.length > 1) {
                    return "* valid phone number is required";
                  } else if (value.length < 7) {
                    return false;
                  } else {
                    return true;
                  }
                }}
                type="text"
                country="us"
                value=""
                onChange={(value, data) => getFormattedNumber(value, data)}
                inputProps={{ name: "phone", required: true }}
                masks={{ fr: "(...) ..-..-..", at: "(....) ...-...." }}
              />
            </div>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!isDirty || !isValid}
            >
              {isLoading ? "Processing..." : "Create Account"}
            </Button>
          </form>
        </div>
      </div>
    </Card>
  );
};

export default RegisterUser;
