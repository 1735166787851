import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const messaging = getMessaging(initializeApp({
    apiKey: "AIzaSyDA4S3ph7PmMHFQ6mFjN05OWr2t7_i2k18",
    authDomain: "applied-theme-340916.firebaseapp.com",
    projectId: "applied-theme-340916",
    storageBucket: "applied-theme-340916.appspot.com",
    messagingSenderId: "403738079125",
    appId: "1:403738079125:web:0d99d32ab04a4e26ac909c"
}));

export const fetchToken = () => {
    return getToken(messaging, { vapidKey: "BFy5wvj_06wWtxKuJisfbrWHoeUiwdWa8pcYrcmTpPCcWBPINfWaQcv4XFwBq6VeJDzpnHgfUd0PIbnYbbvFMGQ" })
        .then((currentToken) => {
            localStorage.setItem("firebaseToken", currentToken);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        }).catch((err) => {
            console.log(err)
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
