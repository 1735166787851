/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React,{useEffect} from "react";
import dayjs from "dayjs";
import { Card, Chips } from "@ui";
import { useDispatch, useSelector } from "react-redux";
import LoadingState from "@components/auctions/auction/LoadingState";
import { fetchAdminAuctionByID } from "@slices/admin-slice";
import { useLocation } from "react-router-dom";
import Countdown from "@components/auctions/Countdown";

const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

const AuctionDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchAdminAuctionByID({ id: location?.state?._id }));
  }, [dispatch, location?.state?._id]);

  const { product, seller, activity, make_bid } = useSelector(
    (state) => state.admin
  );


  return (
    <>
      {Object.keys(product).length == 0 ? (
        <LoadingState />
      ) : (
        <div className="grid lg:grid-cols-3 sm:grid gap-[30px] mt-5">
          <Card className="col-span-2">
            <div className="flex gap-8 pt-1">
              <div className="w-[331px] h-[217px] rounded-lg overflow-hidden">
                <img
                  className="h-full w-full object-cover"
                  src={product?.image[0]?.url}
                  alt="daniOla product card "
                />
              </div>
              <div>
                <h3 className="text-2xl text-heading">
                  {product?.product_name}
                </h3>
                <p className="text-sm text-ts font-light mt-1">
                  {product?.mine?.mine_name}
                </p>
                <Countdown date={product?.end_date} />
                {/* <Countdown /> */}
              </div>
            </div>

            <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Description:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{product?.description}</p>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Quantity:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>
                  {" "}
                  {product?.quantity}{" "}
                  {product?.unit_of_measurement?.id}
                </p>
              </div>
            </div>

            {/* <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Quality:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>Zn 878%</p>
              </div>
            </div> */}

            <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>TC:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>
                  {" "}
                  {product?.treatment_cost} USD/
                  {product?.unit_of_measurement?.id}
                </p>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Price:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>
                  {product?.price} USD/
                  {product?.unit_of_measurement?.id}
                </p>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Total Value:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{product?.total_value} USD</p>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Country:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{product?.mine?.country?.name}</p>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Shipment method:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>
                  {" "}
                  {product?.shipment_options?.map((option, index) => (
                    <span key={index}>{option.slug}&nbsp;</span>
                  ))}
                </p>
              </div>
            </div>
            {/* <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Payment:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>100% payment</p>
              </div>
            </div> */}
            {/* auction's activity */}
            <h3 className="text-heading mt-[41px] mb-6">
              Activity on this auction
            </h3>

            <div className="flex gap-[51px] pb-[75px]">
              <div className="font-light text-ts">
                <p className="mb-4">Bids: </p>
                <p>Last Viewed:</p>
              </div>
              <div className="font-light text-light">
                <p className="mb-4">{activity?.bids}</p>
                <p>{dayjs(product?.updated_at).fromNow()}</p>
              </div>
            </div>
          </Card>
          <div className="col-span-1">
            <Card className="py-[30px]">
              <h3 className="text-heading mb-[18px]">About the seller</h3>
              <div className="grid grid-cols-2 mb-4 font-light">
                <p className="text-base text-ts mb-4 whitespace-nowrap">
                  Member since:
                </p>
                <p className="text-light">
                  {" "}
                  {dayjs(seller?.created_at)?.format("MMMM YYYY")}
                </p>
              </div>
              <div className="grid grid-cols-2 mb-4 font-light">
                <p className="text-base text-ts mb-4">Sells:</p>
                <span className="flex flex-wrap gap-2">
                  <Chips noIcons chips={seller?.sells} />
                </span>
              </div>
              <div className="grid grid-cols-2 mb-4 font-light">
                <p className="text-base text-ts mb-4">Locaton:</p>
                <p className="text-light whitespace-nowrap">
                  {" "}
                  {seller?.country?.name}
                </p>
              </div>
              <div className="grid grid-cols-2 mb-4 font-light">
                <p className="text-base text-ts mb-4">Auctions:</p>
                <p className="text-light">{seller?.auction?.length} active</p>
              </div>
              <div className="grid grid-cols-2 mb-4 font-light">
                <p className=" text-base text-ts mb-4">Deals:</p>
                <p className="text-light">{seller?.deals}</p>
              </div>
              <div className="grid grid-cols-4 gap-2 mt-8">
                {seller?.sdg_goals?.map((sdg_goal) => (
                  <img
                    key={sdg_goal.id}
                    src={sdg_goal.image_url}
                    alt={sdg_goal.name}
                  />
                ))}
              </div>
            </Card>
            <Card className="py-[30px] mt-[66px]">
              <h3 className="text-heading mb-[18px]">Bids</h3>
              <div className="grid grid-cols-2 mb-4 font-light border-lightgray500">
                <div>
                  {/* <p className="text-base text-heading mb-4 whitespace-nowrap">
                    Gary Simpson
                  </p> */}
                  <p className="text-light">Bid:{make_bid?.min_amount} USD</p>
                </div>
                <p className="text-lightgray text-sm">
                  {dayjs(activity?.viewed).fromNow()}
                </p>
              </div>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default AuctionDetails;
