/* eslint-disable no-unused-vars */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DocumentIcon from "components/ui/icons/DocumentIcon";
import { PaperClip, TrashIcon } from "@ui/icons";
import { Card, Input, TextArea, Button } from "@ui";

const ManageOfftaker = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userProfile = location.state.data;
  return (
    <Card>
      <div className="max-w-[796px] mx-auto">
        <h2 className="text-heading text-2xl my-2">Off-taker profile</h2>
        <p>User ID: {userProfile?.user?._id}</p>

        <div className="my-6">
          <h3 className="my-2 text-lg text-heading">Basic Information</h3>
          {/* form */}
          <form>
            <div>
              <Input label="Name" type="text" value={userProfile?.user?.name} />
            </div>

            <div>
              <Input
                label="Business email address"
                type="text"
                value={userProfile?.user?.email}
              />
            </div>

            <div className="mt-4">
              <Input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                value={` +${userProfile?.user.phone?.code} ${userProfile?.user.phone?.number}`}
              />
            </div>

            <h3 className="my-6 text-lg text-heading">
              Documents and Additional Information
            </h3>

            <div>
              <div className="block text-sm  text-lightgray my-4">
                Company registration documentation
              </div>
              <div className="flex justify-between mb-2">
                <div className="flex flex-col ">
                  {userProfile?.profile[0]?.form?.form_content?.files && (
                    <div>
                      {userProfile?.profile[0]?.form?.form_content?.files.map(
                        (file, i) => (
                          // eslint-disable-next-line react/jsx-key
                          <a
                            key={i}
                            download
                            target="_blank"
                            rel="noreferrer"
                            href={file.url}
                            className=" flex items-center  text-xs mb-[16px]"
                          >
                            <DocumentIcon />
                            <span className="text-primary text-sm font-light ml-2">
                              {file.name}
                            </span>
                          </a>
                        )
                      )}
                    </div>
                  )}
                </div>
                {/* <div className="cursor-pointer ">
                  <TrashIcon />
                </div> */}
              </div>
            </div>

            <div>
              <Input
                label="Company Name"
                type="text"
                value={
                  userProfile?.profile[0]?.form?.form_content?.company_name
                }
              />
            </div>

            <TextArea
              label="Description"
              value={userProfile?.profile[0]?.form?.form_content?.description}
            />

            <div className="w-full mt-3">
              <label
                className="block text-xs mb-2  text-lightgray"
                htmlFor="address"
              >
                Address
              </label>
              <Input
                type="text"
                value={
                  userProfile?.profile[0]?.form?.form_content?.location?.address
                }
                readOnly
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <Input
                id="city"
                type="text"
                placeholder=""
                label="City"
                name="city"
                value={userProfile?.profile[0]?.form?.form_content?.city}
                readOnly
              />

              <Input
                id="state"
                type="text"
                label="State"
                name="state"
                value={userProfile?.profile[0]?.form?.form_content?.state}
                readOnly
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <Input
                label="Postal code"
                type="number"
                value={
                  userProfile?.profile[0]?.form?.form_content?.location
                    ?.postal_code
                }
                readOnly
              />

              <Input
                id="country"
                label="Country"
                type="text"
                placeholder=""
                name="country"
                value={
                  userProfile?.profile[0]?.form?.form_content?.country?.name
                }
                readOnly
              />
            </div>

            <div className="flex justify-between  mt-[47px]">
              <Button
                type="button"
                variant="contained"
                color="offwhite"
                onClick={() => {
                  navigate(
                    "../account-information" + `/${userProfile.user._id}`
                  );
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Update
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Card>
  );
};

export default ManageOfftaker;
