import React from "react";
import { Card, Button, Checkbox } from "@ui";

const ConfirmAndSendRequest = ({
  setAction,
  state,
  handleChange,
  transaction,
}) => {
  return (
    <>
      <div className="flex flex-row justify-between mb-[20px]">
        <p className="text-[22px] text-left text-black">Review contract</p>
        <Button
          size="small"
          onClick={() => setAction("sign-contract")}
          disabled={!state.agreed_term}
        >
          Next
        </Button>
      </div>

      <Card noPadding>
        <div className="border-b-[1px] border-[#1C345442] mx-[-1.5rem]">
          <div className="divide-x divide-disabled mx-[2.5rem]">
            <div className="text-default bg-white">
              <div className="grid gap-[2.5rem] grid-cols-2">
                <div className="border-r-[1px] border-[#1C345442]  py-[30px]">
                  <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[24px] text-left text-[#192434]">
                    Seller
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#1b2b41]/[0.72]">
                    {transaction?.seller?.name}
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    {transaction?.seller?.company_name}
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    {transaction?.seller?.location?.address}
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    +{transaction?.seller?.phone_number?.code}
                    {transaction?.seller?.phone_number?.number}
                  </p>
                </div>
                <div className="py-[30px]">
                  <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[24px] text-left text-[#192434]">
                    Buyer
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#1b2b41]/[0.72]">
                    {transaction?.buyer?.name}
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    {transaction?.buyer?.company_name}
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    {transaction?.buyer?.location?.address}
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    +{transaction?.buyer?.phone_number?.code}
                    {transaction?.buyer?.phone_number?.number}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-[1px] border-[#1C345442] mx-[-1.5rem]">
          <div className="divide-x divide-disabled mx-[2.5rem]">
            <div className="text-default bg-white">
              <div className="grid grid-cols-8">
                <div className="pt-[30px] pb-[10px] col-span-1">
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    Description:
                  </p>
                </div>
                <div className="pt-[30px] pb-[10px] col-span-7">
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    {transaction?.product?.product_description}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-8">
                <div className="pb-[10px] col-span-1">
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    Quantity:
                  </p>
                </div>
                <div className="pb-[10px] col-span-7">
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    {transaction?.product?.product_quantity}
                    DMT
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-8">
                <div className="pb-[10px] col-span-1">
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    TC:
                  </p>
                </div>
                <div className="pb-[10px] col-span-7">
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    {transaction?.product?.product_treatment_cost}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-8">
                <div className="pb-[10px] col-span-1">
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    Price:
                  </p>
                </div>
                <div className="pb-[10px] col-span-7">
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    {transaction?.product?.product_price}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-8">
                <div className="pb-[10px] col-span-1">
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    Total value:
                  </p>
                </div>
                <div className="pb-[10px] col-span-7">
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    {transaction?.product?.product_total_value + "USD" ?? "N/A"}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-8">
                <div className="pb-[10px] col-span-1">
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    Payment
                  </p>
                </div>
                <div className="pb-[10px] col-span-7">
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    100% payment to Escrow, payment processed after confirmed
                    delivery
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-8">
                <div className="pb-[10px] col-span-1">
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    Additional clause
                  </p>
                </div>
                <div className="pb-[10px] col-span-7">
                  <div className="mb-3 xl:w-full">
                    <textarea
                      className="form-control resize-none block w-full p-[10px] font-light bg-clip-padding bg-[#1A38601A] border-none z-0 focus:shadow focus:outline-none focus:ring-primary rounded transition ease-in-out"
                      id="exampleFormControlTextarea1"
                      rows="5"
                      name="clause"
                      value={state.clause}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-8">
                <div className="pb-[30px] col-span-1">
                  {/* <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    Signature
                  </p> */}
                </div>
                <div className="pb-[30px] col-span-7">
                  <div className="mb-3 xl:w-full">
                    <div className="flex items-center gap-2 mt-[10px]">
                      <Checkbox
                        value={state.agreed_term}
                        onChange={handleChange}
                        name="agreed_term"
                      />
                      <p className="font-light text-ts mb-">
                        I agree to the Terms that this is signed by me.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ConfirmAndSendRequest;
