/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Card, Button, TableEmptyState } from "@ui";
import {
  // SlidersIcon,
  SearchIcon,
} from "@ui/icons";
import { getInitials } from "@shared/utility";
import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { Search } from "@ui";
import { Pagination } from "@atoms/pagination/Pagination";
import { useParams } from "react-router-dom";
// import { checkPermission } from "shared/utility";
import { getTender, searchTender } from "@slices/admin-slice";
import { useDispatch, useSelector } from "react-redux";
// import ConfirmationModal from "@components/atoms/modals/confirmationModal";
import Avatar from "react-avatar";
import { checkNumberOfDaysLeft } from "@shared/utility";
// import { ConfirmationAwardBidModal } from "./ConfirmationAwardBidModal";

const TDetailsSearchInput = ({ label, prefix, ...rest }) => (
  <div className="mb-3 xl:w-full">
    <label
      htmlFor={label}
      className="form-label inline-block mb-2 font-light text-sm text-[#1B2B41B8]"
    >
      {label}
    </label>
    {/* input field */}
    <div className="relative flex items-center">
      {/* the prefix */}
      <div className="absolute left-0 px-2.5 text-light pointer-events-none">
        {prefix}
      </div>
      {/* input element */}
      <input
        id={label}
        {...rest}
        type="text"
        className="w-full h-[42px] max-h-[42px] p-[10px] border-none font-light text-gray-700 bg-[#1A38601A] rounded z-0 focus:shadow focus:outline-none focus:ring-primary  placeholder:text-tertiary placeholder:font-extralight placeholder:text-base"
      />
    </div>
  </div>
);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TenderDetBasicTabs({ bids }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="w-full">
      <div className="border-b border-gray-light">
        <div className="flex items-center justify-between">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="secondary"
          >
            <Tab label={`Bid (${bids?.length})`} {...a11yProps(0)} />
            {/* <Tab label="Archived" {...a11yProps(1)} /> */}
          </Tabs>
          <p className="font-extralight text-sm text-heading">
            Total bids: <span className="text-primary">{bids?.length}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

const TendersDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams, setParams] = useState();
  // const [open, setOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  // const [showSuccess, setShowSuccess] = useState(false);
  // const [bid, setBid] = useState(null);
  // const [actionType, setActionType] = useState(null);
  const {
    loading,
    tender: { bids, product },
  } = useSelector((state) => state.admin);


  React.useEffect(() => {
    dispatch(getTender(id));
  }, [dispatch]);

  const handleSearch = (e) => {
    if (e.target.value == "") {
      dispatch(getTender(id));
    }

    setParams(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    dispatch(
      searchTender({
        search: searchParams,
        id: id,
      })
    );
  };

  // const handleAwardBid = async () => {
  //   try {
  //     setIsLoading(true);
  //     setActionType("Award");
  //     const body = {
  //       productId: id,
  //       bidId: bid?.uuid,
  //       data: {
  //         status: "awarded",
  //       },
  //     };
  //     const response = await dispatch(awardABid(body));
  //     if (response.payload.status === 200) {
  //       setShowSuccess(true);
  //       setOpen(false);
  //     }
  //     setIsLoading(false);
  //     setOpen(false);
  //   } catch (error) {
  //     setShowSuccess(false);
  //     setOpen(false);
  //     setIsLoading(false);
  //   }
  // };

  // const handleArchiveBid = async () => {
  //   try {
  //     setIsLoading(true);
  //     setActionType("Archive");
  //     const body = {
  //       productId: id,
  //       bidId: bid?.uuid,
  //       data: {
  //         status: "archived",
  //       },
  //     };

  //     const response = await dispatch(awardABid(body));
  //     if (response.payload.status === 200) {
  //       setShowSuccess(true);
  //       dispatch(getTender(id));
  //     }
  //     setIsLoading(false);
  //     setOpen(false);
  //   } catch (error) {
  //     setShowSuccess(false);
  //     setOpen(false);
  //     setIsLoading(false);
  //   }
  // };

  return (
    <>
      {/* <ConfirmationAwardBidModal
        open={open}
        handleArchiveBid={handleArchiveBid}
        handleAwardBid={handleAwardBid}
        setOpen={setOpen}
        actionType={actionType}
        amount={bid?.amount}
        name={bid?.name}
        showSuccess={showSuccess}
        setShowSuccess={setShowSuccess}
        isLoading={isLoading}
        unit={bid?.product?.product_unit_of_measurement?.id}
      /> */}
      <p className=" text-[22px] inline-flex text-left text-black mb-[15px]">
        {product?.product_name}
      </p>
      <span
        className={`py-1 px-5 mx-5 inline-flex capitalize text-sm leading-5 font-light rounded bg-[#D9F2E4] text-[#43BE77] `}
      >
        {product?.status}
      </span>
      <p className=" text-[13px] inline-flex text-left text-black mb-[15px]">
        {/* 20 days left */}
        {/* {product?.product_end_date} */}
        {checkNumberOfDaysLeft(
          product?.product_end_date,
          product?.product_start_date
        )}
      </p>

      <TenderDetBasicTabs bids={bids} />
      {/* input fields and button */}

      <div className="flex items-center justify-between relative">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <form
              onSubmit={(e) => handleSearchSubmit(e)}
              className="flex  gap-2 mt-3 rounded py-1 px-2 mb-3"
            >
              <div className="w-[250px] lg:w-[548px]">
                <Search
                  placeholder="Search Tenders"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
              <Button type="submit" color="primary">
                <SearchIcon color="#ffff" opacity="1" />
              </Button>
            </form>
            {/* <div className="w-[548px]">
              <TDetailsSearchInput placeholder="Search Tenders" />
            </div>
            <div className="bg-primary flex items-center justify-center h-[42px] w-[42px] mt-3 rounded py-1 px-2">
              <SearchIcon color="#ffff" opacity="1" />
            </div> */}
          </div>
        </div>
        {/* <div>
          <Button variant="contained" color="white" endIcon={<SlidersIcon />}>
            Filter
          </Button>
        </div> */}
      </div>
      {/* vendors */}
      <div className="grid grid-cols-3 gap-[30px]">
        {bids?.map((bid, index) => (
          <Card
            key={index}
            className="flex-grow-0 flex-shrink-0 w-[355px] h-[200px] "
          >
            <div className="flex flex-col justify-start items-start  left-[23px] top-[23px] gap-4">
              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 w-[100%] h-[66px] mt-4">
                <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill mr-3">
                  {bid?.accounts[0]?.profile_img?.length > 0 ? (
                    <img
                      className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                      src={`${bid?.accounts[0]?.profile_img[0]?.url}`}
                      alt=""
                    />
                  ) : (
                    `${getInitials(bid?.user_details?.name)}`
                  )}
                  {/* <Avatar name={bid?.user_details?.name} round={true} size="85" /> */}

                  {/* <img
                    className="max-w-[60px]"
                    src={ProfilePicture}
                    alt="Miley Cyrus"
                  /> */}
                </div>
                <div className="flex flex-col justify-start items-start flex-grow-0 ml-4 flex-shrink-0 space-y-2 ">
                  <p className="flex-grow-0 flex-shrink-0 mb-1 text-base text-left text-black">
                    {bid?.user_details?.name}
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 font-light text-[#1b2b41]/[0.72] mb-1 text-base text-left w-[200px]">
                    {bid?.user_details?.company}
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    {bid?.user_details?.country}
                  </p>
                  <div className="flex flex-row justify-start items-center  space-x-2 mb-[15px]  w-[270px] h-[26px]">
                    <p className=" left-0 top-[3.5px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
                      Bid:
                    </p>
                    <p className=" left-[159px] top-0 text-base font-light text-left text-[#18273a]/[0.94]">
                      {bid.amount} USD/
                      {bid?.product?.product_unit_of_measurement?.id}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
      {!bids?.length > 0 && (
        <TableEmptyState loading={loading} text="product" />
      )}
      {!loading && (
        <div className="flex justify-between w-full mt-5">
          {" "}
          <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-[388px]">
            {/* <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
              <p className="flex-grow-0 flex-shrink-0 text-sm font-light text-center text-black">
                Products per page
              </p>
              <div className="flex-grow-0 flex-shrink-0 w-[113px] h-[42px]">
                <div className="w-[113px] h-[42px] absolute left-[137.5px] top-[-0.5px]">
                  <div className="w-[113px] h-[42px] absolute left-[-1px] top-[-1px] rounded bg-white border border-[#1a3860]/10"></div>
                </div>
                <p className="absolute left-[148px] top-2.5 text-base font-light text-left text-[#18273a]/[0.94]">
                  9
                </p>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 absolute left-[218.5px] top-[8.5px]"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z"
                    fill="#1C2E45"
                    fillOpacity="0.6"
                  ></path>
                </svg>
              </div>
            </div> */}
          </div>
          {/* <Pagination /> */}
        </div>
      )}
    </>
  );
};

export default TendersDetails;
