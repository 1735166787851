import React from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { statusColorScheme } from "constants/index";

const AccountDetails = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-2 gap-7">
      <div>
        <p className="text-lg font-semibold">General Information</p>
        <div className="flex justify-between mt-4">
          <span className="text-[#1B2B41B8] font-light text-4 text-base">
            Date joined
          </span>
          <span>{dayjs(data?.user?.created_at).format("DD-MM-YYYY")}</span>
        </div>
        <div className="flex justify-between mt-4">
          <span className="text-[#1B2B41B8] font-light text-4 text-base">
            Approval status
          </span>
          <span
            className={`py-1 px-2 inline-flex text-sm leading-5 font-light rounded  bg-[${
              statusColorScheme[data?.profile[0]?.status].bg
            }] text-[${statusColorScheme[data?.profile[0]?.status].text}] `}
          >
            {data?.profile[0]?.status}
          </span>
        </div>
        {/* <div className="flex justify-between mt-4">
          <span className="text-[#1B2B41B8] font-light text-4 text-base">
            Suspension status
          </span>
          <span className="py-1 px-2 inline-flex text-sm leading-5 font-light rounded bg-[#193B670D] text-[#1A293DD4]">
            Active
          </span>
        </div> */}
      </div>

      <div>
        <p className="text-lg font-semibold ">Profiles</p>
        {data.profile[0]?.role.name == "Vendor" && (
          <div className="flex justify-between mt-5 border-[1px] border-[#D9D9D9] rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">
              Vendor
            </span>
            <span
              className="text-primary text-sm font-semibold cursor-pointer"
              onClick={() =>
                navigate("../manage-vendor", {
                  state: { data: data },
                })
              }
            >
              Manage
            </span>
          </div>
        )}
        {data.profile[0]?.role.name == "Miner" && (
          <div className="flex justify-between mt-5 border-[1px] border-[#D9D9D9] rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">
              Miner
            </span>
            <span
              className="text-primary text-sm font-semibold cursor-pointer"
              onClick={() =>
                navigate("../manage-miner", {
                  state: { data: data },
                })
              }
            >
              Manage
            </span>
          </div>
        )}
        {data.profile[0]?.role.name == "OffTaker" && (
          <div className="flex justify-between mt-5 border-[1px] border-[#D9D9D9] rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">
              Off-taker
            </span>
            <span
              className="text-primary text-sm font-semibold cursor-pointer"
              onClick={() =>
                navigate("../manage-offtaker", {
                  state: { data: data },
                })
              }
            >
              Manage
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountDetails;
