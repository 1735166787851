/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Skeleton from "@components/auctions/Skeleton";
import Auction from "@components/auctions/auction/Auction";
import { Search, Dropdown, Button } from "@ui";
import { SearchIcon } from "@ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuctions,
  searchAuctions,
} from "store/slices/miners/auctions-slice";
import { MinerAuctionDetailsUrl } from "config/app-url";

const Auctions = () => {
  const { loading, auctions } = useSelector((state) => state.auctions);
  const [searchParams, setParams] = useState();
  const dispatch = useDispatch();
  const handleSearch = (e) => {
    if (e.target.value == "") {
      dispatch(getAuctions());
    }

    setParams(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    dispatch(
      searchAuctions({
        search: searchParams,
      })
    );
  };
  useEffect(() => {
    dispatch(getAuctions());
  }, [dispatch]);

  return (
    <div>
      {/* searchbar and buttons */}
      <div className="flex justify-between">
        <div className="flex gap-2">
          <form
            onSubmit={(e) => handleSearchSubmit(e)}
            className="flex  gap-2 rounded py-1 px-2"
          >
            <div className="w-[250px] lg:w-[548px]">
              <Search
                placeholder="Search Auctions"
                onChange={(e) => handleSearch(e)}
              />
            </div>
            <Button type="submit" color="primary">
              <SearchIcon color="#ffff" opacity="1" />
            </Button>
          </form>
          {/* <div className="w-[548px]">
            <Search placeholder="Search Auctions" />
          </div>
          <div className="bg-primary flex items-center justify-center rounded py-1 px-2">
            <SearchIcon color="#ffff" opacity="1" />
          </div> */}
        </div>
        <div className="flex items-center justify-between gap-[11px]">
          {/* <Button variant="contained" color="white" endIcon={<SlidersIcon />}>
            Filter
          </Button> */}
          <Dropdown />
        </div>
      </div>
      {/* main */}
      <div className="grid lg:grid-cols-3 sm:grid gap-[30px] mt-5 font-default">
        {loading ? (
          <>
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <Skeleton key={index} />
              ))}
          </>
        ) : (
          auctions.map((auction) => (
            <Auction
              auction={auction}
              key={auction.id}
              url={MinerAuctionDetailsUrl}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default Auctions;
