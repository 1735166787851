/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";

export const TextArea = forwardRef(function TextArea(
  { placeholder, name, type, size, ...props },
  ref
) {
  return (
    <React.Fragment>
      <textarea
        className={`bg-fill resize-none border-none focus:outline-none ${
          size === "large" ? "h-[493px]" : size === "mid" ? "h-[258px]" : "h-28"
        } text-sm focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded cursor-text text-input py-2 px-4`}
        type={type}
        placeholder={placeholder}
        name={name}
        ref={ref}
        {...props}
      />
    </React.Fragment>
  );
});
