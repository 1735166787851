/* eslint-disable no-unused-vars */
import React, { useEffect,useState } from "react";
import { useLocation } from "react-router-dom";
import { checkPermission } from "shared/utility";
import { Button,Card, Chips } from "@ui";
import dayjs from "dayjs";
import { getServiceOptions } from "store/slices/miners/vendor-slice";
import {
  getSingleOfftakerDemandInterestDetails,
  awardDemandInterest,
} from "store/slices/offtakers/demands-slice";
import { useDispatch, useSelector } from "react-redux";
import Complete from "./Complete";

const DemandInterestDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = location.state;
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { demandInterestDetails, loading, member_since } = useSelector(
    (state) => state.offtaker_demands
  );
  const { demand_commission_charge } = useSelector((store) => store.miner_vendors);

  useEffect(() => {
    dispatch(getSingleOfftakerDemandInterestDetails(id));
    dispatch(getServiceOptions());
    // eslint-disable-next-line
  }, [dispatch]);
  const demand = {
    product_image: demandInterestDetails?.demand?.product?.image[0].url,
    product_name: demandInterestDetails?.demand?.product?.product_name,
    product_total_value: demandInterestDetails?.demand?.product?.total_value,
    product_treatment_cost:
      demandInterestDetails?.demand?.product?.treatment_cost,
    product_quantity: demandInterestDetails?.demand?.product?.quantity,
    product_price: demandInterestDetails?.demand?.product?.price,
    product_unit_of_measurement:
      demandInterestDetails?.demand?.product?.unit_of_measurement?.id,
    mine: demandInterestDetails?.demand?.product?.mine?.mine_name,
    product_description: demandInterestDetails?.demand?.product?.description,
    country: demandInterestDetails?.demand?.demand?.country?.name,
    shipping_type: demandInterestDetails?.demand?.demand?.shipping_type?.slug,
  };
  const miner = {
    sdg_goals: demandInterestDetails?.demand?.product?.mine?.sdg_goals,
    address: demandInterestDetails?.demand?.product?.mine?.location?.address,
  };

  const onSubmit = async () => {
    const response = await dispatch(awardDemandInterest(id));

    if (
      response?.payload?.status === 200 ||
      response?.payload?.status === 201
    ) {
      setSubmitted(true);
    }
  };

  return (
    <>
      <div className="grid grid-cols-12 w-full gap-x-8">
        <div className="col-span-12 flex justify-end mb-4">
          <Button
            variant="contained"
            color="primary"
            disabled={checkPermission("Demands")}
            onClick={() => setOpenSubmitModal(true)}
          >
            Award supply
          </Button>
        </div>
        <div className="col-span-8 bg-white px-[34px] p-[30px]">
          <div className="flex mb-10 gap-x-8">
            <div className="h-[217px] w-80 rounded-lg overflow-hidden">
              <img
                className="h-full w-full object-cover"
                src={demand?.product_image}
                alt="daniOla product card image"
              />
            </div>
            <div className="">
              <h3 className="text-2xl text-heading capitalize text-left">
                {demand?.product_name}
              </h3>
              <p className="text-sm text-light font-light">{demand?.mine}</p>
            </div>
          </div>

          <div className="grid grid-cols-7 gap-y-2 mb-3">
            <div className="col-span-2 font-light text-ts text-sm">
              <p>Description</p>
            </div>
            <div className="col-span-5 font-light text-light">
              <p>{demand?.product_description}</p>
            </div>
          </div>

          <div className="grid grid-cols-7 gap-y-2 mb-3">
            <div className="col-span-2 font-light text-ts text-sm">
              <p>Quantity</p>
            </div>
            <div className="col-span-5 font-light text-light">
              <p>
                {demand?.product_quantity} {demand?.product_unit_of_measurement}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-7 gap-y-2 mb-3">
            <div className="col-span-2 font-light text-ts text-sm">
              <p>TC</p>
            </div>
            <div className="col-span-5 font-light text-light">
              <p>
                {demand?.product_treatment_cost} USD/
                {demand?.product_unit_of_measurement}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-7 gap-y-2 mb-3">
            <div className="col-span-2 font-light text-ts text-sm">
              <p>Price</p>
            </div>
            <div className="col-span-5 font-light text-light">
              <p>
                {demand?.product_price} USD/
                {demand?.product_unit_of_measurement}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-7 gap-y-2 mb-3">
            <div className="col-span-2 font-light text-ts text-sm">
              <p>Total value</p>
            </div>
            <div className="col-span-5 font-light text-light">
              <p>{demand?.product_total_value} USD</p>
            </div>
          </div>
          <div className="grid grid-cols-7 gap-y-2 mb-3">
            <div className="col-span-2 font-light text-ts text-sm">
              <p>Country</p>
            </div>
            <div className="col-span-5 font-light text-light">
              <p>{demand?.country}</p>
            </div>
          </div>
          <div className="grid grid-cols-7 gap-y-2 mb-3">
            <div className="col-span-2 font-light text-ts text-sm">
              <p>Shipment type</p>
            </div>
            <div className="col-span-5 font-light text-light">
              <p>{demand?.shipping_type} </p>
            </div>
          </div>
        </div>
        <div className="col-span-4">
          <Card className="py-[30px]">
            <h3 className="text-heading mb-[18px]">About the seller</h3>
            <div className="grid grid-cols-2 mb-4 font-light">
              <p className="text-base text-ts mb-4 whitespace-nowrap">
                Member since:
              </p>
              <p className="text-light">
                {dayjs(member_since)?.format("MMMM YYYY")}
              </p>
            </div>

            <div className="grid grid-cols-5 mb-4 font-light">
              <p className="col-span-2 text-base text-ts">Sells:</p>
              <span className="col-span-3 flex flex-wrap gap-2">
                <Chips chips={miner?.sdg_goals} noIcons />
              </span>
            </div>
            <div className="grid grid-cols-5 mb-4 font-light">
              <p className="col-span-2 text-base text-ts">Location:</p>
              <p className="col-span-3 text-light whitespace-nowrap">
                {miner?.address}
              </p>
            </div>
            <div className="grid grid-cols-5 mb-4 font-light">
              <p className="col-span-2 text-base text-ts">Auctions:</p>
              <p className="col-span-3 text-light">6 active</p>
            </div>
            <div className="grid grid-cols-5 mb-4 font-light">
              <p className="col-span-2 text-base text-ts">Deals:</p>
              <p className="col-span-3 text-light">100 completion rate</p>
            </div>
            <div className="grid grid-cols-4 gap-2 mt-8">
              {miner?.sdg_goals?.map((goal) => (
                <img src={goal?.image_url} alt="Zero Hunger" key={goal?.id} />
              ))}
            </div>
          </Card>
        </div>
      </div>
      <Complete
        {...{
          demand_commission_charge,
          openSubmitModal,
          setOpenSubmitModal,
          demandInterestDetails,
          submitted,
          onSubmit,
          loading,
        }}
      />
    </>
  );
};

export default DemandInterestDetails;
