/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { BarChartIcon, DiamondIcon, MoneyIcon, PackageIcon } from "@ui/icons";
import HandshakeIcon from "./icons/HandshakeIcon";
// import {useAuth} from "@hooks"

const Stat = ({ bgColor, children }) => {
  const backgroundColor = bgColor ? bgColor : "bg-white";
  const boxShadow = bgColor && "shadow-Stat";
  return (
    <div
      className={`flex items-center justify-between border border-solid border-dark-gray-100 px-6 py-5 rounded-lg ${backgroundColor} ${boxShadow}`}
    >
      {children}
    </div>
  );
};

const Stats = ({ statData, counts, type }) => {
  // const { profileStatus } = useAuth()
  // const sales = profileStatus === "verified" ? 100 : 0;
  // const transactions = profileStatus === "verified" ? 10 : 0;
  // const items = profileStatus === "verified" ? 120 : 0;
  // const mines = profileStatus === "verified" ? 3 : 0;

  return (
    <>
      {statData?.map((data, index) => {
        return (
          <Stat key={index}>
            <div>
              <p className="text-sm mb-2 uppercase text-[#92A2AA]">
                {data.name}
              </p>
              {counts && (
                <p className="text-2xl text-[#374146]">{data.key == "wallet_balance" ?  parseFloat(counts[data.key]).toFixed(2) : counts[data.key]} {type == "wallet" ? "USD" : "" }</p>
              )}
            </div>
            <div>{data.icon}</div>
          </Stat>
        );
      })}
    </>
  );
};

export default Stats;
