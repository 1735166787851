/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as API from "../../config/url";
import { toast } from "react-toastify";

const getProfile = createAsyncThunk(
  "user/getProfile",
  async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "get",
      url: API.getProfile,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
    };

    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getSupport = createAsyncThunk(
  "user/getSupport",
  async (payload, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "get",
      url: API.getSupport(payload.role),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
    };

    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async (payload, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "put",
      url: API.updateProfile(payload.id),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
      data: payload.formdata,
    };

    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const submitTicket = createAsyncThunk(
  "user/submitTicket",
  async (payload, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "post",
      url: API.postTicket(payload.role),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
      data: payload.data,
    };

    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const uploadProfile = createAsyncThunk(
  "user/uploadProfile",
  async (form, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "post",
      url: API.uploadImage,
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": "bearer "+ token
      },
      data: form,
    };

    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const removeImage = createAsyncThunk(
  "user/removeImage",
  async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "post",
      url: API.removeImage,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
    };

    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const deleteAccount = createAsyncThunk(
  "user/deleteAccount",
  async (payload, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "delete",
      url: API.deleteAccount(payload.id),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
    };

    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const changePassword = createAsyncThunk(
  "user/changePassword",
  async (data, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "post",
      url: API.changeUserPassword,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
      data: {
        old_password: data.old_password,
        new_password: data.new_password,
      },
    };

    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getNotifications = createAsyncThunk(
  "user/getNotifications",
  async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "get",
      url: API.getNotifications,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
    };

    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAllNotifications = createAsyncThunk(
  "user/fetchAllNotifications",
  async (payload, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "get",
      url: API.fetchNotifications(payload.limit),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
    };

    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const readAllNotifications = createAsyncThunk(
  "user/readAllNotifications",
  async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "post",
      url: API.readAllNotifications,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
    };

    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const readNotification = createAsyncThunk(
  "user/readNotification",
  async (payload, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "post",
      url: API.readNotification(payload.id),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
    };

    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateNotifications = createAsyncThunk(
  "user/updateNotifications",
  async (data, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "post",
      url: API.getNotifications,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
      data: {
        email: data.email,
        app: data.app,
      },
    };

    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getSupportBySlug = createAsyncThunk(
  "user/getSupportBySlug",
  async (data, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "get",
      url: API.getSupportBySlug(data?.slug),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      }
    };

    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const slice = createSlice({
  name: "settings",

  initialState: {
    loading: false,
    error: null,
    data: {},
    support:[],
    support_slug:{},
    notification:{},
    allnotifications:[],
    notificationcount:{},
    success: false,
    message: "",
  },
  reducers: {},

  extraReducers: {
    // get user profile
    [getProfile.pending]: (state) => {
      state.loading = true;
    },
    [getProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data.user;
      state.success = true;
    },
    [getProfile.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [getSupport.pending]: (state) => {
      state.loading = true;
    },
    [getSupport.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.support = payload.data.support;
      state.success = true;
    },
    [getSupport.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [submitTicket.pending]: (state) => {
      state.loading = true;
    },
    [submitTicket.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
    },
    [submitTicket.rejected]: (state, { payload }) => {
      state.loading = false;
      toast.error(payload.message);
    },
    [updateProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
    },
    [updateProfile.rejected]: (state, { payload }) => {
      state.loading = false;
      toast.error(payload.message);
    },
    [uploadProfile.pending]: (state) => {
      state.loading = true;
    },
    [uploadProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
    },
    [uploadProfile.rejected]: (state, { payload }) => {
      state.loading = false;
      toast.error(payload.message);
    },
    [removeImage.pending]: (state) => {
      state.loading = true;
    },
    [removeImage.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
    },
    [removeImage.rejected]: (state, { payload }) => {
      state.loading = false;
      toast.error(payload.message);
    },
    [deleteAccount.pending]: (state) => {
      state.loading = true;
    },
    [deleteAccount.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
    },
    [deleteAccount.rejected]: (state, { payload }) => {
      state.loading = false;
      toast.error(payload.message);
    },
    [changePassword.pending]: (state) => {
      state.loading = true;
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.success = true;
    },
    [changePassword.rejected]: (state, { payload }) => {
      state.loading = false;
      toast.error(payload.message);
    },
    [fetchAllNotifications.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllNotifications.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.allnotifications = payload.data.notication;
      state.notificationcount = payload.data.count;
      state.success = true;
    },
    [fetchAllNotifications.rejected]: (state, { payload }) => {
      state.loading = false;
      toast.error(payload.message);
    },

    [getNotifications.pending]: (state) => {
      state.loading = true;
    },
    [getNotifications.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.notification = payload.data;
      state.success = true;
    },
    [getNotifications.rejected]: (state, { payload }) => {
      state.loading = false;
      toast.error(payload.message);
    },
    [readAllNotifications.pending]: (state) => {
      state.loading = true;
    },

    [readAllNotifications.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
    },
    [readAllNotifications.rejected]: (state, { payload }) => {
      state.loading = false;
      toast.error(payload.message);
    },
    [readNotification.pending]: (state) => {
      state.loading = true;
    },

    [readNotification.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
    },
    [readNotification.rejected]: (state, { payload }) => {
      state.loading = false;
      toast.error(payload.message);
    },
    [updateNotifications.pending]: (state) => {
      state.loading = true;
    },

    [updateNotifications.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
    },
    [updateNotifications.rejected]: (state, { payload }) => {
      state.loading = false;
      toast.error(payload.message);
    },
    [getSupportBySlug.pending]: (state) => {
      state.loading = true;
    },

    [getSupportBySlug.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.support_slug = payload.support;
    },
    [getSupportBySlug.rejected]: (state, { payload }) => {
      state.loading = false;
      toast.error(payload.message);
    },
  },
});

export {
  getProfile,
  getSupport,
  updateProfile,
  submitTicket,
  deleteAccount,
  uploadProfile,
  removeImage,
  changePassword,
  getNotifications,
  fetchAllNotifications,
  readNotification,
  readAllNotifications,
  getSupportBySlug,
  updateNotifications,
};
export default slice.reducer;
