const Sort = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 11H9L12 7L15 11Z" fill="#1C2E45" fillOpacity="0.6" />
      <path d="M9 13H15L12 17L9 13Z" fill="#1C2E45" fillOpacity="0.6" />
    </svg>
  );
};

export default Sort;
