const MoneyIcon = () => {
  return (
    <svg
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.4584 14.1667V27.8333H8.54171V14.1667H32.4584ZM34.1667 12.4583H6.83337V29.5417H34.1667V12.4583Z"
        fill="#6166B6"
      />
      <path
        d="M20.5 15.875C23.4042 15.875 25.625 18.0958 25.625 21C25.625 23.9042 23.4042 26.125 20.5 26.125H29.0417V24.4167H30.75V17.5833H29.0417V15.875H20.5Z"
        fill="#6166B6"
      />
      <path
        d="M15.375 21C15.375 18.0958 17.5958 15.875 20.5 15.875H11.9583V17.5833H10.25V24.4167H11.9583V26.125H20.5C17.5958 26.125 15.375 23.9042 15.375 21Z"
        fill="#6166B6"
      />
    </svg>
  );
};

export default MoneyIcon;
