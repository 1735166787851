/** @format */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Search, Button, Dropdown } from "@ui";
import { SlidersIcon, SearchIcon } from "@ui/icons";
import { getSingleOfftakerDemandInterests } from "store/slices/offtakers/demands-slice";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@components/auctions/Skeleton";
import Demand from "./Demand";
import TableEmptyState from "components/ui/TableEmptyState";

const OfftakerAuctions = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = location.state;
  const [searchParams, setParams] = useState();
  const {
    demandInterests: { data },
    loading,
  } = useSelector((state) => state.offtaker_demands);

  const handleSearch = (e) => {
    if (e.target.value == "") {
      dispatch(
        getSingleOfftakerDemandInterests({
          id: id,
          search: "",
        })
      );
    }

    setParams(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    dispatch(
      getSingleOfftakerDemandInterests({
        id: id,
        search: searchParams,
      })
    );
  };

  useEffect(() => {
    dispatch(getSingleOfftakerDemandInterests({ id: id, search: "pending" }));
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div>
      {/* searchbar and buttons */}
      <div className="flex justify-between">
        <div className="flex gap-2">
          <form onSubmit={(e) => handleSearchSubmit(e)} className="flex gap-2">
            <div className="w-[250px] lg:w-[538px]">
              <Search
                placeholder="Search demands"
                onChange={(e) => handleSearch(e)}
              />
            </div>
            <Button type="submit" color="primary">
              <SearchIcon color="#ffff" opacity="1" />
            </Button>
          </form>
        </div>
        <div className="flex items-center justify-between gap-[11px]">
          <Button variant="contained" color="white" endIcon={<SlidersIcon />}>
            Filter
          </Button>
          <Dropdown />
        </div>
      </div>
      {/* main */}

      <div className="grid grid-cols-3 gap-[30px] mt-5">
        {loading ? (
          <>
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <Skeleton key={index} />
              ))}
          </>
        ) : (
          <>
            {!data?.length > 0 ? (
              <div className="col-span-3">
                <TableEmptyState text="investment" />
              </div>
            ) : (
              data.map((item) => <Demand demand={item} key={item.id} />)
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OfftakerAuctions;
