/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Box } from '@mui/material';
import { Button } from '@ui';
import Success from 'assets/images/success.svg';

import { Link } from 'react-router-dom';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 739,
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: 3
};

const Complete = ({
	openSubmitModal,
	setOpenSubmitModal,
	message = 'Your proposal has been submitted successful. You will be notified if selected.',
	isSeeking
}) => {
	return (
		<Modal
			open={openSubmitModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<div className="text-center mb-4 py-10 px-16">
					<div className="grid justify-items-center">
						<img src={Success} />
					</div>
					<h1 className="text-[22px] text-head my-4">Submission successful</h1>
					<p className="font-light text-vendorsecondary">{message}</p>

					<div className="flex items-center justify-center mt-8">
						<Link
							to={
								isSeeking
									? '/miner/investors/fundings'
									: '/miner/investors/offerings'
							}
						>
							<Button onClick={() => setOpenSubmitModal(false)}>
								{isSeeking ? 'Go to fundings' : 'Go to offerings'}
							</Button>
						</Link>
					</div>
				</div>
			</Box>
		</Modal>
	);
};
export default Complete;
