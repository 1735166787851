//import Button from "components/ui/Button";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import { Link } from "react-router-dom";
import { getOffering } from "store/slices/vendors/offerings-slice";

function OfferingsDetails() {
  const params = useParams();
  const { id } = params;
  const { offering } = useSelector((store) => store.vendorOfferings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOffering(id));
  }, []);

  return (
    <div className="bg-white p-6 rounded-sm">
      {/* <pre>{JSON.stringify(vendor, null, 2)}</pre> */}

      {offering && (
        <div>
          <div>
            <div className="flex justify-between">
              <h1 className="font-[22px] text-[#192434]">Offering details</h1>
              {/* <div>
                <Button>
                  {" "}
                  <Link
                    to={`/vendor/vendors/find_a_vendor/contactvendor/${offering.id}`}
                  >
                    Manage Interests
                  </Link>
                </Button>
              </div> */}
            </div>
            {/* <div className="flex my-5">
              <Rating
                sx={{
                  color: "#F2C94C",
                }}
                name="read-only"
                value={value}
                readOnly
              />
              <h3 className="text-[14px] text-vendor font-light">
                5.0/5.0 (200)
              </h3>
            </div> */}
          </div>
          <div className="mt-3 mb-7">
            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Job title
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">
                {offering.job_title}
              </h4>
            </div>
            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Offering type:
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">
                {offering.type}
              </h4>
            </div>
            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Category:
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">
                {offering.category}
              </h4>
            </div>
            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Description:
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">
                {offering.description}
              </h4>
            </div>

            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Price:
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">
                {offering.price} USD
              </h4>
            </div>
            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Locations:
              </h4>
              <div className="my-3 flex flex-wrap text-[#6166B6] font-light text-[14px] col-start-2 col-end-4">
                {offering.location.map((location, index) => (
                  <div
                    key={index}
                    className="bg-vendoroptionbg flex justify-between px-2 mr-2 mt-2"
                  >
                    <span className="mr-2">{location.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OfferingsDetails;
