/** @format */

import React from "react";
import Button from "components/ui/Button";
import success from "assets/images/success.svg";
import { useNavigate } from "react-router-dom";

const Success = () => {
    const navigate = useNavigate();
    return (

        <div className="text-center mb-4">
            <div className="grid justify-items-center">
                <img src={success} />
            </div>
            <h1 className="text-xl mb-4">Payment successful</h1>
            <div className="grid justify-items-center mt-8">
                <Button
                    onClick={() => {
                        navigate("/miner/interactions/transactions");
                    }}
                >
                    Go to transactions
                </Button>
            </div>
        </div>


    );
};

export default Success;
