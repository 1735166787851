import { configureStore } from "@reduxjs/toolkit";
import signup from "./slices/onboarding/signup.slice";
import otp from "./slices/otp.slice";
import profile from "./slices/onboarding/profile.slice";
import file from "./slices/file.slice";
import miner_verification from "./slices/onboarding/miner.slice";
import offtaker_verification from "./slices/onboarding/offtaker.slice";
import vendor_verification from "./slices/onboarding/vendor.slice";

import onboard from "./slices/onboard-slice";
import user from "./slices/user-slice";
import mines from "./slices/miners/mines-slice";
import miner_vendors from "./slices/miners/vendor-slice";
import inventories from "./slices/miners/inventory-slice";
import analytics from "./slices/miners/analytics-slice";
import auctions from "./slices/miners/auctions-slice";
import investors from "./slices/miners/investor-slice";
import miner_demands from "./slices/miners/demands-slice";

import vendor from "./slices/vendors/vendor-slice";
import vendorInteractions from "./slices/vendors/interactions-slice";
import vendorServiceRequests from "./slices/vendors/service_request-slice";
import vendorOfferings from "./slices/vendors/offerings-slice";
import vendorAnalytics from "./slices/vendors/analytics-slice";

import offtakers from "./slices/offtaker-slice";
import offtaker_analytics from "./slices/offtakers/analytics-slice";
import settings from "./slices/settings-slice";
import offtaker_demands from "./slices/offtakers/demands-slice";

// import shipment from "./slices/interaction-slice";
import admin from "./slices/admin-slice";
import shipment from "./slices/miners/interaction-slice";
import tracking from "./slices/tracking-slice";
import interactions from "./slices/miners/transaction-slice";
import roles_permissions from "./slices/roles-permissions-slice";

import wallet from "./slices/wallet-slice";

const store = configureStore({
  reducer: {
    signup,
    otp,
    profile,
    file,
    miner_verification,
    offtaker_verification,
    vendor_verification,
    onboard,
    user,
    analytics,
    mines,
    inventories,
    investors,
    auctions,
    offtakers,
    settings,
    offtaker_analytics,
    miner_vendors,
    vendor,
    vendorInteractions,
    roles_permissions,
    vendorServiceRequests,
    vendorOfferings,
    interactions,
    shipment,
    tracking,
    wallet,
    admin,
    miner_demands,
    offtaker_demands,
    vendorAnalytics,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});

export default store;
