import { CardElement } from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			color: '#32325d',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			border: '1px solid red',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4'
			}
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a'
		}
	}
};

const Payment = ({investment_rate}) => {
	return (
		<div className=" mx-auto">
			<h2 className="text-heading text-2xl mb-2">Payment</h2>
			<p className="text-ts font-light mt-2 mb-5">
				You have decided to proceed with paying the listing fee charge of
				<span className="text-heading text-base ml-2">${investment_rate}</span>. You will be
				redirected to stripe to pay.{' '}
			</p>
			{/* <p className="text-ts font-extralight mt-2 mb-5">
				Test card details{' '}
				<span className="text-heading text-base font-normal">
					(4242 4242 4242 4242) 12/32 321 45678
				</span>
			</p> */}
			<label>
				Card details
				<CardElement options={CARD_ELEMENT_OPTIONS} />
			</label>
		</div>
	);
};

export default Payment;
