const MoneyWithdrawIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4L14 7H13V9H11V7H10L12 4Z"
        fill="#1C2E45"
        fillOpacity="0.6"
      />
      <path
        d="M19 11V19H5V11H19ZM20 10H4V20H20V10Z"
        fill="#1C2E45"
        fillOpacity="0.6"
      />
      <path
        d="M12 12C13.657 12 15 13.343 15 15C15 16.657 13.657 18 12 18H17V17H18V13H17V12H12Z"
        fill="#1C2E45"
        fillOpacity="0.6"
      />
      <path
        d="M9 15C9 13.343 10.343 12 12 12H7V13H6V17H7V18H12C10.343 18 9 16.657 9 15Z"
        fill="#1C2E45"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default MoneyWithdrawIcon;
