import GoogleMapReact from "google-map-react";
import { LocationIcon, DiamondIcon } from "@ui/icons";
import { getBox } from "shared/utility";
import { useState } from "react";
// import Marker from "./marker"

const LocationPin = ({ lat, lng, type, name, products }) => {
  const pole = getBox(lat, lng);
  const [showCard, setShowCard] = useState(false);
  return (
    <div>
      <button
        onMouseOver={() => setShowCard(true)}
        onMouseOut={() => setShowCard(false)}
      >
        <LocationIcon />
      </button>
      {showCard && (
        <div className="bg-white w-80 min-h-8 shadow-lg rounded-lg">
          <h1 className="border-b border-b-gray-light p-3 w-full text-xl leading-none font-normal flex place-items-center">
            <DiamondIcon color="#000" w="40" h="40" /> {name} Mine
          </h1>
          <div className="flex flex-col space-y-3 p-3 text-xs font-medium">
            <div>
              <span className="text-gray">Mine type: </span>{" "}
              <span className="capitalize">{type}</span>
            </div>
            <div>
              <span className="text-gray">Location: </span>{" "}
              {pole.north.toFixed(2)}&deg;N {pole.west.toFixed(2)}&deg;W
            </div>
            <div>
              <span className="text-gray">Products: </span>{" "}
              {products.toString()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Map = ({ mines, location: default_location, zoomLevel }) => {
  return (
    <div className="w-full h-full relative">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDA4S3ph7PmMHFQ6mFjN05OWr2t7_i2k18",
          libraries: ["places"],
        }}
        center={default_location}
        defaultZoom={zoomLevel}
        yesIWantToUseGoogleMapApiInternals
        // onGoogleApiLoaded={({ map, maps }) => console.log(map)}
        // onClick={longitude_latitude_search}
      >
        {mines?.map((mine) => {
          return (
            <LocationPin
              key={mine?.id}
              name={mine?.name}
              type={mine?.type}
              lat={mine?.location.latitude}
              lng={mine?.location.longitude}
              products={mine?.mineral_type.map((x) => ` ${x?.name}`)}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
