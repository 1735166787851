import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { usePath } from "@hooks";
import { useLocation } from "react-router-dom";

const Breadcrumb = () => {
  const { pathnames } = usePath();
  let { pathname } = useLocation();

  pathname = pathname.slice(1).split("/");

  const userRole = pathname[0];

  const isLast = (index) => {
    return index === pathnames.length - 1;
  };

  const path = (name) => {
    const pathname = name === userRole ? "dashboard" : name;
    return pathname;
  };

  return (
    <div className="overflow-x-auto scrollbar-hide" >
      <nav className=" rounded-md w-full py-4 px-4 lg:px-8 min-w-[900px] lg:w-full overflow-x-hidden">
        <ol className="list-reset flex text-base font-light">
          <li>
            <Link to={`/${userRole}/dashboard`} className="text-link text-primary">
              Home
            </Link>
          </li>
          {pathnames.map((name, index) => {
            const activeLink = isLast(index);
            return (
              <Fragment key={name}>
                <li>
                  <span className="text-[#8C8C8C] mx-2">/</span>
                </li>
                {activeLink ? (
                  <li className="text-[#18273AF0] capitalize">{name}</li>
                ) : (
                  <li>
                    <Link
                      to={`/${userRole}/${path(name)}`}
                      className="text-link capitalize text-primary"
                    >
                      {name}
                    </Link>
                  </li>
                )}
              </Fragment>
            );
          })}
        </ol>
      </nav> 
    </div>

  );
};

export default Breadcrumb;
