import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@ui';

const FundingDetails = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { funding } = location.state;
	const fundingDetails = {
		id: funding?._id,
		mineral: funding?.mineral?.name,
		production_phase: funding?.production_phase?.slug,
		investment_amount: funding?.amount,
		investment_type: funding?.investment_type?.slug,
		additional_information: funding?.additional_information
	};

	return (
		<div className="text-default  w-full bg-white px-[34px] p-10">
			<div className="flex justify-between items-center">
				<h3 className="mb-6 text-2xl">Funding details</h3>
				<Button
					onClick={() =>
						navigate(`/miner/investors/fundings/manage_interests`, {
							state: {
								id: fundingDetails?.id
							}
						})
					}
					size="small"
				>
					Manage interests
				</Button>
			</div>
			<div className="flex flex-col justify-start items-start flex-grow-0 mt-6  flex-shrink-0">
				<div className="flex flex-row justify-between mb-[15px]">
					<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
						Mineral:
					</p>
					<p className="text-base font-light text-left text-[#18273a]/[0.94]">
						{fundingDetails?.mineral}
					</p>
				</div>
				<div className="flex flex-row justify-between mb-[15px]">
					<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
						Production phase:
					</p>
					<p className="text-base font-light text-left text-[#18273a]/[0.94]">
						{fundingDetails?.production_phase}
					</p>
				</div>
				<div className="flex flex-row justify-between mb-[15px]">
					<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
						Investment type:
					</p>
					<p className="text-base font-light text-left text-[#18273a]/[0.94]">
						{fundingDetails?.investment_type}
					</p>
				</div>
				<div className="flex flex-row justify-between mb-[15px]">
					<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
						Investment amount:
					</p>
					<p className="text-base font-light text-left text-[#18273a]/[0.94]">
						{fundingDetails?.investment_amount} USD
					</p>
				</div>

				<div className="flex justify-between flex-grow-0 flex-shrink-0 w-full">
					<p className="w-[174px] text-base font-light text-left text-[#1b2b41]/[0.72]">
						Additional <br />
						information:
					</p>
					<p className="flex-1 text-base font-light text-left text-[#18273a]/[0.94]">
						{fundingDetails?.additional_information}
					</p>
				</div>
			</div>
		</div>
	);
};

export default FundingDetails;
