import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InteractionsStats from "@ui/InterationsStats";
import { useDispatch, useSelector } from "react-redux";
import Table from "components/Table";
import { MinerInvestmentTableHeaders } from "constants/index";
import {
  fetchPaginatedInvestments,
  setInvestmentsSearchValue,
  getInvestments
} from "@slices/miners/investor-slice";

const Investments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewDetails = (record) => {
    navigate(`/miner/interactions/investments/details`, {
      state: {
        id: record._id,
      },
    });
  };
  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedInvestments({
        page: pagination[option + "Page"],
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedInvestments({
        page: 1,
        per_page: e.target.value,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(setInvestmentsSearchValue(e.target.value));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(getInvestments());
    }
    dispatch(setInvestmentsSearchValue(search));
    dispatch(
      fetchPaginatedInvestments({
        page: 1,
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    if (tab == "All") {
      if (search == "") {
        dispatch(getInvestments());
      } else {
        dispatch(
          fetchPaginatedInvestments({
            page: 1,
            per_page: pagination.limit,
            search: search,
            filter: "",
          })
        );
      }
    } else {
      dispatch(
        fetchPaginatedInvestments({
          page: 1,
          per_page: pagination.limit,
          search: search,
          filter: tab.toLowerCase(),
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getInvestments());
  }, [dispatch]);

  const {
    loading,
    investments: { investments, counts, pagination, search, filter },
  } = useSelector((state) => state.investors);


  return (
    <div>
      <div className="grid grid-cols-3 lg:grid gap-2 sm:flex overflow-x-auto scrollbar-hide">
        <InteractionsStats counts={counts} type="miner-investment" />
      </div>
      {/* tabs */}
      <div className="mt-4 sm:flex overflow-x-auto scrollbar-hide">
        <Table
          tableInfo={MinerInvestmentTableHeaders}
          isViewDetails={true}
          isStatus={true}
          handleViewDetails={handleViewDetails}
          navigate={navigate}
          data={investments}
          pagination={pagination}
          handleSetPerPage={handleSetPerPage}
          handleSearchSubmit={handleSearchSubmit}
          handleChangePage={handleChangePage}
          handleTabChange={handleTabChange}
          loading={loading}
          handleSearch={handleSearch}
          fetchDefaultData={getInvestments}
          search={search}
        />
      </div>
    </div>
  );
};

export default Investments;
