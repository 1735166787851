/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Box } from '@mui/material';
import { Button } from '@ui';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 582,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3
};

const Complete = ({
    openSubmitModal,
    setOpenSubmitModal,
    onSubmit
}) => {

    return (
        <Modal
            open={openSubmitModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="text-center mb-4 py-10 px-16">

                    <h1 className="text-[22px] text-head my-4">Proceed to supply?</h1>
                    <p className="font-light text-vendorsecondary">By clicking “Agree and confirm” you agree to be charged a flat fee if
                        assigned the winner, and the payment will be due before progressing to transaction.</p>

                    <div className="flex items-center gap-x-6 justify-center mt-8">

                        <Button onClick={() => onSubmit()}>
                            Agree and confirm
                        </Button>
                        <Button onClick={() => setOpenSubmitModal(false)} color="neutral">
                            Cancel
                        </Button>

                    </div>
                </div>
            </Box>
        </Modal>
    );
};
export default Complete;
