import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as API from "@config/url";
import { toast } from "react-toastify";



// fetch roles
const getRoles = createAsyncThunk("onbaord/getRoles", async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
        method: "get",
        url: API.getRoles,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "bearer "+ token
        },
    };
    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data);
    }
});



// Fetch offtaker verification data
const getOffTakerVerificationData = createAsyncThunk("onboard/getOffTakerVerificationData", async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
        method: "get",
        url: API.getOffTakerVerificationData,
        headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
        },
    };
    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data);
    }
});

// Post offtaker verification data
const postOffTakerVefiricationData = createAsyncThunk("onboard/postOffTakerVefiricationData", async (data, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
        method: "post",
        url: API.postOffTakerVefiricationData,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "bearer "+ token,
        },
        data: data,
    };
    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data);
    }
});

// Fetch vendor verification data
const getVendorVerificationData = createAsyncThunk("onboard/getVendorVerificationData", async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
        method: "get",
        url: API.getVendorVerificationData,
        headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
        },
    };
    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data);
    }
});

// Post vendor verification data
const postVendorVerificationData = createAsyncThunk("onboard/postVendorVerificationData", async (data, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
        method: "post",
        url: API.postVendorVerificationData,
        headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
        },
        data: data,
    };
    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data);
    }
});

// get products
const getProducts = createAsyncThunk("onboard/getProducts", async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
        method: "get",
        url: API.getProducts,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "bearer "+ token,
        },
    };
    const response = await axios(config);
    return response.data;
});

//get countries
const getCountries = createAsyncThunk("onboard/getCountries", async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
        method: "get",
        url: API.fetchAllCountries,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "bearer "+ token,
        },
    };
    const response = await axios(config);
    return response.data;
});

//get states
const getStates = createAsyncThunk("onboard/getStates", async (country_code, { rejectWithValue }) => {
    const config = {
        method: "post",
        url: API.getStates,
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            country_code: country_code,
        },
    };
    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue(err.response.data);
    }
});

//get cities
const getCities = createAsyncThunk("onboard/getCities", async (data, { rejectWithValue }) => {
    const config = {
        method: "post",
        url: API.getCities,
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            country_code: data.country,
            state_code: data.state_code,
        },
    };
    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue(err.response.data);
    }
});

//change password
const changeUserPassword = createAsyncThunk("onboard/changePassword", async (data, { rejectWithValue }) => {
    try {
        return await axios({
            method: "post",
            url: API.changePassword,
            headers: {
                "Content-Type": "application/json",
            },
            data: { ...data },
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }

});

const uploadFile = createAsyncThunk("onboard/fileUpload", async (formData) => {
    // const { id } = JSON.parse(localStorage.getItem("user"));
    const token = JSON.parse(localStorage.getItem("token"));

    const config = {
        method: "post",
        url: API.postUploadFile,
        headers: {
            "Content-Type": "multipart/form-data",
            "x-access-token": token,
        },
        data: formData,
    };
    const response = await axios(config);
    return response;
});

const slice = createSlice({
    name: "onboard",

    initialState: {
        data: {},
        status: false,
        loading: false,
        error: null,
        message: null,
        countries: [],
        states: [],
        cities: [],
        roles: [],
        mine_types: [],
        industries: [],
        products: [],
        sdg_goals: [],
    },
    reducers: {},
    extraReducers: {
        // get profiles
        [getRoles.pending]: (state) => {
            state.loading = true;
        },
        [getRoles.fulfilled]: (state, { payload }) => {
            state.roles = payload.data.roles;
            state.loading = false;
        },
        [getRoles.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },



        // get offtaker verification data
        [getOffTakerVerificationData.pending]: (state) => {
            state.loading = true;
        },
        [getOffTakerVerificationData.fulfilled]: (state, { payload }) => {
            // console.log("data", payload.data);
            state.mine_types = payload.data.mine_type;
            state.minerals = payload.data.minerals;
            state.countries = payload.data.countries;
            state.sdg_goals = payload.data.sdg_goals;
            state.loading = false;
        },
        [getOffTakerVerificationData.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },

        //store offtaker verification data
        [postOffTakerVefiricationData.pending]: (state) => {
            state.loading = true;
        },
        [postOffTakerVefiricationData.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.loading = false;
        },
        [postOffTakerVefiricationData.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },

        // get vendor verification data
        [getVendorVerificationData.pending]: (state) => {
            state.loading = true;
        },
        [getVendorVerificationData.fulfilled]: (state, { payload }) => {
            // console.log("data", payload.data);
            state.industries = payload.data.industries;
            state.countries = payload.data.countries;
            state.loading = false;
        },
        [getVendorVerificationData.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },

        //store vendor verification data
        [postVendorVerificationData.pending]: (state) => {
            state.loading = true;
        },
        [postVendorVerificationData.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.loading = false;
        },
        [postVendorVerificationData.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },

        //get products
        [getProducts.pending]: (state) => {
            state.loading = true;
        },
        [getProducts.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.loading = false;
        },
        [getProducts.rejected]: (state, { error }) => {
            state.message = error.message;
            state.error = true;
            state.loading = false;
            toast.error(error.message);
        },

        //get countries
        [getCountries.pending]: (state) => {
            state.loading = true;
        },
        [getCountries.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.countries = payload.docs;
        },
        [getCountries.rejected]: (state, { error }) => {
            state.loading = false;
            state.message = error.message;
            state.error = true;
            toast.error(error.message);
        },

        [getStates.pending]: (state) => {
            state.loading = true;
        },
        [getStates.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.states = payload.data.states;
        },
        [getStates.rejected]: (state, { payload }) => {
            // console.log(payload);
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },

        // Get States
        [getCities.pending]: (state) => {
            state.loading = true;
        },
        [getCities.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.cities = payload.data.cities;
        },
        [getCities.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },

        //change password
        [changeUserPassword.pending]: (state) => {
            state.loading = true;
        },
        [changeUserPassword.fulfilled]: (state,{payload}) => {
            state.loading = false;
            toast.success(payload.data.message);
        },
        [changeUserPassword.rejected]: (state, { error }) => {
            state.loading = false;
            state.message = error.message;
            state.error = true;
            toast.error(error.message);
        },
    },
});

export {
    getCountries,
    getProducts,
    changeUserPassword,
    uploadFile,
    getRoles,
    getStates,
    getCities,
    getOffTakerVerificationData,
    postOffTakerVefiricationData,
    getVendorVerificationData,
    postVendorVerificationData,
};
export default slice.reducer;
