/* eslint-disable no-unused-vars */
import React, { createContext, useState } from "react";

export const TermsAndPrivacyContext = createContext();

import { Terms, Privacy } from "@components";
import { CloseIcon } from "@ui/icons";
import { ControlledModal } from "@ui";
import { useEffect } from "react";


export const TermsAndPrivacyProvider = ({ children }) => {
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    // useEffect(() => {
    //     console.log('show Privacy', showPrivacy)
    // }, [showPrivacy])
    return (
        <TermsAndPrivacyContext.Provider value={{ setShowTerms, setShowPrivacy }}>
            {children}
            <ControlledModal
                className="fixed top-[5vh] md:top-[10vh] lg:top-[10vh] left-[2%] right-[2%] md:left-[5%] md:right-[5%] lg:left-[10%] lg:right-[10%]"
                isOpen={showTerms || showPrivacy}
                handleClose={() => setShowTerms(false)}
            >
                <header className="flex justify-between mx-4 mb-2">
                    <h2 className="text-xl font-medium text-gray-900">
                        {showTerms ? 'Terms Of Service' : 'Privacy Policy'}
                    </h2>
                    <CloseIcon close={showTerms ? () => setShowTerms(false) : () => setShowPrivacy(false)} />
                </header>
                <hr className="border-red mb-2" />
                <main className=" mx-4 mb-2 max-h-[70vh] overflow-auto">
                    {showTerms && <Terms />}
                    {showPrivacy && <Privacy />}
                </main>
            </ControlledModal>
        </TermsAndPrivacyContext.Provider>
    );
};
