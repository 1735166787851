/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import "../index.css";
import { useNavigate } from "react-router-dom";
import { Input, TextArea } from "@atoms/input";
import { Select, Chips,Checkbox, Card } from "@ui";
import { UserContext } from "@context/index";
import { Button } from "@atoms/button/index";
import { Map, LongLat, SearchLocation } from "@pages/onboarding";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  submit_miners_verification,
  search_directory,
} from "@slices/onboarding/miner.slice";
import { SuccessUrl } from "@config/app-url";
import { SuccessResponse } from "config/status-code";
import { MinerVerificationFormSchema } from "@validations/schema";

const MineForm = ({
  company_registration_certificate,
  tax_clearance_certificate,
  export_license_certificate,
  mining_license_certificate,
  geological_report_certificate,
  children,
  ...props
}) => {
  const { mine_types, minerals, sdg_goals } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, directory } = useSelector(
    (state) => state.miner_verification
  );
  let plan = "paid";
  const _FormData = new FormData();
  const { form } = useContext(UserContext);
  const [location_type, setLocationType] = useState("");
  const [company_id, setCompanyID] = useState("");
  const [mineralsOptions, setMineralsOptions] = useState(null);
  const [sdgGoalsSelectedOptions, setsdgGoalsSelectedOptions] = useState(null);
  const [address, setAddress] = useState(true);
  const [longitude_latitude, setLongitudeLatitude] = useState(false);
  const [gps, setGps] = useState(false);

  /** Build Uploaded files data */
  let company_registration_files = [];
  for (const file of company_registration_certificate) {
    _FormData.append("company_registration_certificate", file);
    company_registration_files.push(file);
  }

  let tax_clearance_files = [];
  for (const file of tax_clearance_certificate) {
    _FormData.append("tax_clearance_certificate", file);
    tax_clearance_files.push(file);
  }

  let export_license_files = [];
  for (const file of export_license_certificate) {
    _FormData.append("export_license_certificate", file);
    export_license_files.push(file);
  }

  let mining_license_files = [];
  for (const file of mining_license_certificate) {
    _FormData.append("mining_license_certificate", file);
    mining_license_files.push(file);
  }

  let geological_report_files = [];
  for (const file of geological_report_certificate) {
    _FormData.append("geological_report_certificate", file);
    geological_report_files.push(file);
  }

  /** Instantiate validation */
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(MinerVerificationFormSchema),
    mode: "all",
  });

  /** Minerals and SDG Goals selection */
  const handleMultiSelectChange = (values, { name }) => {
    if (name === "minerals_name") {
      setMineralsOptions(values);
      let new_minerals = values.map((item) => item.slug);
      return setValue("minerals", new_minerals, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else if (name === "sdgGoals") {
      setsdgGoalsSelectedOptions(values);
      let new_sdg_goals = values.map((item) => item.slug);
      return setValue("sdg_goals", new_sdg_goals, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const handleChange = (event)=>{
    const { name, value, checked, type } = event.target;
    if(checked === true) {
        plan = "trial";
    }else {
        plan ="paid";
    }
  }

  const setCompany_ID = (data) => {
    setCompanyID(data._id);
    setValue("companyName", data.name);
    dispatch(search_directory({ search: "", type: null }));
  };

  /** Minerals and SDG Goals Removal */
  const removeOption = (selectedItem, id) => {
    let newSelect;
    if (id === "minerals-chips") {
      newSelect = mineralsOptions.filter((item) => item.slug !== selectedItem);
      setValue("minerals", newSelect, {
        shouldValidate: true,
        shouldDirty: true,
      });
      return setMineralsOptions(newSelect);
    } else if (id === "sdgGoals-chips") {
      newSelect = sdgGoalsSelectedOptions.filter(
        (item) => item.slug !== selectedItem
      );
      setValue("sdg_goals", newSelect, {
        shouldValidate: true,
        shouldDirty: true,
      });
      return setsdgGoalsSelectedOptions(newSelect);
    }
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 1) {
      let wordSearch = e.target.value;
      const timer = setTimeout(() => {
        if (wordSearch == e.target.value) {
          if (e.target.value) {
            dispatch(
              search_directory({ search: e.target.value, type: "miner" })
            );
          }
        }
      }, 1500);
      return () => clearTimeout(timer);
    } else {
      if (e.target.value === "") {
        dispatch(search_directory({ search: "", type: null }));
      }
    }
  };

  /** Onchange function of Location Type */
  const onChangeLocationType = (location_type) => {
    setValue("address", "", { shouldValidate: true, shouldDirty: true });
    setValue("postal", "", { shouldValidate: true, shouldDirty: true });
    setValue("state", "", { shouldValidate: true, shouldDirty: true });
    setValue("city", "", { shouldValidate: true, shouldDirty: true });
    setValue("long", "", { shouldValidate: true, shouldDirty: true });
    setValue("lat", "", { shouldValidate: true, shouldDirty: true });
    setValue("country", "", { shouldValidate: true, shouldDirty: true });
    return setLocationType(location_type);
  };

  /** Update on Location type */
  useEffect(() => {
    /** switch location type */
    switch (location_type) {
      case "address":
        setAddress(true);
        setLongitudeLatitude(false);
        setGps(false);
        break;
      case "longitude_latitude":
        setLongitudeLatitude(true);
        setAddress(false);
        setGps(false);
        break;
      case "gps":
        setGps(true);
        setAddress(false);
        setLongitudeLatitude(false);
        break;
      default:
        break;
    }
  }, [location_type]);

  /** Submit Form */
 
  const onSubmit = (data) => {
    const { _id: profile_id } = JSON.parse(
      localStorage.getItem("profile_to_verify")
    );
    const formRequest = {

      profile_id,
      mine_name: data.mineName,
      mine_type: data.mineType,
      description: data.description,
      reserves: data.reserves,
      revenue: data.revenue,
      location: {
        long: data.long,
        lat: data.lat,
        address: data.address,
        postal_code: data.postal,
      },
      directory_id: company_id,
      country: data.country,
      state: data.state,
      city: data.city,
      minerals: data.minerals,
      company_name: data.companyName,
      sdg_goals: data.sdg_goals,
      plan:plan
    };

   
    _FormData.append("data",JSON.stringify(formRequest));
   
    dispatch(submit_miners_verification({formdata:_FormData,id:profile_id}))
      .then((res) => {
        if (res.payload.status && SuccessResponse(res.payload.status)) {
          if(res.payload.data.code == 400 || res.payload.data.code == 422) {
            toast.error(res.payload.data.message);
          }else {
            toast.success(res.payload.data.message);
            navigate(SuccessUrl);
            plan = "paid";
          }
         
        }
        throw "error submitting form";
      })
      .catch((err) => {
        return toast.error(err.message);
      });
  };

  return (
    <div>
      {form && (
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          {/** Mine name */}
          <label className="block text-xs mb-2 text-lightgray">
            {" "}
            Mine name{" "}
          </label>
          <Input type="text" name="mineName" {...register("mineName")} />
          {errors.mineName?.message && (
            <p className="paragraph text-red text-left text-xs mt-1">
              {" "}
              * {errors.mineName?.message}{" "}
            </p>
          )}

          {/** Description */}
          <div className="mt-2">
            <label className="block text-xs mb-2  text-lightgray">
              {" "}
              Description{" "}
            </label>
            <TextArea
              placeholder="Bingham Canyon Mine is an open-pit mining operation extracting a large porphyry copper deposit southwest of Salt Lake City, Utah, in the Oquirrh Mountains."
              name="description"
              type="textarea"
              {...register("description")}
            />
            {errors.description?.message && (
              <p className="paragraph text-red text-xs mt-1">
                {" "}
                * {errors.description?.message}{" "}
              </p>
            )}
          </div>

          {/** Mine Type && Reserves */}
          <div className="flex flex-wrap -mx-3 mt-2">
            {/** Mine Type */}
            <div className=" media px-3">
              <label
                className="block text-xs mb-2  text-lightgray"
                htmlFor="mineType"
              >
                {" "}
                Mine type{" "}
              </label>
              <select
                className="bg-fill border-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded cursor-text text-input py-2 px-4 text-sm"
                id="mineType"
                name="mineType"
                {...register("mineType")}
                type="dropdown"
              >
                <option disabled selected value="">
                  Select a mine type
                </option>
                {mine_types?.length > 0 &&
                  mine_types.map((mine_type, index) => (
                    <option key={index} value={mine_type.slug}>
                      {" "}
                      {mine_type.name}{" "}
                    </option>
                  ))}
              </select>
              {errors.mineType?.message && (
                <p className="paragraph text-red text-xs mt-1">
                  {" "}
                  * {errors.mineType?.message}{" "}
                </p>
              )}
            </div>
            {/** Reserves */}
            <div className="media px-3">
              <label
                className="block text-xs mb-2  text-lightgray"
                htmlFor="reserves"
              >
                {" "}
                Reserves (in dry metric tonnes){" "}
              </label>
              <div className="flex flex-row relative">
                <Input
                  id="reserves"
                  type="number"
                  name="reserves"
                  {...register("reserves")}
                />
                <span className="absolute right-0 border-none appearance-none rounded-r cursor-text text-input py-2 px-2 text-left text-sm">
                  {" "}
                  dmt{" "}
                </span>
              </div>
              {errors.reserves?.message && (
                <p className="paragraph text-red text-xs mt-1">
                  {" "}
                  * {errors.reserves?.message}{" "}
                </p>
              )}
            </div>
          </div>

          {/** Location Type */}
          <div className="w-full mt-2">
            <label
              className="block text-xs mb-2  text-lightgray"
              htmlFor="address"
            >
              {" "}
              Location type{" "}
            </label>
            <select
              className="bg-fill cursor-pointer border-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded text-input py-2 px-4 text-sm"
              id="address"
              type="dropdown"
              onChange={(e) => onChangeLocationType(e.target.value)}
            >
              <option
                className="bg-fill cursor-pointer border-none"
                value="address"
                name="type"
              >
                {" "}
                Address{" "}
              </option>
              <option value="longitude_latitude" name="type">
                {" "}
                Longitude and Latitude{" "}
              </option>
              <option value="gps" name="type">
                {" "}
                GPS{" "}
              </option>
            </select>
          </div>

          {gps && (
            <div className="w-full mt-3">
              <div className="h-96 my-4 ">
                <Map
                  location={{
                    address:
                      "1600 Amphitheatre Parkway, Mountain View, california.",
                    lat: 37.42216,
                    lng: -122.08427,
                  }}
                  zoomLevel={17}
                  setValue={setValue}
                />
              </div>
            </div>
          )}

          {longitude_latitude && (
            <div className="flex flex-wrap -mx-2">
              <LongLat setValue={setValue} errors={errors} />
            </div>
          )}

          {/* Address */}
          <div className="w-full mt-3">
            <label
              className="block text-xs mb-2  text-lightgray"
              htmlFor="address"
            >
              {" "}
              Address{" "}
            </label>
            {address ? (
              <SearchLocation setValue={setValue} />
            ) : (
              <Input type="text" name="address" {...register("address")} />
            )}
            {errors.address?.message && (
              <p className="paragraph text-red text-xs mt-1">
                {" "}
                * {errors.address?.message}{" "}
              </p>
            )}
          </div>

          {/* Longitude && Latitude display this if longitude and latitude is not selected */}
          {!longitude_latitude && (
            <div className="flex flex-wrap -mx-2">
              {/** Longitude */}
              <div className=" media mt-4 px-3">
                <label
                  className="block text-xs mb-2 text-lightgray"
                  htmlFor="longitude"
                >
                  {" "}
                  Longitude{" "}
                </label>
                <Input type="text" name="long" {...register("long")} readOnly />
                {errors.long?.message && (
                  <p className="paragraph text-red text-xs mt-1">
                    {" "}
                    * {errors.long?.message}{" "}
                  </p>
                )}
              </div>
              {/** Latitude */}
              <div className=" media mt-4 px-3">
                <label
                  className="block text-xs mb-2 text-lightgray"
                  htmlFor="latitude"
                >
                  {" "}
                  Latitude{" "}
                </label>
                <Input type="text" name="lat" {...register("lat")} readOnly />
                {errors.lat?.message && (
                  <p className="paragraph text-red text-xs mt-1">
                    {" "}
                    * {errors.lat?.message}{" "}
                  </p>
                )}
              </div>
            </div>
          )}

          {/** Country && Postal code */}
          <div className="flex flex-wrap -mx-3 my-3">
            {/** Country */}
            <div className="media px-3">
              <label
                className="block text-xs mb-2  text-lightgray"
                htmlFor="country"
              >
                {" "}
                Country{" "}
              </label>
              <Input
                id="country"
                type="text"
                placeholder=""
                name="country"
                {...register("country")}
                readOnly
              />
              {errors.country?.message && (
                <p className="paragraph text-red text-xs mt-1">
                  {" "}
                  * {errors.country?.message}{" "}
                </p>
              )}
            </div>
            {/** Postal code */}
            <div className="media px-3">
              <label
                className="block text-xs mb-2  text-lightgray"
                htmlFor="postal"
              >
                {" "}
                Postal code{" "}
              </label>
              <Input
                id="postal"
                type="text"
                name="postal"
                {...register("postal")}
              />
              {errors.postal?.message && (
                <p className="paragraph text-red text-xs mt-1">
                  {" "}
                  * {errors.postal?.message}{" "}
                </p>
              )}
            </div>
          </div>

          {/** State && City */}
          <div className="flex flex-wrap -mx-2">
            {/** State */}
            <div className=" media px-3 mt-2">
              <label
                className="block text-xs mb-2 text-lightgray"
                htmlFor="state"
              >
                {" "}
                State{" "}
              </label>
              <Input
                id="state"
                type="text"
                placeholder=""
                name="state"
                {...register("state")}
              />
              {errors.state?.message && (
                <p className="paragraph text-red text-xs mt-1">
                  {" "}
                  * {errors.state?.message}{" "}
                </p>
              )}
            </div>
            {/** City */}
            <div className=" media px-3 mt-2 ">
              <label
                className="block text-xs mb-2 text-lightgray"
                htmlFor="city"
              >
                {" "}
                City{" "}
              </label>
              <Input
                id="city"
                type="text"
                placeholder=""
                name="city"
                {...register("city")}
              />
              {errors.city?.message && (
                <p className="paragraph text-red text-xs mt-1">
                  {" "}
                  * {errors.city?.message}{" "}
                </p>
              )}
            </div>
          </div>

          {/** Minerals */}
          <div className="w-full mt-2">
            <Select
              control={control}
              id="minerals-chips"
              label="Minerals, Metals and Gemstone"
              name="minerals_name"
              placeholder="Select minerals"
              isMulti
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.slug}
              value={mineralsOptions}
              options={minerals || []}
              onChange={handleMultiSelectChange}
            />
            <div className="flex gap-2 flex-wrap">
              {" "}
              <Chips
                chips={mineralsOptions}
                onClick={removeOption}
                id="minerals-chips"
              />{" "}
            </div>
            {errors.minerals?.message && (
              <p className="paragraph text-red text-xs mt-1">
                {" "}
                * {errors.minerals?.message}{" "}
              </p>
            )}
          </div>

          {/**  Company name */}
          <div className="mt-2">
            <label className="block text-xs mb-2  text-lightgray">
              {" "}
              Company name{" "}
            </label>
            <Input
              type="text"
              placeholder="Rio Tinto Group"
              name="companyName"
              {...register("companyName")}
              onChange={(e) => handleSearch(e)}
            />
            {directory?.length > 0 && (
              <Card className="shadow-md">
                <div className="h-48 align-centre rounded-lg overflow-scroll cursor-pointer">
                  {directory.map((data, index) => (
                    <p
                      className="h-10 text-sm text-light font-light mb-2 box-company"
                      onClick={() => {
                        setCompany_ID(data);
                      }}
                      key={index + 1}
                    >
                      {" "}
                      {data?.name} - {data?.country}
                    </p>
                  ))}
                </div>
              </Card>
            )}

            {errors.companyName?.message && (
              <p className="paragraph text-red text-xs mt-1">
                {" "}
                * {errors.companyName?.message}{" "}
              </p>
            )}
          </div>

          {/** Revenue */}
          <div className="w-full mt-2">
            <label
              className="block text-xs mb-2  text-lightgray"
              htmlFor="address"
            >
              {" "}
              Revenue{" "}
            </label>
            <select
              className="bg-fill cursor-pointer border-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded text-input py-2 px-4 text-sm"
              id="address"
              type="dropdown"
              {...register("revenue")}
            >
              <option
                className="bg-fill cursor-pointer border-none"
                value="below_1000000"
                name="type"
              >
                {" "}
                Below 1,000,000 USD{" "}
              </option>
              <option
                className="bg-fill cursor-pointer border-none"
                value="above_1000000"
                name="type"
              >
                {" "}
                Above 1,000,000 USD{" "}
              </option>
            </select>
            {errors.revenue?.message && (
              <p className="paragraph text-red text-xs mt-1">
                {" "}
                * {errors.revenue?.message}{" "}
              </p>
            )}
          </div>

          {/**  SDG Gaols */}
          <div className="w-full mt-2 mb-3">
            <Select
              control={control}
              id="sdgGoals-chips"
              label="SDG Goal"
              name="sdgGoals"
              placeholder="Select SDG goals"
              isMulti
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.slug}
              value={sdgGoalsSelectedOptions}
              options={sdg_goals || []}
              onChange={handleMultiSelectChange}
            />
            <div className="flex gap-2 flex-wrap">
              {" "}
              <Chips
                chips={sdgGoalsSelectedOptions}
                onClick={removeOption}
                id="sdgGoals-chips"
              />{" "}
            </div>
            {errors.sdg_goals?.message && (
              <p className="paragraph text-red text-xs mt-1">
                {" "}
                * {errors.sdg_goals?.message}{" "}
              </p>
            )}
          </div>

            {/* Trial period */}
            <div className="text-checkbox flex flex-row text-sm">
                <Checkbox  value={plan} name="paid" onChange={handleChange} />
                <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                    Start a free 30-day trial
                </p>
                   
            </div>

          {/**  Submit button */}
          <div className="mt-6 flex justify-end">
            {" "}
            <Button disabled={loading} size="small" type="submit">
              {" "}
              {loading ? "Loading..." : "Submit"}{" "}
            </Button>{" "}
          </div>
        </form>
      )}
    </div>
  );
};
export default MineForm;
