/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { DropDownIcon, Sort } from "@ui/icons";
import { TableEmptyState, TableSkeleton } from "@ui";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { statusColorScheme } from "constants/index";

export default function AdminsTable({
  profiles,
  loading,
  pagination,
  handleSetPerPage,
  handleChangePage,
}) {
  const navigate = useNavigate();
  const [page, setPage] = useState("");
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full max-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-auto  border-gray-200 sm:rounded-lg bg-white">
            {loading && <TableSkeleton />}
            {!loading && profiles?.length > 0 && (
              <>
                <table className="min-w-full divide-y divide-[#1A38601A] border-b border-[#1A38601A]">
                  <thead className="bg-[#EFF0FE] ">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        s/n
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        Business Email
                      </th>
                    
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        <div className="flex items-center">
                          <span> profile</span>
                          {/* <Sort /> */}
                        </div>
                      </th>
                    
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        Requested At
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        Status
                      </th>
                      {/* <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        <div className="flex items-center">
                          <span> time</span>
                          <Sort />
                        </div>
                      </th> */}

                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        <div className="flex items-center">
                          <span></span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white font-light divide-y divide-[#1A38601A]">
                    {profiles?.length > 0 &&
                      profiles?.map((profile, index) => (
                        <tr key={index} className="text-[#18273AF0]">
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {index + 1}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {profile?.user[0]?.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {profile?.user[0]?.email}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {profile?.user[0]?.role[0]?.name}
                          </td>
                        
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {dayjs(profile?.user[0]?.created_at).format("MMMM D, YYYY ,hh:mm a")}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            <span className={`py-1 px-5 inline-flex capitalize text-sm leading-5 font-light rounded bg-[${statusColorScheme[profile?.user[0]?.account_status].bg}] text-[${statusColorScheme[profile?.user[0]?.account_status].text}] `}>
                              {profile?.user[0]?.account_status}
                            </span>
                          </td>
                          {/* <td className="px-6 py-4 whitespace-nowrap text-sm"></td> */}

                          {/* <td>
                    <span onClick={() => alert("hello")}>
                      <UserAccount />
                    </span>
                  </td> */}
                          <td>
                            <span
                              className="underline text-sm text-primary cursor-pointer"
                              onClick={() =>
                                navigate(
                                  "/admin/approval-requests/manage-user",
                                  {
                                    state: {
                                      profile_id: profile._id,
                                      role: profile?.user[0]?.role[0]?.name,
                                    },
                                  }
                                )
                              }
                            >
                              View details
                            </span>
                          </td>
                          {/* <td>
                    <span
                      onClick={() => navigate("/admin/profiles/miners/edit-user")}
                    >
                      <Edit />
                    </span>
                  </td>
                  <td>
                    <Delete />
                  </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="flex justify-end w-full py-4 px-8 text-[13px] uppercase text-[#18273AF0]">
                  <div className="flex justify-between gap-2">
                    <p>Rows per page: {pagination.limit}</p>
                    <FormControl sx={{ height: "30px", border: 0 }}>
                      <Select
                        sx={{ height: "30px", border: 0 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={page}
                        onChange={(e) => handleSetPerPage(e)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="mx-6">
                    1 -{Math.ceil(pagination.total / pagination.limit)} of{" "}
                    {pagination.page}
                  </div>
                  <div className="flex justify-between gap-10">
                    {pagination.prevPage && (
                      <div
                        className="rotate-90 cursor-pointer"
                        onClick={() => handleChangePage("prev")}
                      >
                        <DropDownIcon />
                      </div>
                    )}
                    {pagination.nextPage && (
                      <div
                        className="-rotate-90 cursor-pointer"
                        onClick={() => handleChangePage("prev")}
                      >
                        <DropDownIcon />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            {profiles !== null && !profiles?.length > 0 && !loading && (
              <TableEmptyState text="verification" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
