/* eslint-disable react/prop-types */
const CheckCircleOutline = ({ color, opacity }) => {
  const iconColor = color ? color : "#1C2E45";
  const fillOpacity = opacity ? opacity : "0.6";
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5C15.9 5 19 8.1 19 12C19 15.9 15.9 19 12 19C8.1 19 5 15.9 5 12C5 8.1 8.1 5 12 5ZM12 4C7.6 4 4 7.6 4 12C4 16.4 7.6 20 12 20C16.4 20 20 16.4 20 12C20 7.6 16.4 4 12 4Z"
        fill={iconColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M11.1 15.7L6.90002 11.6L8.30002 10.2L11.1 12.9L16 8L17.4 9.4L11.1 15.7Z"
        fill={iconColor}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export default CheckCircleOutline;
