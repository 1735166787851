/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@config/axios-instance';
import * as API from '@config/url';
import { toast } from 'react-toastify';

const GET = 'get';
// const POST = 'post';

const config = (method, url, data = null) => {
	const token = JSON.parse(localStorage.getItem('token'));

	return {
		method,
		url,
		headers: {
			'Content-Type': 'application/json',
			'x-access-token': token
		},
		data
	};
};

const getInvestmentOffers = createAsyncThunk(
	'miner/investors',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await axios.get(API.fetchInvestmentOffers(payload.page,payload.limit));

			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const getInvestmentOfferDetails = createAsyncThunk(
	'miner/investors/offer',
	async (id, { rejectWithValue }) => {
		try {
			const response = await axios.get(API.getInvestmentOffers + `/${id}`);

			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const getIndicateInterestData = createAsyncThunk(
	'miner/investors/offer/data',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.get(API.getIndicateInterestData);

			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const postOfferInterest = createAsyncThunk(
	'miner/investors/offer/post-interest',
	async (payload , { rejectWithValue }) => {
		try {
			const response = await axios.post(
				API.getInvestmentOffers + `?prospect_id=${payload.id}`,
				payload.data
			);

			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const getSeekFundingData = createAsyncThunk(
	'miner/investors/funding/data',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.get(API.getSeekFundingData);

			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const postSeekFundingData = createAsyncThunk(
	'miner/investors/offer/post-interest',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await axios.post(API.postSeekFundingData, payload.data);

			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const getInvestments = createAsyncThunk(
	'miner/getInvestments',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios(config(GET, API.getMinerInvestments));
			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const fetchPaginatedInvestments = createAsyncThunk(
	'miner/fetchPaginatedInvestments',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				API.paginatedMinerInvestments(
					payload.page,
					payload.per_page,
					payload.search,
					payload.filter
				)
			);

			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const getSingleInvestment = createAsyncThunk(
	'miner/transactions/investments/single',
	// eslint-disable-next-line no-unused-vars
	async (id, { rejectWithValue }) => {
		try {
			const response = await axios(
				config(GET, API.getMinerInvestments + `/${id}`)
			);

			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const getInvestmentPaymentData = createAsyncThunk(
	'miner/transactions/investments/paymentData',
	// eslint-disable-next-line no-unused-vars
	async (id, { rejectWithValue }) => {
		try {
			const response = await axios(
				config(GET, API.getMinerInvestments + `/${id}` + '/create')
			);

			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const postInvestmentPaymentData = createAsyncThunk(
	'miner/transactions/investments/makepayment',
	async ({ id, data }, { rejectWithValue }) => {
		try {
			const response = await axios.post(
				API.getMinerInvestments + `/?investment_id=${id}`,
				data
			);

			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const getFundings = createAsyncThunk(
	'miner/getFundings',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios(config(GET, API.getMinerFundings));
			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const fetchPaginatedFundings = createAsyncThunk(
	'miner/getFundings',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				API.paginatedMinerFundings(
					payload.page,
					payload.per_page,
					payload.search,
					payload.filter
				)
			);

			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const getFundingInterests = createAsyncThunk(
	'miner/funding/interests',
	async ({ id, status }, { rejectWithValue }) => {
		try {
			const response = await axios(
				config(GET, API.getFundingInterests(id) + `&status=${status}`)
			);

			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const getFundingInterestDetails = createAsyncThunk(
	'miner/funding/interest',
	async (id, { rejectWithValue }) => {
		try {
			const response = await axios(
				config(GET, API.getFundingInterestDetails(id))
			);

			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const awardFundingInterest = createAsyncThunk(
	'miner/funding/interest/award',
	async ({ id, data }, { rejectWithValue }) => {
		try {
			const response = await axios.post(API.awardFundingInterest(id), data);

			return response;
		} catch (err) {
			if (!err.response) {
				throw err;
			}
			return rejectWithValue(err.response.data);
		}
	}
);

const investorSlice = createSlice({
	name: 'mines',
	initialState: {
		mineData: {},
		pagination: null,
		loading: false,
		submitting: false,
		error: null,
		mines: null,
		search: '',
		investments: {
			investments: [],
			counts: {},
			pagination: null,
			search: '',
			filter: ''
		},
		investmentDetails: {},
		investmentPaymentDetails: {
			currency: null,
			amount_to_pay: null
		},
		isMakingPayment: false,
		investmentOffers: {
			offers: [],
			pagination: {}
		},
		investmentOfferDetails: {},
		createInterestData: {},
		offerSubmitted: false,
		seekFundingData: {},
		fundings: {
			fundings: [],
			counts: {},
			pagination: null,
			search: '',
			filter: ''
		},
		fundingInterests: {
			interests: [],
			pagination: {}
		},
		fundingInterestDetails: {}
	},
	reducers: {
		setSearchValue: (state, action) => {
			state.search = action.payload;
		},
		setInvestmentsSearchValue: (state, action) => {
			state.investments.search = action.payload;
		},
		setFundingsSearchValue: (state, action) => {
			state.fundings.search = action.payload;
		}
	},
	extraReducers: {
		//Get Investment Offers
		[getInvestmentOffers.pending]: (state) => {
			state.loading = true;
		},
		[getInvestmentOffers.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.investmentOffers.data = payload.data.offerings.docs;
			state.investmentOffers.pagination = payload.data.offerings.pagination;
			// toast.success(payload.data.message);
		},
		[getInvestmentOffers.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = true;
			payload.message
				? toast.error(payload.message)
				: toast.error('An error occurred');
		},

		//Get Investment Offer details
		[getInvestmentOfferDetails.pending]: (state) => {
			state.loading = true;
		},
		[getInvestmentOfferDetails.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.investmentOfferDetails = payload.data.prospect[0];
			// toast.success(payload.data.message);
		},
		[getInvestmentOfferDetails.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = true;
			payload.message
				? toast.error(payload.message)
				: toast.error('An error occurred');
		},

		//Get Interest  data
		[getIndicateInterestData.pending]: (state) => {
			state.loading = true;
		},
		[getIndicateInterestData.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.createInterestData = payload.data;
			// toast.success(payload.data.message);
		},
		[getIndicateInterestData.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = true;
			payload.message
				? toast.error(payload.message)
				: toast.error('An error occurred');
		},

		// Post Offer Interest
		[postOfferInterest.pending]: (state) => {
			state.offerSubmitted = true;
		},
		[postOfferInterest.fulfilled]: (state, { payload }) => {
			state.offerSubmitted = false;
		},
		[postOfferInterest.rejected]: (state, { payload }) => {
			state.offerSubmitted = false;
			state.error = true;
			payload.message
				? toast.error(payload.message)
				: toast.error('An error occurred');
		},

		//Get seek funding  data
		[getSeekFundingData.pending]: (state) => {
			state.loading = true;
		},
		[getSeekFundingData.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.seekFundingData = payload.data;
		},
		[getSeekFundingData.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = true;
			payload.message
				? toast.error(payload.message)
				: toast.error('An error occurred');
		},

		//Post seek funding  data
		[postSeekFundingData.pending]: (state) => {
			state.loading = true;
		},
		[postSeekFundingData.fulfilled]: (state, { payload }) => {
			state.loading = false;
			// toast.success(payload.data.message);
		},
		[postSeekFundingData.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = true;
			payload.message
				? toast.error(payload.message)
				: toast.error('An error occurred');
		},

		// Get investments
		[getInvestments.pending]: (state) => {
			state.loading = true;
		},
		[getInvestments.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.investments.investments = payload.data.investments;
			state.investments.counts = payload.data.counts;
			state.investments.pagination = payload.data.pagination;
			//      toast.success(payload.data.message);
		},
		[getInvestments.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = true;
			toast.error(payload.message);
		},

		// Fetch paginated investments
		// eslint-disable-next-line no-unused-vars
		[fetchPaginatedInvestments.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchPaginatedInvestments.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.investments.investments = payload.data.investments;
			state.investments.counts = payload.data.counts;
			state.investments.pagination = payload.data.pagination;
		},
		[fetchPaginatedInvestments.rejected]: (state) => {
			state.error = true;

			// toast.error(payload.message);
		},

		// Fetch single investments
		// eslint-disable-next-line no-unused-vars
		[getSingleInvestment.pending]: (state, action) => {
			state.loading = true;
		},
		[getSingleInvestment.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.investmentDetails = payload.data.investment;
		},
		[getSingleInvestment.rejected]: (state) => {
			state.error = true;

			// toast.error(payload.message);
		},

		// Get investment payment data
		[getInvestmentPaymentData.fulfilled]: (state, { payload }) => {
			state.investmentPaymentDetails.currency = payload.data.currency;
			state.investmentPaymentDetails.amount_to_pay = payload.data.amount_to_pay;
		},

		// Post investment payment data
		[postInvestmentPaymentData.pending]: (state) => {
			state.isMakingPayment = true;
		},
		[postInvestmentPaymentData.fulfilled]: (state, { payload }) => {
			state.isMakingPayment = false;
			if(payload.data.code == 400 || payload.data.code == 422) {
				toast.error(payload.data.message);
			}else {
				toast.success(payload.data.message);
			}
			
		},
		[postInvestmentPaymentData.rejected]: (state, { payload }) => {
			state.isMakingPayment = false;
			state.error = true;
			toast.error(payload.message);
		},

		// Get fundings
		[getFundings.pending]: (state) => {
			state.loading = true;
		},
		[getFundings.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.fundings.fundings = payload.data.fundings;
			state.fundings.counts = payload.data.count;
			state.fundings.pagination = payload.data.pagination;
			//      toast.success(payload.data.message);
		},
		[getFundings.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = true;
			toast.error(payload.message);
		},

		// Fetch paginated investments
		// eslint-disable-next-line no-unused-vars
		[fetchPaginatedFundings.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchPaginatedFundings.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.fundings.fundings = payload.data.fundings;
			state.fundings.counts = payload.data.count;
			state.fundings.pagination = payload.data.pagination;
		},
		[fetchPaginatedFundings.rejected]: (state) => {
			state.error = true;

			// toast.error(payload.message);
		},

		// Funding Interests
		[getFundingInterests.pending]: (state) => {
			state.loading = true;
			state.fundingInterests = {
				interests: [],
				pagination: {}
			};
		},
		[getFundingInterests.fulfilled]: (state, { payload }) => {
			state.error = false;
			state.loading = false;
			state.status = payload.status;
			state.fundingInterests.interests = payload.data.interests;
			state.fundingInterests.pagination = payload.data.pagination;
			// toast.success(payload.data.message);
		},

		[getFundingInterests.rejected]: (state, { payload }) => {
			state.message = payload.message;
			state.error = true;
			state.loading = false;
			toast.error(payload.message);
		},

		// Get funding interest details
		[getFundingInterestDetails.pending]: (state) => {
			state.loading = true;
			state.fundingInterestDetail = {};
		},
		[getFundingInterestDetails.fulfilled]: (state, { payload }) => {
			state.error = false;
			state.loading = false;
			state.status = payload.status;
			state.fundingInterestDetail = payload.data.interest[0];
			state.awarded = payload.data.awarded;
		},

		[getFundingInterestDetails.rejected]: (state, { payload }) => {
			state.message = payload.message;
			state.error = true;
			state.loading = false;
			toast.error(payload.message);
		},

		// Award Funding
		[awardFundingInterest.pending]: (state) => {
			state.submitting = true;
		},
		[awardFundingInterest.fulfilled]: (state, { payload }) => {
			state.error = false;
			state.submitting = false;
			state.status = payload.status;
		},
		[awardFundingInterest.rejected]: (state, { payload }) => {
			state.message = payload.message;
			state.error = true;
			state.submitting = false;
			toast.error(payload.message);
		}
	}
});

export const {
	setSearchValue,
	setInvestmentsSearchValue,
	setFundingsSearchValue
} = investorSlice.actions;

export {
	getInvestmentOffers,
	getInvestmentOfferDetails,
	getIndicateInterestData,
	postOfferInterest,
	getSeekFundingData,
	postSeekFundingData,
	getInvestments,
	fetchPaginatedInvestments,
	getSingleInvestment,
	getInvestmentPaymentData,
	postInvestmentPaymentData,
	getFundings,
	fetchPaginatedFundings,
	getFundingInterests,
	getFundingInterestDetails,
	awardFundingInterest
};

export default investorSlice.reducer;
