/* eslint-disable react/prop-types */
import { Button, SupportTabs, Search } from "@ui";
import { AddIcon, SearchIcon, DownloadIcon, Sort } from "@ui/icons";
import { tickHeaders } from "@constants";

const SupportAdmin = () => {
  return (
    <div>
      <div className="my-[34.5px] w-full">
        <Button variant="contained" color="primary" startIcon={<AddIcon />}>
          Create New Ticket
        </Button>
      </div>
      {/* tabs */}
      <SupportTabs />
      {/* table */}
      <div className="flex lg:flex flex-rols-1 gap-3">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6">
          <div className="overflow-hidden border border-dark-gray-100 sm:rounded-lg bg-white">
            {/* input field goes here */}
            <div className="flex justify-between p-4">
              <div className="flex gap-2">
                <div className="w-[548px]">
                  <Search placeholder="Search tickets" />
                </div>
                <div className="bg-primary flex items-center justify-center rounded py-1 px-2">
                  <SearchIcon color="#ffff" opacity="1" />
                </div>
              </div>
              <div className="w-[183px] h-[44px] ">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                >
                  Export CSV
                </Button>
              </div>
            </div>
            <table className="min-w-full divide-y divide-[#1A38601A] border-b border-[#1A38601A]">
              <thead className="bg-[#EFF0FE]">
                <tr>
                  {tickHeaders.map((header, index) => (
                    <th
                      key={index + 1}
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-normal text-primary uppercase tracking-wider"
                    >
                      {!header.hasIcon && header.title}
                      {header.hasIcon && (
                        <div className="flex  items-center">
                          <span>{header.title}</span>
                          <Sort />
                        </div>
                      )}
                    </th>
                  ))}
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Delete</span>
                  </th>
                  {/* <th scope="col" className="px-3 py-3">
                    <EclipseDotsIcon />
                  </th> */}
                </tr>
              </thead>
              <tbody className="bg-white font-light divide-y divide-[#1A38601A]"></tbody>
            </table>
            <div className="flex flex-col items-center justify-center h-96">
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M45 16.25C45 18.32 38.285 20 30 20C21.715 20 15 18.32 15 16.25C15 14.18 21.715 12.5 30 12.5C38.285 12.5 45 14.18 45 16.25Z"
                  fill="#1C3454"
                  fillOpacity="0.26"
                />
                <path
                  d="M30 22.5C21.75 22.5 15 20.75 15 18.75V26.25C15 28.25 21.75 30 30 30C38.25 30 45 28.25 45 26.25V18.75C45 20.75 38.25 22.5 30 22.5Z"
                  fill="#1C3454"
                  fillOpacity="0.26"
                />
                <path
                  d="M30 32.5C21.75 32.5 15 30.75 15 28.75V36.25C15 38.25 21.75 40 30 40C38.25 40 45 38.25 45 36.25V28.75C45 30.75 38.25 32.5 30 32.5Z"
                  fill="#1C3454"
                  fillOpacity="0.26"
                />
                <path
                  d="M30 42.5C21.75 42.5 15 40.75 15 38.75V46.25C15 48.25 21.75 50 30 50C38.25 50 45 48.25 45 46.25V38.75C45 40.75 38.25 42.5 30 42.5Z"
                  fill="#1C3454"
                  fillOpacity="0.26"
                />
              </svg>

              <p className="text-heading text-lg mb-2"> No data to display</p>
              <p className="text-light font-extralight">
                Add a new ticket to get started
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SupportAdmin;
