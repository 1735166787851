import { Skeleton, Stack } from "@mui/material";
import { Card } from "@ui";

const SkeletonComponent = () => {
  return (
    <Stack>
      <Card>
        <div className="h-[217px]">
          <Skeleton
            variant="rectangular"
            sx={{ width: "100%", borderRadius: 1 }}
            height={217}
          />
        </div>
        {/* <h3 className="text-2xl text-heading mt-4">{auction.name}</h3>
        <p className="text-sm text-light font-light">{auction.mine?.name}</p> */}
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        {/* card time */}
        <Stack direction="row" sx={{ gap: 1 }}>
          {Array(4)
            .fill(null)
            .map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={50}
                height={50}
              />
            ))}
        </Stack>
        {/* card information */}
        <Stack mb={3}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Stack>
        <Skeleton variant="rectangular" sx={{ width: "100%", height: 36 }} />
      </Card>
    </Stack>
  );
};

export default SkeletonComponent;
