import React from "react";
import Skeleton from "@mui/material/Skeleton";

const TableSkeleton = () => {
  return (
    <>
      <Skeleton
        sx={{ bgcolor: "#EFF0FE" }}
        variant="rectangular"
        height={50}
      />

      {Array(4)
        .fill(null)
        .map((_, index) => (
          <div
            className="grid grid-cols-1 py-3 divide-y divide-[#1A38601A]"
            key={index}
          >
            {/* table contents */}
            <div className="grid grid-cols-6 gap-4 px-4 py-2 mb-2">
              <Skeleton sx={{ bgcolor: "#EFF0FE" }} variant="rectangular" animation="wave" />
              <Skeleton sx={{ bgcolor: "#EFF0FE" }} variant="rectangular" animation="wave" />
              <Skeleton sx={{ bgcolor: "#EFF0FE" }} variant="rectangular" animation="wave" />
              <Skeleton sx={{ bgcolor: "#EFF0FE" }} variant="rectangular" animation="wave" />
              <Skeleton sx={{ bgcolor: "#EFF0FE" }} variant="rectangular" animation="wave" />
              <Skeleton sx={{ bgcolor: "#EFF0FE" }} variant="rectangular" animation="wave" />
            </div>
            <hr />
          </div>
        ))}
    </>
  );
};

export default TableSkeleton;
