/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Input, Select, TextArea, Chips, Button } from "@ui";
import { updateAdminMineral } from "store/slices/admin-slice";
import { SuccessResponse } from "config/status-code";
import { toast } from "react-toastify";
import { checkPermission } from "shared/utility";

const EditMineral = () => {
  const { loading } = useSelector((state) => state.admin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  console.log("LOCATION",location);
  const [data, setData] = useState({
    name: location?.state?.data?.name,
    description: location?.state?.data?.description,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const submitMineral = () => {
    if(data.name == "") {
        toast.error("Name is required")
    }else {

        let formData = {
            name:data.name,
            description:data.description,
            status: location?.state?.data?.status
           };
          
           dispatch(updateAdminMineral({ id: location.state.id, data: formData })).then((response) => {
             if (response.payload.status && SuccessResponse(response.payload.status)) {
               toast.success(response.payload.data.message);
               navigate("/admin/minerals");
             }
           });
    }


  

  };

  return (
    <Card>
      <div className="max-w-[796px] mx-auto">
        <h2 className="text-heading text-2xl my-2">Edit Mineral</h2>
        <div className="my-6">
          <h3 className="my-2 text-lg text-heading">Mineral details</h3>
          <div className="grid lg:grid-cols-2 sm:grid gap-1">
            <Input
              label="Type Mineral Name"
              id="name"
              type="text"
              value={data.name}
              name="name"
              onChange={handleInputChange}
            />
          </div>
          <TextArea
            label="Description"
            name="description"
            value={data.description}
            onChange={handleInputChange}
          />
        </div>
        <div className="flex items-center justify-between mt-8">
        <div>
            <Button
              disabled={checkPermission("Mineral")}
              onClick={() => {
                submitMineral();
              }}
            >
              {loading ? "Processing..." : "Update"}
            </Button>
          </div>
          <div>
          <button
           onClick={() => navigate("../admin/minerals")}
          
            className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
          >
            Cancel
          </button>
          </div>
         
        
        </div>
      </div>
    </Card>
  );
};

export default EditMineral;
