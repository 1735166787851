/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
const Padlock = ({ color, opacity, h, w }) => {
  return (
    <div
      style={{
        padding: "8px 8px",
        borderRadius: "50%",
        backgroundColor: "#1A38601A",
      }}
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 2C7.23579 2 8.25 3.01421 8.25 4.25V6.5H3.75V4.25C3.75 3.01421 4.76421 2 6 2ZM9.75 6.5V4.25C9.75 2.18579 8.06421 0.5 6 0.5C3.93579 0.5 2.25 2.18579 2.25 4.25V6.5H1.5C0.671573 6.5 0 7.17157 0 8V14C0 14.8284 0.671574 15.5 1.5 15.5H10.5C11.3284 15.5 12 14.8284 12 14V8C12 7.17157 11.3284 6.5 10.5 6.5H9.75ZM1.5 8H10.5V14H1.5V8Z"
          fill="#1B2B41"
          fillOpacity="0.72"
        />
      </svg>
    </div>
  );
};

export default Padlock;
