/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";

const Input = forwardRef(function Input(
  {
    type,
    placeholder,
    name,
    id,
    disabled,
    value,
    additionalFunc,
    onChange,
    onKeyDown,
    onKeyUp,
    ...props
  },
  ref
) {
  return (
    <React.Fragment>
      <input
        className="bg-fill border-none focus:outline-none break-all focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded cursor-text text-input text-sm py-3 px-4"
        type={type}
        placeholder={placeholder}
        id={id}
        name={name}
        ref={ref}
        disabled={disabled}
        onChange={(e) => {
          onChange(e);
          additionalFunc && additionalFunc(e);
        }}
        onKeyDown={onKeyDown}
        value={value}
        onKeyUp={onKeyUp}
        {...props}
      />
    </React.Fragment>
  );
});
export default Input;
