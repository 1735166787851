/* eslint-disable react/prop-types */
import { Stepper, Step, StepLabel } from "@mui/material";

import { Check } from "../atoms/icons";

function QontoStepIcon({ active, completed, icon }) {
  return (
    <div>
      {completed ? (
        <div className="border border-primary rounded-full p-[5px] mr-2">
          <Check />
        </div>
      ) : (
        <div
          className={`flex items-center justify-center text-sm font-light text-white ${
            active ? "bg-primary" : "bg-gray"
          }  rounded-full px-2 py-[1.5px] mr-2`}
        >
          {icon}
        </div>
      )}
    </div>
  );
}

const CustomStepper = ({ steps, activeStep }) => {
  return (
    <>
      <div className="hidden lg:flex">
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className="lg:hidden w-screen pr-14">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          orientation="horizontal"
        >
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </>
  );
};

export default CustomStepper;
