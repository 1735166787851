/** @format */

import React, { useEffect, useState } from "react";
import { Search, Button, Dropdown } from "@ui";
import { SlidersIcon, SearchIcon } from "@ui/icons";
import {
  getAllOfftakerAuctions,
  searchAuctions,
} from "store/slices/offtaker-slice";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@components/auctions/Skeleton";
import Auction from "@components/auctions/auction/Auction";
import { OfftakerAuctionsDetailsUrl } from "@config/app-url";

const OfftakerAuctions = () => {
  const dispatch = useDispatch();
  const [searchParams, setParams] = useState();
  const { auctions, loading } = useSelector((state) => state.offtakers);

  const handleSearch = (e) => {
    if (e.target.value == "") {
      dispatch(getAllOfftakerAuctions());
    }

    setParams(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    dispatch(
      searchAuctions({
        search: searchParams,
      })
    );
  };

  useEffect(() => {
    dispatch(getAllOfftakerAuctions());

    // eslint-disable-next-line
  }, [dispatch]);
  return (
    <div>
      {/* searchbar and buttons */}
      <div className="flex justify-between">
        <div className="flex gap-2">
          <form
            onSubmit={(e) => handleSearchSubmit(e)}
            className="flex  gap-2 rounded py-1 px-2"
          >
            <div className="w-[250px] lg:w-[548px]">
              <Search
                placeholder="Search Auctions"
                onChange={(e) => handleSearch(e)}
              />
            </div>
            <Button type="submit" color="primary">
              <SearchIcon color="#ffff" opacity="1" />
            </Button>
          </form>
        </div>
        <div className="flex items-center justify-between gap-[11px]">
          <Button variant="contained" color="white" endIcon={<SlidersIcon />}>
            Filter
          </Button>
          <Dropdown />
        </div>
      </div>
      {/* main */}

      <div className="grid lg:grid-cols-3 sm:grid gap-[30px] mt-5 font-default">
        {loading ? (
          <>
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <Skeleton key={index} />
              ))}
          </>
        ) : (
          auctions.map((auction) => (
            <Auction
              auction={auction}
              key={auction.id}
              url={OfftakerAuctionsDetailsUrl}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default OfftakerAuctions;
