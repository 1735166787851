/* eslint-disable no-unused-vars */
// packages
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./styles.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

// files
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { OnboardiingCreateUserSchema } from "@validations/schema";
import { create_user_account } from "@slices/onboarding/signup.slice";
import { Header } from "@atoms/header";
import { Input } from "@atoms/input";
import { BaseUrl, VerifyPhoneUrl } from "@config/app-url";
import { PhoneInputFormatter } from "@shared/formatter";
import { SuccessResponse } from "config/status-code";
import { signInUser } from "@slices/user-slice";

import { Terms } from "@components";
import { CloseIcon } from "@ui/icons";

import { ControlledModal, Button } from "@ui";

// export
const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phone_input, setPhoneInput] = useState({
    phone_code: "",
    phone_number: "",
    iso_code: "",
  });
  const { loading } = useSelector((state) => state.signup);
  const [local_storage, setLocalStorage] = useState({
    fullname: "",
    email: "",
    notification: false,
    agree: true,
  });

  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  /** Format phone then set to state */
  const getFormattedNumber = (value, data) => {
    const input = PhoneInputFormatter(value, data);
    setPhoneInput({
      ...phone_input,
      phone_code: input.phone_code,
      phone_number: input.phone_number,
      iso_code: input.iso_code,
    });
  };
  /** State for password change */
  const [passValue, setPassValue] = useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = () =>
    setPassValue({ ...passValue, showPassword: !passValue.showPassword });
  /** Use form handler for validation */
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(OnboardiingCreateUserSchema),
    mode: "all",
  });

  /** Store form data in the local storage */
  const onSubmit = (data, e) => {
    e.preventDefault();
    //set modal to true
    handleModalOpen();
    localStorage.setItem("create_account", JSON.stringify(data));
    setLocalStorage(data);
  };

  /** Submit form to backend */
  const submitUserInfo = () => {
    handleModalClose();
    const data = JSON.parse(localStorage.getItem("create_account"));
    dispatch(
      create_user_account({
        name: data.fullname,
        password: data.password,
        email: data.email,
        phone: {
          code: phone_input.phone_code,
          number: phone_input.phone_number,
        },
        // phone_code: phone_input.phone_code,
        // notifications: data.notification,
        // iso_code: phone_input.iso_code,
        // phone_number: phone_input.phone_number,
      })
    ).then((response) => {
      /** change url on success message */
      // dispatch(signInUser(data));

      if (response.payload.status && SuccessResponse(response.payload.status)) {
        localStorage.setItem("auth_user", JSON.stringify(response.payload.data.auth_user));
        navigate(VerifyPhoneUrl, {
          state: phone_input,
          data:data
        });
      }
    });
  };

  /**
   * TODO
   * 1. Refactor code to use offline first approach
   */
  useEffect(() => {
    // setValue
  }, [local_storage]);

  return (
    <div className="font-default">
      <Header />
      <div className="bg-white rounded p-8 mt-[113px] lg:mt-0  lg:bg-none my-4">
        <h2 className="font-bold text-2xl text-head  pt-4 ">
          Create an account
        </h2>

        <p className="text-lightgray pb-4 text-base font-light flex justify-start mb-2 ">
          It takes less than 10 minutes to set up your account
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-4">
            <label className="block text-sm mb-2 text-lightgray">Name</label>
            <Input type="text" name="fullname" {...register("fullname")} />
            {errors.fullname?.message && (
              <p className="paragraph text-red text-xs mt-1">
                {" "}
                * {errors.fullname?.message}
              </p>
            )}
          </div>
          <div className="mt-4">
            <label className="block text-sm mb-2 text-lightgray">
              {" "}
              Business Email Address{" "}
            </label>
            <Input type="text" name="email" {...register("email")} />
            {errors.email?.message && (
              <p className="paragraph text-red text-xs mt-1">
                * {errors.email?.message}
              </p>
            )}
          </div>

          <div className="password_2 block mt-4 relative">
            {/** Phone */}
            <div className="mt-4">
              <label className="block text-sm mb-1 text-lightgray">
                {" "}
                Phone Number{" "}
              </label>
              <PhoneInput
                isValid={(value) => {
                  if (value.length < 7 && value.length > 1) {
                    return "* valid phone number is required";
                  } else if (value.length < 7) {
                    return false;
                  } else {
                    return true;
                  }
                }}
                type="text"
                country="us"
                value=""
                onChange={(value, data) => getFormattedNumber(value, data)}
                inputProps={{ name: "phone", required: true }}
                masks={{ fr: "(...) ..-..-..", at: "(....) ...-...." }}
              />
            </div>
            {/** End Phone */}
            {/* password */}
            <div className="password_2 block mt-8 relative">
              <label className="block text-sm  mb-2 text-lightgray">
                Create Password
              </label>
              <div className="eye_div">
                <Input
                  name="password"
                  type={passValue.showPassword ? "text" : "password"}
                  {...register("password")}
                />
                {errors.password?.message && (
                  <p className="paragraph text-red text-xs mt-1">
                    * {errors.password?.message}
                  </p>
                )}
                <div
                  className="icon_button absolute right-4 top-9"
                  onClick={handleClickShowPassword}
                >
                  {passValue.showPassword ? (
                    <EyeIcon className="h-5 font-extralight cursor-pointer" />
                  ) : (
                    <EyeOffIcon className="h-5 font-extralight cursor-pointer" />
                  )}
                </div>
              </div>
            </div>
            {/* End password */}
          </div>

          {/* <div className="text-checkbox flex flex-col  text-sm my-4">
            <div>
              <input
                className="outline-none appearance-none h-4 w-4 border-none rounded-sm bg-check focus:ring-0 focus:outlinn-0 focus:outline-offset-0 focus:text-primary focus:border-none focus:outline-none checked:bg-primary checked:border-primary hover:text-primary  transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                id="agree"
                name="agree"
                {...register("agree")}
              />
              <label className="text-checkbox " htmlFor="agree">
                I agree to the{" "}
                <span className="text-primary relative cursor-pointer">
                  Terms of Service
                </span>{" "}
                and{" "}
                <span className="text-primary cursor-pointer">
                  Privacy Policy
                </span>
              </label>
            </div>
            {errors.agree?.message && (
              <p className="h-2 flex justify-center align-center mt-1 text-red text-xs">
                <br /> {errors.agree?.message}
              </p>
            )}
          </div> */}
          <div className="text-checkbox flex flex-row text-sm my-4">
            <input
              className="outline-none appearance-none h-4 w-4 border-none rounded-sm bg-check focus:ring-0 focus:outlinn-0 focus:outline-offset-0 focus:text-primary focus:border-none focus:outline-none checked:bg-primary checked:border-primary hover:text-primary  transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="checkbox"
              id="notification"
              name="notification"
              {...register("notification")}
            />
            <label className="text-checkbox" htmlFor="notification">
              I agree to recieve email notifications and announcement from
              Daniola regarding updates and transactions.
            </label>
          </div>
          <Button
            fullWidth
            disabled={!isDirty || !isValid}
            loading={loading}
            type="submit"
          >
            Create account
          </Button>
          <p className="text-lightgray text-sm lg:text-base flex justify-center align-center py-2 cursor-default my-2">
            Already have an account?
            <Link className="text-primary pl-2" to={BaseUrl}>
              Sign in
            </Link>
          </p>
        </form>
        <ControlledModal
          className="fixed top-[5vh] md:top-[10vh] lg:top-[10vh] left-[2%] right-[2%] md:left-[5%] md:right-[5%] lg:left-[10%] lg:right-[10%]"
          isOpen={openModal}
          handleClose={handleModalClose}
        >
          <header className="flex justify-between mx-4 mb-2">
            <h2 className="text-xl font-medium text-gray-900">
              Terms Of Service
            </h2>
            <CloseIcon close={handleModalClose} />
          </header>
          <hr className="border-red mb-2" />
          <main className=" mx-4 mb-2 max-h-[60vh] overflow-auto">
            <Terms />
          </main>
          <hr className="border-red mb-2" />
          <footer className="flex gap-4 mx-4">
            <Button onClick={submitUserInfo}>I agree</Button>
            <Button color="danger" onClick={handleModalClose}>
              I disagree
            </Button>
          </footer>
        </ControlledModal>
      </div>
    </div>
  );
};

export default SignUp;
