const LocationIcon = () => {
  return (
    <svg
      width="18"
      style={{cursor:"pointer"}}
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.88906 23.4C5.44219 20.3906 0 13.0969 0 9C0 4.02938 4.02938 0 9 0C13.9688 0 18 4.02938 18 9C18 13.0969 12.5156 20.3906 10.1109 23.4C9.53438 24.1172 8.46562 24.1172 7.88906 23.4ZM9 12C10.6547 12 12 10.6547 12 9C12 7.34531 10.6547 6 9 6C7.34531 6 6 7.34531 6 9C6 10.6547 7.34531 12 9 12Z"
        fill="#6166B6"
      />
    </svg>
  );
};

export default LocationIcon;
