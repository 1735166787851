/* eslint-disable react/prop-types */
import { useState, useEffect, useContext, useRef } from "react";
import ReactPaginate from "react-paginate";
import { DropDownIcon } from "@ui/icons";
import { PaginationContext } from "../../context/pagination-provider";

const LeftArrow = () => (
  <div className="rotate-90">
    <DropDownIcon />
  </div>
);
const RightArrow = () => (
  <div className="-rotate-90">
    <DropDownIcon />
  </div>
);

export const ItemsPerPage = ({ options = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(options[0]);

  const { setItemsPerPage } = useContext(PaginationContext);

  const dropDownRef = useRef();

  const isSelected = (selected, option) => selected === option;

  useEffect(() => {
    const handleClick = (e) => {
      if (!dropDownRef.current.contains(e.target)) {
        // outside click
        setIsOpen(false);
        return;
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="flex items-center gap-2">
      <span className="text-black text-sm">Product per page: </span>
      <div className="w-[113px]">
        <div className="xl:w-full relative select-none">
          <div
            ref={dropDownRef}
            className="flex justify-between rounded px-4 py-2 bg-white border border-solid border-dark-gray-100 z-0 cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            <p className="font-light text-light">{selected}</p>
            <div className="absolute right-2">
              <DropDownIcon />
            </div>
          </div>
          {isOpen && (
            <div className="options absolute w-full bg-white z-40 mt-1 font-light text-[#18273AF0] rounded-md shadow-[0_4px_24px_-4px_rgba(28,50,79,0.15)]">
              {options.map((option) => (
                <div
                  key={option}
                  className={`flex items-center gap-2 ${
                    isSelected(selected, option) && "bg-primary-light px-2"
                  }  px-6 py-2 rounded cursor-pointer transition-all duration-200 ${
                    !isSelected(selected, option) && "hover:bg-[#f4f4f4]"
                  }`}
                  onClick={() => {
                    setSelected(option);
                    setItemsPerPage(option);
                    setIsOpen(false);
                  }}
                >
                  {isSelected(selected, option) && (
                    <svg
                      width="11"
                      height="9"
                      viewBox="0 0 11 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.28033 4.77494C0.987437 4.48205 0.512563 4.48205 0.21967 4.77494C-0.0732233 5.06784 -0.0732233 5.54271 0.21967 5.8356L2.94202 8.55796C3.23491 8.85085 3.70979 8.85085 4.00268 8.55796L10.447 2.11364C10.7399 1.82075 10.7399 1.34588 10.447 1.05298C10.1541 0.76009 9.67923 0.76009 9.38634 1.05298L3.47235 6.96697L1.28033 4.77494Z"
                        fill="#6166B6"
                      />
                    </svg>
                  )}

                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Pagination = ({ items, ...rest }) => {
  const { setCurrentItems, itemsPerPage } = useContext(PaginationContext);
  const [pageCount, setPageCount] = useState(0);

  // Here I use item offsets; I could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    const slicedItems = items.slice(itemOffset, endOffset);
    setCurrentItems(slicedItems);
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items, setCurrentItems]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <ReactPaginate
      nextLabel={<RightArrow />}
      onPageChange={handlePageClick}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      pageCount={pageCount}
      previousLabel={<LeftArrow />}
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination"
      activeClassName="active-page"
      renderOnZeroPageCount={null}
      {...rest}
    />
  );
};

export default Pagination;
