const PaperClip = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7428 4.2249C14.719 2.20107 11.4233 2.20107 9.40158 4.2249L3.79416 9.82803C3.75764 9.86455 3.7383 9.91396 3.7383 9.96553C3.7383 10.0171 3.75764 10.0665 3.79416 10.103L4.58693 10.8958C4.62317 10.9319 4.67222 10.9521 4.72336 10.9521C4.7745 10.9521 4.82355 10.9319 4.85979 10.8958L10.4672 5.29268C11.1633 4.59658 12.0893 4.21416 13.0733 4.21416C14.0572 4.21416 14.9832 4.59658 15.6772 5.29268C16.3733 5.98877 16.7557 6.91475 16.7557 7.89658C16.7557 8.88057 16.3733 9.80439 15.6772 10.5005L9.96232 16.2132L9.03635 17.1392C8.17053 18.005 6.7633 18.005 5.89748 17.1392C5.47854 16.7202 5.24865 16.1638 5.24865 15.5708C5.24865 14.9778 5.47854 14.4214 5.89748 14.0024L11.5672 8.33486C11.7112 8.19307 11.9002 8.11357 12.1022 8.11357H12.1043C12.3063 8.11357 12.4932 8.19307 12.635 8.33486C12.7789 8.47881 12.8563 8.66787 12.8563 8.86982C12.8563 9.06963 12.7768 9.25869 12.635 9.40049L8.0008 14.0304C7.96428 14.0669 7.94494 14.1163 7.94494 14.1679C7.94494 14.2194 7.96428 14.2688 8.0008 14.3054L8.79357 15.0981C8.82981 15.1342 8.87887 15.1545 8.93 15.1545C8.98114 15.1545 9.03019 15.1342 9.06643 15.0981L13.6985 10.4661C14.126 10.0386 14.3602 9.47139 14.3602 8.86768C14.3602 8.26396 14.1238 7.69463 13.6985 7.26924C12.8154 6.38623 11.3803 6.38838 10.4973 7.26924L9.94729 7.82139L4.82971 12.9368C4.48237 13.2821 4.20704 13.6929 4.01969 14.1455C3.83234 14.598 3.7367 15.0832 3.7383 15.5729C3.7383 16.5677 4.12717 17.5022 4.82971 18.2048C5.55803 18.931 6.51193 19.294 7.46584 19.294C8.41975 19.294 9.37365 18.931 10.0998 18.2048L16.7428 11.5661C17.7203 10.5864 18.2617 9.28232 18.2617 7.89658C18.2639 6.50869 17.7225 5.20459 16.7428 4.2249Z"
        fill="#1C2E45"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default PaperClip;
