/* eslint-disable react/prop-types */
import { createContext, useState } from "react";

export const BannerContext = createContext({
  isBannerOpened: true,
  closeBanner: () => {},
});

const BannerProvider = ({ children }) => {
  const [banner, setBanner] = useState(true);
  const closeBanner = () => {
    setBanner(false);
  };

  const values = {
    isBannerOpened: banner,
    closeBanner,
  };
  return (
    <BannerContext.Provider value={values}>{children}</BannerContext.Provider>
  );
};

export default BannerProvider;
