import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["Gold", "Nickel", "Others"],
  datasets: [
    {
      label: "# of Votes",
      data: [70, 50, 50],
      backgroundColor: ["#9B51E0", "#F2994A", "#2D9CDB"],
      borderColor: ["#9B51E0", "#F2994A", "#2D9CDB"],
      // borderWidth: 1,
    },
  ],
};

export const options = {
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        color: "#849AA9",
        boxWidth: 5,
        boxHeight: 5,
        padding: 20,
        font: {
          family: "Associate Sans",
          weight: 200,
          size: 13,
        },
      },
    },
  },
};

const DoughnutChart = () => <Doughnut data={data} options={options} />;

export default DoughnutChart;
