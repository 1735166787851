/** @format */

import React from "react";

import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import Success from "../../../assets/images/success.svg";
import { useNavigate, Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 528,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

// eslint-disable-next-line react/prop-types
const ConfirmationModal = ({ open }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="text-center mb-4">
          <div className="grid justify-items-center">
            <img src={Success} />
          </div>
          <h1 className="text-xl mb-4">Bid placed successfully</h1>
          <p className="font-light">
            Your bid has been placed,{" "}
            <Link to="/off-taker/interactions/bids">
              <span className="text-black underline">Go to bids</span>
            </Link>{" "}
            to see the status of all your bids.
          </p>
          <div className="grid justify-items-center mt-8">
            <Button
              onClick={() => {
                navigate("/off-taker/auctions");
              }}
            >
              Done
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
