/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useContext } from "react";
import Dot from "@ui/Dot";
import { UserContext } from "@context/index";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MainLink = ({ icon, label, hasDot, setSideBar }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div
        className="flex items-center pl-8 pr-4 py-4 w-full gap-4"
        onClick={() => {
          handleOpen();
          setSideBar(false);
        }}
      >
        <span className="min-w-[24px]">{icon}</span>
        <span className="relative">
          {hasDot && <Dot />}
          {label}
        </span>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Alert!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You're not allowed to perform any action yet until your account has
            been verified.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};


const MenuItem = ({ href, icon, label, hasDot, subList }) => {
  const { setSideBar } = useContext(UserContext);

  return (
    <li className="text-dark-gray-400 font-extralight select-none">
      <MainLink
        href={href}
        icon={icon}
        label={label}
        hasDot={hasDot}
        setSideBar={setSideBar}
      />
    </li>
  );
};

export default MenuItem;
