/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getSingleInvestment,
  postInvestmentPaymentData,
} from "@slices/miners/investor-slice";
import { getProfile,fetchAllNotifications } from "@slices/settings-slice";
import LoadingState from "@components/investments/LoadingState";
import Button from "components/ui/Button";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Payment from "./Payment";
import { getServiceOptions } from "store/slices/miners/vendor-slice";
import { Input } from "@atoms/input";
import { CopyIcon } from "components/atoms/icons/index";
import { toast } from "react-toastify";
import { getInitials } from "@shared/utility";

const InvestmentDetails = () => {
  const location = useLocation();
  const { id } = location.state;
  const [isLoading, setLoading] = useState(false);
  const [makePayment, setMakePayment] = useState(false);
  const [uniquePassword, setUniquePassword] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getServiceOptions());
  }, [dispatch]);

  const { investmentDetails, loading } = useSelector(
    (store) => store?.investors
  );
  const { amount_to_pay, investment_rate } = useSelector((store) => store.miner_vendors);

  const payviawallet = async () => {
    setLoading(true);
    const data = {
      payment_method: "wallet",
      payment_method_id: "",
      payment_intent_id: "",
      amount: amount_to_pay,
    };
    const paymentData = await dispatch(postInvestmentPaymentData({ id, data }));

    if (paymentData?.payload.status == 201) {
      setUniquePassword(paymentData?.payload?.data?.unique_password);
      setMakePayment(false);
      setLoading(false);
      dispatch(getProfile());
      dispatch(fetchAllNotifications({limit:10}));
      dispatch(getSingleInvestment(id));
    } else {
      setLoading(false);
      toast.error(paymentData.data.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: investmentDetails?.miner?.name,
        email: investmentDetails?.miner?.email,
      },
    });

    if (!result?.paymentMethod) {
      toast.error(result.error.message);
    }

    const data = {
      payment_method: "stripe",
      payment_method_id: result.paymentMethod.id,
      payment_intent_id: result.paymentMethod.id,
      amount: amount_to_pay,
    };
    const paymentData = await dispatch(postInvestmentPaymentData({ id, data }));

    if (paymentData?.payload.status == 201) {
      setUniquePassword(paymentData?.payload?.data?.unique_password);
      setMakePayment(false);
      dispatch(getSingleInvestment(id));
    } else {
      toast.error(paymentData.data.message);
    }
  };
  const copyToClipboard = (password) => {
    navigator.clipboard.writeText(password);
    toast.success("Password copied to clipboard");
  };

  return loading ? (
    <div className="text-default w-full bg-white px-[34px] p-[30px]">
      <LoadingState />
    </div>
  ) : (
    <>
      {makePayment ? (
        <div className="text-default w-full bg-white px-[34px] p-[30px]">
          <div className="max-w-lg">
            <Payment investment_rate={investment_rate} />
          </div>
          <div className="mt-10">
            <Button
              className="mt-10"
              variant="contained"
              disabled={!stripe}
              // loading={isPaying}
              type="submit"
              onClick={handleSubmit}
              color="primary"
            >
              Pay now
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="text-default w-full bg-white px-[34px] p-[30px]">
            <div className="flex justify-between items-start">
              <div className="flex justify-start items-start mb-[15px]">
                <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
                  {`${getInitials(investmentDetails?.investor?.name)}`}
                </div>
                <div className="flex flex-col justify-start items-start flex-grow-0 ml-4 flex-shrink-0 ">
                  <p className="flex-grow-0 flex-shrink-0 mb-1 text-base text-left text-primary">
                    {investmentDetails?.investor?.name}
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 mb-1 text-sm text-left text-[#18273a]/[0.94]">
                    {investmentDetails?.investor?.company}
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 text-sm font-light text-left text-[#1b2b41]/[0.72]">
                    {investmentDetails?.investor?.country?.name}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-row  mb-[15px]">
              <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
                Business email address
              </p>
              <p className="text-base font-light text-left text-[#18273a]/[0.94]">
                {investmentDetails?.paid
                  ? investmentDetails.investor?.email
                  : "**************************"}
              </p>
            </div>
            <div className="flex flex-row  mb-[15px]">
              <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
                Phone number:
              </p>
              <p className="text-base font-light text-left text-[#18273a]/[0.94]">
                {investmentDetails?.paid
                  ? `${investmentDetails.investor?.phone?.code}${investmentDetails.investor?.phone?.number}`
                  : "**************************"}
              </p>
            </div>
            <div className="flex flex-row  mb-[15px]">
              <p className="min-w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
                Additional notes:
              </p>
              <p className="text-base font-light text-left text-[#18273a]/[0.94]">
                {investmentDetails?.additional_notes}
              </p>
            </div>

            <div className="w-full mt-8">
              {!investmentDetails?.paid && !uniquePassword && (
                <>
                  <h3 className="text-2xl mb-4">Reveal password</h3>
                  <p className="text-base font-light text-[#18273a]/[0.94] mb-[15px]">
                    This unique password and buyer details can only be viewed
                    after payment. You are required to present this password
                    when contacting the buyer. Do not share this password with a
                    third-party.
                  </p>
                  <div className="flex gap-8 w-[548px]">
                    <Button size="mid" onClick={() => setMakePayment(true)}>
                      Pay with Stripe
                    </Button>
                    <Button size="mid" onClick={() => payviawallet()}>
                      {isLoading ? "Processing..." : "Pay via Wallet"}
                    </Button>
                  </div>
                </>
              )}

              {(investmentDetails?.paid || uniquePassword) && (
                <div className="">
                  <h3 className="text-2xl mb-4">Password</h3>
                  <p className="text-base font-light text-[#18273a]/[0.94] mb-2">
                    This unique password and the buyer details should not be
                    shared with a third party for security reasons.
                  </p>

                  <div className="flex items-center gap-x-4 max-w-[400px]">
                    <Input
                      placeholder="* * * * * * * *"
                      type="password"
                      value={"password"}
                    />
                    <button onClick={() => copyToClipboard(uniquePassword)}>
                      <CopyIcon />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InvestmentDetails;
