const UsersIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.3 13.2999C8.9 13.2999 8.4 13.0999 8.4 12.6999C8.4 12.2999 8.9 12.3999 9.3 11.6999C9.3 11.6999 11.1 6.79993 7.5 6.79993C3.9 6.79993 5.7 11.6999 5.7 11.6999C6.1 12.3999 6.6 12.2999 6.6 12.6999C6.6 13.0999 6.1 13.2999 5.7 13.2999C5.1 13.3999 4.6 13.2999 4 13.8999V19.5999H9C9.2 17.8999 9.7 14.3999 10.1 13.4999L10.2 13.3999C10 13.2999 9.7 13.2999 9.3 13.2999Z"
        fill="#D9E8FC"
        fillOpacity="0.7"
      />
      <path
        d="M20 13.1C19.3 12.3 18.7 12.4 18 12.3C17.5 12.2 16.9 12.1 16.9 11.6C16.9 11.1 17.5 11.3 18 10.4C18 10.4 20.1 4.5 15.8 4.5C11.4 4.6 13.5 10.5 13.5 10.5C14 11.3 14.6 11.2 14.6 11.6C14.6 12.1 14 12.2 13.5 12.3C12.6 12.4 11.8 12.3 11 13.8C10.6 14.7 10 19.6 10 19.6H20V13.1Z"
        fill="#D9E8FC"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default UsersIcon;
