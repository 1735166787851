import React from "react";
import { Header } from "@atoms/header";
import { Button } from "@atoms/button/index";
import Success from "@assets/images/success.png";
import { BaseUrl } from "@config/app-url";
import { useNavigate } from "react-router-dom";

const ChangePasswordSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="font-default">
      <Header />

      <div className=" my-4 bg-white p-8 rounded mt-[113px] lg:mt-16 lg:bg-none">
        <div className=" bg-no-repeat  w-full h-26  flex justify-center  align-center">
          <img alt="checkImage" src={Success} />
        </div>
        <h2 className="font-bold text-xl lg:text-2xl text-head pt-4 flex justify-center align-center">
          Password changed successfully
        </h2>

        <p className="text-lightgray  text-base font-light pt-0.5 pb-8 text-center flex justify-center align-center">
          Your password has been changed successfully and you can proceed to
          sign in.
        </p>

        <Button onClick={() => navigate(BaseUrl)}>Sign in</Button>
      </div>
    </div>
  );
};
export default ChangePasswordSuccess;
