/* eslint-disable react/prop-types */
const DiamondIcon = ({ color, opacity, h, w }) => {
  const iconColor = color ? color : "#D9E8FC";
  const fillOpacity = opacity ? opacity : "0.7";
  const height = h ? h : "25";
  const width = w ? w : "24";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 6.5H7L4 10L12 19.5L20 10L17 6.5ZM8.64 9.5H5.75L7.27 7.72L8.64 9.5ZM10.42 9.5L12 7.66L13.58 9.5H10.42ZM14 10.5L12 17.18L10 10.5H14ZM9.26 10.5L11.15 16.94L5.73 10.5H9.26V10.5ZM14.75 10.5H18.28L12.85 16.94L14.75 10.5ZM15.37 9.5L16.74 7.72L18.25 9.5H15.35H15.37ZM16 7.5L14.56 9.31L13.1 7.5H16V7.5ZM9.43 9.33L8 7.5H10.9L9.43 9.33Z"
        fill={iconColor}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export default DiamondIcon;
