import React, { useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function MinerInventoryChart({ inventory }) {
  const formattedData = useMemo(() => {
    // removing duplicates from the resulting array
    const labels = [...new Set(inventory.map((x) => x.name))];

    const inventoryData = [];

    // getting the summation of each mineral resource
    labels.forEach((x) =>
      inventoryData.push(
        inventory
          .filter((inv) => inv.name === x)
          .reduce((prev, current) => prev + current.count, 0)
      )
    );

    const data = {
      labels,
      datasets: [
        {
          label: "# of Votes",
          data: inventoryData,

          backgroundColor: [
            "rgb(97, 102, 182)",
            "rgb(45, 156, 219)",
            "rgba(275, 206, 86)",
            "rgba(75, 192, 192)",
            "rgba(153, 102, 255)",
            "rgba(255, 159, 64)",
            "rgb(255, 0, 0)",
            "rgb(242, 109, 255)",
            "rgb(128,0,0)",
            "rgb(135,206,250)",
            "rgb(218,112,214)",
            "rgb(245,222,179)",
            "rgb(244,164,96)",
            "rgb(245,255,250)",
            "rgb(255,250,240)",
            "rgb(236, 127, 0)",
            "rgb(114, 127, 0)",
            "rgb(83, 96, 152)",
            "rgb(174, 96, 152)"

          ],
          borderColor: [
            "rgb(97, 102, 182)",
            "rgb(45, 156, 219)",
            "rgba(275, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
            "rgb(255, 0, 0)",
            "rgb(242, 109, 255)",
            "rgb(128,0,0)",
            "rgb(135,206,250)",
            "rgb(218,112,214)",
            "rgb(245,222,179)",
            "rgb(244,164,96)",
            "rgb(245,255,250)",
            "rgb(255,250,240)",
            "rgb(236, 127, 0)",
            "rgb(114, 127, 0)",
            "rgb(83, 96, 152)",
            "rgb(174, 96, 152)"
          ],
          borderWidth: 1,
        },
      ],
    };

    return { data, labels };
  }, [inventory]);

  return <Doughnut data={formattedData.data} />;
}
