/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import {
  // Button,
  TableSkeleton,
  TableEmptyState,
  Search,
  Button,
} from "@ui";
import InterationsStats from "@ui/InterationsStats";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Sort, DropDownIcon, SearchIcon } from "@ui/icons";
import { OffTakerInvestmentInfoUrl } from "config/app-url";
import { useSelector, useDispatch } from "react-redux";
import { getInvestments } from "store/slices/offtaker-slice";

import { OffTakerInvestmenttableHeaders } from "@constants";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Investments = () => {
  const [value, setValue] = React.useState(0);
  const [limit, setLimit] = useState(10);

  const [isVerified] = useState(true);
  const [underReview] = useState(false);
  const [searchParams, setParams] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loading,
    investments: { data: investments, counts, pagination },
  } = useSelector((store) => store?.offtakers);

  React.useEffect(() => {
    dispatch(
      getInvestments({
        page: 1,
        limit: limit,
        filter: "",
        search: searchParams,
      })
    );
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleChangePage(page) {
    dispatch(
      getInvestments({
        page: page,
        limit: limit,
        filter: "",
        search: searchParams,
      })
    );
  }

  const handleSetPerPage = (e) => {
    setLimit(e.target.value);
    dispatch(
      getInvestments({
        page: 1,
        limit: e.target.value,
        filter: "",
        search: searchParams,
      })
    );
  };

  const handleSearch = (e) => {
    if (e.target.value == "") {
      dispatch(
        getInvestments({
          page: 1,
          limit: limit,
          filter: "",
          search: searchParams,
        })
      );
    }

    setParams(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    dispatch(
      getInvestments({
        page: 1,
        limit: limit,
        filter: "",
        search: searchParams,
      })
    );
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    if (tab == "All") {
      dispatch(
        getInvestments({
          page: 1,
          limit: limit,
          filter: "",
          search: searchParams,
        })
      );
    } else {
      dispatch(
        getInvestments({
          page: 1,
          limit: limit,
          filter: tab.toLowerCase(),
          search: searchParams,
        })
      );
    }
  };

  const statusClass = (status) => {
    switch (status) {
      case "Completed":
        return "bg-[#D9F2E4] text-[#43BE77]";
      case "Investment Archived":
        return "bg-[#FFE7E5] text-[#F52419]";
      default:
        return "bg-[#FDF2E7] text-[#F2994A]";
    }
  };

  return (
    <div>
      <div className="flex overflow-x-auto scrollbar-hide lg:grid grid-cols-4 gap-3">
        <InterationsStats
          isVerified={isVerified}
          underReview={underReview}
          won={true}
          counts={counts}
          type="investments"
        />
      </div>
      {/* tabs */}
      <div className="w-full mb-5">
        <div className="border-b border-gray-light">
          <div className="flex items-center justify-between">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor="secondary"
            >
              <Tab
                label="All"
                {...a11yProps(0)}
                onClick={(e) => handleTabChange(e, "All")}
              />
              <Tab
                label="Pending"
                {...a11yProps(1)}
                onClick={(e) => handleTabChange(e, "Pending")}
              />
              <Tab
                label="Completed"
                {...a11yProps(2)}
                onClick={(e) => handleTabChange(e, "Completed")}
              />
              <Tab
                label="Ongoing"
                {...a11yProps(3)}
                onClick={(e) => handleTabChange(e, "Ongoing")}
              />
            </Tabs>
            <p className="font-extralight text-sm text-heading">
              Total investments:{" "}
              <span className="text-primary">{investments.length}</span>
            </p>
          </div>
        </div>
      </div>
      {/* table */}
      <div className="flex flex-col">
        <div className=" flex lg:flex flex-rols-3 gap-1 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden card border-gray-200 sm:rounded-lg bg-white">
              {/* input field goes here */}
              <div className="flex justify-between p-4">
                <div className="flex gap-2">
                  <form
                    onSubmit={(e) => handleSearchSubmit(e)}
                    className="flex gap-2"
                  >
                    <div className="w-[250px] lg:w-[538px]">
                      <Search
                        placeholder="Search investments"
                        onChange={(e) => handleSearch(e)}
                      />
                    </div>
                    <Button type="submit" color="primary">
                      <SearchIcon color="#ffff" opacity="1" />
                    </Button>
                  </form>
                </div>
              </div>
              {loading && <TableSkeleton />}
              {!loading && investments?.length > 0 && (
                <>
                  <table className="min-w-full divide-y divide-[#1A38601A] border-b border-[#1A38601A]">
                    <thead className="bg-[#EFF0FE]">
                      <tr>
                        {OffTakerInvestmenttableHeaders.map((header, index) => (
                          <th
                            key={index + 1}
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-normal text-primary uppercase tracking-wider"
                          >
                            {!header.hasIcon && header.title}
                            {header.hasIcon && (
                              <div className="flex  items-center">
                                <span>{header.title}</span>
                                <Sort />
                              </div>
                            )}
                          </th>
                        ))}
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Delete</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white font-light divide-y divide-[#1A38601A]">
                      {investments.map((investment, index) => (
                        <tr key={index} className="text-[#18273AF0]">
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {++index}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {investment?._id?.slice(0, 8).toUpperCase()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {investment.proposal?.product_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            <span className="flex items-center">
                              {investment.proposal?.production_phase?.slug}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {`${investment.proposal?.price} USD`}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {investment.proposal?.investment_type?.slug}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`py-1 px-5 flex justify-center items-center capitalize text-sm leading-5 font-light rounded ${statusClass(
                                investment.status
                              )} `}
                            >
                              {investment.status}
                            </span>
                          </td>
                          <td>
                            <button
                              className="outline-none mr-1 mb-1 px-3 py-1 bg-transprent text-sm underline text-[#6166B6] focus:outline-none"
                              onClick={() =>
                                navigate(OffTakerInvestmentInfoUrl, {
                                  state: { id: investment._id },
                                })
                              }
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="flex justify-start lg:justify-end items-center w-full py-4 px-8 text-[13px] uppercase text-[#18273AF0]">
                    <div className="flex justify-between gap-2 items-center">
                      <p>Rows per page: {pagination?.limit}</p>
                      <FormControl sx={{ height: "30px", border: 0 }}>
                        <Select
                          sx={{ height: "30px", border: 0 }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={(e) => handleSetPerPage(e)}
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={30}>30</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="mx-6">
                      1 - {Math.ceil(pagination?.totalDocs / pagination?.limit)}{" "}
                      of {pagination?.page}
                    </div>
                    <div className="flex justify-between gap-10">
                      {pagination?.prevPage && (
                        <div
                          className="rotate-90 cursor-pointer"
                          onClick={() => handleChangePage(pagination?.prevPage)}
                        >
                          <DropDownIcon />
                        </div>
                      )}
                      {pagination?.page >=
                      Math.ceil(pagination?.totalDocs / pagination?.limit)
                        ? null
                        : pagination?.page + 1 && (
                            <div
                              className="-rotate-90 cursor-pointer"
                              onClick={() => handleChangePage(pagination?.nextPage)}
                            >
                              <DropDownIcon />
                            </div>
                          )}
                    </div>
                  </div>
                </>
              )}

              {!investments?.length > 0 && !loading && (
                <TableEmptyState text="product" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investments;
