/* eslint-disable no-unused-vars */
import { CardElement } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getServiceOptions } from "store/slices/miners/vendor-slice";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      border: "1px solid red",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const Payment = ({ setpaytype, setBalance }) => {
  const [payment, setPayment] = useState(false);
  const { loading, data } = useSelector((state) => state.settings);
  const { inventory_rate } = useSelector((store) => store.miner_vendors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServiceOptions());
  }, [dispatch]);
  const setPaymentType = (type) => {
    if (type == "Stripe") {
      setpaytype("stripe");
      setPayment(true);
    } else {
      setBalance(data?.wallet_balance ? parseFloat(data?.wallet_balance).toFixed(2) : 0);
      setpaytype("wallet");
      setPayment(false);
    }
  };

  return (
    <div className=" mx-auto">
      <h2 className="text-heading text-2xl mb-2">Payment</h2>
      <p className="text-ts font-light mt-2 mb-5">
        You have decided to proceed with paying the listing fee charge of
        <span className="text-heading text-base ml-2">${inventory_rate}</span>. Choose a
        payment method to complete your transaction.{" "}
      </p>
      {/* <p className="text-ts font-extralight mt-2 mb-5">
        Test card details{" "}
        <span className="text-heading text-base font-normal">
          (4242 4242 4242 4242) 12/32 321 45678
        </span>
      </p> */}
      <label>
        {payment ? (
          <div>
            Card details
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        ) : (
          <>
            <div className="relative flex items-start mt-3">
              <div className="flex items-center h-5">
                <input
                  id="payment"
                  aria-describedby="payment-description"
                  name="plan"
                  type="radio"
                  className="focus:ring-[#6166B6]  h-4 w-4 text-[#6166B6] border-gray-300"
                  onChange={() => {
                    setPaymentType("Stripe");
                  }}
                />
              </div>
              <div className="ml-3 flex flex-col text-sm">
                <label className="font-medium text-gray-700 text[16px]">
                  Pay via Stripe (Cards)
                </label>
              </div>
            </div>
            <div className="relative flex items-start mt-3">
              <div className="flex items-center h-5">
                <input
                  id="payment"
                  aria-describedby="payment-description"
                  name="plan"
                  type="radio"
                  className="focus:ring-[#6166B6]  h-4 w-4 text-[#6166B6] border-gray-300"
                  onChange={() => {
                    setPaymentType("E-Wallet");
                  }}
                />
              </div>
              <div className="ml-3 flex flex-col text-sm">
                <label className="font-medium text-gray-700 text[16px]">
                  Pay via E Wallet ({" "}
                  {data?.wallet_balance
                    ? parseFloat(data?.wallet_balance).toFixed(2)
                    : 0}{" "}
                  USD)
                </label>
              </div>
            </div>
          </>
        )}
      </label>
    </div>
  );
};

export default Payment;
