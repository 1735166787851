import * as yup from "yup";

export const mineSchema = yup.object().shape({
  mine_name: yup.string().required("Please, enter mine name"),
  mine_type: yup.object().required("Please, choose a mine type"),
  description: yup
    .string()
    .min(10)
    .required("Please, give a detail description"),
  company_name: yup.string().required("Please, enter your company's name"),
  address: yup.string().required("Please, enter your address"),
  reserves: yup.string().required("Please, enter number of reserves"),
  mineral_type: yup.array().required("Atleast one mineral must be selected"),
  sdgGoals: yup.array().required("Atleast one sdg goals must be selected"),
});

export const directorySchema = yup.object().shape({
  name: yup.string().required("Please, enter name"),
  type: yup.object().required("Please, choose a type"),
  address: yup.string().required("Please, enter your address"),
  keywords: yup
  .array()
  .of(yup.string())
  .min(1, "Atleast one Product Type is required")
  .required("Product Type is required"),
  // product_type: yup
  // .array()
  // .of(yup.string())
  // .min(1, "Atleast one Product Type is required")
  // .required("Product Type is required"),
});

export const memberSchema = yup.object().shape({
  name: yup.string().required("Please, enter name"),
  email: yup
    .string()
    .email("invalid email")
    .max(255)
    .required("email is required"),
  phone: yup.string().min(7).required().typeError("Phone number is required"),
  address: yup.string().required("Please, enter your address"),
  phone_code: yup.string().required("Please, enter number of reserves"),
  password: yup
    .string()
    .required("enter a password of a minimum of 8 characters")
    .min(8)
    .matches(
      // eslint-disable-next-line no-useless-escape
      RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/),
      "must contain uppercase, lowercase, number, special character"
      // "atleast one uppercase, one lowercase, one number and one special case character"
    ),
});

export const productSchema = yup.object().shape({
  product_name: yup.string().required("Please, enter product name"),
  product_image: yup.array().required("Please, choose a image"),
  mine_id: yup.object().required("Please, choose a source mine").nullable(),
  mineral_slug: yup
    .object()
    .required("Please, choose a mineral type")
    .nullable(),
  product_description: yup
    .string()
    .required("Please, give a detail description")
    .min(10, "Product description must be atleast 10 characters long"),
  unit_of_measurement: yup
    .object()
    .required("Please, select a unit of measurement")
    .nullable(),
  product_quantity: yup.string().required("Please, enter product's quantity"),
  product_treatment_cost: yup
    .string()
    .required("Please, enter the treatment cost"),
  product_price: yup.string().required("Please, enter your product price"),
  // product_total_value: yup.number().required("Please, enter total value"),
  // product_start_date: yup.string().required("Please, choose a starting date"),
  // product_end_date: yup.string().required("Please, oose a ending date"),
  // duration: yup.array().required("Please, choose a product duration"),
  product_shipment_options: yup.array(),
  // .required("Atleast one shipment option must be selected"),
});

export const OnboardiingCreateUserSchema = yup.object().shape({
  fullname: yup.string().max(255).required("please add your fullname"),
  email: yup
    .string()
    .email("invalid email")
    .max(255)
    .required("email is required"),
  // phone: yup.string().min(7).required().typeError("Phone number is required"),
  password: yup
    .string()
    .required("enter a password of a minimum of 8 characters")
    .min(8)
    .matches(
      // eslint-disable-next-line no-useless-escape
      RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/),
      "must contain uppercase, lowercase, number, special character"
      // "atleast one uppercase, one lowercase, one number and one special case character"
    ),
  // agree: yup.bool().oneOf([true], "accept terms and condition").required(),
  notification: yup.bool(),
});

export const MinerVerificationFormSchema = yup.object().shape({
  mineName: yup.string().required("Mine name is required"),
  description: yup.string().required("Description is required"),
  mineType: yup.string().required("Mine type is required"),
  reserves: yup
    .number()
    .min(1, "minimum of 1")
    .required()
    .typeError("Reserves is required"),
  long: yup.number().required().typeError("Longitude is required"),
  lat: yup.number().required().typeError("Latitude is required"),
  address: yup.string().required("Address is required"),
  postal: yup.string(),
  country: yup.string().required("Country is required"),
  state: yup.string(),
  city: yup.string(),
  companyName: yup.string().required("Company name is required"),
  revenue: yup.string().required("Revenue range is required"),
  minerals: yup
    .array()
    .of(yup.string())
    .min(1, "Atleast one Mineral is required")
    .required("Minerals is required"),
  sdg_goals: yup
    .array()
    .of(yup.string())
    .min(1, "Atleast one SDG Goals is required")
    .required("SDG Goals is required"),
});

export const OfftakerVerificationFormSchema = yup.object().shape({
  description: yup.string().required("Description is required"),
  long: yup.number().required().typeError("Longitude is required"),
  lat: yup.number().required().typeError("Latitude is required"),
  address: yup.string().required("Address is required"),
  postal: yup.string(),
  country: yup.string().required("Country is required"),
  state: yup.string(),
  city: yup.string(),
  companyName: yup.string().required("Company name is required"),
});

export const VendorVerificationFormSchema = yup.object().shape({
  companyName: yup.string().required("Company name is required"),
  description: yup.string().required("Description is required"),
  long: yup.number().required().typeError("Longitude is required"),
  lat: yup.number().required().typeError("Latitude is required"),
  address: yup.string().required("Address is required"),
  postal: yup.string(),
  country: yup.string().required("Country is required"),
  state: yup.string(),
  city: yup.string(),
  industry: yup.string().required("Industry is required"),
  keywords: yup
    .array()
    .of(yup.string())
    .min(1, "Atleast one Keyword is required")
    .required("Keyword is required"),
  sdg_goals: yup
    .array()
    .of(yup.string())
    .min(1, "Atleast one SDG Goals is required")
    .required("SDG Goals is required"),
});

export const UploadPocVerificationFormSchema = yup.object().shape({
  shipment_carrier_service: yup
    .string()
    .required("Carrier service is required"),
  shipment_tracking_id: yup.string().required("Tracking ID is required"),
  long: yup.number().required().typeError("Longitude is required"),
  lat: yup.number().required().typeError("Latitude is required"),
  address: yup.string().required("Address is required"),
  postal: yup.string(),
  shipment_reference_id: yup.string().optional("Tracking Id must be a string"),
  shipment_departure_date: yup
    .string()
    .required("Shipment departure date is required"),
  shipment_arrival_date: yup
    .string()
    .required("Shipment arrival date is required"),
  shipment_additional_note: yup
    .string()
    .optional("Additional note must be a string"),
    transaction_poc_documents: yup
    .array()
    // .of(yup.string())
    .required("Atleast 1 Poc document is required"),
    transaction_shipment_documents: yup
    .array()
    // .of(yup.string())
    .required("Atleast 1 Poc document is required"),
});

export const adminRegisterUserSchema = yup.object().shape({
  fullname: yup.string().max(255).required("please add your fullname"),
  email: yup
    .string()
    .email("invalid email")
    .max(255)
    .required("email is required"),
});

export const OfftakerTransactionShippingSchema = yup.object().shape({
  contactPerson: yup.string().required("Contact Person name is required"),
  long: yup.number().required().typeError("Longitude is required"),
  lat: yup.number().required().typeError("Latitude is required"),
  address: yup.string().required("Address is required"),
  postal: yup.string(),
  country: yup.string().required("Country is required"),
  state: yup.string(),
  city: yup.string(),
  terms: yup.boolean().required("terms is required"),
  code: yup.string(),
  number: yup.string(),
  iso_code: yup.string(),
});
