import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "components/Table";
import { offtakersProjectsTableInfo } from "constants/index";
import { InfoStats } from "components/ui/index";
import {
  fetchPaginatedProjects,
  getProjects,
  setProjectsSearchValue,
} from "store/slices/offtaker-slice";

const Projects = () => {
  const {
    loading,
    projects: { counts, data, pagination, search, filter },
  } = useSelector((state) => state.offtakers);

  const handleViewDetails = (record) => {
    navigate(`${record._id}`);
  };
  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedProjects({
        page: pagination[option + "Page"],
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedProjects({
        page: 1,
        per_page: e.target.value,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(setProjectsSearchValue(e.target.value));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(getProjects());
    }
    dispatch(setProjectsSearchValue(search));
    dispatch(
      fetchPaginatedProjects({
        page: 1,
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    if (tab == "All") {
      if (search == "") {
        dispatch(getProjects());
      } else {
        dispatch(
          fetchPaginatedProjects({
            page: 1,
            per_page: pagination.limit,
            search: search,
            filter: "",
          })
        );
      }
    } else {
      dispatch(
        fetchPaginatedProjects({
          page: 1,
          per_page: pagination.limit,
          search: search,
          filter: tab.toLowerCase(),
        })
      );
    }
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getProjects());
  }, []);

  return (
    <div>
      <div className="grid grid-cols-3 lg:grid gap-2 sm:flex overflow-x-auto scrollbar-hide">
        <InfoStats
          statData={offtakersProjectsTableInfo.statInfo}
          counts={counts}
        />
      </div>
      {/* tabs */}
      <div className="mt-4 sm:flex overflow-x-auto scrollbar-hide">
        <Table
          tableInfo={offtakersProjectsTableInfo}
          isViewDetails={true}
          isStatus={true}
          handleViewDetails={handleViewDetails}
          navigate={navigate}
          data={data}
          pagination={pagination}
          handleSetPerPage={handleSetPerPage}
          handleSearchSubmit={handleSearchSubmit}
          handleChangePage={handleChangePage}
          handleTabChange={handleTabChange}
          loading={loading}
          handleSearch={handleSearch}
          fetchDefaultData={getProjects}
          search={search}
        />
      </div>
    </div>
  );
};

export default Projects;
