/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import {
  getCountries,
  getServiceOptions,
  requestService,
} from "store/slices/miners/vendor-slice";
import { useDispatch, useSelector } from "react-redux";
import VendorInquiryForm from "components/vendors/VendorInquiryForm";

const ContactVendor = () => {
  const { countries, service_options } = useSelector(
    (store) => store.miner_vendors
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getServiceOptions());
  }, []);

  return (
    <VendorInquiryForm
      countries={countries}
      service_options={service_options}
      dispatchFunction={requestService}
      action="request"
      role="miner"
    />
  );
};

export default ContactVendor;
