/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "@config/axios-instance";
import * as API from "@config/url";
import { toast } from "react-toastify";

const GET = "get";
const POST = "post";

const config = (method, url, data = null) => {
  const token = JSON.parse(localStorage.getItem("token"));

  return {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    },
    data,
  };
};

const fetchUnverifiedProfile = createAsyncThunk(
  "administrator/fetchUnverifiedProfile",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.fetchUnverifiedProfile(payload.page, payload.limit, payload.search)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminPermissions = createAsyncThunk(
  "fetchAdminPermissions",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getAdminPermissions(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminRoles = createAsyncThunk(
  "fetchAdminRoles",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.getAdminRoles(payload.id, payload.page, payload.limit)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchDirectory = createAsyncThunk(
  "fetchDirectory",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.getDirectory(payload.page, payload.limit)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminTeam = createAsyncThunk(
  "fetchAdminTeam",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.getAdminTeam(payload.page, payload.limit, payload.id)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminRole = createAsyncThunk(
  "fetchAdminRole",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.getAdminRole(payload.id, payload._id)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminMineData = createAsyncThunk(
  "fetchAdminMineData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchAdminMineData);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminMineByID = createAsyncThunk(
  "fetchAdminMineByID",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getMineByID(payload.id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminAuctionByID = createAsyncThunk(
  "fetchAdminAuctionByID",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getAdminAuctionByID(payload.id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminVendorByID = createAsyncThunk(
  "fetchAdminVendorByID",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getAdminVendorByID(payload.id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminOfferByID = createAsyncThunk(
  "fetchAdminOfferByID",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getAdminOfferByID(payload.id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchUsers = createAsyncThunk(
  "fetchUsers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.getUsers(payload.page, payload.limit, payload.search)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchSuspensions = createAsyncThunk(
  "fetchSuspensions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.getSuspensions(payload.page, payload.limit, payload.search)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchPayments = createAsyncThunk(
  "fetchPayments",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.getPayments(payload.page, payload.limit, payload.search)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const searchAllDirectory = createAsyncThunk(
  "searchAllDirectory",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.searchAllDirectory(payload.search));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchPaginatedAdminMines = createAsyncThunk(
  "fetchPaginatedAdminMines",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedAdminMine(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchPaginatedAdminMinerals = createAsyncThunk(
  "fetchPaginatedAdminMinerals",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedAdminMinerals(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminAuctions = createAsyncThunk(
  "fetchAdminAuctions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getAdminAuctions(payload.filter));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminOffers = createAsyncThunk(
  "fetchAdminOffers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getAdminOffers(payload.filter));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminVendors = createAsyncThunk(
  "fetchAdminVendors",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.getAdminVendors(
          payload.filter,
          payload?.search ? payload.search : "",
          payload?.country ? payload.country : ""
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminMines = createAsyncThunk(
  "fetchAdminMines",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getAdminMines);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminMinerals = createAsyncThunk(
  "fetchAdminMinerals",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getAdminMinerals);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdmintariff = createAsyncThunk(
  "fetchAdmintariff",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getAdminTariff);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminAnalytics = createAsyncThunk(
  "fetchAdminAnalytics",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getAdminAnalytics);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getTransactions = createAsyncThunk(
  "administrator/getTransactions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.getAdminTransactions));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchPaginatedTransactions = createAsyncThunk(
  "administrator/fetchPaginatedTransactions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedAdminTransactions(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getTransaction = createAsyncThunk(
  "administrator/getTransaction",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.getAdminTransaction(id)));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postCreateAdminMineral = createAsyncThunk(
  "postCreateAdminMineral",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.addAdminMineral, data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postCreateAdminMine = createAsyncThunk(
  "postCreateAdminMine",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.postAdminMines, data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const addteamMember = createAsyncThunk(
  "addteamMember",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.AddAdminMember, data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateTeamMember = createAsyncThunk(
  "updateTeamMember",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        API.UpdateAdminMember(payload.id),
        payload.data
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateServiceOptions = createAsyncThunk(
  "updateServiceOptions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(API.updateServiceOptions(payload.id),payload.data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateAdminMineral = createAsyncThunk(
  "updateAdminMineral",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        API.updateAdminMineral(payload.id),
        payload.data
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateSuspensionStatus = createAsyncThunk(
  "updateSuspensionStatus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        API.updateSuspension(payload.id),
        payload.data
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateAdminMines = createAsyncThunk(
  "updateAdminMines",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        API.updateAdminMines(payload.id),
        payload.data
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateAdminStatus = createAsyncThunk(
  "updateAdminStatus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        API.updateAdminStatus(payload.id, payload.status),
        payload.data
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const sendAdminMessage = createAsyncThunk(
  "sendAdminMessage",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        API.sendmessageAdmin(payload.id),
        payload.data
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postArchiveAuction = createAsyncThunk(
  "postArchiveAuction",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        API.postAdminAuctionArchive(payload.id),
        payload.data
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postArchivetender = createAsyncThunk(
  "postArchivetender",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        API.archiveAdmintender(payload.id),
        payload.body
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postArchiveTransaction = createAsyncThunk(
  "postArchiveTransaction",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        API.archiveAdmintransaction(payload.id),
        payload.body
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postArchiveInvestMent = createAsyncThunk(
  "postArchiveInvestMent",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        API.archiveInvestment(payload.id),
        payload.body
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postArchiveproject = createAsyncThunk(
  "postArchiveproject",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        API.archiveProject(payload.id),
        payload.body
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postArchiveVendor = createAsyncThunk(
  "postArchiveVendor",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        API.postAdminVendorArchive(payload.id),
        payload.data
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postArchiveOffer = createAsyncThunk(
  "postArchiveOffer",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        API.postAdminArchiveOffers(payload.id),
        payload.data
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateAdminPermissions = createAsyncThunk(
  "updateAdminPermissions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        API.updateAdminPermission(payload.id),
        payload.data
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const deleteAdminmember = createAsyncThunk(
  "deleteAdminmember",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(API.DeleteAdminMember(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const deleteAdminMineral = createAsyncThunk(
  "deleteAdminMineral",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(API.deleteAdminMineral(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const restoreAdminMineral = createAsyncThunk(
  "restoreAdminMineral",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.patch(API.deleteAdminMineral(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const deleteAdminRoles = createAsyncThunk(
  "deleteAdminRoles",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.patch(API.deleteAdminRoles,data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const suspendAdminMine = createAsyncThunk(
  "suspendAdminMine",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.suspendAdminMine(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const deleteAdminUser = createAsyncThunk(
  "deleteAdminUser",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(API.deleteAdminuser(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const registerUser = createAsyncThunk(
  "registerUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.addUser, data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const addDirectory = createAsyncThunk(
  "addDirectory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.postDirectory, data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminMember = createAsyncThunk(
  "fetchAdminMember",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.getAdminMmember(payload.id, payload._id)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const findUser = createAsyncThunk(
  "findUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.findUser(payload.id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const addAdminRole = createAsyncThunk(
  "addAdminRole",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.postAdminRole, data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAdminResources = createAsyncThunk(
  "fetchAdminResources",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getAdminResources(payload.type));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const manageUser = createAsyncThunk(
  "administrator/manageUser",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.manageUser(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const postVerifyProfile = createAsyncThunk(
  "administrator/postVerifyProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.postVerifyProfile(data.id), {
        role: data.role,
        status: data.selectedValue,
      });
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getTenders = createAsyncThunk(
  "administrator/getTenders",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.getAdminTenders));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getInvestments = createAsyncThunk(
  "administrator/getInvestments",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.getAdminInvestments));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getProjects = createAsyncThunk(
  "administrator/getProjects",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.getAdminprojects));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getInvestmentByID = createAsyncThunk(
  "getInvestmentByID",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getAdminInvestmentByID(payload.id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getProjectByID = createAsyncThunk(
  "getProjectByID",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getProjectByID(payload.id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchPaginatedAdminInvestments = createAsyncThunk(
  "fetchPaginatedAdminInvestments",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedAdminInvestments(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchPaginatedAdminProjects = createAsyncThunk(
  "fetchPaginatedAdminProjects",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedAdminprojects(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchPaginatedTenders = createAsyncThunk(
  "administrator/fetchPaginatedTenders",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedAdminTenders(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getTender = createAsyncThunk(
  "administrator/getTender",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.getAdminTender(id)));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const searchTender = createAsyncThunk(
  "administrator/searchTender",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(GET, API.searchAdminTender(payload.id, payload.search))
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const adminSlice = createSlice({
  name: "administrator",

  initialState: {
    data: {},
    docs: [],
    roles: [],
    mineData: {},
    projectDetails: {},
    mines: [],
    funding: {},
    product: {},
    offerings: [],
    vendor: {},
    seller: {},
    investment: {},
    analytics: {},
    activity: {},
    make_bid: {},
    offer: {},
    payments: [],
    directory: [],
    offers: [],
    vendors: [],
    pagination: [],
    team_member: [],
    resources: [],
    users: [],
    suspensions: [],
    userDetails: [],
    members: [],
    minerals:[],
    projects: [],
    auctions: [],
    select_member: [],
    add_member: [],
    add_directory: [],
    add_user: [],
    status: false,
    loading: false,
    error: null,
    message: null,
    tariff:[],
    search: "",
    filter: "",
    profiles: [],
    userProfile: {},
    tenders: {
      products: [],
      counts: {},
      pagination: 0,
      search: "",
      filter: "",
    },
    tender: {
      bids: [],
      product: {},
    },
    transactions: {
      counts: {},
      data: [],
      pagination: 0,
      search: "",
      filter: "",
    },
    transaction: {},
  },
  reducers: {
    setTransactionSearchValue: (state, action) => {
      state.transactions.search = action.payload;
    },
    setTenderSearchValue: (state, action) => {
      state.tenders.search = action.payload;
    },
    setProjectsSearchValue: (state, action) => {
      state.search = action.payload;
    },
  },

  extraReducers: {
    [fetchUnverifiedProfile.pending]: (state) => {
      state.loading = true;
    },
    [fetchUnverifiedProfile.fulfilled]: (state, { payload }) => {
      state.profiles = payload.data.account;
      state.count = payload.data.count;
      state.pagination = payload.data.pagination;
      state.loading = false;
    },
    [fetchUnverifiedProfile.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },
    [fetchAdminAuctions.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminAuctions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.auctions = payload.data.products;
    },
    [fetchAdminAuctions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchAdminOffers.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminOffers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.offers = payload.data[0].docs;
    },
    [fetchAdminOffers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchAdminVendors.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminVendors.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.vendors = payload.data.vendors.docs;
    },
    [fetchAdminVendors.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchAdminResources.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminResources.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.resources = payload.data.resource;
    },
    [fetchAdminResources.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchAdminMines.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminMines.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.mines = payload.data.docs;
      state.pagination = payload.data.pagination;
    },
    [fetchAdminMines.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchAdminMinerals.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminMinerals.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.minerals = payload.data.mineral;
      state.pagination = payload.data.pagination;
    },
    [fetchAdminMinerals.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchAdmintariff.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdmintariff.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.tariff = payload.data.docs;
    },
    [fetchAdmintariff.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [fetchAdminAnalytics.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminAnalytics.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.analytics = payload.data.count;
    },
    [fetchAdminAnalytics.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    // fetch transactions data
    [getTransactions.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.transactions.data = payload.data.transactions;
      state.transactions.counts = payload.data.counts;
      state.transactions.pagination = payload.data.pagination;
    },
    [getTransactions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    }, // eslint-disable-next-line no-unused-vars
    [fetchPaginatedTransactions.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPaginatedTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.transactions.data = payload.data.transactions;
      state.transactions.counts = payload.data.counts;
      state.transactions.pagination = payload.data.pagination;
    },
    [fetchPaginatedTransactions.rejected]: (state, action) => {
      state.error = true;
    },
    // fetch transaction data
    [getTransaction.pending]: (state) => {
      state.loading = true;
    },
    [getTransaction.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.transaction = payload.data.transactions[0];
    },
    [getTransaction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [fetchAdminMineByID.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminMineByID.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.funding = payload.data.mine;
    },
    [fetchAdminMineByID.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchAdminAuctionByID.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminAuctionByID.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.product = payload.data.product;
      state.seller = payload.data.seller;
      state.activity = payload.data.activity;
      state.make_bid = payload.data.make_bid;
    },
    [fetchAdminAuctionByID.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchAdminVendorByID.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminVendorByID.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.vendor = payload.data.vendor;
      state.offerings = payload.data.offerings;
    },
    [fetchAdminVendorByID.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchAdminOfferByID.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminOfferByID.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.offer = payload?.data[0];
    },
    [fetchAdminOfferByID.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchAdminMineData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.mineData = payload.data;
    },
    [fetchAdminMineData.rejected]: (state, { payload }) => {
      state.error = true;
      toast.error(payload.message);
    },
    [fetchPaginatedAdminMines.pending]: (state) => {
      state.loading = true;
    },
    [fetchPaginatedAdminMines.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.mines = payload.data.docs;
      state.pagination = payload.data.pagination;
    },
    [fetchPaginatedAdminMines.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchPaginatedAdminMinerals.pending]: (state) => {
      state.loading = true;
    },
    [fetchPaginatedAdminMinerals.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.minerals = payload.data.mineral;
      state.pagination = payload.data.pagination;
    },
    [fetchPaginatedAdminMinerals.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [postCreateAdminMine.pending]: (state) => {
      state.loading = true;
    },
    [postCreateAdminMine.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postCreateAdminMine.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [postCreateAdminMineral.pending]: (state) => {
      state.loading = true;
    },
    [postCreateAdminMineral.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postCreateAdminMineral.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [addAdminRole.pending]: (state) => {
      state.loading = true;
    },
    [addAdminRole.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.roles = payload.data;
    },
    [addAdminRole.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [addteamMember.pending]: (state) => {
      state.loading = true;
    },
    [addteamMember.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.add_directory = payload.data;
    },
    [addteamMember.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [deleteAdminmember.pending]: (state) => {
      state.loading = true;
    },
    [deleteAdminmember.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteAdminmember.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [deleteAdminMineral.pending]: (state) => {
      state.loading = true;
    },
    [deleteAdminMineral.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteAdminMineral.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [restoreAdminMineral.pending]: (state) => {
      state.loading = true;
    },
    [restoreAdminMineral.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [restoreAdminMineral.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [deleteAdminRoles.pending]: (state) => {
      state.loading = true;
    },
    [deleteAdminRoles.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteAdminRoles.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [suspendAdminMine.pending]: (state) => {
      state.loading = true;
    },
    [suspendAdminMine.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [suspendAdminMine.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [deleteAdminUser.pending]: (state) => {
      state.loading = true;
    },
    [deleteAdminUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteAdminUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [updateTeamMember.pending]: (state) => {
      state.loading = true;
    },
    [updateTeamMember.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.add_directory = payload.data;
    },
    [updateTeamMember.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [updateAdminMineral.pending]: (state) => {
      state.loading = true;
    },
    [updateAdminMineral.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateAdminMineral.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [updateServiceOptions.pending]: (state) => {
      state.loading = true;
    },
    [updateServiceOptions.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateServiceOptions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [updateSuspensionStatus.pending]: (state) => {
      state.loading = true;
    },
    [updateSuspensionStatus.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateSuspensionStatus.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },

    [updateAdminMines.pending]: (state) => {
      state.loading = true;
    },
    [updateAdminMines.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateAdminMines.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [updateAdminStatus.pending]: (state) => {
      state.loading = true;
    },
    [updateAdminStatus.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateAdminStatus.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [sendAdminMessage.pending]: (state) => {
      state.loading = true;
    },
    [sendAdminMessage.fulfilled]: (state, { payload }) => {
      state.loading = false;
      toast.success(payload.message);
    },
    [sendAdminMessage.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [postArchiveAuction.pending]: (state) => {
      state.loading = true;
    },
    [postArchiveAuction.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postArchiveAuction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [postArchivetender.pending]: (state) => {
      state.loading = true;
    },
    [postArchivetender.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postArchivetender.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [postArchiveTransaction.pending]: (state) => {
      state.loading = true;
    },
    [postArchiveTransaction.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postArchiveTransaction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [postArchiveInvestMent.pending]: (state) => {
      state.loading = true;
    },
    [postArchiveInvestMent.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postArchiveInvestMent.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [postArchiveproject.pending]: (state) => {
      state.loading = true;
    },
    [postArchiveproject.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postArchiveproject.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [postArchiveVendor.pending]: (state) => {
      state.loading = true;
    },
    [postArchiveVendor.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postArchiveVendor.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [postArchiveOffer.pending]: (state) => {
      state.loading = true;
    },
    [postArchiveOffer.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postArchiveOffer.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [updateAdminPermissions.pending]: (state) => {
      state.loading = true;
    },
    [updateAdminPermissions.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateAdminPermissions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [registerUser.pending]: (state) => {
      state.loading = true;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.add_user = payload.data;
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [addDirectory.pending]: (state) => {
      state.loading = true;
    },
    [addDirectory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.add_member = payload.data;
    },
    [addDirectory.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    //fetch roles
    [fetchAdminRoles.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminRoles.fulfilled]: (state, { payload }) => {
      state.docs = payload.data[0];
      state.loading = false;
    },
    [fetchAdminRoles.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchAdminPermissions.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminPermissions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    },
    [fetchAdminPermissions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchDirectory.pending]: (state) => {
      state.loading = true;
    },
    [fetchDirectory.fulfilled]: (state, { payload }) => {
      state.directory = payload.data.directory;
      state.pagination = payload.data.pagination;
      state.loading = false;
    },
    [fetchDirectory.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    //fetch roles
    [fetchAdminTeam.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminTeam.fulfilled]: (state, { payload }) => {
      state.members = payload.data[0];
      state.loading = false;
    },
    [fetchAdminTeam.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchAdminRole.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminRole.fulfilled]: (state, { payload }) => {
      state.team_member = payload.data[0];
      state.loading = false;
    },
    [fetchAdminRole.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchUsers.pending]: (state) => {
      state.loading = true;
    },
    [fetchUsers.fulfilled]: (state, { payload }) => {
      if (payload?.data?.user) {
        state.users = payload.data.user[0].docs;
        state.pagination = payload.data.user[0].pagination[0];
        state.counts = payload.data.count;
      }

      state.loading = false;
    },
    [fetchUsers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchSuspensions.pending]: (state) => {
      state.loading = true;
    },
    [fetchSuspensions.fulfilled]: (state, { payload }) => {
      if (payload?.data?.user) {
        state.suspensions = payload.data.user[0].docs;
        state.pagination = payload.data.user[0].pagination[0];
        state.counts = payload.data.count;
      }

      state.loading = false;
    },
    [fetchSuspensions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchPayments.pending]: (state) => {
      state.loading = true;
    },
    [fetchPayments.fulfilled]: (state, { payload }) => {
      if (payload?.data?.payments) {
        state.payments = payload.data.payments;
        state.pagination = payload.data.pagination;
        state.counts = payload.data.counts;
      }

      state.loading = false;
    },
    [fetchPayments.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [searchAllDirectory.pending]: (state) => {
      state.loading = true;
    },
    [searchAllDirectory.fulfilled]: (state, { payload }) => {
      state.directory = payload.data.directory;
      state.pagination = payload.data.pagination;
      state.loading = false;
    },
    [searchAllDirectory.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [fetchAdminMember.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminMember.fulfilled]: (state, { payload }) => {
      state.select_member = payload.data;
      state.loading = false;
    },
    [fetchAdminMember.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [findUser.pending]: (state) => {
      state.loading = true;
    },
    [findUser.fulfilled]: (state, { payload }) => {
      state.userDetails = payload.data;
      state.loading = false;
    },
    [findUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [manageUser.pending]: (state) => {
      state.loading = true;
    },
    [manageUser.fulfilled]: (state, { payload }) => {
      state.userProfile = payload.data;
      state.loading = false;
    },
    [manageUser.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },

    [postVerifyProfile.pending]: (state) => {
      state.loading = true;
    },
    [postVerifyProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      toast.success(payload.data.message);
    },
    [postVerifyProfile.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },

    // fetch tenders data
    [getTenders.pending]: (state) => {
      state.loading = true;
    },
    [getTenders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.tenders.products = payload.data.products;
      state.tenders.counts = payload.data.counts;
      state.tenders.pagination = payload.data.pagination;
    },
    [getTenders.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    }, // eslint-disable-next-line no-unused-vars
    [getInvestments.pending]: (state) => {
      state.loading = true;
    },
    [getInvestments.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.pagination = payload.data.pagination;
      state.counts = payload.data.counts;
      state.investments = payload.data.offers;
    },
    [getInvestments.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [getProjects.pending]: (state) => {
      state.loading = true;
    },
    [getProjects.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.pagination = payload.data.pagination;
      state.counts = payload.data.counts;
      state.projects = payload.data.projects;
    },
    [getProjects.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchPaginatedAdminProjects.pending]: (state) => {
      state.loading = true;
    },
    [fetchPaginatedAdminProjects.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.pagination = payload.data.pagination;
      state.counts = payload.data.counts;
      state.projects = payload.data.projects;
    },
    [fetchPaginatedAdminProjects.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [getInvestmentByID.pending]: (state) => {
      state.loading = true;
    },
    [getInvestmentByID.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.investment = payload.data.investment;
    },
    [getInvestmentByID.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [getProjectByID.pending]: (state) => {
      state.loading = true;
    },
    [getProjectByID.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projectDetails = payload.data.project[0];
    },
    [getProjectByID.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchPaginatedAdminInvestments.pending]: (state) => {
      state.loading = true;
    },
    [fetchPaginatedAdminInvestments.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.pagination = payload.data.pagination;
      state.counts = payload.data.counts;
      state.investments = payload.data.offers;
    },
    [fetchPaginatedAdminInvestments.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchPaginatedTenders.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPaginatedTenders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.tenders.products = payload.data.products;
      state.tenders.counts = payload.data.counts;
      state.tenders.pagination = payload.data.pagination;
    },
    [fetchPaginatedTenders.rejected]: (state, action) => {
      state.error = true;
    },
    // fetch tender data
    [getTender.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getTender.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.tender.bids = payload.data.tender[0].bids;
      state.tender.product = payload.data.tender[0];
      toast.success(payload.data.message);
    },
    [getTender.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [searchTender.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [searchTender.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.tender.bids = payload.data.tender[0].bids;
      state.tender.product = payload.data.tender[0];
      toast.success(payload.data.message);
    },
    [searchTender.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
  },
});

export const {
  setTenderSearchValue,
  setTransactionSearchValue,
  setProjectsSearchValue,
} = adminSlice.actions;

export {
  fetchUnverifiedProfile,
  manageUser,
  postVerifyProfile,
  fetchAdminMinerals,
  updateAdminMineral,
  updateServiceOptions,
  fetchAdmintariff,
  fetchAdminRoles,
  fetchAdminOffers,
  fetchAdminVendors,
  fetchAdminResources,
  findUser,
  postCreateAdminMineral,
  postArchivetender,
  fetchPaginatedAdminMines,
  fetchPaginatedAdminMinerals,
  fetchAdminVendorByID,
  fetchDirectory,
  postArchiveAuction,
  postArchiveVendor,
  deleteAdminMineral,
  restoreAdminMineral,
  getInvestments,
  getProjects,
  postArchiveTransaction,
  getInvestmentByID,
  updateSuspensionStatus,
  getProjectByID,
  fetchPaginatedAdminInvestments,
  postArchiveOffer,
  fetchAdminTeam,
  fetchPaginatedAdminProjects,
  deleteAdminUser,
  deleteAdminmember,
  deleteAdminRoles,
  fetchAdminAnalytics,
  fetchAdminAuctions,
  postArchiveInvestMent,
  postArchiveproject,
  fetchSuspensions,
  fetchPayments,
  updateAdminPermissions,
  fetchAdminOfferByID,
  fetchAdminAuctionByID,
  suspendAdminMine,
  postCreateAdminMine,
  fetchAdminRole,
  fetchAdminMineByID,
  updateAdminMines,
  fetchAdminMineData,
  fetchAdminPermissions,
  fetchUsers,
  addAdminRole,
  sendAdminMessage,
  updateAdminStatus,
  fetchAdminMines,
  searchAllDirectory,
  fetchAdminMember,
  addteamMember,
  updateTeamMember,
  registerUser,
  addDirectory,
  fetchPaginatedTransactions,
  getTransactions,
  getTransaction,
  searchTender,
  getTenders,
  fetchPaginatedTenders,
  getTender,
};
export default adminSlice.reducer;
