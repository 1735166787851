const ClockIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9 7.72721L10.95 9.77721C11.3015 10.1287 11.3015 10.6985 10.95 11.05C10.5985 11.4015 10.0287 11.4015 9.67721 11.05L7.4364 8.80919C7.42415 8.79694 7.41232 8.78442 7.40093 8.77166C7.21626 8.60684 7.1 8.367 7.1 8.1V3.4C7.1 2.90294 7.50294 2.5 8 2.5C8.49706 2.5 8.9 2.90294 8.9 3.4V7.72721ZM8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 14.35C11.507 14.35 14.35 11.507 14.35 8C14.35 4.49299 11.507 1.65 8 1.65C4.49299 1.65 1.65 4.49299 1.65 8C1.65 11.507 4.49299 14.35 8 14.35Z"
        fill="#1C2E45"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default ClockIcon;
