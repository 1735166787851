/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Box } from '@mui/material';
import Button from 'components/ui/Button';
import Success from 'assets/images/success.svg';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 739,
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: 3
};

const CompleteModal = ({
	openSubmitModal,
	setOpenSubmitModal,
	modalMessage,
	investmentId
}) => {
	const navigate = useNavigate();
	const handleClick = () => {
		setOpenSubmitModal(false);
		navigate('/miner/interactions/investments/details', {
			state: {
				id: investmentId
			}
		});
	};
	return (
		<Modal
			open={openSubmitModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<div className="text-center mb-4 py-10 px-16">
					<div className="grid justify-items-center">
						<img src={Success} />
					</div>
					<h1 className="text-[22px] text-head my-4">Submission successful</h1>
					<p className="font-light text-vendorsecondary">{modalMessage}</p>

					<div className="flex items-center justify-center mt-8">
						<Button onClick={handleClick}>Go to investment</Button>
					</div>
				</div>
			</Box>
		</Modal>
	);
};
export default CompleteModal;
