import React from "react";

const AccountStats = ({ data }) => {
  return (
    <div className="grid grid-cols-2 gap-[30px]">
      {data.profile[0].role.name == "Miner" ? (
        <div>
          <p className="text-lg font-semibold ">Miner profile</p>
          <div className="flex justify-between mt-4 border-[1px] border-[#D9D9D9] bg-lightgray500 rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">
              Mines
            </span>
            <span className="text-primary text-sm font-semibold cursor-pointer">
              {data?.stats.miner?.total_mines}
            </span>
          </div>
          <div className="flex justify-between mt-4 border-[1px] border-[#D9D9D9] bg-lightgray500 rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">
              Inventory
            </span>
            <span className="text-primary text-sm font-semibold cursor-pointer">
              {data?.stats.miner?.total_inventory}
            </span>
          </div>
          <div className="flex justify-between mt-4 border-[1px] border-[#D9D9D9] bg-lightgray500 rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">
              Completed transactions
            </span>
            <span className="text-primary text-sm font-semibold cursor-pointer">
              {data?.stats.miner?.completed_transactions}
            </span>
          </div>
          <div className="flex justify-between mt-4 border-[1px] border-[#D9D9D9] bg-lightgray500 rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">
              Team Members
            </span>
            <span className="text-primary text-sm font-semibold cursor-pointer">
              {data?.stats.miner?.team_member}
            </span>
          </div>
        </div>
      ) : data.profile[0].role.name == "Vendor" ? (
        <div>
          <p className="text-lg font-semibold ">Vendor profile</p>
          <div className="flex justify-between mt-4 border-[1px] border-[#D9D9D9] bg-lightgray500 rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">
              Offerings
            </span>
            <span className="text-primary text-sm font-semibold cursor-pointer">
              {data?.stats.vendor?.total_offerings}
            </span>
          </div>
          <div className="flex justify-between mt-4 border-[1px] border-[#D9D9D9] bg-lightgray500 rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">
              Offers
            </span>
            <span className="text-primary text-sm font-semibold cursor-pointer">
              {data?.stats.vendor?.total_offers}
            </span>
          </div>
          <div className="flex justify-between mt-4 border-[1px] border-[#D9D9D9] bg-lightgray500 rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">
              Projects
            </span>
            <span className="text-primary text-sm font-semibold cursor-pointer">
              {data?.stats.vendor?.total_projects}
            </span>
          </div>
          <div className="flex justify-between mt-4 border-[1px] border-[#D9D9D9] bg-lightgray500 rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">
              Team Members
            </span>
            <span className="text-primary text-sm font-semibold cursor-pointer">
              {data?.stats.vendor?.team_member}
            </span>
          </div>
        </div>
      ) : (
        <div>
          <p className="text-lg font-semibold ">Off-taker profile</p>
          <div className="flex justify-between mt-4 border-[1px] border-[#D9D9D9] bg-lightgray500 rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">Bids</span>
            <span className="text-primary text-sm font-semibold cursor-pointer">
              {data?.stats.offtaker?.total_bids}
            </span>
          </div>
          <div className="flex justify-between mt-4 border-[1px] border-[#D9D9D9] bg-lightgray500 rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">
              Demands
            </span>
            <span className="text-primary text-sm font-semibold cursor-pointer">
              {data?.stats.offtaker?.total_demands}
            </span>
          </div>
          <div className="flex justify-between mt-4 border-[1px] border-[#D9D9D9] bg-lightgray500 rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">
              Completed transactions
            </span>
            <span className="text-primary text-sm font-semibold cursor-pointer">
              {data?.stats.offtaker?.total_transactions}
            </span>
          </div>
          <div className="flex justify-between mt-4 border-[1px] border-[#D9D9D9] bg-lightgray500 rounded-[3px] py-[11px] px-4">
            <span className="text-[#192434] font-semibold text-base">
              Team Members
            </span>
            <span className="text-primary text-sm font-semibold cursor-pointer">
              {data?.stats.offtaker?.team_member}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountStats;
