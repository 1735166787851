import { Skeleton, Stack } from '@mui/material';
import { Card } from '@ui';

const SkeletonComponent = () => {
	return (
		<Stack>
			<Card>
				<div className="flex flex-row h-[66px] max-w-[230px]">
					<Skeleton variant="circular" width={60} height={60} />
					<div className="flex-1 ml-4">
						<Skeleton variant="text" />
						<Skeleton variant="text" />
						<Skeleton variant="text" />
					</div>
				</div>
				{Array(4)
					.fill(null)
					.map((_, index) => (
						<div className="w-full mt-6 max-w-[280px]" key={index}>
							<div className="flex items-center mb-3 gap-4">
								<Skeleton variant="text" className="flex-2" />
								<Skeleton variant="text" className="flex-3" />
							</div>
						</div>
					))}
				<Skeleton variant="text" className="w-full" height={70} />
			</Card>
		</Stack>
	);
};

export default SkeletonComponent;
