import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "@config/axios-instance";
import * as API from "../../../config/url";
import { toast } from "react-toastify";

const GET = "get";
const POST = "post";
// const PATCH = "patch";
// const DELETE = "delete";

const config = (method, url, data = null) => {
  const token = JSON.parse(localStorage.getItem("token"));

  return {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      "Authorization": "bearer "+ token
    },
    data,
  };
};

const submitVendorRatingsFromMiner = createAsyncThunk(
  "miner/ratings",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(POST, API.rateVendorFromMiner(payload.id, payload.type), payload.formdata)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const awardABid = createAsyncThunk(
  "tender/awardBid",
  async ({ productId, bidId, data }, { rejectWithValue }) => {
    const params = config(POST, API.awardBid(productId, bidId), data);
    try {
      const response = await axios(params);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOffers = createAsyncThunk(
  "miner/getOffers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getMinerOffers);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPaginatedOffers = createAsyncThunk(
  "miner/fetchPaginatedOffers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedMinerOffers(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOffer = createAsyncThunk(
  "transaction/getOffer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getMinerOffer(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProjects = createAsyncThunk(
  "miner/getProjects",
  async (name, thunkAPI) => {
    try {
      // thunkAPI.dispatch(openModal());
      const resp = await axios.get(API.getMinerProjects);
      return resp;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getProject = createAsyncThunk(
  "miner/getProject",
  async (id, thunkAPI) => {
    try {
      // thunkAPI.dispatch(openModal());
      const resp = await axios.get(API.getMinerProject(id));
      return resp;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const fetchPaginatedProjects = createAsyncThunk(
  "miner/fetchPaginatedProjects",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedMinerProjects(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);


const getTenders = createAsyncThunk(
  "tender/getTenders",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.getMinerTenders));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPaginatedTenders = createAsyncThunk(
  "miner/fetchPaginatedTenders",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedMinerTenders(
          payload.page,
          payload.per_page,
          payload.search
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getTender = createAsyncThunk(
  "tender/getTender",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.getMinerTender(id)));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const searchTender = createAsyncThunk(
  "tender/searchTender",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.searchMinerTender(payload.id,payload.search)));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getTransactions = createAsyncThunk(
  "transaction/getTransactions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.getMinerTransactions));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPaginatedTransactions = createAsyncThunk(
  "miner/fetchPaginatedTransactions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedMinerTransactions(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const InitializeChat = createAsyncThunk(
  "messages/initializeChat",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(POST, API.initializeMinersMessageUrl, payload)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);


const getTransaction = createAsyncThunk(
  "transaction/getTransaction",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.getMinerTransaction(id)));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postTransactionConfirm = createAsyncThunk(
  "transaction/post_confirm_request",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(POST, API.postMinerTransaction(payload.id), payload.formdata)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateEta = createAsyncThunk(
  "transaction/updateEta",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(POST, API.postMinerTransaction(payload.id), payload.formdata)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateTransactionStatus = createAsyncThunk(
  "transaction/updateTransactionStatus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(POST, API.postMinerTransaction(payload.id), payload.formdata)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getInterests = createAsyncThunk(
  "miner/getInterests",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getMinerInterests(payload.id,payload.status));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllMinerChats = createAsyncThunk(
  "messages/getAllMinerChats",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.minersMessagesUrl);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getInterest = createAsyncThunk(
  "miner/getInterest",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getMinerInterest(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const awardInterest = createAsyncThunk(
  "miner/selectVendor",
  async (id, { rejectWithValue, getState }) => {
    try {
      const {
        interactions: { interestSub },
      } = getState();
      const payload = { status: "awarded" };
      const resp = await axios.post(
        API.minerAwardInterest(interestSub),
        payload
      );
      toast.success(
        "Your selection has been sent and the vendor will be notified to connect."
      );
      return resp.data;
    } catch (error) {
      toast.error("An error occured while selecting the vendor.");
      return rejectWithValue("something went wrong");
    }
  }
);

const transactionSlice = createSlice({
  name: "transaction",

  initialState: {
    loading: false,
    error: false,
    message: null,
    projects: {
      counts: {},
      data: [],
      pagination: null,
      search: "",
      filter: "",
    },
    project: null,
    tenders: {
      products: [],
      counts: {},
      pagination: null,
      search: "",
      filter: "",
    },
    tender: {
      bids: [],
      product: {},
    },
    transactions: {
      counts: {},
      data: [],
      pagination: null,
      search: "",
      filter: "",
    },

    transaction: {},
    offers: {
      counts: {},
      data: [],
      pagination: null,
      search: "",
      filter: "",
    },
    offer: null,
    interests: {
      counts: {},
      data: [],
      pagination: null,
      search: "",
      filter: "",
    },
    interest: null,
    interestSub: null,
    chatMessage: {
      message: {},
      messages: [],
    },
  },

  reducers: {
    setSearchValue: (state, action) => {
      state.transactions.search = action.payload;
    },
    setTenderSearchValue: (state, action) => {
      state.tenders.search = action.payload;
    },
    setMinerOffersSearchValue: (state, action) => {
      state.offers.search = action.payload;
    },
    setAwardedInterestSub: (state, action) => {
      state.interestSub = action.payload;
    },
    setProjectsSearchValue: (state, action) => {
      state.projects.search = action.payload;
    },
  },

  extraReducers: {
    // Award a bid
    [awardABid.pending]: (state) => {
      state.loading = true;
    },
    [awardABid.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.status = payload.status;
      toast.success(payload.data.message);
      state.loading = false;
    },
    [awardABid.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },
    [submitVendorRatingsFromMiner.pending]: (state) => {
      state.loading = true;
    },
    [submitVendorRatingsFromMiner.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.status = payload.status;
      toast.success(payload?.data.message);
    },
    [submitVendorRatingsFromMiner.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },
    [getProjects.pending]: (state) => {
      state.loading = true;
    },
    [getProjects.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projects.data = payload.data.projects;
      state.projects.counts = payload.data.counts;
      state.projects.pagination = payload.data.pagination;
    },
    // eslint-disable-next-line no-unused-vars
    [getProjects.rejected]: (state, action) => {
      state.loading = false;
    },
    // Initialize a chat
    [InitializeChat.pending]: (state) => {
      state.loading = true;
    },
    [InitializeChat.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.chatMessage.message = payload.data.messaging;
      // toast.success(payload.data.message);
    },
    [InitializeChat.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
      // Get all chats
      [getAllMinerChats.pending]: (state) => {
        state.loading = true;
      },
      [getAllMinerChats.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.error = false;
        state.chatMessage.messages = payload.data.messaging;
        // toast.success(payload.data.message);
      },
      [getAllMinerChats.rejected]: (state, { payload }) => {
        state.loading = false;
        state.error = true;
        toast.error(payload.message);
      },
    [fetchPaginatedProjects.pending]: (state) => {
      state.loading = true;
    },
    [fetchPaginatedProjects.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projects.data = payload.data.projects;
      state.projects.counts = payload.data.counts;
      state.projects.pagination = payload.data.pagination;
    },
    [fetchPaginatedProjects.rejected]: (state, action) => {
      state.error = true;
      console.log(action);
      // toast.error(payload.message);
    },
    [getProject.pending]: (state) => {
      state.loading = true;
    },
    [getProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.project = payload.data[0];
    },
    // eslint-disable-next-line no-unused-vars
    [getProject.rejected]: (state, action) => {
      state.loading = false;
    },

    [getOffers.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getOffers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.offers.data = payload.data.offers;
      state.offers.counts = payload.data.counts;
      state.offers.pagination = payload.data.pagination;
      //toast.success(payload.data.message);
    },
    [getOffers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    }, // eslint-disable-next-line no-unused-vars
    [fetchPaginatedOffers.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPaginatedOffers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.offers.data = payload.data.offers;
      state.offers.counts = payload.data.counts;
      state.offers.pagination = payload.data.pagination;
    },
    [fetchPaginatedOffers.rejected]: (state, action) => {
      state.error = true;
      console.log(action);
      // toast.error(payload.message);
    },
    // fetch transaction data
    [getOffer.pending]: (state) => {
      state.loading = true;
    },
    [getOffer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.offer = payload.data[0];
      //  toast.success(payload.data.message);
    },
    [getOffer.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    // fetch tenders data
    [getTenders.pending]: (state) => {
      state.loading = true;
    },
    [getTenders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.tenders.products = payload.data.products;
      state.tenders.counts = payload.data.counts;
      state.tenders.pagination = payload.data.pagination;
      //      toast.success(payload.data.message);
    },
    [getTenders.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    }, // eslint-disable-next-line no-unused-vars
    [fetchPaginatedTenders.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPaginatedTenders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.tenders.products = payload.data.products;
      state.tenders.counts = payload.data.counts;
      state.tenders.pagination = payload.data.pagination;
    },
    [fetchPaginatedTenders.rejected]: (state, action) => {
      state.error = true;
      console.log(action);
      // toast.error(payload.message);
    },
    // fetch tender data
    [getTender.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getTender.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.tender.bids = payload.data[0]?.bids;
      state.tender.product = payload.data[0];
      toast.success(payload.data.message);
    },
    [getTender.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [searchTender.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [searchTender.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.tender.bids = payload.data[0].bids;
      state.tender.product = payload.data[0];
      toast.success(payload.data.message);
    },
    [searchTender.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    // fetch transactions data
    [getTransactions.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.transactions.data = payload.data.transactions;
      state.transactions.counts = payload.data.counts;
      state.transactions.pagination = payload.data.pagination;
      //toast.success(payload.data.message);
    },
    [getTransactions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    }, // eslint-disable-next-line no-unused-vars
    [fetchPaginatedTransactions.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPaginatedTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.transactions.data = payload.data.transactions;
      state.transactions.counts = payload.data.counts;
      state.transactions.pagination = payload.data.pagination;
    },
    [fetchPaginatedTransactions.rejected]: (state, action) => {
      state.error = true;
      console.log(action);
      // toast.error(payload.message);
    },
    // fetch transaction data
    [getTransaction.pending]: (state) => {
      state.loading = true;
    },
    [getTransaction.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.transaction = payload.data.transactions[0];
      //  toast.success(payload.data.message);
    },
    [getTransaction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [getInterests.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getInterests.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.interests.data = payload.data;
      state.interests.counts = payload.data.counts;
      state.interests.pagination = payload.data.pagination;
      //toast.success(payload.data.message);
    },
    [getInterests.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    }, // eslint-disable-next-line no-unused-vars
    [getInterest.pending]: (state) => {
      state.loading = true;
    },
    [getInterest.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.interest = payload.data.interest;
      //  toast.success(payload.data.message);
    },
    [getInterest.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    // post transaction data confirm request
    [postTransactionConfirm.pending]: (state) => {
      state.loading = true;
    },
    [postTransactionConfirm.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      toast.success(payload.data.message);
    },
    [postTransactionConfirm.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    // update transaction Eta
    [updateEta.pending]: (state) => {
      state.loading = true;
    },
    [updateEta.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      toast.success(payload.data.message);
    },
    [updateEta.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    // update transaction Status
    [updateTransactionStatus.pending]: (state) => {
      state.loading = true;
    },
    [updateTransactionStatus.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      toast.success(payload.data.message);
    },
    [updateTransactionStatus.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
  },
});

export const {
  setSearchValue,
  setTenderSearchValue,
  setMinerOffersSearchValue,
  setAwardedInterestSub,
  setProjectsSearchValue,
} = transactionSlice.actions;

export {
  awardABid,
  getTenders,
  getTender,
  searchTender,
  getTransaction,
  submitVendorRatingsFromMiner,
  getTransactions,
  InitializeChat,
  postTransactionConfirm,
  updateEta,
  updateTransactionStatus,
};
export default transactionSlice.reducer;
