import ReactDOM from "react-dom";
import Backdrop from "./Backdrop";
import PropTypes from "prop-types";

const ControlledModal = ({ isOpen, handleClose, className, children }) => {
  if (!isOpen) return null;
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop close={handleClose} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <div
          className={`py-4 bg-white shadow-lg rounded-lg z-50 max-h-[85vh] ${className}`}
        >
          {children}
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

ControlledModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};
export default ControlledModal;
