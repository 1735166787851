/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { Button } from "@ui";
import { useSelector, useDispatch } from "react-redux";
import { getFundingInterests } from "@slices/miners/investor-slice";
import { getInitials } from "shared/utility";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TransactionsBasicTabs({ id, filterKey, setFilterKey, interests = 0 }) {
  const [value, setValue] = React.useState(0);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getFundingInterests({ id, status: filterKey }));
  }, [filterKey]);
  const options = ["pending", "awarded", "archived"];
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFilterKey(options[newValue]);
  };

  return (
    <div className="w-full flex-1 mb-5">
      <div className="border-b border-gray-light">
        <div className="flex items-center justify-between">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="secondary"
          >
            <Tab label="Interests" {...a11yProps(0)} />
            <Tab label="Awarded" {...a11yProps(1)} />
            {/* <Tab label="Archived" {...a11yProps(2)} /> */}
          </Tabs>
          <p className="font-extralight text-sm text-heading">
            {`Total ${filterKey}: `}
            <span className="text-primary">{interests?.length}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

const ManageInterests = () => {
  const location = useLocation();
  const { id } = location.state;
  const [filterKey, setFilterKey] = useState("pending");
  const navigate = useNavigate();
  const [filteredInterest, setFilteredInterests] = useState([]);

  const {
    // eslint-disable-next-line no-unused-vars
    fundingInterests: { interests },
  } = useSelector((store) => store?.investors);

  const interestMapper = (values) => {
    return {
      id: values?._id,
      name: values?.account?.name,
      role: values?.account?.role,
      image: values?.image?.url,
      location: values?.account?.country?.name,
    };
  };
  useEffect(() => {
    const data = interests.filter((item) => item.status === filterKey);
    setFilteredInterests(data);
  }, [filterKey, interests]);
  return (
    <div>
      {/* tabs */}
      <TransactionsBasicTabs
        interests={interests}
        id={id}
        setFilterKey={setFilterKey}
        filterKey={filterKey}
      />
      <div className="grid grid-cols-3 gap-[30px]">
        {interests
          ?.map(interestMapper)
          .map(({ id, name, role, image, location }) => (
            <div key={id} className="bg-white p-6 rounded-[6px]">
              <div className="flex">
                <div className="flex w-[71px] h-[71px] rounded-full items-center justify-center text-xl bg-primary-light text-primary">
                  {image ? (
                    <img
                      className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                      src={`${image}`}
                      alt=""
                    />
                  ) : (
                    `${getInitials(name)}`
                  )}
                </div>

                <div className="ml-[30px] flex flex-col justify-between">
                  <span className="text-head text-lg">{name}</span>
                  <span className="text-vendor text-base font-light">
                    {role?.name}
                  </span>
                  <span className="text-inputtext text-base font-light">
                    {location}
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-8">
                {/* <Button
								variant="contained"
								color="neutral"
								//   onClick={() => navigate("/investors")}
							>
								Archive
							</Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    navigate(
                      `/miner/investors/fundings/manage_interests/${id}`,
                      {
                        state: {
                          id,
                        },
                      }
                    )
                  }
                >
                  View details
                </Button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ManageInterests;
