import React from "react";
import OfferingDetails from "./OfferingDetails";
import VendorDetails from "./VendorDetails";

function Index() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [offering, setOffering] = React.useState(null);
  const [steps] = React.useState([
    {
      label: "Details",
      component: (
        <VendorDetails
          setOffering={setOffering}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: "Offering",
      component: <OfferingDetails offering={offering} />,
    },
  ]);

  return (
    <div>{steps[activeStep] && <div>{steps[activeStep].component}</div>}</div>
  );
}

export default Index;
