const Popup = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="text-center mt-9">
        <h3 className="text-heading text-2xl">Connect to lead?</h3>
        <span className="text-ts font-light">
          You agree that they are aware that clicking on ‘Connect to Lead’
          authorizes Daniola to share your contact with the prospect.
        </span>
      </div>
    </div>
  );
};

export default Popup;
