/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Map, LongLat, SearchLocation } from "@pages/onboarding";
import { addDirectory } from "@slices/admin-slice";
import { Card, Input, Select, TextArea, Chips, Button } from "@ui";
import { ErrorMessage } from "@components";
import { SuccessResponse } from "config/status-code";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { directorySchema } from "@validations/schema";

const AddDirectory = () => {
  const [location_type, setLocationType] = useState("");
  const [gps, setGps] = useState(false);
  const [address, setAddress] = useState(true);
  const [longitude_latitude, setLongitudeLatitude] = useState(false);
  var types = [
    {
      name: "Miner",
      slug: "miner",
    },
    {
      name: "Vendor",
      slug: "vendor",
    },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(directorySchema),
    mode: "all",
  });

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();
    if (key === "," && trimmedInput.length && !keywords.includes(trimmedInput)) {
      e.preventDefault();
      keywords.push(trimmedInput);
      setValue("keywords", keywords, { shouldValidate: true, shouldDirty: true,});
      setKeywords(keywords);
      setInput("");
    }

    if (key === "Enter" && trimmedInput.length && !keywords.includes(trimmedInput)) {
      e.preventDefault();
      keywords.push(trimmedInput);
      setValue("keywords", keywords, { shouldValidate: true, shouldDirty: true,});
      setKeywords(keywords);
      setInput("");
    }

    if (key === "Backspace" && !input.length && keywords.length && isKeyReleased) {
      const tagsCopy = [...keywords];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setKeywords(tagsCopy);
      setValue("keywords", tagsCopy, { shouldValidate: true,shouldDirty: true,});
      setInput(poppedTag);
    }
    setIsKeyReleased(false);
  };

  const deleteKeyword = (keyword) => {
    const newKeywords = keywords.filter((item) => item !== keyword);
    setValue("keywords", newKeywords, { shouldValidate: true, shouldDirty: true,});
    return setKeywords(newKeywords);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onSubmit = (data) => {
    const { address, long, lat, ...updatedData } = data;
    let formData = {
      name: data.name,
      type: data.type.slug,
      longitude: Number(data.long),
      latitude: Number(data.lat),
      address: data.address,
      country: data.country,
      product_type: keywords,
    };

    dispatch(addDirectory(formData)).then((response) => {
        if (response.payload.status && SuccessResponse(response.payload.status)) {
          navigate("/admin/directory");
          toast.success("Directory Details Added");
        }
      });
  };

  return (
    <Card>
      <div className="max-w-[796px] mx-auto">
        <h2 className="text-heading text-2xl my-2">Add new directory</h2>
        <p className="text-ts font-light my-2">
          Enter the details for the directory as specified below.
        </p>
        <div className="my-6">
          <h3 className="my-2 text-lg text-heading">Directory details</h3>
          {/* form */}
          <form
            onSubmit={handleSubmit((data) => {
              onSubmit(data);
            })}
          >
            <div className="grid lg:grid-cols-2 sm:grid gap-1">
              <div>
                <Input label="Name" type="text" {...register("name")} />
                {errors.name && (
                  <ErrorMessage>{errors.name?.message}</ErrorMessage>
                )}
              </div>
              <div>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      label="Type"
                      placeholder="Select type"
                      options={types}
                      {...field}
                    />
                  )}
                />
                {errors.type && (
                  <ErrorMessage>{errors.type?.message}</ErrorMessage>
                )}
              </div>
            </div>
            <div className="w-full mt-2">
              <label
                className="block text-xs mb-2  text-lightgray"
                htmlFor="address"
              >
                Location type
              </label>
              <select
                className="bg-fill cursor-pointer border-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded text-input py-2 px-4 text-sm"
                id="address"
                type="dropdown"
              >
                <option
                  className="bg-fill cursor-pointer border-none"
                  value="address"
                  name="type"
                >
                  Address
                </option>
              </select>
            </div>

            {/* Address */}
            <div className="w-full mt-3">
              <label
                className="block text-xs mb-2  text-lightgray"
                htmlFor="address"
              >
                Address
              </label>
              {address ? (
                <SearchLocation setValue={setValue} />
              ) : (
                <Input type="text" name="address" {...register("address")} />
              )}
              {errors.address && (
                <ErrorMessage>{errors.address?.message}</ErrorMessage>
              )}
            </div>

            <div className="grid lg:grid-cols-2 sm:grid gap-1 mt-2">
              {/** Longitude */}
              <div>
                <Input
                  label="Longitude"
                  type="text"
                  name="long"
                  {...register("long")}
                  readOnly
                />
                {errors.long && (
                  <ErrorMessage>{errors.long?.message}</ErrorMessage>
                )}
              </div>
              {/** Latitude */}
              <div>
                <Input
                  label="Latitude"
                  type="text"
                  name="lat"
                  {...register("lat")}
                  readOnly
                />
                {errors.lat && (
                  <ErrorMessage>{errors.lat?.message}</ErrorMessage>
                )}
              </div>
            </div>
            <div className="grid lg:grid-cols-2 sm:grid gap-1">
              <div>
                <Input
                  id="country"
                  label="Country"
                  type="text"
                  placeholder=""
                  name="country"
                  {...register("country")}
                  readOnly
                />
              </div>
              <div>
                <Input label="Postal code" type="text" readOnly />
              </div>
            </div>
            <div className="w-full mt-2">
                <Input label="Product Type" name="keywords" type="text" value={input} placeholder="Seperate with a comma" onKeyDown={onKeyDown} onChange={onChange} onKeyUp={onKeyUp} />
                {keywords.map((keyword,index) => (
                    <div className="inline-block align-center rounded text-primary font-light my-2 text-xs" key={index + 1}>
                        <div className="bg-primaryLight pl-1 py-1 mx-1" name="keywords"> {keyword}
                            <button className="text-primary px-2" onClick={() => deleteKeyword(keyword)} > x </button>
                        </div>
                    </div>
                ))}
                {errors.keywords?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.keywords?.message} </p>)}
            </div>
            <div className="flex justify-end mt-20">
              <div className="min-w-[80px]">
                <Button
                  type="submit"
                  variant="contained"
                  color={"primary"}
                  fullWidth
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Card>
  );
};
export default AddDirectory;
