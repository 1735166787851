/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { Button } from "@ui";
import { useSelector, useDispatch } from "react-redux";
import {
  archiveProposalInterest,
  getProspectInterests,
  setDeletedInterestSub,
} from "store/slices/offtaker-slice";
import Modal from "@mui/material/Modal";
import ArchivePrompt from "./ArchivePrompt";
import { getInitials } from "shared/utility";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TransactionsBasicTabs({ id, filterKey, setFilterKey, interests = 0 }) {
  const [value, setValue] = React.useState(0);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getProspectInterests({id: id,status:"pending" }));
  }, [filterKey]);
  const options = ["pending", "awarded", "archived"];
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(getProspectInterests({id: id,status:options[newValue] }));
  };

  return (
    <div className="w-full flex-1 mb-5">
      <div className="border-b border-gray-light">
        <div className="flex items-center justify-between">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="secondary"
          >
            <Tab label="Interests" {...a11yProps(0)} />
            <Tab label="Awarded" {...a11yProps(1)} />
            <Tab label="Archived" {...a11yProps(2)} />
          </Tabs>
          <p className="font-extralight text-sm text-heading">
            {`Total ${filterKey}: `}
            <span className="text-primary">{interests?.length}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

const ProspectInterests = () => {
  const location = useLocation();
  const { id } = location.state;
  const [filterKey,   setFilterKey] = useState("pending");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [filteredInterest, setFilteredInterests] = useState([]);

  const {
    // eslint-disable-next-line no-unused-vars
    prospectInterests: { data: interests },
  } = useSelector((store) => store?.offtakers);


  const dispatch = useDispatch();

  const interestMapper = (values) => {
    return {
      id: values?._id,
      name: values?.account?.name,
      image: values?.image?.url,
      role: values?.roles[0],
      location: values?.account?.country?.name,
      status: values?.status
    };
  };

  const handleDelete = useCallback(async () => {
    setOpen(close);

    await dispatch(archiveProposalInterest());
    await dispatch(getProspectInterests({id: id,status:"pending" }));
  }, []);

  const handleArchivePopup = (id) => {
    setOpen(true);
    dispatch(setDeletedInterestSub(id));
  };

  useEffect(() => {
    const data = interests.filter((item) => item.status === filterKey);
    setFilteredInterests(data);
  }, [filterKey, interests]);

  return (
    <div>
      {/* tabs */}
      <TransactionsBasicTabs
        interests={interests}
        id={id}
        setFilterKey={setFilterKey}
        filterKey={filterKey}
      />
      <div className="grid grid-cols-3 gap-[30px]">
        {interests[0]?.docs
          ?.map(interestMapper)
          .map(({ id, name, role, image, location,status }) => (
            <div key={id} className="bg-white p-6 rounded-[6px]">
              <div className="flex">
                <div className="flex w-[71px] h-[71px] rounded-full items-center justify-center text-xl bg-primary-light text-primary">
                  {image ? (
                    <img
                      className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                      src={`${image}`}
                      alt=""
                    />
                  ) : (
                    `${getInitials(name)}`
                  )}
                </div>

                <div className="ml-[30px] flex flex-col justify-between">
                  <span className="text-head text-lg">{name}</span>
                  <span className="text-vendor text-base font-light">
                    {role?.name}
                  </span>
                  <span className="text-inputtext text-base font-light">
                    {location}
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-8">
                {status !== "Archived" ? (
                  <Button
                    variant="contained"
                    color="neutral"
                    onClick={() => handleArchivePopup(id)}
                  >
                    Archive
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" disabled="true">
                    Archive
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    navigate(
                      `/off-taker/invest/prospects/manage_interests/${id}`,
                      {
                        state: {
                          id,
                        },
                      }
                    )
                  }
                >
                  View details
                </Button>
              </div>
            </div>
          ))}
      </div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute top-44 w-full flex justify-center ">
          <div className="bg-white  rounded-lg flex items-center flex-col p-10">
            <ArchivePrompt />
            <div className="flex justify-center gap-8 mt-8">
              <Button
                onClick={() => setOpen(false)}
                variant="contained"
                color="neutral"
              >
                Cancel
              </Button>
              <Button variant="contained" color="danger" onClick={handleDelete}>
                Archive
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProspectInterests;
