/** @format */

import React, { useState } from "react";
import { fetchToken, onMessageListener } from "./config/firebase";
import "@css/index.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { UserContext } from "@src/context";
import AuthProvider from "@context/auth/auth-provider";
import PaginationProvider from "@context/pagination-provider";
import RequireRoleAuth from "@components/RequireRoleAuth";
import BannerProvider from "@context/banner";
import { TermsAndPrivacyProvider } from "@context/TermsAndPrivacyContext";
import { Wallet } from "components/index";

import {
  LayoutOnboarding,
  LayoutMiners,
  LayoutOfftaker,
  LayoutAdmin,
  // LayoutMessages,
} from "@components/layouts";

import {
  SignIn,
  SignUp,
  ChangePassword,
  ForgotPassword,
  CheckEmail,
  ChangePasswordSuccess,
  VerifyPhone,
  VerifyPhoneSuccess,
  SelectCategory,
  MinerAccountVerification,
  OfftakerAccountVerification,
  VendorAccountVerification,
  SubmissionSuccessful,
} from "@pages/onboarding";
import {
  Dashboard,
  Mines,
  AddMine,
  Inventory,
  AddProduct,
  Tenders,
  TendersDetails,
  Transactions,
  TransactionInfo,
  Auctions,
  AuctionDetails,
  MinerDemands,
  MinerDemandsDetails,
  MinerDemandsCreateSupply,
  Investors,
  OfferingDetails,
  ApplyToOffering,
  SeekFunding,
  MinerFundings,
  MinerFundingDetails,
  MinerManageFundingDetails,
  MinerFundingInterestDetails,
  Support,
  SettingsProfile,
  NotificationSettings,
  Security,
  Tracking,
  MinerInvestments,
  MinerInvestorDetails,
  MinerInvestmentDetails,
} from "@pages/miners";

import { OffTakerDashboard } from "@pages/offtakers";
import { LongLatProvider } from "context/LongLat";
import { AddressProvider } from "context/AddressContext";
import {
  AddMember,
  ContactVendor,
  ModifyOffering,
  TeamMember,
  TeamRole,
  VendorAddProduct,
  VendorDashboard,
  VendorDetails,
  VendorFindVendor,
  VendorNotificationSettings,
  VendorOfferings,
  VendorOfferingDetails,
  VendorProjectDetails,
  VendorProjects,
  VendorRequestService,
  VendorSecurity,
  VendorSettingsProfile,
  VendorSupport,
  ServiceRequests,
  RequestDetails,
  AddRole,
  VendorOffers,
  VendorOfferDetails,
  EditvendorRole,
  EditMemberVendor,
  VendorInterests,
  VendorInterestDetails,
  Subscription,
} from "pages/vendors/index";
import LayoutVendors from "components/layouts/vendorLayout";
import {
  OfftakerAuctions,
  OfftakersAuctionDetails,
  OfftakerSettingProfile,
  Demands,
  DemandsDetails,
  DemandsList,
  DemandInterests,
  DemandInterestDetails,
  OfftakerFindVendor,
  OfftakerVendorRequestService,
  OfftakerContactVendor,
  OfftakerVendorDetails,
  Prospects,
  ProspectDetails,
  ProspectInterests,
  ProspectInterestDetails,
  ProspectInterestAcceptance,
  FindProspects,
  OfftakerInvestments,
  OfftakerInvestmentDetails,
  OfftakerInterests,
  OfftakerInterestDetails,
  OfftakerRoles,
  AddOfftakerRole,
  OfftakerMembers,
  AddmemberOfftaker,
  EditOfftakerRole,
  OfftakerTracking,
  OfftakerProposals,
  ProposalDetails,
  IndicateInterest,
  OfftakerNotification,
  OfftakerSecurity,
  EditMemberOfftaker,
  OfftakerProjects,
  OfftakerProjectDetails,
  OfftakersOffers,
  OfftakersOfferDetails,
  OfftakerOfferInterests,
  OfftakerOfferInterestDetails,
  OfftakerSupport,
} from "pages/offtakers/index";
import {
  AdminDashboard,
  ManageUser,
  ProfileApproval,
  User,
  ManageMiner,
  RegisterUser,
  ManageOfftaker,
  ManageVendor,
  Listings,
  EditMine,
  AdminAuctionDetails,
  AdminProposalDetails,
  ViewAccount,
  Vendors,
  ViewMine,
  AdminAuctions,
  AdminOfferingDetails,
  InvestmentProposal,
  InvestmentOffers,
  SupportAdmin,
  Payments,
  Suspensions,
  AdminTenders,
  AdminTenderDetails,
  AdminInvestments,
  AdminTransactions,
  AdminTransactionInfo,
  AdminProjects,
} from "@pages/admin";
import {
  BaseUrl,
  SignUpUrl,
  ChangePasswordUrl,
  ForgotPasswordUrl,
  CheckEmailUrl,
  PasswordChangedUrl,
  VerifyPhoneUrl,
  VerifyPhoneSuccessUrl,
  SelectCategoryUrl,
  MinerVerificationUrl,
  VendorVerificationUrl,
  OfftakerVerificationUrl,
  AccountVerificationSuccessUrl,
  MinerDemandsUrl,
} from "@config/app-url";
import { Messages as MinersMessage } from "@pages/miners/messaging";
import { Messages as OfftakerMessages } from "@pages/offtakers/messaging";
import { Messages as VendorMessages } from "@pages/vendors/messaging";
import { Messages as AdminMessages } from "@pages/admin/messaging";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// eslint-disable-next-line no-undef
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

import OfftakerTransactions from "pages/offtakers/Interactions/OfftakerTransactions/OfftakerTransactions";
import TransactionDetails from "pages/offtakers/Interactions/OfftakerTransactions/TransactionDetails";
import {
  AddMemberMiner,
  AddMinerRole,
  EditMinerRole,
  Members,
  MinerContactVendor,
  MinerVendorDetails,
  MinerFindVendor,
  MinerRequestService,
  Roles,
  EditMemberMiner,
  MinerOffers,
  MinerOfferDetails,
  MinerInterests,
  MinerInterestDetails,
  MinerProjects,
  MinerProjectDetails,
} from "pages/miners/index";
import Bids from "pages/offtakers/Interactions/OfftakerTransactions/bids";
import {
  AddAdminMine,
  AddAdminRole,
  AddCarrier,
  AddDirectory,
  AddMemberAdmin,
  AddMineral,
  AdminInvestmentDetails,
  AdminMembers,
  AdminProjectDetails,
  AdminRoles,
  AdminVendorDetails,
  Carrier,
  EditAdminRole,
  EditCarrier,
  EditMemberAdmin,
  EditMineral,
  NotificationSettingsAdmin,
  SecurityAdmin,
  SettingsProfileAdmin,
} from "pages/admin/index";

import { toast } from "react-toastify";
import Directory from "pages/admin/directory/index";
import MinersFaq from "pages/miners/support/faq";
import OfftakersFaq from "pages/offtakers/faq";
import VendorFaq from "pages/vendors/support/faq";
import Minerals from "pages/admin/minerals/index";
import Tariff from "pages/admin/tariff/index";

function App() {
  const [uploader, setUploader] = useState(true);
  const [form, setForm] = useState(false);
  const [numberTwo, setNumberTwo] = useState(true);
  const [checkTwo, setCheckTwo] = useState(false);
  const [sidebar, setSideBar] = useState(false);
  const [, setNotification] = useState({ title: "", body: "" });

  fetchToken();
  onMessageListener().then((payload) => {
    toast.info(payload.notification.body, {
      position: "top-center",
      autoClose: 9000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setNotification({
      title: payload.notification.title,
      body: payload.notification.body,
    });
    // browserNotification(payload.notification.title, payload.notification.body);
  });

  // async function browserNotification(title, body) {
  //   console.log("ITS WORKING");
  //   if (Notification.permission == "granted") {
  //     new Notification(`${title}\n${body}`);
  //   } else if (Notification.permission !== "denied") {
  //     Notification.requestPermission().then((permission) => {
  //       if (permission == "granted") {
  //         new Notification(`${title}\n${body}`);
  //       }
  //     });
  //   }
  // }

  return (
    <Router>
      <AuthProvider>
        <UserContext.Provider
          value={{
            uploader,
            setUploader,
            form,
            setForm,
            numberTwo,
            setNumberTwo,
            checkTwo,
            setCheckTwo,
            sidebar,
            setSideBar,
          }}
        >
          <LongLatProvider>
            <AddressProvider>
              <PaginationProvider>
                <BannerProvider>
                  <TermsAndPrivacyProvider>
                    <Routes>
                      {/* onboarding module */}
                      <Route
                        element={
                          <LayoutOnboarding>
                            {" "}
                            <Outlet />{" "}
                          </LayoutOnboarding>
                        }
                      >
                        <Route path={SignUpUrl} element={<SignUp />} />
                        <Route path={BaseUrl} element={<SignIn />} />
                        <Route
                          path={ChangePasswordUrl}
                          element={<ChangePassword />}
                        />
                        <Route
                          path={ForgotPasswordUrl}
                          element={<ForgotPassword />}
                        />
                        <Route path={CheckEmailUrl} element={<CheckEmail />} />
                        <Route
                          path={PasswordChangedUrl}
                          element={<ChangePasswordSuccess />}
                        />
                        <Route
                          path={VerifyPhoneUrl}
                          element={<VerifyPhone />}
                        />
                        <Route
                          path={VerifyPhoneSuccessUrl}
                          element={<VerifyPhoneSuccess />}
                        />
                        <Route
                          path={SelectCategoryUrl}
                          element={<SelectCategory />}
                        />

                        <Route
                          path={MinerVerificationUrl}
                          element={<MinerAccountVerification />}
                        />
                        <Route
                          path={OfftakerVerificationUrl}
                          element={<OfftakerAccountVerification />}
                        />
                        <Route
                          path={VendorVerificationUrl}
                          element={
                            <Elements stripe={stripePromise}>
                              <VendorAccountVerification />
                            </Elements>
                          }
                        />
                        <Route
                          path={AccountVerificationSuccessUrl}
                          element={<SubmissionSuccessful />}
                        />
                      </Route>
                      {/* end of onboarding module */}

                      {/* miners module */}
                      <Route
                        element={
                          <RequireRoleAuth role="miner">
                            {" "}
                            <LayoutMiners>
                              {" "}
                              <Outlet />{" "}
                            </LayoutMiners>{" "}
                          </RequireRoleAuth>
                        }
                      >
                        <Route
                          path="/miner/dashboard"
                          element={<Dashboard />}
                        />
                        <Route path="miner/tracking" element={<Tracking />} />
                        <Route path="miner/mines">
                          <Route index element={<Mines />} />
                          <Route path="add" element={<AddMine />} />
                        </Route>
                        <Route path="miner/inventory">
                          <Route index element={<Inventory />} />
                          <Route
                            path="add"
                            element={
                              <Elements stripe={stripePromise}>
                                <AddProduct />
                              </Elements>
                            }
                          />
                        </Route>
                        <Route path="miner/wallet">
                          <Route index element={<Wallet />} />
                        </Route>
                        {/* <Route path="miner/carrier">
												<Route index element={<Carrier />} />
												<Route path="add" element={<AddCarrier />} />
												<Route path="edit" element={<EditCarrier />} />
											</Route> */}
                        <Route path="miner/teams">
                          <Route path="member">
                            <Route index element={<Members />} />
                            <Route path="add" element={<AddMemberMiner />} />
                            <Route
                              path="edit/:id"
                              element={<EditMemberMiner />}
                            />
                          </Route>
                          <Route path="role">
                            <Route index element={<Roles />} />
                            <Route path="add" element={<AddMinerRole />} />
                            <Route
                              path="edit/:id"
                              element={<EditMinerRole />}
                            />
                          </Route>
                        </Route>
                        <Route path="miner/interactions">
                          <Route path="tenders">
                            <Route index element={<Tenders />} />
                            <Route
                              path="details/:id"
                              element={<TendersDetails />}
                            />
                          </Route>
                          <Route path="transactions">
                            <Route index element={<Transactions />} />
                            <Route
                              path="information/:id"
                              element={<TransactionInfo />}
                            >
                              {/* <Route
                              path=":status"
                              element={<TransactionInfo />}
                            />
                            <Route /> */}
                            </Route>
                          </Route>
                          <Route path="investments">
                            <Route index element={<MinerInvestments />} />
                            <Route
                              path="details"
                              element={<MinerInvestmentDetails />}
                            />
                            <Route
                              path="details/investor"
                              element={
                                <Elements stripe={stripePromise}>
                                  <MinerInvestorDetails />
                                </Elements>
                              }
                            />
                          </Route>

                          <Route path="projects">
                            <Route index element={<MinerProjects />} />
                            <Route
                              path=":id"
                              element={<MinerProjectDetails />}
                            />
                          </Route>
                          <Route path="offers">
                            <Route index element={<MinerOffers />} />
                            <Route path=":id">
                              <Route index element={<MinerOfferDetails />} />
                              <Route path="interests">
                                <Route index element={<MinerInterests />} />
                                <Route
                                  path=":id"
                                  element={<MinerInterestDetails />}
                                />
                              </Route>
                            </Route>
                          </Route>
                        </Route>

                        <Route path="miner/auctions">
                          <Route index element={<Auctions />} />
                          <Route path=":id" element={<AuctionDetails />} />
                        </Route>
                        <Route path={MinerDemandsUrl()}>
                          <Route index element={<MinerDemands />} />
                          <Route
                            path=":id"
                            element={
                              <Elements stripe={stripePromise}>
                                <MinerDemandsDetails />
                              </Elements>
                            }
                          />
                          <Route
                            path="supply"
                            element={<MinerDemandsCreateSupply />}
                          />
                        </Route>
                        <Route path="miner/investors">
                          <Route path="offerings">
                            <Route index element={<Investors />} />
                            <Route
                              path="details"
                              element={<OfferingDetails />}
                            />
                            <Route path="apply" element={<ApplyToOffering />} />
                          </Route>
                          <Route path="fundings">
                            <Route index element={<MinerFundings />} />
                            <Route
                              path="seek-funding"
                              element={<SeekFunding />}
                            />
                            <Route
                              path="details"
                              element={<MinerFundingDetails />}
                            />
                            <Route
                              path="manage_interests"
                              element={<MinerManageFundingDetails />}
                            />
                            <Route
                              path="manage_interests/:id"
                              element={<MinerFundingInterestDetails />}
                            />
                          </Route>
                        </Route>
                        <Route path="miner/vendors">
                          <Route path="find_a_vendor">
                            <Route index element={<MinerFindVendor />} />
                            <Route
                              path="profile/:id"
                              element={<MinerVendorDetails />}
                            />
                            <Route
                              path="contactvendor/:id"
                              element={<MinerContactVendor />}
                            />
                          </Route>
                          <Route path="request_a_service">
                            <Route index element={<MinerRequestService />} />
                          </Route>
                        </Route>

                        <Route path="miner/support" element={<Support />} />
                        <Route
                          path="miner/support/faq"
                          element={<MinersFaq />}
                        />
                        <Route path="miner/settings">
                          <Route index element={<SettingsProfile />} />
                          <Route
                            path="notification"
                            element={<NotificationSettings />}
                          />
                          <Route path="security" element={<Security />} />
                        </Route>
                        <Route
                          path="miner/messages"
                          element={<MinersMessage />}
                        />
                      </Route>
                      {/* end of miners module */}

                      {/* OFFTAKERS MODULE */}
                      <Route
                        element={
                          <RequireRoleAuth role="off-taker">
                            {" "}
                            <LayoutOfftaker>
                              {" "}
                              <Outlet />{" "}
                            </LayoutOfftaker>{" "}
                          </RequireRoleAuth>
                        }
                      >
                        <Route path="off-taker/interactions">
                          <Route path="transactions">
                            <Route index element={<OfftakerTransactions />} />
                            <Route
                              path="information"
                              // element={<TransactionDetails />}
                              element={
                                <Elements stripe={stripePromise}>
                                  <TransactionDetails />
                                </Elements>
                              }
                            />
                          </Route>
                          <Route path="bids" element={<Bids />} />
                          <Route path="investments">
                            <Route index element={<OfftakerInvestments />} />
                            <Route
                              path="view_details"
                              element={<OfftakerInvestmentDetails />}
                            />
                            <Route
                              path=":id/manage_interests"
                              element={<OfftakerInterests />}
                            />
                            <Route
                              path=":id/manage_interests/:interestId"
                              element={<OfftakerInterestDetails />}
                            />
                          </Route>
                          <Route path="projects">
                            <Route index element={<OfftakerProjects />} />
                            <Route
                              path=":id"
                              element={<OfftakerProjectDetails />}
                            />
                          </Route>
                          <Route path="offers">
                            <Route index element={<OfftakersOffers />} />
                            <Route path=":id">
                              <Route
                                index
                                element={<OfftakersOfferDetails />}
                              />
                              <Route path="interests">
                                <Route
                                  index
                                  element={<OfftakerOfferInterests />}
                                />
                                <Route
                                  path=":id"
                                  element={<OfftakerOfferInterestDetails />}
                                />
                              </Route>
                            </Route>
                          </Route>
                        </Route>
                        <Route path="off-taker/wallet">
                          <Route index element={<Wallet />} />
                        </Route>
                        <Route
                          path="off-taker/interactions/bids"
                          element={<Bids />}
                        />
                        <Route path="off-taker/invest">
                          <Route
                            path="find_prospects"
                            element={<FindProspects />}
                          />
                          <Route path="prospects">
                            <Route index element={<Prospects />} />
                            <Route
                              path="view_details"
                              element={<ProspectDetails />}
                            />
                            <Route
                              path="manage_interests"
                              element={<ProspectInterests />}
                            />
                            <Route
                              path="manage_interests/:id"
                              element={<ProspectInterestDetails />}
                            />
                            <Route
                              path="manage_interests/accept_proposal"
                              element={<ProspectInterestAcceptance />}
                            />
                          </Route>
                          <Route path="proposals">
                            <Route index element={<OfftakerProposals />} />
                            <Route
                              path="view_details"
                              element={<ProposalDetails />}
                            />
                            <Route
                              path="indicate_interest"
                              element={<IndicateInterest />}
                            />
                          </Route>
                        </Route>
                        <Route path="off-taker/teams">
                          <Route path="member">
                            <Route index element={<OfftakerMembers />} />
                            <Route path="add" element={<AddmemberOfftaker />} />
                            <Route
                              path="edit/:id"
                              element={<EditMemberOfftaker />}
                            />
                          </Route>
                          <Route path="role">
                            <Route index element={<OfftakerRoles />} />
                            <Route path="add" element={<AddOfftakerRole />} />
                            <Route
                              path="edit/:id"
                              element={<EditOfftakerRole />}
                            />
                          </Route>
                        </Route>
                        <Route path="off-taker/vendors">
                          <Route path="find_a_vendor">
                            <Route index element={<OfftakerFindVendor />} />
                            <Route
                              path="profile/:id"
                              element={<OfftakerVendorDetails />}
                            />
                            <Route
                              path="contactvendor/:id"
                              element={<OfftakerContactVendor />}
                            />
                          </Route>
                          <Route path="request_a_service">
                            <Route
                              index
                              element={<OfftakerVendorRequestService />}
                            />
                          </Route>
                        </Route>
                        <Route
                          path="off-taker/dashboard"
                          element={<OffTakerDashboard />}
                        />
                        <Route
                          path="off-taker/tracking"
                          element={<OfftakerTracking />}
                        />
                        <Route
                          path="off-taker/auctions"
                          element={<OfftakerAuctions />}
                        />
                        <Route
                          path="off-taker/auctions/:id/"
                          element={<OfftakersAuctionDetails />}
                        />

                        <Route
                          path="off-taker/support"
                          element={<OfftakerSupport />}
                        />
                        <Route
                          path="off-taker/support/faq"
                          element={<OfftakersFaq />}
                        />
                        <Route path="off-taker/settings">
                          <Route index element={<OfftakerSettingProfile />} />
                          <Route
                            path="notification"
                            element={<OfftakerNotification />}
                          />
                          <Route
                            path="security"
                            element={<OfftakerSecurity />}
                          />
                        </Route>
                        <Route path="off-taker/demands">
                          <Route index element={<DemandsList />} />
                          <Route path="new" element={<Demands />} />
                          <Route path="details" element={<DemandsDetails />} />
                          <Route
                            path="interests"
                            element={<DemandInterests />}
                          />
                          <Route
                            path="interest"
                            element={<DemandInterestDetails />}
                          />
                        </Route>
                        <Route
                          path="off-taker/messages"
                          element={<OfftakerMessages />}
                        />
                      </Route>

                      {/* end of offtakers module */}

                      {/* vendors module */}
                      <Route
                        element={
                          <RequireRoleAuth role="vendor">
                            <LayoutVendors>
                              <Outlet />
                            </LayoutVendors>
                          </RequireRoleAuth>
                        }
                      >
                        <Route
                          path="/vendor/dashboard"
                          element={<VendorDashboard />}
                        />

                        <Route path="vendor/offerings">
                          <Route index element={<VendorOfferings />} />
                          <Route path="add" element={<VendorAddProduct />} />
                          <Route index element={<VendorOfferings />} />
                          <Route path="add" element={<VendorAddProduct />} />
                          <Route
                            path="modify/:id"
                            element={<ModifyOffering />}
                          />
                          <Route
                            path="details/:id"
                            element={<VendorOfferingDetails />}
                          />
                        </Route>
                        <Route path="vendor/interactions">
                          <Route path="offers">
                            <Route index element={<VendorOffers />} />
                            <Route path=":id">
                              <Route index element={<VendorOfferDetails />} />
                              <Route path="interests">
                                <Route index element={<VendorInterests />} />
                                <Route
                                  path=":id"
                                  element={<VendorInterestDetails />}
                                />
                              </Route>
                            </Route>
                          </Route>
                          <Route path="projects">
                            <Route index element={<VendorProjects />} />
                            <Route
                              path=":id"
                              element={<VendorProjectDetails />}
                            />
                          </Route>
                        </Route>

                        <Route path="vendor/offerings">
                          <Route index element={<VendorOfferings />} />
                          <Route path="add" element={<VendorAddProduct />} />
                          <Route
                            path="modify/:id"
                            element={<ModifyOffering />}
                          />
                          <Route
                            path="details/:id"
                            element={<VendorOfferingDetails />}
                          />
                        </Route>
                        <Route path="vendor/wallet">
                          <Route index element={<Wallet />} />
                        </Route>
                        <Route
                          path="vendor/subscription"
                          element={
                            <Elements stripe={stripePromise}>
                              <Subscription />
                            </Elements>
                          }
                        ></Route>
                        <Route path="vendor/vendors">
                          <Route path="find_a_vendor">
                            <Route index element={<VendorFindVendor />} />
                            <Route
                              path="profile/:id"
                              element={<VendorDetails />}
                            />

                            <Route
                              path="contactvendor/:id"
                              element={<ContactVendor />}
                            />
                          </Route>
                          <Route path="request_a_service">
                            <Route index element={<VendorRequestService />} />
                          </Route>
                        </Route>
                        <Route path="vendor/requests">
                          <Route index element={<ServiceRequests />} />
                          <Route
                            path="request/:id"
                            element={<RequestDetails />}
                          />
                        </Route>
                        <Route path="vendor/teams">
                          <Route path="member">
                            <Route index element={<TeamMember />} />
                            <Route path="add" element={<AddMember />} />
                            <Route
                              path="edit/:id"
                              element={<EditMemberVendor />}
                            />
                          </Route>
                          <Route path="role">
                            <Route index element={<TeamRole />} />
                            <Route path="add" element={<AddRole />} />
                            <Route
                              path="edit/:id"
                              element={<EditvendorRole />}
                            />
                          </Route>
                        </Route>
                        <Route
                          path="vendor/support"
                          element={<VendorSupport />}
                        />
                        <Route
                          path="vendor/support/faq"
                          element={<VendorFaq />}
                        />
                        <Route path="vendor/settings">
                          <Route index element={<VendorSettingsProfile />} />
                          <Route
                            path="notification"
                            element={<VendorNotificationSettings />}
                          />
                          <Route path="security" element={<VendorSecurity />} />
                        </Route>
                        <Route
                          path="vendor/messages"
                          element={<VendorMessages />}
                        />
                      </Route>
                      {/* end of vendors module */}

                      {/* Admin module */}
                      <Route
                        element={
                          <LayoutAdmin>
                            <Outlet />
                          </LayoutAdmin>
                        }
                      >
                        <Route
                          path="/admin/dashboard"
                          element={<AdminDashboard />}
                        />
                        <Route path="admin/">
                          <Route index element={<ProfileApproval />} />
                          <Route path="approval-requests">
                            <Route index element={<ProfileApproval />} />
                            <Route
                              path="manage-user"
                              element={<ManageUser />}
                            />
                          </Route>
                          <Route path="users">
                            <Route index element={<User />} />
                            <Route
                              path="register-user"
                              element={<RegisterUser />}
                            />
                            <Route
                              path="account-information/:id"
                              element={<ViewAccount />}
                            />
                            <Route
                              path="manage-miner"
                              element={<ManageMiner />}
                            />
                            <Route
                              path="manage-offtaker"
                              element={<ManageOfftaker />}
                            />
                            <Route
                              path="manage-vendor"
                              element={<ManageVendor />}
                            />
                          </Route>
                          <Route path="teams">
                            <Route path="member">
                              <Route index element={<AdminMembers />} />
                              <Route path="add" element={<AddMemberAdmin />} />
                              <Route
                                path="edit/:id"
                                element={<EditMemberAdmin />}
                              />
                            </Route>

                            <Route path="role">
                              <Route index element={<AdminRoles />} />
                              <Route path="add" element={<AddAdminRole />} />
                              <Route
                                path="edit/:id"
                                element={<EditAdminRole />}
                              />
                            </Route>
                          </Route>


                          <Route path="listings">
                            <Route index element={<Listings />} />
                            <Route path="mines" element={<Listings />} />
                            <Route path="mine-details" element={<ViewMine />} />
                            <Route path="edit-mine" element={<EditMine />} />
                            <Route path="add" element={<AddAdminMine />} />
                            <Route
                              path="auctions"
                              element={<AdminAuctions />}
                            />
                            <Route
                              path="auctions"
                              element={<AdminAuctions />}
                            />
                            <Route
                              path="auction-details"
                              element={<AdminAuctionDetails />}
                            />
                            <Route
                              path="investment-offers"
                              element={<InvestmentOffers />}
                            />
                            <Route
                              path="offering-details"
                              element={<AdminOfferingDetails />}
                            />
                            <Route
                              path="vendor-details"
                              element={<AdminVendorDetails />}
                            />
                            <Route
                              path="investment-proposals"
                              element={<InvestmentProposal />}
                            />
                            <Route
                              path="proposal-details"
                              element={<AdminProposalDetails />}
                            />
                            <Route path="vendors" element={<Vendors />} />
                          </Route>

                          <Route path="interactions">
                            <Route path="tenders">
                              <Route index element={<AdminTenders />} />
                              <Route
                                path={"details/:id"}
                                element={<AdminTenderDetails />}
                              />
                            </Route>

                            <Route path="projects">
                              <Route index element={<AdminProjects />} />
                              <Route
                                path="details"
                                element={<AdminProjectDetails />}
                              />
                            </Route>

                            <Route path="investments">
                              <Route index element={<AdminInvestments />} />
                              <Route
                                path="details"
                                element={<AdminInvestmentDetails />}
                              />
                            </Route>

                            <Route path="transactions">
                              <Route index element={<AdminTransactions />} />
                              <Route
                                path="information/:id"
                                element={<AdminTransactionInfo />}
                              ></Route>
                            </Route>
                          </Route>
                        </Route>
                        <Route path="admin/payments" element={<Payments />} />
                        <Route path="admin/minerals" element={<Minerals />} />
                        <Route path="admin/add-mineral" element={<AddMineral />} />
                        <Route path="admin/edit-mineral" element={<EditMineral />} />
                        <Route path="admin/tariff" element={<Tariff />} />
                        <Route path="admin/directory">
                          <Route index element={<Directory />} />
                          <Route path="add" element={<AddDirectory />} />
                        </Route>

                        <Route
                          path="admin/support"
                          element={<SupportAdmin />}
                        />
                        <Route path="admin/settings">
                          <Route index element={<SettingsProfileAdmin />} />
                          <Route
                            path="notification"
                            element={<NotificationSettingsAdmin />}
                          />
                          <Route path="security" element={<SecurityAdmin />} />
                        </Route>
                        <Route path="admin/carrier">
                          <Route index element={<Carrier />} />
                          <Route path="add" element={<AddCarrier />} />
                          <Route path="edit" element={<EditCarrier />} />
                        </Route>
                        <Route
                          path="admin/suspensions"
                          element={<Suspensions />}
                        />
                        <Route
                          path="admin/messages"
                          element={<AdminMessages />}
                        />
                      </Route>
                      {/* end of admin module */}
                    </Routes>
                  </TermsAndPrivacyProvider>
                </BannerProvider>
              </PaginationProvider>
            </AddressProvider>
          </LongLatProvider>
        </UserContext.Provider>
      </AuthProvider>
    </Router>
  );
}
export default App;
