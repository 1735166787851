/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Pagination, ItemsPerPage } from '@ui';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProposals } from 'store/slices/offtaker-slice';
import { toast } from 'react-toastify';

const Proposals = () => {
	const [items] = useState([...Array(33).keys()]);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		dispatch(fetchAllProposals());
	}, []);

	useEffect(() => {
		if (location.state && location.state.message) {
			toast.success(location.state.message);
		}
	}, []);

	const {
		investmentProposals: { data: proposals }
	} = useSelector((store) => store?.offtakers);
	
	return (
		<div>
			{/* searchbar and buttons */}
			<div className="flex justify-between">
				<div className="text-[28px]">Available investment proposals</div>
			</div>
			{/* main */}

			<div className="grid lg:grid-cols-3 sm:grid gap-[30px] mt-5 font-default">
				{proposals?.map((proposal, index) => (
					<Card key={index + 1}>
						<img
							src={proposal?.product_image[0]?.url}
							alt="daniola product card image"
							className="w-full h-52 object-cover"
						/>
						<h3 className="text-2xl text-heading mt-4">
							{proposal.product_name}
						</h3>
						<p className="text-sm text-light text-left font-light capitalize">
							{proposal.mineral?.slug}
						</p>

						<div className="flex gap-2 text-tertiary text-sm font-light text-left mb-6">
							<p>{proposal.mine?.name}</p>
						</div>

						<div className="grid grid-cols-5 gap-y-2 mb-6">
							<>
								<div className="col-span-2 font-light text-ts text-sm">
									<p>Loan type</p>
								</div>
								<div className="col-span-3 font-light text-light">
									<p>{proposal.investment_type?.slug}</p>
								</div>

								<div className="col-span-2 font-light text-ts text-sm">
									<p>Investment amount</p>
								</div>
								<div className="col-span-3 font-light text-light">
									<p>USD {proposal?.amount}</p>
								</div>

								<div className="col-span-2 font-light text-ts text-sm">
									<p>Quantity</p>
								</div>
								<div className="col-span-3 font-light text-light">
									<p>{proposal.quantity} dmT</p>
								</div>

								<div className="col-span-2 font-light text-ts text-sm">
									<p>Price</p>
								</div>
								<div className="col-span-3 font-light text-light">
									<p>{proposal.price} /dmT</p>
								</div>
							</>
						</div>

						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								navigate('/off-taker/invest/proposals/view_details', {
									state: { id: proposal._id }
								});
							}}
							fullWidth
						>
							View details
						</Button>
					</Card>
				))}
			</div>
			<div className="flex items-center justify-between mt-4">
				<ItemsPerPage options={[9, 30]} />
				<Pagination className="flex gap-2" items={items} />
			</div>
		</div>
	);
};

export default Proposals;
