/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getInitials } from "@shared/utility";
import { Button, Card, ControlledModal, TextArea, Chips } from "@ui";
import { LocationArrow } from "@ui/icons";

const VendorItem = ({ vendor, handleOpen }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <div className="flex flex-col justify-start items-start  left-[23px] top-[23px] gap-4">
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 w-[206px] h-[66px]">
          <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill mr-2">
            {vendor?.profile_img?.length > 0 ? (
              <img
                className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                src={`${vendor?.profile_img[0]?.url}`}
                alt=""
              />
            ) : (
              `${getInitials(vendor?.company_name)}`
            )}
          </div>
          <div className="flex flex-col justify-start items-start flex-grow-0 ml-4 flex-shrink-0 ">
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-base text-left text-primary">
              {vendor?.company_name}
            </p>
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-sm text-left font-normal text-[#18273a]/[0.94]">
              {vendor?.industry_name}
            </p>
            <div className="flex flex-row gap-2 text-sm font-light text-left text-[#1b2b41]/[0.72]">
              <LocationArrow />
              {vendor?.country?.name}
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[307px]  ">
          <div className="flex flex-row justify-between mb-4 text-sm  ">
            {vendor?.job_description}
          </div>
          <div className="col-span-3 font-light text-light">
            <p className="flex flex-row flex-wrap gap-2 mb-4">
              <Chips chips={vendor?.keywords} noIcons />
            </p>{" "}
          </div>
        </div>
      </div>
      {/* button */}
      <div className="flex flex-col gap-6">
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            navigate("../vendor-details", {
              state: vendor,
            })
          }
          fullWidth
        >
          View details
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            handleOpen(vendor);
          }}
          fullWidth
        >
          Archive
        </Button>
      </div>
    </Card>
  );
};

export default VendorItem;
