/* eslint-disable no-unused-vars */
/** @format */

import React, { useState } from "react";
import { getInitials } from "@shared/utility";
import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import { SuccessModal } from "./SuccessModal";
import { deletemember } from "@slices/roles-permissions-slice";
import { useDispatch } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

// eslint-disable-next-line react/prop-types
export const DeleteMemberModal = ({ setOpen, open = null, data = null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const deleteMember = async () => {
    try {
      const response = await dispatch(deletemember(data?._id));
      if (response.payload.status === 200) {
        setOpen(false);
        setIsLoading(true);
      } else {
        console.log("error from then", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center mb-4">
            <h1 className="text-xl mb-4 text-[#FF4E44]">Warning !</h1>
            <div className="grid justify-items-center">
              {data?.accounts[0]?.profile_img?.length > 0 ? (
                <img
                  className="rounded-circle w-[71px] h-[71px] mr-3"
                  src={`${data?.accounts[0]?.profile_img[0]?.url}`}
                  alt=""
                />
              ) : (
                <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill mr-3">
                  {getInitials(data?.name)}
                </div>
              )}
            </div>

            {/* <h1 className="text-xl mb-4">Role creation successfull</h1> */}
            <p className="font-light mt-4">
              Are you sure you want to remove{" "}
              <span className="text-[#6166B6]">{data?.name}</span> from your
              team
            </p>
            <div className="flex items-center justify-between mt-8">
              <button
                onClick={() => setOpen(!open)}
                className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
              >
                Cancel
              </button>
              <div
                onClick={() => {
                  deleteMember();
                }}
              >
                <Button> {isLoading ? "Processing..." : "Delete"}</Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <div>
        <SuccessModal
          open={isLoading}
          setIsLoading={setIsLoading}
          data={data}
        />
      </div>
    </>
  );
};
