/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { TrackingMap } from "@pages/onboarding";
import {
  getShipmentActive,
  getShipmentArchive,
  getTrackingDetails,
} from "@slices/tracking-slice";
import { toast } from "react-toastify";
import { SearchIcon, Expandmoreicon } from "@ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Paper,
  Grid,
} from "@mui/material";
import { Card, Liner, Timeline, Search } from "@ui";
import dayjs from "dayjs";
const Styles = {
  root: {
    width: "100%",
  },
  expandedPanel: {
    backgroundColor: "#6166B6",
    color: "white",
    mb: "20rem",
  },
  bg: {
    backgroundColor: "#EFF0FE",
  },

  dividerColor: {
    backgroundColor: "#1C375A29",
  },
  // container:{
  //   height:"calc(100vh - 16rem)",
  // }
};

const OfftakerTracking = () => {
  const location = {
    address: "1600 Amphitheatre Parkway, Mountain View, california.",
    lat: 37.42216,
    lng: -122.08427,
  };
  const shipmentData = useSelector((state) => state.tracking);

  return (
    <Card>
      <Grid container>
        <Grid item xs={4} className="p-2">
          <Paper variant="outlined" elevation={0}>
            <div className="flex gap-2 m-2 mb-4">
              <div className="w-[548px]">
                <Search placeholder="Search Transaction ID" />
              </div>
              {/* bg-primary */}
              <div className=" bg-[#6166B6] flex items-center justify-center rounded py-1 px-2">
                <SearchIcon color="#FFF" opacity="1" />
              </div>
            </div>
            <TrackingTab />
          </Paper>
        </Grid>
        <Grid item xs={8} className="p-2">
          <Paper variant="outlined" className="p-2 " elevation={0}>
            <Box className="relative">
              <div className="h-[calc(100vh-12rem)]">
                <TrackingMap
                  location={location}
                  zoomLevel={17}
                  shipment={shipmentData}
                />
              </div>

              {/* <img
                className="h-[calc(100vh-12rem)]"
                src="/static/media/Map.71edd90baa0dec6db37d.png"
                alt="world map"
              /> */}
              {/* <div className="flex gap-2 m-2 absolute top-0 left-0">
                <div className="w-[530px] md:w-auto">
                  <div className="relative w-full">
                    <input
                      type="text"
                      className="h-11 w-full pl-10 border-none pr-20 bg-white rounded-[4px] z-0 focus:shadow focus:outline-none focus:ring-primary placeholder:text-[#09192e42] placeholder:font-extralight placeholder:text-base"
                      placeholder="Search Location"
                    />
                  </div>
                </div>
                <div className="bg-[#50aaa7] flex items-center justify-center rounded py-1 px-2">
                  <SearchIcon color="#FFF" opacity="1" />
                </div>
              </div> */}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Card>
  );
};

function render(shipment) {
  return (
    <Card>
      <Grid container>
        <Grid item xs={4} className="p-2">
          <Paper variant="outlined" elevation={0}>
            <div className="flex gap-2 m-2 mb-4">
              <div className="w-[548px]">
                <Search placeholder="Search Transaction ID" />
              </div>
              {/* bg-primary */}
              <div className=" bg-[#6166B6] flex items-center justify-center rounded py-1 px-2">
                <SearchIcon color="#FFF" opacity="1" />
              </div>
            </div>
            <TrackingTab />
          </Paper>
        </Grid>
        <Grid item xs={8} className="p-2">
          <Paper variant="outlined" className="p-2 " elevation={0}>
            <Box className="relative">
              <div className="h-[calc(100vh-12rem)]">
                <TrackingMap shipment={shipment} />
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Card>
  );
}

function TabPanel(props) {
  const { shipmentObject, children, value, index, ...other } = props;
  const shipmentData = useSelector((state) => state.tracking);
  const dispatch = useDispatch();
  const getTrackingDetailsbyID = (shipment) => {
    dispatch(
      getTrackingDetails({
        id: shipment?.shipment?.tracking_id,
        _id: shipment._id,
      })
    );
  };
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="grid grid-cols-1 divide-y">
          <Accordion
            onChange={(e, expanded) => {
              if (expanded) {
                getTrackingDetailsbyID(shipmentObject);
              }
            }}
            style={{ boxShadow: "none" }}
            sx={{
              "& .Mui-expanded": {
                backgroundColor: "#6166B6",
                color: "white",
                "& p": {
                  color: "white !important",
                  fontWeight: "300 !important",
                },
                "& .MuiLinearProgress-bar1Determinate": {
                  backgroundColor: "white",
                },
                "& .MuiLinearProgress-determinate ": {
                  backgroundColor: "#e0e0e0",
                },
              },
            }}
          >
            <AccordionSummary
              style={{ alignItems: "start", paddingTop: "10px" }}
              expandIcon={<Expandmoreicon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box className="flex flex-col w-full m-0">
                <Box className="mb-2  ">{children}</Box>
                <Divider />
                <Box className="mb-3">
                  <Liner
                    value={shipmentObject}
                    style={{ marginTop: "0.8rem" }}
                  ></Liner>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails styles={Styles.bg}>
              <div className="flex flex-col w-full ">
                <Grid container className="mb-3">
                  <Grid item xs={8}>
                    <div styles={Styles.bg}>
                      <div>
                        <p className="text-sm text-gray">ATD:</p>
                        <p className="text-xs text-light ">
                          {dayjs(shipmentObject?.atd).format(
                            "MMMM D, YYYY h:mm A"
                          )}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="text-sm text-gray">ETA:</p>
                        <p className="text-xs text-light ">
                          {dayjs(shipmentObject?.eta).format(
                            "MMMM D, YYYY h:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div styles={Styles.bg}>
                      <div>
                        <p className="text-sm text-gray">Carrier Name:</p>
                        <p className="text-xs text-light ">
                          {shipmentObject?.shipment?.carrier_id?.name}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="text-sm text-gray">Status:</p>
                        <p className="text-xs text-light ">
                          {shipmentObject?.shipment?.shipment_status}
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Divider variant="middle" />
                {/* max-h-52 overflow-y-scroll */}
                <div className="mt-5 max-h-60 overflow-y-scroll ">
                  <Timeline active="true" tracking={shipmentData}></Timeline>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Divider styles={Styles.dividerColor} />
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  shipmentObject: PropTypes.any,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TrackingTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const shipmentData = useSelector((state) => state.tracking);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getShipmentActive(), (error) => {
      toast.error(error);
    });
    dispatch(getShipmentArchive(), (error) => {
      toast.error(error);
    });
  }, [dispatch]);

  return (
    <Box className="w-full">
      <Box className="pl-1 pr-1 border-b border-slate-100">
        <Tabs
          textColor="secondary"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={`Active (${shipmentData?.active_data?.length})`}
            {...a11yProps(0)}
          />
          <Tab
            label={`Archive (${shipmentData?.archive_data?.length})`}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      {shipmentData.active_data.length > 0 &&
        shipmentData?.active_data?.map((shipment, index) => (
          <TabPanel
            value={value}
            key={shipment?._id}
            shipmentObject={shipment}
            index={0}
          >
            <span className="text-gray font-medium"> Transaction ID:</span>
            <span className="font-medium mx-1">
              &nbsp; {shipment?.transaction_id}
            </span>
          </TabPanel>
        ))}

      {shipmentData?.archive_data.length > 0 &&
        shipmentData?.archive_data?.map((shipment, index) => (
          <TabPanel
            value={value}
            key={shipment?._id}
            shipmentObject={shipment}
            index={1}
          >
            <span className="text-gray font-medium"> Transaction ID:</span>
            <span className="font-medium mx-1">
              &nbsp; {shipment?.transaction_id}
            </span>
          </TabPanel>
        ))}

      {/* <TabPanel value={value} index={1}>
        <span className="text-gray font-medium"> Transaction ID:</span>
        <span className="font-medium mx-1">&nbsp; F345TYUT</span>
      </TabPanel> */}
    </Box>
  );
}
export default OfftakerTracking;
