import React, { useState } from "react";
import MessageSearch from "components/messaging/message_search";
import MessageTabs from "components/messaging/message_tabs";
import * as message_slice from "store/slices/messages.slice";
// import ChatBubble from 'components/messaging/chat_bubble';
import ChatContainer from "components/messaging/chat_container";
// import { useSelector } from "react-redux";

const Messages = () => {
  //const { user_messages } = useSelector((state) =>  state?.messages_data?.data.displayed_messages);
  const data = message_slice.get_all_messages().payload.data;
  const [chat_content, setChatContent] = useState(data.displayed_messages);

  const handleChatChange = (chat) => {
    // console.log(chat)
    //const chat_user_id = chat.payload.data[0].userid
    // console.log(chat.payload.data[0].chats, "chat messages")
    const top_content = {
      userid: chat.payload.data[0].userid,
      name: chat.payload.data[0].name,
      avatar: chat.payload.data[0].avatar,
    };
    // const chat_content = chat.payload.data[0].chats.map((msg, indx)=>{
    //     return (<ChatBubble key={indx} message={msg.message} time={msg.message_date} bgcolor='#fff' />)
    // })
    setChatContent(
      <ChatContainer
        top_content={top_content}
        chat_list={chat.payload.data[0].chats}
      />
    );
  };
  return (
    <div className="message-layout flex flex-row h-">
      <div className="message-left-side-div sm:w-[100%]">
        <div className="search-div">
          <MessageSearch placeholder=" Search Chat" />
        </div>
        <MessageTabs
          all_messages={data.all_messages}
          archived_messages={data.archived_messages}
          unread_messages={data.unread_messages}
          chat_change={handleChatChange}
        />
      </div>
      <div className="message-right-side-div sm:hidden">{chat_content}</div>
    </div>
  );
};

export default Messages;
