/* eslint-disable no-unused-vars */
import React from 'react';
import { Btn as Button } from '@atoms/button/button';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getSingleProposal } from 'store/slices/offtaker-slice';
import DocumentIcon from 'components/ui/icons/DocumentIcon';

const ProposalDetails = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { id } = location.state;

	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(getSingleProposal(id));
	}, []);

	const { proposalDetails } = useSelector((store) => store?.offtakers);

	return (
		<>
			<div className="text-default w-full bg-white px-[34px] p-[30px]">
				<div className="flex justify-between items-start">
					<div className="flex justify-start items-start">
						<div className="w-[331px] h-[217px] flex justify-center align-center items-center text-2xl text-primary  bg-fill">
							<img
								src={
									proposalDetails?.product_image
										? proposalDetails.product_image[0].url
										: ''
								}
								alt="Product"
								className="w-full h-full rounded-[6px]"
							/>
						</div>
						<div className="flex flex-col justify-start items-start flex-grow-0 ml-8 flex-shrink-0 ">
							<h3 className="mb-1 text-2xl">{proposalDetails?.product_name}</h3>
							<span className="mb-1 text-lg text-primary">
								{proposalDetails?.mineral?.name}
							</span>
							<span className="mb-1 text-base text-primary font-light text-lightgray/[0.72]">
								{proposalDetails?.mine?.mine_name}
							</span>
						</div>
					</div>

					<Button
						onClick={() =>
							navigate(`/off-taker/invest/proposals/indicate_interest`, {
								state: {
									id
								}
							})
						}
						size="small"
					>
						Indicate interest
					</Button>
				</div>

				<div className="flex flex-col justify-start items-start flex-grow-0 mt-6  flex-shrink-0">
					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Quantity:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94]">
							{proposalDetails?.quantity} dmT
						</p>
					</div>
					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Company name:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94]">
							{proposalDetails?.mine?.company_name}
						</p>
					</div>
					{/* <div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Address:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94]">
							{proposalDetails?.address || '-'}
						</p>
					</div> */}
					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Additional information:
						</p>
						<p className="flex-1 text-base font-light text-left text-[#18273a]/[0.94]">
							{proposalDetails?.additional_information || '-'}
						</p>
					</div>

					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Documentation:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94] flex items-center">
							{proposalDetails?.documents?.map((item, index) => (
								<a
									key={index}
									download
									target="_blank"
									rel="noreferrer"
									href={item.url}
									className=" flex items-center"
								>
									<DocumentIcon />
									<span className="text-primary text-sm font-light ml-2">
										{item.name}
									</span>
								</a>
							))}
						</p>
					</div>
				</div>
			</div>
			<div className="w-full bg-white px-[34px] p-[30px] mt-3">
				<h3 className="text-2xl mb-4">Proposal:</h3>
				<p className="text-base font-light text-[#18273a]/[0.94]">
				{proposalDetails?.proposal}				</p>
			</div>
		</>
	);
};

export default ProposalDetails;
