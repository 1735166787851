/* eslint-disable react/prop-types */
const Card = ({ children, noPadding, ...rest }) => {
  return (
    <div
      className={`border border-solid border-dark-gray-100 ${
        !noPadding && "py-5"
      } px-6 rounded-md bg-white ${rest.className}`}
    >
      {children}
    </div>
  );
};

export default Card;
