/* eslint-disable no-unused-vars */
import Button from "components/ui/Button";
import SearchIcon from "components/ui/icons/SearchIcon";
import SlidersIcon from "components/ui/icons/SlidersIcon";
import Search from "components/ui/Search";
import VendorSearch from "components/ui/VendorSearch";
import React, { useEffect, useState } from "react";
import { Rating } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import {
  getServiceRequests,
  searchServiceRequest,
} from "store/slices/vendors/service_request-slice";
import { getInitials } from "@shared/utility";
import { truncate } from "shared/utility";

function ServiceRequests() {
  const { requests } = useSelector((store) => store.vendorServiceRequests);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState();
  const [countryParams, setCountryParams] = useState();

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(
      searchServiceRequest({
        search: searchParams,
        country: countryParams,
      })
    );
  };
  const handleSearchWhat = (e) => {
    if (e.target.value == "") {
      dispatch(getServiceRequests());
    }
    setSearchParams(e.target.value);
  };

  const handleSearchWhere = (e) => {
    if (e.target.value == "") {
      dispatch(getServiceRequests());
    }
    setCountryParams(e.target.value);
  };

  useEffect(() => {
    dispatch(getServiceRequests());
    // console.log(vendors);
  }, []);

  // const checkVendors = () => {
  //   console.log(vendors);
  // };
  return (
    <div>
      <div className="flex">
        <div className="flex flex-7 ">
          <form onSubmit={(e) => handleSearchSubmit(e)} className="flex flex-7">
            <div className="flex items-center relative mr-4 w-full">
              <div className="absolute flex items-center ml-2 h-full">
                <h4>What</h4>
              </div>
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => handleSearchWhat(e)}
                className=" px-16 h-[42px] w-full rounded-md border-transparent text-inputtext bg-inputbg"
              />
            </div>
            <div className="flex items-center relative w-full mr-3">
              <div className="absolute flex items-center ml-2 h-full">
                <h4>Where</h4>
              </div>
              <input
                type="text"
                placeholder="Location"
                onChange={(e) => handleSearchWhere(e)}
                className=" px-16 h-[42px] w-full rounded-md border-transparent text-inputtext bg-inputbg"
              />
            </div>

            <Button type="submit" color="primary">
              <SearchIcon color="#ffff" opacity="1" />
            </Button>
          </form>
        </div>
        <div className="flex-2"></div>
        <div className="flex flex-1 items-center bg-white">
          {/* <Button text="filter" endIcon={<SlidersIcon />} /> */}
          {/* <Button
            variant="text"
            color="neutral"
            fontWeight="font-light"
            endIcon={<SlidersIcon />}
          >
            Filter
          </Button> */}
        </div>
      </div>
      {/* <pre>{JSON.stringify(vendors, null, 2)}</pre> */}
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-8 mt-6">
        {requests &&
          requests[0]?.docs.map((request, index) => (
            <div key={index} className="p-6 bg-white">
              <div className="flex justify-between">
                <div className="flex items-center">
                  <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill mr-3">
                    {request?.user_image && request?.user_image !== null ? (
                      <img
                        className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                        src={`${request?.user_image?.url}`}
                        alt=""
                      />
                    ) : (
                      `${getInitials(request?.account?.name)}`
                    )}
                  </div>

                  <div>
                    <h4 className="text-primary">{request.account.name}</h4>
                    <h4 className="text-[14px] text-vendor">
                      {request.account.company}
                    </h4>
                  </div>
                </div>
                <div>
                  <Button
                    onClick={() =>
                      navigate(`/vendor/requests/request/${request._id}`)
                    }
                  >
                    View Profile
                  </Button>
                </div>
              </div>
              {/* <div className="flex my-5">
                <Rating
                  sx={{
                    color: "#F2C94C",
                  }}
                  name="read-only"
                  value={value}
                  readOnly
                />
                <h3 className="text-[14px] text-vendor font-light">
                  5.0/5.0 (200)
                </h3>
              </div> */}
              <div className="font-light text-[14px]">
                <h2 className="text-vendorsecondary mb-2.5">Description</h2>
                <p>{truncate(request.description, 200)}</p>
              </div>
              {/* <div className="my-3 flex flex-wrap text-[#50AAA7] font-light text-[14px]">
                {request.keywords.map((keyword, index) => (
                  <div
                    key={index}
                    className="bg-vendoroptionbg flex justify-between px-2 mr-2 mt-2"
                  >
                    <span className="mr-2">{keyword}</span>
                    <span>x</span>
                  </div>
                ))}
              </div> */}
            </div>
          ))}
      </div>
    </div>
  );
}

export default ServiceRequests;
