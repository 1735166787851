/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthContext from "./auth-context";
import { SignOut } from "@slices/user-slice";
import { SignInUrl } from "@config/app-url";

const parseJwt = (token) => {
  const decodedToken = window.atob(token.split(".")[1]);
  return JSON.parse(decodedToken);
};

const returnProfileStatus = (form, status,type) => {

  if (form?.form_content === null && status === "Pending") {
    return "pending1";
  } else if (form?.form_content !== null && status === "Pending") {
    return "pending2";
  } else if (form?.form_content !== null && status === "Verified") {
    return "verified";
  } else if(form?.form_content === null && status === "Verified" && type === "admin" ) {
    return "verified";
  }
  // if (status === 'Pending') {
  // 	return 'pending1';
  // } else if (status === 'Verified') {
  // 	return 'verified';
  // }
};
export default function AuthProvider({ children }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [account, setAccount] = useState(
    JSON.parse(localStorage.getItem("account"))
  );
  const [secondaryRole, setSecondaryRole] = useState(
    account?.find((account) => account?.primary === false)?.parent_id?.role
      ?.slug
  );
  const [primaryRole, setPrimaryRole] = useState(
    account?.find((account) => account?.primary === true)?.role?.slug
  );
  const [profileObject, setProfileObject] = useState(null);

  const [profileStatus, setProfileStatus] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedJwt = parseJwt(token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        //dispatch logout
        navigate(SignInUrl);
        // dispatch(SignOut()).then(() => {
        //   navigate(SignInUrl);
        // });
      }
    }
  }, [dispatch]);

  useEffect(() => {
    setPrimaryRole(
      account?.find((account) => account?.primary === true)?.role?.slug
    );
    setSecondaryRole(
      account?.find((account) => account?.primary === false)?.parent_id?.role
        ?.slug
    );

    if (account?.length > 0) {
      setProfileObject(account[0]);

      setProfileStatus(
        returnProfileStatus(account[0]?.form, account[0]?.status, account[0].type)
      );
    }

    // eslint-disable-next-line
  }, [account, profileObject]);

  const value = {
    user,
    primaryRole,
    secondaryRole,
    setUser,
    setAccount,
    profileStatus,
    account: profileObject,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
