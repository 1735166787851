import React from 'react'
// import { useNavigate } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { Button, Search , TableEmptyState } from "@ui";
import InterationsStats from "@ui/InterationsStats";
import { Sort, SlidersIcon, CaretDown, DropDownIcon } from "@ui/icons";
import DownloadIcon from "@ui/icons/DownloadIcon";
// import ProfilePicture from "@images/profile-picture.png";
// import { FundingUrl } from "config/app-url";
import {
  
  // transactionsData,
  fundingTableHeaders,
} from "@constants";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function FundingsBasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="w-full mb-5">
      <div className="border-b border-gray-light">
        <div className="flex items-center justify-between">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="secondary"
          >
            <Tab label="All" {...a11yProps(0)} />
            <Tab label="Completed" {...a11yProps(1)} />
            <Tab label="Pending" {...a11yProps(2)} />
            <Tab label="Archived" {...a11yProps(3)} />
          </Tabs>
          <p className="font-extralight text-sm text-heading">
            Total Transactions: <span className="text-primary">50</span>
          </p>
        </div>
      </div>
    </div>
  );
}

const Fundings = () => {
    //  const navigate = useNavigate();
   const fundings = []
    //  const statusClass = (status) => {
    //    switch (status) {
    //      case "active":
    //        return "bg-[#D9F2E4] text-[#43BE77]";
    //      case "archived":
    //        return "bg-[#FFE7E5] text-[#F52419]";
    //      default:
    //        return "bg-[#FDF2E7] text-[#F2994A]";
    //    }
    //  };
  return (
    <div>
      <div className="grid grid-cols-3 lg:grid gap-2 sm:flex overflow-x-auto scrollbar-hide">
        <InterationsStats
          
          transactions={true}
        />
      </div>
      {/* tabs */}
      <FundingsBasicTabs />
      {/* table */}
      <div className="flex flex-col overflow-x-auto scrollbar-hide">
        <div className="mt-4">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden card border-gray-200 sm:rounded-lg bg-white">
              {/* input field goes here */}
              <div className="flex justify-between p-4">
                <div className="flex gap-2">
                  <div className="w-[548px]">
                    <Search placeholder="Search transactions" />
                  </div>
                  {/* <div className="bg-primary flex items-center justify-center rounded py-1 px-2">
                    <SearchIcon color="#ffff" opacity="1" />
                  </div> */}
                </div>
                <div className="flex items-center">
                  <Button
                    variant="outlined"
                    color="neutral"
                    fontWeight="font-light"
                    endIcon={<SlidersIcon />}
                  >
                    Filter
                  </Button>
                  <div className="ml-[8px] bg-[#6166B6]">
                    <Button
                      variant="outlined"
                      color="text-[#ffffff]"
                      fontWeight="font-light"
                      startIcon={<DownloadIcon />}
                    >
                      Export
                    </Button>
                  </div> 
                </div>
              </div>
              <table className="min-w-full divide-y divide-[#1A38601A] border-b border-[#1A38601A]">
                <thead className="bg-[#EFF0FE]">
                  <tr>
                    {fundingTableHeaders.map((header, index) => (
                      <th
                        key={index + 1}
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-normal text-primary uppercase tracking-wider"
                      >
                        {!header.hasIcon && header.title}
                        {header.hasIcon && (
                          <div className="flex  items-center">
                            <span>{header.title}</span>
                            <Sort />
                          </div>
                        )}
                      </th>
                    ))}
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
               {/* <tbody className="bg-white font-light divide-y divide-[#1A38601A]">
                  {transactionsData.map((inventory, index) => (
                    <tr key={index} className="text-[#18273AF0]">
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {inventory.id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {inventory.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <span className="flex items-center">
                          <img
                            className="max-w-[24px] mr-[8px]"
                            src={ProfilePicture}
                            alt="Miley Cyrus"
                          />
                          {inventory.buyer}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {inventory.date}
                      </td>                      
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={`py-1 px-5 flex justify-center items-center capitalize text-sm leading-5 font-light rounded ${statusClass(
                            inventory.status
                          )} `}
                        >
                          {inventory.status}
                        </span>
                      </td>
                      <td>
                        <button
                          className="outline-none mr-1 mb-1 px-3 py-1 bg-transprent text-sm underline text-[#50AAA7] focus:outline-none"
                          onClick={() =>
                            navigate(FundingUrl, {
                              state: { status: inventory.status },
                            })
                          }
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody> */}
              </table>
             
              {!fundings.length > 0 ? (
               <TableEmptyState/>
              ) : (
                <div className="flex justify-end w-full py-4 px-8 text-[13px] uppercase text-[#18273AF0]">
                  <div className="flex items-center justify-between gap-2">
                    <p>Rows per page: 10</p>
                    <div>
                      <CaretDown />
                    </div>
                  </div>
                  <div className="mx-6">1 -3 of 3</div>
                  <div className="flex justify-between gap-10">
                    <div className="rotate-90">
                      <DropDownIcon />
                    </div>
                    <div className="-rotate-90">
                      <DropDownIcon />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fundings