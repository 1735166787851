import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "@config/axios-instance";
import * as API from "@config/url";
import { toast } from "react-toastify";

const fetchMineData = createAsyncThunk(
  "miner/fetchMineData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchMineData);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAllMines = createAsyncThunk(
  "miner/fetchAllMines",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchMines);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAllActiveMines = createAsyncThunk(
  "miner/fetchAllActiveMines",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchActiveMines);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPaginatedMines = createAsyncThunk(
  "miner/fetchPaginatedMines",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedMine(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const searchMineByNameOrType = createAsyncThunk(
  "miner/fetchMineByNameOrType",
  async (searchParam, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchMines, {
        params: { search: searchParam },
      });
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postCreateMine = createAsyncThunk(
  "miner/postCreateMine",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.postCreateMine, data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const minesSlice = createSlice({
  name: "mines",
  initialState: {
    mineData: {},
    pagination: null,
    loading: false,
    error: null,
    mines: null,
    search: "",
  },
  reducers: {
    setSearchValue: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: {
    //fetch mine data
    [fetchMineData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.mineData = payload.data;
    },
    [fetchMineData.rejected]: (state, { payload }) => {
      state.error = true;
      toast.error(payload.message);
    },
    // eslint-disable-next-line no-unused-vars
    [fetchPaginatedMines.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPaginatedMines.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.mines = payload.data.docs;
      state.pagination = payload.data.pagination;
    },
    [fetchPaginatedMines.rejected]: (state, action) => {
      state.error = true;
      console.log(action);
      // toast.error(payload.message);
    },
    //search mine
    [searchMineByNameOrType.pending]: (state) => {
      state.loading = true;
    },
    [searchMineByNameOrType.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.mines = payload.data.mines;
    },
    [searchMineByNameOrType.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    //fetch mines
    [fetchAllMines.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllMines.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.mines = payload.data.docs;
      state.pagination = payload.data.pagination;
    },
    [fetchAllMines.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchAllActiveMines.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllActiveMines.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.mines = payload.data.docs;
      state.pagination = payload.data.pagination;
    },
    [fetchAllActiveMines.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },


    //create mine
    [postCreateMine.pending]: (state) => {
      state.loading = true;
    },
    [postCreateMine.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      // state.mines = payload.data.mines
    },
    [postCreateMine.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
  },
});

export const { setSearchValue } = minesSlice.actions;

export { fetchMineData, fetchAllMines,fetchAllActiveMines, searchMineByNameOrType, postCreateMine };

export default minesSlice.reducer;
