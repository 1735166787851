import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as API from "@config/url";
import { toast } from "react-toastify";

// fetch roles
const get_roles = createAsyncThunk("profile/get_roles", async (_, { rejectWithValue }) => {
    try {
        return await axios({
            method: "get",
            url: API.getRoles,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer "+ JSON.parse(localStorage.getItem("token")),
            },
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

//create profile
const create_profile = createAsyncThunk("profile/create_profile", async (payload, { rejectWithValue }) => {
    try {
        const response = await axios({
            method: "post",
            url: API.postCreateProfile(payload.role,payload.user_id),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer "+ JSON.parse(localStorage.getItem("token")),
            },
            // data: {},
        });
        return response;
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

/** create slice */
const slice = createSlice({
    name: "profile",
    initialState: {
        data: {},
        status: false,
        loading: false,
        error: null,
        message: null,
    },
    reducers: {},
    extraReducers: {
        [create_profile.pending]: (state) => {
            state.loading = true;
        },
        [create_profile.fulfilled]: (state, { payload }) => {
            const profile = payload.data?.account
            state.data = profile
            state.status = payload.status
            // toast.success(payload.data.message) not needed to be displayed
            state.loading = false;
            localStorage.setItem('profile_to_verify', JSON.stringify(profile));
        },
        [create_profile.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
        [get_roles.pending]: (state) => {
            state.loading = true;
        },
        [get_roles.fulfilled]: (state, { payload }) => {
            state.data = payload.data
            state.status = payload.status;
            // toast.success(payload.data.message); not needed to be displayed
            state.loading = false;
        },
        [get_roles.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
    },
});

export { create_profile, get_roles }
export default slice.reducer;