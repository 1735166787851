import React, { useState, useEffect } from "react";
import { Map, LongLat, SearchLocation } from "@pages/onboarding";
import { Input } from "@atoms/input";
import PhoneInput from "react-phone-input-2";
import { PhoneInputFormatter } from "@shared/formatter";

const OffTakerShippingForm = ({ register, setValue, errors }) => {
  const [gps, setGps] = useState(false);
  const [address, setAddress] = useState(true);
  const [location_type, setLocationType] = useState("");
  const [longitude_latitude, setLongitudeLatitude] = useState(false);

  const [, setPhoneInput] = useState({
    code: "",
    number: "",
    iso_code: "",
  });

  /** Format phone then set to state */
  const getFormattedNumber = (value, data) => {
    const input = PhoneInputFormatter(value, data);
    setPhoneInput({
      code: input.phone_code,
      number: input.phone_number,
      iso_code: input.iso_code,
    });

    setValue("code", input.phone_code);
    setValue("number", input.phone_number);
    setValue("iso_code", input.iso_code);
  };
  /** Onchange of Location Type */
  const onChangeLocationType = (location_type) => {
    setValue("address", "", { shouldValidate: true, shouldDirty: true });
    setValue("postal", "", { shouldValidate: true, shouldDirty: true });
    setValue("state", "", { shouldValidate: true, shouldDirty: true });
    setValue("city", "", { shouldValidate: true, shouldDirty: true });
    setValue("long", "", { shouldValidate: true, shouldDirty: true });
    setValue("lat", "", { shouldValidate: true, shouldDirty: true });
    setValue("country", "", { shouldValidate: true, shouldDirty: true });
    return setLocationType(location_type);
  };
  useEffect(() => {
    /** switch location type */
    switch (location_type) {
      case "address":
        setAddress(true);
        setLongitudeLatitude(false);
        setGps(false);
        break;
      case "longitude_latitude":
        setLongitudeLatitude(true);
        setAddress(false);
        setGps(false);
        break;
      case "gps":
        setGps(true);
        setAddress(false);
        setLongitudeLatitude(false);
        break;
      default:
        break;
    }
  }, [location_type]);

  return (
    <div>
      {/* Contact Person's Name */}
      <div className="">
        <label className="block text-xs mb-2  text-lightgray">
          {" "}
          Name Of Contact Person
        </label>
        <Input
          type="text"
          placeholder="Enter Name Of Contact Person"
          name="contactPerson"
          {...register("contactPerson")}
        />{" "}
        {errors?.contactPerson?.message && (
          <p className="paragraph text-red text-xs mt-1">
            {" "}
            * {errors?.contactPerson?.message}{" "}
          </p>
        )}
      </div>
      {/* PhoneNumber */}
      <div className="mt-2">
        <label className="block text-sm mb-2 text-lightgray">
          {" "}
          Phone Number{" "}
        </label>
        <PhoneInput
          isValid={(value) => {
            if (value.length < 10 && value.length > 1) {
              return "* valid phone number is required";
            } else if (value.length < 10) {
              return false;
            } else {
              return true;
            }
          }}
          type="text"
          country="us"
          value=""
          onChange={(value, data) => getFormattedNumber(value, data)}
          inputProps={{ name: "phone", required: true }}
          masks={{ fr: "(...) ..-..-..", at: "(....) ...-...." }}
        />
      </div>
      {/** Location Type */}
      <div className="w-full mt-2">
        <label className="block text-xs mb-2  text-lightgray" htmlFor="address">
          {" "}
          Location type{" "}
        </label>
        <select
          className="bg-fill cursor-pointer 
          border-none focus:outline-none
           focus:border-primary focus:ring-1
            focus:ring-primary w-full
             appearance-none rounded 
             text-input py-2 px-4 text-sm"
          id="address"
          type="dropdown"
          onChange={(e) => onChangeLocationType(e.target.value)}
        >
          <option
            className="bg-fill cursor-pointer border-none"
            value="address"
            name="type"
          >
            {" "}
            Address{" "}
          </option>
          <option value="longitude_latitude" name="type">
            {" "}
            Longitude and Latitude{" "}
          </option>
          <option value="gps" name="type">
            {" "}
            GPS{" "}
          </option>
        </select>
      </div>
      {gps && (
        <div className="w-full mt-3">
          <div className="h-96 my-4 ">
            <Map
              location={{
                address:
                  "1600 Amphitheatre Parkway, Mountain View, california.",
                lat: 37.42216,
                lng: -122.08427,
              }}
              zoomLevel={17}
              setValue={setValue}
            />
          </div>
        </div>
      )}
      {longitude_latitude && (
        <div className="flex flex-wrap -mx-2">
          <LongLat setValue={setValue} errors={errors} />
        </div>
      )}
      {/* Address */}
      <div className="w-full mt-3">
        <label className="block text-xs mb-2  text-lightgray" htmlFor="address">
          {" "}
          Address{" "}
        </label>
        {address ? (
          <SearchLocation setValue={setValue} />
        ) : (
          <Input type="text" name="address" {...register("address")} />
        )}
        {errors.address?.message && (
          <p className="paragraph text-red text-xs mt-1">
            {" "}
            * {errors.address?.message}{" "}
          </p>
        )}
      </div>
      {/* Longitude && Latitude display this if longitude and latitude is not selected */}
      {!longitude_latitude && (
        <div className="flex flex-wrap -mx-2">
          {/** Longitude */}
          <div className=" media mt-4 px-3">
            <label
              className="block text-xs mb-2 text-lightgray"
              htmlFor="longitude"
            >
              {" "}
              Longitude{" "}
            </label>
            <Input type="text" name="long" {...register("long")} readOnly />
            {errors.long?.message && (
              <p className="paragraph text-red text-xs mt-1">
                {" "}
                * {errors.long?.message}{" "}
              </p>
            )}
          </div>
          {/** Latitude */}
          <div className=" media mt-4 px-3">
            <label
              className="block text-xs mb-2 text-lightgray"
              htmlFor="latitude"
            >
              {" "}
              Latitude{" "}
            </label>
            <Input type="text" name="lat" {...register("lat")} readOnly />
            {errors.lat?.message && (
              <p className="paragraph text-red text-xs mt-1">
                {" "}
                * {errors.lat?.message}{" "}
              </p>
            )}
          </div>
        </div>
      )}
      {/** Country && Postal code */}
      <div className="flex flex-wrap -mx-3 my-3">
        {/** Country */}
        <div className="media px-3">
          <label
            className="block text-xs mb-2  text-lightgray"
            htmlFor="country"
          >
            {" "}
            Country{" "}
          </label>
          <Input
            id="country"
            type="text"
            placeholder=""
            name="country"
            {...register("country")}
            readOnly
          />
          {errors.country?.message && (
            <p className="paragraph text-red text-xs mt-1">
              {" "}
              * {errors.country?.message}{" "}
            </p>
          )}
        </div>
        {/** Postal code */}
        <div className="media px-3">
          <label
            className="block text-xs mb-2  text-lightgray"
            htmlFor="postal"
          >
            {" "}
            Postal code{" "}
          </label>
          <Input
            id="postal"
            type="text"
            name="postal"
            {...register("postal")}
          />
          {errors.postal?.message && (
            <p className="paragraph text-red text-xs mt-1">
              {" "}
              * {errors.postal?.message}{" "}
            </p>
          )}
        </div>
      </div>
      {/** State && City */}
      <div className="flex flex-wrap -mx-2">
        {/** State */}
        <div className=" media px-3 mt-2">
          <label className="block text-xs mb-2 text-lightgray" htmlFor="state">
            {" "}
            State{" "}
          </label>
          <Input
            id="state"
            type="text"
            placeholder=""
            name="state"
            {...register("state")}
          />
          {errors.state?.message && (
            <p className="paragraph text-red text-xs mt-1">
              {" "}
              * {errors.state?.message}{" "}
            </p>
          )}
        </div>
        {/** City */}
        <div className=" media px-3 mt-2 ">
          <label className="block text-xs mb-2 text-lightgray" htmlFor="city">
            {" "}
            City{" "}
          </label>
          <Input
            id="city"
            type="text"
            placeholder=""
            name="city"
            {...register("city")}
          />
          {errors.city?.message && (
            <p className="paragraph text-red text-xs mt-1">
              {" "}
              * {errors.city?.message}{" "}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default OffTakerShippingForm;
