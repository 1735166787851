/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "@config/url";
import { toast } from "react-toastify";
import axios from "@config/axios-instance";
const GET = "get";
const DELETE = "delete";
const POST = "post";

const initialState = {
  activeStep: 0,

  isLoading: false,
  error: null,
  isError: false,
  isSuccess: false,
  requests: null,
  request: null,
};

export const vendorConfig = (method, url, data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      "Authorization": "bearer "+ token
    },
    data,
  };
};

export const getServiceRequests = createAsyncThunk(
  "vendor/getServiceRequests",
  async (name, thunkAPI) => {
    try {
      // thunkAPI.dispatch(openModal());
      const resp = await axios.get(API.fetchServiceRequests);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const searchServiceRequest = createAsyncThunk(
  "vendor/searchServiceRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.searchServiceRequests(
          payload?.search ? payload.search : "",
          payload?.country ? payload.country : ""
        )
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getServiceRequest = createAsyncThunk(
  "vendor/getServiceRequest",
  async (id, thunkAPI) => {
    try {
      const resp = await axios.get(API.fetchServiceRequest(id));
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const applyToServiceRequest = createAsyncThunk(
  "vendor/applyToServiceRequest",
  async (id, thunkAPI) => {
    try {
      const resp = await axios.post(API.applyToRequest(id));
      return resp;
    } catch (error) {
      toast.error("An error occured while applying to this request");
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

const serviceRequestSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {},
  isLoading:false,
  extraReducers: (builder) => {
    builder
      .addCase(getServiceRequests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getServiceRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.servicerequest)
          state.requests = action.payload.servicerequest;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getServiceRequests.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getServiceRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getServiceRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.servicerequest)
          state.request = action.payload.servicerequest;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getServiceRequest.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(searchServiceRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchServiceRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.servicerequest)
          state.requests = action.payload.servicerequest;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(searchServiceRequest.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(applyToServiceRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applyToServiceRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(applyToServiceRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

//export const {} = serviceRequestSlice.actions;

export default serviceRequestSlice.reducer;
