/* eslint-disable react/prop-types */
const Avatar = ({ children, bgPrimary }) => {
  const backgroundColor = bgPrimary ? "bg-primary" : "bg-gray-100";
  return (
    <div
      className={`rounded-full w-10 h-10 flex items-center justify-center text-white ${backgroundColor} mr-4`}
    >
      {children}
    </div>
  );
};

export default Avatar;
