import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "components/ui/Button";
import dayjs from "dayjs";
import { getInitials } from "shared/utility";
import { useNavigate } from "react-router-dom";
import { getOffer } from "store/slices/vendors/interactions-slice";

function OfferDetails() {
  const params = useParams();
  const { id } = params;
  const { offer } = useSelector((store) => store.vendorInteractions);
  const {  data } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getOffer(id));
  }, []);

  return (
    <div>
      <div className="bg-white rounded-sm flex justify-center py-6">
        {/* <pre>{JSON.stringify(vendor, null, 2)}</pre> */}
        {offer && (
          <div>
            <div>
              <div className="flex justify-between">
                <div className="flex">
                {data?.profile_img?.length > 0 ? (
                    <img
                    className="rounded-circle w-[60px] h-[60px] mr-3"
                      src={`${data?.profile_img[0]?.url}`}
                      alt=""
                    />
                  ) : (
                    `${getInitials(offer?.account?.name)}`
                  )}
                  <div>
                    <h4 className="text-primary">{offer?.account.company}</h4>
                    <h4 className="text-[14px] text-vendor">
                      {offer?.account?.name}
                    </h4>
                  </div>
                </div>
                <div>
                  <Button onClick={() => navigate("interests")}>
                    {" "}
                    Manage Interests
                  </Button>
                </div>
              </div>
            </div>
            <div className="mt-3 mb-7">
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Job title:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {offer.title}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project type:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {offer.type}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Category:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {offer.category.name}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Description:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {offer?.description}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project duration:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {offer?.duration}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Country:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {offer?.account?.country.name}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Budget:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {offer?.budget}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project start date:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {dayjs(offer?.start_date).format("DD/MM/YYYY")}
                </h4>
              </div>
              {offer.additional_notes && (
                <div className="grid grid-cols-3 mb-8 font-light">
                  <h4 className="text-vendorsecondary col-start-1 col-end-2">
                    Additional notes:
                  </h4>
                  <h4 className="text-vendor col-start-2 col-end-4">
                    {offer?.additional_notes}
                  </h4>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OfferDetails;
