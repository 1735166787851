/* eslint-disable react/prop-types */
import SideImage from "@images/unsplash_781VLZjFR8g.png";

const LayoutOnboarding = ({ children }) => {
  return (
    <div className="lg:grid lg:grid-cols-2 w-full h-full mx-auto flex justify-center">
      {/* form container */}
      <div className="col-span-1">
        <div className="bg-white   h-full w-full lg:max-h-screen mx-auto px-8 lg:px-28 py-12  bg-bckImage bg-cover lg:bg-none bg-no-repeat overflow-auto scrollbar-hide">
          {children}
        </div>
      </div>
      {/*image container */}
      <img
        src={SideImage}
        alt="daniola corporation"
        className="hidden lg:flex max-h-screen max-w-full w-full object-cover"
      />
    </div>
  );
};

export default LayoutOnboarding;
