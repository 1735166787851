/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminOffers, postArchiveOffer } from "@slices/admin-slice";
import { adminOffers } from "constants/index";
import { checkPermission } from "shared/utility";
import { Button, ControlledModal, TextArea, AdminInvestmentTab } from "@ui";
import { toast } from "react-toastify";

const InvestmentOffers = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [prodID, setProductID] = useState();
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const { loading, offers } = useSelector((state) => state.admin);

  let [data, setData] = useState({
    status: "archived",
    content: "",
  });

  const handleOpen = (offer) => {
    setOpen(true);
    setProductID(offer?._id);
  };

  const handleClosed = () => setOpened(false);

  const handleClose = () => setOpen(false);

  const handleOpenFeedback = () => {
    setOpened(true);
    setOpen(false);
  };

  const archiveOffer = async () => {
    try {
      setIsLoading(true);
      const response = await dispatch(
        postArchiveOffer({ id: prodID, data: data })
      );
      if (response.payload.status === 200 || response.payload.status === 201) {
        if (
          response.payload.data.code == 422 ||
          response.payload.data.code == 400
        ) {
          setIsLoading(false);
          toast.error(response.payload.data.message);
        } else {
          toast.success(response.payload.data.message);
          setOpened(false);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        console.log("error from then", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    if (tab == "All") {
      dispatch(
        fetchAdminOffers({
          filter: "",
        })
      );
    } else {
      dispatch(
        fetchAdminOffers({
          filter: tab,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(fetchAdminOffers({ filter: "" }));
  }, [dispatch]);

  return (
    <div>
      <ControlledModal
        isOpen={open}
        handleClose={handleClose}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
      >
        <p className="text-head text-center font-semibold text-[22px]">
          Archive Offer?
        </p>
        <p className="text-lightgray text-center font-light text-base mt-2">
          Are you sure you want to archive this investment offer?
        </p>
        <div className="flex flex-row justify-center gap-4 mt-6">
          <Button
            type="button"
            variant="text"
            color="neutral"
            onClick={() => setOpen(!open)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            disabled={checkPermission("Listings")}
            variant="contained"
            color="danger"
            onClick={handleOpenFeedback}
          >
            Yes, archive
          </Button>
        </div>
      </ControlledModal>
      <ControlledModal
        isOpen={opened}
        handleClose={handleClosed}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
      >
        <div className="flex flex-col justify-center gap-4 mt-6">
          <p className="text-head text-center font-semibold text-[22px]">
            Feedback to user
          </p>
          <p className="text-lightgray text-center font-light text-base mt-2">
            Kindly provide additional notes to explain to seller the reason for
            archiving the auction{" "}
          </p>
          <TextArea name="content" onChange={handleInputChange} />
          <div className="flex justify-end ">
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => {
                archiveOffer();
              }}
            >
              {isLoading ? "Processing..." : "Send"}
            </Button>
          </div>
        </div>
      </ControlledModal>
      {/* main */}
      <div className="mt-5 font-default ">
        <AdminInvestmentTab
          tableInfo={adminOffers}
          data={offers}
          loading={loading}
          handleOpen={handleOpen}
          handleTabChange={handleTabChange}
        />
      </div>
    </div>
  );
};

export default InvestmentOffers;
