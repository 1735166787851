import React from "react";
import Button from "components/ui/Button";
import SearchIcon from "components/ui/icons/SearchIcon";
import { useNavigate } from "react-router-dom";
import { getInitials } from "shared/utility";
import { Rating } from "@mui/material";

const FindVendor = ({
  vendors,
  userType,
  handleSearchSubmit,
  handleSearchWhat,
  handleSearchWhere,
}) => {
  const navigate = useNavigate();
  return (
    <div>
      {/* <button onClick={checkVendors} className="bg-red text-white">
        check
      </button> */}
      <div className="flex">
        <div className="flex flex-7 ">
          <form onSubmit={handleSearchSubmit} className="flex flex-7">
            <div className="flex items-center relative mr-4 w-full">
              <div className="absolute flex items-center ml-2 h-full">
                <h4>What</h4>
              </div>
              <input
                type="text"
                placeholder="Company name"
                onChange={handleSearchWhat}
                className=" px-16 h-[42px] w-full rounded-md border-transparent text-inputtext bg-inputbg"
              />
            </div>
            <div className="flex items-center relative w-full mr-3">
              <div className="absolute flex items-center ml-2 h-full">
                <h4>Where</h4>
              </div>
              <input
                type="text"
                placeholder="Location"
                onChange={handleSearchWhere}
                className=" px-16 h-[42px] w-full rounded-md border-transparent text-inputtext bg-inputbg"
              />
            </div>

            <Button type="submit" color="primary">
              <SearchIcon color="#ffff" opacity="1" />
            </Button>
          </form>
        </div>
        <div className="flex-2"></div>
        <div className="flex flex-1 items-center">
          {/* <Button text="filter" endIcon={<SlidersIcon />} /> */}
          {/* <Button
            variant="text"
            color="neutral"
            fontWeight="font-light"
            endIcon={<SlidersIcon />}
          >
            Filter
          </Button> */}
        </div>
      </div>
      {/* <pre>{JSON.stringify(vendors, null, 2)}</pre> */}
      {userType !== "vendor" ? (
        <div className="grid grid-cols-2 sm:grid-cols-1 gap-8 mt-6">
          {vendors &&
            vendors?.map((vendor, index) => (
              <div key={index} className="p-6 bg-white">
                <div className="flex justify-between">
                  <div className="flex">
                    <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill mr-3 mb-2">
                      {vendor?.profile_img !== null ? (
                        <img
                          className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                          src={`${vendor?.profile_img?.url}`}
                          alt=""
                        />
                      ) : (
                        `${getInitials(vendor?.company_name)}`
                      )}
                    </div>
                    <div>
                      <h4 className="text-primary">{vendor.company_name}</h4>
                      <h4 className="text-[14px] text-vendor">
                        {vendor.industry_name}
                      </h4>
                      <Rating
                        name="simple-controlled"
                        readOnly
                        className="!text-primary mt-1 !text-[15px] "
                        value={vendor?.averageRating}
                      />
                    </div>
                  </div>
                  <div>
                    <Button onClick={() => navigate(`profile/${vendor?._id}`)}>
                      View Profile
                    </Button>
                  </div>
                </div>
                <div className="font-light text-[14px]">
                  <h2 className="text-vendorsecondary mb-2.5">Description</h2>
                  <p>{vendor.job_description}</p>
                </div>
                <div className="my-3 flex flex-wrap text-[#6166B6] font-light text-[14px]">
                  {vendor?.keywords?.map((keyword, index) => (
                    <div
                      key={index}
                      className="bg-vendoroptionbg flex justify-between px-2 mr-2 mt-2"
                    >
                      <span className="mr-2">{keyword}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-1 gap-8 mt-6">
          {vendors &&
            vendors?.map((vendor, index) => (
              <div key={index} className="p-6 bg-white">
                <div className="flex justify-between">
                  <div className="flex">
                    <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill mr-3 mb-2">
                      {vendor?.profile_img !== null ? (
                        <img
                          className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                          src={`${vendor?.profile_img?.url}`}
                          alt=""
                        />
                      ) : (
                        `${getInitials(vendor?.company_name)}`
                      )}
                    </div>
                    <div>
                      <h4 className="text-primary">{vendor.company_name}</h4>
                      <h4 className="text-[14px] text-vendor">
                        {vendor.industry_name}
                      </h4>
                    </div>
                  </div>
                  <div>
                    <Button onClick={() => navigate(`profile/${vendor?._id}`)}>
                      View Profile
                    </Button>
                  </div>
                </div>
                <div className="font-light text-[14px]">
                  <h2 className="text-vendorsecondary mb-2.5">Description</h2>
                  <p>{vendor.job_description}</p>
                </div>
                <div className="my-3 flex flex-wrap text-[#6166B6] font-light text-[14px]">
                  {vendor?.keywords?.map((keyword, index) => (
                    <div
                      key={index}
                      className="bg-vendoroptionbg flex justify-between px-2 mr-2 mt-2"
                    >
                      <span className="mr-2">{keyword}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default FindVendor;
