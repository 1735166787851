import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "@config/url";
import { toast } from "react-toastify";
import axios from "@config/axios-instance";

const fetchDashboardActivities = createAsyncThunk(
  "miner/fetchDashboardActivities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchDashboardActivities);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchDashboardNotifications = createAsyncThunk(
  "miner/fetchDashboardNotifications",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchDashboardNotifications);

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchDashboardRecordsCard = createAsyncThunk(
  "miner/fetchDashboardRecordsCard",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchDashboardRecordsCard);

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAnalyticsChart = createAsyncThunk(
  "miner/fetchAnalyticsChart",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchMinerAnalyticsChart);

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchInventoryChart = createAsyncThunk(
  "miner/fetchInventoryChart",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchMinerInventoryChart);

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    record_card: {
      total_transactions: 0,
      total_sales: 0,
      items_in_inventory: 0,
      total_mines: 0,
    },
    activities: [],
    notifications: [],
    analytics: [],
    inventory: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    //fetch dashboard activities
    [fetchDashboardActivities.pending]: (state) => {
      state.loading = true;
    },
    [fetchDashboardActivities.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.activities = payload.data.activities;
    },
    [fetchDashboardActivities.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    //fetch dashboard notfications
    [fetchDashboardNotifications.pending]: (state) => {
      state.loading = true;
    },
    [fetchDashboardNotifications.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.notifications = payload.data.notifications;
    },
    [fetchDashboardNotifications.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    //fetch records card
    [fetchDashboardRecordsCard.pending]: (state) => {
      state.loading = true;
    },
    [fetchDashboardRecordsCard.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.record_card.total_transactions = payload.data.total_transactions;
      state.record_card.total_sales = payload.data.total_sales;
      state.record_card.items_in_inventory = payload.data.items_in_inventory;
      state.record_card.total_mines = payload.data.total_mines;
    },
    [fetchDashboardRecordsCard.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    //fetch analytics
    [fetchAnalyticsChart.pending]: (state) => {
      state.loading = true;
    },
    [fetchAnalyticsChart.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.analytics = payload.data.data;
    },
    [fetchAnalyticsChart.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    //fetch inventories
    [fetchInventoryChart.pending]: (state) => {
      state.loading = true;
    },
    [fetchInventoryChart.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.inventory = payload.data.mineral_types;
    },
    [fetchInventoryChart.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
  },
});

export {
  fetchDashboardActivities,
  fetchDashboardNotifications,
  fetchDashboardRecordsCard,
  fetchAnalyticsChart,
  fetchInventoryChart,
};

export default analyticsSlice.reducer;
