/* eslint-disable no-unused-vars */
import Button from "components/ui/Button";
//import { DesktopPicker } from "components/ui/DatePicker";
import {
  CustomDatePicker,
  Input,
  SelectField,
  TextArea,
} from "components/ui/Inputs";
import React, { useState, useEffect } from "react";
import { Formik, useField, Field, Form } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DatePicker from "react-datepicker";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Select as SelectForm } from "@ui";
import VerifiedIcon from "../ui/icons/VerifiedIcon";
import axios from "@config/axios-instance";
import { SuccessResponse } from "config/status-code";
import Modal from "@mui/material/Modal";
import { contactVendor } from "store/slices/offtaker-slice";
import { useLocation } from "react-router-dom";

const VendorInquiryForm = ({
  countries,
  service_options,
  dispatchFunction,
  action,
  role,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  let location = useLocation();
  // const profile = localStorage.getItem("profile");
  // const { url } = profile.role;
  const contactMessage =
    "You have successfully sent your project details to this vendor. As soon as the vendor confirms, contact details will be available for further communication.";
  const requestMessage =
    "You have successfully requested for a service. As soon as a vendor confirms, their contact details will be available for further communication.";
  const contactValidationSchema = yup.object().shape({
    type: yup.string().required("Offering type is required"),
    category: yup.string().required(),
    title: yup.string().required("Title is a required field."),
    description: yup.string().required(),
    start_date: yup.string().required(),
    budget: yup.number().required(),
    duration: yup
      .array()
      .when("type", (type, schema) =>
        type === "Service"
          ? schema.required("Duration field is required")
          : schema
      ),
    country: yup.string().required(),
  });

  const initialValues = {
    type: "",
    category: "",
    title: "",
    description: "",
    duration: "",
    country: "",
    start_date: "",
    budget: "",
    additional_notes: "",
  };
  const projectTypeOptions = [
    { label: "Service", value: "service" },
    { label: "Contractual", value: "contractual" },
  ];
  const roles = useSelector((state) => state.roles_permissions);
  const params = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [type, setFormType] = useState(null);
  const [category, setCategory] = useState(null);

  const handleSelectType = (e, setFieldValue) => {
    setFormType(e.slug);
    setFieldValue("type", e.slug);
    setCategory({
      name: "",
      slug: "",
    });
    setFieldValue("category", "");
  };

  const handleSelectCategory = (e, setFieldValue) => {
    setFieldValue("category", e.slug);
    setCategory(e.slug);
  };

  const checkPermission = () => {
    let Permission = false;
    roles?.all_permissions?.forEach((x) => {
      if (x.resource.name == "Vendors") {
        if (
          x.method.add === false &&
          x.method.delete === false &&
          x.method.edit === false &&
          x.method.search === false &&
          x.method.view === true
        ) {
          Permission = true;
        } else {
          Permission = false;
        }
      }
    });

    return Permission;
  };

  const DatePickerField = ({ ...props }) => {
    const [field, , { setValue }] = useField(props);
    return (
      <DatePicker
        {...field}
        {...props}
        minDate={new Date()}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setValue(val);
        }}
      />
    );
  };

  // useEffect(() => {
  //   console.log(location);
  // });
  return (
    <div className="bg-white rounded-sm flex justify-center py-6">
      <div className="w-3/4">
        <h1 className="text-[22px] text-[##192434]">{`${
          action === "contact" ? "Contact Vendor" : "Request a service"
        }`}</h1>

        <p className="text-vendorsecondary">
          Enter the details of the project you intend to create with this
          vendor.
        </p>

        <div className="relative">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting }) => {
              setLoading(true);
              const payload = { ...values };
              payload.action = action;
              payload.type = type;
              payload.category = category;
              payload.duration = values.duration[0] + " " + values.duration[1];
              //console.log(values);
              const data = action === "contact" ? { id, payload } : { payload };
              dispatch(dispatchFunction(data))
                .then((response) => {
                  // console.log(response);
                  if (
                    response.payload.status &&
                    SuccessResponse(response.payload.status)
                  ) {
                    setLoading(false);
                    setOpen(true);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setLoading(false);
                  toast.error("Error contacting Vemdor");
                });
              // try {
              //   const resp = await axios(
              //     vendorConfig("post", API.contactVendor(id), values)
              //   );
              //   setMessage(resp.data.message);
              // } catch (error) {
              //   toast.error("Error contacting Vemdor");
              //   console.log(error);
              // }
            }}
            validationSchema={contactValidationSchema}
          >
            {({
              values,
              handleChange,
              isSubmitting,
              handleSubmit,
              setFieldValue,
              errors,
            }) => (
              <Form>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                <div className="flex w-full">
                  <div className="w-full">
                    <Input
                      label="Project title"
                      name="title"
                      value={values.slug}
                      onChange={handleChange}
                      sx={{ width: "100%" }}
                    />
                    {errors.title && (
                      <p className=" text-red font-light text-xs">
                        Title is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex">
                  <div className=" w-full mr-4">
                    <SelectForm
                      label="Project type"
                      options={[
                        { name: "Service", slug: "service" },
                        { name: "Commodity", slug: "commodity" },
                      ]}
                      name="type"
                      value={values.slug}
                      onChange={(e) => handleSelectType(e, setFieldValue)}
                    />
                    {errors.type && (
                      <p className=" text-red font-light text-xs">
                        Type is required
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <SelectForm
                      label="Category"
                      options={
                        type === "service"
                          ? Object.keys(service_options).map((key) => ({
                              name: service_options[key],
                              slug: key,
                            }))
                          : [
                              { name: "Logistics", slug: "logistics" },
                              { name: "Contractual", slug: "contractual" },
                            ]
                      }
                      name="category"
                      value={category ? category.slug : values.slug}
                      onChange={(e) => handleSelectCategory(e, setFieldValue)}
                    />
                    {errors.category && (
                      <p className=" text-red font-light text-xs">
                        Category is required
                      </p>
                    )}
                  </div>
                </div>
                <div></div>
                <div>
                  <TextArea
                    label="Project description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  />
                  {errors.description && (
                    <p className=" text-red font-light text-xs">
                      Description is required
                    </p>
                  )}
                  <div className="flex items-center">
                    <div className="mt-6 w-full mr-4">
                      <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                        Project duration
                      </p>
                      <div className="flex items-center">
                        <input
                          className="p-[10px] border-none mr-3 w-full font-light text-gray-700 bg-[#1A38601A] rounded z-0 focus:shadow focus:outline-none focus:ring-primary placeholder:text-[#1C345442] placeholder:font-extralight placeholder:text-base"
                          name="duration[0]"
                          placeholder="Input number"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          type="number"
                          onChange={handleChange}
                        />
                        <SelectField
                          items={["day(s)", "weeks(s)", "month(s)", "year(s)"]}
                          name="duration[1]"
                          value={values.duration[1] ? values.duration[1] : ""}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.duration && (
                        <p className=" text-red font-light text-xs w-full">
                          Duration is required
                        </p>
                      )}
                    </div>{" "}
                    <div className="mt-6 w-full">
                      <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                        Location
                      </p>
                      <Select
                        sx={{
                          width: "100%",
                          backgroundColor: "#1A38601A",
                          height: "42px",
                        }}
                        displayEmpty
                        //                      value={personName}
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Select Country</em>;
                          }
                          const countryName = countries.find(
                            (country) => country.iso_code === selected
                          );
                          return countryName.name;
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {countries.map((country) => (
                          <MenuItem
                            key={country.iso_code}
                            value={country.iso_code}
                          >
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.country && (
                        <p className=" text-red font-light text-xs">
                          Location is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    <div className=" mt-2 w-full mr-3">
                      <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                        Project start date
                      </p>
                      <div className="h-full w-full">
                        <DatePickerField name="start_date" />
                        {errors.start_date && (
                          <p className=" text-red font-light text-xs">
                            Start date is required
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="w-full">
                        <Input
                          label="Budget"
                          name="budget"
                          value={values.budget}
                          isMoneyInput={true}
                          //            postfix="/dmT"
                          onChange={handleChange}
                          type="number"
                          sx={{ width: "100%" }}
                        />
                        {errors.budget && (
                          <p className=" text-red font-light text-xs">
                            Budget is required
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <TextArea
                    label="Additional notes"
                    name="additional_notes"
                    value={values.additional_notes}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex justify-between">
                  <button
                    className="text-primary bg-back px-4 py-2 rounded-md"
                    type="button"
                    onClick={() =>
                      navigate(
                        `/${role}/vendors/find_a_vendor`
                      )
                    }
                  >
                    Back
                  </button>
                  <Button
                    type="submit"
                    loading={loading}
                    disabled={isSubmitting || checkPermission()}
                  >
                    Confirm
                  </Button>
                </div>
                {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute top-44 w-full flex justify-center">
          <div className="bg-white rounded-lg py-8 w-1/2 flex justify-center ">
            <div className="w-3/4">
              <div className="flex flex-col items-center justify-center">
                <VerifiedIcon />
                <div className="text-center mt-9">
                  <h3 className="text-heading text-2xl">Action confirmed.</h3>
                  <span className="text-ts font-light">
                    {action === "contact" ? contactMessage : requestMessage}
                  </span>
                </div>
              </div>
              <div className="flex justify-center gap-8 mt-8">
                <Button
                  onClick={() =>
                    navigate(
                      `/${role}/interactions/${
                        action === "contact" ? "projects" : "offers"
                      }`
                    )
                  }
                  color="primary"
                  variant="contained"
                >
                  {`${
                    action === "contact" ? "Go to Projects" : "Go to Offers"
                  }`}
                </Button>
                <Button
                  variant="contained"
                  color="neutral"
                  onClick={() => navigate(`/${role}/dashboard`)}
                >
                  Go to Dashboard
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VendorInquiryForm;
