/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getFundingInterestDetails,
  awardFundingInterest,
} from "@slices/miners/investor-slice";
import SkeletonComponent from "components/investors/Skeleton";
import Button from "components/ui/Button";
import CompleteModal from "./CompleteModal";

const FundingInterestDetails = () => {
  const location = useLocation();
  const { id } = location.state;
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [investmentId, setInvestmentId] = useState();

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getFundingInterestDetails(id));
  }, []);

  const { fundingInterestDetail, awarded, loading } = useSelector(
    (store) => store?.investors
  );

  const handleSubmit = async () => {
    try {
      const response = await dispatch(
        awardFundingInterest({
          id,
          data: { status: "awarded" },
        })
      );

      if (response.payload.status == 201) {
        setModalMessage(response.payload.data.message);
        setInvestmentId(response.payload.data.investment._id);
        setOpenSubmitModal(true);
      } else {
        // console.log('error from then', response);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  return loading ? (
    <SkeletonComponent />
  ) : (
    <div className="text-default h-full flex-1 bg-white px-[34px] py-[54px]">
      <div className="flex flex-col lg:flex-row justify-between">
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 w-[206px] h-[66px]">
          <div className=" h-[60px] w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
            {`${fundingInterestDetail?.account?.name[0]}`}
          </div>
          <div className="flex flex-col justify-start items-start flex-grow-0 ml-4 flex-shrink-0 ">
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-base text-left text-primary">
              {fundingInterestDetail?.account?.name}
            </p>
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-sm text-left text-[#18273a]/[0.94]">
              {fundingInterestDetail?.account?.company}
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-sm font-light text-left text-[#1b2b41]/[0.72]">
              {fundingInterestDetail?.account?.country?.name}
            </p>
          </div>
        </div>

        <div className="hidden lg:block">
          {fundingInterestDetail?.status?.toLowerCase() === "pending" &&
            !awarded && (
              <Button onClick={handleSubmit} size="small">
                Award investment
              </Button>
            )}
        </div>
      </div>

      <div className="flex flex-col justify-start items-start flex-grow-0 mt-6  flex-shrink-0">
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Business email
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {fundingInterestDetail?.isVisible
              ? fundingInterestDetail.account?.email
              : "**************************"}
          </p>
        </div>
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Phone number:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {fundingInterestDetail?.isVisible
              ? fundingInterestDetail.account?.phone?.number
              : "**************************"}
          </p>
        </div>
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="min-w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Additional notes:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {fundingInterestDetail?.proposal?.additional_notes}
          </p>
        </div>
      </div>
      <CompleteModal
        {...{ openSubmitModal, setOpenSubmitModal, modalMessage, investmentId }}
      />
    </div>
  );
};

export default FundingInterestDetails;
