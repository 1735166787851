/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Dialog, Transition } from "@headlessui/react";
import React, { useEffect, Fragment, useState } from "react";
import { Doticon } from "@ui/icons";
import { Button } from "@ui";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  fetchAllNotifications,
  readNotification,
  readAllNotifications,
} from "@slices/settings-slice";

const Notifications = ({ openNotify, setOpenNotify, profiles }) => {
  const { allnotifications } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAllNotifications({ limit: limit }));
  }, [dispatch]);

  const viewNotification = async (x) => {
    const response = await dispatch(readNotification({ id: x?._id }));
    if (response.payload.status === 200 || response.payload.status === 201) {
      if (
        response.payload.data.code == 422 ||
        response.payload.data.code == 400
      ) {
        toast.error(response.payload.data.message);
      } else {
        if (x?.route == "/dashboard") {
          navigate(`/${profiles?.account?.role.slug}/dashboard`);
        } else {
          if (x?.route !== null) {
            navigate(x?.route);
          }
        }
        setOpenNotify(false);
        dispatch(fetchAllNotifications({ limit: limit }));
      }
    } else {
      toast.error(response.payload.msg);
    }
  };

  const viewpreviousNotifications = async () => {
    let newLimit = limit + 10;
    setLimit(newLimit);
    setLoading(true);
    const response = await dispatch(fetchAllNotifications({ limit: newLimit }));
    if (response.payload.status === 200 || response.payload.status === 201) {
      if (
        response.payload.data.code == 422 ||
        response.payload.data.code == 400
      ) {
        setLoading(false);
        toast.error(response.payload.data.message);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error(response.payload.msg);
    }
  };

  const markAllNotifications = async () => {
    const response = await dispatch(readAllNotifications());
    if (response.payload.status === 200 || response.payload.status === 201) {
      if (
        response.payload.data.code == 422 ||
        response.payload.data.code == 400
      ) {
        toast.error(response.payload.data.message);
      } else {
        toast.success(response.payload.data.message);
        dispatch(fetchAllNotifications({ limit: limit }));
      }
    } else {
      toast.error(response.payload.msg);
    }
  };

  const markasRead = async (x) => {
    const response = await dispatch(readNotification({ id: x?._id }));
    if (response.payload.status === 200 || response.payload.status === 201) {
      if (
        response.payload.data.code == 422 ||
        response.payload.data.code == 400
      ) {
        toast.error(response.payload.data.message);
      } else {
        toast.success(response.payload.data.message);
        dispatch(fetchAllNotifications({ limit: limit }));
      }
    } else {
      toast.error(response.payload.msg);
    }
  };

  return (
    <Transition.Root show={openNotify} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenNotify}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-lg">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      >
                        <span className="sr-only">Close panel</span>
                        <span
                          className="icon-close absolute top-4"
                          onClick={() => setOpenNotify(false)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.92 8.42L15.5 7L11.96 10.55L8.42 7L7 8.42L10.55 11.96L7 15.5L8.42 16.92L11.96 13.37L15.5 16.92L16.92 15.5L13.37 11.96L16.92 8.42Z"
                              fill="#1C2E45"
                              fillOpacity="0.6"
                            />
                          </svg>
                        </span>
                        {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-2 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-lg font-medium text-gray-900 border-b-[1px] border-[#1C345442] py-4">
                        Notifications
                        <span
                          className="text-primary text-sm font-light right-heading cursor-pointer"
                          onClick={() => markAllNotifications()}
                        >
                          Mark as all read
                        </span>
                      </Dialog.Title>
                      {/* <div className="flex justify-between my-4 bg-[#EFF0FE]">
                          <p className="text-sm wrap px-4 py-2 text-primary font-normal">
                            Today
                          </p>
                        </div> */}
                      {allnotifications.map((x, i) => (
                        <div
                          className="flex justify-between my-6 ml-2 border-b-[1px] border-[#1C345442] cursor-pointer"
                          key={i + 1}
                        >
                          <div className="flex flex-row justify-between">
                            <div className="w-[25px] mt-2">
                              {x?.is_read ? (
                                <Doticon />
                              ) : (
                                <Doticon color={"#6166B6"} />
                              )}
                            </div>
                            <div
                              className="col-span-3 flex flex-col"
                              onClick={() => viewNotification(x)}
                            >
                              <span className="text-heading text-lg mb-2">
                                {x?.title}
                              </span>
                              <span className="text-light text-sm mb-1">
                                {x?.message}
                              </span>
                              <span className="font-light text-ts text-sm mb-3">
                                {dayjs(x?.created_at).format("MMMM D, YYYY")}{" "}
                                &nbsp;
                                {dayjs(x?.updated_at).fromNow()}
                              </span>
                            </div>
                          </div>
                          {!x?.is_read && (
                            <span
                              className="text-tertiary text-xs font-light w-[30%] text-right mt-2"
                              onClick={() => markasRead(x)}
                            >
                              Mark as read
                            </span>
                          )}
                        </div>
                      ))}
                      <div className="flex justify-center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => viewpreviousNotifications()}
                        >
                          {loading
                            ? "Loading..."
                            : "View previous notifications"}
                        </Button>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {/* Replace with your content */}
                      <div className="absolute inset-0 px-4 sm:px-6">
                        <div
                          className="h-full border-gray-200"
                          aria-hidden="true"
                        />
                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Notifications;
