/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Input, TextArea, Checkbox } from "@ui";
import {
  fetchAllActiveMines,
  //  searchMineByNameOrType,
} from "@slices/miners/mines-slice";

import { getServiceOptions } from "store/slices/miners/vendor-slice";

import { FileUpload } from "pages/onboarding/index";
import ErrorMessage from "components/ErrorMessage";
import PaperClip from "@ui/icons/PaperClip";
import DatePicker from "react-datepicker";
import { useEffect } from "react";

const Description = ({
  errors,
  register,
  productImage,
  setProductImage,
  uploadProductImage,
  handleChange,
  setMine,
  setValue,
  shipping_type,
  demand_mineral,
  watch,
}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [delivery_period, setDeliveryPeriod] = useState("");
  const formatDate = (date_val) => {
    const start_date = new Date(date_val);
    const day =
      start_date.getDate() < 10
        ? `0${start_date.getDate()}`
        : start_date.getDate();
    const month =
      start_date.getMonth() + 1 < 10
        ? `0${start_date.getMonth() + 1}`
        : start_date.getMonth() + 1;
    return `${day}-${month}-${start_date.getFullYear()}`;
  };

  const { measurements } = useSelector((store) => store.miner_vendors);

  const { mines } = useSelector((state) => state.mines);
  let minesData = [];
  if (mines?.length > 0) {
    mines?.forEach((y) => {
      minesData.push({
        name: y.mine_name,
        id: y._id,
        slug: y.mine_name.toLowerCase(),
      });
    });
  }

  const handleDateRangeSelected = (date_val) => {
    const time_range =
      formatDate(date_val[0]) + " ~ " + formatDate(date_val[1]);

    setDateRange(date_val);
    setDeliveryPeriod(time_range);

    setValue("start_date", date_val[0]);
    setValue("end_date", date_val[1]);
  };
  const { supplyFormData } = useSelector((store) => store.miner_demands);

  const handleMineChange = (e, { name }) => {
    setMine(e);
    setValue(name, e.id, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  let files = watch("product_image");
  const dispatch = useDispatch();
  const removeFile = (id) => {
    setValue("product_image", []);
    files = files.filter((file) => file.id !== id);
    setProductImage(files);
  };
  const [unit, setUnit] = useState("");
  const handleUnitChange = (e, { name }) => {
    setValue(name, e.slug, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setUnit(e.slug);
  };
  useEffect(() => {
    dispatch(fetchAllActiveMines());
    dispatch(getServiceOptions());
    setValue("mineral", demand_mineral?.slug);
  }, [dispatch, demand_mineral?.slug]);

  const handleCalculatedFields = (name, value) => {
    setValue(name, value);
    const [treatment_cost, quantity, price] = watch([
      "treatment_cost",
      "quantity",
      "price",
    ]);
    let productTotalValue = treatment_cost * quantity + price * quantity;
    setValue("total_value", productTotalValue);
  };
  return (
    <div className="text-default  w-[796px] bg-white px-[34px]">
      <p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434]">
        Description
      </p>
      <p className="flex-grow-0 flex-shrink-0 w-[796px] text-base font-light text-left text-[#1b2b41]/[0.72]">
        Enter the details for the mineral resource as specified below.
      </p>

      <p className="flex-grow-0 flex-shrink-0 text-[18px] mb-2 text-left text-[#192434]">
        Supply details
      </p>

      <div className="mt-4">
        <Input
          label="Product name"
          id="product_name"
          {...register("product_name")}
          name="product_name"
          onChange={handleChange}
        />
        {errors.product_name && (
          <ErrorMessage>{errors.product_name?.message}</ErrorMessage>
        )}
      </div>

      {/* file upload */}
      <div className="mt-4">
        <FileUpload
          source="Product image (20mb max)"
          handleChange={uploadProductImage}
          label="Upload image ..."
          dropZoneLabel="Drop image here"
          allowedTypes={["png", "jpg", "jpeg"]}
        />
      </div>
      <div>
        <p className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] align-center">
          <span>{<PaperClip />}</span>
          <span>
            {" "}
            {productImage && productImage[0]
              ? `${productImage[0]?.name}`
              : "no files uploaded"}
          </span>
          <button
            className="text-red text-[16px] px-2"
            onClick={() => removeFile(productImage[0].id)}
          >
            {" "}
            x{" "}
          </button>
        </p>
        {/* {productImage.map((file) => {
          return (
            <p
              key={file.name}
              className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] align-center"
            >
              <span>{<PaperClip />}</span>
              <span>{file ? `${file.name}` : "no files uploaded"}</span>
              <button
                className="text-red text-[16px] px-2"
                onClick={() => removeFile(file.name)}
              >
                {" "}
                x{" "}
              </button>
            </p>
          );
        })} */}
      </div>
      {errors.product_image && (
        <ErrorMessage>{errors.product_image?.message}</ErrorMessage>
      )}

      <div className="grid grid-cols-2 mt-4 gap-x-4">
        <div className="">
          <Select
            name="mine"
            label="Source mine"
            options={minesData}
            onChange={handleMineChange}
          />
          {errors.mine && <ErrorMessage>{errors.mine?.message}</ErrorMessage>}
        </div>

        <div className="flex-1">
          <Input
            label="Mineral type"
            {...register("mineral")}
            name="mineral"
            value={demand_mineral.slug}
            disabled={true}
            onChange={handleChange}
          />
          {errors.mineral && (
            <ErrorMessage>{errors.mineral?.message}</ErrorMessage>
          )}
        </div>
      </div>

      <div className="mt-2">
        <TextArea
          id="description"
          resizable
          row={7}
          type="textarea"
          label="Description"
          name="description"
          // size="mid"
          maxHeight="200px"
          {...register("description")}
        />
        {errors.description && (
          <ErrorMessage>{errors.description?.message}</ErrorMessage>
        )}
      </div>

      <div className="grid grid-cols-2  gap-x-4">
        <div>
          <Select
            name="unit_of_measurements"
            placeholder="Select Unit of Measurement"
            label="Unit of measurement"
            options={
              measurements
                ? Object.keys(measurements).map((key) => ({
                    name: measurements[key],
                    slug: key,
                  }))
                : []
            }
            onChange={handleUnitChange}
          />
          {errors.unit_of_measurements && (
            <ErrorMessage>{errors.unit_of_measurements?.message}</ErrorMessage>
          )}
        </div>

        <div className="flex-1">
          <Input
            className="pl-4"
            label="Quantity"
            id="quantity"
            type="number"
            {...register("quantity")}
            name="quantity"
            onChange={(e) =>
              handleCalculatedFields(e.target.name, e.target.value)
            }
          />
          {errors.quantity && (
            <ErrorMessage>{errors.quantity?.message}</ErrorMessage>
          )}
        </div>
      </div>

      <div className="mt-4 flex gap-7">
        <div className="flex-1">
          <Input
            isMoneyInput
            label="Treatment cost"
            postfix={`/${unit}`}
            id="treatment_cost"
            type="number"
            {...register("treatment_cost")}
            onChange={(e) =>
              handleCalculatedFields(e.target.name, e.target.value)
            }
          />
          {errors.treatment_cost && (
            <ErrorMessage>{errors.treatment_cost?.message}</ErrorMessage>
          )}
        </div>
        <div className="flex-1">
          <Input
            isMoneyInput
            label="Price"
            postfix={`/${unit}`}
            type="number"
            id="price"
            {...register("price")}
            name="price"
            onChange={(e) =>
              handleCalculatedFields(e.target.name, e.target.value)
            }
          />
          {errors.price && <ErrorMessage>{errors.price?.message}</ErrorMessage>}
        </div>
      </div>

      <div className="mt-4 grid grid-cols-2 gap-x-4">
        <div className="flex-1">
          <Input
            isMoneyInput
            label="Total value"
            id="total_value"
            type="number"
            name="total_value"
            {...register("total_value")}
            readOnly
          />
          {errors.total_value && (
            <ErrorMessage>{errors.total_value?.message}</ErrorMessage>
          )}
        </div>
        <div className="mt-4">
          <label
            className="block text-xs mb-2  text-lightgray"
            htmlFor="delivery"
          >
            Delivery Period
          </label>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            minDate={new Date()}
            onChange={(update) => {
              handleDateRangeSelected(update);
            }}
            isClearable={true}
          />
          {(errors.start_date || errors.end_date) && (
            <ErrorMessage>{errors.start_date?.message}</ErrorMessage>
          )}
        </div>
      </div>

      <div className="mt-4">
        <Input
          label="Preferred shipping type"
          value={shipping_type?.slug}
          disabled
        />
      </div>

      <div className="text-checkbox flex flex-col text-sm mt-8">
        <p className="flex-grow-0 flex-shrink-0 text-[18px] mb-2 text-left text-[#192434]">
          Terms and conditions
        </p>
        <div className="flex items-center">
          {/* <input
						className="appearance-none checked:bg-primary checked:hover:bg-primary focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary rounded border-none h-5 w-5 mr-4 bg-check cursor-pointer accent-primary bg-primary"
						type="checkbox"
						id="terms"
						name="terms"
						{...register('terms')}
					/> */}
          <Checkbox
            value="true"
            onChange={handleChange}
            {...register("terms")}
          />
          <label className="text-checkbox" htmlFor="agree">
            I agree to the{" "}
            <span className="text-primary cursor-pointer">
              Terms and Conditions
            </span>{" "}
          </label>
        </div>
        {errors.terms && <ErrorMessage>{errors.terms?.message}</ErrorMessage>}
      </div>
    </div>
  );
};

export default Description;
