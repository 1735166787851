/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

import React, { useState, useEffect } from "react";
import { Btn as Button } from "@atoms/button/button";
import Skeleton from "@components/auctions/Skeleton";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { usePath, useAuth } from "@hooks";
import { checkPermission } from "shared/utility";
import { getCountries } from "@slices/onboard-slice";

import Navbar from "../../../components/layouts/navbar";
import { Input, TextArea } from "@atoms/input";
import { PhoneInputFormatter } from "@shared/formatter";
import * as yup from "yup";
import { Avatar, ProfileAvatar } from "@ui";
import { file_uploader } from "@slices/file.slice";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { SuccessResponse } from "config/status-code";
import { useDispatch, useSelector } from "react-redux";
import { OTPInfoModal } from "./otpInfo";
import {
  getProfile,
  updateProfile,
  uploadProfile,
  removeImage,
} from "@slices/settings-slice";
// file upload imports
import FileUpload from "../../onboarding/select-category/shared/FileUpload";
import FileUploadImage from "../../onboarding/select-category/shared/FileUploadImage";
import PaperClip from "@ui/icons/PaperClip";

const schema = yup.object().shape({
  fullname: yup.string().required("Please add your fullname"),
  profileType: yup.string().required("Please add your profile type"),
  companyname: yup.string().required("Please add your company name"),
  description: yup.string().required("Please provide a description"),
  address: yup.string().required("Address is required"),
  postal: yup.number().required().typeError("Postal code is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  email: yup
    .string()
    .email("Invalid email")
    .max(255)
    .required("Email is required"),
});

const VendorSettingsProfile = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const _FormData = new FormData();
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const { loading, data } = useSelector((state) => state.settings);
  const countries = useSelector((state) => state.onboard);
  const [company_registration_certificate, setCompanyRegistrationCertificate] =
    useState([]);

  const [profile, setProfile] = useState();
  const { user } = useAuth();
  const [firstname, lastname] = user.name.split(" ");
  const initials = firstname?.charAt(0) + (lastname ? lastname?.charAt(0) : "");
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setProfile(data);
      setCompanyRegistrationCertificate(data.company_registration_docs);
      countries?.countries.forEach((x) => {
        if (x?.phone_code === data?.phone_code) {
          setProfile({
            ...data,
            iso_code: x.iso_code,
          });
        }
      });
    }
  }, [data]);

  const removeFile = (type, id) => {
    // refactor
    switch (type) {
      case "company_registration_certificate":
        setCompanyRegistrationCertificate((prevState) =>
          prevState.filter((file) => file.id !== id)
        );
        break;
      default:
        break;
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfile((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const deleteImage = () => {
    dispatch(removeImage())
      .then((response) => {
        if (
          response.payload.status &&
          SuccessResponse(response.payload.status)
        ) {
          toast.success(response.payload.data.message);

          dispatch(getProfile());
        }
        throw "error uploading file";
      })
      .catch((error) => toast.error(error.message));
  };

  const handleInputAddress = (event) => {
    const { name, value } = event.target;
    setProfile((prevState) => {
      return {
        ...prevState,
        location: {
          latitude: profile.location.latitude,
          longitude: profile.location.longitude,
          postal_code: profile.location.postal_code,
          [name]: value,
        },
      };
    });
  };

  const submitDetails = async () => {
    for (const file of files) {
      _FormData.append("company_registration_certificate", file);
    }
    // _FormData.append(JSON.stringify(profile));
    dispatch(updateProfile({formdata:profile,id:data._id}))
      .then((response) => {
        if (response.payload.status === 200 || response.payload.status === 201) {
          toast.success(response.payload.data.message);
          dispatch(getProfile());
          if (response?.payload?.data?.phone?.phone_change == true) {
            setOpen(true);
          }
          //   window.location.reload();
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const uploadCompanyRegistration = (file) =>
    handleChange(file, "company_registration_certificate");

  const uploadImage = (file) => handleChange(file, "image");

  const handleChange = async (FileList, type) => {
    /** check if filelist is empty  */
    if (FileList.length <= 0) {
      return toast.error("No file selected");
    }
    /** Declare form data */
    const _FormData = new FormData();
    for (const file of FileList) {
      _FormData.append("data", file);
    }
    /** set upload type */
    // _FormData.append("type", type);
    // setFiles([...files, ...FileList]);
    dispatch(uploadProfile(_FormData))
      .then((response) => {
        if (
          response.payload.status &&
          SuccessResponse(response.payload.status)
        ) {
          dispatch(getProfile());

          /** Set files to state */
          uploadTypeSetter(type, response.payload.data.files);
        }
        throw "error uploading file";
      })
      .catch((error) => toast.error(error.message));
  };

  const uploadTypeSetter = (type, array) => {
    switch (type) {
      case "company_registration_certificate":
        setCompanyRegistrationCertificate((prevState) =>
          prevState.concat(array)
        );
        break;
      default:
        break;
    }
  };
  /** Format phone then set to state */
  const getFormattedNumber = (value, data) => {
    const input = PhoneInputFormatter(value, data);
    setProfile({
      ...profile,
      phone_code: input.phone_code,
      phone_number: input.phone_number,
      iso_code: input.iso_code,
    });
  };

  return (
    <><OTPInfoModal open={open} setOpen={setOpen} phone_number={data?.phone_number} phone_code={data?.phone_code} iso_code={profile?.iso_code} profile={profile} /><div className="w-[1125px]">
      <div className="flex flex-row bg-white py-10 pl-10 w-[1125px]">
        <div className="flex flex-col justify-start items-start mr-4">
          <p className="flex-grow-0 flex-shrink-0 text-base text-left mb-6 text-primary cursor-pointer">
            Profile
          </p>

          <div
              className="flex-grow-0 flex-shrink-0 text-base font-light text-left mb-6 text-[#1b2b41]/[0.72] cursor-pointer"
              onClick={() => {
                navigate("/vendor/settings/notification");
              }}
            >
              Notification settings
            </div>

            <div className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72] cursor-pointer"   onClick={() => {
                navigate("/vendor/settings/security");
              }}>
              Security
            </div>
        </div>

        <svg
          width="1"
          height="1374"
          viewBox="0 0 1 1374"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-32"
          preserveAspectRatio="xMidYMid meet"
        >
          <line
            x1="0.375"
            y1="-1.63918e-8"
            x2="0.37506"
            y2="1374"
            stroke="#1C3454"
            strokeOpacity="0.26"
            strokeWidth="0.75"
          ></line>
        </svg>

        <div className="w-[503px] ">
          <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-6">
            <p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434]">
              Profile
            </p>
            <div className="flex justify-start items-end flex-grow-0 flex-shrink-0 gap-[29px]">
              <div className=" h-[120px] w-[120px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
              {data?.profile_img?.length > 0 ? (
                    <img
                      className="rounded-full h-[120px] w-[120px] flex items-center justify-center text-white bg-primary"
                      src={`${
                        data?.profile_img?.length > 0
                          ? data?.profile_img[0]?.url
                          : "https://cdn.smehost.net/2020sonymusiccouk-ukprod/wp-content/uploads/2019/10/Miley-Cyrus-03_MidnightSky_WhiteLight_2302.jpg"
                      }`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src =
                          "https://cdn.smehost.net/2020sonymusiccouk-ukprod/wp-content/uploads/2019/10/Miley-Cyrus-03_MidnightSky_WhiteLight_2302.jpg";
                      }}
                      alt=""
                    />
                  ) : (
                    <ProfileAvatar bgPrimary={true}>{initials}</ProfileAvatar>
                  )}
              </div>
              <div style={{ display: "none" }}>
                <Navbar userType="vendor" image={data} />
              </div>

              <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 gap-3">
                <FileUploadImage handleChange={uploadImage} disable={checkPermission("Settings")} />
                {!checkPermission("Settings") && (
                     <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 h-[42px] relative gap-2 px-4 py-2">
                     <p 
                       disabled={true}
                       className="flex-grow-0 flex-shrink-0 text-base text-center text-[#1b2b41]/[0.72] cursor-pointer"
                       onClick={() => deleteImage()}
                     >
                       Remove
                     </p>
                   </div>
                  )}
              </div>
            </div>
          </div>
          <br />
          {loading ? (
            <>
              {Array(1)
                .fill(null)
                .map((_, index) => (
                  <Skeleton key={index} style={{ width: "31em" }} />
                ))}
            </>
          ) : (
            <div className="flex flex-col justify-start items-start  mt-6 ">
              <p className="flex-grow-0 flex-shrink-0 text-lg text-left text-black">
                Basic information
              </p>

              <form className="w-full">
                <div className="mt-4 ">
                  <label className="block text-sm mb-2   text-lightgray">
                    Profile Type
                  </label>
                  <Input
                    type="text"
                    name="profileType"
                    placeholder="Vendor"
                    value="Vendor" />
                  {/* <p className="h-2 flex justify-center align-center mt-1 text-primary text-xs">
        {errors.profileType?.message}
      </p> */}
                </div>

                <div className="mt-4">
                  <label className="block text-sm mb-2  text-lightgray">
                    Name
                  </label>
                  <Input
                    type="text"
                    name="name"
                    defaultValue={data.name}
                    onChange={handleInputChange}
                    placeholder={data.name} />
                  {/* <p className='h-2 flex justify-center align-center mt-1 text-primary text-xs'>
                  {errors.fullname?.message}
                </p> */}
                </div>

                <div className="mt-4">
                  <label className="block text-sm  mb-2 text-lightgray">
                    Business email address
                  </label>
                  <Input
                    type="text"
                    name="email"
                    onChange={handleInputChange}
                    defaultValue={data.email}
                    placeholder={data.email} />
                  {/* <p className='h-2 flex justify-center align-center mt-1 text-primary text-xs'>
                  {' '}
                  {errors.email?.message}
                </p> */}
                </div>

                <div className="mt-4 ">
                  <label className="block text-sm mb-1 text-lightgray">
                    Phone Number
                  </label>
                  <PhoneInput
                    isValid={(value) => {
                      if (value.length < 7 && value.length > 1) {
                        return "* valid phone number is required";
                      } else if (value.length < 7) {
                        return false;
                      } else {
                        return true;
                      }
                    } }
                    type="text"
                    country="us"
                    value={data.phone_code + data.phone_number}
                    onChange={(value, data) => getFormattedNumber(value, data)}
                    inputProps={{ name: "phone", required: true }}
                    masks={{ fr: "(...) ..-..-..", at: "(....) ...-...." }} />
                  {/* <div className='flex flex-row'>
                  <div className='bg-fill border-none w-24 mr-1 focus:outline-none break-all focus:border-primary focus:ring-1 focus:ring-primary appearance-none rounded cursor-text text-input text-sm py-2 px-4'>
                    {' '}
                    <span className='flex justify-end align-center '>
                      <Shape className='h-4' />
                    </span>
                  </div>

                  <Input name='phone' type='number' defaultValue={data.phone_number} />
                  <p>{errors?.phone.message}</p>
                </div> */}
                </div>

                {/* <div className='mt-8 flex justify-end'>
                  <Button size='small' type='submit'>
                    Save Changes
                  </Button>
                </div> */}
              </form>
            </div>
          )}

          {loading ? (
            <div></div>
          ) : (
            <div
              className="flex flex-col justify-start items-start  mt-6 "
              style={{ display: "none" }}
            >
              <p className="flex-grow-0 flex-shrink-0 text-lg text-left text-black">
                Additional information
              </p>

              <div className="w-full">
                <div className="mt-4 ">
                  <label className="block text-xs mb-2  text-lightgray">
                    Company name
                  </label>
                  <Input
                    type="text"
                    name="company_name"
                    onChange={handleInputChange}
                    defaultValue={data.company_name} />
                  {/* <p className="h-2 paragraph text-primary text-xs mt-2">
                  {errors.companyname?.message}
                </p> */}
                </div>
                <div className="mt-4 ">
                  <label className="block text-xs mb-2  text-lightgray">
                    Description
                  </label>
                  <TextArea
                    type="text"
                    label="Description"
                    name="description"
                    onChange={handleInputChange}
                    defaultValue={data.description} />
                  {/* <p className="h-2 paragraph text-primary text-xs mt-2">
                  {errors.companyname?.message}
                </p> */}
                </div>
                <div className="mt-4 ">
                  <label className="block text-xs mb-2  text-lightgray">
                    Address
                  </label>
                  <Input
                    type="text"
                    placeholder="12732 Bacchus Highway"
                    name="address"
                    onChange={handleInputAddress}
                    defaultValue={data?.location?.address} />
                  {/* <SearchLocation defaultValue={data?.location?.address} /> */}
                  {/* <p className="h-2 paragraph text-primary text-xs mt-2">
                  {errors.companyname?.message}
                </p> */}
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="mt-4">
                    <label className="block text-xs mb-2  text-lightgray">
                      Country
                    </label>
                    <Input
                      id="country"
                      label="Country"
                      type="text"
                      defaultValue={data?.country?.name}
                      onChange={handleInputChange}
                      placeholder="Country"
                      name="country" />
                    <label className="block text-xs mb-2 mt-2  text-lightgray">
                      State
                    </label>
                    <Input
                      id="state"
                      type="text"
                      name="state"
                      defaultValue={data?.state}
                      onChange={handleInputChange}
                      label="State"
                      placeholder="State" />
                  </div>
                  <div className="mt-4">
                    <label className="block text-xs mb-2  text-lightgray">
                      Postal Code
                    </label>
                    <Input
                      label="Postal code"
                      defaultValue={data?.location?.postal_code}
                      type="text"
                      placeholder="Postal code" />
                    <label className="block text-xs mb-2 mt-2 text-lightgray">
                      City
                    </label>
                    <Input
                      id="city"
                      type="text"
                      defaultValue={data?.city}
                      placeholder="City"
                      label="City"
                      name="city" />

                    {/* chips */}
                  </div>
                </div>

                {/* File upload */}
                <div className="w-full py-4 px-4 ml-2.5 my-2 border-l-2 border-[#1C345442]">
                  <FileUpload handleChange={uploadCompanyRegistration} />
                  <div>
                    {company_registration_certificate?.map((file, index) => {
                      return (
                        <p
                          key={index + 1}
                          className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] align-center"
                        >
                          <span>{<PaperClip />}</span>
                          <span>
                            {file ? `${file.name}` : "no files uploaded"}
                          </span>
                          <button
                            className="text-primary text-[15px] px-2  "
                            onClick={() => removeFile(
                              "company_registration_certificate",
                              file.id
                            )}
                          >
                            x
                          </button>
                        </p>
                      );
                    })}
                  </div>
                </div>
                {/* File upload */}

                {/* <div className="mt-4 ">
                  <label className="block text-sm mb-1 text-lightgray">
                    Phone Number
                  </label>
                  <div className="flex flex-row">
                    <div className="bg-fill border-none w-24 mr-1 focus:outline-none break-all focus:border-primary focus:ring-1 focus:ring-primary appearance-none rounded cursor-text text-input text-sm py-2 px-4">
                      {" "}
                      <span className="flex justify-end align-center ">
                        <Shape className="h-4" />
                      </span>
                    </div>
  
                    <Input name="phone" type="number" {...register("phone")} />
                    <p>{errors?.phone.message}</p>
                  </div>
                </div> */}
                {/*
                <div className="mt-2">
                  <label className="block text-xs mb-2  text-lightgray">
                    Description
                  </label>
  
                  <TextArea
                    placeholder="Bingham Canyon Mine is an open-pit mining operation extracting a large porphyry copper deposit southwest of Salt Lake City, Utah, in the Oquirrh Mountains."
                    name="description"
                    type="textarea"
                    {...register("description")}
                  />
                  <p className="h-2 paragraph text-primary text-xs mt-2">
                    {errors.description?.message}
                  </p>
                </div>
  
                <div className="mt-2">
                  <label className="block text-xs mb-2  text-lightgray">
                    Address
                  </label>
                  <Input
                    type="text"
                    placeholder="12732 Bacchus Highway"
                    name="address"
                    {...register("address")}
                  />
                  <p className="h-2 paragraph text-primary text-xs mt-2">
                    {errors.address?.message}
                  </p>
                </div> */}
                {/*
                <div className="flex flex-wrap -mx-2">
                  <div className="w-full md:w-1/2 px-3 mt-2 ">
                    <label
                      className="block text-xs mb-2  text-lightgray"
                      htmlFor="city"
                    >
                      City
                    </label>
                    <Input
                      id="city"
                      type="text"
                      placeholder="Herriman"
                      name="city"
                      {...register("city")}
                    />
                  </div>
  
                  <div className="w-full md:w-1/2 px-3 mt-2">
                    <label
                      className="block text-xs mb-2  text-lightgray"
                      htmlFor="state"
                    >
                      State
                    </label>
                    <Input
                      id="state"
                      type="text"
                      placeholder="Utah"
                      name="state"
                      {...register("state")}
                    />
                  </div>
                </div> */}
                {/* an api for countries will be provided */}

                {/* <div className="flex flex-wrap -mx-3 mt-2">
                  {" "}
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block text-xs mb-2  text-lightgray"
                      htmlFor="postal"
                    >
                      Postal code
                    </label>
                    <Input
                      id="postal"
                      type="text"
                      placeholder="84006"
                      name="postal"
                      {...register("postal")}
                    />
                    <p className="h-2 paragraph text-primary text-xs mt-2">
                      {errors.postal?.message}
                    </p>
                  </div>
                  <div className="w-full md:w-1/2 px-3 ">
                    <label
                      className="block text-xs mb-2  text-lightgray"
                      htmlFor="country"
                    >
                      Country
                    </label>
                    <select
                      className="bg-fill border-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded cursor-text text-input py-2 px-4 text-sm"
                      id="country"
                      type="dropdown"
                    >
                      <option>United States</option>
                    </select>
                  </div>
                </div> */}

                <div className="mt-4 flex justify-end">
                  <Button
                    size="small"
                    onClick={() => {
                      submitDetails();
                    } }
                  >
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          )}
          <div className="mt-4 flex justify-end">
            <Button
              size="small"
              disabled={checkPermission("Settings")}
              onClick={() => {
                submitDetails();
              } }
            >
              Save changes
            </Button>
          </div>
          <p className="text-xs text-check">
            Daniola Corp runs a thorough KYC (Know Your Client) process to
            ensure full compliance. Daniola Corp also reserves the right to
            request additional information for KYC purposes.
          </p>
        </div>
      </div>
    </div></>
  );
};

export default VendorSettingsProfile;
