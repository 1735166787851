/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "@config/url";
import { toast } from "react-toastify";
import axios from "@config/axios-instance";
const GET = "get";
const DELETE = "delete";
const POST = "post";

const initialState = {
  activeStep: 0,

  isLoading: false,
  error: null,
  isError: false,
  isSuccess: false,
  vendors: null,
  inventory_rate:0 ,
  investment_rate:0 ,
  miner_demand_charge:0,
  offtaker_transaction_charge:0,
  demand_commission_charge:0,
  vendor_subscription:0,
  generalSettingID:"",
  vendor: null,
  transactions: [],
  transaction: null,
  countries: [],
  service_options: {},
  requests: null,
  request: null,
  offering: null,
};

export const vendorConfig = (method, url, data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      "Authorization": "bearer "+ token
    },
    data,
  };
};

export const getVendors = createAsyncThunk(
  "miner/getVendors",
  async (name, thunkAPI) => {
    try {
      // thunkAPI.dispatch(openModal());
      const resp = await axios.get(API.minerFetchVendors);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const searchVendors = createAsyncThunk(
  "miner/searchVendors",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.searchminerVendors(
          payload?.search ? payload.search : "",
          payload?.country ? payload.country : ""
        )
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getVendor = createAsyncThunk(
  "miner/getVendor",
  async (id, thunkAPI) => {
    try {
      // thunkAPI.dispatch(openModal());
      const resp = await axios.get(API.minerFetchVendor(id));
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getCountries = createAsyncThunk(
  "miner/getCountries",
  async (name, thunkAPI) => {
    try {
      // thunkAPI.dispatch(openModal());
      const resp = await axios.get(API.fetchAllCountries);
      // console.log(resp);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getServiceOptions = createAsyncThunk(
  "miner/getServiceOptions",
  async (name, thunkAPI) => {
    try {
      // thunkAPI.dispatch(openModal());
      const resp = await axios.get(API.fetchServiceOptions);
      // console.log(resp);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }

)



export const contactVendor = createAsyncThunk(
  "offtakers/contactVendor",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.minerContactVendor(id), payload);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const requestService = createAsyncThunk(
  "offtakers/requestService",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.minerRequestService, payload);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setOffering: (state, action) => {
      state.offering = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.vendors) state.vendors = action.payload.vendors.docs;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getVendors.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(searchVendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchVendors.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.vendors) state.vendors = action.payload.vendors.docs;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(searchVendors.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVendor.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.vendor) state.vendor = action.payload;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getVendor.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getCountries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.docs)
          state.countries = action.payload.docs;
        // if (action.payload.service_options)
        //   state.service_options = action.payload.service_options;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getCountries.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getServiceOptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getServiceOptions.fulfilled, (state, action) => {
        state.isLoading = false;
         if (action.payload.docs)
           state.investment_type = action.payload.docs[0].investment_type;
          state.generalSettingID = action.payload.docs[0]._id;
           state.production_phase = action.payload.docs[0].production_phase;
           state.amount_to_pay = action.payload.docs[0].seek_funding_rate;
           state.service_options = action.payload.docs[0].service_options;
           state.measurements = action.payload.docs[0].unit_of_measurements;
           state.shippings = action.payload.docs[0].shipment_options;
           state.inventory_rate = action.payload.docs[0].inventory_rate;
           state.investment_rate = action.payload.docs[0].investment_rate;
           state.miner_demand_charge = action.payload.docs[0].miner_demand_charge;
           state.offtaker_transaction_charge = action.payload.docs[0].offtaker_transaction_charge;
           state.demand_commission_charge = action.payload.docs[0].demand_commission_charge;
           state.vendor_subscription = action.payload.docs[0].vendor_subscription;

      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getServiceOptions.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(contactVendor.pending, (state) => {
        state.loading = true;
      })
      .addCase(contactVendor.fulfilled, (state, { payload }) => {
        //state.data = payload.data.files
        state.status = payload.status;
        state.loading = false;
      })
      .addCase(contactVendor.rejected, (state, { payload }) => {
        //      state.message = payload.message;
        state.error = true;
        state.loading = false;
        // toast.error(payload.message);
      })
      .addCase(requestService.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestService.fulfilled, (state, { payload }) => {
        //state.data = payload.data.files
        state.status = payload.status;
        state.loading = false;
      })
      .addCase(requestService.rejected, (state, { payload }) => {
        //      state.message = payload.message;
        state.error = true;
        state.loading = false;
        // toast.error(payload.message);
      });
  },
});

export const { setOffering } = vendorSlice.actions;

export default vendorSlice.reducer;
