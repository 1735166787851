const SlidersIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 4H13V7H11V4Z" fill="#1C2E45" fillOpacity="0.6" />
      <path
        d="M10 8V11H11V20H13V11H14V8H10Z"
        fill="#1C2E45"
        fillOpacity="0.6"
      />
      <path d="M6 4H8V12H6V4Z" fill="#1C2E45" fillOpacity="0.6" />
      <path d="M5 13V16H6V20H8V16H9V13H5Z" fill="#1C2E45" fillOpacity="0.6" />
      <path d="M16 4H18V14H16V4Z" fill="#1C2E45" fillOpacity="0.6" />
      <path
        d="M15 15V18H16V20H18V18H19V15H15Z"
        fill="#1C2E45"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default SlidersIcon;
