/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import axios from "axios"
import { SignOut } from "@slices/user-slice";
import { SignInUrl } from "@config/app-url";

let store

export const injectStore = _store => {
  store = _store
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    // timeout: 1000,
    headers: {}
});  

// Add a request interceptor
instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    let token = JSON.parse(localStorage.getItem("token"))
    if(Object.keys(config?.data ? config?.data : "").length > 0 && token) {
      config.headers.credentials = 'include';
      
      config.headers["Authorization"] = "bearer " + token;
        
      config.headers['Content-Type'] = 'application/json';
    }
    else {
      if (token) {
        config.headers.credentials = 'include';
      
        config.headers["Authorization"] = "bearer " + token;
        
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    }
   
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
      window.location = SignInUrl
        // store.dispatch(SignOut()).then(() => {
        //     window.location = SignInUrl
        // });        
    }  
    return Promise.reject(error);
});
  
export default instance