const DatabaseIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6.5C18 7.328 15.314 8 12 8C8.686 8 6 7.328 6 6.5C6 5.672 8.686 5 12 5C15.314 5 18 5.672 18 6.5Z"
        fill="#D9E8FC"
        fillOpacity="0.7"
      />
      <path
        d="M12 9C8.7 9 6 8.3 6 7.5V10.5C6 11.3 8.7 12 12 12C15.3 12 18 11.3 18 10.5V7.5C18 8.3 15.3 9 12 9Z"
        fill="#D9E8FC"
        fillOpacity="0.7"
      />
      <path
        d="M12 13C8.7 13 6 12.3 6 11.5V14.5C6 15.3 8.7 16 12 16C15.3 16 18 15.3 18 14.5V11.5C18 12.3 15.3 13 12 13Z"
        fill="#D9E8FC"
        fillOpacity="0.7"
      />
      <path
        d="M12 17C8.7 17 6 16.3 6 15.5V18.5C6 19.3 8.7 20 12 20C15.3 20 18 19.3 18 18.5V15.5C18 16.3 15.3 17 12 17Z"
        fill="#D9E8FC"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default DatabaseIcon;
