/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";

import { contactVendor } from "store/slices/vendors/vendor-slice";
import {
  getCountries,
  getServiceOptions
} from "store/slices/miners/vendor-slice";

import { useDispatch, useSelector } from "react-redux";

import { VendorInquiryForm } from "components/index";

function ContactVendor() {
  const { countries, service_options } = useSelector((store) => store.miner_vendors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getServiceOptions());
  }, []);

  return (
    <VendorInquiryForm
      countries={countries}
      service_options={service_options}
      dispatchFunction={contactVendor}
      action="contact"
      role="vendor"
    />
  );
}

export default ContactVendor;
