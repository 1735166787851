/* eslint-disable react/prop-types */
import * as React from "react";

function SvgShape(props) {
  const { transform, height } = props;
  return (
    <svg
      width={12}
      height={7}
      fill="none"
      transform="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.505.337a.897.897 0 0 0-1.274.07A.917.917 0 0 0 .3 1.694l4.957 4.592c.342.31.862.31 1.205 0l5.071-4.696a.917.917 0 0 0 .07-1.287.897.897 0 0 0-1.275-.07L5.86 4.383 1.505.338Z"
        fill="#1C2E45"
        fillOpacity={0.6}
        transform={transform}
        height={height}
      />
    </svg>
  );
}
export default SvgShape;
