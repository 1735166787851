/* eslint-disable react/prop-types */
export const chipOptions = [
  { slug: "molybdenum", name: "Molybdenum" },
  { slug: "copper", name: "Copper" },
  { slug: "gold", name: "Gold" },
  { slug: "silver", name: "Silver" },
];

const Chip = ({ name, text, noIcons, onClick, id }) => {
  return (
    <span className="px-2 py-1 rounded whitespace-nowrap text-[#6166B6] bg-primary-light font-extralight text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
      {text}
      {!noIcons && (
        <button
          className="bg-transparent hover focus:outline-none ml-2"
          onClick={() => onClick(name, id)}
        >
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.28008 1.61329L6.33341 0.666626L3.97341 3.03329L1.61341 0.666626L0.666748 1.61329L3.03341 3.97329L0.666748 6.33329L1.61341 7.27996L3.97341 4.91329L6.33341 7.27996L7.28008 6.33329L4.91341 3.97329L7.28008 1.61329Z"
              fill="#6166B6"
            />
          </svg>
        </button>
      )}
    </span>
  );
};

const Chips = ({ chips, noIcons, onClick, id }) => {
  return (
    <>
      {chips &&
        chips?.map((chip) => (
          <Chip
            key={chip?.name}
            id={id}
            text={chip.name ? chip.name : chip}
            name={chip?.slug}
            noIcons={noIcons}
            onClick={onClick}
          />
        ))}
    </>
  );
};

export default Chips;
