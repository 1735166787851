/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Button } from "@ui";
import { FileUploader } from "react-drag-drop-files";
import { Upload } from "@atoms/icons";
import { UserContext } from "@context/index";

const fileTypes = ["png", "jpg", "jpeg", "pdf", "gif"];

const FileUploadImage = ({
  children,
  disable,
  handleChange,
  multiple,
  source = "",
  ...props
}) => {
  const { uploader, setUploader } = useContext(UserContext);

  const hideUploader = () => {
    setUploader(!uploader);
  };

  return (
    <div className="tab">
      {/* file upload */}
      {uploader && (
        <>
          {disable == false ? (
            <div>
              <FileUploader
                disabled={disable}
                className="upload tab"
                handleChange={handleChange}
                name="file"
                types={fileTypes}
                multiple
                children={
                  <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 h-[42px] relative gap-2 px-4 py-2 rounded bg-[#193b67]/5">
                    <p className="cursor-pointer flex-grow-0 flex-shrink-0 text-base text-center text-[#6166B6]">
                      Upload
                    </p>
                  </div>
                }
              />
            </div>
          ) : (
            <div className="upload tab">
              <Button variant="contained" color="primary" disabled="true">
                Upload
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default FileUploadImage;
