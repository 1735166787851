/* eslint-disable react/prop-types */
import { SearchIcon } from "@ui/icons";

const Search = ({ placeholder, onChange }) => {
  return (
    <div className="relative w-full" >
      <div
        className="absolute top-3 left-3 pointer-events-none"
       
      >
        <SearchIcon />
      </div>
      <input
        type="text"
        className="h-11 w-full pl-10 border-none pr-0 lg:pr-20 bg-[#1A38601A] rounded-[4px] z-0 focus:shadow focus:outline-none focus:ring-primary placeholder:text-[#1C345442] placeholder:font-extralight placeholder:text-base"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default Search;
