/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Card } from "@ui";
import { get_sdgGoal_data,get_industry_data } from "@slices/onboarding/miner.slice";
import { Select, Chips,Checkbox } from "@ui";
import { Button } from "@atoms/button/index";
import { Header } from "@atoms/header";
import { Vector } from "@atoms/icons";
import { Input, TextArea } from "@atoms/input";

import { submit_vendor_verification, get_vendors_verification_data, search_directory,get_vendor_subscription } from "@slices/onboarding/vendor.slice"
import { Map, LongLat, SearchLocation } from "@pages/onboarding";
import "./index.css";
import { SuccessUrl, BaseUrl } from "@config/app-url";

import { SuccessResponse } from "config/status-code";
import { VendorVerificationFormSchema } from "@validations/schema"
import { ControlledModal, Button as ModalButton } from "@ui";
import Payment from "./payment"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";


const VendorAccountVerification = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();

    /** get vendors verification data */
    useEffect(() => {
        // dispatch(get_vendors_verification_data());
        dispatch(get_vendor_subscription());
        dispatch(get_sdgGoal_data());
        dispatch(get_industry_data());
    }, [dispatch]);

    /** Get Data from state set in get_miners_verification_data  */
    const { loading, data: {  user_details }, directory, general } = useSelector((state) => state.vendor_verification);
    const [location_type, setLocationType] = useState("");
    const _FormData = new FormData();
    const [address, setAddress] = useState(true);
    let plan = "paid";
    const [longitude_latitude, setLongitudeLatitude] = useState(false);
    const [gps, setGps] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const { sdg_goals,industries } = useSelector((state) => state.miner_verification);
    const handleModalOpen = () => setOpenModal(true);
    const handleModalClose = () => setOpenModal(false);
    const [local_storage, setLocalStorage] = useState({});

    const [company_id, setCompanyID] = useState("");
    const [input, setInput] = useState("");

    const [keywords, setKeywords] = useState([]);
    const [isKeyReleased, setIsKeyReleased] = useState(false);
    const [sdgGoalsSelectedOptions, setsdgGoalsSelectedOptions] = useState(null);
    /** Onchange of Location Type */
    const onChangeLocationType = (location_type) => {
        setValue("address", '', { shouldValidate: true, shouldDirty: true });
        setValue("postal", '', { shouldValidate: true, shouldDirty: true });
        setValue("state", '', { shouldValidate: true, shouldDirty: true });
        setValue("city", '', { shouldValidate: true, shouldDirty: true });
        setValue("long", '', { shouldValidate: true, shouldDirty: true });
        setValue("lat", '', { shouldValidate: true, shouldDirty: true });
        setValue("country", '', { shouldValidate: true, shouldDirty: true });
        return setLocationType(location_type)
    }
    useEffect(() => {
        /** switch location type */
        switch (location_type) {
            case "address":
                setAddress(true)
                setLongitudeLatitude(false)
                setGps(false)
                break;
            case "longitude_latitude":
                setLongitudeLatitude(true)
                setAddress(false);
                setGps(false)
                break;
            case "gps":
                setGps(true)
                setAddress(false);
                setLongitudeLatitude(false)
                break
            default:
                break;
        }
    }, [location_type]);

    const {
        control,
        setValue,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(VendorVerificationFormSchema),
        mode: "all",
    });

    const submitfreetrialInfo = async () => {
        const { _id: profile_id } = JSON.parse(
            localStorage.getItem("profile_to_verify")
          );
        const data = JSON.parse(localStorage.getItem("verify_account"));

        const formRequest = {
            profile_id,
            payment_method_id: "",
            company_name: data.companyName,
            description: data.description,
            industry_name: data.industry,
            keywords: data.keywords,
            location: {
                long: data.long,
                lat: data.lat,
                address: data.address,
                postal_code: data.postal,
            },
            directory_id: company_id,
            country: data.country,
            state: data.state,
            city: data.city,
            plan: plan,
            sdg_goals: data.sdg_goals,
            revenue: data.revenue,
        };

        _FormData.append("data",JSON.stringify(formRequest));

        dispatch(submit_vendor_verification({formdata:_FormData,id:profile_id}))
            .then((res) => {
                if (res.payload.status && SuccessResponse(res.payload.status)) {
                    if(res.payload.data.code == 422 || res.payload.code == 400) {
                       toast.error(res.payload.data.message);
                    }else {
                        toast.success(res.payload.data.message);
                        localStorage.removeItem("create_account");
                        localStorage.removeItem("auth_user");
                        plan = "paid";
                        navigate(SuccessUrl);
                    }   
                }
                throw 'error submitting form'
            }).catch((err) => {
                return toast.error(err.message);
            });
    }


    const submitpaidtrialInfo = async () => {
        const { _id: profile_id } = JSON.parse(
            localStorage.getItem("profile_to_verify")
          );
        const data = JSON.parse(localStorage.getItem("verify_account"));
        // const user = JSON.parse(localStorage.getItem("create_account"));
        if (!stripe || !elements) {
            return;
        }
        const user = JSON.parse(localStorage.getItem("user"));
        // console.log(profile_id);
        const result = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
                name: user.name,
                email: user.email,
            },
        });

        if (!result?.paymentMethod) {
            return toast.error(result.error.message);
        }

        const formRequest = {
            profile_id,
            payment_method_id: result.paymentMethod.id,
            company_name: data.companyName,
            description: data.description,
            industry_name: data.industry,
            keywords: data.keywords,
            location: {
                long: data.long,
                lat: data.lat,
                address: data.address,
                postal_code: data.postal,
            },
            directory_id: company_id,
            country: data.country,
            state: data.state,
            city: data.city,
            plan: plan,
            sdg_goals: data.sdg_goals,
            revenue: data.revenue,
        };

        _FormData.append("data",JSON.stringify(formRequest));

        dispatch(submit_vendor_verification({formdata:_FormData,id:profile_id}))
            .then((res) => {
                if (res.payload.status && SuccessResponse(res.payload.status)) {
                    localStorage.removeItem("create_account");
                    localStorage.removeItem("auth_user");
                    plan = "paid";
                    navigate(SuccessUrl);
                }
                throw 'error submitting form'
            }).catch((err) => {
                return toast.error(err.message);
            });
    }

    const onSubmit = (data, e) => {
        e.preventDefault();
        //set modal to true
        if(plan == "paid") {
            localStorage.setItem("verify_account", JSON.stringify(data));
            setLocalStorage(data);
            handleModalOpen();
        }else {
            localStorage.setItem("verify_account", JSON.stringify(data));
            setLocalStorage(data);
            submitfreetrialInfo();
        }
       
      
    };

    /** Set Input to state */
    const onChange = (e) => {
        const { value } = e.target;
        setInput(value);
    };

    /** add keyword to state */
    const onKeyDown = (e) => {
        const { key } = e;
        const trimmedInput = input.trim();
        if (key === "," && trimmedInput.length && !keywords.includes(trimmedInput)) {
            e.preventDefault();
            keywords.push(trimmedInput);
            setValue('keywords', keywords, { shouldValidate: true, shouldDirty: true })
            setKeywords(keywords);
            setInput("");
        }

        if (key === "Enter" && trimmedInput.length && !keywords.includes(trimmedInput)) {
            e.preventDefault();
            keywords.push(trimmedInput);
            setValue('keywords', keywords, { shouldValidate: true, shouldDirty: true })
            setKeywords(keywords);
            setInput("");
        }

        if (key === "Backspace" && !input.length && keywords.length && isKeyReleased) {
            const tagsCopy = [...keywords];
            const poppedTag = tagsCopy.pop();
            e.preventDefault();
            setKeywords(tagsCopy);
            setValue('keywords', tagsCopy, { shouldValidate: true, shouldDirty: true })
            setInput(poppedTag);
        }
        setIsKeyReleased(false);
    };

    const handleChange = (event)=>{
        const { name, value, checked, type } = event.target;
        if(checked === true) {
            plan = "trial";
        }else {
            plan ="paid";
        }
      }

    const handleSearch = (e) => {
        if (e.target.value.length > 1) {
            let wordSearch = e.target.value;
            const timer = setTimeout(() => {
              if (wordSearch == e.target.value) {
                if (e.target.value) {
                    dispatch(search_directory({ search: e.target.value, type: "vendor" }));
                }
              }
            }, 1500);
            return () => clearTimeout(timer);
          } else {
            if (e.target.value === "") {
              dispatch(search_directory({ search: "", type: null }));
            }
        }
    };

    const setCompany_ID = (data) => {
        setCompanyID(data._id);
        setValue("companyName", data.name);
        dispatch(search_directory({ search: "", type: null }));
    };

    /** release input  */
    const onKeyUp = () => {
        setIsKeyReleased(true);
    };

    /** Delete a keyword */
    const deleteKeyword = (keyword) => {
        const newKeywords = keywords.filter((item) => item !== keyword);
        setValue('keywords', newKeywords, { shouldValidate: true, shouldDirty: true })
        return setKeywords(newKeywords);
    };

    /** Minerals and SDG Goals selection */
    const handleMultiSelectChange = (values, { name }) => {
        if (name === "sdgGoals") {
            setsdgGoalsSelectedOptions(values)
            let new_sdg_goals = values.map((item) => item.slug);
            return setValue("sdg_goals", new_sdg_goals, { shouldValidate: true, shouldDirty: true });
        }
    };
    /** Minerals and SDG Goals Removal */
    const removeOption = (selectedItem, id) => {
        let newSelect;
        if (id === "sdgGoals-chips") {
            newSelect = sdgGoalsSelectedOptions.filter((item) => item.slug !== selectedItem);
            setValue("sdg_goals", newSelect, { shouldValidate: true, shouldDirty: true });
            return setsdgGoalsSelectedOptions(newSelect);
        }
    };
    return (
        <div className="mt-12 font-default">
            <Header />
            <div className="bg-white rounded p-8 mt-[113px] lg:mt-0  lg:bg-none my-4">
                <div className="flex justify-between my-8">
                    <div className="text-primary border-none cursor-pointer flex justify-end" > {" "} </div>
                    <Link to={BaseUrl} className="text-primary border-none cursor-pointer flex justify-end"> {" "} Skip </Link>
                </div>

                <h2 className="font-bold text-3xl text-head  mb-3 ">
                    Account verification
                </h2>
                <h6 className="text-lightgray pb-4 text-base font-light lg:mb-2 ">
                    To use the platform, we have to verify the authenticity of your
                    account information.
                </h6>
                <div className=" lg:hidden rounded-full bg-primary text-white text-xs w-fit  my-4  py-1 px-[9.5px]"> 1 </div>
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <div className=" hidden lg:flex rounded-full bg-primary text-white text-xs w-fit  my-0.5  py-1 px-[9.5px]"> 1 </div>
                        <p className="text-black  lg:ml-3">Fill vendor information</p>
                    </div>
                </div>
                <form className="mt-5" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    {/**  Company name */}
                    <div className="mt-2">
                        <label className="block text-xs mb-2  text-lightgray">  Name  </label>
                        <Input type="text" placeholder="Rio Tinto Group" name="companyName" {...register("companyName")} onChange={(e) => handleSearch(e)} />
                        {directory?.length > 0 && (
                            <Card className="shadow-md">
                                <div className="h-48 align-centre rounded-lg overflow-scroll cursor-pointer">
                                    {directory.map((data, index) => (
                                        <p className="h-10 text-sm text-light font-light mb-2 box-company" onClick={() => { setCompany_ID(data) }} key={index + 1} > {data?.name} - {data?.country}</p>
                                    ))}
                                </div>
                            </Card>
                        )}
                        {errors.companyName?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.companyName?.message} </p>)}
                    </div>

                    {/* Industry */}
                    <div className="mt-4">
                        <label className="block text-xs mb-2  text-lightgray"> Industry </label>
                        <select
                            className="bg-fill border-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded cursor-text text-input py-2 px-4 text-sm"
                            id="industry" name="industry" {...register("industry")} type="dropdown"> {" "}
                            <option value="">Select Industry</option>
                            {industries?.length > 0 && industries.map((industry_name, index) => (<option key={index} value={industry_name.slug}>  {industry_name.name} </option>))}
                        </select>
                    </div>

                    {/** Description */}
                    <div className="mt-2">
                        <label className="block text-xs mb-2  text-lightgray"> Description </label>
                        <TextArea placeholder="Bingham Canyon Mine is an open-pit mining operation extracting a large porphyry copper deposit southwest of Salt Lake City, Utah, in the Oquirrh Mountains."
                            name="description" type="textarea" {...register("description")} />
                        {errors.description?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.description?.message} </p>)}
                    </div>

                    <div>
                        <label className="block text-xs my-2 text-lightgray"> Search optimization keywords </label>
                        <Input name="keywords" type="text" value={input} placeholder="Seperate with a comma" onKeyDown={onKeyDown} onChange={onChange} onKeyUp={onKeyUp} />
                        {keywords.map((keyword) => (
                            <div className="inline-block align-center rounded text-primary font-light my-2 text-xs">
                                <div className="bg-primaryLight pl-1 py-1 mx-1" name="keywords"> {keyword}
                                    <button className="text-primary px-2" onClick={() => deleteKeyword(keyword)} > x </button>
                                </div>
                            </div>
                        ))}
                        {errors.keywords?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.keywords?.message} </p>)}
                    </div>

                    {/** Location Type */}
                    <div className="w-full mt-2">
                        <label className="block text-xs mb-2  text-lightgray" htmlFor="address"> Location type </label>
                        <select
                            className="bg-fill cursor-pointer border-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded text-input py-2 px-4 text-sm"
                            id="address" type="dropdown" onChange={(e) => onChangeLocationType(e.target.value)}>
                            <option className="bg-fill cursor-pointer border-none" value="address" name="type"> Address </option>
                            <option value="longitude_latitude" name="type">  Longitude and Latitude </option>
                            <option value="gps" name="type">  GPS  </option>
                        </select>
                    </div>

                    {gps && (
                        <div className="w-full mt-3">
                            <div className="h-96 my-4 ">
                                <Map location={{ address: "1600 Amphitheatre Parkway, Mountain View, california.", lat: 37.42216, lng: -122.08427 }} zoomLevel={17} setValue={setValue} />
                            </div>
                        </div>
                    )}

                    {longitude_latitude && (
                        <div className="flex flex-wrap -mx-2">
                            <LongLat setValue={setValue} errors={errors} />
                        </div>
                    )}

                    {/* Address */}
                    <div className="w-full mt-3">
                        <label className="block text-xs mb-2  text-lightgray" htmlFor="address"> Address </label>
                        {address ? (<SearchLocation setValue={setValue} />) : <Input type="text" name="address" {...register("address")} />}
                        {errors.address?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.address?.message} </p>)}
                    </div>

                    {/* Longitude && Latitude display this if longitude and latitude is not selected */}
                    {!longitude_latitude && (
                        <div className="flex flex-wrap -mx-2">
                            {/** Longitude */}
                            <div className=" media mt-4 px-3">
                                <label className="block text-xs mb-2 text-lightgray" htmlFor="longitude"> Longitude </label>
                                <Input type="text" name="long" {...register("long")} readOnly />
                                {errors.long?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.long?.message} </p>)}
                            </div>
                            {/** Latitude */}
                            <div className=" media mt-4 px-3">
                                <label className="block text-xs mb-2 text-lightgray" htmlFor="latitude"> Latitude </label>
                                <Input type="text" name="lat"  {...register("lat")} readOnly />
                                {errors.lat?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.lat?.message} </p>)}
                            </div>
                        </div>
                    )}

                    {/** Country && Postal code */}
                    <div className="flex flex-wrap -mx-3 my-3">
                        {/** Country */}
                        <div className="media px-3">
                            <label className="block text-xs mb-2  text-lightgray" htmlFor="country" >  Country </label>
                            <Input id="country" type="text" placeholder="" name="country" {...register("country")} readOnly />
                            {errors.country?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.country?.message} </p>)}
                        </div>
                        {/** Postal code */}
                        <div className="media px-3">
                            <label className="block text-xs mb-2  text-lightgray" htmlFor="postal" > Postal code </label>
                            <Input id="postal" type="text" name="postal"  {...register("postal")} />
                            {errors.postal?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.postal?.message} </p>)}
                        </div>
                    </div>

                    {/** State && City */}
                    <div className="flex flex-wrap -mx-2">
                        {/** State */}
                        <div className=" media px-3 mt-2">
                            <label className="block text-xs mb-2 text-lightgray" htmlFor="state">  State </label>
                            <Input id="state" type="text" placeholder="" name="state"  {...register("state")} />
                            {errors.state?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.state?.message} </p>)}
                        </div>
                        {/** City */}
                        <div className=" media px-3 mt-2 ">
                            <label className="block text-xs mb-2 text-lightgray" htmlFor="city"> City </label>
                            <Input id="city" type="text" placeholder="" name="city"  {...register("city")} />
                            {errors.city?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.city?.message} </p>)}
                        </div>
                    </div>

                    {/** Revenue */}
                    <div className="mt-4">
                        <label className="block text-xs mb-2  text-lightgray"> Revenue </label>
                        <select
                            className="bg-fill border-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded cursor-text text-input py-2 px-4 text-sm"
                            id="revenue" name="revenue" {...register("revenue")}> {" "}
                            <option selected={true} value={""}>Select revenue</option>
                            <option value={"below_250000"}>Below 250,000 USD</option>
                            <option value={"above_250000"}>Above 250,000 USD</option>
                        </select>
                    </div>

                    {/**  SDG Gaols */}
                    <div className="w-full mt-2 mb-5">
                        <Select
                            control={control}
                            id="sdgGoals-chips"
                            label="SDG Goal"
                            name="sdgGoals"
                            placeholder="Select SDG goals"
                            isMulti
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.slug}
                            value={sdgGoalsSelectedOptions}
                            options={sdg_goals || []}
                            onChange={handleMultiSelectChange}
                        />
                        <div className="flex gap-2 flex-wrap"> <Chips chips={sdgGoalsSelectedOptions} onClick={removeOption} id="sdgGoals-chips" /> </div>
                        {errors.sdg_goals?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.sdg_goals?.message} </p>)}
                    </div>

                    {/* Trial period */}
                    <div className="text-checkbox flex flex-row text-sm">
                        <Checkbox  value={plan} name="paid" onChange={handleChange} />
                        <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            Start a free 30-day trial
                        </p>
                   
                    </div>

                    {/**  Submit button */}
                    <div className="mt-6 flex justify-end">  <Button disabled={loading} size="small" type="submit"> {loading ? "Loading..." : "Submit"} </Button> </div>
                </form>
                {/* <div className="flex align-center pb-2 mt-20 font-thin">
                    <Vector />
                    <p className=" paragraph text-lightgray text-xs ml-2 font-thin">
                        You can switch between multiple profiles later on
                    </p>
                </div> */}
            </div>
            <ControlledModal
                className="fixed top-[15vh] left-[10%] w-4/7 "
                isOpen={openModal}
                handleClose={handleModalClose}
            >
                <header className="flex justify-between mx-4 mb-2">
                    <h2 className="text-xl font-medium text-gray-900">
                        Subscribe
                    </h2>
                    {/* <CloseIcon close={handleModalClose} /> */}
                </header>
                <hr className="border-red mb-2" />
                <main className=" mx-4 mb-2">
                    <div className="col-span-3 py-10 px-8">
                        <Payment fee={general?.vendor_subscription} />
                    </div>
                </main>
                <hr className="border-red mb-2" />
                <footer className="flex gap-4 mx-4">
                    <ModalButton onClick={submitpaidtrialInfo}>Pay Now</ModalButton>
                    <ModalButton color="danger" onClick={handleModalClose}>
                        Cancel
                    </ModalButton>
                </footer>
            </ControlledModal>
        </div>
    );
};
export default VendorAccountVerification;
