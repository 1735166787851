/* eslint-disable no-unused-vars */
import React from "react";
import { useNavigate } from "react-router-dom";
import Countdown from "@components/auctions/Countdown";
import { Button, Card } from "@ui";
const AdminAuction = ({ auction, url, handleOpen }) => {
  const navigate = useNavigate();

  return (
    <>
      <Card>
        <div className="h-[217px] rounded-lg overflow-hidden">
          <img
            className="h-full w-full object-cover"
            src={auction?.image[0]?.url}
            alt="daniOla product card image"
          />
        </div>
        <h3 className="text-2xl text-heading mt-4 capitalize">
          {auction?.product_name}
        </h3>
        <p className="text-sm text-light font-light">
          {auction?.mine?.mine_name}
        </p>
        {/* timer */}
        <Countdown date={auction?.end_date} />
        {/* card information */}
        <div className="grid grid-cols-5 gap-y-2 mb-3">
          <div className="col-span-2 font-light text-ts text-sm">
            <p>Quantity</p>
          </div>
          <div className="col-span-3 font-light text-light">
            <p>
              {auction?.quantity}{" "}  
              {auction?.unit_of_measurement?.id}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-y-2 mb-3">
          <div className="col-span-2 font-light text-ts text-sm">
            <p>TC</p>
          </div>
          <div className="col-span-3 font-light text-light">
            <p>
              {auction?.treatment_cost} USD/
              {auction?.unit_of_measurement?.id}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-y-2 mb-3">
          <div className="col-span-2 font-light text-ts text-sm">
            <p>Price</p>
          </div>
          <div className="col-span-3 font-light text-light">
            <p>
              {auction?.price} USD/
              {auction?.unit_of_measurement?.id}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-5 gap-y-2 mb-3">
          <div className="col-span-2 font-light text-ts text-sm">
            <p>Total cost</p>
          </div>
          <div className="col-span-3 font-light text-light">
            <p>{auction?.total_value} USD</p>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          {auction?.status == "Archived" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                navigate("../auction-details", {
                  state: auction,
                })
              }
              fullWidth
            >
              View details
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate("../auction-details", {
                    state: auction,
                  })
                }
                fullWidth
              >
                View details
              </Button>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  handleOpen(auction);
                }}
                fullWidth
              >
                Archive
              </Button>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default AdminAuction;
