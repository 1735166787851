/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Input, Select, TextArea, Chips, Button } from "@ui";
import { getServiceOptions } from "store/slices/miners/vendor-slice";
import { toast } from "react-toastify";
import { SuccessResponse } from "config/status-code";
import { updateServiceOptions } from "store/slices/admin-slice";
import { checkPermission } from "shared/utility";

const Tariff = () => {
    const dispatch = useDispatch();
    const {inventory_rate,miner_demand_charge, investment_rate, offtaker_transaction_charge, demand_commission_charge,vendor_subscription, generalSettingID } = useSelector((store) => store.miner_vendors);
    const {loading}= useSelector((store) => store.admin);
    const [data, setData] = useState({
      inventory_rate: inventory_rate,
      investment_rate: investment_rate,
      miner_demand_charge: miner_demand_charge,
      offtaker_transaction_charge: offtaker_transaction_charge,
      demand_commission_charge:demand_commission_charge,
      vendor_subscription: vendor_subscription
    });
   
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setData((prevState) => {
        return {
          ...prevState,
          [name]: Number(value),
        };
      });
    };

    useEffect(() => {
          dispatch(getServiceOptions());   
            setData({
              inventory_rate: inventory_rate,
              investment_rate: investment_rate,
              miner_demand_charge: miner_demand_charge,
              offtaker_transaction_charge: offtaker_transaction_charge,
              demand_commission_charge:demand_commission_charge,
              vendor_subscription: vendor_subscription
             })
      }, [dispatch,inventory_rate]);

    const updateTariff = () => {
      let formData = {
        demand_commission_charge: data.demand_commission_charge,
        miner_demand_charge: data.miner_demand_charge,
        vendor_subscription: data.vendor_subscription,
        inventory_rate: data.inventory_rate,
        investment_rate: data.investment_rate,
        offtaker_transaction_charge: data.offtaker_transaction_charge
       };
       dispatch(updateServiceOptions({ id: generalSettingID, data: formData })).then((response) => {
        if (response.payload.status && SuccessResponse(response.payload.status)) {
          toast.success(response.payload.data.message);
          dispatch(getServiceOptions());   
        }
      });

    }

  return (
    <Card>
      <h3 className="text-2xl mb-4 text-heading text-primary font-light">
        Miners
      </h3>
      <div className="grid lg:grid-cols-3 sm:grid gap-10 mb-5">
      <div>
          <Input label="Add to Inventory (USD)" type="text" id="inventory_rate" value={data?.inventory_rate} name="inventory_rate" onChange={handleInputChange} />
        </div>
        <div>
          <Input label="Investment Fees (USD)" value={data?.investment_rate}  id="investment_rate" type="text" name="investment_rate" onChange={handleInputChange} />
        </div>
        <div>
          <Input label="Demand Fees (USD)" value={data?.miner_demand_charge} type="text" id="miner_demand_charge" name="miner_demand_charge" onChange={handleInputChange} />
        </div>
      </div>
      <h3 className="text-2xl mb-4 text-heading text-primary font-light">
        Offtaker
      </h3>
      <div className="grid lg:grid-cols-3 sm:grid gap-10 mb-5">
        <div>
          <Input label="Transaction Initiate Commission (%)" value={data.offtaker_transaction_charge} type="text" id="offtaker_transaction_charge" name="offtaker_transaction_charge" onChange={handleInputChange} />
        </div>

        <div>
          <Input label="Demand Commision (%)" value={data.demand_commission_charge} type="text" id="demand_commission_charge" name="demand_commission_charge" onChange={handleInputChange} />
        </div>
      </div>
      <h3 className="text-2xl mb-4 text-heading text-primary font-light">
        Vendors
      </h3>
      <div className="grid lg:grid-cols-3 sm:grid gap-10">
        <div>
          <Input label="Subscription Fees (USD)" type="text" value={data.vendor_subscription} id="vendor_subscription" name="vendor_subscription" onChange={handleInputChange} />
          <p className="font-light text-ts text-sm mb-4">
            Subscription Fees is charged on monthly basis
          </p>
        </div>

      
      </div>
      <div className="flex items-center justify-between mt-8">
        <div>
            <Button
                disabled={checkPermission("Tariff")}
              onClick={() => {
                updateTariff();
              }}
            >
              {loading ? "Processing..." : "Update Tariff"}
            </Button>
          </div>
        
        </div>
    </Card>
  );
};
export default Tariff;
