const EditIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 8C20 8 20 7 19 6C18 5 17.1 5 17.1 5L16 6.1V4H4V20H16V12L20 8ZM10.3 15.4L9.7 14.8L10 13.7L11.5 15.2L10.3 15.4ZM11.2 13.5L10.6 12.9L15.8 7.7C16 7.8 16.2 8 16.4 8.2L11.2 13.5ZM18.1 6.5L17.2 7.5C17 7.3 16.8 7.2 16.6 7L17.5 6.1C17.6 6.2 17.8 6.3 18.1 6.5ZM15 19H5V5H15V7.1L9.1 13L8 17.1L12.1 16L15 13V19Z"
        fill="#1C2E45"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default EditIcon;
