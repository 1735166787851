import React from "react";
// import { Logo } from "../icons";
import Logo from "@images/daniola-logo.png";

export const Header = () => {
  return (
    <div className="w-full py-1 flex justify-center lg:justify-start">
      <img className="mx-auto w-3/6 h-13 mb-8 ml-7" src={Logo} alt="logo" />
      {/* <Logo /> */}
    </div>
  );
};
