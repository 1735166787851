/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Btn as Button } from "@atoms/button/button";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getDemand,postDemandInterestPaymentData } from "@slices/miners/demands-slice";
import SkeletonComponent from "components/investors/Skeleton";
import { priceSplitter } from "@shared/utility";
import InfoCircleSmall from "components/ui/icons/InfoCircleSmall";
import PaymentModal from "./modals/payment";
import { toast } from "react-toastify";
import { getProfile ,fetchAllNotifications} from "@slices/settings-slice";
import { getInitials, checkPermission } from "shared/utility";
import { getServiceOptions } from "store/slices/miners/vendor-slice";

const DemandDetails = () => {
  const [makePayment, setMakePayment] = useState(false);
  const [isloading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state;
  const dispatch = useDispatch();
  const { demand } = useSelector((store) => store.miner_demands);
  const { miner_demand_charge } = useSelector((store) => store.miner_vendors);

  React.useEffect(() => {
    dispatch(getDemand(id));
    dispatch(getServiceOptions());
  }, []);

  const payviaWallet = async () => {
    setLoading(true);
    const data = {
      payment_method: "wallet",
      payment_method_id: "",
      payment_intent_id: "",
    };
    const response = await dispatch(
      postDemandInterestPaymentData({ id, data })
    );
    if (response.payload.status === 201) {
      if (
        response?.payload?.data?.code == 422 ||
        response?.payload?.data?.code == 400
      ) {
        setLoading(false);
        toast.error(response.payload.data.message);
      } else {
        navigate("/miner/demands");
        dispatch(getProfile());
        dispatch(fetchAllNotifications({limit:10}));
        setLoading(false);
        toast.success(response.payload.data.message);
      }
    } else {
      setLoading(false);
    }
  };

  return Object.keys(demand).length == 0 ? (
    <SkeletonComponent />
  ) : (
    <div className="text-default h-full bg-white px-[34px] py-[54px]">
      {makePayment && (
        <PaymentModal open={makePayment} setOpen={setMakePayment} miner_demand_charge={miner_demand_charge} {...{ id }} />
      )}
      {demand.interest.status === "Awarded" && (
        <p className="flex items-center gap-x-2 text-sm font-light text-left bg-dark-gray-300/5 text-lightgray/[.72] w-full border border-disabled/25 py-2 px-5 rounded-md mb-8">
          <span>
            <InfoCircleSmall />
          </span>
          You are required to pay the flat fee of ${miner_demand_charge} to progress with the
          transaction.
        </p>
      )}
      <div className="flex flex-col lg:flex-row justify-between">
        {/* <div className="flex flex-col justify-start items-start  left-[23px] top-[23px] gap-4"> */}
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 w-[206px] h-[66px]">
          <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
            {demand?.demand[0]?.accounts[0]?.profile_img?.length > 0 ? (
              <img
                className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                src={`${demand?.demand[0]?.accounts[0]?.profile_img[0]?.url}`}
                alt=""
              />
            ) : (
              `${getInitials(demand.demand[0]?.account?.name)}`
            )}
          </div>
          <div className="flex flex-col justify-start items-start flex-grow-0 ml-4 flex-shrink-0 ">
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-base text-left text-primary">
              {demand?.demand[0].account?.name}
            </p>
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-sm text-left text-[#18273a]/[0.94]">
              {demand?.demand[0].account?.company}
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-sm font-light text-left text-[#1b2b41]/[0.72]">
              {demand?.demand[0].account?.country?.name}
            </p>
          </div>
        </div>

        <div className="flex justify-center gap-8 w-[548px]">
          {demand.interest.status === "Awarded" && (
            <>
              <Button  disabled={checkPermission("Demands")} size="mid" onClick={() => payviaWallet()}>
                {isloading ? "Processing.." : " Pay via Wallet"}
              </Button>
              <Button  disabled={checkPermission("Demands")} size="mid" onClick={() => setMakePayment(true)}>
                Pay with Stripe
              </Button>
            </>
          )}
        </div>
        {demand.interest.status === null && (
          <div className="hidden lg:block">
            <Button
              disabled={checkPermission("Demands")}
              onClick={() =>
                navigate("/miner/demands/supply", {
                  state: {
                    id,
                    demand_mineral: demand?.demand[0]?.mineral,
                    shipping_type: demand?.demand[0]?.shipping_type,
                  },
                })
              }
              size="small"
            >
              Supply
            </Button>
          </div>
        )}
      </div>

      <div className="flex flex-col justify-start items-start flex-grow-0 mt-6  flex-shrink-0">
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Mineral:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {demand?.demand[0].mineral?.name}
          </p>
        </div>
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Contract type:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {demand?.demand[0].contract_type}
          </p>
        </div>
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Delivery Period:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {demand?.demand[0].delivery_period}
          </p>
        </div>
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Price:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {`${priceSplitter(demand?.demand[0].price)} USD/${
              demand?.demand[0].unit_of_measurement?.id
            }`}
          </p>
        </div>
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="min-w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Preffered Shipping type:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {demand?.demand[0].shipping_type?.slug}
          </p>
        </div>

        <div className="flex flex-row justify-between mb-[15px]">
          <p className="min-w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Delivery Location:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {demand?.demand[0].location?.address}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DemandDetails;
