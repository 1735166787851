/* eslint-disable no-unused-vars */
/** @format */

import React, { useState } from "react";
import { fetchAdminRoles, deleteAdminRoles } from "@slices/admin-slice";
import { Modal, Box } from "@mui/material";
import {
  getCarrier,
  deleteCarrierByID,
} from "@slices/miners/interaction-slice";
import Button from "components/ui/Button";
import { useAuth } from "@hooks";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

// eslint-disable-next-line react/prop-types
export const DeleteCarrierModal = ({ setOpen, open = null, id }) => {
  const profiles = useAuth();
  const [isLoading] = useState(false);
  const dispatch = useDispatch();
  const deletecarrier = async () => {
    try {
      const response = await dispatch(deleteCarrierByID(id));
      if (response.payload.status === 200 || response.payload.status === 201) {
        if (
          response.payload.data.code == 422 ||
          response.payload.data.code == 400
        ) {
          toast.error(response.payload.data.message);
        } else {
          setOpen(!open);
          dispatch(getCarrier(), (error) => {
            toast.error(error);
          });
        }

        // toast.success("Carrier Deleted Successfully!!!");
      } else {
        toast.error(response.payload.msg);

        // console.log("error from then", response);
      }
    } catch (error) {
      toast.error(error);
      // console.log("error", error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center mb-4">
            <h1 className="text-xl mb-4 text-[#FF4E44]">Warning !</h1>

            <>
              <p className="font-light mt-4">
                {" "}
                Are you sure you want to remove this carrier ?
              </p>
              <div className="flex items-center justify-between mt-8">
                <button
                  onClick={() => setOpen(!open)}
                  className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
                >
                  Cancel
                </button>
                <div
                  onClick={() => {
                    deletecarrier();
                  }}
                >
                  <Button> {isLoading ? "Processing..." : "Delete"}</Button>
                </div>
              </div>
            </>
          </div>
        </Box>
      </Modal>
    </>
  );
};
