/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';

const Review = ({ getValues, productImage, mine,shipping_type }) => {
	const { supplyFormData } = useSelector((store) => store.miner_demands);
	const data = getValues();
	const objectUrl = URL.createObjectURL(data?.product_image[0][0]);
	return (
		<div className="">
			<p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434]">
				Review
			</p>

			<div className="h-[217px] rounded-lg overflow-hidden">
				<img
					className="object-cover h-52 w-80"
					src={objectUrl}
					alt="daniOla product card image"
				/>
			</div>

			<div className="flex mb-3">
				<div className="min-w-[165px] font-light text-ts text-base">
					<p>Product name</p>
				</div>
				<div className="font-light text-light">
					<p>{data?.product_name}</p>
				</div>
			</div>
			<div className="flex mb-3">
				<div className="min-w-[165px] font-light text-ts text-base">
					<p>Mineral type</p>
				</div>
				<div className="font-light text-light">
					<p>{data?.mineral}</p>
				</div>
			</div>
			<div className="flex mb-3">
				<div className="min-w-[165px] font-light text-ts text-base">
					<p>Source mine</p>
				</div>
				<div className="font-light text-light">
					<p>{mine?.name}</p>
				</div>
			</div>
			<div className="flex mb-3">
				<div className="min-w-[165px] font-light text-ts text-base">
					<p>Description</p>
				</div>
				<div className="font-light text-light">
					<p>{data?.description}</p>
				</div>
			</div>
			<div className="flex mb-3">
				<div className="min-w-[165px] font-light text-ts text-base">
					<p>Quantity</p>
				</div>
				<div className="font-light text-light">
					<p>
						{data?.quantity} {data?.unit_of_measurements}
					</p>
				</div>
			</div>

			<div className="flex mb-3">
				<div className="min-w-[165px] font-light text-ts text-base">
					<p>TC</p>
				</div>
				<div className="col-span-3 font-light text-light">
					<p>
						{data?.treatment_cost} USD/
						{data?.unit_of_measurement}
					</p>
				</div>
			</div>
			<div className="flex mb-3">
				<div className="min-w-[165px] font-light text-ts text-base">
					<p>Price</p>
				</div>
				<div className="col-span-3 font-light text-light">
					<p>
						{data?.price} USD/
						{data?.unit_of_measurement}
					</p>
				</div>
			</div>

			<div className="flex mb-3">
				<div className="min-w-[165px] font-light text-ts text-base">
					<p>Total value</p>
				</div>
				<div className="col-span-3 font-light text-light">
					<p>{data?.total_value} USD</p>
				</div>
			</div>

			<div className="flex mb-3">
				<div className="min-w-[165px] font-light text-ts text-base">
					<p>Shipping type</p>
				</div>
				<div className="col-span-3 font-light text-light">
					<p>{shipping_type?.slug}</p>
				</div>
			</div>
		</div>
	);
};

export default Review;
