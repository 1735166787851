import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as API from "@config/url";
import { toast } from "react-toastify";


// Fetch verification data
const get_miners_verification_data = createAsyncThunk("miner_verification/get_miners_verification_data", async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
        return await axios({
            method: "get",
            url: API.getMinerVefiricationData,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            },
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

const get_mineral_data = createAsyncThunk("miner_verification/get_mineral_data", async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
        return await axios({
            method: "get",
            url: API.getMineralData,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer "+ token
            },
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

const getadminmineral_data = createAsyncThunk("miner_verification/getadminmineral_data", async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
        return await axios({
            method: "get",
            url: API.getAdminMineralData,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer "+ token
            },
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

const get_sdgGoal_data = createAsyncThunk("miner_verification/get_sdgGoal_data", async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
        return await axios({
            method: "get",
            url: API.getsdgGoalData,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer "+ token
            },
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

const get_industry_data = createAsyncThunk("miner_verification/get_industry_data", async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
        return await axios({
            method: "get",
            url: API.getIndustry,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer "+ token
            },
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

const get_mineType_data = createAsyncThunk("miner_verification/get_mineType_data", async (_, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
        return await axios({
            method: "get",
            url: API.getMineralType,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer "+ token
            },
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

const search_directory = createAsyncThunk("miner_verification/search_directory", async (payload, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
        return await axios({
            method: "get",
            url: API.searchDirectory(payload?.search,payload?.type),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer "+ token
            },
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

// Post verification data
const submit_miners_verification = createAsyncThunk("miner_verification/submit_miners_verification", async (payload, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    try {
        return await axios({
            method: "post",
            url: API.createAccount(payload.id),
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "bearer "+ token
            },
            data:payload.formdata,
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

/** create slice */
const slice = createSlice({
    name: "miner_verification",
    initialState: {
        data: {},
        minerals:[],
        directory:[],
        industries:[],
        sdg_goals:[],
        mine_types:[],
        status: false,
        loading: false,
        error: false,
        message: null,
    },
    reducers: {},
    extraReducers: {
        // verify otp code
        [submit_miners_verification.pending]: (state) => {
            state.loading = true;
        },
        [submit_miners_verification.fulfilled]: (state, { payload }) => {
            state.status = payload.status;
            state.loading = false;
            localStorage.clear()
        },
        [submit_miners_verification.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },

        // get miner verification data
        [get_miners_verification_data.pending]: (state) => {
            state.loading = true;
        },
        [get_miners_verification_data.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.loading = false;
        },
        [get_miners_verification_data.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
        [get_mineral_data.pending]: (state) => {
            state.loading = true;
        },
        [get_mineral_data.fulfilled]: (state, { payload }) => {
            state.minerals = payload.data.docs;
            state.loading = false;
        },
        [get_mineral_data.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
        [getadminmineral_data.pending]: (state) => {
            state.loading = true;
        },
        [getadminmineral_data.fulfilled]: (state, { payload }) => {
            state.minerals = payload.data.docs;
            state.loading = false;
        },
        [getadminmineral_data.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
        [get_sdgGoal_data.pending]: (state) => {
            state.loading = true;
        },
        [get_sdgGoal_data.fulfilled]: (state, { payload }) => {
            state.sdg_goals = payload.data.docs;
            state.loading = false;
        },
        [get_sdgGoal_data.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
        [get_industry_data.pending]: (state) => {
            state.loading = true;
        },
        [get_industry_data.fulfilled]: (state, { payload }) => {
            state.industries = payload.data.docs;
            state.loading = false;
        },
        [get_industry_data.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
        [get_mineType_data.pending]: (state) => {
            state.loading = true;
        },
        [get_mineType_data.fulfilled]: (state, { payload }) => {
            state.mine_types = payload.data.docs;
            state.loading = false;
        },
        [get_mineType_data.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
        [search_directory.pending]: (state) => {
            state.loading = true;
        },
        [search_directory.fulfilled]: (state, { payload }) => {
            state.directory = payload.data;
            state.loading = false;
        },
        [search_directory.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
    },
});

export { submit_miners_verification, get_miners_verification_data,get_mineral_data,getadminmineral_data,get_sdgGoal_data,get_industry_data,get_mineType_data,search_directory }
export default slice.reducer;