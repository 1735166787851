/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';

import { FileUploader } from 'react-drag-drop-files';
import { Upload } from '@atoms/icons';
import { UserContext } from '@context/index';

const fileTypes = ['png', 'jpg', 'jpeg', 'pdf', 'gif'];

const FileUpload = ({
	children,
	handleChange,
	multiple,
	allowedTypes = fileTypes,
	label,
	dropZoneLabel,
	source = '',
	...props
}) => {
	const { uploader, setUploader } = useContext(UserContext);

	const hideUploader = () => {
		setUploader(!uploader);
	};

	return (
		<div className="tab">
			{/* file upload */}
			{uploader && (
				<div>
					<p className="sub mb-2 font-thin">
						{source ? source : 'Registration document'}
					</p>
					<FileUploader
						className="upload tab"
						handleChange={handleChange}
						name="file"
						types={allowedTypes}
						multiple
						children={
							<h5>
								<span className="text-primary cursor-pointer bg-dark-gray-300 py-2.5 px-4 rounded m-4">
									{label || 'Upload files...'}
								</span>

								<p className="flex  align-center justify-center">
									<Upload className="mr-2" />
									{dropZoneLabel || 'Drop file here'}
								</p>
							</h5>
						}
					/>
				</div>
			)}
		</div>
	);
};
export default FileUpload;
