/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "@config/url";
import { toast } from "react-toastify";
import axios from "@config/axios-instance";

const GET = "get";
const DELETE = "delete";
const POST = "post";

const initialState = {
  activeStep: 0,

  loading: false,
  error: null,
  isError: false,
  isSuccess: false,
  offeringActiveStep: 0,
  offeringFormData: {
    type: "",
    category: "",
    job_title: "",
    description: "",
    location: [],
    price: "",
    duration: "",
    agree: false,
    contract_type: "",
  },
  pagination: null,
  search: "",
  toggledOfferingSub: null,
  deletedOfferingSub: null,
  offerings: [],
  offering: null,
};

export const vendorConfig = (method, url, data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      "Authorization": "bearer "+ token
    },
    data,
  };
};

export const getOfferings = createAsyncThunk(
  "vendor/getOfferings",
  async (name, thunkAPI) => {
    try {
      // thunkAPI.dispatch(openModal());
      const resp = await axios(vendorConfig(GET, API.fetchOfferings));
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchPaginatedOfferings = createAsyncThunk(
  "vendor/fetchPaginatedOfferings",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedOfferings(payload.page, payload.per_page, payload.search)
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOffering = createAsyncThunk(
  "vendor/getOffering",
  async (id, thunkAPI) => {
    try {
      // thunkAPI.dispatch(openModal());
      const resp = await axios(vendorConfig(GET, API.fetchOffering(id)));
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteOffering = createAsyncThunk(
  "vendor/deleteOffering",
  async (id, { rejectWithValue, getState }) => {
    try {
      const {
        vendorOfferings: { deletedOfferingSub },
      } = getState();
      const resp = await axios(
        vendorConfig(DELETE, API.deleteOffering(deletedOfferingSub))
      );
      toast.success("Offering deleted successfully");
      return resp;
    } catch (error) {
      toast.error("Error deleting offering");
      return rejectWithValue(error.response.data);
    }
  }
);

const offeringsSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setAddOfferingStep: (state, action) => {
      state.offeringActiveStep = action.payload;
    },
    setOfferingFormData: (state, action) => {
      state.offeringFormData = action.payload;
    },
    setToggledOfferingSub: (state, action) => {
      state.toggledOfferingSub = action.payload;
    },
    setDeletedOfferingSub: (state, action) => {
      state.deletedOfferingSub = action.payload;
    },
    setSearchValue: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOfferings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOfferings.fulfilled, (state, action) => {
        state.loading = false;

        state.offerings = action.payload.offerings;
        state.pagination = action.payload.pagination;
        // state.offerings = action.payload.offerings;
        //        state.pagination = action.payload.data.pagination;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getOfferings.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        toast.error(action.payload.message);
      })
      .addCase(fetchPaginatedOfferings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPaginatedOfferings.fulfilled, (state, action) => {
        state.loading = false;
        state.offerings = action.payload.offerings;
        state.pagination = action.payload.pagination;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(fetchPaginatedOfferings.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        toast.error(action.payload.message);
      })

      .addCase(getOffering.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOffering.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.offering) state.offering = action.payload.offering;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getOffering.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setAddOfferingStep,
  setOfferingFormData,
  setToggledOfferingSub,
  setDeletedOfferingSub,
  setSearchValue,
} = offeringsSlice.actions;

export default offeringsSlice.reducer;
