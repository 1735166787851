import React, { useEffect } from "react";
//eslint-disable-next-line
import { useParams } from "react-router-dom";
import { Tab } from "@ui";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/ui/Button";
import Modal from "@mui/material/Modal";
import Complete from "./Complete";
import {
  awardInterest,
  getInterests,
  setAwardedInterestSub,
} from "store/slices/offtaker-slice";
import TableEmptyState from "components/ui/TableEmptyState";
import { getInitials } from "shared/utility";

const Interests = () => {
  const params = useParams();
  const { id } = params;
  const tabData = {
    data: ["Interests", "Awarded"],
    summary: "Total Interests:",
  };
  const {
    //eslint-disable-next-line
    //eslint-disable-next-line
    interests: { data, pagination },
  } = useSelector((store) => store.offtakers);

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSelectVendor = () => {
    dispatch(awardInterest());
    handleClose();
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    if (tab == "Interests") {
      dispatch(getInterests({ id: id, status: "" }));
    } else {
      dispatch(getInterests({ id: id, status: tab.toLowerCase() }));
    }
  };

  const handlePopUp = (id) => {
    handleOpen();
    dispatch(setAwardedInterestSub(id));
  };

  useEffect(() => {
    dispatch(getInterests({ id: id, status: "" }));
  }, []);
  return (
    <div className="flex justify-center">
      <div className="w-[97.5%]">
        <h1 className="text-[22px] text-black">Mineral Transport</h1>
        <div>
          <Tab
            tabData={tabData}
            handleTabChange={handleTabChange}
            total={pagination?.total}
          />

          <div className="grid grid-cols-2 sm:grid-cols-1 gap-8 mt-6">
            {data?.map((record, index) => (
              <div key={index} className="p-6 bg-white">
                <div className="flex justify-between">
                  <div className="flex">
                    <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill mr-3">
                      {record?.image ? (
                        <img
                          className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                          src={`${record?.image?.url}`}
                          alt=""
                        />
                      ) : (
                        `${getInitials(record?.account?.name)}`
                      )}
                    </div>
                    <div>
                      <h4 className="text-primary">{record?.account?.company}</h4>
                      <h4 className="text-[14px] text-vendor">
                        {record?.account?.name}
                      </h4>
                    </div>
                  </div>
                  <div>
                    <Button onClick={() => handlePopUp(record._id)}>
                      Select Vendor
                    </Button>
                  </div>
                </div>
                <div className="font-light text-[14px]">
                  <h2 className="text-vendorsecondary mb-2.5">Description</h2>
                  <p>{record?.account?.role?.description}</p>
                </div>
                {/* <div className="my-3 flex flex-wrap text-[#50AAA7] font-light text-[14px]">
                  {record?.keywords.map((keyword, index) => (
                    <div
                      key={index}
                      className="bg-vendoroptionbg flex justify-between px-2 mr-2 mt-2"
                    >
                      <span className="mr-2">{keyword}</span>
                    </div>
                  ))}
                </div> */}
              </div>
            ))}
          </div>
          {data !== null && !data?.length > 0 && (
            <TableEmptyState text="interest" />
          )}
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute top-44 w-full flex justify-center">
          <div className="bg-white rounded-lg py-8 w-1/2 flex justify-center ">
            <div className="w-3/4">
              <Complete />
              <div className="flex justify-center gap-8 mt-8">
                <Button
                  onClick={handleSelectVendor}
                  variant="contained"
                  color="primary"
                >
                  Confirm
                </Button>
                <Button
                  variant="contained"
                  color="neutral"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Interests;
