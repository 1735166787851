/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { SwitchOn, SwitchOff } from "@atoms/icons";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications, updateNotifications } from "@slices/settings-slice";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  const { loading, notification } = useSelector((state) => state.settings);

  const [emailtoggle, setToggleEmail] = useState();
  const [apptoggle, setToggleApp] = useState();
  useEffect(() => {
    if (Object.keys(notification).length > 0) {
      setToggleEmail(notification.user?.notification?.email);
      setToggleApp(notification.user?.notification?.app);
    }
  }, [notification]);

  const toggleEmail = (email) => {
    setToggleEmail(email);

    let data = {
      email: email,
      app: true,
      // bn: toggleOffii,
    };

    dispatch(updateNotifications(data));
  };

  const toggleApp = (app) => {
    setToggleApp(app);
    let data = {
      email: emailtoggle,
      app: app,
      // bn: toggleOffii,
    };

    dispatch(updateNotifications(data));
  };
  const toggleButtonAllNotify = (all_notify) => {
    setToggleEmail(all_notify);
    setToggleApp(all_notify);
    let data = {
      email: all_notify,
      app: all_notify,
      // bn: toggleOffii,
    };

    dispatch(updateNotifications(data));
  };
  return (
    <div>
      <div className="flex flex-row font-default bg-white py-10 pl-10 w-[1125px]">
        <div className="flex flex-col justify-start items-start mr-4">
          <div
            className="flex-grow-0 flex-shrink-0 text-base font-light text-left mb-6 text-[#1b2b41]/[0.72] cursor-pointer"
            onClick={() => {
              navigate("/miner/settings");
            }}
          >
            Profile
          </div>

          <div
            className="flex-grow-0 flex-shrink-0 text-base text-left mb-6 text-primary cursor-pointer"
            onClick={() => {
              navigate("/miner/settings/notification");
            }}
          >
            Notification settings
          </div>

          <div
            className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72] cursor-pointer"
            onClick={() => {
              navigate("/miner/settings/security");
            }}
          >
            Security
          </div>
        </div>

        <svg
          width="1"
          height="1374"
          viewBox="0 0 1 1374"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-32"
          preserveAspectRatio="xMidYMid meet"
        >
          <line
            x1="0.375"
            y1="-1.63918e-8"
            x2="0.37506"
            y2="1374"
            stroke="#1C3454"
            strokeOpacity="0.26"
            strokeWidth="0.75"
          ></line>
        </svg>

        <div className="flex flex-col justify-start items-start  ">
          <p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434 ] mb-6">
            Manage your notifications
          </p>

          {loading ? (
            <div>
              <h4>Loading data...</h4>
            </div>
          ) : (
            <div>
              <div className="flex justify-between items-center flex-grow-0 flex-shrink-0 w-[503px] mb-4 ">
                <p className="flex-grow-0 flex-shrink-0 text-base font-light text-center text-black">
                  Email notifications
                </p>

                {emailtoggle && (
                  <SwitchOn
                    onClick={() => {
                      toggleEmail(false);
                    }}
                  />
                )}
                {!emailtoggle && (
                  <SwitchOff
                    onClick={() => {
                      toggleEmail(true);
                    }}
                  />
                )}
              </div>
              <div className="flex justify-between items-center flex-grow-0 flex-shrink-0 w-[503px] mb-4 ">
                <p className="flex-grow-0 flex-shrink-0 text-base font-light text-center text-black">
                  App notifications
                </p>
                {apptoggle && (
                  <SwitchOn
                    onClick={() => {
                      toggleApp(false);
                    }}
                  />
                )}
                {!apptoggle && (
                  <SwitchOff
                    onClick={() => {
                      toggleApp(true);
                    }}
                  />
                )}
              </div>
              <div className="flex justify-between items-center flex-grow-0 flex-shrink-0 w-[503px] mb-4 ">
                <p className="flex-grow-0 flex-shrink-0 text-base font-light text-center text-black">
                  Turn on/off all notifications
                </p>{" "}
                {apptoggle && emailtoggle && (
                  <SwitchOn
                    onClick={() => {
                      toggleButtonAllNotify(false);
                    }}
                  />
                )}
                {((apptoggle && !emailtoggle) ||
                  (!apptoggle  && emailtoggle) ||
                  (!apptoggle && !emailtoggle)) && (
                  <SwitchOff
                    onClick={() => {
                      toggleButtonAllNotify(true);
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;
