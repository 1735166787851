/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
//import { Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getInitials } from "@shared/utility";
import { useParams } from "react-router-dom";
import { getProjectByID } from "@slices/admin-slice";
import dayjs from "dayjs";
import { priceSplitter } from "shared/utility";

const AdminProjectDetails = () => {
  const params = useParams();
  const location = useLocation();
  const { id } = location.state;
  const { projectDetails } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjectByID({ id: id }));
  }, [dispatch]);

  return (
    <div>
      <div className="bg-white rounded-sm pl-24 py-6">
      {projectDetails && (
          <div>
            <div>
              <div className="flex justify-between mb-10">
                <div className="flex items-center ">
                  {/* <img
                    className="rounded-circle w-[60px] h-[60px] mr-3"
                    src="https://cdn.smehost.net/2020sonymusiccouk-ukprod/wp-content/uploads/2019/10/Miley-Cyrus-03_MidnightSky_WhiteLight_2302.jpg"
                    alt=""
                  /> */}
                  <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill mr-5">
                  {projectDetails?.user_image !== null ? (
                    <img
                    className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                      src={`${projectDetails?.user_image?.url}`}
                      alt=""
                    />
                  ) : (
                    `${getInitials(projectDetails?.vendor?.name)}`
                  )}
                  </div>
                   
                  {/* <div className="w-[60px] h-[60px] mr-5">
                    <Avatar name={project?.vendor?.name} round={true} size="70" />
                  </div> */}

                  <div>
                    <h4 className="text-primary">{projectDetails?.vendor?.company}</h4>
                    <h4 className="text-[14px] text-vendor">
                      {projectDetails?.vendor?.name}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 mb-7">
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project title:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {projectDetails?.title}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project type:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {projectDetails?.type}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Category:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {projectDetails?.category?.name}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project description:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4 break-words">
                  {projectDetails?.description}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project duration:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {projectDetails?.duration}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Country:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {projectDetails?.country?.name}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Budget:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {priceSplitter(projectDetails?.budget ?? 0) + " USD"}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project start date:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {dayjs(projectDetails?.start_date).format("DD/MM/YYYY")}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2 break-words">
                  Additional notes:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {projectDetails?.additional_notes}
                </h4>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminProjectDetails;
