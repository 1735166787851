/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import EclipseDotsIcon from "components/ui/icons/EclipseDotsIcon";
//eslint-disable-next-line
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToggledOfferingSub } from "store/slices/vendors/offerings-slice";
import Modal from "@mui/material/Modal";

function RecordAction({ option }) {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          {" "}
          <div className="cursor-pointer">
            {" "}
            <EclipseDotsIcon />
          </div>
          <div className="absolute top-8 right-8 z-10 w-[188px] rounded-md text-[14px] font-light text-vendor">
            <Paper elevation={4}>
              <div className="grid">
                <h1 className=" hover:bg-primary hover:bg-opacity-10 pl-6 py-2.5">
                  Modify
                </h1>
                <h1 className=" hover:bg-primary hover:bg-opacity-10 pl-6 py-2.5">
                  View details
                </h1>
                <h1 className=" hover:bg-primary hover:bg-opacity-10 pl-6 py-2.5">
                  Manage interests
                </h1>
              </div>
            </Paper>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default RecordAction;
