/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import MenuItems from "./menu/Items";
import Rectangle from "@ui/Rectangle";
import Logo from "@images/daniola-logo.png";

const Sidebar = ({ items, sliceRange }) => {
  let menu_items = [];
  let sub_items = [];
  items.forEach((x) => {
    if (x.permission !== "restricted") {
      menu_items.push(x);
    }
  });
  menu_items.forEach((x) => {
    if (x?.subList) {
      sub_items = [];
      x.subList.forEach((y) => {
        if (y.permission !== "restricted") {
          sub_items.push(y);
        }
      });
      x.subList = sub_items;
    }
  });

  return (
    <div className=" bg-secondary h-full fixed w-[16.5%] overflow-y-auto scrollbar-hide">
      <div className="mt-5 mb-6">
        <img className="mx-auto w-3/5 h-13 mb-8 ml-7" src={Logo} alt="logo" />
        <MenuItems menuItems={menu_items} sliceRange={sliceRange} />
      </div>
    </div>
  );
};

export default Sidebar;
