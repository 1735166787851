const ListIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 4H8V7H4V4Z" fill="#D9E8FC" fillOpacity="0.7" />
      <path d="M4 8H8V11H4V8Z" fill="#D9E8FC" fillOpacity="0.7" />
      <path d="M4 16H8V19H4V16Z" fill="#D9E8FC" fillOpacity="0.7" />
      <path d="M4 12H8V15H4V12Z" fill="#D9E8FC" fillOpacity="0.7" />
      <path d="M9 4H20V7H9V4Z" fill="#D9E8FC" fillOpacity="0.7" />
      <path d="M9 8H20V11H9V8Z" fill="#D9E8FC" fillOpacity="0.7" />
      <path d="M9 16H20V19H9V16Z" fill="#D9E8FC" fillOpacity="0.7" />
      <path d="M9 12H20V15H9V12Z" fill="#D9E8FC" fillOpacity="0.7" />
    </svg>
  );
};

export default ListIcon;
