const TeamIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2051 12V7.5H9.20508V4H10.7051V0H6.70508V4H8.20508V7.5H2.20508V12H0.705078V16H4.70508V12H3.20508V8.5H8.20508V12H6.70508V16H10.7051V12H9.20508V8.5H14.2051V12H12.7051V16H16.7051V12H15.2051Z"
        fill="#1C304A"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default TeamIcon;
