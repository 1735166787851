/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Popover, Paper, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { DropDownIcon, TrashIcon, EclipseDotsIcon } from "@ui/icons";
import { TableEmptyState, TableSkeleton, ControlledModal, Button } from "@ui";
import FormControl from "@mui/material/FormControl";
import { statusColorScheme } from "constants/index";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function UserAdminsTable({
  openModal,
  data,
  pagination,
  handleDeleteOpen,
  handleClosed,
  deleteUser,
  setOpened,
  opened,
  handleSetPerPage,
  handleChangePage,
  loading,
}) {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [uuid, setuuid] = useState();
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setuuid(data.users._id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="flex flex-col">
      <ControlledModal
        isOpen={opened}
        handleClose={handleClosed}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
      >
        <p className="text-head text-center font-semibold text-[22px]">
          Delete user?
        </p>
        <p className="text-lightgray text-center font-light text-base mt-2">
          Are you sure you want to delete this user?
        </p>
        <div className="flex flex-row justify-center gap-4 mt-6">
          <Button
            type="button"
            variant="text"
            color="neutral"
            onClick={() => setOpened(!opened)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="contained"
            color="danger"
            onClick={() => {
              deleteUser();
            }}
          >
            Yes, delete
          </Button>
        </div>
      </ControlledModal>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full max-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-auto  border-gray-200 sm:rounded-lg bg-white">
            {loading && <TableSkeleton />}
            {!loading && data?.length > 0 && (
              <>
                <table className="min-w-full divide-y divide-[#1A38601A] border-b border-[#1A38601A]">
                  <thead className="bg-[#EFF0FE] ">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        s/n
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        Business Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        <div className="flex items-center">profile(s)</div>
                      </th>
                    
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        <div className="flex items-center">Requested At</div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        <div className="flex items-center">Approved At</div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        <div className="flex items-center">Status</div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        <div className="flex items-center">
                          <span></span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white font-light divide-y divide-[#1A38601A]">
                    {data?.length > 0 &&
                      data?.map((record, index) => (
                        <tr className="text-[#18273AF0]" key={index + 1}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {index + 1}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {record?.users?.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {record?.users?.email}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {record?.roles?.name ? record?.roles?.name : record?.roles.map((data, i) => (
                              <span
                                className="bg-[#193B670D] rounded mr-2 py-1 px-2"
                                key={i + 1}
                              >
                                {data.name}
                              </span>
                            ))}
                            {/* <span className="bg-[#193B670D] rounded mr-2 py-1 px-2"></span> */}
                          </td>
                        
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {dayjs(record?.users?.created_at).format(
                              "MMMM D, YYYY ,hh:mm a"
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {record?.verified_at ? dayjs(record?.verified_at).format("MMMM D, YYYY ,hh:mm a") : ""}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            <span className={`py-1 px-5 inline-flex capitalize text-sm leading-5 font-light rounded bg-[${statusColorScheme[record?.users?.status].bg}] text-[${statusColorScheme[record?.users?.status].text}] `}>
                              {record?.users?.status}
                            </span>
                          </td>

                          <td className="flex justify-end px-6 py-4 whitespace-nowrap text-sm">
                            <span
                              className="cursor-pointer"
                              onClick={() => handleDeleteOpen(record.users._id)}
                            >
                              <TrashIcon />
                            </span>

                            <div className="rotate-90 mx-7 cursor-pointer">
                              <span
                                aria-describedby={id}
                                onClick={(event) => handleClick(event, record)}
                              >
                                <EclipseDotsIcon />
                              </span>
                              <div className="shadow-2xl">
                                <Popover
                                  elevation={0}
                                  id={id}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                >
                                  <Paper>
                                    <div className="grid  z-10  rounded-md w-[188px] border-2 border-lightgray500 border-opacity-20 text-[14px] font-light text-vendor cursor-pointer">
                                      <span
                                        className=" hover:bg-primary hover:bg-opacity-10 pl-6 py-2.5"
                                        onClick={() => {
                                          navigate(
                                            "./account-information" + `/${uuid}`
                                          );
                                        }}
                                      >
                                        View account
                                      </span>
                                      <span
                                        className=" hover:bg-primary hover:bg-opacity-10 pl-6 py-2.5"
                                        onClick={() => {
                                          handleClose();
                                          openModal(uuid);
                                        }}
                                      >
                                        Send message
                                      </span>
                                    </div>
                                  </Paper>
                                </Popover>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="flex justify-start lg:justify-end items-center w-full py-4 px-8 text-[13px] uppercase text-[#18273AF0]">
                  <div className="flex justify-between gap-2 items-center">
                    <p>Rows per page: {pagination?.limit}</p>
                    <FormControl sx={{ height: "30px", border: 0 }}>
                      <Select
                        sx={{ height: "30px", border: 0 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => handleSetPerPage(e)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="mx-6">
                    1 - {Math.ceil(pagination?.total / pagination?.limit)} of{" "}
                    {pagination?.page}
                  </div>
                  <div className="flex justify-between gap-10">
                    {pagination?.prevPage && (
                      <div
                        className="rotate-90 cursor-pointer"
                        onClick={() => handleChangePage(pagination?.prevPage)}
                      >
                        <DropDownIcon />
                      </div>
                    )}
                    {pagination?.page >=
                    Math.ceil(pagination?.total / pagination?.limit)
                      ? null
                      : pagination?.page + 1 && (
                          <div
                            className="-rotate-90 cursor-pointer"
                            onClick={() =>
                              handleChangePage(pagination?.page + 1)
                            }
                          >
                            <DropDownIcon />
                          </div>
                        )}
                  </div>
                </div>
              </>
            )}
            {data !== null && !data?.length > 0 && !loading && (
              <TableEmptyState text="user" />
            )}

            {/* <TableEmptyState text="user" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
