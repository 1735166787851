/* eslint-disable no-unused-vars */
import React from "react";
import { Btn as Button } from "@atoms/button/button";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSingleInvestment } from "@slices/miners/investor-slice";
import DocumentIcon from "components/ui/icons/DocumentIcon";
import { getInitials, priceSplitter,checkPermission } from "shared/utility";

const InvestmentDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state;
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getSingleInvestment(id));
  }, []);

  const { investmentDetails } = useSelector((store) => store?.investors);
  const statusClass = (status) => {
    switch (status) {
      case "Awarded":
        return "bg-[#D9F2E4] text-[#43BE77]";
      case "Archived":
        return "bg-[#FFE7E5] text-[#F52419]";
      case "Completed":
        return "bg-[#D9F2E4] text-[#43BE77]";
      default:
        return "bg-[#FDF2E7] text-[#F2994A]";
    }
  };

  return (
    <>
      <div className="text-default w-full bg-white px-[34px] p-[30px] rounded-md mb-8">
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 w-full h-[66px]">
          <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
            {`${getInitials(investmentDetails?.investor?.name)}`}
          </div>
          <div className="flex flex-col justify-start items-start flex-grow-0 ml-4 flex-shrink-0 ">
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-base text-left text-primary">
              {investmentDetails?.investor?.name}
            </p>
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-sm text-left text-[#18273a]/[0.94]">
              {investmentDetails?.investor?.company}
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-sm font-light text-left text-[#1b2b41]/[0.72]">
              {investmentDetails?.investor?.country?.name}
            </p>
          </div>
          <div className="-mt-4 ml-auto">
            <Button
              disabled={checkPermission("Interactions")}
              onClick={() =>
                navigate("/miner/interactions/investments/details/investor", {
                  state: {
                    id,
                  },
                })
              }
              color="primary"
              size="mid"
            >
              Contact investor
            </Button>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start flex-grow-0 mt-6  flex-shrink-0">
          <div className="flex flex-row justify-between mb-[15px]">
            <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
              Mineral:
            </p>
            <p className="text-base font-light text-left text-[#18273a]/[0.94]">
              {investmentDetails?.proposal?.mineral?.name}
            </p>
          </div>
          <div className="flex flex-row justify-between mb-[15px]">
            <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
              Production phase:
            </p>
            <p className="text-base font-light text-left text-[#18273a]/[0.94]">
              {investmentDetails?.proposal?.production_phase?.slug}
            </p>
          </div>
          <div className="flex flex-row justify-between mb-[15px]">
            <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
              Investment type:
            </p>
            <p className="text-base font-light text-left text-[#18273a]/[0.94]">
              {investmentDetails?.proposal?.investment_type?.slug}
            </p>
          </div>
          <div className="flex flex-row justify-between mb-[15px]">
            <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
              Investment amount:
            </p>
            <p className="text-base font-light text-left text-[#18273a]/[0.94]">
              {priceSplitter(investmentDetails?.proposal?.amount)} USD
            </p>
          </div>

          {/* <div className="flex flex-row justify-start items-center mb-[15px]">
                        <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
                            Funding terms:
                        </p>
                        <p className="flex-1 text-base font-light text-left text-[#18273a]/[0.94]">
                            {investmentDetails?.proposal?.funding_terms}
                        </p>
                    </div> */}
          <div className="flex flex-row justify-start items-center mb-[15px]">
            <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
              Status:
            </p>
            <p
              className={`text-base font-light text-left rounded py-1 px-5 ${statusClass(
                investmentDetails?.status
              )}`}
            >
              {investmentDetails?.status}
            </p>
          </div>
        </div>
      </div>

      <h3 className="text-2xl mb-4 text-heading">Applications details:</h3>
      <div className="text-default w-full bg-white px-[34px] p-[30px] rounded-md">
        <div className="flex justify-between items-start">
          <div className="flex justify-start items-start w-full">
            <div className="w-[331px] h-[217px] flex justify-center align-center items-center text-2xl text-primary  bg-fill">
              <img
                src={investmentDetails?.proposal?.product_image?.url ? investmentDetails?.proposal?.product_image?.url : investmentDetails?.proposal?.product_image[0]?.url}
                alt="Product"
                className="w-full h-full rounded-[6px]"
              />
            </div>
            <div className="flex flex-col justify-start items-start flex-grow-0 ml-8 flex-shrink-0 ">
              <h3 className="mb-1 text-2xl">
                {investmentDetails?.proposal?.product_name}
              </h3>
              <span className="mb-1 text-lg text-primary">
                {investmentDetails?.proposal?.mineral?.name}
              </span>
              <span className="mb-1 text-base text-primary font-light text-lightgray/[0.72]">
                {investmentDetails?.proposal?.mine?.mine_name}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-start items-center mb-[15px] mt-6">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Company name:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {investmentDetails?.proposal?.company}
          </p>
        </div>

        <div className="flex flex-row justify-start items-center mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Address:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {investmentDetails?.proposal?.address || "-"}
          </p>
        </div>

        <div className="flex flex-row justify-start items-center mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Additional <br />
            information:
          </p>
          <p className="flex-1 text-base font-light text-left text-[#18273a]/[0.94]">
            {investmentDetails?.additional_notes || "-"}
          </p>
        </div>

        <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0">
          <div className="flex flex-row justify-between mb-[15px]">
            <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
              Documentation:
            </p>
            <p className="text-base font-light text-left text-[#18273a]/[0.94] flex items-center">
              {investmentDetails?.proposal?.documents?.map((item, index) => (
                <a
                  key={index}
                  download
                  target="_blank"
                  rel="noreferrer"
                  href={item.url}
                  className=" flex items-center"
                >
                  <DocumentIcon />
                  <span className="text-primary text-sm font-light ml-2">
                    {item.name}
                  </span>
                </a>
              ))}
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-start items-center mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Quantity:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {investmentDetails?.proposal?.quantity} dmT
          </p>
        </div>
        <div className="flex flex-row justify-start items-center mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Price:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {`${investmentDetails?.proposal?.price} USD`}
          </p>
        </div>
        <div className="flex flex-row justify-start items-center mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Production phase to fund:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {investmentDetails?.proposal?.production_phase?.slug}
          </p>
        </div>
        <div className="flex flex-row justify-start items-center mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Investment type:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {investmentDetails?.proposal?.investment_type?.slug}
          </p>
        </div>
        <div className="flex flex-row justify-start items-center mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Investment duration:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {investmentDetails?.proposal?.duration}
          </p>
        </div>
        <div className="flex flex-row justify-start items-center mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Investment amount:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {`${investmentDetails?.proposal?.amount} USD`}
          </p>
        </div>
      </div>
      <div className="w-full bg-white px-[34px] p-[30px] mt-8 rounded-md">
        <h3 className="text-2xl mb-4">Proposal:</h3>
        <p className="text-base font-light text-[#18273a]/[0.94]">
          {investmentDetails?.proposal?.proposal}
        </p>
      </div>
    </>
  );
};

export default InvestmentDetails;
