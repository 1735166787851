/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Description from "./description";
import Review from "./review";
import Payment from "./payment";
import Complete from "./complete";
import { Button, Stepper, Checkbox } from "@ui";
import { useAuth } from "@hooks";
import { useSelector, useDispatch } from "react-redux";
import { ErrorMessage } from "@components";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { storeMinerInventory } from "@slices/miners/inventory-slice";
import { toast } from "react-toastify";
import { getProfile, fetchAllNotifications } from "@slices/settings-slice";
import { getServiceOptions } from "store/slices/miners/vendor-slice";

const AddProduct = () => {
  const [activeStep, setActiveStep] = useState(0);
  const _FormData = new FormData();
  const [payment_type, setPaymentType] = useState("");
  const [balance, setBalance] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [disableNextButton, SetDisableNextButton] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { loading, inventoryProduct } = useSelector(
    (state) => state.inventories
  );
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsError, setTermsError] = useState();
 
  const handleCheck = (e) => {
    setTermsChecked(e.target.checked);
    if (!e.target.checked) {
      setTermsError("Accept terms");
    } else {
      setTermsError("");
    }
  };

  const navigate = useNavigate();
  const { user } = useAuth();



  const handleNext = () => {
    if (activeStep === 0) {
      if (termsChecked) {
        childFunc.current();
      } else {
        setTermsError("Accept terms");
        return;
      }
    }
    if (!disableNextButton)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (payment_type == "stripe") {
      if (!stripe || !elements) {
        return;
      }
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: user.name,
          email: user.email,
        },
      });

      if (!result?.paymentMethod) {
        toast.error(result.error.message);
      }

      const {
        duration,
        mine_id,
        mineral_slug,
        product_image,
        unit_of_measurement,
        ...updatedInventoryProduct
      } = inventoryProduct;
      const [startDate, endDate] = duration;
      
      for (const file of product_image) {
        _FormData.append("product_image", file);
      }

      const data = {
        ...updatedInventoryProduct,
        payment_method: payment_type,
        payment_method_id: result.paymentMethod.id,
        mine_id: mine_id.id,
        mineral_slug: mineral_slug.slug,
        // product_image: product_image.map((image) => {
        //   return {
        //     id: image.id,
        //   };
        // }),
        unit_of_measurement: unit_of_measurement.id,
        product_start_date: dayjs(startDate).format("YYYY-MM-DD"),
        product_end_date: dayjs(endDate).format("YYYY-MM-DD"),
      };

      _FormData.append("data", JSON.stringify(data));

      const response = await dispatch(storeMinerInventory(_FormData));
      if (response.payload.status === 200 || response.payload.status === 201) {
        dispatch(getProfile());
        dispatch(fetchAllNotifications({ limit: 10 }));
        setCompleted(true);
        localStorage.removeItem("formData");
      }
    } else {
      if (balance >= 500) {
        const {
          duration,
          mine_id,
          mineral_slug,
          product_image,
          unit_of_measurement,
          ...updatedInventoryProduct
        } = inventoryProduct;
        const [startDate, endDate] = duration;
        for (const file of product_image) {
          _FormData.append("product_image", file);
        }
        const data = {
          ...updatedInventoryProduct,
          payment_method: payment_type,
          payment_method_id: "",
          mine_id: mine_id.id,
          mineral_slug: mineral_slug.slug,
          unit_of_measurement: unit_of_measurement.id,
          product_start_date: dayjs(startDate).format("YYYY-MM-DD"),
          product_end_date: dayjs(endDate).format("YYYY-MM-DD"),
        };

        _FormData.append("data", JSON.stringify(data));

        const response = await dispatch(storeMinerInventory(_FormData));
        if (
          response.payload.status === 200 ||
          response.payload.status === 201
        ) {
          if (
            response.payload.data.code == 400 ||
            response.payload.data.code == 422
          ) {
            toast.error(response.payload.data.message);
          } else {
            setCompleted(true);
            dispatch(getProfile());
            dispatch(fetchAllNotifications({ limit: 10 }));
            localStorage.removeItem("formData");
          }
        }
      } else {
        toast.error("Insufficient Balance");
      }
    }
    console.log("BALANCE", balance);
  };

  const childFunc = useRef(null);

  const [steps] = useState([
    {
      label: "Description",
      component: (
        <Description
          childFunc={childFunc}
          setDisable={SetDisableNextButton}
          {...{ handleCheck, termsChecked }}
        />
      ),
    },
    {
      label: "Review",
      component: <Review />,
    },
    {
      label: "Payment",
      component: (
        <Payment setpaytype={setPaymentType} setBalance={setBalance} />
      ),
    },
  ]);

  return (
    <div className="border card border-solid border-dark-gray-100 rounded-md bg-white">
      <div className="flex flex-col lg:hidden mt-10">
        <Stepper steps={steps} activeStep={activeStep} />
        <h3 className="text-2xl font-normal text-heading my-8">
          Add new product
        </h3>
      </div>
      <div className="grid grid-cols-4 lg:divide-x lg:divide-disabled">
        <div className="col-span-1 py-10 mx-auto">
          {" "}
          <h3 className="hidden lg:flex text-2xl font-normal text-heading my-8">
            Add new product
          </h3>
          {/* stepper starts here */}
          <div className="hidden lg:flex">
            {" "}
            <Stepper steps={steps} activeStep={activeStep} />
          </div>
          {/* stepper ends here */}
        </div>

        <div className="col-span-3 py-10 px-8">
          {/* stepper contents here */}
          {!completed && (
            <div>
              {steps[activeStep].component}
              <div>
                {activeStep === 0 && (
                  <div className="flex items-center gap-2">
                    <Checkbox
                      value="true"
                      checked={termsChecked}
                      onChange={(e) => handleCheck(e)}
                    />
                    <p className="font-light text-ts">
                      I agree to the{" "}
                      <span className="text-primary">
                        Terms and Conditions.
                      </span>
                    </p>
                  </div>
                )}
                {termsError && activeStep === 0 && (
                  <ErrorMessage>{termsError}</ErrorMessage>
                )}
              </div>
              <div
                className={`flex ${
                  activeStep === 0 ? "justify-end" : "justify-between"
                } mt-8`}
              >
                {activeStep > 0 && (
                  <Button
                    variant="contained"
                    onClick={handleBack}
                    color="neutral"
                  >
                    Back
                  </Button>
                )}
                <div>
                  {activeStep !== steps.length - 1 && (
                    <Button
                      variant="contained"
                      // disabled={disableNextButton}
                      onClick={handleNext}
                      color="primary"
                    >
                      Next
                    </Button>
                  )}
                  <form onSubmit={handleSubmit}>
                    {activeStep === steps.length - 1 && (
                      <Button
                        variant="contained"
                        disabled={!stripe}
                        loading={loading}
                        type="submit"
                        color="primary"
                      >
                        Pay now
                      </Button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          )}
          {/* stepper contents ends here */}
          {/* content after all steps have been completed */}
          {completed && (
            <>
              <Complete />
              <div className="flex justify-center gap-8 mt-8">
                <Button
                  variant="contained"
                  onClick={() => navigate("/miner/inventory")}
                  color="neutral"
                >
                  Back to inventory
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/miner/auctions")}
                >
                  Go to auctions
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
