import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { AdminMines } from "pages/admin/index";
import { ControlledModal, Button } from "@ui";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AdminUserTabs = () => {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="w-full mb-5">
      <div className="border-b border-gray-light">
        <div className="flex items-center justify-between">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="secondary"
          >
            <Tab label="All" {...a11yProps(0)} />
            <Tab label="Approved" {...a11yProps(1)} />
            <Tab label="Suspended" {...a11yProps(2)} onClick={handleOpen} />
          </Tabs>
        </div>
      </div>
      <TabPanel value={value} index={0}>
        <AdminMines />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* <ApprovedMines /> */}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ControlledModal
          isOpen={open}
          handleClose={handleClose}
          className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
        >
          <p className="text-head text-center font-semibold text-[22px]">
            Suspend Mine?
          </p>
          <p className="text-lightgray text-center font-light text-base mt-2">
            Are you sure you want to Suspend this mine?
          </p>
          <div className="flex flex-row justify-center gap-4 mt-6">
            <Button type="button" variant="text" color="neutral">
              Cancel
            </Button>
            <Button type="button" variant="contained" color="danger">
              Yes, suspend
            </Button>
          </div>
        </ControlledModal>
      </TabPanel>
    </div>
  );
};

export default AdminUserTabs;
