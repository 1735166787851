/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@mui/material";
import { Search, Button, TableSkeleton, Tab } from "@ui";
import { VendorItem } from "@pages/admin";
import Skeleton from "@components/investors/Skeleton";
import { SearchIcon } from "@ui/icons";

const VendorsTabs = ({
  handleSearch,
  handleSearchSubmit,
  handleSearchWhat,
  handleSearchWhere,
  tableInfo,
  handleTabChange,
  handleOpen,
  data,
  loading,
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="flex flex-7 mb-3">
        <form onSubmit={handleSearchSubmit} className="flex flex-7">
          <div className="flex items-center relative mr-4 w-full">
            <div className="absolute flex items-center ml-2 h-full">
              <h4>What</h4>
            </div>
            <input
              type="text"
              placeholder="Company name"
              onChange={handleSearchWhat}
              className=" px-16 h-[42px] w-full rounded-md border-transparent text-inputtext bg-inputbg"
            />
          </div>
          <div className="flex items-center relative w-full mr-3">
            <div className="absolute flex items-center ml-2 h-full">
              <h4>Where</h4>
            </div>
            <input
              type="text"
              placeholder="Location"
              onChange={handleSearchWhere}
              className=" px-16 h-[42px] w-full rounded-md border-transparent text-inputtext bg-inputbg"
            />
          </div>
          <Button type="submit" color="primary">
            <SearchIcon color="#ffff" opacity="1" />
          </Button>
        </form>
      </div>
      <Tab tabData={tableInfo.tabData} handleTabChange={handleTabChange} />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-hidden sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full max-w-[1020.270px] sm:px-6 lg:px-8">
            <div className="flex-1 grid lg:grid-cols-3 sm:grid gap-[30px] font-default">
              {loading ? (
                <>
                  {Array(3)
                    .fill(null)
                    .map((_, index) => (
                      <Skeleton key={index} />
                    ))}
                </>
              ) : (
                data?.map((vendor) => (
                  <VendorItem
                    vendor={vendor}
                    key={vendor._id}
                    handleOpen={handleOpen}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
    // <div className="w-full mb-5">
    //   <div className="border-b border-gray-light">
    //     <div className="flex items-center justify-between">
    //       <Tabs
    //         value={value}
    //         onChange={handleChange}
    //         aria-label="basic tabs example"
    //         textColor="secondary"
    //       >
    //         <Tab label="All" {...a11yProps(0)} />
    //         <Tab label="Archived" {...a11yProps(1)} />
    //       </Tabs>
    //     </div>
    //   </div>
    //   <TabPanel value={value} index={0}>
    //     <VendorItem />
    //   </TabPanel>
    //   <TabPanel value={value} index={1}>
    //     <VendorItem />
    //   </TabPanel>
    // </div>
  );
};

export default VendorsTabs;
