/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-key */
import dayjs from "dayjs";
import { Gavel, PackageIcon, DiamondIcon } from "@ui/icons";

const icons = [
  <Gavel opacity="1" />,
  <DiamondIcon color="#6166B6" opacity="1" />,
  <PackageIcon color="#6166B6" opacity="1" />,
];
// eslint-disable-next-line react/prop-types
const Activities = ({ activities }) => {
  return (
    <>
      {activities?.slice(-7).map((activity) => (
        <div key={activity.id} className="flex justify-between mt-6">
          <div className="flex gap-5">
            {icons[Math.floor(Math.random() * 3)]}
            <p className="text-heading text-sm font-light">
              {activity.message}{" "}
              {/* <span className="text-primary font-normal">Pure Molybdenum</span> */}
            </p>
          </div>
          <span className="text-tertiary text-xs">
            {dayjs(activity.created_at).format("hh:mm a")}
          </span>
        </div>
      ))}
    </>
  );
};

export default Activities;
