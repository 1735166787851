const HeadsetIcon = ({ color}) => {
  const iconColor = color ? color : "#D9E8FC";
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.82 12.5C18.511 11.649 17.851 10.989 17.021 10.687L17 9C17 6.5 14.53 4.5 11.5 4.5C8.47 4.5 6 6.5 6 9V10.68C4.827 11.103 4.004 12.205 4 13.5V14.5C4 16.157 5.343 17.5 7 17.5H8V10.5H7V9C7 7.07 9 5.5 11.5 5.5C14 5.5 16 7.07 16 9V10.5H15V17.5H16C17.657 17.5 19 16.157 19 14.5V16.23C19 18.036 17.536 19.5 15.73 19.5H14C14 18.948 13.552 18.5 13 18.5H12C11.448 18.5 11 18.948 11 19.5C11 20.052 11.448 20.5 12 20.5H15.73C18.088 20.5 20 18.588 20 16.23V12.5H18.82Z"
        fill={iconColor}
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default HeadsetIcon;
