import React from "react";
import { Search, ProposalTabs } from "@ui";
import { SearchIcon, FilterIcon } from "@ui/icons";

const InvestmentProposal = () => {
  return (
    <div>
      {/* searchbar and buttons */}
      <div className="flex  p-4 pl-0 justify-between bg-white h-fit">
        <div className="flex gap-2">
          <div className="w-[250px] lg:w-[548px] ml-2">
            <Search placeholder="Search database" />
          </div>
          <div className="bg-primary flex items-center justify-center rounded py-1 px-2 h-[43px]">
            <SearchIcon color="#ffff" opacity="1" />
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <div>
            <FilterIcon />
          </div>
          {/* <div className="w-[183px] h-[44px] ">
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
            >
              Export CSV
            </Button>
          </div> */}
        </div>
      </div>
      {/* main */}
      <div className="mt-5 font-default">
        <ProposalTabs />
      </div>
    </div>
  );
};

export default InvestmentProposal;
