const Sitemap = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 16V11.5H12.5V8H14V4H10V8H11.5V11.5H5.5V16H4V20H8V16H6.5V12.5H11.5V16H10V20H14V16H12.5V12.5H17.5V16H16V20H20V16H18.5Z"
        fill="#D9E8FC"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default Sitemap;
