/* eslint-disable no-unused-vars */
import { Button } from "@atoms/button/index";
import { Header } from "@atoms/header";
import { Check, Shape, Vector } from "@atoms/icons";
import { UserContext } from "@context/index";
import { get_offtakers_verification_data } from "@slices/onboarding/offtaker.slice";
import { get_mineral_data,get_sdgGoal_data,get_mineType_data } from "@slices/onboarding/miner.slice";
import { file_uploader } from "@slices/file.slice"
import PaperClip from "@ui/icons/PaperClip";
import Open from "@assets/images/dashOpen.png";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FileUpload, OfftakerForm } from "../../index";
import "./index.css";
import { toast } from "react-toastify";
import { BaseUrl } from "@config/app-url";
import { SuccessResponse } from "config/status-code";

const OfftakerAccountVerification = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // set needed states
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [number, setNumber] = useState(true);
    const [check, setCheck] = useState(false);
    const [nextButton, setNextButton] = useState(true);
    // set context
    const { numberTwo } = useContext(UserContext);
    const { checkTwo } = useContext(UserContext);
    const { form, setForm } = useContext(UserContext);

    /** get offtakers verification data */
    useEffect(() => {
        dispatch(get_mineral_data());
        dispatch(get_sdgGoal_data());
        dispatch(get_mineType_data());
    }, [dispatch]);

    /** Used to set check and number based on uploaded files */
    useEffect(() => {
        if (uploadedFiles.length > 0) {
            setCheck(true);
            setNumber(false);
        } else {
            setNextButton(false);
            setNumber(true);
            setCheck(false);
            expandForm(false)
        }
    }, [uploadedFiles]);

    /** Get Data from state set in get_miners_verification_data  */
    const { minerals ,mine_types ,sdg_goals} = useSelector((state) => state.miner_verification);

    /** Handle File upload */
    const handleChange = async (FileList) => {
        /** check if filelist is empty  */
        if (FileList.length <= 0) {
            return toast.error("No file selected");
        }
        /** Declare form data */
        const _FormData = new FormData();
        for (const file of FileList) {
            _FormData.append("file", file);
            setUploadedFiles((prevState) => prevState.concat(file));
            return expandForm();
        }
       
       
        /** set upload type */
        _FormData.append("type", "off_takers_account_verification");
    };

    /** Used to remove a file after upload from state */
    const deleteTag = (id) => {
        setUploadedFiles((prevState) => prevState.filter((file) => file.id !== id));
    };
    /** Used to expand form to show step 2 */
    const expandForm = (expand = true) => {
        if (expand) {
            setForm(true);
            setNextButton(false);
        } else {
            setForm(false);
            setNextButton(true);
        }
    };

    /** Used to hide form */
    const hideForm = () => {
        if (uploadedFiles.length > 0)
            setForm(!form);
    };

    return (
        <div className="mt-12">
            <Header />
            <div className="bg-white rounded p-8 mt-[113px] lg:mt-0  lg:bg-none my-4">
                <div className="flex justify-between my-8">
                    <div className="text-primary border-none cursor-pointer flex justify-end" > {" "} </div>
                    <Link to={BaseUrl} className="text-primary border-none cursor-pointer flex justify-end"> {" "}  Skip </Link>
                </div>
                <h2 className="font-bold text-3xl text-head mb-3">  Account verification </h2>
                <h6 className="text-lightgray pb-4 text-base font-light lg:mb-2 ">
                    To use the platform, we have to verify the authenticity of your account information.
                </h6>

                <div className="hidden lg:flex flex-col">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            {number && (<div className="rounded-full bg-primary text-white text-xs w-fit my-0.5 py-1 px-[9.5px]"> 1  </div>)}
                            {check && (<div className=" rounded-full outline-primary outline outline-1 w-fit py-[6.5px] px-[5.5px]">  <Check /> </div>)}
                            <p className="text-black font-thin ml-3">  Upload your company registration document </p>
                        </div>
                        <Shape className={`${uploadedFiles > 0 ? "none" : "rotate-180"} cursor-pointer`} />
                    </div>

                    <div className="w-full py-4 px-4 ml-2.5 my-2 border-l-2 border-[#1C345442]">
                        <FileUpload source={true} handleChange={handleChange} />
                        <div>
                            {uploadedFiles.map((file) => {
                                return (
                                    <p key={file.id} className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] align-center">
                                        <span>{<PaperClip />}</span>
                                        <span>{file ? `${file.name}` : "no files uploaded"}</span>
                                        <button className="text-red text-[16px] px-2" onClick={() => deleteTag(file.id)}> {" "} x{" "} </button>
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex justify-between items-center mb-5">
                        <div className="flex items-center">
                            {numberTwo && (<div className="rounded-full bg-primary text-white text-xs w-fit  my-0.5  py-1 px-[9.5px]"> 2 </div>)}
                            {checkTwo && (<div className=" rounded-full outline-primary outline outline-1  w-fit   py-[6.5px] px-[5.5px]">  <Check /> </div>)}
                            <p className="text-black font-thin ml-3">  Fill company information  </p>
                        </div>
                        <Shape className={`${form === false ? "none" : "rotate-180"} cursor-pointer`} />
                    </div>
                </div>

                <div className="text-center  lg:hidden  lg:my-6 2xl:ml-20 flex justify-center flex-row text-primary  h-fit md:items-center ">
                    <div>
                        {number && (
                            <div className="rounded-full bg-primary text-white text-xs mt-1 my-0.5 mx-5 py-1 px-[9.5px]">
                                1
                            </div>
                        )}
                        {check && (
                            <div className=" rounded-full outline-primary outline outline-1  flex justify-center align-center mx-5 py-2 mb-1 px-[7px] ">
                                <Check />
                            </div>
                        )}
                    </div>

                    <div className="mx-20 lg:mx-auto py-1 ">
                        <img className="rotate-90 lg:rotate-0	" alt="" src={Open} />
                    </div>

                    <div>
                        {numberTwo && (
                            <div className="rounded-full bg-primary text-white text-xs mt-1 my-0.5 mx-5 py-1 px-2">
                                2
                            </div>
                        )}

                        {checkTwo && (
                            <div className=" rounded-full outline-primary outline outline-1  flex justify-center align-center mx-5 py-2 mb-1 px-[7px]">
                                <Check />
                            </div>
                        )}
                    </div>
                </div>

                <div className="w-full py-4 px-4 ml-2.5 my-2  lg:hidden">
                    <FileUpload handleChange={handleChange} />
                    <div>
                        {uploadedFiles.map((file) => {
                            return (
                                <p
                                    key={file.id}
                                    className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] align-center"
                                >
                                    <span>{<PaperClip />}</span>
                                    <span>{file ? `${file.name}` : "no files uploaded"}</span>
                                    <button
                                        className="text-red text-[16px] px-2"
                                        onClick={() => deleteTag(file.id)}
                                    >
                                        {" "}
                                        x{" "}
                                    </button>
                                </p>
                            );
                        })}
                    </div>
                </div>

                <OfftakerForm uploadedFiles={uploadedFiles} />
                {(!form || nextButton) && (
                    <div className="mt-8 flex justify-end">
                        <Button disabled={!uploadedFiles.length > 0} size="small" onClick={expandForm} type="submit">  {" "}  Next{" "} </Button>
                    </div>
                )}
                {/* <div className="flex align-center pb-2 mt-20 font-thin">
                    <Vector />
                    <p className=" paragraph text-lightgray text-xs ml-2">
                        You can switch between multiple profiles later on
                    </p>
                </div> */}
            </div>
        </div>
    );
};
export default OfftakerAccountVerification;
