/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Input, Select, TextArea, Chips, Button } from "@ui";
import { postCreateAdminMineral } from "store/slices/admin-slice";
import { SuccessResponse } from "config/status-code";
import { toast } from "react-toastify";

const AddMineral = () => {
  const { loading } = useSelector((state) => state.admin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    name: "",
    description: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const submitMineral = () => {
    if(data.name == "") {
        toast.error("Name is required")
    } else if (data.description == "") {
      toast.error("Description is required");
    } else {
        let formData = {
            name:data.name,
            description:data.description
           };
     
           dispatch(postCreateAdminMineral(formData)).then((response) => {
            if (response.payload.status === 200 || response.payload.status === 201) {
              if (response.payload.data.code == 400 || response.payload.data.code == 422) {
                toast.error(response.payload.data.message);
              } else {
                toast.success(response.payload.data.message);
                navigate("/admin/minerals");
              }
            } else {
              console.log("error from then", response);
            }
      });
    }


  

  };

  return (
    <Card>
      <div className="max-w-[796px] mx-auto">
        <h2 className="text-heading text-2xl my-2">Add new Mineral</h2>
        <div className="my-6">
          <h3 className="my-2 text-lg text-heading">Mineral details</h3>
          <div className="grid lg:grid-cols-2 sm:grid gap-1">
            <Input
              label="Type Mineral Name"
              id="name"
              type="text"
              name="name"
              onChange={handleInputChange}
            />
          </div>
          <TextArea
            label="Description"
            name="description"
            onChange={handleInputChange}
          />
        </div>
        <div className="flex items-center justify-between mt-8">
        <div>
            <Button
              
              onClick={() => {
                submitMineral();
              }}
            >
              {loading ? "Processing..." : "Submit"}
            </Button>
          </div>
          <div>
          <button
           onClick={() => navigate("../admin/minerals")}
          
            className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
          >
            Cancel
          </button>
          </div>
         
        
        </div>
      </div>
    </Card>
  );
};

export default AddMineral;
