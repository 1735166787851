/* eslint-disable react/prop-types */
import React from "react";

export const Btn = ({
	children,
	type,
	name,
	disabled,
	id,
	onClick,
	width,
	size,
	error,

	...props
}) => {
	return (
		<button
			className={`${
				disabled ? "bg-primary cursor-not-allowed pointer-events-all" : "bg-primary"
			} font-default text-white flex justify-center py-2 mb-3 mt-4 px-10 rounded ${
				size === "small" ? "w-[fit-content]" : "w-full"
			} ${error === true ? "bg-[#F52419]" : "bg-primary"} `}
			id={id}
			type={type}
			name={name}
			disabled={disabled}
			onClick={onClick}
			width={width}
			{...props}>
			{children}
		</button>
	);
};
