// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
//import Countdown from "@components/auctions/Countdown";
//import { Button, Card, ControlledModal, TextArea } from "@ui";
import { TableEmptyState } from "@ui";

const ProposalItem = () => {
  // const navigate = useNavigate();
  // const [open, setOpen] = useState(false);
  // const [opened, setOpened] = useState(false);

  // const handleOpen = () => setOpen(true);
  // const handleOpenFeedback = () => {
  //   setOpened(true), setOpen(false);
  // };
  // const handleClosed = () => setOpened(false);

  // const handleClose = () => setOpen(false);
  return (
    <>
      {/* <div className="grid grid-cols-3 gap-6">
         <Card>
          <div className="h-[217px] rounded-lg overflow-hidden">
            <img
              className="h-full w-full object-cover"
              src="https//:www.image.com"
              alt="daniOla product card image"
            />
          </div>
          <h3 className="text-2xl text-heading mt-4 capitalize">
            Proposal name
          </h3>
          <p className="text-sm text-light font-light">Copper</p>
          <p className="text-sm text-gray font-light mb-6">Bingham mine</p>
          {/* timer */}

      {/* card information */}
      {/*<div className="grid grid-cols-5 gap-y-2 mb-3">
            <div className="col-span-2 font-light text-ts text-sm">
              <p>Loan type</p>
            </div>
            <div className="col-span-3 font-light text-light">
              <p>short termT</p>
            </div>
          </div>
          <div className="grid grid-cols-5 gap-y-2 mb-3">
            <div className="col-span-2 font-light text-ts text-sm">
              <p>Investment amount</p>
            </div>
            <div className="col-span-3 font-light text-light">
              <p>USD 56989</p>
            </div>
          </div>
          <div className="grid grid-cols-5 gap-y-2 mb-3">
            <div className="col-span-2 font-light text-ts text-sm">
              <p>Quantity</p>
            </div>
            <div className="col-span-3 font-light text-light">
              <p>576 /dmT</p>
            </div>
          </div>
          <div className="grid grid-cols-5 gap-y-2 mb-3">
            <div className="col-span-2 font-light text-ts text-sm">
              <p>Price</p>
            </div>
            <div className="col-span-3 font-light text-light">
              <p>7.978 USD/dmT</p>
            </div>
          </div>

          {/* button */}
      {/*  <div className="flex flex-col gap-6 mt-3">
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("../proposal-details")}
              fullWidth
            >
              View details
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={handleOpen}
              fullWidth
            >
              Archive
            </Button>
          </div>
          <ControlledModal
            isOpen={open}
            handleClose={handleClose}
            className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
          >
            <p className="text-head text-center font-semibold text-[22px]">
              Archive Auction?
            </p>
            <p className="text-lightgray text-center font-light text-base mt-2">
              Are you sure you want to archive auction for this user?
            </p>
            <div className="flex flex-row justify-center gap-4 mt-6">
              <Button type="button" variant="text" color="neutral">
                Cancel
              </Button>
              <Button
                type="button"
                variant="contained"
                color="danger"
                onClick={handleOpenFeedback}
              >
                Yes, archive
              </Button>
            </div>
          </ControlledModal>
          <ControlledModal
            isOpen={opened}
            handleClose={handleClosed}
            className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
          >
            <div className="flex flex-col justify-center gap-4 mt-6">
              <p className="text-head text-center font-semibold text-[22px]">
                Feedback to user
              </p>
              <p className="text-lightgray text-center font-light text-base mt-2">
                Kindly provide additional notes to explain to seller the reason
                for archiving the auction{" "}
              </p>
              <TextArea />
              <div className="flex justify-end ">
                <Button type="button" variant="contained" color="primary">
                  Send
                </Button>
              </div>
            </div>
          </ControlledModal>
        </Card>
      </div> */}
      <TableEmptyState text="investment proposal" />
    </>
  );
};

export default ProposalItem;
