const TrashIcon = ({ color }) => {
  const iconColor = color ? color : "#1C2E45";
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 7C17 7 17 6.49 15 6.2V5.5C14.983 4.668 14.305 4 13.47 4H10.47C9.655 4.017 9 4.682 9 5.5V6.2C8.235 6.268 7.548 6.559 6.993 7.006L6 7V8H18V7H17ZM10 5.5C10.005 5.226 10.226 5.005 10.499 5H13.5H13.501C13.783 5 14.014 5.22 14.03 5.499V6.06C13.677 6.018 13.267 5.995 12.852 5.995C12.735 5.995 12.619 5.997 12.503 6.001C11.95 6.001 10.44 6.001 10 6.071V5.501V5.5Z"
        fill={iconColor}
        fillOpacity="0.6"
      />
      <path
        d="M6 9V10H7V19C8.234 19.631 9.692 20 11.236 20C11.238 20 11.239 20 11.241 20H12.761C12.762 20 12.764 20 12.765 20C14.309 20 15.767 19.631 17.054 18.975L17 10H18V9H6ZM10 17.92C9.66 17.88 9.32667 17.8233 9 17.75V11H10V17.92ZM13 18H11V11H13V18ZM15 17.72C14.733 17.79 14.394 17.856 14.05 17.904L14 11H15V17.72Z"
        fill={iconColor}
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default TrashIcon;
