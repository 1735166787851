import { useNavigate } from "react-router-dom";
import Countdown from "@components/auctions/Countdown";
import { Button, Card } from "@ui";


const Auction = ({ auction, url }) => {
    const navigate = useNavigate();
    return (
      <Card>
        <div className="h-[217px] rounded-lg overflow-hidden">
          <img
            className="h-full w-full object-cover"
            src={auction?.image[0]?.url}
            alt="daniOla product card image"
          />
        </div>
        <h3 className="text-2xl text-heading mt-4 capitalize">{auction.name}</h3>
        <p className="text-sm text-light font-light">{auction.mine?.mine_name}</p>
        {/* timer */}
        <Countdown date={auction?.endDate} />
        {/* card information */}
        <div className="grid grid-cols-5 gap-y-2 mb-3">
          <div className="col-span-2 font-light text-ts text-sm">
            <p>Quantity</p>
          </div>
          <div className="col-span-3 font-light text-light">
            <p>
              {auction.quantity} {auction.unitOfMeasurement?.id}
            </p>
          </div>
        </div>
  
        <div className="grid grid-cols-5 gap-y-2 mb-3">
          <div className="col-span-2 font-light text-ts text-sm">
            <p>TC</p>
          </div>
          <div className="col-span-3 font-light text-light">
            <p>
              {auction.treatmentCost} USD/
              {auction?.unitOfMeasurement.id}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-y-2 mb-3">
          <div className="col-span-2 font-light text-ts text-sm">
            <p>Price</p>
          </div>
          <div className="col-span-3 font-light text-light">
            <p>
              {auction.price} USD/
              {auction?.unitOfMeasurement?.id}
            </p>
          </div>
        </div>
  
        <div className="grid grid-cols-5 gap-y-2 mb-3">
          <div className="col-span-2 font-light text-ts text-sm">
            <p>Total cost</p>
          </div>
          <div className="col-span-3 font-light text-light">
            <p>{auction.totalValue} USD</p>
          </div>
        </div>
        {/* button */}
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            navigate(url(auction.id), {
              state: auction,
            })
          }
          fullWidth
        >
          View details
        </Button>
      </Card>
    );
};
  
export default Auction