/* eslint-disable no-unused-vars */
import React,{useState} from "react";
import { Card, Button, Checkbox } from "@ui";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Modal, Step, StepLabel, Stepper, Rating } from "@mui/material";
import Padlock from "@ui/icons/PadLock";
import DocumentIcon from "components/ui/icons/DocumentIcon";
import StepperCheckIcon from "@ui/icons/StepperCheckIcon";
import DiplomaScroll from "@ui/icons/DiplomaScroll";
import { submitRatings } from "store/slices/offtaker-slice";

import { toast } from "react-toastify";
import {
  updateEta,
  updateTransactionStatus,
} from "@slices/miners/transaction-slice";

const Intransit = ({ status, transactionId: id, transaction }) => {
  const [isDisabled, setDisabled] = useState(true);
  const [openEta, setOpenEta] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [transaction_status, set_transaction_status] = useState("In Transit");
  const _FormData = new FormData();
  // Ratings logic
  const [ratings, setRatings] = useState(2);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [data, setData] = useState({
    transaction_eta: "",
    transaction_eta_terms: false,
    note: "",
  });

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    if (type == "checkbox") setDisabled(!checked);
    setData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? checked : value,
      };
    });
  };

  const submitDetails = async () => {
    const { transaction_eta, transaction_eta_terms } = data;
    try {
      const body = {
        action: "update_eta",
        transaction_eta,
        transaction_eta_terms,
      };
      _FormData.append("data", JSON.stringify(body));

      const response = await dispatch(
        updateEta({ formdata: _FormData, id: id })
      );
      if (response.payload.status == 200) {
        navigate("/miner/interactions/transactions/");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onSubmitRatings = async () => {
    try {
      setLoading(true);
      const body = {
        rating: ratings ?? 1,
        comment: comments,
      };

      const res = await dispatch(
        submitRatings({ id: transaction?._id, formdata: body, type: "miner" })
      );
      if (res.payload.status === 200 || res.payload.status === 201) {
        setLoading(false);
        setOpenConfirm(!openConfirm);
        return;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const submitStatus = async () => {
    try {
      const body = {
        action: "update_status",
        transaction_status,
      };
      _FormData.append("data", JSON.stringify(body));
      const response = await dispatch(
        updateTransactionStatus({ formdata: _FormData, id: id })
      );
      if (response.payload.status == 201) {
        setOpenStatus(false);
        navigate("/miner/interactions/transactions/");
      }

      return;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const steps = [
    "Pending",
    "Awaiting POP",
    "Awaiting POF",
    "Awaiting POC",
    "In Transit",
    "Delivered",
    "Completed",
  ];

  const indexOf = steps.findIndex((el) => {
    return el == status;
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 448,
    bgcolor: "background.paper",
    borderRadius: "0.25rem",
    boxShadow: 24,
    p: 4,
    outline: 0,
    padding: "22px",
  };

  const styleETA = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 448,
    height: 300,
    bgcolor: "background.paper",
    borderRadius: "0.25rem",
    boxShadow: 24,
    p: 4,
    outline: 0,
    padding: "22px",
  };

  return (
    <div>
      <>
        {/*Start Of Rating Modal */}
        <div>
          <Modal
            open={openConfirm}
            onClose={() => setOpenConfirm(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <h1 className="text-center text-[22px] mt-5">Rate Offtaker</h1>
              <div className="flex  items-center mt-3 flex-col gap-3">
                <img
                  className="rounded-full w-10 h-10 flex items-center justify-center text-white bg-primary mr-4"
                  src={`${transaction?.buyer?.profile_image.url}`}
                  alt=""
                />
                <h1 className="text-primary font-light text-base capitalize">
                  {transaction?.offtaker?.name}
                </h1>
                <div className="star">
                  <p className="text-center font-light text-lightgray text-xs">
                    Click on star or type in rating
                  </p>
                  <div className="flex flex-col items-center">
                    <Rating
                      name="simple-controlled"
                      className="!text-primary mt-3 !text-[30px] "
                      value={ratings}
                      onChange={(_, newValue) => {
                        setRatings(newValue);
                      }}
                    />
                    <div className="py-1 rounded-sm w-20 text-center bg-lightgray500 mt-5">
                      <span className=" grid grid-cols-3  px-2">
                        <span>
                          <input
                            type="number"
                            max="5"
                            value={ratings}
                            className="w-full text-center bg-lightgray500"
                            onChange={({ target: { value } }) => {
                              if (value <= 5) {
                                setRatings(value);
                              }
                            }}
                          />
                        </span>
                        <span className="text-dark-gray-500">/</span>
                        <span className="text-primary">5</span>
                      </span>
                    </div>
                    <div className="mb-3 xl:w-full w-full mt-5">
                      <label
                        htmlFor={"Dispute details"}
                        className="form-label inline-block mb-1 font-light text-xs text-[#1B2B41B8]"
                      >
                        How was the transaction?
                      </label>
                      <textarea
                        className="form-control placeholder:text-xs resize-none block w-[400px] p-[10px] font-light bg-clip-padding bg-[#1A38601A] border-none z-0 focus:shadow focus:outline-none focus:ring-primary rounded transition ease-in-out"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        placeholder="This field has limit of 160 characters"
                        onChange={({ target: { value } }) => setComments(value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-7 mb-3">
                <Button
                  color="neutral"
                  onClick={() => setOpenConfirm(!openConfirm)}
                >
                  Skip
                </Button>
                <Button onClick={() => onSubmitRatings()}>
                  {loading ? "Processing..." : "Submit ratings"}
                </Button>
              </div>
            </Box>
          </Modal>
        </div>

        {/* End of Rating Modal */}
        {/* update ETA Modal */}
        <div>
          <Modal
            open={openEta}
            onClose={() => setOpenEta(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleETA}>
              <div className="flex flex-col items-center justify-center w-">
                <div className="">
                  <p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434]">
                    New ETA
                  </p>
                  <div className="mb-3 xl:w- mt-2">
                    <form>
                      <div className="flex flex-col items-left w-full">
                        <p className="font-light text-ts text-left mb-2 text-sm">
                          Enter your new estimated time of arrival
                        </p>
                        <input
                          type="date"
                          name="transaction_eta"
                          onChange={handleInputChange}
                          // defaultValue="2022-05-24"
                          className="focus:outline-0 font-light text-gray-700 border-none ring-offset-0 focus:ring-primary bg-[#1A38601A] p-[10px] h-[42px] rounded-[0.25rem] w-full"
                        />
                        <div className="flex gap-2 mt-8">
                          <Checkbox
                            onChange={handleInputChange}
                            name="transaction_eta_terms"
                            value={data.transaction_eta_terms}
                          />
                          <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            I agree to the Terms that this is signed by me.
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center  mt-5">
                <Button
                  size="small"
                  disabled={isDisabled}
                  onClick={() => {
                    submitDetails();
                    setOpenEta(false);
                  }}
                >
                  Confirm and update
                </Button>
              </div>
            </Box>
          </Modal>
        </div>

        {/* update Status Modal */}
        <div>
          <Modal
            open={openStatus}
            onClose={() => setOpenStatus(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434]">
                Update Status
              </p>
              <div className="mb-[24px] xl:w-full">
                <label
                  htmlFor={"Update the transaction status:"}
                  className="form-label inline-block mb-2 font-light text-base text-[#1B2B41B8]"
                >
                  Update the transaction status :
                </label>
                <div className="relative flex-col flex items-center">
                  <select
                    className="bg-fill border-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded cursor-text text-input py-2 px-4 text-sm"
                    id="update"
                    type="dropdown"
                    value={transaction_status}
                    onChange={(e) => {
                      set_transaction_status(e.target.value);
                    }}
                  >
                    <option value="In Transit">In Transit</option>
                    <option value="Delivered">Delivered</option>
                  </select>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <Button
                  size="small"
                  onClick={() => submitStatus()}
                  disabled={!transaction_status}
                >
                  Confirm
                </Button>
              </div>
            </Box>
          </Modal>
        </div>

        <Card noPadding>
          <div className="border-b-[1px] border-[#1C345442] h-[258px] mx-[-1.5rem]">
            <div className="divide-x divide-disabled mx-[2.5rem]">
              <div className="text-default bg-white pt-[30px]">
                <div className="flex items-center justify-between">
                  <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[24px] text-left text-[#192434]">
                    Main Info
                  </p>
                  <div className="flex row items-start ">
                    {status === "In Transit" && (
                      <>
                        <div className="ml-[8px] bg-[#6166B6] rounded-[4px] py-1">
                          <Button
                            variant="outlined"
                            color="text-[#ffffff]"
                            fontWeight="font-light"
                            onClick={() => {
                              setOpenEta(true);
                            }}
                          >
                            Update ETA
                          </Button>
                        </div>
                        <div className="ml-[8px] bg-[#6166B6] rounded-[4px] py-1">
                          <Button
                            variant="outlined"
                            color="text-[#ffffff]"
                            fontWeight="font-light"
                            onClick={() => {
                              setOpenStatus(true);
                            }}
                          >
                            Update Status
                          </Button>
                        </div>
                      </>
                    )}

                    {status === "Completed" && (
                      <div className="ml-[8px] bg-[#6166B6] rounded-[4px] py-1">
                        <Button
                          variant="outlined"
                          color="text-[#ffffff]"
                          fontWeight="font-light"
                          onClick={() => {
                            setOpenConfirm(true);
                          }}
                        >
                          Rate Offtaker
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="text-default  w-[796px] bg-white">
                  <div className="flex">
                    <div className="w-[50%]">
                      <div className="flex mb-[16px]">
                        <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                          Transaction ID:
                        </p>
                        <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                          {transaction?.transaction_id ?? "N/A"}
                        </p>
                      </div>
                      <div className="flex mb-[16px]">
                        <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                          Product name:
                        </p>
                        <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                          {transaction?.product?.product_name ?? "N/A"}
                        </p>
                      </div>
                      <div className="flex mb-[16px]">
                        <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                          Name of buyer:
                        </p>
                        <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                          <span className="flex items-center text-[#6166B6]">
                            {transaction?.buyer?.name ?? "N/A"}
                          </span>
                        </p>
                      </div>
                      <div className="flex mb-[16px]">
                        <p className="flex-grow-0 mr-[100px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                          Status:
                        </p>
                        <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                          <span
                            className={`py-1 px-5 inline-flex capitalize text-sm leading-5 font-light rounded bg-[#EAE6FF] text-[#7B61FF] `}
                          >
                            {status ?? "N/A"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="mb-[16px]">
                        <p>Transaction value</p>
                        <p className="font-medium text-lg">
                          {transaction?.product?.product_total_value}
                        </p>
                      </div>
                      <div>
                        <p>Commission value (5% of transaction value)</p>
                        <p className="font-medium text-lg">
                          {(
                            transaction?.product?.product_total_value * 0.05
                          )?.toFixed(2) ?? "N/A"}{" "}
                          {transaction?.product?.product_total_value && "USD"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="divide-x divide-disabled">
              <div className="text-default bg-white pt-[30px]">
                <div className="flex items-center justify-between">
                  <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[24px] text-left text-[#192434]">
                    Documents
                  </p>
                </div>
                <div className="flex flex-wrap pb-2">
                  {transaction?.documents[0].map((document, i) => (
                    <a
                      key={i}
                      download
                      target="_blank"
                      rel="noreferrer"
                      href={document.url}
                      className=" flex items-center  text-xs mb-[16px]"
                    >
                      <DocumentIcon />
                      <span className="text-primary text-sm font-light ml-2">
                        {document.name}
                      </span>
                    </a>
                    // <p
                    //   key={i}
                    //   className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] ml-[19px]"
                    // >
                    //   <span>{<PaperClip />}</span>
                    //   <span>{document.name}</span>
                    // </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Card>
        <div className="mt-[30px]">
          <Card noPadding>
            <div className="">
              <div className="divide-x divide-disabled">
                <div className="text-default bg-white pt-[30px]">
                  <div className="flex items-center justify-between">
                    <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[16px] text-left text-[#192434]">
                      Timeline
                    </p>
                  </div>
                  <div>
                    <Stepper orientation="vertical">
                      {transaction?.timeline?.map((step, i) => (
                        <Step key={i}>
                          <StepLabel
                            StepIconComponent={() => (
                              <DiplomaScroll fill_="black" h_="16px" w="16px" />
                            )}
                          >
                            <div className="flex font-normal items-center">
                              <span className="font-[300] text-[#1b2b41a3]">
                                <span className="text-black mr-2">
                                  {" "}
                                  {step?.message}
                                </span>

                                {step?.created_at
                                  ? new Date(step?.created_at).toLocaleString()
                                  : null}
                              </span>
                            </div>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className="pt-[40px]">
          <Stepper alternativeLabel orientation="horizontal">
            {steps.map((step, i) => (
              <Step key={step}>
                <StepLabel
                  StepIconComponent={i <= indexOf ? StepperCheckIcon : Padlock}
                >
                  {step}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </>
    </div>
  );
};

export default Intransit;
