import React from "react";

const CashIcon = () => {
  return (

      <svg
        width="24"
        height="25"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 16.5L6 13.5H7V11.5H9V13.5H10L8 16.5Z"
          fill="#D9E8FC"
          fillOpacity="0.7"
        />
        <path
          d="M15 1.5V9.5H1V1.5H15ZM16 0.5H0V10.5H16V0.5Z"
          fill="#D9E8FC"
          fillOpacity="0.7"
        />
        <path
          d="M8 2.5C9.657 2.5 11 3.843 11 5.5C11 7.157 9.657 8.5 8 8.5H13V7.5H14V3.5H13V2.5H8Z"
          fill="#D9E8FC"
          fillOpacity="0.7"
        />
        <path
          d="M5 5.5C5 3.843 6.343 2.5 8 2.5H3V3.5H2V7.5H3V8.5H8C6.343 8.5 5 7.157 5 5.5Z"
          fill="#D9E8FC"
          fillOpacity="0.7"
        />
      </svg>

  );
};

export default CashIcon;
