/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Input, TextArea, Checkbox } from "@ui";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { MyTextField, SelectField } from "components/ui/Inputs";

import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Select as MultiSelect } from "@ui";
import TextField from "@mui/material/TextField";
import {
  setAddOfferingStep,
  setOfferingFormData,
  vendorConfig,
} from "store/slices/vendors/offerings-slice";
import PropTypes from "prop-types";
import Button from "components/ui/Button";
import * as yup from "yup";

import FormControl from "@mui/material/FormControl";
import { getCountries } from "store/slices/miners/vendor-slice";

const OfferingDetails = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const { countries } = useSelector((store) => store.miner_vendors);
  const { offeringFormData } = useSelector((store) => store.vendorOfferings);

  const handleChangee = (event, arrayHelpers, locations) => {
    const {
      target: { value },
    } = event;

    const isFound = locations.some((element) => {
      if (element.iso_code === value.iso_code) {
        return true;
      }

      return false;
    });

    if (!isFound) {
      arrayHelpers.push(value);
    }
    return;
  };

  const handleType = (e, setFieldValue) => {
    setFieldValue("type", e.target.value);
    setFieldValue("category", "");
  };
  const dispatch = useDispatch();

  // const initialValues = {
  //   type: "",
  //   category: "",
  //   job_title: "",
  //   description: "",
  //   location: [],
  //   price: "",
  //   duration: "",
  //   agree: false,
  //   contract_type: "",
  // };

  const validationSchema = yup.object({
    type: yup.string().required("Offering type is required"),
    category: yup.string().required(),
    job_title: yup.string().required("Job title is a required field."),
    description: yup.string().required(),
    price: yup.number().required(),
    contract_type: yup
      .string()
      .when("type", (type, schema) =>
        type === "Service"
          ? schema.required("Contract type is required")
          : schema
      ),
    duration: yup
      .array()
      .when("type", (type, schema) =>
        type === "Service"
          ? schema.required("Duration field is required")
          : schema
      ),
    quantity: yup
      .number()
      .when("type", (type, schema) =>
        type === "Commodity" ? schema.required("Quantity is required") : schema
      ),
    agree: yup
      .boolean()
      .required()
      .test("isTrue", "", function (value) {
        // your condition
        if (value !== true) {
          // setting the error message using the value's length
          return this.createError({
            message: `You have to agree to our terms and conditions`,
          });
        }
        return true;
      }),
    location: yup
      .array()
      .required()
      .test("required", "", function (value) {
        // your condition
        if (value.length < 1) {
          // setting the error message using the value's length
          return this.createError({
            message: `Location field is required`,
          });
        }
        return true;
      }),
  });

  validationSchema.validate({ agree: "true" }).catch(function (err) {
    err.name; // => 'ValidationError'
    err.errors; // => ['Deve ser maior que 18']
  });

  useEffect(() => {
    dispatch(getCountries());
  }, []);
  return (
    <>
      <div className="max-w-[796px] mx-auto">
        <h2 className="text-heading text-2xl mb-2">Offering details</h2>
        <p className="text-ts font-light my-2">
          Enter the details for the mineral resources as specified below.
        </p>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={offeringFormData}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              dispatch(setOfferingFormData(values));
              dispatch(setAddOfferingStep(1));
              setSubmitting(false);
            }}
          >
            {({
              values,
              handleChange,
              isSubmitting,
              setFieldValue,
              errors,
            }) => (
              <Form>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                <div className="flex">
                  <div className="mt-6 w-full mr-4">
                    <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                      Offering type
                    </p>
                    <SelectField
                      items={["Service", "Commodity"]}
                      name="type"
                      value={values.type}
                      onChange={(e) => handleType(e, setFieldValue)}
                      placeholder="Select type"
                    />
                  </div>
                  <div className="mt-6 w-full">
                    <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                      Category
                    </p>
                    <SelectField
                      items={
                        values.type === "Service"
                          ? ["Logistics", "Manpower", "Geo Tagging"]
                          : [
                              "Safety vests",
                              "Drillers",
                              "Excavators",
                              "Dredgers",
                            ]
                      }
                      disabled={values.type === ""}
                      name="category"
                      value={values.category}
                      onChange={handleChange}
                      placeholder="Select category"
                    />
                  </div>
                </div>
                <div>
                  <MyTextField
                    label="Job title"
                    name="job_title"
                    // value={values.job_title}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <div>
                    <TextArea
                      label="Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    />
                    {errors.description && (
                      <p className=" text-red font-light text-xs">
                        Description is required
                      </p>
                    )}
                  </div>
                  <div className="flex items-center">
                    {values.type === "Service" && (
                      <div className="w-full mr-4">
                        <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                          Duration
                        </p>
                        <div className="flex items-center">
                          <input
                            className="p-[10px] border-none mr-3 w-full font-light text-gray-700 bg-[#1A38601A] rounded z-0 focus:shadow focus:outline-none focus:ring-primary placeholder:text-[#1C345442] placeholder:font-extralight placeholder:text-base"
                            name="duration[0]"
                            placeholder="Input number"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            type="number"
                            onChange={handleChange}
                          />
                          <SelectField
                            items={[
                              "day(s)",
                              "weeks(s)",
                              "month(s)",
                              "year(s)",
                            ]}
                            name="duration[1]"
                            value={values.duration[1] ? values.duration[1] : ""}
                            onChange={handleChange}
                          />
                        </div>
                        {errors.duration && (
                          <p className=" text-red font-light text-xs w-full">
                            Duration is required
                          </p>
                        )}
                      </div>
                    )}

                    {values.type === "Commodity" && (
                      <div className="flex items-center w-full">
                        <div className="w-full mr-4">
                          <Input
                            label="Quantity"
                            name="quantity"
                            value={values.quantity}
                            onChange={handleChange}
                            type="number"
                          />
                          {errors.quantity && (
                            <p className=" text-red font-light text-xs">
                              {errors.quantity}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="flex w-full">
                      <div className="w-full mr-4">
                        <Input
                          label="Offering Price"
                          name="price"
                          value={values.price}
                          isMoneyInput={true}
                          onChange={handleChange}
                          type="number"
                          postfix="/unit"
                        />
                        {errors.price && (
                          <p className=" text-red font-light text-xs">
                            Price is required
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {values.type === "Service" && (
                    <div>
                      <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                        Contract type
                      </p>
                      <SelectField
                        items={["One time", "Revolving"]}
                        name="contract_type"
                        value={values.contract_type}
                        onChange={handleChange}
                        placeholder="Select contract type"
                      />
                    </div>
                  )}
                  <div className="mt-6 w-full mb-11">
                    <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                      Locations
                    </p>
                    <div className="relative w-full">
                      <FieldArray name="location">
                        {(arrayHelpers) => (
                          <div>
                            <Select
                              sx={{
                                width: "100%",
                              }}
                              displayEmpty
                              value={values.location}
                              onChange={(e) =>
                                handleChangee(e, arrayHelpers, values.location)
                              }
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <em>Select Location</em>;
                                }
                                return;
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              {/* <MenuItem disabled value="">
                                <em>Placeholder</em>
                              </MenuItem> */}
                              {countries.map((country) => (
                                <MenuItem
                                  key={country.iso_code}
                                  value={country}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {country.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <div className="flex flex-wrap w-full text-[#6166B6] font-light text-[14px] mt-3">
                              {values.location.map((option, index) => (
                                <div
                                  key={index}
                                  className="bg-vendoroptionbg flex justify-between px-2 mr-5"
                                >
                                  <span className="mr-2">{option.name}</span>
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    x
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </FieldArray>
                      {errors.location && (
                        <p className=" text-red font-light text-xs">
                          {errors.location}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-2 mt-3">
                    <Checkbox
                      name="agree"
                      value={!values.agree}
                      onChange={handleChange}
                    />
                    <p className="font-light text-ts">
                      I agree to the{" "}
                      <span className="text-primary underline">
                        Terms and Conditions.
                      </span>
                    </p>
                  </div>
                  {errors.agree && (
                    <p className=" text-red font-light text-xs">
                      You have to agree to the terms and conditions
                    </p>
                  )}
                </div>
                <div className="flex justify-end">
                  <Button type="submit">Next</Button>
                </div>
                {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default OfferingDetails;
