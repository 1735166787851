/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeUserPassword } from "@slices/onboard-slice";
import { Header } from "@atoms/header";
import { Button } from "@atoms/button/index";
import { Input } from "@atoms/input";
import { EyeIcon } from "@heroicons/react/outline";
import { EyeOffIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PasswordChangedUrl } from "@config/app-url";
import { toast } from "react-toastify";
import { SuccessResponse } from "config/status-code";

const schema = yup.object().shape({
  code: yup.number().required("Enter code sent to your email").typeError("Only numbers are accepted"),
  password: yup
    .string()
    .min(8)
    .max(16)
    .required("Enter a password of a minimum of 8 characters")
    .matches(
      RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/),
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirm_password: yup.string().oneOf([yup.ref("password"), null]),
});

const ChangePassword = () => {
  const [passValue, setPassValue] = useState({
    password: "",
    showPassword: false,
  });

  const [newPassValue, setnewPassValue] = useState({
    password: "",
    showPassword: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /** Get Data from state set in get_miners_verification_data  */
  const { loading, data : user} = useSelector((state) => state.user);

  const handleClickShowPassword = () =>
    setPassValue({ ...passValue, showPassword: !passValue.showPassword });
  
    const handleClickShowNewPassword = () => 
    setnewPassValue({ ...newPassValue, showPassword: !newPassValue.showPassword });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit = (data) => {
    const owner = JSON.parse(localStorage.getItem("data"));
    // console.log(owner.user)
    dispatch(changeUserPassword({
      email: owner?.user?.email,
      otp_code: data.code,
      confirm_password: data.confirm_password,
      password: data.password
    })).then((res) => {
      if (res.payload.status && SuccessResponse(res.payload.status)) {
        navigate(PasswordChangedUrl);
      }
      throw 'error submitting form'
    }).catch((err) => {
      return toast.error(err.message);
    });

  };

  return (
    <div className="font-default">
      <Header />

      <div className=" my-4 bg-white p-8 rounded mt-[113px] lg:mt-0 lg:bg-none">
        <h2 className="font-bold text-2xl text-head  pt-4 ">Change password</h2>

        <p className="text-lightgray pb-4 text-base font-light mt-1 ">
          Your new password must be different from previously used passwords.
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="password_2 block mt-4 relative">
            <label className="block text-sm  mb-1 text-lightgray">
              OTP Code
            </label>
            <div className="eye_div">
              <Input
                name="code"
                type='number'
                {...register("code")}
              />
              {errors.code?.message && (
                <p className="paragraph text-red text-xs mt-1">
                  * {errors.code?.message}
                </p>
              )}

            </div>
          </div>

          <div className="password_2 block mt-4 relative">
            <label className="block text-sm  mb-1 text-lightgray">
              Password
            </label>
            <div className="eye_div">
              <Input
                name="password"
                type={passValue.showPassword ? "text" : "password"}
                {...register("password")}
              />
              {errors.password?.message && (
                <p className="paragraph text-red text-xs mt-1">
                  * {errors.password?.message}
                </p>
              )}
              <div
                className="icon_button absolute right-4 top-8"
                onClick={handleClickShowPassword}
              >
                {passValue.showPassword ? (
                  <EyeIcon className="h-5 font-extralight cursor-pointer" />
                ) : (
                  <EyeOffIcon className="h-5 font-extralight cursor-pointer" />
                )}
              </div>
            </div>
          </div>

          <div className="password_2 block mt-5 relative">
            <label className="block text-sm  mb-1 text-lightgray">
              Confirm Password
            </label>
            <div className="eye_div">
              <Input
                name="confirm_password"
                type={newPassValue.showPassword ? "text" : "password"}
                {...register("confirm_password")}
              />
              {errors.confirm_password?.message && (
                <p className="paragraph text-red text-xs mt-1">
                  * {errors.confirm_password?.message}
                </p>
              )}
              <div
                className="icon_button absolute right-4 top-8"
                onClick={handleClickShowNewPassword}
              >
                {newPassValue.showPassword ? (
                  <EyeIcon className="h-5 font-extralight cursor-pointer" />
                ) : (
                  <EyeOffIcon className="h-5 font-extralight cursor-pointer" />
                )}
              </div>
            </div>
          </div>

          <Button disabled={loading} type="submit">
            Change Password
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
