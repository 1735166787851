import React from 'react'

const Megaphone = () => {
  return (
    <div><svg
    width="41"
    height="42"
    viewBox="0 0 41 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.3124 16.5583L32.4582 15.875V9.04166C32.4582 8.01666 31.7749 7.33333 30.7499 7.33333C29.7249 7.33333 29.0416 8.01666 29.0416 9.04166V9.89583C25.6249 11.4333 20.4999 14.1667 15.3749 14.1667H11.1041C8.71242 14.1667 6.83325 16.2167 6.83325 18.4375C6.83325 19.975 7.68742 21.3417 8.88325 22.025L10.7624 32.1042C10.7624 32.6167 11.2749 32.9583 11.9583 32.9583C12.1291 32.9583 12.1291 32.9583 12.2999 32.9583L18.4499 31.7625C19.1333 31.5917 19.4749 31.0792 19.3041 30.5667C18.7916 29.5417 17.9374 28.0042 17.2541 27.4917C16.9124 27.3208 16.3999 25.9542 16.0583 24.4167H17.0833V22.8792C21.6958 23.3917 27.3332 25.6125 29.0416 26.9792V27.8333C29.0416 28.8583 29.7249 29.5417 30.7499 29.5417C31.7749 29.5417 32.4582 28.8583 32.4582 27.8333V21L33.1416 20.4875C33.8249 19.975 34.1666 19.2917 34.1666 18.6083V18.2667C34.1666 17.5833 33.8249 17.0708 33.3124 16.5583V16.5583ZM10.2499 15.875H15.3749V17.5833H10.2499V15.875ZM16.3999 28.8583C16.5708 28.8583 16.9124 29.3708 17.2541 30.0542L12.4708 31.25L10.7624 22.7083H14.0083C14.3499 24.9292 15.0333 28.175 16.3999 28.8583V28.8583ZM29.0416 24.9292C26.3082 23.5625 21.5249 21.5125 17.0833 21V15.875C21.5249 15.3625 26.3082 13.4833 29.0416 11.9458V24.9292Z"
      fill="#6166B6"
    />
  </svg></div>
  )
}

export default Megaphone
