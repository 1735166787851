/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import Button from "components/ui/Button";
import AddIcon from "components/ui/icons/AddIcon";
import SearchIcon from "components/ui/icons/SearchIcon";
import SlidersIcon from "components/ui/icons/SlidersIcon";
import React,{useEffect, useState} from "react";
import { useAuth } from "@hooks";
import { Search, Card } from "@ui";
import { useDispatch, useSelector } from "react-redux";
import { getInitials } from "@shared/utility";
import {
  getMembers,
  searchMembers,
} from "@slices/roles-permissions-slice";
import { DropDownIcon } from "@ui/icons";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DeleteMemberModal } from "./DeleteMemberModal";

const OfftakerMembers = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const navigate = useNavigate();
  const profiles = useAuth();
  const members = useSelector((state) => state.roles_permissions);
  const { loading } = useSelector((state) => state.roles_permissions);
  const [limit, setLimit] = useState(10);
  const [searchParams, setParams] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getMembers({
        id: profiles?.account?._id,
        page: 1,
        limit: limit,
      })
    );
  }, [dispatch]);

  const deleteMember = async (data) => {
    setData(data);
    setOpen(true);
  };

  function handleChangePage(page) {
    dispatch(
      getMembers({
        id: profiles?.account?._id,
        page: page,
        limit: limit,
      })
    );
  }

  const handleSetPerPage = (e) => {
    setLimit(e.target.value);
    dispatch(
      getMembers({
        id: profiles?.account?._id,
        page: 1,
        limit: e.target.value,
      })
    );
  };

  const handleSearch = (e) => {
    if (e.target.value == "") {
      dispatch(
        getMembers({
          id: profiles?.account?._id,
          page: 1,
          limit: limit,
        })
      );
    }

    setParams(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    dispatch(
      searchMembers({
        search: searchParams,
        id: profiles?.account?._id,
      })
    );
  };

  return (
    <>
      <DeleteMemberModal open={open} setOpen={setOpen} data={data} />
      <div className="overflow-x-auto scrollbar-hide">
        <div className=" p-6 rounded-sm min-w-[900px] lg:w-full overflow-x-hidden">
          <div className="flex justify-between">
            <div className="flex-7 flex">
              <form
                onSubmit={(e) => handleSearchSubmit(e)}
                className="flex gap-2"
              >
                <div className="w-[250px] lg:w-[538px]">
                  <Search
                    placeholder="Search team member"
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
                <Button type="submit" color="primary">
                  <SearchIcon color="#ffff" opacity="1" />
                </Button>
              </form>
              <div className="flex items-center bg-white ml-6">
                {/* <Button text="filter" endIcon={<SlidersIcon />} /> */}
                <Button
                  variant="text"
                  color="neutral"
                  fontWeight="font-light"
                  endIcon={<SlidersIcon />}
                >
                  Filter
                </Button>
              </div>
            </div>
            <div className="flex-2"></div>
            <div className="flex-1">
              <Button
                //           onClick={() => navigate("vendor/vendor-offerings/add")}
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => navigate("add")}
              >
                Add member
              </Button>
            </div>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-6 mt-6">
            {members?.members?.map((data, index) => (
              <Card
                key={index}
                className="flex-grow-0 flex-shrink-0 mt-6"
              >
                <div className="p-5 rounded-sm">
                  <div className="flex justify-between items-start mb-6">
                  <div className="flex">
                    {data?.accounts[0]?.profile_img?.length > 0 ? (
                      <img
                        className="rounded-circle w-[60px] h-[60px] mr-3"
                        src={`${data?.accounts[0]?.profile_img[0]?.url}`}
                        alt=""
                      />
                    ) : (
                      <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill mr-3">
                        {getInitials(data?.name)}
                      </div>
                    )}
                    <div>
                      <h4 className="text-[#192434] text-[18px]">
                        {data?.name}
                      </h4>

                      {data?.roles?.map((x,i) => (
                        <h4 className="text-vendor font-light" key={i}>{x?.name}</h4>
                      ))}
                    </div>
                  </div>
                    {data?.status == "Active" ? (
                      <div className="text-[#00A344] bg-activeBg rounded-sm flex items-center justify-center p-2 ">
                        <h4>{data?.status}</h4>
                      </div>
                    ) : (
                      <div className="text-[#F52419] bg-dangerBg rounded-sm flex items-center justify-center p-2 ">
                        <h4>{data?.status}</h4>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-between">
                    <button
                      className="text-primary bg-back px-4 py-2 rounded-md w-[110px]"
                      onClick={() => {
                        deleteMember(data);
                      }}
                    >
                      Remove
                    </button>
                    <Button
                      style={{ width: "110px" }}
                      onClick={() => navigate("edit" + `/${data._id}`)}
                    >
                      Edit
                    </Button>
                  </div>
                </div>
              </Card>
            ))}
          </div>
          {loading ? (
            <div>Loading...</div>
          ) : !members?.members?.length > 0 ? (
            <div className="bg-white flex flex-col items-center justify-center h-96">
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M45 16.25C45 18.32 38.285 20 30 20C21.715 20 15 18.32 15 16.25C15 14.18 21.715 12.5 30 12.5C38.285 12.5 45 14.18 45 16.25Z"
                  fill="#1C3454"
                  fillOpacity="0.26"
                />
                <path
                  d="M30 22.5C21.75 22.5 15 20.75 15 18.75V26.25C15 28.25 21.75 30 30 30C38.25 30 45 28.25 45 26.25V18.75C45 20.75 38.25 22.5 30 22.5Z"
                  fill="#1C3454"
                  fillOpacity="0.26"
                />
                <path
                  d="M30 32.5C21.75 32.5 15 30.75 15 28.75V36.25C15 38.25 21.75 40 30 40C38.25 40 45 38.25 45 36.25V28.75C45 30.75 38.25 32.5 30 32.5Z"
                  fill="#1C3454"
                  fillOpacity="0.26"
                />
                <path
                  d="M30 42.5C21.75 42.5 15 40.75 15 38.75V46.25C15 48.25 21.75 50 30 50C38.25 50 45 48.25 45 46.25V38.75C45 40.75 38.25 42.5 30 42.5Z"
                  fill="#1C3454"
                  fillOpacity="0.26"
                />
              </svg>

              <p className="text-heading text-lg mb-2 flex">
                {" "}
                No data to display
              </p>
              <p className="text-light font-extralight">
                Add a new member to get started
              </p>
            </div>
          ) : (
            <div className="flex justify-start lg:justify-end items-center w-full py-4 px-8 text-[13px] uppercase text-[#18273AF0]">
              <div className="flex justify-between gap-2 items-center">
                <p>Rows per page: {members?.pagination?.limit}</p>
                <FormControl sx={{ height: "30px", border: 0 }}>
                  <Select
                    sx={{ height: "30px", border: 0 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => handleSetPerPage(e)}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="mx-6">
                1 -{" "}
                {Math.ceil(
                  members?.pagination?.total / members?.pagination?.limit
                )}{" "}
                of {members?.pagination?.page}
              </div>
              <div className="flex justify-between gap-10">
                {members?.pagination?.prevPage && (
                  <div
                    className="rotate-90 cursor-pointer"
                    onClick={() =>
                      handleChangePage(members?.pagination?.prevPage)
                    }
                  >
                    <DropDownIcon />
                  </div>
                )}
                {members?.pagination?.page >=
                Math.ceil(
                  members?.pagination?.total / members?.pagination?.limit
                )
                  ? null
                  : members?.pagination?.page + 1 && (
                      <div
                        className="-rotate-90 cursor-pointer"
                        onClick={() =>
                          handleChangePage(members?.pagination?.nextPage)
                        }
                      >
                        <DropDownIcon />
                      </div>
                    )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OfftakerMembers;
