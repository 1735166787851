const DownloadIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 18H20V20H4V18Z" fill="white" />
      <path d="M12 17L17 12H14V4H10V12H7L12 17Z" fill="white" />
    </svg>
  );
};

export default DownloadIcon;
