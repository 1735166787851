/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddCourierUrl, EditCarrierUrl } from "config/app-url";

import { Button, Search } from "@ui";
import {
  AddIcon,
  Sort,
  SearchIcon,
  EditIcon,
  CaretDown,
  DropDownIcon,
  TrashIcon,
} from "@ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { curierHeader } from "@constants";
import { getCarrier } from "@slices/miners/interaction-slice";
import { toast } from "react-toastify";
import { DeleteCarrierModal } from "./deleteCarrier";
import { id } from "date-fns/locale";

const Carrier = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [carrier_id, setCarrierID] = useState();
  const courierData = useSelector((state) => state.shipment);
  const { loading } = useSelector((state) => state.shipment);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCarrier(), (error) => {
      toast.error(error);
    });
  }, [dispatch]);

  const deleteCarrier = (id) => {
    setCarrierID(id);
    setOpen(true);
  };

  return (
    <>
      <DeleteCarrierModal open={open} setOpen={setOpen} id={carrier_id} />
      <div>
        <div className="w-[183px] my-[34.5px]">
          <Button
            onClick={() => navigate(AddCourierUrl)}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add carrier
          </Button>
        </div>
        {/* tabs
    <Tabs /> */}
        {/* table */}
        <div className="flex flex-col">
          <div className="flex lg:flex flex-rols-1 gap-3">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden border border-dark-gray-100 sm:rounded-lg bg-white">
                {/* input field goes here */}
                <div className="flex justify-between p-4">
                  <div className="flex gap-2">
                    <div className="w-[548px]">
                      <Search placeholder="Search courier" />
                    </div>
                    <div className="bg-primary flex items-center justify-center rounded py-1 px-2">
                      <SearchIcon color="#ffff" opacity="1" />
                    </div>
                  </div>
                  <div className="flex items-center">
                    {/* <Button text="filter" endIcon={<SlidersIcon />} /> */}
                    {/* <Button
      variant="text"
      color="neutral"
      fontWeight="font-light"
      endIcon={<SlidersIcon />}
    >
      Filter
    </Button> */}
                  </div>
                </div>
                <table className="min-w-full divide-y divide-[#1A38601A] border-b border-[#1A38601A]">
                  <thead className="bg-[#EFF0FE]">
                    <tr>
                      {curierHeader.map((header, index) => (
                        <th
                          key={index + 1}
                          scope="col"
                          className="px-6 py-3 text-left text-sm font-normal text-primary uppercase tracking-wider"
                        >
                          {!header.hasIcon && header.title}
                          {header.hasIcon && (
                            <div className="flex  items-center">
                              <span>{header.title}</span>
                              <Sort />
                            </div>
                          )}
                        </th>
                      ))}
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-normal text-primary uppercase tracking-wider"
                      >
                        {/* <span>Edit</span> */}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-normal text-primary uppercase tracking-wider"
                      >
                        {/* <span>Delete</span> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white font-light divide-y divide-[#1A38601A]">
                    {courierData?.data.length > 0 &&
                      courierData?.data.map((carrier, index) => (
                        <tr className="text-[#18273AF0]">
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {carrier.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {carrier.web_url}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {carrier.service_from_country}
                          </td>

                          {/* <td className="px-6 py-4 whitespace-nowrap">
                  <span
                    className={`py-1 px-5 inline-flex capitalize text-sm leading-5 font-light rounded ${
                      inventory.status === "active"
                        ? "bg-[#D9F2E4] text-[#43BE77]"
                        : "bg-[#FDF2E7] text-[#F2994A]"
                    } `}
                  >
                    {inventory.status}
                  </span>
                </td> */}
                          <td>
                            <div
                              onClick={() =>
                                navigate(EditCarrierUrl, {
                                  state: { id: carrier._id },
                                })
                              }
                            >
                              <EditIcon />
                            </div>
                          </td>
                          <td className="px-3 py-3">
                            <div onClick={() => deleteCarrier(carrier._id)}>
                              <TrashIcon />
                            </div>

                            {/* <EclipseDotsIcon /> */}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {loading ? (
                  <div>Loading...</div>
                ) : !courierData.data.length > 0 ? (
                  <div className="flex flex-col items-center justify-center h-96">
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M45 16.25C45 18.32 38.285 20 30 20C21.715 20 15 18.32 15 16.25C15 14.18 21.715 12.5 30 12.5C38.285 12.5 45 14.18 45 16.25Z"
                        fill="#1C3454"
                        fillOpacity="0.26"
                      />
                      <path
                        d="M30 22.5C21.75 22.5 15 20.75 15 18.75V26.25C15 28.25 21.75 30 30 30C38.25 30 45 28.25 45 26.25V18.75C45 20.75 38.25 22.5 30 22.5Z"
                        fill="#1C3454"
                        fillOpacity="0.26"
                      />
                      <path
                        d="M30 32.5C21.75 32.5 15 30.75 15 28.75V36.25C15 38.25 21.75 40 30 40C38.25 40 45 38.25 45 36.25V28.75C45 30.75 38.25 32.5 30 32.5Z"
                        fill="#1C3454"
                        fillOpacity="0.26"
                      />
                      <path
                        d="M30 42.5C21.75 42.5 15 40.75 15 38.75V46.25C15 48.25 21.75 50 30 50C38.25 50 45 48.25 45 46.25V38.75C45 40.75 38.25 42.5 30 42.5Z"
                        fill="#1C3454"
                        fillOpacity="0.26"
                      />
                    </svg>

                    <p className="text-heading text-lg mb-2 flex">
                      {" "}
                      No data to display
                    </p>
                    <p className="text-light font-extralight">
                      Add a new carrier to get started
                    </p>
                  </div>
                ) : (
                  <div className="flex justify-end w-full py-4 px-8 text-[13px] uppercase text-[#18273AF0]">
                    <div className="flex items-center justify-between gap-2">
                      <p>Rows per page: 10</p>
                      <div>
                        <CaretDown />
                      </div>
                    </div>
                    <div className="mx-6">1 -3 of 3</div>
                    <div className="flex justify-between gap-10">
                      <div className="rotate-90">
                        <DropDownIcon />
                      </div>
                      <div className="-rotate-90">
                        <DropDownIcon />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Carrier;
