/* eslint-disable no-unused-vars */
import React ,{ useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ControlledModal,AdminMinesTable } from "@ui";
import { AddIcon } from "@ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminMinerals, fetchPaginatedAdminMinerals, deleteAdminMineral, restoreAdminMineral } from "store/slices/admin-slice";
import { adminMineralInfo } from "constants/index";
import { checkPermission } from "shared/utility";
import { toast } from "react-toastify";



const Minerals = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [openrestore, setOpenrestore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [mineralID, setMineralID] = useState();
    const [mineralID_restore, setMineralID_restore] = useState();
    const [filterParams, setFilter] = useState("");
    const [searchParams, setParams] = useState();

    useEffect(() => {
        dispatch(fetchAdminMinerals());
      }, [dispatch]);
    const { loading, minerals, pagination, search } = useSelector(
        (state) => state.admin
      );
      console.log("minerals",minerals);
    
      const handleClose = () => setOpen(false);

      const handleCloseRestore = () => setOpenrestore(false);
    
      const handleTabChange = (e, tab) => {
        e.preventDefault();
        setFilter(tab);
        if (tab == "All") {
          dispatch(fetchAdminMinerals());
        } else {
          dispatch(
            fetchPaginatedAdminMinerals({
              page: 1,
              per_page: pagination.limit,
              search: "",
              filter: tab,
            })
          );
        }
      };
    
      const handleSearch = (e) => {
        if (!e.target.value) {
          dispatch(fetchAdminMinerals());
        }
        setParams(e.target.value);
      };

      const handleChangePage = (option) => {
        dispatch(
            fetchPaginatedAdminMinerals({
            page: pagination[option + "Page"],
            per_page: pagination.limit,
            search: search,
            filter: "",
          })
        );
      };

      const handleSearchSubmit = (e) => {
        e.preventDefault();
        // if (!search) {
        //   dispatch(fetchAdminMinerals());
        // }
        dispatch(
            fetchPaginatedAdminMinerals({
            page: 1,
            per_page: pagination.limit,
            search: searchParams,
            filter: filterParams,
          })
        );
      };
    
      const handleSetPerPage = (e) => {
        dispatch(
            fetchPaginatedAdminMinerals({
            page: 1,
            per_page: e.target.value,
            search: search,
            filter: "",
          })
        );
      };
    const restoreMineral = async () => {
      setIsLoading(true);
      try {
        const response = await dispatch(restoreAdminMineral(mineralID_restore));
       
        if (response.payload.status === 200 || response.payload.status === 201) {
          if(response?.payload?.data?.code == 422) {
            toast.error(response.payload.data.message);
            setIsLoading(false);
          }else {
            toast.success(response.payload.data.message);
            dispatch(fetchAdminMinerals());
            setOpenrestore(false);
            setIsLoading(false);
          }
      } else {
        setIsLoading(false);
        console.log("error from then", response);
      }
    } catch (error) {
      setIsLoading(false);
       console.log("error", error);
    }
    }

    const deleteMineral = async () => {
      setIsLoading(true);
      try {
        const response = await dispatch(deleteAdminMineral(mineralID));
       
        if (response.payload.status === 200 || response.payload.status === 201) {
          if(response?.payload?.data?.code == 422) {
            toast.error(response.payload.data.message);
            setIsLoading(false);
          }else {
            toast.success(response.payload.data.message);
            dispatch(fetchAdminMinerals());
            setOpen(false);
            setIsLoading(false);
          }
      } else {
        console.log("error from then", response);
      }
    } catch (error) {
       console.log("error", error);
    }
  }

      const actions = {
        toggle: {
          fields: ["Edit Mineral"],
          action: (option, id, status) => {
            if (option === "Edit Mineral") {
              navigate("../admin/edit-mineral", {
                state: { id: id, data: status },
              });
            }
          },
        },
        delete: (sub) => {
          setMineralID(sub); 
          setOpen(true);
        },
        restore: (sub) => {
          setOpenrestore(true);
          setMineralID_restore(sub);
        }
      };

return (
    <>
    <ControlledModal
        isOpen={open}
        handleClose={handleClose}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
    >
        <p className="text-head text-center font-semibold text-[22px]">
            Warning !
        </p>
        <p className="text-lightgray text-center font-light text-base mt-2">
            Are you sure you want to remove this mineral ?
        </p>
        <div className="flex flex-row justify-center gap-4 mt-6">
            <Button
                type="button"
                variant="text"
                color="neutral"
                onClick={() => setOpen(!open)}
            >
                Cancel
            </Button>
            <Button
                type="button"
                disabled={checkPermission("Mineral")}
                variant="contained"
                color="danger"
                onClick={() => {
                    deleteMineral();
                } }
            >
                {isLoading ? "Processing..." : "Yes"}
            </Button>
        </div>
    </ControlledModal>
    <ControlledModal
        isOpen={openrestore}
        handleClose={handleCloseRestore}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
    >
        <p className="text-head text-center font-semibold text-[22px]">
           Restore
        </p>
        <p className="text-lightgray text-center font-light text-base mt-2">
            Are you sure you want to restore this mineral ?
        </p>
        <div className="flex flex-row justify-center gap-4 mt-6">
            <Button
                type="button"
                variant="text"
                color="neutral"
                onClick={() => setOpenrestore(!openrestore)}
            >
                Cancel
            </Button>
            <Button
                type="button"
                variant="contained"
                color="danger"
                disabled={checkPermission("Mineral")}
                onClick={() => {
                    restoreMineral();
                } }
            >
                {isLoading ? "Processing..." : "Yes"}
            </Button>
        </div>
    </ControlledModal>
    <div className="mt-2">
            <div className="w-40">
                <Button
                    variant="contained"
                    onClick={() => navigate("../admin/add-mineral")}
                    disabled={checkPermission("Mineral")}
                    color="primary"
                    startIcon={<AddIcon />}
                    fullWidth
                >
                    Add new minerals
                </Button>
            </div>
            <div className="mt-4 flex overflow-x-auto scrollbar-hide gap-3">
                <div className="min-w-[1024px] lg:w-full overflow-x-hidden ">
                    <AdminMinesTable
                        tableInfo={adminMineralInfo}
                        handleTabChange={handleTabChange}
                        handleSetPerPage={handleSetPerPage}
                        handleChangePage={handleChangePage}
                        handleSearch={handleSearch}
                        handleSearchSubmit={handleSearchSubmit}
                        data={minerals}
                        pagination={pagination}
                        loading={loading}
                        isDelete={true}
                        isRestore={true}
                        isToggle={true}
                        actions={actions} />
                </div>
            </div>
        </div></>
)

};

export default Minerals;