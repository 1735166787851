/* eslint-disable react/prop-types */
import { Gavel, MoneyIcon } from '@ui/icons';
import DiplomaScroll from '@ui/icons/DiplomaScroll';
import PiggyBankIcon from '@ui/icons/PiggyBankIcon';
import HandIcon from '@ui/icons/HandIcon';
import HandshakeIcon from '@ui/icons/HandshakeIcon';
import PointerIcon from '@ui/icons/Pointer';

export const Stat = ({ bgColor, children }) => {
	const backgroundColor = bgColor ? bgColor : 'bg-white';
	const boxShadow = bgColor && 'shadow-Stat';
	return (
		<div
			className={`flex items-center justify-between card border-solid px-6 py-5 rounded-lg ${backgroundColor} ${boxShadow}`}
		>
			{children}
		</div>
	);
};

const InterationsStats = ({ transactions, type, counts, won = false }) => {
	if (transactions) {
		return (
			<>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
							TRANSACTIONS TODAY
						</p>
						<p className="text-2xl text-[#374146]">
							{counts?.today_transactions}
						</p>
					</div>
					<div>
						<MoneyIcon h="41" w="41" color="#6166B6" opacity="1" />
					</div>
				</Stat>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
							ALL TIME TRANSACTIONS
						</p>
						<p className="text-2xl text-[#374146]">
							{counts?.total_transactions}
						</p>
					</div>
					<div>
						<PiggyBankIcon h="41" w="41" color="#6166B6" opacity="1" />
					</div>
				</Stat>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
							COMPLETED TRANSACTIONS
						</p>
						<p className="text-2xl text-[#374146]">
							{counts?.completed_transactions}
						</p>
					</div>
					<div>
						<MoneyIcon />
					</div>
				</Stat>
			</>
		);
	} else if (type === 'investments') {
		return (
			<>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
							INVESTMENTS
						</p>
						<p className="text-2xl text-[#374146]">{counts?.investments}</p>
					</div>
					<div>
						<MoneyIcon h="41" w="41" color="#6166B6" opacity="1" />
					</div>
				</Stat>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
							INTERESTS TODAY
						</p>
						<p className="text-2xl text-[#374146]">{counts?.interests_today}</p>
					</div>
					<div>
						<PiggyBankIcon h="41" w="41" color="#6166B6" opacity="1" />
					</div>
				</Stat>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
							COMPLETED PROPOSALS
						</p>
						<p className="text-2xl text-[#374146]">{counts?.active_proposals}</p>
					</div>
					<div>
						<MoneyIcon />
					</div>
				</Stat>
			</>
		);
	} else if (type === 'miner-investment') {
		return (
			<>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
						INVESTMENTS
						</p>
						<p className="text-2xl text-[#374146]">{counts?.investments}</p>
					</div>
					<div>
						<HandshakeIcon h="41" w="41" color="#6166B6" opacity="1" />
					</div>
				</Stat>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
						INTERESTS TODAY
						</p>
						<p className="text-2xl text-[#374146]">{counts?.interests_today}</p>
					</div>
					<div>
						<HandshakeIcon h="41" w="41" color="#6166B6" opacity="1" />
					</div>
				</Stat>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
						ACTIVE PROPOSALS
						</p>
						<p className="text-2xl text-[#374146]">{counts?.active_proposals}</p>
					</div>
					<div>
						<PointerIcon />
					</div>
				</Stat>
			</>
		);
	} else if (type === 'prospects') {
		return (
			<>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
							ALL PROSPECTS
						</p>
						<p className="text-2xl text-[#374146]">
							{counts?.prospects_today}
						</p>
					</div>
					<div>
						<HandIcon width="41" height="41" fill="#6166B6" opacity="1" />
					</div>
				</Stat>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
							ACCEPTED PROSPECTS
						</p>
						<p className="text-2xl text-[#374146]">
							{counts?.accepted_prospects}
						</p>
					</div>
					<div>
						<HandIcon width="41" height="41" fill="#6166B6" opacity="1" />
					</div>
				</Stat>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
							INTERESTS TODAY
						</p>
						<p className="text-2xl text-[#374146]">{counts?.interest_today}</p>
					</div>
					<div>
						<HandIcon width="41" height="41" fill="#6166B6" opacity="1" />
					</div>
				</Stat>
			</>
		);
	} else {
		return (
			<>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
							Bids Today
						</p>
						<p className="text-2xl text-[#374146]">{counts?.today_bids}</p>
					</div>
					<div>
						<Gavel h="41" w="41" color="#6166B6" opacity="1" />
					</div>
				</Stat>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
							All Time Bids
						</p>
						<p className="text-2xl text-[#374146]">{counts?.total_bids}</p>
					</div>
					<div>
						<Gavel h="41" w="41" color="#6166B6" opacity="1" />
					</div>
				</Stat>
				<Stat>
					<div>
						<p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
							Bids {won ? ' Won' : ' Awarded'}
						</p>
						<p className="text-2xl text-[#374146]">{counts?.awarded_bids}</p>
					</div>
					<div>
						<DiplomaScroll />
					</div>
				</Stat>
			</>
		);
	}
};

export default InterationsStats;
