import React from "react";
import PropTypes from "prop-types";

const Backdrop = ({ close }) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-[100vh] z-10 bg-black opacity-70"
      onClick={close}
    ></div>
  );
};

Backdrop.propTypes = {
  close: PropTypes.func.isRequired,
};
export default Backdrop;
