/* eslint-disable */
/** @format */

import React, { useState, useEffect } from "react";
import { Header } from "@atoms/header";
import { Button } from "@atoms/button/index";
import { Input } from "@atoms/input";
import { Link } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";
import { EyeOffIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { signInUser } from "@slices/user-slice";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@hooks";
import { SelectCategoryUrl, SignUpUrl } from "@config/app-url";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("invalid email")
    .max(255)
    .required("email is required"),
  password: yup
    .string()
    .min(8)
    .required("enter a password of a minimum of 8 characters"),
  rememberMe: yup.bool(),
});

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setUser, setAccount } = useAuth();

  const [passValue, setPassValue] = useState({
    password: "",
    showPassword: false,
  });

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleClickShowPassword = () =>
    setPassValue({ ...passValue, showPassword: !passValue.showPassword });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const { loading } = useSelector((state) => state.user);

  const onSubmit = (data) => {
    dispatch(signInUser(data))
      .then((response) => {
        if(response?.payload?.data?.code === 400) {
          toast.error(response.payload.data.message);
        }else {
          const { account, user } = response.payload.data;
          setUser(user);
          setAccount(account);
          if (
            response.payload.data.account.length < 1 ||
            response.payload.data.account === null
          ) {
            return navigate(SelectCategoryUrl);
          }
          /* Check for sub-team member */
          const profileDetails = account.find((profile) => profile);
          if (profileDetails.type == "user" || profileDetails.primary == false) {
            return navigate(`/${profileDetails.parent_id.role.slug}/dashboard`);
          }
          /** Check for primary role */
          const primaryRole = account.find(
            (profile) => profile.primary === true
          );
          if (!primaryRole) {
            throw new Error("Unable to determine role");
          }
          /** check for redirect slug of a role */
          const redirectRoleSlug = primaryRole.role.slug;
          if (!redirectRoleSlug) {
            throw new Error("Unable to redirect url");
          }
          /** redirect user to the primary role slug */
          return navigate(`/${redirectRoleSlug}/dashboard`);

        }
       
      })
      .catch((error) => {
        console.log(error);
        let itemsToClear = ["account", "notification_token", "user"];
        itemsToClear.forEach((element) => {
          localStorage.removeItem(element);
        });
      });
  };

  return (
    <div className="font-default">
      <Header />

      <div className=" my-4 bg-white p-8 rounded mt-[113px] lg:mt-0 lg:bg-none">
        <h2 className="font-bold text-2xl text-head  pt-4 ">Sign In</h2>

        <p className="text-lightgray pb-4 text-base font-light mt-1 ">
          Welcome back
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-4">
            <label className="block text-sm  mb-2 text-lightgray">
              Business Email Address
            </label>
            <Input type="text" name="email" {...register("email")} />
            {errors.email?.message && (
              <p className="paragraph text-red text-xs mt-1">
                * {errors.email?.message}
              </p>
            )}
          </div>

          <div className="password_2 block mt-4 relative">
            <label className="block text-sm  mb-1 text-lightgray">
              Password
            </label>
            <div className="eye_div">
              <Input
                name="password"
                type={passValue.showPassword ? "text" : "password"}
                {...register("password")}
              />
              {errors.password?.message && (
                <p className="paragraph text-red text-xs mt-1">
                  * {errors.password?.message}
                </p>
              )}
              <div
                className="icon_button absolute right-4 top-8"
                onClick={handleClickShowPassword}
              >
                {passValue.showPassword ? (
                  <EyeIcon className="h-5 font-extralight cursor-pointer" />
                ) : (
                  <EyeOffIcon className="h-5 font-extralight cursor-pointer" />
                )}
              </div>
            </div>
          </div>

          <div className="text-checkbox flex items-center justify-between flex-row text-sm mt-4 mb-8">
            <div className="text-checkbox flex flex-row text-sm">
              <input
                className="appearance-none checked:bg-primary checked:hover:bg-primary focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary rounded border-none h-5 w-5 mr-3 bg-check cursor-pointer accent-primary bg-primary"
                type="checkbox"
                id="rememberMe"
                name="rememberMe"
                {...register("rememberMe")}
              />
              <label className="text-checkbox" htmlFor="rememberMe">
                Remember me
              </label>
            </div>

            <Link
              className="inline-block align-baseline text-sm text-primary hover:text-gray"
              to="/forgot-password"
            >
              Forgot Password?
            </Link>
          </div>

          <Button disabled={!isDirty || !isValid} type="submit">
            {loading ? "Loading..." : "Sign In"}
          </Button>

          <p className="text-lightgray flex justify-center text-xs xl:text-base  align-center py-2 cursor-default my-4">
            Don't have an account?{" "}
            <Link className="text-primary pl-2" to={SignUpUrl}>
              Create an account
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
