import React from "react";

const VerifiedIcon = () => {
  return (
    <svg
      width="137"
      height="132"
      viewBox="0 0 137 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="68.5" cy="65.5833" rx="68.5" ry="65.5833" fill="#43BE77" />
      <path
        d="M64.5 97.968L29 72.4528L37.5 60.6765L61.5 77.8502L94.5 36.1426L106 44.9748L64.5 97.968Z"
        fill="white"
      />
    </svg>
  );
};

export default VerifiedIcon;
