import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as API from "@config/url";
import { toast } from "react-toastify";

const file_uploader = createAsyncThunk("file/file_uploader", async (formData, { rejectWithValue }) => {
    try {
        return await axios({
            method: "post",
            url: API.postUploadFile,
            headers: {
                "Content-Type": "multipart/form-data",
                "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
            data: formData,
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

/** create slice */
const slice = createSlice({
    name: "file",
    initialState: {
        data: [],
        status: false,
        loading: false,
        error: null,
        message: null,
    },
    reducers: {},
    extraReducers: {
        // verify otp code
        [file_uploader.pending]: (state) => {
            state.loading = true;
        },
        [file_uploader.fulfilled]: (state, { payload }) => {
            // state.data = payload.data.files
            state.status = payload.status;
            toast.success(payload.data.message);
            state.loading = false;
        },
        [file_uploader.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
    },
});

export { file_uploader }
export default slice.reducer;