/* eslint-disable no-unused-vars */
import React,{ useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getInvestments,
  fetchPaginatedAdminInvestments,
  postArchiveInvestMent
} from "@slices/admin-slice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, ControlledModal,TenderTabs } from "@ui";
import { adminInteractionInvestmentTable } from "constants/index";

const Investments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [mineID, setMineID] = useState();
  const [filterParams, setFilter] = useState("");
  const [searchParams, setParams] = useState();
  const { loading, investments, pagination, search } = useSelector(
    (state) => state.admin
  );

  const handleClose = () => setOpen(false);

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    setFilter(tab);
    if (tab == "All") {
      dispatch(getInvestments());
    } else {
      dispatch(
        fetchPaginatedAdminInvestments({
          page: 1,
          per_page: pagination.limit,
          search: "",
          filter: tab.toLowerCase(),
        })
      );
    }
  };

  const archiveInvestMent = async () => {
    setIsLoading(true);
    let body = {
      status: "Archived",
      content: "transaction archived",
    };
    try {
      const response = await dispatch(
        postArchiveInvestMent({ id: mineID, body: body })
      );
      if (response.payload.status === 200 || response.payload.status == 201) {
        if (
          response?.payload?.data?.code == 422 ||
          response.payload.data.code == 400
        ) {
          setIsLoading(false);
          toast.error(response.payload.data.message);
        } else {
          toast.success(response?.payload?.data?.message);
          setOpen(false);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        console.log("error from then", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };


  const handleSearch = (e) => {
    if (!e.target.value) {
      dispatch(getInvestments());
    }
    setParams(e.target.value);
  };

  const actions = {
    toggle: {
      fields: ["View Details", "Archive"],
      action: (option, id) => {
      
        if (option === "View Details") {
          navigate("../../investments/details", {
            state: { id: id },
          });
        } else {
          setOpen(true);
          setMineID(id);
        }
      },
    },
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(getInvestments());
    }
    dispatch(
      fetchPaginatedAdminInvestments({
        page: 1,
        per_page: pagination.limit,
        search: searchParams,
        filter: filterParams,
      })
    );
  };

  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedAdminInvestments({
        page: pagination[option],
        per_page: pagination.limit,
        search: search,
        filter: "",
      })
    );
  };

  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedAdminInvestments({
        page: 1,
        per_page: e.target.value,
        search: search,
        filter: "",
      })
    );
  };

  useEffect(() => {
    dispatch(getInvestments());
  }, [dispatch]);

  return (
    <>
      <ControlledModal
        isOpen={open}
        handleClose={handleClose}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
      >
        <p className="text-head text-center font-semibold text-[22px]">
          Archive Investment?
        </p>
        <p className="text-lightgray text-center font-light text-base mt-2">
          Are you sure you want to archive this investment?
        </p>
        <div className="flex flex-row justify-center gap-4 mt-6">
          <Button
            type="button"
            variant="text"
            color="neutral"
            onClick={() => setOpen(!open)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="contained"
            color="danger"
            onClick={() => {
              archiveInvestMent();
            }}
          >
            {isLoading ? "Processing..." : "Yes, archive"}
          </Button>
        </div>
      </ControlledModal>
      <TenderTabs
        tableInfo={adminInteractionInvestmentTable}
        handleTabChange={handleTabChange}
        handleSetPerPage={handleSetPerPage}
        handleChangePage={handleChangePage}
        handleSearch={handleSearch}
        handleSearchSubmit={handleSearchSubmit}
        data={investments}
        pagination={pagination}
        loading={loading}
        isToggle={true}
        actions={actions}
      />
    </>
  );
};

export default Investments;