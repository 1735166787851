/** @format */

import React, { useState } from "react";

import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import { useDispatch } from "react-redux";
import { makePayment } from "store/slices/offtaker-slice";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 528,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

// eslint-disable-next-line react/prop-types
const MarkCompletedModal = ({ open, handleClose, id }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const _FormData = new FormData();
  const onSubmit = async () => {
    setLoading(true);
    try {
      const body = {
        action: "completed",
        status: "delivered",
        dispute_details: "",
      };

      _FormData.append("data",JSON.stringify(body));
      const response = await dispatch(makePayment({ id:id, formdata:_FormData }));
      if (response.payload.status == 200 || response.payload.status == 201) {
        setLoading(false);
        handleClose();
        navigate(0);
      } else {
        setLoading(false);
      }

      return;
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center mb-4">
            <h1 className="text-xl mb-4">Confirm action</h1>
            <p className="font-light">
              You are about to mark this transaction as completed by clicking
              the confirm button. Note that this is not reversible.
            </p>
          </div>
          <div className="flex justify-between">
            <Button color="neutral" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={onSubmit}>
              {loading ? "processing..." : "Confirm"}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default MarkCompletedModal;
