/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { Input } from "@atoms/input";

function SearchLocationInput({ setValue }) {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  const APIKEY = "AIzaSyDA4S3ph7PmMHFQ6mFjN05OWr2t7_i2k18";

  let autoComplete;

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        types: ["geocode"],
      }
    );
    // autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    const postal_code = addressObject.address_components.find((component) =>
      component.types.includes("postal_code")
    )?.long_name;

    const country = addressObject.address_components.find((component) =>
      component.types.includes("country")
    )?.short_name;

    const state = addressObject.address_components.find((component) =>
      component.types.includes("administrative_area_level_1")
    )?.long_name;

    const city = addressObject.address_components.find((component) =>
      component.types.includes("administrative_area_level_2")
    )?.long_name;

    const lng = addressObject.geometry.location.lng();
    const lat = addressObject.geometry.location.lat();
    setValue("address", query, { shouldValidate: true, shouldDirty: true });
    setValue("postal", postal_code === undefined ? '' : postal_code, { shouldValidate: true, shouldDirty: true });
    setValue("state", state, { shouldValidate: true, shouldDirty: true });
    setValue("city", city, { shouldValidate: true, shouldDirty: true });
    setValue("long", lng, { shouldValidate: true, shouldDirty: true });
    setValue("lat", lat, { shouldValidate: true, shouldDirty: true });
    setValue("country", country, { shouldValidate: true, shouldDirty: true });
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${APIKEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  return (
    <div className="search-location-input">
      <Input ref={autoCompleteRef} onChange={(event) => setQuery(event.target.value)} value={query} />
    </div>
  );
}

export default SearchLocationInput;
