/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInitials,priceSplitter } from "@shared/utility";
import { Button } from "@ui";

import { getSingleOfftakerDemand } from "store/slices/offtakers/demands-slice";
// import { User } from "@ui/icons"
import SkeletonComponent from "@components/investors/Skeleton";

const DemandsDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const { id } = location.state;

  const { demand } = useSelector((store) => store?.offtaker_demands);

  useEffect(() => {
    dispatch(getSingleOfftakerDemand(id));
  }, [id]);

  return Object.keys(demand).length == 0 ? (
    <SkeletonComponent />
  ) : (
    <div className="text-default h-full bg-white px-[34px] py-[54px]">
      <div className="flex flex-col lg:flex-row justify-between">
        {/* <div className="flex flex-col justify-start items-start  left-[23px] top-[23px] gap-4"> */}
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 w-[206px] h-[66px]">
          <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
            {demand?.user_image ? (
              <div>
                {demand?.user_image !== null ? (
                  <img
                    className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                    src={`${demand?.user_image?.url}`}
                    alt=""
                  />
                ) : (
                  `${getInitials(demand?.account?.name)}`
                )}
              </div>
            ): (
              `${getInitials(demand?.account?.name)}`
            )}
          </div>
          <div className="flex flex-col justify-start items-start flex-grow-0 ml-4 flex-shrink-0 ">
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-base text-left text-primary">
              {demand?.account?.name}
            </p>
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-sm text-left text-[#18273a]/[0.94]">
              {demand?.account?.company}
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-sm font-light text-left text-[#1b2b41]/[0.72]">
              {demand?.account?.country?.name}
            </p>
          </div>
        </div>

        <div className="hidden lg:block">
          <Button
            onClick={() =>
              navigate("/off-taker/demands/interests", {
                state: {
                  id: demand?._id,
                },
              })
            }
            size="small"
          >
            Manage Interests
          </Button>
        </div>
      </div>

      <div className="flex flex-col justify-start items-start flex-grow-0 mt-6  flex-shrink-0">
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Mineral:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {demand?.mineral?.name}
          </p>
        </div>
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Contract type:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {demand?.contract_type}
          </p>
        </div>
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Delivery Period:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {demand?.delivery_period}
          </p>
        </div>
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Price:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {`${priceSplitter(demand?.price)} USD/${
              demand?.unit_of_measurement?.id
            }`}
          </p>
        </div>
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="min-w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Preffered Shipping type:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {demand?.shipping_type?.slug}
          </p>
        </div>
        {/* <div className="flex flex-row justify-between mb-[15px]">
          <p className="min-w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Delivery Port name:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {demand?.delivery_port_name}
          </p>
        </div> */}
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="min-w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Delivery Location:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {demand?.location?.address}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DemandsDetails;
