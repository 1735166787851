/* eslint-disable no-unused-vars */
/** @format */

import React from "react";
import { Link } from "react-router-dom";

import { Modal, Box } from "@mui/material";
import Success from "../../../assets/images/success.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@hooks";
import { useDispatch } from "react-redux";
import { fetchSubscription } from "@slices/wallet-slice";
import { getProfile,fetchAllNotifications } from "@slices/settings-slice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

// eslint-disable-next-line react/prop-types
export const SuccessModal = ({ showStatus, open = null, type }) => {
  const navigate = useNavigate();
  const profiles = useAuth();
  const dispatch = useDispatch();
  const close = () => {
    showStatus(false);
    dispatch(fetchSubscription());
    dispatch(getProfile());
    dispatch(fetchAllNotifications({limit:10}));
    // navigate("../../../../../miner/team/member");
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {type == "Renewal" ? (
          <div className="text-center mb-4">
            <div className="grid justify-items-center">
              <img src={Success} />
            </div>
            <h1 className="text-xl mb-4"> Your subscription is active</h1>
            <p className="font-light mt-4">
              Congratulations! Your subscription is now active. Thank you for
              choosing our service. We look forward to providing you with the
              best experience possible.
            </p>
            <div className="flex items-center justify-center mt-8">
              <button
                onClick={() => close()}
                className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
              >
                Done
              </button>
              {/* <Link to="/miner/team/member">
                 <Button>Add Member</Button>
               </Link> */}
            </div>
          </div>
        ) : (
          <div className="text-center mb-4">
            <div className="grid justify-items-center">
              <img src={Success} />
            </div>
            <h1 className="text-xl mb-4"> Subscription Cancelled</h1>
            <p className="font-light mt-4">
              Your subscription has been successfully cancelled. Thank you for
              your support and we hope to see you again.
            </p>
            <div className="flex items-center justify-center mt-8">
              <button
                onClick={() => close()}
                className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
              >
                Done
              </button>
              {/* <Link to="/miner/team/member">
                <Button>Add Member</Button>
              </Link> */}
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
};
