/* eslint-disable react/prop-types */
import { BarChartIcon, DiamondIcon, MoneyIcon, PackageIcon } from "@ui/icons";
// import {useAuth} from "@hooks"

const Stat = ({ bgColor, children }) => {
  const backgroundColor = bgColor ? bgColor : "bg-white";
  const boxShadow = bgColor && "shadow-Stat";
  return (
    <div
      className={`flex items-center justify-between border border-solid border-dark-gray-100 px-6 py-5 rounded-lg ${backgroundColor} ${boxShadow}`}
    >
      {children}
    </div>
  );
};

const Stats = ({ record_card }) => {
  // const { profileStatus } = useAuth()
  // const sales = profileStatus === "verified" ? 100 : 0;
  // const transactions = profileStatus === "verified" ? 10 : 0;
  // const items = profileStatus === "verified" ? 120 : 0;
  // const mines = profileStatus === "verified" ? 3 : 0;
  return (
    <>
      <Stat>
        <div>
          <p className="text-sm mb-2 uppercase text-[#92A2AA]">Total sales</p>
          <p className="text-2xl text-[#374146]">{record_card?.total_sales}</p>
        </div>
        <div>
          <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
        </div>
      </Stat>
      <Stat>
        <div>
          <p className="text-sm mb-2 uppercase text-[#92A2AA]">
            All Transactions
          </p>
          <p className="text-2xl text-[#374146]">
            {record_card?.total_transactions}
          </p>
        </div>
        <div>
          <MoneyIcon />
        </div>
      </Stat>
      <Stat>
        <div>
          <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
            Items in Inventory
          </p>
          <p className="text-2xl text-[#374146]">
            {record_card?.items_in_inventory}
          </p>
        </div>
        <div>
          <PackageIcon h="41" w="41" color="#6166B6" opacity="1" />
        </div>
      </Stat>
      <Stat>
        <div>
          <p className="text-sm  mb-2 uppercase text-[#92A2AA]">Mines</p>
          <p className="text-2xl text-[#374146]">{record_card?.total_mines}</p>
        </div>
        <div>
          <DiamondIcon h="41" w="41" color="#6166B6" opacity="1" />
        </div>
      </Stat>
    </>
  );
};

export default Stats;
