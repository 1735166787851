/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import Success from "../../../assets/images/success.svg";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { verify_otp_code, resend_otp_code } from "@slices/otp.slice";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { VerifyPhoneSuccessUrl, BaseUrl } from "@config/app-url";
import { SuccessResponse } from "config/status-code";
// import Success from "../../../../../assets/images/success.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 528,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

// eslint-disable-next-line react/prop-types
export const OTPInfoModal = ({
  setOpen,
  open = null,
  phone_number,
  phone_code,
  iso_code,
  profile
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const handleResendOtp = () => {
    dispatch(
      resend_otp_code({
      user_id:profile?._id
      })
    );
  };

  const { loading } = useSelector((state) => state.otp);

  const onSubmit = () => {
    const data = {
      otp_code: otp,
      phone_number,
      phone_code,
      iso_code,
      device: "web",
      otp_type: "onboarding",
    };

    dispatch(verify_otp_code(data))
      .then((response) => {
        if (
          response.payload.status &&
          SuccessResponse(response.payload.status)
        ) {
          toast.success(response.payload.data.message);
          setOpen(!open);
        }
        throw "error verifying otp";
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    localStorage.removeItem("create_account");
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="text-center mb-4">
          <div className="grid justify-items-center">
            <img src={Success} />
          </div>
          <div className=" my-4 w-full">
            <h2 className="font-bold text-2xl text-head mb-4 pt-4 ">
              Verify phone number
            </h2>

            <p className="text-lightgray pb-4 text-base font-light mb-2 ">
              An sms with a verification code has been sent to your phone number
            </p>

            <div
              id="otp"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                appearance: "none",
                border: "none",
              }}
            >
              <OtpInput
                inputStyle={{
                  margin: "8px",
                  backgroundColor: "#1A38601A",
                  border: "none",
                  width: "62px",
                  height: "56px",
                  textAlign: "center",
                  borderRadius: "5px",
                  borderColor: "none",
                  cursor: "text",
                  fontSize: "2rem",
                  outline: "#6166B6",
                  ring: "#6166B6",
                  appearance: "none",
                }}
                focusStyle={{
                  outline: "#6166B6",
                  ring: "1rem solid #6166B6",
                  border: "1px solid #6166B6",
                  appearance: "none",
                  borderRadius: "4px",
                }}
                id="first"
                value={otp}
                onChange={setOtp}
                numInputs={6}
                isInputNum={true}
              />
            </div>

            <div>
              <p className="text-checkbox mt-2" htmlFor="agree">
                Didn't receive a code?{" "}
                <span
                  className="text-primary cursor-pointer"
                  onClick={handleResendOtp}
                >
                  Resend
                </span>
              </p>
            </div>
          </div>

          <div className="flex items-center justify-between mt-8">
            <Button disabled={!otp || loading} onClick={onSubmit}>
              {loading ? "Loading..." : "Verify phone number"}
            </Button>
            <button
              onClick={() => setOpen(!open)}
              className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
            >
              Close
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
