/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useDispatch } from "react-redux";
import { Box, Modal, Step, StepLabel, Stepper, Rating } from "@mui/material";
import DocumentIcon from "components/ui/icons/DocumentIcon";
import Padlock from "@ui/icons/PadLock";
import StepperCheckIcon from "@ui/icons/StepperCheckIcon";
import { Card, Button } from "@ui";
import { makePayment, submitRatings } from "store/slices/offtaker-slice";
import DiplomaScroll from "@ui/icons/DiplomaScroll";
import MarkCompletedModal from "@components/transactions/MarkCompletedModal";

const OffTakerInTransit = ({ inventoryStatus, singleTransaction }) => {
  const [openStatus, setOpenStatus] = useState(false);
  const _FormData = new FormData();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [showDisputeField, setShowDisputeField] = useState(false);
  const [ratings, setRatings] = useState(2);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState();
  const [isCompleted, setIsCompleted] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm({
    mode: "all",
  });

  const steps = [
    "Pending",
    "Awaiting POP",
    "Awaiting POF",
    "Awaiting POC",
    "In Transit",
    "Delivered",
    "Completed",
  ];

  const indexOf = steps.findIndex((el) => {
    return el == inventoryStatus;
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 448,
    bgcolor: "background.paper",
    borderRadius: "0.25rem",
    boxShadow: 24,
    p: 4,
    outline: 0,
    padding: "22px",
  };
  const onSubmit = handleSubmit(async (data) => {
    try {
      const body = {
        action: "completed",
        status: data.status,
        dispute_details: data?.dispute_details ?? "",
      };
      _FormData.append("data", JSON.stringify(body));
      const res = await dispatch(
        makePayment({ id: singleTransaction?._id, formdata: _FormData })
      );

      res.payload.status === 200
        ? navigate("/off-taker/interactions/transactions")
        : setOpenStatus(false);
    } catch (error) {
      console.error(error);
    }
  });

  const onSubmitRatings = async () => {
    try {
      setLoading(true);
      const body = {
        rating: ratings ?? 1,
        comment: comments ?? "",
      };
      const res = await dispatch(
        submitRatings({ id: singleTransaction?._id, formdata: body })
      );
      if (res.payload.status === 200 || res.payload.status === 201) {
        setLoading(false);
        setOpenConfirm(!openConfirm);
        return;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  const handleIsCompleted = () => {
    setIsCompleted(false);
  };

  return (
    <>
      {/* Confirm button */}
      <MarkCompletedModal
        open={isCompleted}
        handleClose={handleIsCompleted}
        id={singleTransaction?._id}
      />
      {/* Confirm button */}
      <div>
        <>
          {/* update Status Modal */}
          <div>
            <Modal
              open={openStatus}
              onClose={() => setOpenStatus(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434]">
                  Update Status
                </p>
                <div className="mb-[24px] xl:w-full">
                  <label
                    htmlFor={"Update the transaction status:"}
                    className="form-label inline-block mb-2 font-light text-base text-[#1B2B41B8]"
                  >
                    Update the transaction status:
                  </label>
                  <div className="relative flex items-center">
                    <select
                      className="bg-fill border-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded cursor-text text-input py-2 px-4 text-sm"
                      id="update"
                      type="dropdown"
                      {...register("status")}
                      onChange={(e) => {
                        if (e.target.options.selectedIndex === 1) {
                          setShowDisputeField(true);
                        } else {
                          setShowDisputeField(false);
                        }
                      }}
                    >
                      <option value="delivered" selected>
                        Delivered
                      </option>
                      <option value="dispute">Dispute</option>
                    </select>
                  </div>
                </div>
                {showDisputeField && (
                  <div className="pb-[10px] col-span-7">
                    <div className="mb-3 xl:w-full">
                      <label
                        htmlFor={"Dispute details"}
                        className="form-label inline-block mb-2 font-light text-base text-[#1B2B41B8]"
                      >
                        Dispute details
                      </label>
                      <textarea
                        className="form-control resize-none block w-full p-[10px] font-light bg-clip-padding bg-[#1A38601A] border-none z-0 focus:shadow focus:outline-none focus:ring-primary rounded transition ease-in-out"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        {...register("dispute_details")}
                      ></textarea>
                    </div>
                  </div>
                )}
                <div className="flex items-center justify-center">
                  <Button
                    size="small"
                    onClick={() => {
                      onSubmit();
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </Box>
            </Modal>
          </div>
          {/* Confirm Arrival */}
          <div>
            <Modal
              open={openConfirm}
              onClose={() => setOpenConfirm(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <h1 className="text-center text-[22px] mt-5">Rate Miner</h1>
                <div className="flex  items-center mt-3 flex-col gap-3">
                  <img
                    className="rounded-full w-10 h-10 flex items-center justify-center text-white bg-primary mr-4"
                    src={`${singleTransaction?.seller_image?.url}`}
                    alt=""
                  />
                  <h1 className="text-primary font-light text-base capitalize">
                    {singleTransaction?.seller?.name}
                  </h1>
                  <div className="star">
                    <p className="text-center font-light text-lightgray text-xs">
                      Click on star or type in rating
                    </p>
                    <div className="flex flex-col items-center">
                      <Rating
                        name="simple-controlled"
                        className="!text-primary mt-3 !text-[30px] "
                        value={ratings}
                        onChange={(_, newValue) => {
                          setRatings(newValue);
                        }}
                      />
                      <div className="py-1 rounded-sm w-20 text-center bg-lightgray500 mt-5">
                        <span className=" grid grid-cols-3  px-2">
                          <span>
                            <input
                              type="number"
                              max="5"
                              value={ratings}
                              className="w-full text-center bg-lightgray500"
                              onChange={({ target: { value } }) => {
                                if (value <= 5) {
                                  setRatings(value);
                                }
                              }}
                            />
                          </span>
                          <span className="text-dark-gray-500">/</span>
                          <span className="text-primary">5</span>
                        </span>
                      </div>
                      <div className="mb-3 xl:w-full w-full mt-5">
                        <label
                          htmlFor={"Dispute details"}
                          className="form-label inline-block mb-1 font-light text-xs text-[#1B2B41B8]"
                        >
                          How was the transaction?
                        </label>
                        <textarea
                          className="form-control placeholder:text-xs resize-none block w-[400px] p-[10px] font-light bg-clip-padding bg-[#1A38601A] border-none z-0 focus:shadow focus:outline-none focus:ring-primary rounded transition ease-in-out"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          placeholder="This field has limit of 160 characters"
                          onChange={({ target: { value } }) =>
                            setComments(value)
                          }
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between mt-7 mb-3">
                  <Button
                    color="neutral"
                    onClick={() => setOpenConfirm(!openConfirm)}
                  >
                    Skip
                  </Button>
                  <Button onClick={() => onSubmitRatings()}>
                    {loading ? "Processing..." : "Submit ratings"}
                  </Button>
                </div>
              </Box>
            </Modal>
          </div>

          <Card noPadding>
            <div className="border-b-[1px] border-[#1C345442] h-[258px] mx-[-1.5rem]">
              <div className="divide-x divide-disabled mx-[2.5rem]">
                <div className="text-default bg-white pt-[30px]">
                  <div className="flex items-center justify-between">
                    <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[24px] text-left text-[#192434]">
                      Main Info
                    </p>
                    {["Completed"].includes(inventoryStatus) && (
                      <div className="ml-[8px] bg-[#6166B6] rounded-[3px] py-1 text-">
                        <Button
                          variant="outlined"
                          color="text-[#ffffff]"
                          fontWeight="font-light"
                          onClick={() => {
                            setOpenConfirm(true);
                          }}
                          styleClass="!text-base"
                        >
                          Rate Miner
                        </Button>
                      </div>
                    )}
                    {["In Transit"].includes(inventoryStatus) && (
                      <div className="ml-[8px] bg-[#6166B6] rounded-[3px] py-1 text-">
                        <Button
                          variant="outlined"
                          color="text-[#ffffff]"
                          fontWeight="font-light"
                          onClick={() => {
                            setOpenStatus(true);
                          }}
                          styleClass="!text-base"
                        >
                          Update status
                        </Button>
                      </div>
                    )}
                    {["Delivered"].includes(inventoryStatus) && (
                      <div className="ml-[8px] bg-[#6166B6] rounded-[3px] py-1 text-">
                        <Button
                          variant="outlined"
                          color="text-[#ffffff]"
                          fontWeight="font-light"
                          onClick={() => {
                            setIsCompleted(true);
                          }}
                          styleClass="!text-base"
                        >
                          Mark as completed
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="text-default  w-[796px] bg-white">
                    <div className="flex">
                      <div className="w-[50%]">
                        <div className="flex mb-[16px]">
                          <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            Transaction ID:
                          </p>
                          <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            {singleTransaction?.transaction_id}
                          </p>
                        </div>
                        <div className="flex mb-[16px]">
                          <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            Product name:
                          </p>
                          <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            {singleTransaction?.product?.product_name}
                          </p>
                        </div>
                        <div className="flex mb-[16px]">
                          <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            Name of seller:
                          </p>
                          <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            <span className="flex -ml-2 text-[#6166B6]">
                              {singleTransaction?.seller?.name}
                            </span>
                          </p>
                        </div>
                        <div className="flex mb-[16px]">
                          <p className="flex-grow-0 mr-[100px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            Status:
                          </p>
                          <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            <span
                              className={`py-1 px-5 inline-flex capitalize text-sm leading-5 font-light rounded bg-[#FDF2E7] text-[#F2994A] `}
                            >
                              {inventoryStatus}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="mb-[16px]">
                          <p>Transaction value</p>
                          <p className="font-medium text-lg">
                            {singleTransaction?.product?.product_total_value}
                          </p>
                        </div>
                        <div>
                          <p>Commission value (5% of transaction value)</p>
                          <p className="font-medium text-lg">
                            {(
                              singleTransaction?.product?.product_total_value *
                              0.05
                            )?.toFixed(2) ?? "N/A"}{" "}
                            {singleTransaction?.product?.product_total_value &&
                              "USD"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="divide-x divide-disabled">
                <div className="text-default bg-white pt-[30px]">
                  <div className="flex items-center justify-between">
                    <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[24px] text-left text-[#192434]">
                      Documents
                    </p>
                  </div>
                  <div className="flex flex-wrap pb-2">
                    {singleTransaction?.documents[0].map((document, i) => (
                      <a
                        key={i}
                        download
                        target="_blank"
                        rel="noreferrer"
                        href={document.url}
                        className=" flex items-center  text-xs mb-[16px]"
                      >
                        <DocumentIcon />
                        <span className="text-primary text-sm font-light ml-2">
                          {document.name}
                        </span>
                      </a>
                      // <p
                      //   key={i}
                      //   className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] ml-[19px]"
                      // >
                      //   <span>{<PaperClip />}</span>
                      //   <span>{document.name}</span>
                      // </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <div className="mt-[30px]">
            <Card noPadding>
              <div className="">
                <div className="divide-x divide-disabled">
                  <div className="text-default bg-white pt-[30px]">
                    <div className="flex items-center justify-between">
                      <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[16px] text-left text-[#192434]">
                        Timeline
                      </p>
                    </div>
                    <div>
                      <Stepper orientation="vertical">
                        {singleTransaction?.timeline?.map((step, i) => (
                          <Step key={i}>
                            <StepLabel
                              StepIconComponent={() => <DiplomaScroll />}
                            >
                              <div className="flex font-normal items-center">
                                {/* <img
                    className="max-w-[24px] mr-[8px]"
                    src={ProfilePicture}
                    alt="Miley Cyrus"
                  /> */}
                                <span className="font-[300] text-[#1b2b41a3]">
                                  <span className="text-black mr-2">
                                    {" "}
                                    {step?.message}
                                  </span>

                                  {step?.created_at
                                    ? new Date(
                                        step?.created_at
                                      ).toLocaleString()
                                    : null}
                                </span>
                              </div>
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className="pt-[40px]">
            <Stepper alternativeLabel orientation="horizontal">
              {steps?.map((step, i) => {
                return (
                  <Step key={i}>
                    <StepLabel
                      StepIconComponent={
                        i <= indexOf ? StepperCheckIcon : Padlock
                      }
                    >
                      {step}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </>
      </div>
    </>
  );
};

export default OffTakerInTransit;
