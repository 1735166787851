/* eslint-disable no-unused-vars */
/** @format */

import React from "react";
import { Link } from "react-router-dom";

import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import Success from "../../../../assets/images/success.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@hooks";
import { useDispatch } from "react-redux";
import { fetchAdminTeam } from "@slices/admin-slice";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

// eslint-disable-next-line react/prop-types
export const SuccessModal = ({ setIsLoading, open = null, data = null }) => {
  const navigate = useNavigate();
  const profiles = useAuth();
  const dispatch = useDispatch();
  const close = () => {
    dispatch(
      fetchAdminTeam({
        id: profiles?.account?._id,
        page: 1,
        limit: 10,
      })
    );
    setIsLoading(!open);

    // navigate("../../../../../miner/team/member");
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="text-center mb-4">
          <div className="grid justify-items-center">
            <img src={Success} />
          </div>
          {/* <h1 className="text-xl mb-4">The account {data?.name} has been removed from the database.</h1> */}
          <p className="font-light mt-4">
            The account <span className="text-[#6166B6]">{data?.name}</span> has
            been removed from the database.
          </p>
          <div className="flex items-center justify-center mt-8">
            <button
              onClick={() => close()}
              className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
            >
              View Members
            </button>
            {/* <Link to="/miner/team/member">
              <Button>Add Member</Button>
            </Link> */}
          </div>
        </div>
      </Box>
    </Modal>
  );
};
