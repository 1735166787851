/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { InfoStats } from "components/ui/index";
import { walletInfo } from "constants/index";
import Table from "components/Table";
import { useDispatch, useSelector } from "react-redux";
import { Search, Button, TableEmptyState, TableSkeleton, Tab } from "@ui";
import { DropDownIcon, SearchIcon, Sort } from "@ui/icons";
import { getWalletLogs } from "@slices/wallet-slice";

const Wallet = () => {
  const dispatch = useDispatch();
  const { loading, wallet, pagination, counts } = useSelector(
    (state) => state.wallet
  );
  useEffect(() => {
    dispatch(getWalletLogs());
  }, [dispatch]);

  return (
    <div>
      <div className="grid grid-cols-3 lg:grid gap-2 sm:flex overflow-x-auto scrollbar-hide">
        <InfoStats statData={walletInfo.statInfo} type="wallet"  counts={counts} />
      </div>
      {/* tabs */}
      <div className="mt-4 sm:flex overflow-x-auto scrollbar-hide">
        <Table
          tableInfo={walletInfo}
          data={wallet}
          fetchDefaultData={getWalletLogs}
          pagination={pagination}
          isStatus={true}
          isViewDetails={false}
          isToggle={false}
          loading={loading}
        />
        {/* <div className="flex flex-col">
          <div className="-my-2 overflow-x-hidden sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full max-w-[1020.270px] sm:px-6 lg:px-8">
              <div className="shadow overflow-auto  border-gray-200 sm:rounded-lg bg-white">
                {
                  <div className="flex justify-between p-4">
                    <form className="flex gap-2">
                      <div className="w-[250px] lg:w-[538px]">
                        <Search placeholder="Search Wallet log" />
                      </div>
                      <Button type="submit" color="primary">
                        <SearchIcon color="#ffff" opacity="1" />
                      </Button>
                    </form>
                  </div>
                }
                <table className="min-w-full divide-y divide-[#1A38601A] border-b border-[#1A38601A]">
                  <thead className="bg-[#EFF0FE] text-center">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        s/n
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        Payment ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        Payment For
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        Paid On
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-sm font-light text-primary uppercase tracking-wider"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Wallet;
