import { useNavigate } from "react-router-dom";
import { Button, Card } from "@ui";

const Demand = ({ demand: dem }) => {
  const navigate = useNavigate();
  const demand = {
    product_image: dem?.demand?.product?.image[0].url,
    product_name: dem?.demand?.product?.product_name,
    product_total_value: dem?.demand?.product?.total_value,
    product_treatment_cost: dem?.demand?.product?.treatment_cost,
    product_quantity: dem?.demand?.product?.quantity,
    product_price: dem?.demand?.product?.price,
    product_unit_of_measurement:
      dem?.demand?.product?.unit_of_measurement?.id,
    mine: dem?.demand?.product?.mine?.mine_name,
  };

  return (
    <Card>
      <div className="h-[217px] rounded-lg overflow-hidden">
        <img
          className="h-full w-full object-cover"
          src={demand?.product_image}
          alt="daniOla product card image"
        />
      </div>
      <h3 className="text-2xl text-heading mt-4 capitalize">
        {demand?.product_name}
      </h3>
      <p className="text-sm text-light font-light">{demand?.mine}</p>

      {/* card information */}
      <div className="grid grid-cols-5 gap-y-2 mb-3">
        <div className="col-span-2 font-light text-ts text-sm">
          <p>Quantity</p>
        </div>
        <div className="col-span-3 font-light text-light">
          <p>
            {demand?.product_quantity} {demand?.product_unit_of_measurement}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-5 gap-y-2 mb-3">
        <div className="col-span-2 font-light text-ts text-sm">
          <p>TC</p>
        </div>
        <div className="col-span-3 font-light text-light">
          <p>
            {demand?.product_treatment_cost} USD/
            {demand?.product_unit_of_measurement}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-5 gap-y-2 mb-3">
        <div className="col-span-2 font-light text-ts text-sm">
          <p>Price</p>
        </div>
        <div className="col-span-3 font-light text-light">
          <p>
            {demand?.product_price} USD/
            {demand?.product_unit_of_measurement}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-5 gap-y-2 mb-3">
        <div className="col-span-2 font-light text-ts text-sm">
          <p>Total value</p>
        </div>
        <div className="col-span-3 font-light text-light">
          <p>{demand?.product_total_value} USD</p>
        </div>
      </div>
      {/* button */}
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          navigate("/off-taker/demands/interest", {
            state: {
              id: dem?._id,
            },
          })
        }
        fullWidth
      >
        View details
      </Button>
    </Card>
  );
};

export default Demand;
