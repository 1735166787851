/* eslint-disable no-unused-vars */
import { useNavigate } from "react-router-dom";
import { Button } from "@ui";
import { AddIcon } from "@ui/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkPermission } from "shared/utility";
import { getMinerInventoryLog } from "@slices/miners/inventory-slice";
import {
  fetchPaginatedInventory,
  setSearchValue,
} from "store/slices/miners/inventory-slice";
import Table from "components/Table";
import { inventoryTableInfo } from "constants/index";

const Inventory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterParams, setFilter] = useState("");
  const { loading, pagination, search, inventory, filter } = useSelector(
    (state) => state.inventories
  );

  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedInventory({
        page: pagination[option + "Page"],
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };

  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedInventory({
        page: 1,
        per_page: e.target.value,
        search: search,
        filter: filter,
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(setSearchValue(e.target.value));
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(getMinerInventoryLog());
    }
    dispatch(setSearchValue(search));
    dispatch(
      fetchPaginatedInventory({
        page: 1,
        per_page: pagination.limit,
        search: search,
        filter: filterParams,
      })
    );
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    setFilter(tab);
    if (tab == "All") {
      if (search == "") {
        dispatch(getMinerInventoryLog());
      } else {
        dispatch(
          fetchPaginatedInventory({
            page: 1,
            per_page: pagination.limit,
            search: search,
            filter: "",
          })
        );
      }
    } else {
      dispatch(
        fetchPaginatedInventory({
          page: 1,
          per_page: pagination.limit,
          search: search,
          filter: tab,
        })
      );
    }
  };

  return (
    <div>
      <div className="my-[34.5px] w-full">
        <Button
          onClick={() => navigate("/miner/inventory/add")}
          disabled={checkPermission("Inventory")}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Add new product
        </Button>
      </div>
      {/* tabs */}
      <div className="mt-4 flex overflow-x-auto scrollbar-hide gap-3">
        <div className="min-w-[1024px] lg:w-full overflow-x-hidden ">
          <Table
            tableInfo={inventoryTableInfo}
            isViewDetails={false}
            navigate={navigate}
            data={inventory}
            pagination={pagination}
            handleSetPerPage={handleSetPerPage}
            handleSearch={handleSearch}
            handleSearchSubmit={handleSearchSubmit}
            handleChangePage={handleChangePage}
            loading={loading}
            handleTabChange={handleTabChange}
            fetchDefaultData={getMinerInventoryLog}
            search={search}
          />
        </div>
      </div>
    </div>
  );
};

export default Inventory;
