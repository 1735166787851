/* eslint-disable react/prop-types */

// the page prop could be mine || inventory e.t.c
const TableEmptyState = ({ text }) => {
  return (
    <>
      <div className="flex flex-col items-center justify-center h-96">
        <>
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M45 16.25C45 18.32 38.285 20 30 20C21.715 20 15 18.32 15 16.25C15 14.18 21.715 12.5 30 12.5C38.285 12.5 45 14.18 45 16.25Z"
              fill="#1C3454"
              fillOpacity="0.26"
            />
            <path
              d="M30 22.5C21.75 22.5 15 20.75 15 18.75V26.25C15 28.25 21.75 30 30 30C38.25 30 45 28.25 45 26.25V18.75C45 20.75 38.25 22.5 30 22.5Z"
              fill="#1C3454"
              fillOpacity="0.26"
            />
            <path
              d="M30 32.5C21.75 32.5 15 30.75 15 28.75V36.25C15 38.25 21.75 40 30 40C38.25 40 45 38.25 45 36.25V28.75C45 30.75 38.25 32.5 30 32.5Z"
              fill="#1C3454"
              fillOpacity="0.26"
            />
            <path
              d="M30 42.5C21.75 42.5 15 40.75 15 38.75V46.25C15 48.25 21.75 50 30 50C38.25 50 45 48.25 45 46.25V38.75C45 40.75 38.25 42.5 30 42.5Z"
              fill="#1C3454"
              fillOpacity="0.26"
            />
          </svg>

          <p className="text-heading text-lg mb-2"> No data to display</p>
          <p className="text-light font-extralight">
            Add a new {text} to get started
          </p>
        </>
      </div>
    </>
  );
};

export default TableEmptyState;
