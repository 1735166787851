/* eslint-disable react/no-unescaped-entities */
const Privacy = () => {
    return (
        <div className="text-sm text-gray">
            This Privacy Policy was last modified on August 1, 2022. <br />
            1. Application
            Daniola Corporation (“Daniola“, “we“, “our“, or “us“) is committed to complying with privacy laws and
            legislation applicable to us, and to protecting the privacy and security of personal information in our custody
            or control. We are based in Lethbridge, Alberta, Canada, and we are subject to Canadian law, so we are
            generally subject to Alberta's Personal Information Protection Act (PIPA), and there may be circumstances
            in which Canada’s Personal Information Protection and Electronic Documents Act (PIPEDA) may be
            applicable.
            This Privacy Policy (“Policy”) applies to our online tracking and bid system for mining, minerals, and related
            activities, and all related content and services provided by us (the “Portal”), and describes how we collect,
            use, store, process, transfer, share, and disclose personal information. Any use of the Portal, and access
            to content or services, is subject to our applicable Terms of Service.
            www.daniolacorp.com/termsandconditions
            Personal information means data that identifies an individual or is associated with an identifiable individual
            (“personal information”). This Policy also provides information regarding the use of cookies and similar
            technologies.
            By accessing or using the Portal, you are accepting and consenting to our practices as described in this
            Policy insofar as your access and use relates to Daniola.
            It is our policy to only collect, use, store, process, transfer, share, and disclose your personal information
            for reasonable purposes and with your consent, except where consent is not required by law. We reserve
            all our rights under all available exceptions or exclusions under applicable privacy law and this applies to
            all our activities as described in this Policy, and to all provisions stated in this Policy.
            When we collect, use, or disclose personal information, we make reasonable efforts to ensure that it is
            accurate, up to date, and complete.
            The laws and legislation applicable to us may change, or our policies and procedures related to our
            collection, use, and disclosure of personal information may change (in response to changes in the law, or
            due to changes in how we carry on business and improvements we may make), or both, so we may
            occasionally modify this Policy to reflect such changes.
            Please be advised that the Internet, websites, web portals, and email are inherently insecure media, and
            we cannot take responsibility for the security or privacy of personal information in transit over the Internet.
            Please also note that the Portal may contain links to other websites which are provided as a convenience
            for visitors to the Portal only. Any third-party websites will have their own privacy policies and practices,
            and we cannot be responsible for such third parties or their websites.
            The Portal is not directed at persons under 18, or the age of majority in the jurisdiction in which such persons
            are located, resident, or domiciled, whichever is greater, and we do not intend to collect personal
            information from individuals under 18, or their applicable age of majority. If you become aware that an
            individual under the age of 18 has provided us with personal information without appropriate consent, then
            please contact us using the details at the end of this Policy so that we can take the appropriate steps in
            accordance with our legal obligations and this Policy.
            If we need to contact or notify you, or provide you with information, we will select what we believe is the
            best way to do so. We will usually do this through email or by placing a notice on the Portal or, where you
            have subscribed to content or services from us, in that content or services or materials or information made
            available through such services. You consent to communicating, transacting, and receiving messages
            electronically, including via email or text, or by our placing a notice in the Portal or services, as applicable.
            2. Personal information we collect
            We generally collect personal information for reasonable purposes related to carrying on our business and
            the functions and purposes of the Portal.
            Disclosure of personal information is voluntary. You may choose to not provide personal information, but
            as a result we may not be able to provide access to the Portal, or services available from us to you or
            respond to your requests.
            When you voluntarily submit information directly to us, such as by corresponding or communicating with
            use by email, text, phone, through the use of the Portal, or other means, or by filling in forms, we collect the
            personal information you provide. This may include information you provide when you register to use the
            Portal, or during the course of your subsequent use of the Portal, or when you subscribe for services or
            content, or when you report a problem with the Portal, or use some other feature of the Portal as may be
            available from time to time.
            We may receive personal information about you from third parties such as individuals or corporate entities
            which are working with you or otherwise have a relationship with you. We require those third parties to only
            provide personal information to us with consent or pursuant to a lawful exception or exemption to consent.
            We may also work with third parties (including, for example, subcontractors in the provision of technical
            services, or in procuring and processing payments, providers of analytics services, advertising networks,
            search information providers, and credit reporting or reference agencies) and we may receive information
            about you from them, subject to your agreements with them, and your consent to their disclosing your
            personal information to us.
            Personal Information we collect includes the following:
            • Contact information and personal details, such as name, phone number, email address, physical or
            street address, location, IP address and, where applicable, professional details and credentials such
            as information about your business or professional status.
            • Login credentials to the Portal, or any services provided or made available, such as email account,
            username, and password.
            • Correspondence, comments, and another information you may choose to send or disclose to us.
            • Payment information, if applicable.
            • Investment, credit, and employment details such as contact information and personal details
            (described above), professional and educational information, employment history, resume, and
            similar information.
            • We may also automatically collect information about how you access and use the Portal, and any
            services provided, including, for example, the device you use, the times at which, how frequently and
            for how long you access the Portal, which documents, records, or data you access, whether you
            open emails or text messages from us or click the links contained in those emails or text messages,
            whether you access the Portal from multiple devices, and generally your activities in using the Portal.
            If you subscribe for services pursuant to our Terms of Service,
            www.daniolacorp.com/termsandconditions you also consent to our collection, use, and
            disclosure of Aggregated Statistics as the same is defined in the Terms of Service.
            www.daniolacorp.com/termsandconditions
            • Internet Protocol or IP addresses, browser type and version, Internet Service Provider or ISP,
            referring or exit web pages, date/time stamp, operating system, and some types of “clickstream” data
            may also be collected automatically by us, or by our service providers and then provided to us.
            We may link or combine the personal information we collect or receive about you with the information we
            collect automatically. We use this information to help us provide you with a personalized experience in your
            interaction with us or use of the Portal
            We may anonymize and aggregate any of the personal information we collect. “Anonymize” means that
            we remove any identifiers so that the information no longer identifies you, and “aggregate” means that we
            combine that anonymized information with other anonymized information. We may use anonymized and/or
            aggregated information for business analytics purposes or research for internal purposes. We may also
            share such anonymized and/or aggregated information with others, but you cannot be identified from such
            anonymized and/or aggregated information.
            3. Use of personal information
            We use personal information for purposes including the following:
            • To communicate with you, respond to your inquiries and requests, including to deal with service
            issues, technical support, and inquiries related to the Portal and provision of goods or services to
            you, and to send you statements, alerts, notifications, news, and financial and investment
            information.
            • To operate, maintain, support, enhance, improve, and provide information through the Portal.
            • To process any payments or money transfers, to assess your credit score (with your consent), and
            to detect and prevent fraud and misuse of the Portal.
            4. Disclosure of personal information
            We may share personal information with any member of our corporate group, which may include
            subsidiaries. Except as otherwise permitted by applicable lawful exceptions or exemptions, we will not share
            your personal information with any third parties except as described in this Policy or in connection with the
            Portal, or otherwise without obtaining your consent.
            We may share your information with selected third parties, including:
            • Our service providers, business partners, vendors, suppliers, licensors, and subcontractors who
            perform services on our behalf (but we use contractual measures to protect and maintain the
            confidentiality and security of personal information shared with these entities, and these entities are
            authorized by us to use your personal information only as necessary to provide these services to us).
            • Information required to provide goods or services to you.
            • Billing, payment, and credit card information for the purposes of accepting payment from you.
            • Credit reference agencies for the purpose of assessing your credit score related to our entering into
            a contract with you or providing services to you.
            .
            We may also be under a duty to disclose or share your personal information in order to comply with
            contractual, regulatory, or legal obligations, or in order to enforce or apply our terms of use with respect to
            the Portal and any services provided www.daniolacorp.com/termsandconditions and other contracts, or
            to protect the legal or proprietary rights, intellectual property rights, property, or safety of Daniola, our
            investors, customers, subscribers, or others.
            We may exchange information with other companies and organizations for the purposes of fraud protection
            and credit risk reduction, or to conduct legal investigations or legal proceedings, or to protect the property,
            safety, or rights of Daniola, our investors, customers, licensees, users, subscribers, or others.
            We may disclose personal information to third parties in connection with a potential or actual business
            transaction, such as a merger, sale of assets or shares, reorganization, financing, change of control or
            acquisition of all or a portion of our business. Alberta law permits our doing so without consent under
            certain conditions, and we reserve our rights under Alberta law.
            From time to time, we may contact you with your consent with relevant information about the Portal and our
            business. Most messages will be sent electronically. For some messages, we may use personal information
            we collect about you to help us determine the most relevant information to share with you. If you do not
            want to receive such messages from us, you will be able to tell us by selecting certain boxes on forms we
            use when we first collect your contact details. You can also change your preferences at any time by
            following the unsubscribe link at the bottom of our emails or text messages.
            5. Lawful Exceptions to Consent
            Under Alberta law, we may collect, use, or disclose personal information without consent in circumstances
            that may include but are not limited to the following:
            • Where collection, use or disclosure of the information is clearly in the interests of the individual and
            consent of the individual cannot be obtained in a timely way;
            • Where the collection, use, or disclosure of the information is required or authorized by law;
            • Where the collection, use or disclosure of the information is reasonable for the purposes of an
            investigation or a legal proceeding;
            • Where the information is publicly available from a source we are allowed legally to receive the
            information from without consent;
            • Where the collection of the information is necessary to collect a debt owed to us or for us to repay to
            an individual money owed by us;
            • In certain situations, we may be required by law to disclose personal information in response to lawful
            requests by governmental or public authorities, including to meet securities regulators and law
            enforcement requirements. It is our policy to comply with all such legal requirements.
            6. Security
            We recognize our legal obligations to protect the personal information we have gathered about individuals.
            We have therefore made arrangements to secure against unauthorized access, collection, use, disclosure,
            copying, modification, disposal or destruction of personal information. These arrangements may include
            physical security measures, network security measures, and organizational measures such as nondisclosure agreements and need-to-know access.
            8. Notification of Loss or Unauthorized Access or Disclosure
            Where an incident occurs involving the loss of, or unauthorized access to or disclosure of personal
            information under our control, where a reasonable person would consider that there exists a real risk of
            significant harm to an individual as a result of the loss or unauthorized access or disclosure, we may notify
            as required by law any Information and Privacy Commissioner, including, in some situations, the Privacy
            Commissioner for Canada, including providing any information required by law at the time to be provided
            to the applicable Commissioner. We may also elect in any event to immediately notify you of any incident
            in the event we consider it reasonable in the circumstances.
            9. Retention and destruction of personal information
            The law generally allows us, for legal or business purposes, to retain personal information for as long as is
            reasonable. We will only retain your personal information for as long as necessary to fulfill the purposes
            we collected it for, including for the purposes of our legitimate business interests and satisfying any legal
            or reporting requirements.
            To determine the appropriate retention period for personal information, we consider the amount, nature,
            and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure
            of your personal information, the purposes for which we process your personal information and the
            applicable legal requirements.
            Upon expiry of an appropriate retention period, bearing in mind reasonable legal and business
            requirements, personal information will either be destroyed in a secure manner or made anonymous.
            Should consent to our collection, use, disclosure, or retention of personal information be revoked by the
            individual in question, the law also allows us to continue to retain the information for as long as is reasonable
            for legal or business purposes. In the event that revocation of consent may have consequences to the
            individual concerned, we will advise the individual of the consequences of revoking their consent where it
            is reasonable in the circumstances to do so.
            10. Legal Holds
            There are situations where records scheduled for destruction must be preserved and not destroyed. In
            those cases, we may implement and enforce a "legal hold". Those situations include audits, investigations,
            government or regulatory action, and actual or potential civil litigation. In addition, where an individual has
            requested access to their personal information, it is illegal to destroy that information. It is therefore our
            policy to retain all records required for legal purposes for as long as those records, which may include
            personal information, may be required.
            11. Outsourcing and Data Hosting Outside of Canada
            We may use "cloud computing" third party service providers, and those providers may be either in or outside
            Canada, and the data housed, hosted, and processed by such providers may reside in or outside of
            Canada, and may include personal information about individuals. Where consent or notification is legally
            required, it is our policy to notify individuals about such service providers outside of Canada, and such
            notification will include the way in which the individual may obtain access to written information about our
            policies and practices with respect to service providers outside of Canada and the name or tile of a person
            who can answer any questions about the collection, use, disclosure or storage of personal information by
            any service providers outside Canada.
            If you are located in the European Union or the United Kingdom, your personal information may be
            processed outside of the European Union or the United Kingdom, including in Canada. Such international
            transfers of your personal information are made pursuant to appropriate safeguards, and we will take
            suitable steps to ensure that your personal data is treated as safely and securely as it would be within the
            UK or the EU and under the General Data Protection Regulation (“GDPR”). Such measures shall include,
            but are not limited to, having Data Processing Agreements with applicable sub-processors and ensuring
            that such sub-processors have adequate security and data protection procedures in place aligned with the
            GDPR or any other applicable data protection law. If you wish to inquire further about these safeguards
            used, please contact us using the details set out at the end of this policy.
            12. CASL Compliance
            Canada has implemented legislation (Generally referred to as Canadian Anti-Spam Legislation, or "CASL")
            prohibiting commercial electronic communications without adequate consent, and without an adequate
            unsubscribe mechanism. Commercial electronic communications ("CEM's") are defined as emails and text
            messages. It is our policy to not send CEMs without valid legal consent from the recipient, and it is our
            policy to always use the unsubscribe mechanisms prescribed by CASL. CASL limits how long we can keep
            and use your information so, unless you have given us a permanent and express consent to contact you or
            send messages to you regularly, we may cease corresponding with you without notice once applicable time
            limits have passed.
            13. Requests for Access
            The law permits individuals to submit written requests to us to provide them with:
            • access to their personal information under our custody or control;
            • information about the purposes for which their personal information under our custody or control
            has been and is being used; and
            • the names of organizations or persons to whom and the circumstances in which personal
            information has been and is being disclosed by us.
            Requests for access are subject to the following:
            • Any requests must be in writing.
            • We reserve the right to not accept such requests or respond to such requests via email in order to
            be certain of the identity of persons with which we communicate and to protect the privacy of
            individuals.
            • In order to receive a response to such a request, the individual must provide us with sufficient
            information to verify their identity or authority, to locate their record, if any, and to respond to them.
            • We will respond to requests in the time allowed by law, which is generally 30 days. We reserve our
            right to extend that time period as permitted by applicable law.
            • We will make a reasonable effort to assist applicants and to respond as accurately and completely
            as reasonably possible.
            • All requests may be subject to any fees and disbursements the law permits us to charge.
            • Where appropriate to do so, we may require advance payment of a deposit or the entire costs of
            responding to a request for access to personal information.
            Please note that an individual’s ability to access his or her personal information under our control is not an
            absolute right. The law prohibits the disclosure of some information, and, with respect to certain other
            information, gives us the right to choose whether to disclose it. We reserve all our rights under applicable
            law to refuse disclosure were legally permitted to do so.
            14. Responses to Requests for Access
            Our responses to requests for access to personal information will be in writing, and will confirm:
            • Whether we are providing all or part of the requested information,
            • Whether or not we are allowing access or providing copies, and,
            • If access is being provided, when and how that will be given.
            If access to information or copies are refused by us, we will provide written reasons for such refusal and
            the section of PIPA (the Personal Information Protection Act, Alberta, if applicable) on which that refusal is
            based. We will also provide the name of an individual at Daniola who can answer questions about the
            refusal, and particulars of how the requesting individual can ask the Information and Privacy Commissioner
            of Alberta to review our decision.
            15. Requests for Correction
            The law permits individuals to submit written requests to us to correct errors or omissions in their personal
            information that is in our custody or control. If an individual alleges errors or omissions in the personal
            information in our custody or control, we will either:
            • Correct the personal information and, if reasonable to do so, and if not contrary to law, send
            correction notifications to any other organizations to whom we disclosed the incorrect information; or
            • Decide not to correct the personal information but annotate the personal information that a correction
            was requested but not made.
            16. Certain Additional Rights
            Applicable privacy law may also provide you with rights including the following (some of which may also be
            applicable under Alberta law as described above):
            • Right of access and portability. The right to obtain access to your personal information along with
            certain information, and to receive that personal information in a commonly used format and to have
            it ported to another data controller.
            • Right to rectification. The right to obtain rectification of your personal information without undue delay
            where that personal information is inaccurate or incomplete.
            • Right to erasure. The right to obtain the erasure of your personal information without undue delay in
            certain circumstances, such as where the personal information is no longer necessary in relation to
            the purposes for which it was collected or processed, or where we have no other lawful basis for
            retaining the information.
            • Right to restriction. The right to obtain the restriction of the processing undertaken by us on your
            personal information in certain circumstances, such as where the accuracy of the personal
            information is contested by you.
            • Right to object. The right to object, on grounds relating to your particular circumstances, to the
            processing of your personal information, and to object to the processing of your personal information
            for direct marketing purposes, to the extent it is related to such direct marketing.
            • Right to non-discrimination. The right to non-discrimination for exercising your rights as outlined in
            this policy. This includes, but is not limited to, denying you goods or services, charging you different
            prices for similar services, or providing a different level or quality of service as a result of your
            exercising your lawful rights.
            If you wish to exercise one of these rights, subject to it being available to you under applicable privacy law,
            please contact us using the contact details at the end of this Policy.
            17. Contacting Daniola
            If you have any questions with respect to our policies concerning the collection, use, disclosure, or handling
            of your personal information, or if you wish to request access to, or correction of, your personal information
            under our care and control, or if you are dissatisfied with how we handle your personal information, please
            contact our Privacy Officer at:
            support@daniolacorp.com
            3582 30 St N
            Lethbridge, Alberta. CANADA T1H 6Z4
            If you remain dissatisfied after our Privacy Officer has reviewed and responded to your concern, or have
            other concerns or questions, you have the right at any time to contact the Office of the Information and
            Privacy Commissioner at:
            Office of the Information and Privacy Commissioner for Alberta
            9925 109 St NW Suite 410
            Edmonton, Alberta, CANADA T5K 2J8
            Tel. 780-422-6860
            Website: https://oipc.ab.ca

        </div>
    );
};

export default Privacy;
