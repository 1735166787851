import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TransactionInfoUrl } from "config/app-url";
import InterationsStats from "@ui/InterationsStats";

import { useDispatch, useSelector } from "react-redux";

import { transactionsTableInfo } from "@constants";
import {
  fetchPaginatedTransactions,
  getTransactions,
  setSearchValue,
} from "store/slices/miners/transaction-slice";
import Table from "components/Table";

const Transactions = () => {
  const {
    loading,
    transactions: { counts, data, pagination, search, filter },
  } = useSelector((state) => state.interactions);


  const handleViewDetails = (record) => {
    navigate(TransactionInfoUrl + `/${record._id}`);
  };
  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedTransactions({
        page: pagination[option + "Page"],
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedTransactions({
        page: 1,
        per_page: e.target.value,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(setSearchValue(e.target.value));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(getTransactions());
    }
    dispatch(setSearchValue(search));
    dispatch(
      fetchPaginatedTransactions({
        page: 1,
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    if (tab == "All") {
      if (search == "") {
        dispatch(getTransactions());
      } else {
        dispatch(
          fetchPaginatedTransactions({
            page: 1,
            per_page: pagination.limit,
            search: search,
            filter: "",
          })
        );
      }
    } else {
      dispatch(
        fetchPaginatedTransactions({
          page: 1,
          per_page: pagination.limit,
          search: search,
          filter: tab,
        })
      );
    }
   
  };
  const [isVerified] = useState(true);
  const [underReview] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div>
      <div className="grid grid-cols-3 lg:grid gap-2 sm:flex overflow-x-auto scrollbar-hide">
        <InterationsStats
          isVerified={isVerified}
          underReview={underReview}
          transactions={true}
          counts={counts}
        />
      </div>
      {/* tabs */}
      <div className="mt-4 sm:flex overflow-x-auto scrollbar-hide">
        <Table
          tableInfo={transactionsTableInfo}
          isViewDetails={true}
          isStatus={true}
          handleViewDetails={handleViewDetails}
          navigate={navigate}
          data={data}
          pagination={pagination}
          handleSetPerPage={handleSetPerPage}
          handleSearchSubmit={handleSearchSubmit}
          handleChangePage={handleChangePage}
          handleTabChange={handleTabChange}
          loading={loading}
          handleSearch={handleSearch}
          fetchDefaultData={getTransactions}
          search={search}
        />
      </div>
    </div>
  );
};

export default Transactions;
