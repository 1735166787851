const ShopIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 19.5H20V20.5H4V19.5Z" fill="#D9E8FC" fillOpacity="0.7" />
      <path
        d="M4 4.5V10.5C4.005 11.232 4.401 11.87 4.991 12.215L5 18.5H14V13.5H17V18.5H19V12.22C19.599 11.87 19.995 11.232 20 10.501V4.5H4V4.5ZM8 6.5H10V10.5C10 11.052 9.552 11.5 9 11.5C8.448 11.5 8 11.052 8 10.5V6.5ZM6 11.5C5.448 11.5 5 11.052 5 10.5V6.5H7V10.5C7 11.052 6.552 11.5 6 11.5ZM12 16.5H7V13.5H12V16.5ZM13 10.5C13 11.052 12.552 11.5 12 11.5C11.448 11.5 11 11.052 11 10.5V6.5H13V10.5ZM16 10.5C16 11.052 15.552 11.5 15 11.5C14.448 11.5 14 11.052 14 10.5V6.5H16V10.5ZM19 10.5C19 11.052 18.552 11.5 18 11.5C17.448 11.5 17 11.052 17 10.5V6.5H19V10.5Z"
        fill="#D9E8FC"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default ShopIcon;
