import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "@config/axios-instance";
import * as API from "../../config/url";
import { toast } from "react-toastify";

const getShipmentActive = createAsyncThunk(
  "miner/getShipmentActive",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getShipmentActive);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getShipmentArchive = createAsyncThunk(
  "miner/getShipmentArchive",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getShipmentArchive);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getTrackingDetails = createAsyncThunk(
  "miner/getTrackingDetails",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        API.getTrackingDetails(payload.id, payload._id)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const shipmentSlice = createSlice({
  name: "tracking",

  initialState: {
    active_data: [],
    archive_data: [],
    docs: {},
    status: false,
    loading: false,
    error: null,
    message: null,
  },

  reducers: {},

  extraReducers: {
    [getShipmentActive.pending]: (state) => {
      state.loading = true;
    },
    [getShipmentActive.fulfilled]: (state, { payload }) => {
      state.loading = false;
      if(payload.data.transaction) {
        state.active_data = payload?.data.transaction;
      }
     
    },
    [getShipmentActive.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [getShipmentArchive.pending]: (state) => {
      state.loading = true;
    },
    [getShipmentArchive.fulfilled]: (state, { payload }) => {
      state.loading = false;
      if(payload?.data?.transaction) {
        state.archive_data = payload?.data.transaction;
      }
      
    },
    [getShipmentArchive.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [getTrackingDetails.pending]: (state) => {
      if (state?.data) {
        delete state.data;
      }
      state.loading = true;
    },
    [getTrackingDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      if(payload?.data?.code == 400 || payload?.data?.code == 422) {
        delete state.data;
        toast.error(payload?.data?.message);
      }else {
        state.data = payload.data;
      }
     
    },
    [getTrackingDetails.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
  },
});

export { getShipmentActive, getShipmentArchive, getTrackingDetails };
export default shipmentSlice.reducer;
