import * as React from "react";

const SvgVector = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8Zm1 13H7V6h2v7Zm0-8H7V3h2v2Z"
      fill="#1C2E45"
      fillOpacity={0.6}
    />
  </svg>
);

export default SvgVector;
