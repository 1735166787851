/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { signInUser } from "@slices/user-slice";
import { Header } from "@atoms/header";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@atoms/button/index";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { verify_otp_code, resend_otp_code } from "@slices/otp.slice";
import { VerifyPhoneSuccessUrl, BaseUrl } from "@config/app-url";
import { toast } from "react-toastify";
import { SuccessResponse } from "config/status-code";

const VerifyPhone = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    state: { phone_code, phone_number, iso_code },
  } = useLocation();

  
  const [otp, setOtp] = useState("");

  const { loading } = useSelector((state) => state.otp);
  const userDetails = JSON.parse(localStorage.getItem("create_account"));
  const auth_user = JSON.parse(localStorage.getItem("auth_user"));
  const onSubmit = () => {
    const data = {
      otp_code: otp,
      phone_number,
      phone_code,
    
      // iso_code,
      // device: "web",
      // otp_type: "onboarding",
    };

    dispatch(verify_otp_code(data))
      .then((response) => {
        if (
          response.payload.status &&
          SuccessResponse(response.payload.status)
        ) {
          const userForm = {
            password: userDetails.password,
            email: userDetails.email,
          }
          dispatch(signInUser(userForm));
          navigate(VerifyPhoneSuccessUrl, { replace: true });
        }
        throw "error verifying otp";
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    // localStorage.removeItem("create_account");
  };

  const handleResendOtp = () => {
    dispatch(
      resend_otp_code({
        user_id:auth_user._id
      })
    );
  };

  return (
    <div className="font-default">
      <Header />
      <div className="bg-white p-8 lg:p-0 rounded mt-[113px] lg:mt-0 ">
        <div
          onClick={() => navigate(-1)}
          className="text-primary border-none cursor-pointer flex justify-end"
        >
          {" "}
          Back
        </div>
        <div className=" my-4 w-full">
          <h2 className="font-bold text-2xl text-head mb-4 pt-4 ">
            Verify phone number
          </h2>

          <p className="text-lightgray pb-4 text-base font-light mb-2 ">
            An sms with a verification code has been sent to your phone number
          </p>

          <div
            id="otp"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              appearance: "none",
              border: "none",
            }}
          >
            <OtpInput
              inputStyle={{
                margin: "8px",
                backgroundColor: "#1A38601A",
                border: "none",
                width: "62px",
                height: "56px",
                textAlign: "center",
                borderRadius: "5px",
                borderColor: "none",
                cursor: "text",
                fontSize: "2rem",
                outline: "#6166B6",
                ring: "#6166B6",
                appearance: "none",
              }}
              focusStyle={{
                outline: "#6166B6",
                ring: "1rem solid #6166B6",
                border: "1px solid #6166B6",
                appearance: "none",
                borderRadius: "4px",
              }}
              id="first"
              value={otp}
              onChange={setOtp}
              numInputs={6}
              isInputNum={true}
            />
          </div>

          <div>
            <p className="text-checkbox mt-2" htmlFor="agree">
              Didn't receive a code?{" "}
              <span
                className="text-primary cursor-pointer"
                onClick={handleResendOtp}
              >
                Resend
              </span>
            </p>
          </div>

          <Button disabled={!otp || loading} onClick={onSubmit}>
            {loading ? "Loading..." : "Verify phone number"}
          </Button>

          <p className="text-lightgray flex justify-center align-center py-2 text-sm  cursor-default my-2">
            Already have an account?{" "}
            <Link className="text-primary pl-2" to={BaseUrl}>
              Sign in instead
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyPhone;
