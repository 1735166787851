/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "@config/url";
import axios from "@config/axios-instance";
const GET = "get";
const DELETE = "delete";
const POST = "post";

const initialState = {
  activeStep: 0,

  isLoading: false,
  error: null,
  isError: false,
  isSuccess: false,
  vendors: null,
  vendor: null,
  transactions: [],
  transaction: null,
  countries: [],
  service_options: {},
  requests: null,
  request: null,
  offering: null,
};

export const vendorConfig = (method, url, data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      "Authorization": "bearer "+ token
    },
    data,
  };
};
export const contactVendor = createAsyncThunk(
  "offtakers/contactVendor",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.contactVendor(id), payload);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const requestService = createAsyncThunk(
  "offtakers/requestService",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.requestService, payload);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getVendors = createAsyncThunk(
  "vendor/getVendors",
  async (name, thunkAPI) => {
    try {
      const resp = await axios.get(API.fetchVendors);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getVendor = createAsyncThunk(
  "vendor/getVendor",
  async (id, thunkAPI) => {
    try {
      const resp = await axios.get(API.fetchVendor(id));
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const searchVendors = createAsyncThunk(
  "vendor/searchVendors",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.searchVendor(
          payload?.search ? payload.search : "",
          payload?.country ? payload.country : ""
        )
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCountries = createAsyncThunk(
  "vendor/getCountries",
  async (name, thunkAPI) => {
    try {

      const resp = await axios.get(API.fetchCountries);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getTransactions = createAsyncThunk(
  "vendor/getTransactions",
  async (name, thunkAPI) => {
    try {
      const resp = await axios.get(API.fetchTransactions);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getTransaction = createAsyncThunk(
  "vendor/getTransaction",
  async (id, thunkAPI) => {
    try {
      const resp = await axios.get(API.fetchTransaction(id));
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

const getDashboardActivities = createAsyncThunk(
  "vendor/getDashboardActivities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.fetchDashboardActivities);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

//NOTIFICATIONS
export const getNotifications = createAsyncThunk(
  "vendor/getNotifications",
  async (name, thunkAPI) => {
    try {
      const resp = await axios.get(API.fetchOfferings);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setProjectPage: (state, action) => {
      action.payload === "New"
        ? (state.activeStep = 1)
        : (state.activeStep = 0);
    },
    setOffering: (state, action) => {
      state.offering = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.vendors) state.vendors = action.payload.vendors.docs;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getVendors.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(searchVendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchVendors.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.vendors) state.vendors = action.payload.vendors.docs;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(searchVendors.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(getVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVendor.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.vendor) state.vendor = action.payload;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getVendor.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getCountries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.countries)
          state.countries = action.payload.countries;
        if (action.payload.service_options)
          state.service_options = action.payload.service_options;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getCountries.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.transactions)
          state.transactions = action.payload.transactions;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getTransactions.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getTransaction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTransaction.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.transaction)
          state.transaction = action.payload.transaction;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getTransaction.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(contactVendor.pending, (state) => {
        state.loading = true;
      })
      .addCase(contactVendor.fulfilled, (state, { payload }) => {
        //state.data = payload.data.files
        state.status = payload.status;
        state.loading = false;
      })
      .addCase(contactVendor.rejected, (state, { payload }) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(requestService.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestService.fulfilled, (state, { payload }) => {
        //state.data = payload.data.files
        state.status = payload.status;
        state.loading = false;
      })
      .addCase(requestService.rejected, (state, { payload }) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export const { setProjectPage, setOffering } = vendorSlice.actions;

export default vendorSlice.reducer;
