/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "@atoms/header";
import { Button } from "@atoms/button/index";
import { Vector } from "@atoms/icons";
import { useDispatch, useSelector } from "react-redux";
import { create_profile, get_roles } from "@slices/onboarding/profile.slice";
import { SuccessResponse } from "@config/status-code";
import { toast } from "react-toastify";
import {
  MinerVerificationUrl,
  VendorVerificationUrl,
  OfftakerVerificationUrl,
} from "@config/app-url";

const SelectCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(get_roles());
  }, [dispatch]);

  const { loading, data } = useSelector((state) => state.profile);
  const user = JSON.parse(localStorage.getItem("user"));
  const roles = [];
  if (data?.docs) {
    data?.docs.forEach((x) => {
      if (!x.account_id && x.slug != "admin") {
        roles.push(x);
      }
    });
  }
  const [category, setCategory] = useState("");
  const [roleID,setRoleID] = useState("");

  const redirect = (category) => {
    switch (category) {
      case "miner":
        navigate(MinerVerificationUrl);
        break;
      case "off-taker":
        navigate(OfftakerVerificationUrl);
        break;
      case "vendor":
        navigate(VendorVerificationUrl);
        break;
      default:
        return;
    }
  };

  const setParams = (role) => {
    setCategory(role.slug);
    setRoleID(role._id);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(create_profile({
      role:roleID,
      user_id:user?.id
    }))
      .then((res) => {
        if (res.payload.status && SuccessResponse(res.payload.status)) {
          redirect(category);
        }
        throw "Error creating profile";
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <div className="font-default">
      <Header />

      <div className=" my-4 bg-white p-8 rounded mt-[113px] lg:mt-0 ">
        <h2 className="font-bold text-2xl text-head pt-4 ">
          Great! Tell us about yourself
        </h2>

        <p className="text-lightgray pb-4 text-base font-light mb-2 ">
          What can Daniola help you as. Select the category that applies to you.
        </p>

        <form className="block ">
          <div>
            {roles?.map((role, index) => (
              <div className="mt-4" key={index}>
                <div>
                  <label className="flex flex-col">
                    <div className="inline-flex items-center">
                      {" "}
                      <input
                        type="radio"
                        className="form-radio text-primary checked:ring-0"
                        name="category"
                        value={role.slug}
                        onClick={() => setParams(role)}
                      />
                      <span className="ml-2 text-input text-base">
                        {role.name}
                      </span>
                    </div>
                    <p className="ml-6 text-lightgray text-sm">
                      {role.description}
                    </p>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </form>

        <Button
          disabled={!category || loading}
          value="submit"
          type="submit"
          onClick={onSubmit}
        >
          Continue
        </Button>

        {/* <div className="flex  align-center pb-2 mt-20">
          <Vector />
          <p className="text-lightgray text-sm ml-2">
            You can switch between multiple profiles later on
          </p>
        </div> */}
      </div>
    </div>
  );
};
export default SelectCategory;
