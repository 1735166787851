/* eslint-disable no-unused-vars */
/**
 * /* eslint-disable react/prop-types
 *
 * @format
 */

import Sidebar from "./sidebar/sidebar";
import SideBar from "./sidebar/sidebarResponsive";
import Navbar from "./navbar";
import Breadcrumb from "./breadcrumb";
import Footer from "./footer";
import { getPermissions } from "shared/utility";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
  AreaSelectIcon,
  BarChartIcon,
  CogIcon,
  PieChartIcon,
  HeadsetIcon,
  ShopIcon,
  LocationIconWhite,
  HandIcon,
  CashIcon,
} from "@ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchAllPermissions } from "@slices/roles-permissions-slice";


var MENU_ITEMS = [];

const LayoutOfftaker = ({ children }) => {
  const roles = useSelector((state) => state.roles_permissions);
  const dispatch = useDispatch();
  const profiles = JSON.parse(localStorage.getItem("account"));
  useEffect(() => {
    dispatch(fetchAllPermissions({id:profiles[0]?.role?._id,role:"user"}));
  }, [dispatch]);

  getPermissions(roles);

  function checkPermission(page) {
    let Permission = "read & write";
    roles?.all_permissions?.forEach((x) => {
      if (x.resource.name == page) {
        if (
          x.method.add === true &&
          x.method.delete === true &&
          x.method.edit === true &&
          x.method.search === true &&
          x.method.view === true
        ) {
          Permission = "read & write";
        } else if (
          x.method.add === false &&
          x.method.delete === false &&
          x.method.edit === false &&
          x.method.search === false &&
          x.method.view === true
        ) {
          Permission = "read";
        } else {
          Permission = "restricted";
        }
      }
    });

    if (page == "Member") {
      if (profiles[0]?.parent_id) {
        Permission = "restricted";
      }
    }

    return Permission;
  }

  function checkSubListPermission(page, action) {
    let Permission = "read & write";

    roles?.all_permissions?.forEach((x) => {
      if (x.resource.name == page) {
        x.actions.forEach((y) => {
          if (y?.name == action) {
            if (y?.allowed == true) {
              Permission = "read & write";
            } else {
              Permission = "restricted";
            }
          }
        });
      }
    });

    return Permission;
  }

  MENU_ITEMS = [
    {
      label: "Dashboard",
      url: "/off-taker/dashboard",
      icon: <PieChartIcon />,
    },
    {
      label: "Tracking",
      url: "/off-taker/tracking",
      icon: <LocationIconWhite />,
      permission: checkPermission("Tracking"),
    },
    {
      label: "Auctions",
      url: "/off-taker/auctions",
      icon: <BarChartIcon />,
      hasDot: true,
      permission: checkPermission("Auction"),
    },
    {
      label: "Demands",
      url: "/off-taker/demands",
      icon: <HandIcon />,
      permission: checkPermission("Demands"),
    },
    {
      label: "Interactions",
      url: "/off-taker/interactions",
      icon: <AreaSelectIcon />,
      hasDot: true,
      permission: checkPermission("Interactions"),
      subList: [
        {
          label: "Bids",
          path: "/off-taker/interactions/bids",
          permission: checkSubListPermission("Interactions", "Bids"),
        },
        {
          label: "Transactions",
          path: "/off-taker/interactions/transactions",
          permission: checkSubListPermission("Interactions", "Transactions"),
        },
        {
          label: "Investments",
          path: "/off-taker/interactions/investments",
          permission: checkSubListPermission("Interactions", "Investment"),
        },
        {
          label: "Projects",
          path: "/off-taker/interactions/projects",
          permission: checkSubListPermission("Interactions", "Project"),
        },
        {
          label: "Offers",
          path: "/off-taker/interactions/offers",
          permission: checkSubListPermission("Interactions", "Offer"),
        },
      ],
    },
    {
      label: "Invest",
      url: "/off-taker/invest",
      icon: <CashIcon />,
      hasDot: true,
      permission: checkPermission("Invest"),
      subList: [
        {
          label: "Proposals",
          path: "/off-taker/invest/proposals",
          permission: checkSubListPermission("Invest", "Proposal"),
        },
        {
          label: "Prospects",
          path: "/off-taker/invest/prospects",
          permission: checkSubListPermission("Invest", "Prospect"),
        },
      ],
    },
    {
      label: "Teams",
      icon: <ShopIcon />,
      permission: checkPermission("Member"),
      subList: [
        {
          label: "Team Roles",
          path: "/off-taker/teams/role",
          permission: checkSubListPermission("Member", "Role"),
        },
        {
          label: "Team Members",
          path: "/off-taker/teams/member",
          permission: checkSubListPermission("Member", "Member"),
        },
      ],
    },
    {
      label: "Vendors",
      icon: <ShopIcon />,
      permission: checkPermission("Vendors"),
      subList: [
        {
          label: "Find a vendor",
          path: "/off-taker/vendors/find_a_vendor",
          permission: checkSubListPermission("Vendors", "All"),
        },
        {
          label: "Request a service",
          path: "/off-taker/vendors/request_a_service",
          permission: checkSubListPermission("Vendors", "Request"),
        },
      ],
    },
    // {
    //   label: "Messages",
    //   url: "/off-taker/messages",
    //   icon: <MailOutlineIcon />,
    // },
    {
      label: "Support",
      url: "/off-taker/support",
      icon: <HeadsetIcon />,
      permission: checkPermission("Support"),
    },
    {
      label: "Settings",
      url: "/off-taker/settings",
      icon: <CogIcon />,
      permission: checkPermission("Settings"),
    },
  ];
  return (
    <div className="grid grid-cols-6 h-screen">
      {/*side bar  */}
      <div className="hidden lg:block col-span-1">
        <Sidebar items={MENU_ITEMS} sliceRange={MENU_ITEMS.length} />
      </div>
      {/* main content */}
      <div className="col-span-full lg:col-span-5">
        <Navbar userType="OffTaker" />
        <div className="xl:hidden">
          <SideBar items={MENU_ITEMS} sliceRange={MENU_ITEMS.length} />
        </div>
        <Breadcrumb />
        <main className="px-4 lg:px-8 min-h-screen">{children}</main>
        <div className="relative">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LayoutOfftaker;
