/* eslint-disable no-unused-vars */
import React from 'react';
import ErrorMessage from 'components/ErrorMessage';
import { TextArea, Checkbox } from '@ui';

const Proposal = ({
	errors,
	register,

	handleChange
}) => {
	return (
		<div className="text-default  w-[896px] h-[898px] bg-white px-[34px]">
			<div className="mb-8">
				<p className="flex-grow-0 flex-shrink-0 text-[22px] mb-2 text-left text-[#192434]">
					Proposal
				</p>
				<p className="flex-grow-0 flex-shrink-0 w-[796px] text-base font-light text-left text-[#1b2b41]/[0.72]">
					Write a proposal to improve your chances of funding.
				</p>
			</div>

			<TextArea
				label="Proposal"
				id="proposal"
				resizable
				row={10}
				{...register('proposal')}
				name="proposal"
				onChange={handleChange}
				size="large"
				maxHeight="600px"
			/>
			{errors.proposal && (
				<ErrorMessage>{errors.proposal?.message}</ErrorMessage>
			)}

			<div className="text-checkbox flex flex-col text-sm mt-8">
				<p className="flex-grow-0 flex-shrink-0 text-[18px] mb-2 text-left text-[#192434]">
					Terms and conditions
				</p>
				<div className="flex items-center">
					<Checkbox value="true" onChange={handleChange} {...register('terms')} />
					<label className="text-checkbox" htmlFor="agree">
						I agree to the{' '}
						<span className="text-primary cursor-pointer">
							Terms and Conditions
						</span>
					</label>
				</div>
				{errors.terms && <ErrorMessage>{errors.terms?.message}</ErrorMessage>}
			</div>
		</div>
	);
};

export default Proposal;
