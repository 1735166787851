import * as React from "react";

const SvgUpload = (props) => (
  <svg
    width={16}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.912 3.504v7.992a.912.912 0 0 1-.912.912c-.504 0-.888-.408-.888-.912V3.504L4.088 6.168a.857.857 0 0 1-1.248-.072.886.886 0 0 1 .072-1.272L7.4.84c.336-.312.84-.312 1.2-.024V.84l4.488 3.984a.91.91 0 0 1 .096 1.272.886.886 0 0 1-1.272.072l-3-2.664Zm5.28 10.392c0-.504.408-.888.912-.888s.888.384.888.888v2.208c0 .48-.384.888-.888.888H.896a.902.902 0 0 1-.888-.888v-2.208c0-.504.408-.888.888-.888.504 0 .912.384.912.888v1.296h12.384v-1.296Z"
      fill="#1B2B41"
      fillOpacity={0.72}
    />
    <path
      d="m9.244 3.13-.832-.739v9.105a.412.412 0 0 1-.412.412c-.21 0-.388-.166-.388-.412V2.397l-.83.732-3.025 2.664-.005.005-.005.005c-.16.149-.4.125-.52-.022l-.01-.013-.012-.013a.386.386 0 0 1 .036-.555l.003-.002 4.488-3.984.008-.007a.401.401 0 0 1 .45-.061l.078.068 4.488 3.984.003.002a.41.41 0 0 1 .048.568.386.386 0 0 1-.554.035l-.01-.009-3-2.664ZM1.308 15.192v.5h13.384v-1.796c0-.21.166-.388.412-.388.228 0 .388.16.388.388v2.208a.386.386 0 0 1-.388.388H.896a.402.402 0 0 1-.388-.388v-2.208c0-.219.175-.388.388-.388.245 0 .412.178.412.388v1.296Z"
      stroke="#1C375A"
      strokeOpacity={0.16}
    />
  </svg>
);

export default SvgUpload;
