/** @format */
import React from "react";
import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import { AwardBidSuccessModal } from "./AwardBidSuccessModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 268,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

// eslint-disable-next-line react/prop-types
export const ConfirmationAwardBidModal = ({
  open,
  setOpen,
  handleAwardBid,
  handleArchiveBid,
  actionType,
  name,
  amount,
  showSuccess,
  setShowSuccess,
  isLoading,
  unit,
}) => {
  const handleShowSuccess = () => {
    setShowSuccess(!showSuccess);
  };
  const handleBidActions = () => {
    if (actionType == "Award") {
      handleAwardBid();
    } else {
      handleArchiveBid();
    }
    return;
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={setOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center mb-4 mt-3">
            <h1 className="text-xl mb-4">Confirm action</h1>

            {actionType == "Award" ? (
              <p className="font-light">
                You are about to award this auction to this profile. Kindly
                confirm this action to proceed
              </p>
            ) : (
              <p className="font-light">
                You are about to archive this auction. Kindly confirm this
                action to proceed
              </p>
            )}
          </div>
          <div className="flex justify-between mt-12">
            <Button color="neutral" onClick={() => setOpen(!open)}>
              Cancel
            </Button>
            <Button onClick={handleBidActions}>
              {isLoading ? "Processing..." : "Confirm"}
            </Button>
          </div>
        </Box>
      </Modal>

      <div>
        <AwardBidSuccessModal
          open={showSuccess}
          amount={amount}
          name={name}
          actionType={actionType}
          handleShowSuccess={handleShowSuccess}
          unit={unit}
        />
      </div>
    </div>
  );
};
