import * as React from "react";

const SvgSwitchOn = (props) => (
  <svg
    width={37}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={36} height={18} rx={9} fill="#6166B6" />
    <g clipPath="url(#switchOn_svg__a)" filter="url(#switchOn_svg__b)">
      <rect
        x={18.818}
        y={1.636}
        width={14.727}
        height={14.727}
        rx={7.364}
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="switchOn_svg__a">
        <path
          fill="#fff"
          transform="translate(18.818 1.636)"
          d="M0 0h14.727v14.727H0z"
        />
      </clipPath>
      <filter
        id="switchOn_svg__b"
        x={15.546}
        y={0}
        width={21.272}
        height={21.273}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.636} />
        <feGaussianBlur stdDeviation={1.636} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.137255 0 0 0 0 0.0431373 0 0 0 0.2 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_977_8505"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_977_8505"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgSwitchOn;
