import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { checkPermission } from "shared/utility";
import { Button } from "@ui";
import { AddIcon } from "@ui/icons";
import { getBox } from "@shared/utility";
import Map from "./map";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllMines,
  fetchPaginatedMines,
  setSearchValue
} from "@slices/miners/mines-slice";
import { toast } from "react-toastify";

import Table from "components/Table";
import { mineTableInfo } from "constants/index";

const Mines = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading, mines, pagination, search } = useSelector(
    (state) => state.mines
  );
  const [mine, setMine] = useState({});
  const handleClose = () => setOpen(false);

  const pole = getBox(mine?.location?.latitude, mine?.location?.longitude);
  const [filterParams, setFilter] = useState("");
  const [open, setOpen] = useState(false);
  const handleViewDetails = (mine) => {
    setMine(mines.find((m) => m._id === mine._id));
    setOpen(true);
  };

  useEffect(() => {
    if (location.state && location.state.message) {
      toast.success(location.state.message);
    }
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedMines({
        page: pagination[option + "Page"],
        per_page: pagination.limit,
        search: search,
        filter: "",
      })
    );
  };
  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedMines({
        page: 1,
        per_page: e.target.value,
        search: search,
        filter: "",
      })
    );
  };
  const handleSearch = (e) => {
    if (!e.target.value) {
      dispatch(
        fetchPaginatedMines({
          page: 1,
          per_page: pagination.limit,
          search: search,
          filter: filterParams,
        })
      );
    }else {
      dispatch(setSearchValue(e.target.value));
    }
  };
  
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(fetchAllMines());
    }
    dispatch(setSearchValue(search));
    dispatch(
      fetchPaginatedMines({
        page: 1,
        per_page: pagination.limit,
        search: search,
        filter: filterParams,
      })
    );
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    setFilter(tab);
    if (tab == "All") {
      if (search == "") {
        dispatch(fetchAllMines());
      } else {
        dispatch(
          fetchPaginatedMines({
            page: 1,
            per_page: pagination.limit,
            search: search,
            filter: "",
          })
        );
      }
    } else {
      dispatch(
        fetchPaginatedMines({
          page: 1,
          per_page: pagination.limit,
          search: search,
          filter: tab,
        })
      );
    }
  };

  return (
    <div className="mt-2">
      <div className="w-40">
        <Button
          onClick={() => navigate("/miner/mines/add")}
          disabled={checkPermission("Mines")}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Add new mine
        </Button>
      </div>

      {mines && mines.length > 0 && (
        <div className="w-full mt-3">
          <div className="h-96 my-4 ">
            <Map
              location={{
                address: mines[0].location.address,
                lat: mines[0].location.latitude,
                lng: mines[0].location.longitude,
              }}
              zoomLevel={10}
              mines={mines}
            />
          </div>
        </div>
      )}
      <div className="mt-4 flex overflow-x-auto scrollbar-hide gap-3">
        <div className="min-w-[1024px] lg:w-full overflow-x-hidden ">
          <Table
            tableInfo={mineTableInfo}
            isViewDetails={true}
            isStatus={true}
            viewDetailsAction={handleViewDetails}
            handleViewDetails={handleViewDetails}
            navigate={navigate}
            data={mines}
            setMine={setMine}
            handleTabChange={handleTabChange}
            pagination={pagination}
            handleSetPerPage={handleSetPerPage}
            handleSearch={handleSearch}
            handleSearchSubmit={handleSearchSubmit}
            handleChangePage={handleChangePage}
            loading={loading}
            fetchDefaultData={fetchAllMines}
            search={search}
          />
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute top-2/4 left-2/4 -translate-y-1/2 -translate-x-1/2 w-auto outline-0 bg-white shadow-lg px-8 py-10 capitalize">
          <span
            className="icon-close absolute right-5 top-4"
            onClick={handleClose}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.92 8.42L15.5 7L11.96 10.55L8.42 7L7 8.42L10.55 11.96L7 15.5L8.42 16.92L11.96 13.37L15.5 16.92L16.92 15.5L13.37 11.96L16.92 8.42Z"
                fill="#1C2E45"
                fillOpacity="0.6"
              />
            </svg>
          </span>
          <h2 className="flex lg:flex flex-rols-1 gap-3">Mine details</h2>
          <div id="modal-modal-description" className="mt-2">
            <div className="grid grid-cols-6 gap-12 mb-6">
              <div className="col-span-3 flex flex-col">
                <span className="font-light text-ts text-sm">Mine name</span>
                <span className="text-light">{mine?.mine_name}</span>
              </div>
              <div className="col-span-2 flex flex-col">
                <span className="font-light text-ts text-sm">Mine type</span>
                <span className="text-light">{mine?.mine_type}</span>
              </div>
            </div>
            <div className="mb-6">
              <span className="font-light text-ts text-sm block">
                Description
              </span>
              <span className="text-light">{mine?.description}</span>
            </div>
            <div className="grid grid-cols-6 gap-10 mb-6">
              <div className="col-span-3 flex flex-col">
                <span className="font-light text-ts text-sm">Company name</span>
                <span className="text-light">{mine?.company_name}</span>
              </div>
              <div className="col-span-2 flex flex-col">
                <span className="font-light text-ts text-sm">Address</span>
                <span className="text-light">{mine?.location?.address}</span>
              </div>
            </div>
            <div className="grid grid-cols-6 gap-12 mb-6">
              <div className="col-span-3 flex flex-col ">
                <span className="font-light text-ts text-sm">Location</span>
                <span className="text-light">
                  {pole.north.toFixed(2)}&deg;N
                  {pole.west.toFixed(2)}&deg;W
                </span>
              </div>
              <div className="col-span-1 flex flex-col normal-case">
                <span className="font-light text-ts text-sm whitespace-nowrap">
                  Reserves (in mT)
                </span>
                <span className="text-light">{mine?.reserves} mT</span>
              </div>
            </div>

            <div className="mb-4">
              <span className="font-light text-ts text-sm">Mineral type</span>
              <span className="flex gap-2">
                {mine?.mineral_type?.map((mineral, index) => (
                  <span
                    key={index}
                    className="px-2 py-1 rounded whitespace-nowrap text-[#6166B6] bg-primary-light font-extralight text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease"
                  >
                    {mineral?.name}
                  </span>
                ))}
              </span>
            </div>
            <div>
              <span className="font-light text-ts text-sm">SDG Goals</span>
              <div className="flex gap-2">
                {mine?.sdg_goals?.map((sdg) => (
                  <div key={sdg?._id} className="max-w-[50px]">
                    <img src={sdg?.image_url} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Mines;
