/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ControlledModal } from "@ui";
import { useDispatch, useSelector } from "react-redux";
import Table from "components/Table";
import { toast } from "react-toastify";
import { adminInteractionTendersTable } from "constants/index";
import { checkPermission } from "shared/utility";
import {
  getTenders,
  fetchPaginatedTenders,
  setTenderSearchValue,
  postArchivetender,
} from "store/slices/admin-slice";
// import NoProduct from "@components/inventory/NoProduct";

const Tenders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [tenderID, setTenderID] = useState();

  const handleClose = () => setOpen(false);

  const archiveTender = async () => {
    setIsLoading(true);
    let body = {
      status: "archived",
      content: "tender archived",
    };
    try {
      const response = await dispatch(
        postArchivetender({ id: tenderID, body: body })
      );
      if (response.payload.status === 200 || response.payload.status === 201) {
        if (
          response.payload.data.code == 422 ||
          response.payload.data.code == 400
        ) {
          toast.error(response.payload.data.message);
          setIsLoading(false);
        } else {
          toast.success(response.payload.data.message);
          setOpen(false);
          setIsLoading(false);
        }
      } else {
        setOpen(false);
        setIsLoading(false);
        console.log("error from then", response);
      }
    } catch (error) {
      //   toast.error(error);
      // console.log("error", error);
    }
  };

  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedTenders({
        page: pagination[option],
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedTenders({
        page: 1,
        per_page: e.target.value,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(setTenderSearchValue(e.target.value));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(getTenders());
    }
    dispatch(setTenderSearchValue(search));
    dispatch(
      fetchPaginatedTenders({
        page: 1,
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    if (tab == "All") {
      dispatch(getTenders());
    } else {
      dispatch(
        fetchPaginatedTenders({
          page: 1,
          per_page: pagination.limit,
          search: search,
          filter: tab,
        })
      );
    }
  };

  const actions = {
    toggle: {
      fields: ["View Details", "Archive"],
      action: (
        option,
        id
        // status
      ) => {
        // if (toggleOptions[option] === true) {
        if (option === "View Details") {
          navigate(`/admin/interactions/tenders/details/${id}`);
        } else if (option === "Archive") {
          setOpen(true);
          setTenderID(id);
        }
      },
    },
  };

  const {
    loading,
    tenders: { products, pagination, search, filter },
  } = useSelector((state) => state.admin);

  return (
    <>
      <ControlledModal
        isOpen={open}
        handleClose={handleClose}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
      >
        <p className="text-head text-center font-semibold text-[22px]">
          Archive tender?
        </p>
        <p className="text-lightgray text-center font-light text-base mt-2">
          Are you sure you want to archive this tender?
        </p>
        <div className="flex flex-row justify-center gap-4 mt-6">
          <Button
            type="button"
            variant="text"
            color="neutral"
            onClick={() => setOpen(!open)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            disabled={checkPermission("Interaction")}
            variant="contained"
            color="danger"
            onClick={() => {
              archiveTender();
            }}
          >
            {isLoading ? "Processing..." : "Yes, archive"}
          </Button>
        </div>
      </ControlledModal>
      <div className="mt-4 sm:flex overflow-x-auto scrollbar-hide">
        <Table
          tableInfo={adminInteractionTendersTable}
          actions={actions}
          isToggle={true}
          isStatus={true}
          navigate={navigate}
          data={products}
          pagination={pagination}
          handleSetPerPage={handleSetPerPage}
          handleSearchSubmit={handleSearchSubmit}
          handleChangePage={handleChangePage}
          handleTabChange={handleTabChange}
          loading={loading}
          handleSearch={handleSearch}
          fetchDefaultData={getTenders}
          search={search}
        />
      </div>{" "}
    </>
  );
};

export default Tenders;
