/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { ControlledModal, Button as ModalButton } from "@ui";
import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Input } from "@ui";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { postFundWallet } from "@slices/wallet-slice";
import { getProfile,fetchAllNotifications } from "@slices/settings-slice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      border: "1px solid red",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const FundWallet = ({ setOpen, open = null }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  let [amountData, setData] = useState({
    amount: 0,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }
    const user = JSON.parse(localStorage.getItem("user"));

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: user.name,
        email: user.email,
      },
    });

    if (!result?.paymentMethod) {
      toast.error(result.error.message);
      setLoading(false);
      setOpen(!open);
    }

    const data = {
      payment_method_id: result.paymentMethod.id,
      amount: amountData.amount,
    };
    const paymentData = await dispatch(postFundWallet(data));

    if (paymentData?.payload.status == 201) {
      if (paymentData.payload.code == 422) {
        toast.error(paymentData.payload.message);
        setOpen(!open);
        setLoading(false);
      } else {
        dispatch(getProfile());
        dispatch(fetchAllNotifications({limit:10}));
        setLoading(false);
        setOpen(!open);
        toast.success(paymentData.payload?.data.message);
      }
    } else {
      setLoading(false);
      setOpen(!open);
      toast.error(paymentData.data.message);
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center mb-4">
            <h1 className="mb-4 black_btn">Pay with Stripe</h1>
            <p className="font-light mt-4">
              You have decided to fund your Daniola Wallet. Enter your correct
              card details below to fund.
            </p>
            <div className="max-w-lg paragraph mb-5">
              <label
                className="block mb-2  text-[#1B2B41B8] font-light"
                htmlFor="card_details"
              >
                Card details
              </label>
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
            <div className="max-w-lg paragraph">
              <Input
                label="Amount (USD)"
                id="amount"
                type="text"
                onChange={handleInputChange}
                name="amount"
              />
            </div>
            <div className="flex items-center justify-between mt-8">
              {/* <button
                onClick={() => setOpen(!open)}
                className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
              >
                Cancel
              </button> */}
              <div>
                <Button
                  onClick={handleSubmit}
                  styleClass="fund-btn"
                  disabled={!stripe}
                >
                  {loading ? "Processing..." : "Fund Wallet"}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default FundWallet;
