import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

export default function LinearDeterminate(prop) {
  let value = Math.floor(prop.value?.shipment?.progress);
  // const [progress, setProgress] = React.useState(0);

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       if (oldProgress === 78) {
  //         return 0;
  //       }
  //       const diff = Math.random() * 10;
  //       return Math.min(oldProgress + diff, 100);
  //     });
  //   }, 500);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return (
    <div>
      <div className="mt-3 flex">
        <span className=" font-light text-gray">Tracking ID:</span>
        <p className="font-light mx-1 text-primary"> &nbsp; {prop?.value?.shipment?.tracking_id}</p>
      </div>

      <Box className="flex flex-row justify-between items-center mb-5 mt-2">
        <p className="text-base font-medium text-primary ">
          {prop?.value?.pickup?.address} <br></br>
        </p>
        <p className="text-base font-medium text-primary">
          {prop?.value?.dropoff?.city} <br></br>
          {/* <span className="font-light text-base ml-2">{prop.value.dropoff.country}</span> */}
        </p>
      </Box>

      <LinearProgress
        sx={{
          backgroundColor: "#EFF0FE",
          "& .MuiLinearProgress-bar1Determinate": {
            backgroundColor: "#6166B6",
          },
        }}
        variant="determinate"
        value={value}
      />
    </div>
  );
}
