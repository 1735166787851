import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//eslint-disable-next-line
import { Button } from "@ui";
import { AddIcon } from "@ui/icons";
/* eslint-disable no-unused-vars */
import {
  deleteOffering,
  fetchPaginatedOfferings,
  getOfferings,
  setDeletedOfferingSub,
  setToggledOfferingSub,
  setSearchValue,
} from "store/slices/vendors/offerings-slice";

import { useDispatch, useSelector } from "react-redux";

import DeleteOffering from "./deleteOffering";
import { checkPermission } from "shared/utility";
import Table from "components/Table";
import { offeringsTableInfo } from "constants/index";

const Offerings = () => {
  const { offerings, pagination, search, loading } = useSelector(
    (store) => store.vendorOfferings
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [offering_name, setOffering] = useState();
  const [isOpen, setOpen] = useState(false);

  const handleDelete = useCallback(async () => {
    setOpen(close);

    await dispatch(deleteOffering());
    await dispatch(getOfferings());
  }, []);

  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedOfferings({
        page: pagination[option],
        per_page: pagination.limit,
        search: search,
      })
    );
  };

  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedOfferings({
        page: 1,
        per_page: e.target.value,
        search: search,
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(setSearchValue(e.target.value));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(getOfferings());
    }
    dispatch(setSearchValue(search));
    dispatch(
      fetchPaginatedOfferings({
        page: 1,
        per_page: pagination.limit,
        search: search,
      })
    );
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const actions = {
    toggle: {
      fields: ["Modify", "View details"],
      action: (option, id) => {
        if (option === "Modify") {
          navigate(`modify/${id}`);
        } else if (option === "View details") {
          navigate(`details/${id}`);
        }
      },
    },
    delete: (sub,record) => {
      dispatch(setToggledOfferingSub(null));
      dispatch(setDeletedOfferingSub(sub));
      setOffering(record?.job_title);
      setOpen(true);
    },
  };
  useEffect(() => {
    dispatch(getOfferings());
  }, []);
  
  return (
    <div className="bg-white rounded-sm flex justify-center">
      <div className="w-full">
        {/* <Hero isVerified={isVerified} underReview={underReview} /> */}
        <div className="w-full my-[34.5px] pl-4">
          <Button
            onClick={() => navigate("add")}
            variant="contained"
            color="primary"
            disabled={checkPermission("Offerings")}
            startIcon={<AddIcon />}
          >
            Add new offering
          </Button>
        </div>
        {/* tabs */}
        {/* table */}
        <Table
          tableInfo={offeringsTableInfo}
          isViewDetails={false}
          isToggle={true}
          isStatus={false}
          navigate={navigate}
          data={offerings}
          pagination={pagination}
          handleSetPerPage={handleSetPerPage}
          handleSearch={handleSearch}
          handleSearchSubmit={handleSearchSubmit}
          handleChangePage={handleChangePage}
          loading={loading}
          search={search}
          fetchDefaultData={getOfferings}
          actions={actions}
          isDelete={true}
        />
      </div>
      {isOpen && (
        <div className="top-0 bg-black bg-opacity-20 absolute h-full w-full"></div>
      )}
      {isOpen && (
        <div className="absolute top-44 w-full flex justify-center">
          <div className="bg-white rounded-lg py-8 w-1/2 flex justify-center ">
            <div className="w-3/4">
              <DeleteOffering offering={offering_name}  />
              <div className="flex justify-center gap-8 mt-8">
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  color="neutral"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="danger"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Offerings;
