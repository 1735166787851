/* eslint-disable no-unused-expressions */
import { useContext } from "react";
import { TermsAndPrivacyContext } from "context/TermsAndPrivacyContext";


TermsAndPrivacyContext
const Footer = () => {
  const { setShowTerms, setShowPrivacy } = useContext(TermsAndPrivacyContext)

  return (
    <footer className="p-8 text-xs font-light">
      <p className="text-[#1C304A80]">&copy; 2022 Daniola Corp, Canada.</p>
      <div className="flex items-center gap-2 text-primary">
        <p>Refund Policy</p>
        <span className="h-[3px] w-[3px] rounded-full bg-black"></span>

        <p role="button" onClick={() => setShowTerms(true)}>Terms of Service</p>
        <p role="button" onClick={() => setShowPrivacy(true)}>Privacy Policy</p>
      </div>
    </footer>
  );
};

export default Footer;
