/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { Select, Input } from '@ui';
import ErrorMessage from 'components/ErrorMessage';

const InvestmentDetails = ({ errors, register, handleSelectChange,investment_type,production_phase }) => {
	const { seekFundingData } = useSelector((store) => store?.investors);
	return (
		<div className="text-default  w-[896px] h-[798px] bg-white px-[34px]">
			<div className="mb-8">
				<p className="flex-grow-0 flex-shrink-0 text-[22px] mb-2 text-left text-[#192434]">
					Investment details
				</p>
				<p className="flex-grow-0 flex-shrink-0 w-[796px] text-base font-light text-left text-[#1b2b41]/[0.72]">
					{' '}
					State the details of the mineral and terms of investment.
				</p>
			</div>

			<p className="flex-grow-0 flex-shrink-0 text-lg text-left mt-8 text-black">
				Terms of investment
			</p>

			<div className="mt-4">
				<Select
					name="production_phase"
					label="Production phase to fund"
					options={
						production_phase
							? Object.keys(production_phase).map((key) => {
									return {
										name: production_phase[key],
										slug: key
									};
							  })
							: []
					}
					onChange={handleSelectChange}
				/>
				{errors.production_phase && (
					<ErrorMessage>{errors.production_phase?.message}</ErrorMessage>
				)}
			</div>
			<div className="mt-4">
				<Select
					name="investment_type"
					label="Investment type"
					options={
						investment_type
							? Object.keys(investment_type).map((key) => {
									return {
										name: investment_type[key],
										slug: key
									};
							  })
							: []
					}
					onChange={handleSelectChange}
				/>
				{errors.production_phase && (
					<ErrorMessage>{errors.investment_type?.message}</ErrorMessage>
				)}
			</div>

			<div className="grid grid-cols-2 mt-4 gap-x-4">
				<div>
					<Input
						label="Investment duration"
						id="duration"
						type="text"
						placeholder="2 years"
						name="duration"
						{...register('duration')}
					/>
					{errors.duration && (
						<ErrorMessage>{errors.duration?.message}</ErrorMessage>
					)}
				</div>

				<div>
					<Input
						label="Investment amount"
						id="amount"
						type="text"
						placeholder="200 000"
						name="amount"
						{...register('amount')}
						isMoneyInput
					/>
					{errors.investment_amount && (
						<ErrorMessage>{errors.amount?.message}</ErrorMessage>
					)}
				</div>
			</div>
		</div>
	);
};

export default InvestmentDetails;
