/* eslint-disable react/prop-types */
import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import dayjs from "dayjs";

// eslint-disable-next-line react/prop-types
export default function OppositeContentTimeline({ active, tracking }) {
  return (
    <>
      <Timeline
        align="alternate"
        style={{ padding: "0", marginBottom: "12px" }}
      >
        {tracking?.data?.events.map((shipment,index) => (
          <TimelineItem key={index + 1}>
            <TimelineOppositeContent>
              <p className="text-sm text-gray font-semibold">
                {dayjs(shipment?.checkpoint_date).format("MMMM D, YYYY")}
              </p>
              <p className="text-sm text-gray">
                ({dayjs(shipment?.checkpoint_date).format("h:mm A")})
              </p>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
                className={` ${active ? "bg-primary" : "bg-gray"}  `}
              ></TimelineDot>
              { shipment.checkpoint_delivery_status !== 'delivered' && (
                <TimelineConnector />
              )}
              
            </TimelineSeparator>
            <TimelineContent>
              <p className="text-sm heading font-bold">
                {shipment?.tracking_detail}
              </p>
              <p className="text-sm text-gray">{shipment?.location}</p>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
}
