/* eslint-disable react/no-children-prop */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCarrier } from "@slices/miners/interaction-slice";
import { getTransaction } from "store/slices/miners/transaction-slice";
import { TableEmptyState } from "@ui";

import { toast } from "react-toastify";
import Pending from "components/transactions/Pending";
import Poc from "components/transactions/Poc";
import Intransit from "components/transactions/Intransit";

const TransactionInfo = () => {
  const [inventoryStatus, setInventoryStatus] = useState("Pending");
  let { id } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTransaction(id));
    dispatch(getCarrier(), (error) => {
      toast.error(error);
    });
  }, [dispatch]);

  const {
    loading,
    transaction: { status = [], ...rest },
  } = useSelector((state) => state.interactions);

  useEffect(() => {
    setInventoryStatus(status[status.length - 1]?.status);
  }, [status]);

  return !loading ? (
    <>
      {inventoryStatus === "Pending" && (
        <Pending
          transaction={rest}
          status={inventoryStatus}
          transactionId={id}
        />
      )}

      {/* Upload POP Section */}
      {inventoryStatus === "Awaiting POP" && (
        <Poc transaction={rest} status={inventoryStatus} transactionId={id} />
      )}
      {inventoryStatus === "Awaiting POF" && (
        <Poc transaction={rest} status={inventoryStatus} transactionId={id} />
      )}
      {inventoryStatus === "Awaiting POC" && (
        <Poc transaction={rest} status={inventoryStatus} transactionId={id} />
      )}
      {/* Intransit Section */}
      {inventoryStatus === "In Transit" && (
        <Intransit
          status={inventoryStatus}
          transactionId={id}
          transaction={rest}
        />
      )}
      {/* Delivered Section */}
      {inventoryStatus === "Delivered" && (
        <Intransit
          status={inventoryStatus}
          transactionId={id}
          transaction={rest}
        />
      )}
      {/* Completed Section */}
      {inventoryStatus === "Completed" && (
        <Intransit
          status={inventoryStatus}
          transactionId={id}
          transaction={rest}
        />
      )}
    </>
  ) : (
    <TableEmptyState loading={loading} text="Status" />
  );
};

export default TransactionInfo;
