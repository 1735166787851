import React, { useEffect, useState } from "react";

import PaperClip from "@ui/icons/PaperClip";

import { useDispatch, useSelector } from "react-redux";
import { manageUser, postVerifyProfile } from "@slices/admin-slice";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { useLocation, useNavigate } from "react-router-dom";
import { Card, Button, Chips, TextArea, ControlledModal } from "@ui";

const ManageUser = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [feedback, setFeedback] = useState(false);
  const [minerAccount, setMinerAccount] = useState(false);

  const [vendorAccount, setVendorAccount] = useState(false);
  const [offTakerAccount, setOffTakerAccount] = useState(false);

  const { userProfile } = useSelector((state) => state.admin);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    setFeedback(false);
    if (e.target.value === "declined") {
      setFeedback(true);
    }
  };

  const selectAction = () => {
    if(userProfile?.form.form_content == null) {
      toast.error("Please ask user to fulfill the required documents to process the 'approval request'");
    }else {
      setOpen(true);
    }
  
  }

  const id = location.state.profile_id;
  const role = location.state.role;
  useEffect(() => {
    if (role === "Miner") {
      setMinerAccount(true);
    } else if (role === "Vendor") {
      setVendorAccount(true);
    } else if (role === "OffTaker") {
      setOffTakerAccount(true);
    }
  }, [role]);

  useEffect(() => {
    if (id) {
      dispatch(manageUser(id));
    }
  }, [id, dispatch]);

  const handleSubmit = async () => {
    const profile = {
      id,
      role,
      selectedValue,
    };
    dispatch(postVerifyProfile(profile))
      .then((res) => {
        // eslint-disable-next-line no-empty
        if (res.payload.status === 201) {
          navigate("/admin/approval-requests");
        } else {
          toast.error("there is an error");
        }
      })
      .catch((err) => {
        return toast.error(err.message);
      });
  };

  return (
    <>
      <div>
        <ControlledModal
          isOpen={open}
          handleClose={handleClose}
          className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[85%] lg:w-[25%]"
        >
          <div className="p-10">
            <p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434] mb-6">
              Update approval status
            </p>
            <div className="mb-8 xl:w-full">
              <label
                htmlFor={"Update approval status:"}
                className="form-label inline-block mb-2 font-light text-base text-[#1B2B41B8]"
              >
                Update approval status
              </label>
              <div className="relative flex items-center">
                <select
                  value={selectedValue}
                  onChange={handleChange}
                  type="dropdown"
                  className={`w-full h-[42px] max-h-[42px] p-[10px] border-none font-light text-gray-700 bg-[#1A38601A] rounded z-0 focus:shadow focus:outline-none focus:ring-primary  placeholder:text-[#1C345442] placeholder:font-extralight placeholder:text-base`}
                >
                  <option value="">-Select approval status</option>
                  <option value="verified">Approved</option>
                  <option value="blocked">Declined</option>
                </select>
              </div>
            </div>
            {feedback && (
              <div>
                <p className="form-label inline-block  font-light text-base text-[#1B2B41B8]">
                  Kindly provide additional notes to explain to user the reason
                  for declining their profile verification request
                </p>
                <TextArea />
              </div>
            )}

            <div className="flex items-center justify-end">
              <Button size="small" onClick={handleSubmit}>
                Update
              </Button>
            </div>
          </div>
        </ControlledModal>
      </div>
      <Card>
        <div className="max-w-[796px] lg:ml-[70px]">
          <div className="flex flex-row justify-between">
            <h2 className="text-heading text-2xl my-2">View Details</h2>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => selectAction()}
            >
              Select action
            </Button>
          </div>

          <div className="my-6">
            <h3 className="my-2 text-lg text-heading">Basic Information</h3>

            <div className="grid grid-cols-4 gap-y-4">
              <div className="col-span-1 font-light text-ts">
                <p>User ID</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{userProfile?.user?._id?.slice(0, 8).toUpperCase()}</p>
              </div>
              <div className="col-span-1 font-light text-ts">
                <p>Name</p>
              </div>
              <div className="col-span-3 font-light text-light capitalize">
                <p>{userProfile?.user?.name}</p>
              </div>
              <div className="col-span-1 font-light text-ts">
                <p>Phone number</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{` +${userProfile?.user?.phone?.code} ${userProfile?.user?.phone?.number}`}</p>
              </div>
            </div>
            <h3 className="my-6 text-lg text-heading">
              Documents and Additional Information
            </h3>

            <div className="grid grid-cols-4 gap-y-4">
              {minerAccount && (
                <div className="col-span-1 font-light text-ts">
                  <p>Export license</p>
                </div>
              )}
              {minerAccount && (
                <div className="col-span-3 font-light text-light">
                  {userProfile?.form?.form_content?.export_license_certificate?.map(
                    (file) => (
                      <a
                        key={file.id}
                        download
                        target="_blank"
                        rel="noreferrer"
                        href={file.url}
                        className=" flex items-center  text-xs"
                      >
                        <PaperClip />
                        <span className="text-primary text-sm font-light ml-2">
                          {file.name}
                        </span>
                      </a>
                    )
                  )}
                </div>
              )}

              {minerAccount && (
                <div className="col-span-1 font-light text-ts">
                  <p>Geological Report license</p>
                </div>
              )}
              {minerAccount && (
                <div className="col-span-3 font-light text-light">
                  {userProfile?.form?.form_content?.geological_report_certificate?.map(
                    (file) => (
                      <a
                      key={file.id}
                      download
                      target="_blank"
                      rel="noreferrer"
                      href={file.url}
                      className=" flex items-center  text-xs"
                    >
                      <PaperClip />
                      <span className="text-primary text-sm font-light ml-2">
                        {file.name}
                      </span>
                    </a>
                    )
                  )}
                </div>
              )}

              {minerAccount && (
                <div className="col-span-1 font-light text-ts">
                  <p>Mining license</p>
                </div>
              )}
              {minerAccount && (
                <div className="col-span-3 font-light text-light">
                  {userProfile?.form?.form_content?.mining_license_certificate?.map(
                    (file) => (
                      <a
                      key={file.id}
                      download
                      target="_blank"
                      rel="noreferrer"
                      href={file.url}
                      className=" flex items-center  text-xs"
                    >
                      <PaperClip />
                      <span className="text-primary text-sm font-light ml-2">
                        {file.name}
                      </span>
                    </a>
                    )
                  )}
                </div>
              )}
              {minerAccount && (
                <div className="col-span-1 font-light text-ts">
                  <p>Tax license</p>
                </div>
              )}
              {minerAccount && (
                <div className="col-span-3 font-light text-light">
                  {userProfile?.form?.form_content?.tax_clearance_certificate?.map(
                    (file) => (
                      // eslint-disable-next-line react/jsx-key
                      <a
                        key={file.id}
                        download
                        target="_blank"
                        rel="noreferrer"
                        href={file.url}
                        className=" flex items-center  text-xs"
                      >
                        <PaperClip />
                        <span className="text-primary text-sm font-light ml-2">
                          {file.name}
                        </span>
                      </a>
                    )
                  )}
                </div>
              )}
              {offTakerAccount && (
                <div className="col-span-1 font-light text-ts">
                  <p>Off taker verification</p>
                </div>
              )}
              {offTakerAccount && (
                <div className="col-span-3 font-light text-light">
                  {userProfile?.form?.form_content?.files?.map((file) => (
                    // eslint-disable-next-line react/jsx-key
                    <a
                        key={file.id}
                        download
                        target="_blank"
                        rel="noreferrer"
                        href={file.url}
                        className=" flex items-center  text-xs"
                      >
                        <PaperClip />
                        <span className="text-primary text-sm font-light ml-2">
                          {file.name}
                        </span>
                      </a>
                  ))}
                </div>
              )}
              <div className="col-span-1 font-light text-ts">
                <p>Company name</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{userProfile?.form?.form_content?.company_name}</p>
              </div>
              {minerAccount && (
                <div className="col-span-1 font-light text-ts">
                  <p>Mine name</p>
                </div>
              )}
              {minerAccount && (
                <div className="col-span-3 font-light text-light">
                  <p>{userProfile?.form?.form_content?.mine_name}</p>
                </div>
              )}
              {vendorAccount && (
                <div className="col-span-1 font-light text-ts">
                  <p>Industry name</p>
                </div>
              )}
              {vendorAccount && (
                <div className="col-span-3 font-light text-light">
                  <p>{userProfile?.form?.form_content?.industry_name}</p>
                </div>
              )}
              <div className="col-span-1 font-light text-ts">
                <p>Description</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{userProfile?.form?.form_content?.description}</p>
              </div>
              {minerAccount && (
                <div className="col-span-1 font-light text-ts">
                  <p>Mine type</p>
                </div>
              )}

              {minerAccount && (
                <div className="col-span-3 font-light text-light">
                  <p>{userProfile?.form?.form_content?.mine_type}</p>
                </div>
              )}
              {minerAccount && (
                <div className="col-span-1 font-light text-ts">
                  <p>Revenue</p>
                </div>
              )}
              {minerAccount && (
                <div className="col-span-3 font-light text-light">
                  <p>{userProfile?.form?.form_content?.revenue}</p>
                </div>
              )}
              <div className="col-span-1 font-light text-ts">
                <p>Address</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{userProfile?.form?.form_content?.location?.address}</p>
              </div>
              <div className="col-span-1 font-light text-ts">
                <p>Country</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{userProfile?.form?.form_content?.country?.name}</p>
              </div>
              <div className="col-span-1 font-light text-ts">
                <p>City</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{userProfile?.form?.form_content?.city} </p>
              </div>
              <div className="col-span-1 font-light text-ts">
                <p>State</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{userProfile?.form?.form_content?.state}</p>
              </div>
              <div className="col-span-1 font-light text-ts">
                <p>Date</p>
              </div>
              <div className="col-span-3 font-light text-light">
                {/* { userProfile?.form?.form_content?.files ? dayjs(userProfile?.form?.form_content?.files[0].created_at).format("MMMM D, YYYY") : "" } */}
                <p>
                  {userProfile?.form?.form_content ? dayjs(userProfile?.account?.updated_at).format("MMMM D, YYYY") : ""}
                </p>
              </div>
              {minerAccount && (
                <div className="col-span-1 font-light text-ts">
                  <p>Minerals</p>
                </div>
              )}
              {minerAccount && (
                <div className="col-span-3 font-light text-light">
                  <p className="flex flex-row flex-wrap gap-2">
                    <Chips
                      chips={userProfile?.form?.form_content?.minerals}
                      noIcons
                    />
                  </p>{" "}
                </div>
              )}
              {minerAccount && (
                <div className="col-span-1 font-light text-ts">
                  <p>SDG Goals</p>
                </div>
              )}
              {minerAccount && (
                <div className="col-span-3 font-light text-light">
                  <p className="flex flex-row flex-wrap gap-2">
                    <Chips
                      chips={userProfile?.form?.form_content?.sdg_goals}
                      noIcons
                    />
                  </p>
                </div>
              )}
            </div>

            {/* <div className="flex justify-end  mt-20">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => handleOpen()}
              >
                Select action
              </Button>
            </div> */}
          </div>
        </div>
      </Card>
    </>
  );
};

export default ManageUser;
