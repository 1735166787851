/* eslint-disable no-unused-vars */
import React from "react";
import { Btn as Button } from "@atoms/button/button";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getInitials } from "@shared/utility";
import { getInvestmentOfferDetails } from "@slices/miners/investor-slice";
import SkeletonComponent from "components/investors/Skeleton";
import { priceSplitter } from "shared/utility";
import { checkPermission } from "shared/utility";

const OfferingDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state;
  const dispatch = useDispatch();
  const { investmentOfferDetails: offer, loading } = useSelector(
    (store) => store?.investors
  );

  React.useEffect(() => {
    dispatch(getInvestmentOfferDetails(id));
  }, [dispatch]);

  return Object.keys(offer).length == 0 ? (
    <SkeletonComponent />
  ) : (
    <div className="text-default h-full bg-white px-[34px] py-[54px]">
      <div className="flex flex-col lg:flex-row justify-between">
        {/* <div className="flex flex-col justify-start items-start  left-[23px] top-[23px] gap-4"> */}
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 w-[206px] h-[66px]">
          <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
            {offer?.user_image !== null && offer?.user_image ? (
              <img
                className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                src={`${offer?.user_image?.url}`}
                alt=""
              />
            ) : (
              `${getInitials(offer?.investor?.name)}`
            )}
            {/* {`${offer?.investor?.name[0]}`} */}
          </div>
          <div className="flex flex-col justify-start items-start flex-grow-0 ml-4 flex-shrink-0 ">
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-base text-left text-primary">
              {offer?.investor?.name}
            </p>
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-sm text-left text-[#18273a]/[0.94]">
              {offer?.investor?.company}
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-sm font-light text-left text-[#1b2b41]/[0.72]">
              {offer?.investor?.country?.name}
            </p>
          </div>
        </div>

        <div className="hidden lg:block">
          <Button
            disabled={checkPermission("Investment")}
            onClick={() =>
              navigate("/miner/investors/offerings/apply", {
                state: {
                  id: offer?._id,
                },
              })
            }
            size="small"
          >
            Apply to offering
          </Button>
        </div>
      </div>

      <div className="flex flex-col justify-start items-start flex-grow-0 mt-6  flex-shrink-0">
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Mineral:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {offer?.mineral?.name}
          </p>
        </div>
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Production phase:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {offer?.production_phase?.slug}
          </p>
        </div>
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Investment type:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {offer?.investment_type?.slug}
          </p>
        </div>
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Investment amount:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {`${priceSplitter(offer?.investment_amount)} USD`}
          </p>
        </div>
        <div className="flex flex-row justify-between mb-[15px]">
          <p className="min-w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
            Funding terms:
          </p>
          <p className="text-base font-light text-left text-[#18273a]/[0.94]">
            {offer?.funding_terms}
          </p>
        </div>
      </div>
      <div className="block lg:hidden w-full">
        <Button
          disabled={checkPermission()}
          onClick={() =>
            navigate("/miner/investors/offerings/apply", {
              state: {
                id: offer?.id,
              },
            })
          }
        >
          Apply to offering
        </Button>
      </div>
    </div>
  );
};

export default OfferingDetails;
