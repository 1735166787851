const LineChartIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 47.5V10H10V50H50V47.5H12.5Z"
        fill="#1C3454"
        fillOpacity="0.26"
      />
      <path
        d="M32.5 30L25 22.5L15 32.5V37.5L25 27.5L32.5 35L50 17.5V12.5L32.5 30Z"
        fill="#1C3454"
        fillOpacity="0.26"
      />
    </svg>
  );
};

export default LineChartIcon;
