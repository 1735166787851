/* eslint-disable react/prop-types */
import Spinner from "./Spinner";

const Button = ({
  children,
  variant = "contained",
  color = "primary",
  startIcon,
  endIcon,
  onClick,
  fullWidth,
  fontWeight = "font-normal",
  disabled = false,
  loading,
  styleClass = null,
  ...rest
}) => {
  const isDisabled = disabled ? "disabled" : "";
  const classes = [
    "button",
    variant,
    color,
    fontWeight,
    isDisabled,
    styleClass,
  ].join(" ");

  let content = (
    <>
      {startIcon && <div className="mr-1">{startIcon}</div>}
      {children}
      {endIcon && <div className="ml-1">{endIcon}</div>}
    </>
  );
  if (loading) {
    content = <Spinner />;
  }

  return (
    <button
      {...rest}
      disabled={disabled || loading}
      onClick={onClick}
      className={`${classes} ${fullWidth && "w-full"} rounded-lg`}
    >
      {content}
    </button>
  );
};
export default Button;
