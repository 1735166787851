import Button from "components/ui/Button";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as API from "@config/url";
import {
  setAddOfferingStep,
  vendorConfig,
} from "store/slices/vendors/offerings-slice";
import axios from "axios";
import { toast } from "react-toastify";

const Review = () => {
  const dispatch = useDispatch();
  const { offeringFormData, offeringActiveStep } = useSelector(
    (store) => store.vendorOfferings
  );
  const [loading, setLoading] = useState(false);

  const reviews = [
    { label: "Offering type", value: offeringFormData.type },
    { label: "Category", value: offeringFormData.category },
    { label: "Job title", value: offeringFormData.job_title },
    {
      label: "Description",
      value: offeringFormData.description,
    },
    { label: "Contract type", value: offeringFormData.type },
    { label: "Price ", value: offeringFormData.price },
  ];

  const handleSubmit = async () => {
    setLoading(true);
    try {

      const locations = offeringFormData.location.map(
        (location) => location.iso_code
      );
      let offeringPayload;

      if (offeringFormData.type === "Service") {
        const {
          // eslint-disable-next-line no-unused-vars
          ["location"]: location,
          // eslint-disable-next-line no-unused-vars
          ["agree"]: agree,
          // eslint-disable-next-line no-unused-vars
          ["quantity"]: quantity,
          ...offering
        } = offeringFormData;
        offeringPayload = offering;
        offeringPayload.duration =
          offeringPayload.duration[0] + " " + offeringPayload.duration[1];
      } else if (offeringFormData.type === "Commodity") {
        const {
          // eslint-disable-next-line no-unused-vars
          ["location"]: location,
          // eslint-disable-next-line no-unused-vars
          ["agree"]: agree,
          // eslint-disable-next-line no-unused-vars
          ["duration"]: duration,
          // eslint-disable-next-line no-unused-vars
          ["contract_type"]: contract_type,
          ...offering
        } = offeringFormData;
        offeringPayload = offering;
      }

      offeringPayload.location = locations;

      await axios(vendorConfig("post", API.addOffering, offeringPayload));
      setLoading(false);
      dispatch(setAddOfferingStep(2));
      toast.success("Offering added successfully");
      // setMessage(resp.data.message);
    } catch (error) {
      setLoading(false);
      toast.error(error);
      console.log(error);
    }
  };
  return (
    <div className=" mx-auto">
      <h2 className="text-heading text-2xl mb-2">Review</h2>
      <p className="text-ts font-light mt-2 mb-5">
        Confirm that the product details are correct before proceeding.
      </p>
      <div className="grid grid-cols-4 gap-y-4">
        {reviews.map((review, index) => (
          <Fragment key={index + 1}>
            <div className="col-span-1 font-light text-ts">
              <p>{review.label}:</p>
            </div>
            <div className="col-span-3 font-light text-light">
              <p>{review.value}</p>
            </div>
          </Fragment>
        ))}
      </div>
      <div className="grid grid-cols-4 gap-y-4 mt-3">
        <div className="col-span-1 font-light text-ts">
          <p>Locations:</p>
        </div>
        <div className="col-span-3 flex flex-wrap text-[#6166B6] font-light text-[14px]">
          {offeringFormData.location.map((option, index) => (
            <div
              key={index}
              className="bg-vendoroptionbg flex justify-between px-2 mr-5"
            >
              <span className="mr-2">{option.name}</span>
              {/* <span
                  className="cursor-pointer"
                  onClick={() => arrayHelpers.remove(index)}
                >
                  x
                </span> */}
            </div>
          ))}
        </div>
      </div>
      <div
        className={`flex ${
          offeringActiveStep === 0 ? "justify-end" : "justify-between"
        } mt-8`}
      >
        {offeringActiveStep > 0 && (
          <Button
            variant="contained"
            onClick={() => dispatch(setAddOfferingStep(0))}
            color="neutral"
          >
            Back
          </Button>
        )}
        <Button
          variant="contained"
          loading={loading}
          onClick={handleSubmit}
          color="primary"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default Review;
