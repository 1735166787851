/* eslint-disable react/prop-types */
import { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import Dot from "@ui/Dot";
import { ChevronUpIcon, ChevronDownIcon } from "@ui/icons";
import { UserContext } from "@context/index";

const MainLink = ({ href, icon, label, hasDot, setSideBar }) => (
  <NavLink
    className="flex items-center pl-8 pr-4 py-4 w-full gap-4"
    to={href}
    onClick={() => setSideBar(false)}
  >
    <span className="min-w-[24px]">{icon}</span>
    <span className="relative">
      {hasDot && <Dot />}
      {label}
    </span>
  </NavLink>
);

const CollapsedItem = ({
  icon,
  label,
  hasDot,
  subList,
  isCollapsed,
  setIsCollapsed,
}) => (
  <div
    className="flex items-center pl-8 pr-4 py-4 w-full gap-4"
    onClick={() => subList && setIsCollapsed(!isCollapsed)}
  >
    <span>{icon}</span>
    <span className="relative">
      {hasDot && <Dot />}
      {label}
    </span>
    {isCollapsed ? (
      <span className="ml-auto">
        <ChevronUpIcon />
      </span>
    ) : (
      <span className="ml-auto">
        <ChevronDownIcon />
      </span>
    )}
  </div>
);

const MenuItem = ({ href, icon, label, hasDot, subList }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { setSideBar } = useContext(UserContext);

  return (
    <li className="text-dark-gray-400 font-extralight select-none">
      {!subList ? (
        <MainLink
          href={href}
          icon={icon}
          label={label}
          hasDot={hasDot}
          setSideBar={setSideBar}
        />
      ) : (
        <CollapsedItem
          icon={icon}
          label={label}
          hasDot={hasDot}
          subList={subList}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      )}

      {!isCollapsed && subList && (
        <ul className="flex flex-col items-center text-left transition-all duration-1000 translate-y-0">
          {subList.map((item) => (
            <li className="w-full" key={item.label}>
              <NavLink
                className="inline-flex items-center pl-[72px] pr-8 py-4 w-full whitespace-nowrap"
                to={item.path}
                onClick={() => setSideBar(false)}
              >
                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default MenuItem;
