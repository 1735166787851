/* eslint-disable no-unused-vars */
/** @format */

import React, { useState } from "react";

import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import { useAuth } from "@hooks";
import { DeleteRoles, fetchRoles } from "@slices/roles-permissions-slice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

// eslint-disable-next-line react/prop-types
export const DeleteRoleModal = ({ setOpen, open = null, data = null }) => {
  const profiles = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const deleteMember = async () => {
    try {
      let body = {
        role_id: data,
      };
      const response = await dispatch(DeleteRoles(body));
      if (response.payload.status === 200) {
        window.location.reload();
        toast.success(response.payload.data.message);
        dispatch(
          fetchRoles({
            id: profiles?.account?._id,
            page: 1,
            limit: 10,
          })
        );
        setOpen(!open);
      } else {
        // toast.error(response.payload.msg);
        console.log("error from then", response);
      }
    } catch (error) {
      //   toast.error(error);
      // console.log("error", error);
    }
  };
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center mb-4">
            <h1 className="text-xl mb-4 text-[#FF4E44]">Warning !</h1>
            {data.length > 0 ? (
              <>
                <p className="font-light mt-4">
                  {" "}
                  Are you sure you want to remove{" "}
                  <span className="text-[#6166B6]">{data?.length}</span> roles ?
                </p>
                <div className="flex items-center justify-between mt-8">
                  <button
                    onClick={() => setOpen(!open)}
                    className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
                  >
                    Cancel
                  </button>
                  <div
                    onClick={() => {
                      deleteMember();
                    }}
                  >
                    <Button> {isLoading ? "Processing..." : "Delete"}</Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p className="font-light mt-4">
                  {" "}
                  Please select atleast one role
                </p>
                <div className="flex items-center justify-center mt-8">
                  <button
                    onClick={() => setOpen(!open)}
                    className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};
