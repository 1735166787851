/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { Btn as Button } from "@atoms/button/button";
import Skeleton from "@components/auctions/Skeleton";
import { useSelector, useDispatch } from "react-redux";
import { Money } from "@ui/icons";
import { fetchSubscription } from "@slices/wallet-slice";
import { RenewSubscription } from "./renewSubscription";
import { CancelSubscription } from "./cancelSubscription";

const Subscription = () => {
  const [open, setOpen] = useState(false);
  const [cancelSubscription, setCancel] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("");
  const dispatch = useDispatch();
  const { loading, subscription, pagination } = useSelector(
    (state) => state.wallet
  );

  const renewSubscription = async (type) => {
    setOpen(true);
    setSubscriptionType(type);
  };

  const cancelsubscription = async () => {
    setCancel(true);
  };

  useEffect(() => {
    dispatch(fetchSubscription());
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <CancelSubscription open={cancelSubscription} setCancel={setCancel} />
          <RenewSubscription
            open={open}
            setOpen={setOpen}
            type={subscriptionType}
          />
          <h3 className="text-2xl mb-4 text-heading">Your payment method</h3>
          <div className="text-default w-full bg-white px-[34px] p-[30px] rounded-md mb-8">
            <div className="flex flex-col items-center justify-center">
              <div className=" h-[80px] w-[80px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
                <div
                  className={`rounded-full h-[80px] w-[80px] flex items-center justify-center text-white bg-gray-100`}
                  style={{ fontSize: "45px" }}
                >
                  <Money width="41" height="41" fill="#6166B6" />
                </div>
              </div>

              <div className="text-center mt-8">
                <h3 className="text-heading text-2xl">
                  {subscription?.wallet} USD
                </h3>
                <span className="font-light text-primary">Wallet balance</span>
              </div>
              <div className="flex justify-center gap-8 mt-3 mb-3 w-[548px]">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    renewSubscription("stripe");
                  }}
                >
                  Pay via Stripe
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    renewSubscription("wallet");
                  }}
                >
                  Pay via Wallet
                </Button>
              </div>
            </div>
          </div>
          <h3 className="text-2xl mb-4 text-heading">Your Subscription</h3>
          <div className="text-default w-full bg-white px-[34px] p-[30px] rounded-md">
            <div className="flex flex-col items-center justify-center">
              <div className=" h-[80px] w-[80px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
                <div
                  className={`rounded-full h-[80px] w-[80px] flex items-center justify-center text-white bg-gray-100`}
                  style={{ fontSize: "32px", color: "#6166B6" }}
                >
                  $
                </div>
              </div>
              {subscription?.subscription?.status == "Canceled" ? (
                <>
                  <div className="text-center mt-8">
                    <h3 className="text-heading text-2xl">
                      Subscription ended on{" "}
                      {subscription?.subscription?.expires_in}
                    </h3>
                    <span className="text-primary font-light">
                      {subscription?.subscription_fee} / month
                    </span>
                  </div>
                
                </>
              ) : (
                <>
                  <div className="text-center mt-8">
                    <h3 className="text-heading text-2xl">
                      Subscription ends on{" "}
                      {subscription?.subscription?.expires_in}
                    </h3>
                    <span className="text-primary font-light">
                      {subscription?.subscription_fee}$ / month
                    </span>
                  </div>
                  <div className="flex justify-between mt-3 mb-3">
                    <Button
                      color="neutral"
                      onClick={() => {
                        cancelsubscription();
                      }}
                    >
                      Cancel Subscription
                    </Button>
                    {/* <Button>Pay via Wallet</Button> */}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Subscription;
