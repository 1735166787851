/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Card, Stepper, Button } from '@ui';
import BusinessSummary from '@components/investors/BusinessSummary';
import Documentation from '@components/investors/Documentation';
import ProductInformation from '@components/investors/ProductInfo';
import InvestmentDetails from '@components/investors/InvestmentDetails';
import Proposal from '@components/investors/Proposal';
import { useNavigate } from "react-router-dom";
import Complete from '@components/investors/Complete';
import { useSelector, useDispatch } from 'react-redux';
import {
	getServiceOptions
  } from "store/slices/miners/vendor-slice";
import {
	postSeekFundingData
} from '@slices/miners/investor-slice';
import {
	fetchAllActiveMines
  } from "@slices/miners/mines-slice";
import {
	get_mineral_data,
  } from "@slices/onboarding/miner.slice";
import { useForm } from 'react-hook-form';
import { Formik } from 'formik';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
	product_name: yup.string().required('Product name is required'),
	quantity: yup
		.number()
		.typeError('Quantity must be number')
		.required('Quantity is is required'),
	price: yup
		.number()
		.typeError('Price must be number')
		.required('Price is is required'),
	mineral: yup.string().required('Please select a mineral'),
	production_phase: yup.string().required('Please select a phase'),
	investment_type: yup.string().required('Please select a type'),
	duration: yup.string().required('Duration is required'),
	amount: yup.string().required('Amount is required'),
	product_image: yup.array().required('Please upload a product image'),
	additional_information: yup.string(),
	documents: yup.array().min(1).required('Upload at least one document'),
	proposal: yup.string().required('Proposal is required'),
	terms: yup.boolean().oneOf([true], 'Accept terms and conditions'),
});

const SeekFunding = ({ headingText = 'Seek funding' }) => {
	const [openSubmitModal, setOpenSubmitModal] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [productImage, setProductImage] = useState([]);
	const [documents, setDocuments] = useState([]);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(get_mineral_data());
		dispatch(fetchAllActiveMines());
		dispatch(getServiceOptions());
	}, []);
	const navigate = useNavigate();

	const _FormData = new FormData();

	const { investment_type, production_phase } = useSelector((store) => store.miner_vendors);

	const { minerals } = useSelector((state) => state.miner_verification);
		
	const { mines } = useSelector((state) => state.mines);

	const [activeStep, setActiveStep] = useState(0);
	const [mine, setMine] = useState();

	const {
		register,
		setValue,
		getValues,
		handleSubmit,
		clearErrors,
		watch,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(schema),
		mode: 'all'
	});

	const handleNext = () => {
		if (activeStep === 0) {
			const values = getValues();
			const { product_name, mineral, quantity, price, product_image, mine } =
				values;
			if (
				product_name &&
				mineral &&
				quantity &&
				price &&
				product_image &&
				mine
			) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else if (activeStep === 2) {
			const values = getValues();
			const { documents } = values;

			if (documents?.length > 0) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else if (activeStep === 3) {
			const values = getValues();
			const { production_phase, amount, duration, investment_type } = values;
			if (production_phase && amount && duration && investment_type) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else if (activeStep === 4) {
			const values = getValues();
			const { proposal, terms } = values;
			if (proposal?.length > 1 && terms) {
				clearErrors();
			}
		}
		else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		
		if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
		if(activeStep == 0) {
			navigate("/miner/investors/fundings");
		}
	};

	// eslint-disable-next-line no-unused-vars
	const handleSelectChange = (e, { name }) => {
		setValue(name, e.slug, {
			shouldValidate: true,
			shouldDirty: true
		});
	};

	const onSubmit = async (data) => {
		setLoading(true);
		delete data.terms
		for (const file of data.product_image[0]) {
			_FormData.append("product_files", file);
		}

		for (const file of data.documents[0]) {
			_FormData.append("document_files", file);
		}
		delete data.product_image;
		delete data.documents;
		_FormData.append("data",JSON.stringify(data));
		const response = await dispatch(postSeekFundingData({data:_FormData}));
		if (response.payload.status === 200 || response.payload.status === 201) {
			if(response.payload.data.code == 422 || response.payload.data.code == 400) {
				toast.error(response.payload.data.message);
				setLoading(false);
			}else {
				setLoading(false);
				setOpenSubmitModal(true);
			}		
		}
	};
	const uploadTypeSetter = (type, array) => {
		switch (type) {
			case 'product_image':
				setProductImage(array);
				clearErrors('product_image');
				break;
			case 'documents':
				setDocuments((prevState) => prevState.concat(array));
				clearErrors('documents');
				break;
			default:
				break;
		}
	};
	const handleFileChange = async (FileList, type) => {
		/** check if filelist is empty  */

		if (FileList.length <= 0) {
			return toast.error('No file selected');
		}
		/** Declare form data */
		const _FormData = new FormData();
		for (const file of FileList) {
			_FormData.append('file', file);
		}
		/** set upload type */
		_FormData.append('type', type);
		let product_files = [];
		let docu_files = [];
		
		if (type === "product_image") {
			product_files.push(FileList);
			setValue("product_image",product_files);
			uploadTypeSetter('product_image', FileList);
		}else {
			docu_files.push(FileList);
			setValue("documents",docu_files);
			uploadTypeSetter('documents', FileList);
		}
		/** Dispatch files to backend */
		// dispatch(file_uploader(_FormData))
		// 	.then((response) => {
		// 		if (
		// 			response.payload.status &&
		// 			SuccessResponse(response.payload.status)
		// 		) {
		// 			/** Set files to state */
		// 			if (type === 'product_image') {
		// 				setValue('product_image', [
		// 					{ id: response.payload.data.files[0].id }
		// 				]);
		// 				uploadTypeSetter('product_image', response.payload.data.files);
		// 			} else {
		// 				uploadTypeSetter(type, response.payload.data.files);
		// 				const values = getValues();

		// 				let doc = [];
		// 				const { documents } = values;
		// 				if (!documents) {
		// 					doc = [{ id: response.payload.data.files[0].id }];
		// 					setValue('documents', doc);
		// 				} else {
		// 					doc = [...documents, { id: response.payload.data.files[0].id }];
		// 					setValue('documents', doc);
		// 				}
		// 			}
		// 		}
		// 		throw 'error uploading file';
		// 	})
			// .catch((error) => toast.error(error.message));
	};
	const uploadProductImage = (file) => handleFileChange(file, 'product_image');
	const steps = [
		{
			label: 'Product information'
		},
		{
			label: 'Business summary'
		},
		{
			label: 'Documentation'
		},
		{
			label: 'Investment details'
		},
		{
			label: 'Proposal'
		}
	];
	const initialValues = {
		product_name: '',
		mineral: '',
		quantity: '',
		price: '',
		product_image: '',
		mine: '',
		documents: [],
		production_phase: '',
		investment_type: '',
		amount: '',
		funding_terms: '',
		terms: false
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				handleSubmit={handleSubmit}
			>
				{({ handleChange, isSubmitting, values }) => (
					<form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
						<Card noPadding>
							<div className="grid grid-cols-4 divide-x divide-disabled">
								<div className="col-span-1 py-10 mx-auto">
									<h3 className="text-2xl font-normal text-heading text-center mb-8">
										{headingText}
									</h3>
									{/* stepper starts here */}

									<Stepper steps={steps} activeStep={activeStep} />
								</div>
								<div className="col-span-3 py-10 px-8">
									{/* stepper contents here */}
									{steps[activeStep] && (
										<div>
											{activeStep === 0 && (
												<ProductInformation
													{...{
														errors,
														register,
														handleSelectChange,
														uploadProductImage,
														productImage,
														setProductImage,
														handleChange,
														values,
														minerals,
														mines,
														watch,
														setMine,
														setValue
														// mineral,
														// setMineral
													}}
													isSeeking
												/>
											)}
											{activeStep === 1 && (
												<BusinessSummary
													{...{
														errors,
														register,

														mine
													}}
												/>
											)}
											{activeStep === 2 && (
												<Documentation
													{...{
														errors,
														register,
														getValues,
														setValue,
														handleFileChange,
														documents,
														setDocuments
													}}
												/>
											)}
											{activeStep === 3 && (
												<InvestmentDetails
													{...{
														errors,
														clearErrors,
														investment_type,
														production_phase,
														register,
														handleSelectChange
													}}
												/>
											)}
											{activeStep === 4 && (
												<Proposal
													{...{
														errors,
														register
													}}
												/>
											)}

											<div className="flex justify-between mt-8">
												<Button
													variant="contained"
													onClick={handleBack}
													color="neutral"
												>
													{activeStep > 0 ? 'Back' : 'Cancel'}
												</Button>
												<Button
													variant="contained"
													onClick={handleNext}
													color="primary"
													disabled={isSubmitting}
												>
													{activeStep === steps.length - 1 ? `${isLoading ? 'Processing...' : 'Submit'}` : 'Next'}
												</Button>
											</div>
										</div>
									)}
									{/* stepper contents ends here */}
								</div>
							</div>
						</Card>
					</form>
				)}
			</Formik>
			<Complete
				{...{ openSubmitModal, setOpenSubmitModal }}
				isSeeking
				message="Your request for funding has been successfully posted. You will be notified when someone shows interest."
			/>
		</>
	);
};

export default SeekFunding;
