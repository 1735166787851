import { VerifiedIcon } from "@ui/icons";

const Complete = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <VerifiedIcon />
      <div className="text-center mt-9">
        <h3 className="text-heading text-2xl">Product added successfully</h3>
        <span className="text-ts font-light">
          Your payment was successful and your listing has been confirmed and
          published.
        </span>
        <div className="flex items-center justify-center gap-2 mt-6 text-ts font-light text-sm">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 4.46338C7.6 4.46338 4 8.06338 4 12.4634C4 16.8634 7.6 20.4634 12 20.4634C16.4 20.4634 20 16.8634 20 12.4634C20 8.06338 16.4 4.46338 12 4.46338ZM13 17.4634H11V10.4634H13V17.4634ZM13 9.46338H11V7.46338H13V9.46338Z"
              fill="#1C3454"
              fillOpacity="0.26"
            />
          </svg>
          To see your active auctions, go to Auctions
        </div>
      </div>
    </div>
  );
};

export default Complete;
