/* eslint-disable react/prop-types */
import Sidebar from "./sidebar/sidebar";
import SideBar from "./sidebar/sidebarResponsive";
import Navbar from "./navbar";
import Breadcrumb from "./breadcrumb";
import Footer from "./footer";
import {
  AreaSelectIcon,
  Money,
  CogIcon,
  UsersIcon,
  HeadsetIcon,
  PieChartIcon,
  DatabaseIcon,
  ListIcon,
} from "@ui/icons";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { SignInUrl } from "@config/app-url";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MENU_ITEMS = [
  {
    label: "Dashboard",
    url: "/admin/dashboard",
    icon: <PieChartIcon />,
  },

  {
    label: "Request",
    url: "/admin/requests",
    icon: <DatabaseIcon />,
    subList: [
      {
        label: "Approval",
        path: "/admin/requests/approval",
      },
    ],
  },
  {
    label: "Team",
    url: "/admin/team",
    icon: <UsersIcon />,
    hasDot: true,
    subList: [
      {
        label: "Members",
        path: "/admin/teams/members",
      },
      {
        label: "Roles",
        path: "/admin/teams/roles",
      },
      {
        label: "Activity",
        path: "/admin/teams/activity",
      },
    ],
  },
  {
    label: "Listings",
    url: "/admin/listings",
    icon: <ListIcon />,
    subList: [
      {
        label: "Auctions",
        path: "/admin/listings/auctions",
      },
      {
        label: "Investors",
        path: "/admin/listings/investors",
      },
      {
        label: "Vendors",
        path: "/admin/listings/vendors",
      },
    ],
  },
  {
    label: "Interactions",
    url: "/admin/interaction",
    icon: <AreaSelectIcon />,
    hasDot: true,
    subList: [
      {
        label: "Tenders",
        path: "/admin/interactions/tenders",
      },
      {
        label: "Transactions",
        path: "/admin/interactions/transactions",
      },
      {
        label: "Fundings",
        path: "/admin/interactions/fundings",
      },
      {
        label: "Projects",
        path: "/admin/interactions/projects",
      },
    ],
  },

  {
    label: "Payments",
    url: "/admin/payments",
    icon: <Money />,
  },

  {
    label: "Tickets",
    url: "/admin/tickets",
    icon: <HeadsetIcon />,
  },
  {
    label: "Messages",
    url: "/messages",
    icon: <MailOutlineIcon />,
  },
  {
    label: "Settings",
    url: "/admin/settings",
    icon: <CogIcon />,
  },
];

const LayoutMessages = ({ children }) => {
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setloading(true);
    if (!user) {
      return navigate(SignInUrl, { replace: true });
    }
    setloading(false);
  }, [navigate]);

  if (loading) {
    return null;
  }

  return (
    <div className="xl:grid xl:grid-cols-6 h-screen relative ">
      {/*side bar  */}
      <div
        className="7
      col-span-0 hidden xl:grid xl:col-span-1  "
      >
        <Sidebar items={MENU_ITEMS} sliceRange={7} />
      </div>
      {/* main content */}
      <div className="col-span-6 lg:col-span-5 grey-layout">
        <Navbar userType="admin" />
        {/* responsiveness */}
        <div className="xl:hidden">
          <SideBar items={MENU_ITEMS} sliceRange={7} />
        </div>
        <Breadcrumb />
        <main className="px-8">{children}</main>
        <div className=" bottom-0">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LayoutMessages;
