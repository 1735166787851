const CourierIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 11V7L12 5L8 7V11L4 13V18L8 20L12 18L16 20L20 18V13L16 11ZM12.09 6.12L15 7.56L12.4 8.86L9.49 7.42L12.09 6.12ZM9 7.78L12 9.28V12.88L9 11.38V7.78ZM8 18.88L5 17.38V13.78L8 15.28V18.88ZM8.28 14.88L5.4 13.42L8 12.12L10.88 13.56L8.28 14.88ZM16 18.88L13 17.38V13.78L16 15.28V18.88ZM16.28 14.88L13.4 13.42L16 12.12L18.88 13.56L16.28 14.88Z"
        fill="#D9E8FC"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default CourierIcon;
