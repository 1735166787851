/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Button, Search, AdminsTable } from "@ui";
import { DownloadIcon, SearchIcon, BarChartIcon } from "@ui/icons";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { fetchUnverifiedProfile } from "@slices/admin-slice";
import { toast } from "react-toastify";

const Stat = ({ bgColor, children }) => {
  const backgroundColor = bgColor ? bgColor : "bg-white";
  const boxShadow = bgColor && "shadow-Stat";
  return (
    <div
      className={`flex items-center justify-between border border-solid border-dark-gray-100 px-6 py-5 rounded-lg ${backgroundColor} ${boxShadow}`}
    >
      {children}
    </div>
  );
};

const Approval = () => {
  const dispatch = useDispatch();

  const { profiles, count, pagination, loading } = useSelector(
    (state) => state.admin
  );
  const [searchParams, setParams] = useState("");

  const handleSetPerPage = (e) => {
    dispatch(
      fetchUnverifiedProfile({
        page: 1,
        limit: e.target.value,
        search: searchParams,
        filter: "",
      })
    );
  };

  const exportcsv = () => {
    let data = [];
    profiles.forEach((x) => {
      data.push({
        id: x.user[0]._id,
        name: x.user[0]?.name,
        email: x.user[0]?.email,
        role: x.user[0]?.role[0]?.name,
        phone: x.user[0]?.phone?.number,
      });
    });

    let header = ["ID", "Name", "Email", "Role", "Phone No"];
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "base64" });
    FileSaver.saveAs(finalData, "ApprovalRequests.xlsx");
  };

  const handleSearch = (e) => {
    if (!e.target.value) {
      dispatch(fetchUnverifiedProfile({ page: 1, limit: 10, search: "" }));
    }
    setParams(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!searchParams) {
      dispatch(fetchUnverifiedProfile({ page: 1, limit: 10, search: "" }));
    }
    dispatch(
      fetchUnverifiedProfile({
        page: 1,
        limit: pagination.limit,
        search: searchParams,
      })
    );
  };

  const handleChangePage = (option) => {
    dispatch(
      fetchUnverifiedProfile({
        page: pagination[option],
        limit: pagination.limit,
        search: searchParams,
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchUnverifiedProfile({ page: 1, limit: 10, search: "" }),
      (error) => {
        toast.error(error);
      }
    );
  }, [dispatch]);

  return (
    <div>
      <div className="flex overflow-x-scroll scrollbar-hide lg:grid grid-cols-4 gap-3">
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">
              approval requests
            </p>
            <p className="text-2xl text-[#374146]">{count?.miners + count?.off_takers + count?.vendors}</p>
          </div>
          <div>
            <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">miners</p>
            <p className="text-2xl text-[#374146]">{count?.miners}</p>
          </div>
          <div>
            <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">off-takers</p>
            <p className="text-2xl text-[#374146]">{count?.off_takers}</p>
          </div>
          <div>
            <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">vendors</p>
            <p className="text-2xl text-[#374146]">{count?.vendors}</p>
          </div>
          <div>
            <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>
      </div>
      <div className="flex py-4 justify-between h-fit ">
        <div className="flex gap-2">
          <form onSubmit={(e) => handleSearchSubmit(e)} className="flex gap-2">
            <div className="w-[250px] lg:w-[538px]">
              <Search
                placeholder="Search database"
                onChange={(e) => handleSearch(e)}
              />
            </div>
            <Button type="submit" color="primary">
              <SearchIcon color="#ffff" opacity="1" />
            </Button>
          </form>
        </div>
        <div className="w- h-[44px] ">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              exportcsv();
            }}
            startIcon={<DownloadIcon />}
          >
            Export CSV
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <AdminsTable
          profiles={profiles}
          loading={loading}
          pagination={pagination}
          handleSetPerPage={handleSetPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
    </div>
  );
};

export default Approval;
