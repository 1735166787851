import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  maintainAspectRatio: false,

  scales: {
    x: {
      ticks: {
        labelOffset: 5,
        color: "#A2A3A5",
        font: {
          family: "Associate Sans",
          weight: 200,
          size: 14,
        },
      },
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 100, //difference between the ticks
        padding: 7,
        // sampleSize: 3,
        color: "#A2A3A5",
        crossAlign: "far",
        font: {
          family: "Associate Sans",
          weight: 300,
          size: 14,
        },
      },

      grid: {
        borderDash: [2, 2], //this changes the horizontal line to dotted lines
        drawBorder: false, //this removes the vertical grid lines
        drawTicks: false, //remove the tick (-) lines
      },
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointRadius: 1,
        color: "#849AA9",
        boxWidth: 8,
        boxHeight: 8,
        padding: 40,
        font: {
          family: "Associate Sans",
          size: 11,
        },
      },
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "March",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const data = {
  labels,
  datasets: [
    {
      label: "Sales",
      data: [100, 200, 300, 100, 300, 200, 200, 0, 300],
      borderColor: "rgba(80, 170, 167, 1)",
      backgroundColor: "rgba(80, 170, 167, 1)",
      radius: 0, //takes away the the points on the lines
    },
    {
      label: "Transaction",
      data: [100, 300, 200, 300, 100, 300, 100, 100, 100],
      borderColor: "rgba(45, 156, 219, 1)",
      backgroundColor: "rgba(45, 156, 219, 1)",
      radius: 0,
    },
  ],
};

const LineChart = () => {
  return <Line options={options} data={data} />;
};

export default LineChart;
