/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import InteractionsStats from "@ui/InterationsStats";
import { useDispatch, useSelector } from "react-redux";
import { getTenders } from "@slices/miners/transaction-slice";
import Table from "components/Table";
import { tendersTableInfo } from "constants/index";
import {
  fetchPaginatedTenders,
  setTenderSearchValue,
} from "store/slices/miners/transaction-slice";

const Tenders = () => {
  const [isVerified] = useState(true);
  const [underReview] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewDetails = (record) => {
    navigate(`/miner/interactions/tenders/details/${record._id}`);
  };
  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedTenders({
        page: pagination[option],
        per_page: pagination.limit,
        search: search,
        // filter: filter,
      })
    );
  };
  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedTenders({
        page: 1,
        per_page: e.target.value,
        search: search,
        // filter: filter,
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(setTenderSearchValue(e.target.value));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(getTenders());
    }
    dispatch(setTenderSearchValue(search));
    dispatch(
      fetchPaginatedTenders({
        page: 1,
        per_page: pagination.limit,
        search: search,
        // filter: filter,
      })
    );
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    dispatch(
      fetchPaginatedTenders({
        page: 1,
        per_page: pagination.limit,
        search: search,
        // filter: tab.toLowerCase(),
      })
    );
  };


  useEffect(() => {
    dispatch(getTenders());
  }, [dispatch]);

  const {
    loading,
    tenders: { products, counts, pagination, search },
  } = useSelector((state) => state.interactions);

  return (
    <div>
      <div className="grid grid-cols-3 lg:grid gap-2 sm:flex overflow-x-auto scrollbar-hide">
        <InteractionsStats
          isVerified={isVerified}
          underReview={underReview}
          counts={counts}
        />
      </div>
      {/* tabs */}
      <div className="mt-4 sm:flex overflow-x-auto scrollbar-hide">
        <Table
          tableInfo={tendersTableInfo}
          isViewDetails={true}
          isStatus={true}
          handleViewDetails={handleViewDetails}
          navigate={navigate}
          data={products}
          pagination={pagination}
          handleSetPerPage={handleSetPerPage}
          handleSearchSubmit={handleSearchSubmit}
          handleChangePage={handleChangePage}
          handleTabChange={handleTabChange}
          loading={loading}
          handleSearch={handleSearch}
          fetchDefaultData={getTenders}
          search={search}
        />
      </div>{" "}
    </div>
  );
};

export default Tenders;
