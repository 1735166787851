import React from "react";

const AddIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99992 4.16665C13.2499 4.16665 15.8333 6.74998 15.8333 9.99998C15.8333 13.25 13.2499 15.8333 9.99992 15.8333C6.74992 15.8333 4.16659 13.25 4.16659 9.99998C4.16659 6.74998 6.74992 4.16665 9.99992 4.16665ZM9.99992 3.33331C6.33325 3.33331 3.33325 6.33331 3.33325 9.99998C3.33325 13.6666 6.33325 16.6666 9.99992 16.6666C13.6666 16.6666 16.6666 13.6666 16.6666 9.99998C16.6666 6.33331 13.6666 3.33331 9.99992 3.33331Z"
        fill="white"
      />
      <path
        d="M14.1666 9.16665H10.8333V5.83331H9.16659V9.16665H5.83325V10.8333H9.16659V14.1666H10.8333V10.8333H14.1666V9.16665Z"
        fill="white"
      />
    </svg>
  );
};

export default AddIcon;
