/* eslint-disable no-unused-vars */
import React,{useState} from "react";
import { Card, Select, Button } from "@ui";
import { Input, TextArea } from "@atoms/input";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { Step, StepLabel, Stepper } from "@mui/material";
import { Upload } from "@atoms/icons";
import dayjs from "dayjs";
import Padlock from "@ui/icons/PadLock";
import StepperCheckIcon from "@ui/icons/StepperCheckIcon";
import PaperClip from "@ui/icons/PaperClip";
import DiplomaScroll from "@ui/icons/DiplomaScroll";
import { UploadPocVerificationFormSchema } from "@validations/schema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SuccessResponse } from "config/status-code";
import { postTransactionConfirm } from "@slices/miners/transaction-slice";

import { toast } from "react-toastify";
import GoogleAutoComplete2 from "pages/onboarding/select-category/miner/GoogleAutoComplete2";

const Poc = ({ transaction, status, transactionId }) => {
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const _FormData = new FormData();
  const [action, setAction] = useState("review");
  const courierData = useSelector((state) => state.shipment);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const now = new Date();

  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const { loading } = useSelector((state) => state.file);

  /** validation */
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(UploadPocVerificationFormSchema),
    mode: "all",
  });

  function handleGoogleAutoComplete(place) {
    setValue("address", place?.formatted_address ?? "", {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("postal_code", place?.address_components[6]?.long_name ?? "", {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("long", place?.geometry?.location.lng() ?? "", {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("lat", place?.geometry?.location?.lat() ?? "", {
      shouldValidate: true,
      shouldDirty: true,
    });
  }

  const removeShipmentFile = (name) => {
    setValue("transaction_shipment_documents", []);
    setFiles2((prevState) => prevState.filter((file) => file.name !== name));
  };

  const removeFile = (name) => {
    setValue("transaction_poc_documents", []);
    setFiles((prevState) => prevState.filter((file) => file.name !== name));
  };

  const handleFileChange = async (FileList, name) => {
    /** check if filelist is empty  */
    if (FileList.length <= 0) {
      return toast.error("No file selected");
    }
    /** Declare form data */

    if (name === "poc-document") {
      for (const file of FileList) {
        _FormData.append("transaction_poc_documents", file);
      }
      setValue("transaction_poc_documents", [...FileList]);

      /** set files to state */
      setFiles([...files, ...FileList]);
    }

    if (name === "shipment-document") {
      for (const file of FileList) {
        _FormData.append("transaction_shipment_documents", file);
      }

      setValue("transaction_shipment_documents", [...FileList]);

      /** set files to state */
      setFiles2([...files2, ...FileList]);
    }
  };

  const handleSelectChange = (e, { name }) => {
    setValue(name, e._id, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onSubmit = async (data) => {
    const { address, long, postal_code, lat } = data;

    try {
      const formload = {
        shipment_pickup_location: {
          address,
          long,
          postal_code,
          lat,
        },
        shipment_additional_note: data.shipment_additional_note,
        shipment_arrival_date: data.shipment_arrival_date,
        shipment_carrier_service: data.shipment_carrier_service,
        shipment_departure_date: data.shipment_departure_date,
        shipment_reference_id: data.shipment_reference_id,
        shipment_tracking_id: data.shipment_tracking_id,
        action:"upload_poc"
      };
      for (const file of data.transaction_poc_documents) {
        _FormData.append("transaction_poc_documents", file);
      }

      for (const file of data.transaction_shipment_documents) {
        _FormData.append("transaction_shipment_documents", file);
      }


      _FormData.append("data",JSON.stringify(formload));
      const response = await dispatch(postTransactionConfirm({formdata:_FormData,id:transactionId}));
      if (response.payload.status && SuccessResponse(response.payload.status)) {
        navigate("/miner/interactions/transactions");
      }
      throw "error submitting form";
    } catch (error) {
      toast.error(error.message);
    }
  };

  const steps = [
    "Pending",
    "Awaiting POP",
    "Awaiting POF",
    "Awaiting POC",
    "In Transit",
    "Delivered",
    "Completed",
  ];

  const fileTypes = ["JPG", "PNG", "PDF"];
  const indexOf = steps.findIndex((el) => {
    return el == status;
  });

  return (
    <div>
      {action === "upload-poc" ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row justify-between mb-[20px]">
            <p className="text-[22px] text-left text-black">Upload POC</p>
            <Button size="small" disabled={loading}>
              Upload and Confirm
            </Button>
          </div>
          {/* Confirm and send request input field section */}
          <Card noPadding>
            <div>
              <div className="divide-x divide-disabled">
                <div className="text-default bg-white pt-[30px]">
                  <div className="flex items-center justify-between">
                    <p className="font-light text-ts mb-4">
                      Kindly upload POC to validate and support authenticity.
                    </p>
                  </div>
                  <div className="mt-4 w-[50%]">
                    <FileUploader
                      className="border-dashed border-4"
                      handleChange={(e) => handleFileChange(e, "poc-document")}
                      name="file"
                      types={fileTypes}
                      multiple
                    >
                      <h5>
                        <span className="text-primary bg-dark-gray-300 py-2.5 px-4 rounded m-4">
                          Upload image
                        </span>

                        <p className="flex  align-center justify-center">
                          <Upload className="mr-2" />
                          Drop image here
                        </p>
                      </h5>
                    </FileUploader>
                    {errors.transaction_poc_documents?.message && (
                      <p className="paragraph text-red text-left text-xs mt-1">
                        {" "}
                        * {errors.transaction_poc_documents?.message}{" "}
                      </p>
                    )}
                  </div>
                  <div className="pb-5">
                    {files.map((file, i) => {
                      return (
                        <p
                          key={i}
                          className="h-2 flex items-center text-primary my-2 text-xs mb-[16px]"
                        >
                          <span>{<PaperClip />}</span>
                          <span>
                            {file ? `${file.name}` : "no files uploaded"}
                          </span>
                          <button
                            className="text-red text-[16px] px-2"
                            onClick={() => removeFile(file.name)}
                          >
                            {" "}
                            x{" "}
                          </button>
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <div className="flex flex-row justify-between my-[20px]">
            <p className="text-[22px] text-left text-black">Shipment details</p>
          </div>
          <Card noPadding>
            <div className="px-3">
              <div className="my-6">
                {/* form */}
                <>
                  <div className="grid grid-cols-3 gap-10 items-center">
                    <div className="">
                      <Select
                        label="Carrier service"
                        name="shipment_carrier_service"
                        options={courierData.data}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option._id}
                        onChange={handleSelectChange}
                        placeholder="Select carrier service"
                      />
                      {errors.shipment_carrier_service?.message && (
                        <p className="paragraph text-red text-left text-xs mt-1">
                          {" "}
                          * {errors.shipment_carrier_service?.message}{" "}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="shipment_tracking_id"
                        className="form-label inline-block mb-2 font-light text-sm text-[#1B2B41B8]"
                      >
                        Tracking ID
                      </label>
                      <Input
                        label="Tracking ID"
                        name="shipment_tracking_id"
                        {...register("shipment_tracking_id")}
                        placeholder="TRI013453"
                      />
                      {errors.shipment_tracking_id?.message && (
                        <p className="paragraph text-red text-left text-xs mt-1">
                          {" "}
                          {errors.shipment_tracking_id?.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="shipment_reference_id"
                        className="form-label inline-block mb-2 font-light text-sm text-[#1B2B41B8]"
                      >
                        Reference ID
                      </label>
                      <Input
                        label="Reference ID"
                        name="shipment_reference_id"
                        {...register("shipment_reference_id")}
                        placeholder="RID@356788"
                      />
                      {errors.shipment_tracking_id?.message && (
                        <p className="paragraph text-red text-left text-xs mt-1">
                          &nbsp;&nbsp;
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col mt-2">
                    <p className="font-light text-ts mb-2 text-sm">
                      Pick-Up Location
                    </p>
                    <GoogleAutoComplete2
                      handleGoogleAutoComplete={handleGoogleAutoComplete}
                    />
                    {errors.address?.message && (
                      <p className="paragraph text-red text-left text-xs mt-1">
                        {" "}
                        * {errors.address?.message}{" "}
                      </p>
                    )}
                  </div>
                  <div className="grid grid-cols-2 gap-10 mt-2 mb-2">
                    <div className="flex flex-col">
                      <p className="font-light text-ts mb-2 text-sm">
                        Actual date of departure
                      </p>
                      <input
                        type="date"
                        min={dayjs(today).format("YYYY-MM-DD")}
                        name="shipment_departure_date"
                        {...register("shipment_departure_date")}
                        // defaultValue="2022-05-24"
                        className="focus:outline-0 font-light text-gray-700 border-none ring-offset-0 focus:ring-primary bg-[#1A38601A] p-[10px] h-[42px] rounded-[0.25rem]"
                      />
                      {errors.shipment_departure_date?.message && (
                        <p className="paragraph text-red text-left text-xs mt-1">
                          {" "}
                          * {errors.shipment_departure_date?.message}{" "}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <p className="font-light text-ts mb-2 text-sm">
                        Estimated date of arrival
                      </p>
                      <input
                        type="date"
                        min={dayjs(today).format("YYYY-MM-DD")}
                        name="shipment_arrival_date"
                        {...register("shipment_arrival_date")}
                        // defaultValue="2022-05-24"
                        className="focus:outline-0 font-light text-gray-700 border-none ring-offset-0 focus:ring-primary bg-[#1A38601A] p-[10px] h-[42px] rounded-[0.25rem]"
                      />
                      {errors.shipment_arrival_date?.message && (
                        <p className="paragraph text-red text-left text-xs mt-1">
                          {" "}
                          * {errors.shipment_arrival_date?.message}{" "}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-10 mt-2">
                    <div className="mt-4 ">
                      <div className="flex items-center justify-between">
                        <p className="font-light text-ts mb-1 text-sm">
                          Upload a shippment document
                        </p>
                      </div>
                      <FileUploader
                        className="border-dashed border-4"
                        handleChange={(e) =>
                          handleFileChange(e, "shipment-document")
                        }
                        name="file"
                        types={fileTypes}
                        multiple
                      >
                        <h5>
                          <span className="text-primary bg-dark-gray-300 py-2.5 px-4 rounded m-4">
                            Upload Files...
                          </span>

                          <p className="flex  align-center justify-center">
                            <Upload className="mr-2" />
                            Drop File here
                          </p>
                        </h5>
                      </FileUploader>
                      {errors.transaction_shipment_documents?.message && (
                        <p className="paragraph text-red text-left text-xs mt-1">
                          {" "}
                          * {
                            errors.transaction_shipment_documents?.message
                          }{" "}
                        </p>
                      )}
                      <div className="pb-5">
                        {files2.map((file, i) => {
                          return (
                            <p
                              key={i}
                              className="h-2 flex items-center text-primary my-2 text-xs mb-[16px]"
                            >
                              <span>{<PaperClip />}</span>
                              <span>
                                {file ? `${file.name}` : "no files uploaded"}
                              </span>
                              <button
                                className="text-red text-[16px] px-2"
                                onClick={() => removeShipmentFile(file.name)}
                              >
                                {" "}
                                x{" "}
                              </button>
                            </p>
                          );
                        })}
                      </div>
                    </div>

                    <TextArea
                      label="Additional Note"
                      placeholder="Add note here"
                      {...register("shipment_additional_note")}
                    />
                  </div>
                </>
              </div>
            </div>
          </Card>
        </form>
      ) : (
        <>
          {/* main Information view */}
          <Card noPadding>
            <div className="border-b-[1px] border-[#1C345442] h-[258px] mx-[-1.5rem]">
              <div className="divide-x divide-disabled mx-[2.5rem]">
                <div className="text-default bg-white pt-[30px]">
                  <div className="flex items-center justify-between">
                    <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[24px] text-left text-[#192434]">
                      Main Info
                    </p>
                    <div className="ml-[8px] bg-[#6166B6] rounded-[4px] border-0">
                      <Button
                        disabled={status !== "Awaiting POC"}
                        variant=""
                        color="text-[#ffffff]"
                        fontWeight="font-light"
                        onClick={() => {
                          setAction("upload-poc");
                        }}
                      >
                        Upload POC
                      </Button>
                    </div>
                  </div>
                  <div className="text-default  w-[796px] bg-white">
                    <div className="flex">
                      <div className="w-[50%]">
                        <div className="flex mb-[16px]">
                          <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            Transaction ID:
                          </p>
                          <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            {transaction?.transaction_id ?? "N/A"}
                          </p>
                        </div>
                        <div className="flex mb-[16px]">
                          <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            Product name:
                          </p>
                          <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            {transaction?.product?.product_name ?? "N/A"}
                          </p>
                        </div>
                        <div className="flex mb-[16px]">
                          <p className="flex-grow-0 mr-[50px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            Name of buyer:
                          </p>
                          <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            <span className="flex items-center text-[#6166B6]">
                              {/* <img
                                className="max-w-[24px] mr-[8px]"
                                src={ProfilePicture}
                                alt="Miley Cyrus"
                              /> */}
                              {transaction?.buyer?.name ?? "N/A"}
                            </span>
                          </p>
                        </div>
                        <div className="flex mb-[16px]">
                          <p className="flex-grow-0 mr-[100px] flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            Status:
                          </p>
                          <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            <span
                              className={`py-1 px-5 inline-flex capitalize text-sm leading-5 font-light rounded bg-[#FDF2E7] text-[#F2994A] `}
                            >
                              {status ?? "N/A"}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="mb-[px]">
                          <p>Transaction value:</p>
                          {/* <p className="font-medium text-lg">7 921 950 USD</p> */}
                        </div>
                        <div>
                          <p className="font-medium text-lg">
                            {transaction?.product?.product_total_value ?? "N/A"}
                            {transaction?.product?.product_total_value &&
                              " USD"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {/* <div className="divide-x divide-disabled">
                <div className="text-default bg-white pt-[30px]">
                  <div className="flex items-center justify-between">
                    <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[24px] text-left text-[#192434]">
                      Documents
                    </p>
                  </div>
                  <div className="pb-2">
                    <p className="h-2 flex items-center text-primary my-2 text-xs mb-[16px]">
                      <span>{<PaperClip />}</span>
                      <span>Assay Report</span>
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </Card>
          {/* information status */}
          <div className="mt-[30px]">
            <Card noPadding>
              <div className="flex items-center justify-between mt-5">
                <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-[16px] text-left text-[#192434]">
                  Timeline
                </p>
              </div>
              <Stepper orientation="vertical">
                {transaction?.timeline?.map((step, i) => (
                  <Step key={i}>
                    <StepLabel
                      StepIconComponent={() => (
                        <DiplomaScroll fill_="black" h_="16px" w="16px" />
                      )}
                    >
                      <div className="flex font-normal items-center">
                        {/* <img
                          className="max-w-[24px] mr-[8px]"
                          src={ProfilePicture}
                          alt="Miley Cyrus"
                        /> */}
                        <span className="font-[300] text-[#1b2b41a3]">
                          <span className="text-black mr-2">
                            {" "}
                            {step?.message}
                          </span>

                          {step?.created_at
                            ? new Date(step?.created_at).toLocaleString()
                            : null}
                        </span>
                      </div>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Card>
          </div>

          <div className="pt-[40px]">
            <Stepper alternativeLabel orientation="horizontal">
              {steps.map((step, i) => (
                <Step key={step}>
                  <StepLabel
                    StepIconComponent={
                      i <= indexOf ? StepperCheckIcon : Padlock
                    }
                  >
                    {step}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </>
      )}
    </div>
  );
};

export default Poc;
