/* eslint-disable react/prop-types */
import { MoneyIcon } from "@ui/icons";
import PiggyBankIcon from "@ui/icons/PiggyBankIcon";

const Stat = ({ bgColor, children }) => {
  const backgroundColor = bgColor ? bgColor : "bg-white";
  const boxShadow = bgColor && "shadow-Stat";
  return (
    <div
      className={`flex items-center justify-between card border-solid px-6 py-5 rounded-lg ${backgroundColor} ${boxShadow}`}
    >
      {children}
    </div>
  );
};

const OfftakerStats = ({ statData }) => {
    return (
      <>
        <Stat>
          <div>
            <p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
              TRANSACTIONS TODAY
            </p>
            <p className="text-2xl text-[#374146]">{statData?.today_transactions}</p>
          </div>
          <div>
            <MoneyIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
              ALL TIME TRANSACTIONS
            </p>
            <p className="text-2xl text-[#374146]">{statData?.total_transactions}</p>
          </div>
          <div>
            <PiggyBankIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm font-light mb-2 uppercase text-[#92A2AA]">
              COMPLETED TRANSACTIONS
            </p>
            <p className="text-2xl text-[#374146]">{statData?.completed_transactions}</p>
          </div>
          <div>
            <MoneyIcon />
          </div>
        </Stat>
      </>
    );
    }
export default OfftakerStats;
