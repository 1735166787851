import { Skeleton, Stack, Typography } from '@mui/material';
// import { Card } from '@ui';

const variants = [
	'h1',
	'h2',
	'body1',
	'body1',
	'body1',
	'body1',
	'body1',
	'h3',
	'body1',
	'body1',
	'body1',
	'body1',
	'body1',
	'body1',
	'h2',
	'body1',
	'body1',
	'body1'
];

const LoadingState = () => {
	return (
		<div className="grid grid-rows-1 lg:grid-rows-none lg:grid-cols-3 gap-7 justify-center">
			<div className="row-span-1 lg:col-span-2 min-h-[1145px]">
				<div className="flex flex-col lg:flex-row gap-8 pt-1">
					<div className="w-[285px] lg:w-[331px] h-[217px] rounded-lg overflow-hidden mb-4">
						<Skeleton
							variant="rectangular"
							sx={{ width: '100%', borderRadius: 1 }}
							height={217}
						/>
					</div>
					<div className="w-2/4">
						<Skeleton variant="text" />
						<Skeleton variant="text" width={200} />
						<Skeleton variant="text" width={200} />
					</div>
				</div>
				{/* card information */}
				<Stack>
					{variants.map((variant, index) => (
						<Typography component="div" key={index} variant={variant}>
							<Skeleton />
						</Typography>
					))}
				</Stack>
			</div>
		</div>
	);
};

export default LoadingState;
