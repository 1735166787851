import React from "react";
import Permissions from "./Editpermission";
import Role from "./EditRole";
import TeamMember from "./EditTeamMember";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";

function EditOfftakerRole() {
  let { id } = useParams();
  const initialValues = {
    role: "",
  };
  return (
    <div>
      <Formik initialValues={initialValues}>
        {({ values, handleChange }) => (
          <Form>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            <Role values={values} handleChange={handleChange} id={id} />
            <TeamMember values={values} handleChange={handleChange} id={id} />
            <Permissions values={values} handleChange={handleChange} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditOfftakerRole;
