const User = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9 4.5C6.3 4.5 9 11.8 9 11.8C9.6 12.8 10.4 12.6 10.4 13.3C10.4 13.9 9.7 14.1 9 14.2C7.9 14.2 6.9 14 5.9 15.8C5.3 16.9 5 20.5 5 20.5H18.7C18.7 20.5 18.4 16.9 17.9 15.8C16.9 13.9 15.9 14.2 14.8 14.1C14.1 14 13.4 13.8 13.4 13.2C13.4 12.6 14.2 12.8 14.8 11.7C14.8 11.8 17.5 4.5 11.9 4.5V4.5Z"
        fill="#D9E8FC"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default User;
