/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import { useNavigate } from "react-router-dom";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { postDemandInterestPaymentData } from "@slices/miners/demands-slice";
import Success from "./Success";
import { toast } from "react-toastify";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      border: "1px solid red",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 528,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
// Changed file name case
const PaymentModal = ({ open, setOpen, id, miner_demand_charge }) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { paymentLoading, paymentSuccess } = useSelector(
    (store) => store.miner_demands
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: "Simon Miner",
        email: "minersimon@daniola.com",
      },
    });

    if (!result?.paymentMethod) {
      toast.error(result.error.message);
    }

    const data = {
      payment_method: "stripe",
      payment_method_id: result.paymentMethod.id,
      payment_intent_id: result.paymentMethod.id,
    };
    const response = await dispatch(
      postDemandInterestPaymentData({ id, data })
    );
    if (response.payload.status === 201) {
      if (
        response?.payload?.data?.code == 422 ||
        response?.payload?.data?.code == 400
      ) {
        toast.error(response.payload.data.message);
      } else {
        navigate("/miner/demands");

        toast.success(response.payload.data.message);
      }
    } else {
      console.log("error from then", response);
    }
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {paymentSuccess ? (
          <Success />
        ) : (
          <div className="text-center mb-4">
            <div className=" mx-auto">
              <h2 className="text-heading text-2xl mb-2">Payment</h2>
              <p className="text-ts font-light mt-2 mb-5">
                You have decided to proceed with paying the listing fee charge
                of
                <span className="text-heading text-base ml-2">${miner_demand_charge}</span>. You
                will be redirected to stripe to pay.{" "}
              </p>
              {/* <p className="text-ts font-extralight mt-2 mb-5">
                Test card details{" "}
                <span className="text-heading text-base font-normal">
                  (4242 4242 4242 4242) 12/32 321 45678
                </span>
              </p> */}
              <label>
                Card details
                <CardElement options={CARD_ELEMENT_OPTIONS} />
              </label>
            </div>
            <div className="flex gap-x-10 items-center justify-center mt-8">
              <Button
                loading={paymentLoading}
                onClick={handleSubmit}
                size="mid"
              >
                Pay
              </Button>
              <Button onClick={() => setOpen(false)} color="neutral" size="mid">
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default PaymentModal;
