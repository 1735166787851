/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { MoneyIcon, SearchIcon, DownloadIcon } from "@ui/icons";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {
  Button,
  Search,
  SuspensionAdminTable,
  ControlledModal,
  Input,
  TextArea,
} from "@ui";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSuspensions,
  sendAdminMessage,
  deleteAdminUser,
  updateSuspensionStatus,
} from "@slices/admin-slice";

const Stat = ({ bgColor, children }) => {
  const backgroundColor = bgColor ? bgColor : "bg-white";
  const boxShadow = bgColor && "shadow-Stat";
  return (
    <div
      className={`flex items-center justify-between border border-solid border-dark-gray-100 px-6 py-5 rounded-lg ${backgroundColor} ${boxShadow}`}
    >
      {children}
    </div>
  );
};

const Suspensions = () => {
  const { suspensions, counts, pagination, loading } = useSelector(
    (state) => state.admin
  );
  const [uuid, setUUID] = useState("");
  const [user_id, setUserID] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [limit, setLimit] = useState(10);
  const [opened, setOpened] = useState(false);
  const [openSuspend, setopenSuspend] = useState(false);
  const [open, setOpen] = useState(false);
  const [acc_id, setaccId] = useState();
  const handleClosedSuspension = () => setopenSuspend(false);
  const handleClosed = () => setOpened(false);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const [data, setData] = useState({
    subject: "",
    content: "",
    url: "",
  });

  const handleOpenSuspension = (id) => {
    setopenSuspend(true);
    setUserID(id);
  };

  const handleOpen = (id) => {
    setOpen(true);
    setUUID(id);
  };

  const handleDeleteOpen = (id) => {
    setOpened(true);
    setaccId(id);
  };

  const handleChange = (e) => {
    if (!e.target.value) {
      dispatch(
        fetchSuspensions({
          page: 1,
          limit: limit,
          search: searchValue,
        })
      );
    }

    setSearchValue(e.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSetPerPage = (e) => {
    setLimit(e.target.value);
    dispatch(
      fetchSuspensions({
        page: 1,
        limit: e.target.value,
        search: searchValue,
      })
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      fetchSuspensions({
        page: 1,
        limit: limit,
        search: searchValue,
      })
    );
  };

  const handleChangePage = (page) => {
    dispatch(
      fetchSuspensions({
        page: page,
        limit: limit,
        search: searchValue,
      })
    );
  };

  const exportcsv = () => {
    let header = ["ID", "Name", "Email", "Status", "Created At","Updated At", "Profile"];
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, suspensions, {
      origin: "A2",
      skipHeader: true,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "base64" });
    FileSaver.saveAs(finalData, "Suspensions.xlsx");
  };

  const sendMessage = async () => {
    try {
      const response = await dispatch(
        sendAdminMessage({ id: uuid, data: data })
      );
      if (response.payload.status === 200 || response.payload.status === 201) {
        if (
          response.payload.data.code == 400 ||
          response.payload.data.code == 422
        ) {
          toast.error(response.payload.data.message);
          setOpen(false);
        } else {
          dispatch(
            fetchSuspensions({ page: 1, limit: limit, search: searchValue })
          );
          toast.success(response.payload.data.message);
          setOpen(false);
        }
      } else {
        console.log("error from then", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const suspendUser = async () => {
    let body = {
      status: "active",
    };
    try {
      const response = await dispatch(
        updateSuspensionStatus({ id: user_id, data: body })
      );
      if (response.payload.status === 201 || response.payload.status === 200) {
        dispatch(
          fetchSuspensions({ page: 1, limit: limit, search: searchValue })
        );
        toast.success(response.payload.data.message);
        setopenSuspend(false);
      } else {
        console.log("error from then", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteUser = async () => {
    try {
      const response = await dispatch(deleteAdminUser(acc_id));
      if (response.payload.status === 201 || response.payload.status === 200) {
        dispatch(
          fetchSuspensions({ page: 1, limit: limit, search: searchValue })
        );
        toast.success(response.payload.data.message);
        setOpened(false);
      } else {
        console.log("error from then", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    dispatch(
      fetchSuspensions({
        page: 1,
        limit: limit,
        search: searchValue,
      })
    );
  }, [dispatch]);

  return (
    <div>
      <div className="flex overflow-x-auto scrollbar-hide lg:grid grid-cols-4 gap-3">
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">
              SUSPENDED USERS
            </p>
            <p className="text-2xl text-[#374146]">
              {counts?.total_miners +
                counts?.total_off_taker +
                counts?.total_vendors}
            </p>
          </div>
          <div>
            <MoneyIcon />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">
              SUSPENDED MINERS
            </p>
            <p className="text-2xl text-[#374146]">{counts?.total_miners}</p>
          </div>
          <div>
            <MoneyIcon />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              SUSPENDED OFF-TAKERS
            </p>
            <p className="text-2xl text-[#374146]">{counts?.total_off_taker}</p>
          </div>
          <div>
            <MoneyIcon />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              SUSPENDED VENDORS
            </p>
            <p className="text-2xl text-[#374146]"> {counts?.total_vendors} </p>
          </div>
          <div>
            <MoneyIcon />
          </div>
        </Stat>
      </div>
      <div className="flex lg:flex flex-rols-1 gap-3">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6">
          <div className="overflow-hidden border border-dark-gray-100 sm:rounded-lg bg-white">
            {/* input field goes here */}
            <div className="flex justify-between p-4">
              <form className="flex gap-2" onSubmit={handleSearch}>
                <div className="w-[250px] lg:w-[548px]">
                  <Search
                    placeholder="Search suspension records"
                    value={searchValue}
                    onChange={handleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="bg-primary flex items-center justify-center rounded py-1 px-2 h-[43px]"
                >
                  <SearchIcon color="#ffff" opacity="1" />
                </button>
              </form>
              <div className="w-[183px] h-[44px] ">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    exportcsv();
                  }}
                  startIcon={<DownloadIcon />}
                >
                  Export CSV
                </Button>
              </div>
            </div>
            <div className="mt-4">
              <SuspensionAdminTable
                openModal={handleOpen}
                openSuspensionModal={handleOpenSuspension}
                data={suspensions}
                opened={opened}
                openSuspend={openSuspend}
                setOpened={setOpened}
                setopenSuspend={setopenSuspend}
                suspendUser={suspendUser}
                deleteUser={deleteUser}
                handleDeleteOpen={handleDeleteOpen}
                handleClosed={handleClosed}
                handleClosedSuspension={handleClosedSuspension}
                handleSetPerPage={handleSetPerPage}
                handleChangePage={handleChangePage}
                handleSearchInput={handleChange}
                handleSearchClick={handleSearch}
                pagination={pagination}
                loading={loading}
              />
            </div>
            <ControlledModal
              isOpen={open}
              handleClose={handleClose}
              className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[30%]"
            >
              <div className="p-10">
                <p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434] mb-6">
                  Send Message
                </p>
                <Input
                  label="Heading"
                  placeholder="Enter the title of the heading"
                  name="subject"
                  onChange={handleInputChange}
                />
                <TextArea
                  label="Content"
                  placeholder="Type message content here"
                  name="content"
                  onChange={handleInputChange}
                />
                <Input
                  label="Action URL"
                  placeholder="https://takeaction.com"
                  name="url"
                  onChange={handleInputChange}
                />

                <div className="flex flex-row justify-end gap-2 mt-8">
                  <Button
                    variant="text"
                    color="neutral"
                    onClick={() => setOpen(!open)}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      sendMessage();
                    }}
                  >
                    Send message
                  </Button>
                </div>
              </div>
            </ControlledModal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Suspensions;
