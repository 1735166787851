import React from 'react';
import PropTypes from 'prop-types';

const ChatBubble=({message, time, bgclass})=> {
    ChatBubble.propTypes = {
        message: PropTypes.string.isRequired,
        time: PropTypes.string.isRequired,
        bgclass: PropTypes.string.isRequired,
}

  return (
    <div className={bgclass}>
      <div className="chat-bubble-text">
        {message}
        <div className="chat-bubble-time" >
        {time.substring(time.indexOf(" "))}
      </div>
      </div>
     
    </div>
  );
}
export default ChatBubble;
