/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import { useState } from "react";

const Review = () => {
  const { inventoryProduct } = useSelector((state) => state.inventories);
  const objectUrl = URL.createObjectURL(inventoryProduct?.product_image[0]);
  const shipmentOptions = inventoryProduct.product_shipment_options
    .join(" /  ")
    .split(" ");

  return (
    <div className=" mx-auto">
      <h2 className="text-heading text-2xl mb-2">Review</h2>
      <p className="text-ts font-light mt-2 mb-5">
        Confirm that the product details are correct before proceeding.
      </p>
      <div className="mb-4 w-[331px] h-[217px]">
        <img
          className="h-full object-cover"
          src={objectUrl}
          alt="daniOla product image"
        />
      </div>

      <div className="grid grid-cols-4 gap-y-4">
        <div className="col-span-1 font-light text-ts">
          <p>Product name:</p>
        </div>
        <div className="col-span-3 font-light text-light">
          <p>{inventoryProduct.product_name}</p>
        </div>
        <div className="col-span-1 font-light text-ts">
          <p>Mineral type:</p>
        </div>
        <div className="col-span-3 font-light text-light capitalize">
          <p>{inventoryProduct.mineral_slug.name}</p>
        </div>
        <div className="col-span-1 font-light text-ts">
          <p>Source mine:</p>
        </div>
        <div className="col-span-3 font-light text-light">
          <p>{inventoryProduct.mine_id.name}</p>
        </div>
        <div className="col-span-1 font-light text-ts">
          <p>Description:</p>
        </div>
        <div className="col-span-3 font-light text-light">
          <p>{inventoryProduct.product_description}</p>
        </div>
        <div className="col-span-1 font-light text-ts">
          <p>Quantity:</p>
        </div>
        <div className="col-span-3 font-light text-light">
          <p>
            {inventoryProduct.product_quantity}{" "}
            {inventoryProduct.unit_of_measurement.id}
          </p>
        </div>

        <div className="col-span-1 font-light text-ts">
          <p>TC:</p>
        </div>
        <div className="col-span-3 font-light text-light">
          <p>
            {inventoryProduct.product_treatment_cost} USD/
            {inventoryProduct.unit_of_measurement.id}
          </p>
        </div>
        <div className="col-span-1 font-light text-ts">
          <p>Price:</p>
        </div>
        <div className="col-span-3 font-light text-light">
          <p>
            {inventoryProduct.product_price} USD/
            {inventoryProduct.unit_of_measurement.id}
          </p>
        </div>
        <div className="col-span-1 font-light text-ts">
          <p>Total Value:</p>
        </div>
        <div className="col-span-3 font-light text-light">
          <p>{inventoryProduct.product_total_value} USD</p>
        </div>
        <div className="col-span-1 font-light text-ts">
          <p>Shipment method:</p>
        </div>
        <div className="col-span-3 font-light text-light">
          <p>
            {shipmentOptions.map((option, index) => (
              <span key={index}>{option}&nbsp;</span>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Review;
