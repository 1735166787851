/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Activities } from "@components";
import { Card, Button, Stats } from "@ui";
import { LineChartIcon } from "@ui/icons";
import { MinerAnalyticsChart, MinerInventoryChart } from "@ui/charts";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchDashboardActivities,
  fetchDashboardRecordsCard,
  fetchAnalyticsChart,
  fetchInventoryChart,
} from "store/slices/miners/analytics-slice";

import { useAuth } from "@hooks";

const Dashboard = () => {
  const navigate = useNavigate();
  const { activities, record_card, analytics, inventory } = useSelector(
    (state) => state.analytics
  );
  const { profileStatus } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDashboardActivities());
    dispatch(fetchDashboardRecordsCard());
    dispatch(fetchAnalyticsChart());
    dispatch(fetchInventoryChart());
  }, [dispatch]);

  return (
    <div>
      <div className="flex overflow-x-auto scrollbar-hide lg:grid grid-cols-4 gap-3">
        <Stats record_card={record_card} />
      </div>
      {/* CHARTS */}
      <div className="flex flex-col lg:grid grid-cols-6 gap-6 mt-6">
        <div className="col-span-4">
          <Card>
            <div className="flex items-center justify-between w-full mb-12">
              <p className="text-heading text-lg">Analytics</p>
            </div>

            <div className="mx-auto min-h-[324px]">
              {profileStatus === "verified" ? (
                <>
                  <MinerAnalyticsChart analytics={analytics} />
                </>
              ) : (
                <div className="flex flex-col items-center justify-center h-[324px]">
                  <LineChartIcon />
                  <p className="font-light text-center mt-6 text-light">
                    This is where your sales and transactions analytics will
                    appear
                  </p>
                </div>
              )}
            </div>
          </Card>
        </div>
        <div className="col-span-2">
          <Card>
            <div className="flex items-center justify-between w-full mb-12">
              <p className="text-heading text-lg">Inventory</p>
              {profileStatus === "verified" && (
                <Button
                  color="neutral"
                  variant="outlined"
                  fontWeight="font-light"
                  onClick={() => navigate("/miner/inventory")}
                >
                  View more
                </Button>
              )}
            </div>
            <div className="mx-auto min-h-[324px]">
              {profileStatus === "verified" ? (
                <>
                  <MinerInventoryChart inventory={inventory} />
                </>
              ) : (
                <div className="flex flex-col items-center justify-center h-[324px]">
                  <svg
                    width="60"
                    height="60"
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.5 12.5C40.5 13 46.75 19.5 47.5 27.5H32.5V12.5ZM31.25 10C30.75 10 30.5 10 30 10V30H50C50 29.5 50 29.25 50 28.75C50 18.5 41.5 10 31.25 10Z"
                      fill="#1C3454"
                      fillOpacity="0.26"
                    />
                    <path
                      d="M27.5 32.5V12.5C17.75 13.25 10 21.25 10 31.25C10 41.5 18.5 50 28.75 50C38.75 50 46.75 42.25 47.5 32.5H27.5Z"
                      fill="#1C3454"
                      fillOpacity="0.26"
                    />
                  </svg>
                  <p className="font-light text-center mt-6 text-light">
                    This is where your inventory analytics will appear{" "}
                  </p>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
      {/* ACTIVITY  */}
      <div className="flex flex-col lg:grid grid-cols-6 gap-6 mt-8">
        <div className="col-span-6">
          <Card>
            <p className="text-heading text-lg">Recent activity</p>
            <div className="min-h-[324px]">
              {profileStatus === "verified" ? (
                <Activities activities={activities} />
              ) : (
                <div className="flex flex-col items-center justify-center h-[324px]">
                  <svg
                    width="60"
                    height="60"
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 25H40V27.5H20V25Z"
                      fill="#1C3454"
                      fillOpacity="0.26"
                    />
                    <path
                      d="M20 30H40V32.5H20V30Z"
                      fill="#1C3454"
                      fillOpacity="0.26"
                    />
                    <path
                      d="M20 35H32.5V37.5H20V35Z"
                      fill="#1C3454"
                      fillOpacity="0.26"
                    />
                    <path
                      d="M37.5 12.5V10H22.5V12.5H17.5V15H15V50H45V47.5H47.5V12.5H37.5ZM25 12.5H35V17.5H25V12.5ZM42.5 47.5H17.5V17.5H22.5V20H37.5V17.5H42.5V47.5Z"
                      fill="#1C3454"
                      fillOpacity="0.26"
                    />
                  </svg>{" "}
                  <p className="font-light text-center mt-6 text-light">
                    No recent activity{" "}
                  </p>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
