/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import Button from "components/ui/Button";
import SearchIcon from "components/ui/icons/SearchIcon";
import SlidersIcon from "components/ui/icons/SlidersIcon";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "@ui";
import { useAuth } from "@hooks";
import Sort from "components/ui/icons/Sort";
import { rolesTableHeaders } from "constants/index";
import React,{ useEffect, useState } from "react";
import { fetchRoles, searchRoles } from "@slices/roles-permissions-slice";
import { useNavigate } from "react-router-dom";
import TrashIcon from "../../../../components/ui/icons/TrashIcon";
import ToggledRoleInfoSection from "./ToggledRoleInfoSection";
import { DropDownIcon } from "@ui/icons";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DeleteRoleModal } from "./DeleteRoles";

function Roles() {
  const profiles = useAuth();
 
  const [open, setOpen] = useState(false);
  const [role_ids] = useState([]);
  const roles = useSelector((state) => state.roles_permissions);
  const { loading } = useSelector((state) => state.roles_permissions);
  const [limit, setLimit] = useState(10);
  const [searchParams, setParams] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchRoles({
        id: profiles?.account?._id,
        page: 1,
        limit: limit,
      })
    );
  }, [dispatch,profiles?.account?._id]);

  const handleSetPerPage = (e) => {
    setLimit(e.target.value);
    dispatch(
      fetchRoles({
        id: profiles?.account?._id,
        page: 1,
        limit: e.target.value,
      })
    );
  };

  function handleChangePage(page) {
    dispatch(
      fetchRoles({
        id: profiles?.account?._id,
        page: page,
        limit: limit,
      })
    );
  }
  const handleSearch = (e) => {
    if (e.target.value == "") {
      dispatch(
        fetchRoles({
          id: profiles?.account?._id,
          page: 1,
          limit: limit,
        })
      );
    }

    setParams(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    dispatch(
      searchRoles({
        search: searchParams,
        id: profiles?.account?._id,
      })
    );
  };

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;

    if (checked) {
      role_ids.push(name);
    } else {
      let index = role_ids.indexOf(name);
      if (index !== -1) {
        role_ids.splice(index, 1);
      }
    }
  };

  const deleteRoles = () => {
    setOpen(true);
  };

  return (
    <>
      <DeleteRoleModal open={open} setOpen={setOpen} data={role_ids} />
      <div className="overflow-x-auto scrollbar-hide">
        <div className="bg-white p-6 rounded-sm min-w-[1024px] lg:w-full overflow-x-hidden ">
          <div className="flex justify-between mb-4">
            <div className="flex-7 flex">
              <form
                onSubmit={(e) => handleSearchSubmit(e)}
                className="flex gap-2"
              >
                <div className="w-[250px] lg:w-[538px]">
                  <Search
                    placeholder="Search roles"
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
                <Button type="submit" color="primary">
                  <SearchIcon color="#ffff" opacity="1" />
                </Button>
              </form>

              <div className="flex items-center bg-white ml-6">
                <Button
                  variant="text"
                  color="neutral"
                  fontWeight="font-light"
                  endIcon={<SlidersIcon />}
                >
                  Filter
                </Button>
              </div>
            </div>
            <div className="flex-2"></div>
            <div className="flex-1 flex">
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteRoles()}
              >
                <TrashIcon color="#D9E8FC" /> Delete
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("add")}
              >
                Add Role
              </Button>
            </div>
          </div>

          <div className="min-w-full divide-y divide-[#1A38601A] border-b border-[#1A38601A]">
            <div className="bg-[#EFF0FE]">
              <div className="grid grid-cols-9">
                <h3 className="px-6 py-3 col-start-1 col-end-2"></h3>
                {rolesTableHeaders.map((header, index) => (
                  <h3
                    key={index + 1}
                    className={`px-6 py-3 text-left text-sm font-normal text-primary uppercase tracking-wider ${header.col}`}
                  >
                    {!header.hasIcon && header.title}
                    {header.hasIcon && (
                      <div className="flex  items-center">
                        <span>{header.title}</span>
                        <Sort />
                      </div>
                    )}
                  </h3>
                ))}
                <h3 className="px-6 py-3 col-start-8 col-end-9"></h3>
                <h3 className="px-3 py-3 col-start-9 col-end-10"></h3>
              </div>
            </div>
            <div className="bg-white font-light divide-y divide-[#1A38601A]">
              {roles.docs.map((fake, index) => (
                <ToggledRoleInfoSection
                  key={index}
                  props={fake}
                  handleChange={handleChange}
                />
              ))}
            </div>
            {loading ? (
              <div>Loading...</div>
            ) : !roles.docs.length > 0 ? (
              <div className="flex flex-col items-center justify-center h-96">
                <svg
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M45 16.25C45 18.32 38.285 20 30 20C21.715 20 15 18.32 15 16.25C15 14.18 21.715 12.5 30 12.5C38.285 12.5 45 14.18 45 16.25Z"
                    fill="#1C3454"
                    fillOpacity="0.26"
                  />
                  <path
                    d="M30 22.5C21.75 22.5 15 20.75 15 18.75V26.25C15 28.25 21.75 30 30 30C38.25 30 45 28.25 45 26.25V18.75C45 20.75 38.25 22.5 30 22.5Z"
                    fill="#1C3454"
                    fillOpacity="0.26"
                  />
                  <path
                    d="M30 32.5C21.75 32.5 15 30.75 15 28.75V36.25C15 38.25 21.75 40 30 40C38.25 40 45 38.25 45 36.25V28.75C45 30.75 38.25 32.5 30 32.5Z"
                    fill="#1C3454"
                    fillOpacity="0.26"
                  />
                  <path
                    d="M30 42.5C21.75 42.5 15 40.75 15 38.75V46.25C15 48.25 21.75 50 30 50C38.25 50 45 48.25 45 46.25V38.75C45 40.75 38.25 42.5 30 42.5Z"
                    fill="#1C3454"
                    fillOpacity="0.26"
                  />
                </svg>

                <p className="text-heading text-lg mb-2 flex">
                  {" "}
                  No data to display
                </p>
                <p className="text-light font-extralight">
                  Add a new role to get started
                </p>
              </div>
            ) : (
              <div className="flex justify-start lg:justify-end items-center w-full py-4 px-8 text-[13px] uppercase text-[#18273AF0]">
                <div className="flex justify-between gap-2 items-center">
                  <p>Rows per page: {roles?.pagination?.limit}</p>
                  <FormControl sx={{ height: "30px", border: 0 }}>
                    <Select
                      sx={{ height: "30px", border: 0 }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => handleSetPerPage(e)}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="mx-6">
                  1 -{" "}
                  {Math.ceil(
                    roles?.pagination?.total / roles?.pagination?.limit
                  )}{" "}
                  of {roles?.pagination?.page}
                </div>
                <div className="flex justify-between gap-10">
                  {roles?.pagination?.prevPage && (
                    <div
                      className="rotate-90 cursor-pointer"
                      onClick={() =>
                        handleChangePage(roles?.pagination?.prevPage)
                      }
                    >
                      <DropDownIcon />
                    </div>
                  )}
                  {roles?.pagination?.page >=
                  Math.ceil(roles?.pagination?.total / roles?.pagination?.limit)
                    ? null
                    : roles?.pagination?.page + 1 && (
                        <div
                          className="-rotate-90 cursor-pointer"
                          onClick={() =>
                            handleChangePage(roles?.pagination?.nextPage)
                          }
                        >
                          <DropDownIcon />
                        </div>
                      )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Roles;
