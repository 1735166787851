const DashBoardIcon = () => {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7051 8.1C16.7051 3.7 13.1051 0 8.70508 0C4.30508 0 0.705078 3.7 0.705078 8.1C0.705078 9.5 1.00508 11 1.60508 12H6.50508C7.00508 12.6 7.80508 13 8.70508 13C9.60508 13 10.4051 12.6 10.9051 12H15.8051C16.4051 11 16.7051 9.5 16.7051 8.1ZM14.7051 5V6L10.6051 9.5C10.6051 9.6 10.7051 9.8 10.7051 10C10.7051 11.1 9.80508 12 8.70508 12C7.60508 12 6.70508 11.1 6.70508 10C6.70508 8.9 7.60508 8 8.70508 8C9.00508 8 9.30508 8.1 9.50508 8.2L13.7051 5H14.7051ZM10.7051 2H11.7051V3H10.7051V2ZM5.70508 2H6.70508V3H5.70508V2ZM2.70508 10H1.70508V9H2.70508V10ZM3.70508 6H2.70508V5H3.70508V6ZM15.7051 10H14.7051V9H15.7051V10Z"
        fill="#1C304A"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default DashBoardIcon;
