import { useState, useEffect } from "react";
import { Hero, Activities } from "@components";
import {
  Card,
  //  Button,
  StatsOfftaker,
} from "@ui";
import { OfftakerLineChart } from "@ui/charts";
import { LineChartIcon } from "@ui/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchDashboardRecordsCard,
  fetchDashboardActivities,
  fetchDashboardAnalyticsChart,
} from "store/slices/offtakers/analytics-slice";

const OffTakerDashboard = () => {
  const { record_card, activities, analytics } = useSelector(
    (state) => state.offtaker_analytics
  );
  const [isVerified] = useState(true);
  const [underReview] = useState(false);
  const [banner, setBanner] = useState(true);

  const handleClose = () => {
    setBanner(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDashboardRecordsCard());
    dispatch(fetchDashboardActivities());
    dispatch(fetchDashboardAnalyticsChart());
  }, [dispatch]);

  return (
    <div>
      {banner && (
        <div className="mb-6">
          <Hero
            isVerified={isVerified}
            underReview={underReview}
            close={handleClose}
          />
        </div>
      )}
      <div className="flex overflow-x-auto scrollbar-hide lg:grid grid-cols-4 gap-3">
        <StatsOfftaker
          record_card={record_card}
          isVerified={isVerified}
          underReview={underReview}
        />
      </div>
      {/* CHARTS */}
      <div className="grid grid-cols-6 gap-4 mt-6">
        <div className="col-span-6">
          <Card>
            <div className="flex items-center justify-between w-full mb-12">
              <p className="text-heading text-lg">Analytics</p>
            </div>
            <div className="mx-auto min-h-[324px]">
              {isVerified && !underReview ? (
                <OfftakerLineChart analytics={analytics} />
              ) : (
                <div className="flex flex-col items-center justify-center h-[324px]">
                  <LineChartIcon />
                  <p className="font-light text-center mt-6 text-light">
                    This is where your sales and transactions analytics will
                    appear
                  </p>
                </div>
              )}
            </div>
          </Card>
        </div>
        <div className="col-span-2"></div>
      </div>
      {/* ACTIVITY & NOTIFICATION */}
      <div className="flex flex-col lg:grid grid-cols-6 gap-6 mt-8">
        <div className="col-span-6">
          <Card>
            <p className="text-heading text-lg">Recent activity</p>
            <div className="min-h-[324px]">
              {isVerified && !underReview ? (
                <Activities activities={activities} />
              ) : (
                <div className="flex flex-col items-center justify-center h-[324px]">
                  <svg
                    width="60"
                    height="60"
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 25H40V27.5H20V25Z"
                      fill="#1C3454"
                      fillOpacity="0.26"
                    />
                    <path
                      d="M20 30H40V32.5H20V30Z"
                      fill="#1C3454"
                      fillOpacity="0.26"
                    />
                    <path
                      d="M20 35H32.5V37.5H20V35Z"
                      fill="#1C3454"
                      fillOpacity="0.26"
                    />
                    <path
                      d="M37.5 12.5V10H22.5V12.5H17.5V15H15V50H45V47.5H47.5V12.5H37.5ZM25 12.5H35V17.5H25V12.5ZM42.5 47.5H17.5V17.5H22.5V20H37.5V17.5H42.5V47.5Z"
                      fill="#1C3454"
                      fillOpacity="0.26"
                    />
                  </svg>{" "}
                  <p className="font-light text-center mt-6 text-light">
                    No recent activity{" "}
                  </p>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default OffTakerDashboard;
