/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TicketModal } from "components/ticket/TicketModal";

const OfftakersFaq = () => {
  const navigate = useNavigate();
  let [curToggleTab, setCurToggleTab] = useState(1);
  function backtosupport() {
    navigate("../../off-taker/support");
  }
  const [open, setOpen] = useState(false);
  const openTicket = async () => {
    setOpen(true);
  };

  const toggleTab = (id) => {
    let curId = curToggleTab;

    document.getElementById("tabcontent" + curId).classList.add("hidden");
    document.getElementById("tab" + curId).classList.remove("bg-dark-gray-100");
    document.getElementById("circle" + curId).classList.remove("bg-primary");
    document.getElementById("circle" + curId).classList.add("bg-dark-gray-400");
    document.getElementById("path" + curId).setAttribute("fill", "#d9eafc");

    document.getElementById("tabcontent" + id).classList.remove("hidden");
    document.getElementById("tab" + id).classList.add("bg-dark-gray-100");
    document.getElementById("circle" + id).classList.remove("bg-dark-gray-400");
    document.getElementById("circle" + id).classList.add("bg-primary");
    document.getElementById("path" + id).setAttribute("fill", "#6166B6");

    setCurToggleTab((curToggleTab = id));
  };

  return (
    <>
      <TicketModal open={open} setOpen={setOpen} role={"offtaker"}></TicketModal>
      <div className="mt-10">
        <p className="text-2xl my-6">How can we help?</p>
        <div className="mb-8 flex justify-around lg:justify-end lg:mb-4">
          <div className="flex">
            <button
              className="button contained primary font-normal rounded-lg mx-2"
              onClick={backtosupport}
            >
              Back to Support
            </button>
          </div>
        </div>
        <div className="border border-solid border-dark-gray-100 py-5 px-4 rounded-md bg-white p-[30px] lg:px-[50px] py-10 rounded-md bg-white border border-[#1a3860]/10 mb-6">
          <div className="flex justify-center items-center">
            <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 mb-4">
              <svg
                width="81"
                height="81"
                viewBox="0 0 81 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="flex-grow-0 flex-shrink-0 w-[81px] h-[81px]"
                preserveAspectRatio="none"
              >
                <circle
                  cx="40.5"
                  cy="40.5"
                  r="40.5"
                  fill="#6166B6"
                  fillOpacity="0.1"
                ></circle>
                <path
                  d="M42.6667 43.3334H39.3333C39.3333 40 41.3333 39 42.6667 38.3334C43.1667 38.1667 43.5 38 43.8333 37.6667C44 37.5 44.3333 37.1667 44 36.5C43.6667 35.6667 42.6667 34.8334 41.1667 34.8334C38.8333 34.8334 38.5 36.8334 38.3333 37.3334L35 36.8334C35.1667 35 36.6667 31.5 41 31.5C43.6667 31.5 46 33 47 35.1667C47.6667 37 47.3333 38.8334 46 40.1667C45.3333 40.8334 44.6667 41.1667 44 41.3334C43 42 42.6667 41.6667 42.6667 43.3334Z"
                  fill="#6166B6"
                ></path>
                <path
                  d="M40.9993 28.3333C47.4993 28.3333 52.666 33.5 52.666 40C52.666 46.5 47.4993 51.6667 40.9993 51.6667C34.4993 51.6667 29.3327 46.5 29.3327 40C29.3327 33.5 34.4993 28.3333 40.9993 28.3333ZM40.9993 26.6667C33.666 26.6667 27.666 32.6667 27.666 40C27.666 47.3333 33.666 53.3333 40.9993 53.3333C48.3327 53.3333 54.3327 47.3333 54.3327 40C54.3327 32.6667 48.3327 26.6667 40.9993 26.6667Z"
                  fill="#6166B6"
                ></path>
                <path
                  d="M39.166 45H42.4993V48.3333H39.166V45Z"
                  fill="#6166B6"
                ></path>
              </svg>
              <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                <p className="flex-grow-0 flex-shrink-0 text-lg text-left text-black">
                  FAQs
                </p>
              </div>
            </div>
          </div>
          <p className="my-8 text-3xl font-bold">Frequently Asked Questions</p>
          <div className="my-6 bg-dark-gray-100 p-4 lg:p-10">
            <div className="flex flex-col lg:flex-row">
              <div className="basis-3/5 bg-white">
                <div
                  id="tab1"
                  className="bg-dark-gray-100 flex flex-row justify-between p-4"
                >
                  <div className="flex flex-row basis-11/12 items-center">
                    <div
                      onClick={() => toggleTab(1)}
                      className="flex justify-between flex-row items-center"
                    >
                      <div
                        id="circle1"
                        className="shrink-0 grow-0 rounded-full bg-primary flex items-center justify-center h-[15px] w-[15px] "
                      ></div>
                      <p className="ml-3">
                        Are all the documents requested for upload compulsory?{" "}
                      </p>
                    </div>
                  </div>
                  <p className="flex basis-1/12 justify-end">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-10 h-10 relative"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path
                        id="path1"
                        d="M15.5056 11.5909L12.8176 8.59089C12.5056 8.23089 12.5296 7.65489 12.9136 7.31889C13.2736 7.0069 13.8496 7.0309 14.1616 7.41489L18.1696 11.9029C18.4816 12.2629 18.4816 12.7669 18.1696 13.1029L14.1616 17.5909C13.8496 17.9749 13.2736 17.9989 12.9136 17.6629C12.5296 17.3509 12.5056 16.7749 12.8176 16.4149L15.5056 13.3909H7.48956C7.00956 13.3909 6.60156 13.0069 6.60156 12.5029C6.60156 11.9989 7.00956 11.5909 7.48956 11.5909H15.5056Z"
                        fill="#6166B6"
                      ></path>
                    </svg>
                  </p>
                </div>
                <div id="tab2" className="flex flex-row justify-between p-4">
                  <div className="flex flex-row basis-11/12 items-center">
                    <div
                      onClick={() => toggleTab(2)}
                      className="flex justify-between flex-row items-center"
                    >
                      <div
                        id="circle2"
                        className="font-bold text-gray-700 rounded-full bg-dark-gray-400 flex items-center justify-center font-mono h-[15px] w-[15px]"
                      ></div>
                      <p className="ml-3">
                        Can I list all the minerals I have for auction at once?
                      </p>
                    </div>
                  </div>
                  <p className="flex basis-1/12 justify-end">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-10 h-10 relative"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path
                        id="path2"
                        d="M15.5056 11.5909L12.8176 8.59089C12.5056 8.23089 12.5296 7.65489 12.9136 7.31889C13.2736 7.0069 13.8496 7.0309 14.1616 7.41489L18.1696 11.9029C18.4816 12.2629 18.4816 12.7669 18.1696 13.1029L14.1616 17.5909C13.8496 17.9749 13.2736 17.9989 12.9136 17.6629C12.5296 17.3509 12.5056 16.7749 12.8176 16.4149L15.5056 13.3909H7.48956C7.00956 13.3909 6.60156 13.0069 6.60156 12.5029C6.60156 11.9989 7.00956 11.5909 7.48956 11.5909H15.5056Z"
                        fill="#d9eafc"
                      ></path>
                    </svg>
                  </p>
                </div>
                <div id="tab3" className="flex flex-row justify-between p-4">
                  <div className="flex flex-row basis-11/12 items-center">
                    <div
                      onClick={() => toggleTab(3)}
                      className="flex justify-between flex-row items-center"
                    >
                      <div
                        id="circle3"
                        className="font-bold text-gray-700 rounded-full bg-dark-gray-400 flex items-center justify-center font-mono h-[15px] w-[15px]"
                      ></div>
                      <p className="ml-3">
                        Is there a timeline for product availability on the
                        platform?
                      </p>
                    </div>
                  </div>
                  <p className="flex basis-1/12 justify-end">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-10 h-10 relative"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path
                        id="path3"
                        d="M15.5056 11.5909L12.8176 8.59089C12.5056 8.23089 12.5296 7.65489 12.9136 7.31889C13.2736 7.0069 13.8496 7.0309 14.1616 7.41489L18.1696 11.9029C18.4816 12.2629 18.4816 12.7669 18.1696 13.1029L14.1616 17.5909C13.8496 17.9749 13.2736 17.9989 12.9136 17.6629C12.5296 17.3509 12.5056 16.7749 12.8176 16.4149L15.5056 13.3909H7.48956C7.00956 13.3909 6.60156 13.0069 6.60156 12.5029C6.60156 11.9989 7.00956 11.5909 7.48956 11.5909H15.5056Z"
                        fill="#d9eafc"
                      ></path>
                    </svg>
                  </p>
                </div>
                <div id="tab4" className="flex flex-row justify-between p-4">
                  <div className="flex flex-row basis-11/12 items-center">
                    <div
                      onClick={() => toggleTab(4)}
                      className="flex justify-between flex-row items-center"
                    >
                      <div
                        id="circle4"
                        className="font-bold text-gray-700 rounded-full bg-dark-gray-400 flex items-center justify-center font-mono h-[15px] w-[15px]"
                      ></div>
                      <p className="ml-3">
                        Can I place bids for metals on the portal?
                      </p>
                    </div>
                  </div>
                  <p className="flex basis-1/12 justify-end">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-10 h-10 relative"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path
                        id="path4"
                        d="M15.5056 11.5909L12.8176 8.59089C12.5056 8.23089 12.5296 7.65489 12.9136 7.31889C13.2736 7.0069 13.8496 7.0309 14.1616 7.41489L18.1696 11.9029C18.4816 12.2629 18.4816 12.7669 18.1696 13.1029L14.1616 17.5909C13.8496 17.9749 13.2736 17.9989 12.9136 17.6629C12.5296 17.3509 12.5056 16.7749 12.8176 16.4149L15.5056 13.3909H7.48956C7.00956 13.3909 6.60156 13.0069 6.60156 12.5029C6.60156 11.9989 7.00956 11.5909 7.48956 11.5909H15.5056Z"
                        fill="#d9eafc"
                      ></path>
                    </svg>
                  </p>
                </div>
                <div id="tab5" className="flex flex-row justify-between p-4">
                  <div
                    onClick={() => toggleTab(5)}
                    className="flex flex-row basis-11/12 items-center"
                  >
                    <div className="flex justify-between flex-row items-center">
                      <div
                        id="circle5"
                        className="font-bold text-gray-700 rounded-full bg-dark-gray-400 flex items-center justify-center font-mono h-[15px] w-[15px]"
                      ></div>
                      <p className="ml-3">How do you protect my data?</p>
                    </div>
                  </div>
                  <p className="flex basis-1/12 justify-end">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-10 h-10 relative"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path
                        id="path5"
                        d="M15.5056 11.5909L12.8176 8.59089C12.5056 8.23089 12.5296 7.65489 12.9136 7.31889C13.2736 7.0069 13.8496 7.0309 14.1616 7.41489L18.1696 11.9029C18.4816 12.2629 18.4816 12.7669 18.1696 13.1029L14.1616 17.5909C13.8496 17.9749 13.2736 17.9989 12.9136 17.6629C12.5296 17.3509 12.5056 16.7749 12.8176 16.4149L15.5056 13.3909H7.48956C7.00956 13.3909 6.60156 13.0069 6.60156 12.5029C6.60156 11.9989 7.00956 11.5909 7.48956 11.5909H15.5056Z"
                        fill="#d9eafc"
                      ></path>
                    </svg>
                  </p>
                </div>
                <div id="tab6" className="flex flex-row justify-between p-4">
                  <div className="flex flex-row basis-11/12 items-center">
                    <div
                      onClick={() => toggleTab(6)}
                      className="flex justify-between flex-row items-center"
                    >
                      <div
                        id="circle6"
                        className="font-bold text-gray-700 rounded-full bg-dark-gray-400 flex items-center justify-center font-mono h-[15px] w-[15px]"
                      ></div>
                      <p className="ml-3">
                        I have multiple mines producing the same mineral, can I
                        register for each mine on the portal? Would I pay for
                        each registered mine?
                      </p>
                    </div>
                  </div>
                  <p className="flex basis-1/12 justify-end">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-10 h-10 relative"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path
                        id="path6"
                        d="M15.5056 11.5909L12.8176 8.59089C12.5056 8.23089 12.5296 7.65489 12.9136 7.31889C13.2736 7.0069 13.8496 7.0309 14.1616 7.41489L18.1696 11.9029C18.4816 12.2629 18.4816 12.7669 18.1696 13.1029L14.1616 17.5909C13.8496 17.9749 13.2736 17.9989 12.9136 17.6629C12.5296 17.3509 12.5056 16.7749 12.8176 16.4149L15.5056 13.3909H7.48956C7.00956 13.3909 6.60156 13.0069 6.60156 12.5029C6.60156 11.9989 7.00956 11.5909 7.48956 11.5909H15.5056Z"
                        fill="#d9eafc"
                      ></path>
                    </svg>
                  </p>
                </div>
                <div id="tab7" className="flex flex-row justify-between p-4">
                  <div className="flex flex-row basis-11/12 items-center">
                    <div
                      onClick={() => toggleTab(7)}
                      className="flex justify-between flex-row items-center"
                    >
                      <div
                        id="circle7"
                        className="font-bold text-gray-700 rounded-full bg-dark-gray-400 flex items-center justify-center font-mono h-[15px] w-[15px]"
                      ></div>
                      <p className="ml-3">
                        Can I sign up as an offtaker and place bids for
                        products?
                      </p>
                    </div>
                  </div>
                  <p className="flex basis-1/12 justify-end">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-10 h-10 relative"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path
                        id="path7"
                        d="M15.5056 11.5909L12.8176 8.59089C12.5056 8.23089 12.5296 7.65489 12.9136 7.31889C13.2736 7.0069 13.8496 7.0309 14.1616 7.41489L18.1696 11.9029C18.4816 12.2629 18.4816 12.7669 18.1696 13.1029L14.1616 17.5909C13.8496 17.9749 13.2736 17.9989 12.9136 17.6629C12.5296 17.3509 12.5056 16.7749 12.8176 16.4149L15.5056 13.3909H7.48956C7.00956 13.3909 6.60156 13.0069 6.60156 12.5029C6.60156 11.9989 7.00956 11.5909 7.48956 11.5909H15.5056Z"
                        fill="#d9eafc"
                      ></path>
                    </svg>
                  </p>
                </div>
              </div>
              <div className="basis-2/5">
                <div id="tabcontent1" className="pl-8 py-4">
                  <p className="text-xl font-bold mb-4">
                    Are all the documents requested for upload compulsory?
                  </p>
                  <p>Yes, as this is part of our due diligence process.</p>
                </div>
                <div id="tabcontent2" className="pl-8 py-4 hidden">
                  <p className="text-xl font-bold mb-4">
                    Can I list all the minerals I have for auction at once?
                  </p>
                  <p>
                    Yes, use our trade platform to list current offers and
                    receive offers.
                  </p>
                </div>
                <div id="tabcontent3" className="pl-8 py-4 hidden">
                  <p className="text-xl font-bold mb-4">
                    Is there a timeline for product availability on the
                    platform?
                  </p>
                  <p>You decide the timeline for product availability.</p>
                </div>
                <div id="tabcontent4" className="pl-8 py-4 hidden">
                  <p className="text-xl font-bold mb-4">
                    Can I place bids for metals on the portal?
                  </p>
                  <p>Absolutely you can bid on offers.</p>
                </div>
                <div id="tabcontent5" className="pl-8 py-4 hidden">
                  <p className="text-xl font-bold mb-4">
                    How do you protect my data?
                  </p>
                  <p>Microsoft is a trusted cloud partner .</p>
                </div>
                <div id="tabcontent6" className="pl-8 py-4 hidden">
                  <p className="text-xl font-bold mb-4">
                    I have multiple mines producing the same mineral, can I
                    register for each mine on the portal? Would I pay for each
                    registered mine?
                  </p>
                  <p>
                    No, you will not be charged, it is free to list multiple
                    mines on Daniola.
                  </p>
                </div>
                <div id="tabcontent7" className="pl-8 py-4 hidden">
                  <p className="text-xl font-bold mb-4">
                    Can I sign up as an offtaker and place bids for products?
                  </p>
                  <p>Yes.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-start items-center mt-[37px] ">
          <p className="text-left mr-6 text-black">
            Didn't find what you were looking for?
          </p>
          <button
            className="button contained primary font-normal rounded-lg mx-2"
            onClick={() => {
              openTicket();
            }}
          >
            Submit a Ticket
          </button>
        </div>
      </div>
    </>
  );
};

export default OfftakersFaq;
