const CaretUp = () => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 7L0 7L5 -4.37114e-07L10 7Z"
        fill="#1C2E45"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default CaretUp;
