import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: false,
      text: "Vendor Analytics Chart",
    },
  },
};

export default function VendorLineChart({ analytics }) {
  const formattedData = useMemo(() => {
    const offeringData = analytics.map((x) => x.offering) || [];
    const projectData = analytics.map((x) => x.project) || [];
    const labels = analytics.map((x) => x.month) || [];
    const data = {
      labels,
      datasets: [
        {
          label: "Offering",
          data: offeringData,
          borderColor: "rgb(97, 102, 182)",
          backgroundColor: "rgba(97, 102, 182, 0.5)",
        },
        {
          label: "Project",
          data: projectData,
          borderColor: "rgb(45, 156, 219)",
          backgroundColor: "rgba(45, 156, 219, 0.5)",
        },
      ],
    };

    return { data, labels };
  }, [analytics]);

  return <Line options={options} data={formattedData.data} />;
}
