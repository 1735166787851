/* eslint-disable no-unused-vars */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import OfferingDetails from "./AddOffering/OfferingDetails";
import Review from "./AddOffering/Review";
import Complete from "./Complete";
import { Button, Stepper } from "@ui";
import { VendorDashboardUrl, VendorOfferingsUrl } from "config/app-url";
import { useDispatch, useSelector } from "react-redux";
import { setAddOfferingStep } from "store/slices/vendors/offerings-slice";

const AddProduct = () => {
  const { offeringActiveStep } = useSelector((store) => store.vendorOfferings);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNext = () => {
    // if (activeStep === 0) {
    // }
    dispatch(setAddOfferingStep(1));
  };

  const handleBack = () => {
    dispatch(setAddOfferingStep(0));
  };

  const handleReset = () => {
    dispatch(setAddOfferingStep(0));
    navigate(VendorOfferingsUrl);
  };

  const [steps] = React.useState([
    {
      label: "Description",
      component: <OfferingDetails />,
    },
    {
      label: "Review",
      component: <Review />,
    },
  ]);

  return (
    <div className="border card border-solid border-dark-gray-100 rounded-md bg-white">
      <div className="grid grid-cols-4 divide-x divide-disabled">
        <div className="col-span-1 py-10 mx-auto">
          <h3 className="text-2xl font-normal text-heading text-center mb-8">
            Add new product
          </h3>
          {/* stepper starts here */}

          <Stepper steps={steps} activeStep={offeringActiveStep} />
          {/* stepper ends here */}
        </div>
        <div className="col-span-3 py-10 px-8">
          {/* stepper contents here */}
          {steps[offeringActiveStep] && (
            <div>{steps[offeringActiveStep].component}</div>
          )}
          {/* stepper contents ends here */}
          {/* content after all steps have been completed */}
          {offeringActiveStep === steps.length && (
            <>
              <Complete />
              <div className="flex justify-center gap-8 mt-8">
                <Button
                  variant="contained"
                  onClick={handleReset}
                  color="neutral"
                >
                  Back to My Offerings
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(VendorDashboardUrl)}
                >
                  Go to Dashboard
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
