/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Btn as Button } from "@atoms/button/button";
import { Link } from "react-router-dom";
import { Input, TextArea, Card, Select } from "@ui";
import { toast } from "react-toastify";
import { Modal, Box } from "@mui/material";
import { submitTicket } from "@slices/settings-slice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

export const TicketModal = ({ setOpen, open = null, role }) => {
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState({
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const sendTicket = async () => {
    let body = {
      subject: data.subject,
      priority: "important",
      category: selectedValue,
      message: data.message,
    };
    setLoading(true);
    try {
      const response = await dispatch(
        submitTicket({ role: role, data: body })
      );
      if (response.payload.status === 200 || response.payload.status === 201) {
        if (
          response.payload.data.code == 400 ||
          response.payload.data.code == 422
        ) {
          toast.error(response.payload.data.message);
          setOpen(false);
          setLoading(false);
        } else {
          toast.success(response.payload.data.message);
          setOpen(false);
          setLoading(false);
        }
      } else {
        console.log("error from then", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center mb-4">
            <h1 className="text-center text-[22px] mt-5 paragraph">
              Submit a Ticket
            </h1>
            <p className="font-light mt-2 paragraph mb-4">
              Fill out the form below, and we 'll assist you the best we can.
            </p>

            <div className="w-[100%] paragraph">
              <Input
                label="Subject"
                id="amount"
                type="text"
                name="subject"
                onChange={handleInputChange}
              />
            </div>
            <div className="w-[100%] paragraph">
              <label
                htmlFor={"Ticket category:"}
                className="form-label inline-block mb-2 font-light text-base text-[#1B2B41B8]"
              >
                Ticket category
              </label>
              <select
                value={selectedValue}
                onChange={handleChange}
                type="dropdown"
                className={`w-full h-[42px] max-h-[42px] p-[10px] border-none font-light text-gray-700 bg-[#1A38601A] rounded z-0 focus:shadow focus:outline-none focus:ring-primary  placeholder:text-[#1C345442] placeholder:font-extralight placeholder:text-base`}
              >
                <option value="">-Select ticket category</option>
                <option value="payment_issue">Payment Issues</option>
                <option value="abuse_report">Abuse report</option>
                <option value="billing_issue">Billing Issue</option>
                <option value="general_issue">General Issue</option>
                <option value="feedback_message">Feedback Message</option>
              </select>
            </div>

            <div className="w-[100%] paragraph">
              <TextArea
                type="text"
                label="Message"
                name="message"
                onChange={handleInputChange}
              />
            </div>
            <div className="flex items-center justify-between mt-8">
              <button
                onClick={() => setOpen(!open)}
                className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
              >
                Cancel
              </button>
              <div>
                <Button
                  styleClass="fund-btn"
                  onClick={() => {
                    sendTicket();
                  }}
                >
                  {loading ? "Processing..." : "Submit Support ticket"}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
