import React from "react";
import { Header } from "@atoms/header";
import { Button } from "@atoms/button/index";
import { Checkmail } from "@atoms/icons";
import { useNavigate } from "react-router-dom";
import { ChangePasswordUrl, ForgotPasswordUrl } from "@config/app-url";

const CheckEmail = () => {
  const navigate = useNavigate();

  return (
    <div className="font-default">
      <Header />

      <div className=" my-4 bg-white p-8 rounded mt-[110px] lg:mt-0 lg:bg-none">
        <div
          onClick={() => navigate(-1)}
          className="text-primary border-none cursor-pointer flex justify-end mb-16"
        >
          {" "}
          Back
        </div>

        <div className="flex justify-center align-center">
          {" "}
          <Checkmail />
        </div>
        <h2 className="font-bold text-2xl text-head w-ful pt-4 flex justify-center align-center ">
          Check your mail
        </h2>

        <p className="text-lightgray pb-4 text-base font-light mt-1 text-center">
          We have sent password recovery instructions to your business email 
          address.
        </p>

        <Button onClick={() => navigate(ChangePasswordUrl)} type="submit">
         CLICK HERE TO CHANGE PASSWORD
        </Button>
        <div className="text-center mt-48">
          Did not recieve the email? Check your spam filter or try
          <button
            className="text-primary pl-2"
            onClick={() => navigate(ForgotPasswordUrl)}
          >
            another email address
          </button>
        </div>
      </div>
    </div>
  );
};
export default CheckEmail;
