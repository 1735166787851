/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Input, TextArea, Checkbox } from "@ui";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, useField, Field, FieldArray } from "formik";
import { SelectField } from "components/ui/Inputs";
import * as API from "@config/url";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Select as MultiSelect } from "@ui";
import TextField from "@mui/material/TextField";
import { getCountries, vendorConfig } from "store/slices/vendors/vendor-slice";
import PropTypes from "prop-types";
import Button from "components/ui/Button";
import * as yup from "yup";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import { getOffering } from "store/slices/vendors/offerings-slice";

const ModifyOffering = () => {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const { countries } = useSelector((store) => store.vendor);

  const { offering } = useSelector((store) => store.vendorOfferings);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const names = [
    "Atlanta",
    "Georgia",
    "Florida",
    "Los angeles",
    "Toronto",
    "Carlos Abbott",
    "Ohio",
    "New jersey",
    "Virginia",
    "Alaska",
  ];

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangee = (event, arrayHelpers, locations) => {
    const {
      target: { value },
    } = event;

    const isFound = locations.some((element) => {
      if (element.iso_code === value.iso_code) {
        return true;
      }

      return false;
    });

    if (!isFound) {
      arrayHelpers.push(value);
    }
    return;
  };

  const validationSchema = yup.object({
    type: yup.string().required(),
    category: yup.string().required(),
    job_title: yup.string().required(),
    description: yup.string().required(),
    // location: yup.string().required(),
    price: yup.number().required(),
  });

  const MyTextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : "";
    return (
      <div>
        <label
          htmlFor={label}
          className="form-label inline-block mb-2 font-light text-sm text-[#1B2B41B8]"
        >
          {label}
        </label>
        <TextField
          sx={{
            width: "100%",
            backgroundColor: "#1A38601A",
            height: "100%",
          }}
          {...field}
          // helperText={errorText}
          // error={!!errorText}
        />
      </div>
    );
  };

  MyTextField.propTypes = {
    label: PropTypes.string.isRequired,
  };

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getOffering(id));
  }, []);

  const initialValues = {
    type: !offering ? "" : offering.type,
    category: !offering ? "" : offering.category,
    job_title: !offering ? "" : offering.job_title,
    description: !offering ? "" : offering.description,
    location: !offering ? [] : offering.location,
    price: !offering ? "" : offering.price,
    quantity: !offering ? "" : offering.quantity,
    agree: false,
  };
  return (
    <>
      <div className="max-w-[796px] mx-auto">
        <h2 className="text-heading text-2xl mb-2">Modify offerings</h2>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setIsLoading(true);
              try {
                const { ["agree"]: agree, ...payload } = values;
                const resp = await axios(
                  vendorConfig("put", API.modifyOffering(id), payload)
                );
                if (resp.status === 200 || resp.status === 201) {
                  if (resp.data.code == 422 || resp.data.code == 400) {
                    toast.error(resp.data.message);
                  } else {
                    toast.success(resp.data.message);
                    navigate("/vendor/offerings");
                  }
                } else {
                  toast.error(resp.payload.msg);
                }
              } catch (error) {
                toast.error("An error occured");
                console.log(error);
              }
              setIsLoading(false);
              setSubmitting(false);
            }}
          >
            {({
              values,
              handleChange,
              isSubmitting,
              handleSubmit,
              setFieldValue,
              errors,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                <h1>{personName}</h1>
                <div className="flex">
                  <div className="mt-6 w-full mr-4">
                    <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                      Offering type
                    </p>
                    <SelectField
                      items={["service", "commodity"]}
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mt-6 w-full">
                    <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                      Category
                    </p>
                    <SelectField
                      items={["logistics", "contractual"]}
                      name="category"
                      value={values.category}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <MyTextField
                    label="Job title"
                    name="job_title"
                    // value={values.job_title}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <TextArea
                    label="Description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  />
                  <div className="flex items-center">
                    <div className="w-full mr-4">
                      <Input
                        label="Quantity"
                        name="quantity"
                        value={values.quantity}
                        onChange={handleChange}
                        type="number"
                      />
                    </div>
                    <div className="flex w-full">
                      <div className="w-full mr-4">
                        <Input
                          label="Offering Price"
                          name="price"
                          value={values.price}
                          isMoneyInput={true}
                          onChange={handleChange}
                          type="number"
                          postfix="/unit"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 w-full mb-11">
                    <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                      Locations
                    </p>
                    <div className="relative w-full">
                      <FieldArray name="location">
                        {(arrayHelpers) => (
                          <div>
                            <Select
                              sx={{
                                width: "100%",
                              }}
                              displayEmpty
                              value={values.location}
                              onChange={(e) =>
                                handleChangee(e, arrayHelpers, values.location)
                              }
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <em>Placeholder</em>;
                                }
                                return;
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              {countries.map((country) => (
                                <MenuItem
                                  key={country.iso_code}
                                  value={country}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {country.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <div className="flex flex-wrap w-full text-[#6166B6] font-light text-[14px] mt-3">
                              {values.location.map((option, index) => (
                                <div
                                  key={index}
                                  className="bg-vendoroptionbg flex justify-between px-2 mr-5"
                                >
                                  <span className="mr-2">{option.name}</span>
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    x
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <Button
                    onClick={() => navigate("/vendor/offerings")}
                    type="button"
                    color="danger"
                  >
                    Cancel
                  </Button>
                  <Button type="submit">
                    {isLoading ? "Loading..." : "Save changes"}
                  </Button>
                </div>

                {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ModifyOffering;
