import { useLocation } from "react-router-dom";

 const usePath = () => {
  let { pathname } = useLocation();
  pathname = pathname.slice(1);
//   pathname = !pathname ? "dashboard" : pathname;

  let pathnames = pathname.split("/");

  return { pathnames };
};

export default usePath