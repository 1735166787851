/* eslint-disable react/prop-types */
const BarChartIcon = ({ color, opacity, h, w }) => {
  const iconColor = color ? color : "#D9E8FC";
  const fillOpacity = opacity ? opacity : "0.7";
  const height = h ? h : "25";
  const width = w ? w : "24";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 19.5V4.5H4V20.5H20V19.5H5Z"
        fill={iconColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M6 12.5H10V18.5H6V12.5Z"
        fill={iconColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M11 6.5H15V18.5H11V6.5Z"
        fill={iconColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M16 10.5H20V18.5H16V10.5Z"
        fill={iconColor}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export default BarChartIcon;
