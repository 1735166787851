/* eslint-disable no-unused-vars */
import Button from "components/ui/Button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "@hooks";
import { fetchPermissions } from "@slices/roles-permissions-slice";
// import { SelectField } from "components/ui/Inputs";
import { Input } from "@ui";
import PropTypes from "prop-types";
import { RoleSuccessModal } from "../add/RoleConfirmModal";
import { SuccessResponse } from "config/status-code";
import { useParams } from "react-router-dom";
import { addRole, getTeamMemberBYID } from "@slices/roles-permissions-slice";

const Role = (id) => {
  // const permission = permissions.permissions.data;
  const profiles = useAuth();
  const [open, setOpen] = useState(false);
  const roles = useSelector((state) => state.roles_permissions);

  let [data, setData] = useState({
    name: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getTeamMemberBYID({
        id: profiles?.account?._id,
        _id: id.id,
      })
    );
  }, [dispatch]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  function createRole() {
    let formData = {
      name: data.name,
    };
    dispatch(addRole(formData)).then((response) => {
      if (response.payload.status === 200) {
        setOpen(true);
        return;
      }
    });
  }

  return (
    <>
      <RoleSuccessModal open={open} setOpen={setOpen} name={data?.name} />
      <div className="bg-white pt-5 rounded-md mb-5">
        <div className="flex justify-between px-5 mb-4">
          <h1>Role</h1>
          {/* <Button
            onClick={() => {
              createRole();
              //   setOpen(!open);
            }}
          >
            Save
          </Button> */}
        </div>
        <Divider />
        <div className="flex justify-center py-8">
          <div className=" w-10/12">
            {/* <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
      Role Title
    </p> */}
            <Input
              type="text"
              id="role_title"
              value={roles?.team_member[0]?.name}
              name="name"
              label="Role Title"
              onChange={handleInputChange}
            />
            {/* <SelectField
      items={["Intern", "Accountant", "MineOfficer", "Technical Officer"]}
      name="role"
      value={values.role}
      onChange={handleChange}
    /> */}
          </div>
        </div>
      </div>
    </>
  );
};

Role.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Role;
