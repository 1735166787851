/* eslint-disable no-unused-vars */
import React from 'react';
import { Input, TextArea } from '@ui';

const BusinessSummary = ({
	errors,
	register,

	mine
}) => {
	return (
		<div className="text-default  w-[896px] h-[798px] bg-white px-[34px]">
			<div className="mb-8">
				<p className="flex-grow-0 flex-shrink-0 text-[22px] mb-2 text-left text-[#192434]">
					Business Summary
				</p>
				<p className="flex-grow-0 flex-shrink-0 w-[796px] text-base font-light text-left text-[#1b2b41]/[0.72]">
					{' '}
					Fill out your business or company details
				</p>
			</div>

			<div>
				<Input
					label="Company Name"
					type="text"
					name="company"
					value={mine?.company_name}
					readOnly
				/>
				<p className="h-2 paragraph text-primary text-xs mt-2">
					{errors.company?.message}
				</p>
			</div>

			{/** Country && Postal code */}

			<Input
				label="Address"
				type="text"
				name="address"
				readOnly
				value={mine?.location?.address}
			/>

			{/* City and State */}
			<div className="flex flex-wrap -mx-3 my-3">
				<div className=" media px-3 mt-2 ">
					<Input
						label="City"
						id="city"
						type="text"
						name="city"
						value={mine?.city}
						readOnly
					/>
				</div>
				<div className=" media px-3 mt-2">
					<Input
						label="State"
						id="state"
						type="text"
						placeholder=""
						name="state"
						value={mine?.state}
						readOnly
					/>
				</div>
			</div>
			{/** Postal code and country */}
			<div className="flex flex-wrap -mx-2">
				<div className="media px-3">
					<Input
						label="Postal code"
						id="postal"
						type="text"
						name="postal_code"
						value={mine?.location?.postal_code}
						readOnly
					/>
				</div>

				<div className="media px-3">
					<Input
						id="country"
						type="text"
						label="Country"
						name="country"
						readOnly
						value={mine?.country?.name}
					/>
				</div>
			</div>
			<div className="mt-2">
				<TextArea
					id="additional_information"
					resizable
					row={7}
					type="textarea"
					label="Additional note"
					name="additional_information"
					// size="mid"
					maxHeight="200px"
					{...register('additional_information')}
				/>
			</div>
		</div>
	);
};

export default BusinessSummary;
