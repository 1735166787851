/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

import { Modal, Box } from '@mui/material';
import { Button } from '@ui';
import Success from 'assets/images/success.svg';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 528,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	borderRadius: 3
};

export const CreateProspectSuccessModal = ({ open, setOpenSubmitModal }) => {
	return (
		<Modal
			open={open}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<div className="text-center mb-4">
					<div className="grid justify-items-center">
						<img src={Success} />
					</div>
					<h1 className="text-[22px] text-head my-4">Submission successful</h1>
					<p className="font-light text-vendorsecondary">
						Your investment interest has been published and you will notified
						when someone shows interest.
					</p>
					<div className="flex items-center justify-center mt-8">
						<Link to="/off-taker/invest/prospects">
							<Button onClick={() => setOpenSubmitModal(false)}>
								Go to Prospects
							</Button>
						</Link>
					</div>
				</div>
			</Box>
		</Modal>
	);
};
