/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {
  Button,
  Search,
  UserAdminsTable,
  Input,
  TextArea,
  TableSkeleton,
  ControlledModal,
} from "@ui";
import { toast } from "react-toastify";

import { DownloadIcon, SearchIcon, AddIcon, BarChartIcon } from "@ui/icons";
import {
  fetchUsers,
  sendAdminMessage,
  deleteAdminUser,
} from "@slices/admin-slice";
import { useDispatch, useSelector } from "react-redux";

const Stat = ({ bgColor, children }) => {
  const backgroundColor = bgColor ? bgColor : "bg-white";
  const boxShadow = bgColor && "shadow-Stat";
  return (
    <div
      className={`flex items-center justify-between border border-solid border-dark-gray-100 px-6 py-5 rounded-lg ${backgroundColor} ${boxShadow}`}
    >
      {children}
    </div>
  );
};

const User = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [uuid, setUUID] = useState("");
  const [acc_id, setaccId] = useState();
  const [limit, setLimit] = useState(10);
  const [opened, setOpened] = useState(false);
  const { users, counts, pagination, loading } = useSelector(
    (state) => state.admin
  );

  const [open, setOpen] = useState(false);
  const handleOpen = (id) => {
    setOpen(true);
    setUUID(id);
  };

  const handleDeleteOpen = (id) => {
    setOpened(true);
    setaccId(id);
  };
  const handleClosed = () => setOpened(false);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    if (!e.target.value) {
      dispatch(
        fetchUsers({
          page: 1,
          limit: limit,
          search: searchValue,
        })
      );
    }
    setSearchValue(e.target.value);
  };
  const dispatch = useDispatch();
  const [data, setData] = useState({
    subject: "",
    content: "",
    url: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    dispatch(
      fetchUsers({
        page: 1,
        limit: limit,
        search: searchValue,
      })
    );
  }, [dispatch]);
  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      fetchUsers({
        page: 1,
        limit: limit,
        search: searchValue,
      })
    );
  };

  const handleSetPerPage = (e) => {
    setLimit(e.target.value);
    dispatch(
      fetchUsers({
        page: 1,
        limit: e.target.value,
        search: searchValue,
      })
    );
  };

  const deleteUser = async () => {
    try {
      const response = await dispatch(deleteAdminUser(acc_id));
      if (response.payload.status === 201 || response.payload.status === 200) {
        dispatch(fetchUsers({ page: 1, limit: limit, search: searchValue }));
        toast.success(response.payload.data.message);
        setOpened(false);
      } else {
        console.log("error from then", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const exportcsv = () => {
    let data = [];
    users.forEach((x) => {
      data.push({
        id: x?._id,
        name: x?.name,
        email: x?.email,
        profile: x?.roles[0]?.name,
        status: x?.status,
        created_at:x?.created_at,
        updated_at:x?.updated_at
      });
    });
    let header = ["ID", "Name", "Email", "Profile", "Status", "Created At","Updated At"];
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "base64" });
    FileSaver.saveAs(finalData, "Users.xlsx");
  };

  const sendMessage = async () => {
    try {
      const response = await dispatch(
        sendAdminMessage({ id: uuid, data: data })
      );
      if (response.payload.status === 200 || response.payload.status === 201) {
        if (
          response.payload.data.code == 400 ||
          response.payload.data.code == 422
        ) {
          toast.error(response.payload.data.message);
          setOpen(false);
        } else {
          dispatch(fetchUsers({ page: 1, limit: limit, search: searchValue }));
          toast.success(response.payload.data.message);
          setOpen(false);
        }
      } else {
        console.log("error from then", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  function handleChangePage(page) {
    dispatch(
      fetchUsers({
        page: page,
        limit: limit,
        search: searchValue,
      })
    );
  }

  return users?.length == 0 ? (
    <TableSkeleton />
  ) : (
    <div className="mt-2">
      <div className="flex overflow-x-auto scrollbar-hide lg:grid grid-cols-4 gap-3">
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">
              total no. of users
            </p>
            <p className="text-2xl text-[#374146]">
              {pagination?.total}
            </p>
          </div>
          <div>
            <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">
              total no. of miners
            </p>
            <p className="text-2xl text-[#374146]">{counts?.total_miners}</p>
          </div>
          <div>
            <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">
              total no. of off-takers
            </p>
            <p className="text-2xl text-[#374146]">{counts?.total_off_taker}</p>
          </div>
          <div>
            <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">
              total no. of vendors
            </p>
            <p className="text-2xl text-[#374146]">{counts?.total_vendors}</p>
          </div>
          <div>
            <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>
      </div>

      <div className="w-40  mt-4">
        <Button
          onClick={() => navigate("./register-user")}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          fullWidth
        >
          Register new user
        </Button>
      </div>
      <div className="flex  p-4 pl-0 justify-between h-fit overflow-x-scroll  scrollbar-hide">
        <form className="flex gap-2" onSubmit={handleSearch}>
          <div className="w-[250px] lg:w-[548px]">
            <Search
              placeholder="Search database"
              value={searchValue}
              onChange={handleChange}
            />
          </div>
          <button
            type="submit"
            className="bg-primary flex items-center justify-center rounded py-1 px-2 h-[43px]"
          >
            <SearchIcon color="#ffff" opacity="1" />
          </button>
        </form>
        <div className="flex flex-row gap-4">
          <div className="w-[183px] h-[44px] ">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                exportcsv();
              }}
              startIcon={<DownloadIcon />}
            >
              Export CSV
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <UserAdminsTable
          openModal={handleOpen}
          data={users}
          pagination={pagination}
          navigate={navigate}
          loading={loading}
          opened={opened}
          setOpened={setOpened}
          deleteUser={deleteUser}
          handleDeleteOpen={handleDeleteOpen}
          handleClosed={handleClosed}
          handleSetPerPage={handleSetPerPage}
          handleChangePage={handleChangePage}
          handleSearchInput={handleChange}
          handleSearchClick={handleSearch}
        />
      </div>
      <ControlledModal
        isOpen={open}
        handleClose={handleClose}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[30%]"
      >
        <div className="p-10">
          <p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434] mb-6">
            Send Message
          </p>
          <Input
            label="Heading"
            placeholder="Enter the title of the heading"
            name="subject"
            onChange={handleInputChange}
          />
          <TextArea
            label="Content"
            placeholder="Type message content here"
            name="content"
            onChange={handleInputChange}
          />
          <Input
            label="Action URL"
            placeholder="https://takeaction.com"
            name="url"
            onChange={handleInputChange}
          />

          <div className="flex flex-row justify-end gap-2 mt-8">
            <Button
              variant="text"
              color="neutral"
              onClick={() => setOpen(!open)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                sendMessage();
              }}
            >
              Send message
            </Button>
          </div>
        </div>
      </ControlledModal>
    </div>
  );
};

export default User;
