/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "@config/url";
import { toast } from "react-toastify";
import { } from "@shared/utility";
import axios from "@config/axios-instance"



const getDemands = createAsyncThunk("miner/getDemands", async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get(API.getMinerDemands(payload.page,payload.limit));
        return response;
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data);
    }
}
);

const searchDemands = createAsyncThunk("miner/searchDemands", async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get(API.searchMinerDemands(payload.search));
        return response;
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data);
    }
}
);

const getDemand = createAsyncThunk("miner/getDemand", async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(API.getMinerDemand(id));
        return response;
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data);
    }
}
);

const getDemandSupplyCreateData = createAsyncThunk("miner/demand/supply/create", async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(API.getMinerDemand(id) + '/create');

        return response;
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data);
    }
}
);

const postDemandSupply = createAsyncThunk(
    'miner/demand/supply/post',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                API.postMinerDemandSupply(payload.id),
                payload.form
            );

            return response;
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

const postDemandInterestPaymentData = createAsyncThunk(
    'miner/demand/supply/payment',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                API.postDemandpayment(id),
                data
            );

            return response;
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);


const slice = createSlice({
    name: "miner_demands",
    initialState: {
        loading: false,
        error: null,
        paymentSuccess: false,
        demands: [],
        demand: {},
        supplyFormData: {},
    },
    reducers: {},
    extraReducers: {
        //miner demands
        [getDemands.pending]: (state) => {
            state.loading = true;
        },
        [getDemands.fulfilled]: (state, { payload: { data } }) => {
            state.loading = false;
            state.demands = data[0];
        },
        [getDemands.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
            toast.error(payload.message);
        },

        [searchDemands.pending]: (state) => {
            state.loading = true;
        },
        [searchDemands.fulfilled]: (state, { payload: { data } }) => {
            state.loading = false;
            state.demands = data[0];
        },
        [searchDemands.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
            toast.error(payload.message);
        },

        //miner demand
        [getDemand.pending]: (state) => {
            state.loading = true;
        },
        [getDemand.fulfilled]: (state, { payload: { data } }) => {
            state.loading = false;
            state.demand = data;
        },
        [getDemand.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
            toast.error(payload.message);
        },

        // Get supply form data
        [getDemandSupplyCreateData.fulfilled]: (state, { payload: { data } }) => {
            state.loading = false;
            state.supplyFormData = data;
        },

        // Post demand supply data
        [postDemandSupply.pending]: (state) => {
            state.loading = true;
        },
        [postDemandSupply.fulfilled]: (state, { payload: { data } }) => {
            state.loading = false;
            toast.success(data.message);
        },
        [postDemandSupply.rejected]: (state, { payload }) => {
            state.loading = false;
            toast.error(payload.message);
        },

        // Make for for demand interest
        [postDemandInterestPaymentData.pending]: (state) => {
            state.paymentLoading = true;
        },
        [postDemandInterestPaymentData.fulfilled]: (state, { payload }) => {
            state.paymentLoading = false;
            // toast.success(data.message);
            state.paymentSuccess = true
        },
        [postDemandInterestPaymentData.rejected]: (state, { payload }) => {
            state.paymentLoading = false;
            toast.error(payload.message);
        },



    },
});

export { getDemands,searchDemands, getDemand, getDemandSupplyCreateData, postDemandSupply, postDemandInterestPaymentData };
export default slice.reducer;
