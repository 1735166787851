/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "@config/axios-instance";

import * as API from "../../../config/url";
import { toast } from "react-toastify";

const GET = "get";
// const DELETE = "delete";

const config = (method, url, data = null) => {
  const token = JSON.parse(localStorage.getItem("token"));

  return {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      "Authorization": "bearer "+ token
    },
    data,
  };
};

const postCarrier = createAsyncThunk(
  "shipment/postCarrier",
  async (data, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "post",
      url: API.postCarrier,
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": "bearer "+ token
      },
      data: data,
    };
    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      // console.log(error.response);
      if (!err.response) {
        3;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const postCreateShipment = createAsyncThunk(
  "shipment/postCreateShipment",
  async (data, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    // const formdata = {
    //   data:JSON.stringify({
    //     "courier_id": data.courier_id,
    //     "transaction_id": data.transaction_id,
    //     "tracking_id": data.tracking_id,
    //     "reference_id": data.reference_id,
    //     "atd": data.atd,
    //     "eta": data.eta,
    //     "note": data.note,
    //   }),
    //   courier_proof: data.courier_proof,
    // };
    const config = {
      method: "post",
      url: API.postShipment,
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": "bearer "+ token,
      },
      data: data,
    };
    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      // console.log(error.response);
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const updateCarrierByID = createAsyncThunk(
  "shipment/updateCarrierByID",
  async ({ id, data }, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "put",
      url: API.updateCarrierByID(id),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
      data: data,
    };
    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      // console.log(error.response);
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const deleteCarrierByID = createAsyncThunk(
  "shipment/deleteCarrierByID",
  async (id, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "delete",
      url: API.getCarrierByID(id),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
    };
    try {
      const response = await axios(config);
      return response;
    } catch (err) {
      // console.log(error.response);
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
const getCarrierByID = createAsyncThunk(
  "miner/getCarrierByID",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getCarrierByID(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getCarrier = createAsyncThunk(
  "shipment/getCarrier",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getCarrier);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const shipmentSlice = createSlice({
  name: "shipment",

  initialState: {
    data: [],
    carrier_data: {},
    update_carrier: {},
    postcarrier_data: {},
    docs: {},
    status: false,
    loading: false,
    error: null,
    message: null,
    tenders: {
      products: [],
      counts: {},
    },
    tender: {
      bids: [],
      product: {},
    },
  },

  reducers: {},

  extraReducers: {
    // create shipment reducer
    [postCreateShipment.pending]: (state) => {
      state.loading = true;
    },
    [postCreateShipment.fulfilled]: (state, { payload }) => {
      state.data = payload.data.user;
      state.status = payload.status;
      toast.success(payload.data.message);
      state.loading = false;
    },
    [postCreateShipment.rejected]: (state, { payload }) => {
      // console.log(payload, "postCreateShipment.rejected");
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },

    [deleteCarrierByID.pending]: (state) => {
      state.loading = true;
    },
    [deleteCarrierByID.fulfilled]: (state, { payload }) => {
      state.carrier_data = payload.data.docs;
      state.status = payload.status;
      toast.success(payload.data.message);
      state.loading = false;
    },
    [deleteCarrierByID.rejected]: (state, { payload }) => {
      // console.log(payload, "postCreateShipment.rejected");
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },

    [updateCarrierByID.pending]: (state) => {
      state.loading = true;
    },
    [updateCarrierByID.fulfilled]: (state, { payload }) => {
      state.update_carrier = payload.data.docs;
      state.status = payload.status;
      // toast.success(payload.data.message);
      state.loading = false;
    },
    [updateCarrierByID.rejected]: (state, { payload }) => {
      // console.log(payload, "postCreateShipment.rejected");
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },
    // create shipment reducer
    [postCarrier.pending]: (state) => {
      state.loading = true;
    },
    [postCarrier.fulfilled]: (state, { payload }) => {
      state.postcarrier_data = payload.data.docs;
      state.status = payload.status;
      // toast.success(payload.data.message);
      state.loading = false;
    },
    [postCarrier.rejected]: (state, { payload }) => {
      // console.log(payload, "postCreateShipment.rejected");
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },
    //fetch courier data
    [getCarrier.pending]: (state) => {
      state.loading = true;
    },
    [getCarrier.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data.docs;
    },
    [getCarrier.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [getCarrierByID.pending]: (state) => {
      state.loading = true;
    },
    [getCarrierByID.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.docs = payload.data.docs;
    },
    [getCarrierByID.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    // fetch transactions data
  },
});

export {
  postCreateShipment,
  postCarrier,
  getCarrierByID,
  deleteCarrierByID,
  updateCarrierByID,
  getCarrier,
  // getTenders,
  // getTender,
  // awardABid,
};
export default shipmentSlice.reducer;
