//eslint-disable-next-line
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "components/Table";
import { InfoStats } from "components/ui/index";
import { offtakerOffersTableInfo } from "constants/index";
import {
  fetchPaginatedOffers,
  getOffers,
  setOfftakerOffersSearchValue,
} from "store/slices/offtaker-slice";

const Offers = () => {
  const {
    loading,
    offers: { data, pagination, search, counts, filter },
  } = useSelector((state) => state.offtakers);

  const handleViewDetails = (record) => {
    navigate(`${record._id}`);
  };
  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedOffers({
        page: pagination[option + "Page"],
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedOffers({
        page: 1,
        per_page: e.target.value,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(setOfftakerOffersSearchValue(e.target.value));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(getOffers());
    }
    dispatch(setOfftakerOffersSearchValue(search));
    dispatch(
      fetchPaginatedOffers({
        page: 1,
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };

  const handleTabChange = (e, tab) => {
    console.log(tab);
    e.preventDefault();
    if (tab == "All") {
      if (search == "") {
        dispatch(getOffers());
      } else {
        dispatch(
          fetchPaginatedOffers({
            page: 1,
            per_page: pagination.limit,
            search: search,
            filter: "",
          })
        );
      }
    } else {
      dispatch(
        fetchPaginatedOffers({
          page: 1,
          per_page: pagination.limit,
          search: search,
          filter: tab.toLowerCase(),
        })
      );
    }
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getOffers());
  }, []);

  return (
    <div>
      <div className="grid grid-cols-3 lg:grid gap-2 sm:flex overflow-x-auto scrollbar-hide">
        {/* <InterationsStats
          isVerified={isVerified}
          underReview={underReview}
          offers={true}
          counts={counts}
        /> */}
        <InfoStats
          statData={offtakerOffersTableInfo.statInfo}
          counts={counts}
        />
      </div>
      {/* tabs */}
      <div className="mt-4 sm:flex overflow-x-auto scrollbar-hide">
        <Table
          tableInfo={offtakerOffersTableInfo}
          isViewDetails={true}
          isStatus={true}
          handleViewDetails={handleViewDetails}
          navigate={navigate}
          data={data}
          isToggle={false}
          pagination={pagination}
          handleSetPerPage={handleSetPerPage}
          handleSearchSubmit={handleSearchSubmit}
          handleChangePage={handleChangePage}
          handleTabChange={handleTabChange}
          loading={loading}
          handleSearch={handleSearch}
          fetchDefaultData={getOffers}
          search={search}
        />
      </div>
    </div>
  );
};

export default Offers;
