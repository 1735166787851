import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "@config/url";
import { toast } from "react-toastify";
import { transformProductObject } from "@shared/utility";
import axios from "@config/axios-instance"



const getAuctions = createAsyncThunk(
  "miner/getAuctions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getAuctions);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const searchAuctions = createAsyncThunk(
  "miner/searchAuctions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.searchAuctions(payload.search));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getSingleAuctions = createAsyncThunk(
  "miner/getSingleAuctions",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getSingleAuctions(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const minersSlice = createSlice({
  name: "miners",
  initialState: {
    loading: false,
    error: null,
    auctions: [],
    auction: {},
  },
  reducers: {},
  extraReducers: {
    //miner auctions
    [getAuctions.pending]: (state) => {
      state.loading = true;
    },
    [getAuctions.fulfilled]: (state, { payload: { data } }) => {
      state.loading = false;
      const transformedProducts = data.products.map((product) =>
        transformProductObject(product)
      );

      state.auctions = transformedProducts;
    },
    [getAuctions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [searchAuctions.pending]: (state) => {
      state.loading = true;
    },
    [searchAuctions.fulfilled]: (state, { payload: { data } }) => {
      state.loading = false;
      const transformedProducts = data.products.map((product) =>
        transformProductObject(product)
      );

      state.auctions = transformedProducts;
    },
    [searchAuctions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    //miner single auctions
    [getSingleAuctions.pending]: (state) => {
      state.loading = true;
    },
    [getSingleAuctions.fulfilled]: (state, { payload: { data } }) => {
      const product = transformProductObject(data.product);
      const auction = {
        ...data,
        product,
      };
      state.loading = false;
      state.auction = auction;
    },
    [getSingleAuctions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
  },
});

export { getAuctions,searchAuctions, getSingleAuctions };

export default minersSlice.reducer;
