import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { AccountDetails, Actions, AccountStats } from "pages/admin/index";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AdminUserTabs = ({data}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="w-full mb-5">
      <div className="border-b border-gray-light">
        <div className="flex items-center justify-between">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="secondary"
          >
            <Tab label="Account details" {...a11yProps(0)} />
            <Tab label="Account stats" {...a11yProps(1)} />
            <Tab label="Actions" {...a11yProps(2)} />
          </Tabs>
        </div>
      </div>
      <TabPanel value={value} index={0}>
        <AccountDetails data={data} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AccountStats data={data} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Actions data={data} />
      </TabPanel>
    </div>
  );
};

export default AdminUserTabs;
