import SearchIcon from '@mui/icons-material/Search';
import Button from "components/ui/Button";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const MessageSearch = ({ placeholder, onClick }) => {

    MessageSearch.propTypes = {
        placeholder: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    }

  return (
    <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={2}>
      <Grid item xs={8} >
        <input
        type="text"
        className="border-none bg-[#1A38601A] rounded-[4px] z-0 focus:shadow focus:outline-none focus:ring-primary placeholder:text-[#1C345442] placeholder:font-extralight placeholder:text-base search-input"
        placeholder={placeholder}
      />
      </Grid>
      <Grid item xs={4}>
        <Button startIcon={<SearchIcon style={{ color: 'white' }} />} onClick={onClick} style={{float:'right'}} />
      </Grid>
    </Grid>
  </Box>
  );
};

export default MessageSearch;
