/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { ControlledModal, Button as ModalButton } from "@ui";
import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Input } from "@ui";
import { toast } from "react-toastify";
import { SuccessModal } from "./successModal";
import { useSelector, useDispatch } from "react-redux";
import { renewSubscription } from "@slices/wallet-slice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      border: "1px solid red",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const RenewSubscription = ({ setOpen, open = null, type }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [amount, showAmount] = useState(false);
  const [successStatus, showStatus] = useState(false);
  const [loadData, setLoading] = useState(false);
  let [subscriptionData, setData] = useState({
    duration: 0,
    amount: 0,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    showAmount(true);
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleWallet = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = {
      payment_method: type,
      zip_code: "12345",
      payment_method_id: "",
      duration: subscriptionData.duration,
      amount: subscriptionData.duration * 150,
    };

    const paymentData = await dispatch(renewSubscription(data));

    if (paymentData?.payload.status == 201) {
      if (paymentData.payload?.data?.code == 422 || paymentData?.payload?.data.code == 400) {
        toast.error(paymentData.payload.data.message);
        setOpen(!open);
        showAmount(false);
        setLoading(false);
      } else {
        showAmount(false);
        setLoading(false);
        showStatus(true);
        setOpen(false);
        toast.success(paymentData.payload?.data.message);
      }
    } else {
      showAmount(false);
      setLoading(false);
      setOpen(!open);
      toast.error(paymentData.data.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }
    const user = JSON.parse(localStorage.getItem("user"));

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: user.name,
        email: user.email,
      },
    });

    if (!result?.paymentMethod) {
      toast.error(result.error.message);
      setLoading(false);
      setOpen(!open);
    }

    const data = {
      payment_method: type,
      zip_code: "12345",
      payment_method_id: result?.paymentMethod.id,
      duration: subscriptionData.duration,
      amount: subscriptionData.duration * 150,
    };

    const paymentData = await dispatch(renewSubscription(data));

    if (paymentData?.payload.status == 201) {
      if (paymentData.payload?.data?.code == 422 || paymentData?.payload?.data.code == 400) {
        toast.error(paymentData.payload.data.message);
        setOpen(!open);
        showAmount(false);
        setLoading(false);
      } else {
        showAmount(false);
        setLoading(false);
        showStatus(true);
        setOpen(false);
        toast.success(paymentData.payload?.data.message);
      }
    } else {
      showAmount(false);
      setLoading(false);
      setOpen(!open);
      toast.error(paymentData.data.message);
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {type == "stripe" ? (
            <div className="text-center mb-4">
              <h1 className="mb-4 black_btn">Pay with Stripe</h1>
              {/* <p className="font-light mt-4">
                You have decided to fund your Daniola Wallet. Enter your correct
                card details below to fund.
              </p> */}
              <div className="max-w-lg paragraph mb-5">
                <label
                  className="block mb-2  text-[#1B2B41B8] font-light"
                  htmlFor="card_details"
                >
                  Card details
                </label>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
              </div>
              <div className="max-w-lg paragraph">
                <Input
                  label="Number of months"
                  id="duration"
                  type="number"
                  onChange={handleInputChange}
                  name="duration"
                />
              </div>
              {amount && (
                <span className="font-light text-primary">
                  ${subscriptionData?.duration * 150}
                </span>
              )}

              <p className=" paragraph text-lightgray text-xs ml-2 font-thin">
                By clicking Subscribe, I accept Daniola's terms and conditions
              </p>
              <div className="flex items-center justify-between mt-8">
                <div>
                  <Button
                    onClick={handleSubmit}
                    styleClass="fund-btn"
                    disabled={!stripe}
                  >
                    {loadData ? "Processing..." : "Subscribe"}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center mb-4">
              <h1 className="mb-4 black_btn">Pay via Wallet</h1>
              <div className="max-w-lg paragraph">
                <Input
                  label="Number of months"
                  id="duration"
                  type="number"
                  onChange={handleInputChange}
                  name="duration"
                />
              </div>
              {amount && (
                <span className="font-light text-primary">
                  ${subscriptionData?.duration * 150}
                </span>
              )}

              <p className=" paragraph text-lightgray text-xs ml-2 font-thin">
                By clicking Subscribe, I accept Daniola's terms and conditions
              </p>
              <div className="flex items-center justify-between mt-8">
                <div>
                  <Button
                    onClick={handleWallet}
                    styleClass="fund-btn"
                    disabled={!stripe}
                  >
                    {loadData ? "Processing..." : "Subscribe"}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Box>
      </Modal>
      <div>
        <SuccessModal open={successStatus} showStatus={showStatus} type="Renewal" />
      </div>
    </>
  );
};

export default RenewSubscription;
