/* eslint-disable no-unused-vars */
import React,{useEffect} from "react";
import { AdminUserTabs } from "@ui";
import { useParams } from "react-router-dom";
import { getInitials } from "shared/utility";
import { findUser } from "@slices/admin-slice";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@components/auctions/Skeleton";

const ViewAccount = () => {
  let { id } = useParams();

  const user = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      findUser({
        id: id,
      })
    );
  }, [dispatch]);
  return user?.userDetails.length == 0 ? (
    <Skeleton />
  ) : (
    <div className="w-full mb-5 bg-white p-10">
      <div className="mb-6">
        <p className="text-[#192434] font-medium text-xl my-6">
          Account Information
        </p>
        <div className="flex flex-row">
          <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill mr-3">
            {user?.userDetails?.profile[0]?.profile_img?.length > 0 ? (
              <img
                className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                src={`${user?.userDetails?.profile[0]?.profile_img[0]?.url}`}
                alt=""
              />
            ) : (
              `${getInitials(user?.userDetails?.user?.name)}`
            )}
          </div>
          {/* <img className="h-[60px] w-[60px] rounded-full mr-6" src="#" /> */}
          <div>
            <p className="mb-2 font-medium text-[#192434]">
              {user?.userDetails?.user?.name}
            </p>
            <p className="text-[#192434]">
              User ID: <span>{ user?.userDetails?.user?._id?.slice(0, 8).toUpperCase()}</span>
            </p>
          </div>
        </div>
      </div>
      <AdminUserTabs data={user?.userDetails} />
    </div>
  );
};

export default ViewAccount;
