/* eslint-disable react/prop-types */
import { Fragment } from "react";
import MenuItem from "./Item";
import DisableMenuItem from "./DisabledItem";
import { useAuth } from "@hooks";

const MenuItems = ({ menuItems, sliceRange }) => {
  const { profileStatus } = useAuth();
  return (
    <ul className="w-full">
      <MenuItem
        href={menuItems[0]?.url}
        icon={menuItems[0]?.icon}
        label={menuItems[0]?.label}
        hasDot={menuItems[0]?.hasDot}
        subList={menuItems[0]?.subList}
      />
      {menuItems.slice(1, sliceRange).map((item) => (
        <Fragment key={item.label}>
          {profileStatus === "verified" ? (
            <MenuItem
              href={item?.url}
              icon={item?.icon}
              label={item?.label}
              hasDot={item?.hasDot}
              subList={item?.subList}
              style={item?.style}
            />
          ) : (
            <DisableMenuItem
              href={item?.url}
              icon={item?.icon}
              label={item?.label}
              hasDot={item?.hasDot}
              subList={item?.subList}
            />
          )}
        </Fragment>
      ))}
      <hr className="border-lightgray my-5" />
      {menuItems.slice(sliceRange).map((item) => (
        <MenuItem
          key={item?.label}
          href={item?.url}
          icon={item?.icon}
          label={item?.label}
        />
      ))}
    </ul>
  );
};

export default MenuItems;
