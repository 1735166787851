import React from "react";
import { Header } from "@atoms/header";
import { Button } from "@atoms/button";
import Success from "@assets/images/success.png";
import { SelectCategoryUrl } from "@config/app-url";
import { useNavigate } from "react-router-dom";

export default function VerifySuccess() {
  const navigate = useNavigate();
  return (
    <div className=" font-default h-full">
      <Header />
      <div className="bg-white rounded p-8 mt-[113px] lg:mt-0  lg:bg-none my-4">
        <div className=" bg-no-repeat  w-full h-26 mt-6 flex justify-center align-center">
          <img src={Success} alt="success" />
        </div>

        <div className=" my-4 ">
          <h2 className="font-bold text-2xl text-head pt-4 flex justify-center align-center">
            Phone number verified!
          </h2>

          <p className="text-lightgray pb-4 text-base font-light mb-2 flex justify-center align-center">
            Your phone number has been verified successfully
          </p>

          <Button onClick={() => navigate(SelectCategoryUrl)}>Continue</Button>
        </div>
      </div>
    </div>
  );
}
