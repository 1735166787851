/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import "../index.css";
import { useNavigate } from "react-router-dom";
import { Input, TextArea } from "@atoms/input";
import { Checkbox } from "@ui";
import { UserContext } from "@context/index";
import { Button } from "@atoms/button/index";
import { Map, LongLat, SearchLocation } from "@pages/onboarding";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { submit_offtaker_verification } from "@slices/onboarding/offtaker.slice"
import { toast } from "react-toastify";
import { SuccessUrl } from "@config/app-url";
import { SuccessResponse } from "config/status-code";
import { OfftakerVerificationFormSchema } from "@validations/schema"

const OfftakerForm = ({ uploadedFiles, children, ...props }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading } = useSelector((state) => state.offtaker_verification);
    const _FormData = new FormData();
    let plan = "paid";
    const { form } = useContext(UserContext);
    const [location_type, setLocationType] = useState("");
    const [address, setAddress] = useState(true);
    const [longitude_latitude, setLongitudeLatitude] = useState(false);
    const [gps, setGps] = useState(false);

    /** Instantiate validation */
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(OfftakerVerificationFormSchema), mode: "all" });

    /** Onchange of Location Type */
    const onChangeLocationType = (location_type) => {
        setValue("address", '', { shouldValidate: true, shouldDirty: true });
        setValue("postal", '', { shouldValidate: true, shouldDirty: true });
        setValue("state", '', { shouldValidate: true, shouldDirty: true });
        setValue("city", '', { shouldValidate: true, shouldDirty: true });
        setValue("long", '', { shouldValidate: true, shouldDirty: true });
        setValue("lat", '', { shouldValidate: true, shouldDirty: true });
        setValue("country", '', { shouldValidate: true, shouldDirty: true });
        return setLocationType(location_type)
    }
    useEffect(() => {
        /** switch location type */
        switch (location_type) {
            case "address":
                setAddress(true)
                setLongitudeLatitude(false)
                setGps(false)
                break;
            case "longitude_latitude":
                setLongitudeLatitude(true)
                setAddress(false);
                setGps(false)
                break;
            case "gps":
                setGps(true)
                setAddress(false);
                setLongitudeLatitude(false)
                break
            default:
                break;
        }
    }, [location_type]);

    const handleChange = (event)=>{
        const { name, value, checked, type } = event.target;
        if(checked === true) {
            plan = "trial";
        }else {
            plan ="paid";
        }
      }

    const onSubmit = (data) => {
        const { _id: profile_id } = JSON.parse(localStorage.getItem("profile_to_verify"));
        for (const file of uploadedFiles) {
            _FormData.append("offtaker_file", file);
        }
        const formRequest = {
            profile_id: profile_id,
            description: data.description,
            location: {
                long: data.long,
                lat: data.lat,
                address: data.address,
                postal_code: data.postal,
            },
            country: data.country,
            state: data.state,
            city: data.city,
            company_name: data.companyName,
            plan:plan
        };

        _FormData.append("data",JSON.stringify(formRequest));

        dispatch(submit_offtaker_verification({formdata:_FormData,id:profile_id}))
            .then((res) => {
                if (res.payload.status && SuccessResponse(res.payload.status)) {
                    navigate(SuccessUrl);
                    plan = "paid";
                }
            }).catch((err) => {
                return toast.error(err.message);
            });
    };

    return (
        <div>
            {form && (
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    {/**  Company name */}
                    <div className="mt-2">
                        <label className="block text-xs mb-2  text-lightgray"> Company name  </label>
                        <Input type="text" placeholder="Rio Tinto Group" name="companyName" {...register("companyName")} />{" "}
                        {errors.companyName?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.companyName?.message} </p>)}
                    </div>
                    {/** Description */}
                    <div className="mt-2">
                        <label className="block text-xs mb-2  text-lightgray"> Description </label>
                        <TextArea placeholder="Bingham Canyon Mine is an open-pit mining operation extracting a large porphyry copper deposit southwest of Salt Lake City, Utah, in the Oquirrh Mountains."
                            name="description" type="textarea" {...register("description")} />
                        {errors.description?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.description?.message} </p>)}
                    </div>

                    {/** Location Type */}
                    <div className="w-full mt-2">
                        <label className="block text-xs mb-2  text-lightgray" htmlFor="address"> Location type </label>
                        <select
                            className="bg-fill cursor-pointer border-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary w-full appearance-none rounded text-input py-2 px-4 text-sm"
                            id="address" type="dropdown" onChange={(e) => onChangeLocationType(e.target.value)}>
                            <option className="bg-fill cursor-pointer border-none" value="address" name="type"> Address </option>
                            <option value="longitude_latitude" name="type">  Longitude and Latitude </option>
                            <option value="gps" name="type">  GPS  </option>
                        </select>
                    </div>

                    {gps && (
                        <div className="w-full mt-3">
                            <div className="h-96 my-4 ">
                                <Map location={{ address: "1600 Amphitheatre Parkway, Mountain View, california.", lat: 37.42216, lng: -122.08427 }} zoomLevel={17} setValue={setValue} />
                            </div>
                        </div>
                    )}

                    {longitude_latitude && (
                        <div className="flex flex-wrap -mx-2">
                            <LongLat setValue={setValue} errors={errors} />
                        </div>
                    )}

                    {/* Address */}
                    <div className="w-full mt-3">
                        <label className="block text-xs mb-2  text-lightgray" htmlFor="address"> Address </label>
                        {address ? (<SearchLocation setValue={setValue} />) : <Input type="text" name="address" {...register("address")} />}
                        {errors.address?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.address?.message} </p>)}
                    </div>

                    {/* Longitude && Latitude display this if longitude and latitude is not selected */}
                    {!longitude_latitude && (
                        <div className="flex flex-wrap -mx-2">
                            {/** Longitude */}
                            <div className=" media mt-4 px-3">
                                <label className="block text-xs mb-2 text-lightgray" htmlFor="longitude"> Longitude </label>
                                <Input type="text" name="long" {...register("long")} readOnly />
                                {errors.long?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.long?.message} </p>)}
                            </div>
                            {/** Latitude */}
                            <div className=" media mt-4 px-3">
                                <label className="block text-xs mb-2 text-lightgray" htmlFor="latitude"> Latitude </label>
                                <Input type="text" name="lat"  {...register("lat")} readOnly />
                                {errors.lat?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.lat?.message} </p>)}
                            </div>
                        </div>
                    )}

                    {/** Country && Postal code */}
                    <div className="flex flex-wrap -mx-3 my-3">
                        {/** Country */}
                        <div className="media px-3">
                            <label className="block text-xs mb-2  text-lightgray" htmlFor="country" >  Country </label>
                            <Input id="country" type="text" placeholder="" name="country" {...register("country")} readOnly />
                            {errors.country?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.country?.message} </p>)}
                        </div>
                        {/** Postal code */}
                        <div className="media px-3">
                            <label className="block text-xs mb-2  text-lightgray" htmlFor="postal" > Postal code </label>
                            <Input id="postal" type="text" name="postal"  {...register("postal")} />
                            {errors.postal?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.postal?.message} </p>)}
                        </div>
                    </div>

                    {/** State && City */}
                    <div className="flex flex-wrap -mx-2">
                        {/** State */}
                        <div className=" media px-3 mt-2">
                            <label className="block text-xs mb-2 text-lightgray" htmlFor="state">  State </label>
                            <Input id="state" type="text" placeholder="" name="state"  {...register("state")} />
                            {errors.state?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.state?.message} </p>)}
                        </div>
                        {/** City */}
                        <div className=" media px-3 mt-2 ">
                            <label className="block text-xs mb-2 text-lightgray" htmlFor="city"> City </label>
                            <Input id="city" type="text" placeholder="" name="city"  {...register("city")} />
                            {errors.city?.message && (<p className="paragraph text-red text-xs mt-1"> * {errors.city?.message} </p>)}
                        </div>
                    </div>

                      {/* Trial period */}
                    <div className="text-checkbox flex flex-row text-sm">
                        <Checkbox  value={plan} name="paid" onChange={handleChange} />
                        <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#1b2b41]/[0.72]">
                            Start a free 30-day trial
                        </p>
                        
                    </div>

                     {/**  Submit button */}
                     <div className="mt-6 flex justify-end">  <Button disabled={loading} size="small" type="submit"> {loading ? "Loading..." : "Submit"} </Button> </div>
                </form>
            )}
        </div>
    );
};
export default OfftakerForm;
