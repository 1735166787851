import {
  MinerDashboardUrl,
  MinerMinesUrl,
  MinerInventoryUrl,
  MinerSupportUrl,
  MinerVendorsUrl,
  MinerAuctionsUrl,
} from "config/app-url";
import PointerOne from "@assets/images/pointer-1.svg";
import PointerTwo from "@assets/images/pointer-2.svg";
import PointerThree from "@assets/images/pointer-3.svg";
import PointerFour from "@assets/images/pointer-4.svg";
import PointerFive from "@assets/images/pointer-5.svg";
import PointerSix from "@assets/images/pointer-6.svg";
import PointerSeven from "@assets/images/pointer-7.svg";

export const minerOnboardingGuides = [
  {
    heading: "View analytics and notifications",
    paragraph:
      "View all recent happenings on your profile right on your dashboard. Cards and charts to illustrate all your important data.",
    path: MinerDashboardUrl,
  },
  {
    heading: "Add and view mines",
    paragraph:
      "Easily add new mines and easily manage and locate them with the help of geo-mapping.",
    path: MinerMinesUrl,
  },
  {
    heading: "Manage inventory",
    paragraph:
      "Add products easily for auctions and manage all your products or listings in one place.",
    path: MinerInventoryUrl,
  },
  {
    heading: "View auctions",
    paragraph:
      "View all auctions currently available for bids on the platform.Only off-takers can make bids.",
    path: MinerAuctionsUrl,
  },
  {
    heading: "Find and request vendors",
    paragraph:
      "Search through our database of verified vendors offering various products and services to aid your process. If you can’t find one that meets your needs, you can make a request for such service.",
    path: MinerVendorsUrl,
  },
  {
    heading: "If you need help, we are always with you",
    paragraph:
      "In the case where you need help, there are articles, FAQs and videos to help you and you can also contact us.",
    path: MinerSupportUrl,
  },
  {
    heading: "If you need help, we are always with you",
    paragraph: "You can also access the onboarding anytime here.",
    path: "",
  },
];


export const minerOnboardingGuidesPointers = [
  {
    imageSrc: PointerOne,
    pointerPositions: {
      top: "top-[11%]",
      left: "left-[16.5%]",
    },
    modalPositions: {
      top: "top-[16%]",
      left: "left-[20%]",
    },
  },
  {
    imageSrc: PointerTwo,
    pointerPositions: {
      top: "top-[20%]",
      left: "left-[16.5%]",
    },
    modalPositions: {
      top: "top-[45%]",
      left: "left-[20%]",
    },
  },
  {
    imageSrc: PointerThree,
    pointerPositions: {
      top: "top-[25%]",
      left: "left-[16.5%]",
    },
    modalPositions: {
      top: "top-[45%]",
      left: "left-[20%]",
    },
  },
  {
    imageSrc: PointerFour,
    pointerPositions: {
      top: "top-[43%]",
      left: "left-[16.5%]",
    },
    modalPositions: {
      top: "top-[30%]",
      left: "left-[28%]",
    },
  },
  {
    imageSrc: PointerFive,
    pointerPositions: {
      top: "top-[60%]",
      left: "left-[16.5%]",
    },
    modalPositions: {
      top: "top-[30%]",
      left: "left-[20%]",
    },
  },
  {
    imageSrc: PointerSix,
    pointerPositions: {
      top: "top-[63%]",
      left: "left-[16.5%]",
    },
    modalPositions: {
      top: "top-[33%]",
      left: "left-[20%]",
    },
  },
  {
    imageSrc: PointerSeven,
    pointerPositions: {
      top: "top-[6%]",
      left: "left-[66%]",
    },
    modalPositions: {
      top: "top-[13%]",
      left: "left-[33%]",
    },
  },
];
