/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Stepper, Button } from "@ui";
import Description from "./Description";
import Review from "./Review";
import Complete from "./Complete";
import { useSelector, useDispatch } from "react-redux";
import {
  getDemandSupplyCreateData,
  postDemandSupply,
} from "@slices/miners/demands-slice";
import { useForm } from "react-hook-form";
import { Formik, Form } from "formik";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { file_uploader } from "@slices/file.slice";
import { SuccessResponse } from "config/status-code";

const schema = yup.object().shape({
  product_name: yup.string().required("Product name is required"),
  unit_of_measurements: yup.string().required("Select unit of measurement"),
  quantity: yup
    .number()
    .typeError("Quantity must be number")
    .required("Quantity is is required"),
  price: yup
    .number()
    .typeError("Price must be number")
    .required("Price is is required"),
  treatment_cost: yup
    .number()
    .typeError("Treatment cost must be number")
    .required("Treatment cost is required"),
  mineral: yup.string().required("Please select a mineral"),
  mine: yup.string().required("Please select a mine"),
  total_value: yup
    .number()
    .typeError("Total value must be number")
    .required("Total value is required"),
  product_image: yup.array().required("Please upload a product image"),
  description: yup.string().required("Description is required"),
  terms: yup.bool().required().oneOf([true], "Accept terms and conditions"),
  start_date: yup.string().required("Delivery period is required"),
  end_date: yup.string().required(),
});

const CreateSupply = ({ headingText = "Apply to offering" }) => {
  const location = useLocation();
  const { id, demand_mineral, shipping_type } = location.state;
  const navigate = useNavigate();
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const _FormData = new FormData();
  const [productImage, setProductImage] = useState([]);

  const dispatch = useDispatch();
//   useEffect(() => {
//     dispatch(getDemandSupplyCreateData(id));
//   }, []);

  const [activeStep, setActiveStep] = useState(0);
  const [mine, setMine] = useState();

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    clearErrors,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const handleNext = () => {
    trigger();
    if (isValid && activeStep == 0) {
      clearErrors();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep == 1) {
      setOpenSubmitModal(true);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // eslint-disable-next-line no-unused-vars
  const handleReset = () => {
    setActiveStep(0);
  };
  const handleSelectChange = (e, { name }) => {
    setValue(name, e.slug, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onSubmit = async () => {
    const data = getValues();
    for (const file of data.product_image[0]) {
      _FormData.append("product_image", file);
    }

    _FormData.append("data",JSON.stringify(data));
    
    try {
      const response = await dispatch(postDemandSupply({id: id, form:_FormData }));
      if (response.payload.status === 200 || response.payload.status === 201) {
        setOpenSubmitModal(false);
        navigate("/miner/demands");
      } else {
        // console.log("error from then", response);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const uploadTypeSetter = (type, array) => {
    switch (type) {
      case "product_image":
        setProductImage(array);
        break;
      default:
        break;
    }
  };
  const handleFileChange = async (FileList, type) => {
    /** check if filelist is empty  */

    if (FileList.length <= 0) {
      return toast.error("No file selected");
    }
    /** Declare form data */
    const _FormData = new FormData();
    for (const file of FileList) {
      _FormData.append("file", file);
    }
    /** set upload type */
    _FormData.append("type", type);
    let files = [];
    files.push(FileList);
    if (type === "product_image") {
      setValue("product_image",files);
      uploadTypeSetter('product_image', FileList);
    }
    /** Dispatch files to backend */
    // dispatch(file_uploader(_FormData))
    //     .then((response) => {
    //         if (
    //             response.payload.status &&
    //             SuccessResponse(response.payload.status)
    //         ) {
    //             /** Set files to state */
    //             if (type === 'product_image') {
    //                 setValue('product_image', [
    //                     { id: response.payload.data.files[0].id }
    //                 ]);

    //                 uploadTypeSetter('product_image', response.payload.data.files);
    //             }
    //         }
    //         throw 'error uploading file';
    //     })
    //     .catch((error) => toast.error(error.message));
  };
  const uploadProductImage = (file) => handleFileChange(file, "product_image");
  const steps = [
    {
      label: "Description",
    },
    {
      label: "Review",
    },
  ];
  const initialValues = {
    product_name: "",
    mineral: "",
    quantity: "",
    price: "",
    product_image: "",
    mine: "",
    documents: [],
    production_phase: "",
    investment_type: "",
    investment_amount: "",
    funding_terms: "",
  };

  const offer = {};
  return (
    <>
      <Formik
        initialValues={initialValues}
        // onSubmit={() => setOpenSubmitModal(true)}
        // handleSubmit={handleSubmit}
      >
        {({ handleChange, isSubmitting, values }) => (
          <Form className="mt-8">
            <Card noPadding>
              <div className="grid grid-cols-4 divide-x divide-disabled">
                <div className="col-span-1 py-10 mx-auto">
                  <h3 className="text-2xl font-normal text-heading text-center mb-8">
                    {headingText}
                  </h3>
                  {/* stepper starts here */}

                  <Stepper steps={steps} activeStep={activeStep} />
                </div>
                <div className="col-span-3 py-10 px-8">
                  {/* stepper contents here */}
                  {steps[activeStep] && (
                    <div>
                      {/* {steps[activeStep].component} */}
                      {activeStep === 0 && (
                        <Description
                          {...{
                            errors,
                            register,
                            handleSelectChange,
                            uploadProductImage,
                            productImage,
                            setProductImage,
                            handleChange,
                            values,
                            getValues,
                            setValue,
                            setMine,
                            shipping_type,
                            demand_mineral,
                            watch,
                          }}
                          mineral={{
                            slug: offer?.mineral?.id,
                            name: offer?.mineral?.name,
                          }}
                        />
                      )}
                      {activeStep === 1 && (
                        <Review {...{ productImage, getValues, mine,shipping_type }} />
                      )}

                      <div className="flex justify-between mt-8">
                        <Button
                          variant="contained"
                          onClick={handleBack}
                          color="neutral"
                        >
                          {activeStep > 0 ? "Back" : "Cancel"}
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          color="primary"
                        >
                          {activeStep === steps.length - 1 ? "Supply" : "Next"}
                        </Button>
                      </div>
                    </div>
                  )}
                  {/* stepper contents ends here */}
                </div>
              </div>
            </Card>
          </Form>
        )}
      </Formik>
      <Complete {...{ openSubmitModal, setOpenSubmitModal, onSubmit }} />
    </>
  );
};

export default CreateSupply;
