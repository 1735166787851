/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import MessageSearch from "components/messaging/message_search";
import MessageTabs from "components/messaging/message_tabs";
import * as message_slice from "store/slices/messages.slice";
import ChatContainer from "components/messaging/chat_container";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  InitializeChat,
  getAllMinerChats,
} from "store/slices/miners/transaction-slice";

const Messages = () => {
  const {
    chatMessage: { messages },
  } = useSelector((state) => state.interactions);

  const data = message_slice.get_all_messages().payload.data;
  const [chat_content, setChatContent] = useState(messages);

  const { state } = useLocation();
  const dispatch = useDispatch();

  const handleChatChange = (chat) => {
    const top_content = {
      chat_id: chat?.id,
      userid: state?.profile_id,
      name: chat?.profile_2?.user?.name,
      avatar: chat?.profile_2?.image?.url,
    };
    setChatContent(<ChatContainer top_content={top_content} chat={chat} />);
  };

  React.useEffect(() => {
    if (state?.profile_id) {
      dispatch(InitializeChat({ profile_id: state?.profile_id })).then(
        ({ payload }) => {
          if (payload?.data?.messaging) {
            handleChatChange(payload?.data?.messaging);
          }
        }
      );
    }
  }, [state?.profile_id]);

  React.useEffect(() => {
    dispatch(getAllMinerChats()).then(() => {});
  }, []);
  return (
    <div className="message-layout flex flex-row">
      <div className="message-left-side-div sm:w-[100%]">
        <div className="search-div">
          <MessageSearch placeholder=" Search Chat" onClick={null} />
        </div>
        <MessageTabs
          all_messages={messages}
          archived_messages={data.archived_messages}
          unread_messages={data.unread_messages}
          chat_change={handleChatChange}
        />
      </div>
      <div className="message-right-side-div sm:hidden">{chat_content}</div>
    </div>
  );
};

export default Messages;