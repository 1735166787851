const ArchivePrompt = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="text-center mt-9">
        <h3 className="text-heading text-2xl mb-4">Archive Inetrest</h3>
        <span className="text-ts font-light">
          You are about to archive this interest.
        </span>
        <span className="text-ts font-light">Kindly confirm this action</span>
      </div>
    </div>
  );
};

export default ArchivePrompt;
