import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as API from "@config/url";
import { toast } from "react-toastify";

/** Create a User */
const create_user_account = createAsyncThunk("signup/create_user_account", async (data, { rejectWithValue }) => {
    try {
        return await axios({
            method: "post",
            url: API.postSignup,
            headers: { "Content-Type": "application/json" },
            data
        });
    } catch (err) {
        if (err.response !== undefined) {
            return rejectWithValue(err.response.data);
        }
        return rejectWithValue(err);
    }
});

/** create slice */
const slice = createSlice({
    name: "signup",
    initialState: {
        data: {},
        status: false,
        loading: false,
        error: null,
        message: null,
    },
    reducers: {},
    extraReducers: {
        [create_user_account.pending]: (state) => {
            state.loading = true;
        },
        [create_user_account.fulfilled]: (state, { payload }) => {
            state.status = payload.status;
            toast.success(payload.data.message);
            state.loading = false;
        },
        [create_user_account.rejected]: (state, { payload }) => {
            state.message = payload.message;
            state.error = true;
            state.loading = false;
            toast.error(payload.message);
        },
    },
});

export { create_user_account }
export default slice.reducer;