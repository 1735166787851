import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "@config/url";
import axios from "@config/axios-instance";
import { toast } from "react-toastify";
import { transformProductObject } from "@shared/utility";

const GET = "get";
const POST = "post";

export const config = (method, url, data = null) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      "Authorization": "bearer "+ token
    },
    data,
  };
};

const fetchAllProposals = createAsyncThunk(
  "offtakers/fetchAllProposals",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(GET, API.fetchAllInvestmentProposals)
      );

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getSingleProposal = createAsyncThunk(
  "offtakers/getSingleProposal",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(GET, API.fetchAllInvestmentProposals + "/" + id)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postProposalInterest = createAsyncThunk(
  "offtakers/proposal/post",
  // eslint-disable-next-line no-unused-vars
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(
          POST,
          API.fetchAllInvestmentProposals + "?proposal_id=" + id,
          data
        )
      );

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const archiveProposalInterest = createAsyncThunk(
  "vendor/archiveProposalInterest",
  async (id, { rejectWithValue, getState }) => {
    try {
      const {
        offtakers: { deletedInterestSub },
      } = getState();
      const resp = await axios.put(
        API.archiveProspectInterest(deletedInterestSub),
        { status: "archived" }
      );

      toast.success("Proposal interest archived successfully");
      return resp;
    } catch (error) {
      toast.error("Error arhiving interest");
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOffers = createAsyncThunk(
  "offtaker/getOfftakerOffers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getOfftakerOffers);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPaginatedOffers = createAsyncThunk(
  "offtaker/paginatedOfftakerOffers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedOfftakerOffers(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOffer = createAsyncThunk(
  "transaction/getOfftakerOffer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getOfftakerOffer(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getInterests = createAsyncThunk(
  "offtaker/getInterests",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getOfftakerInterests(payload.id,payload.status));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getInterest = createAsyncThunk(
  "offtaker/getInterest",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getOfftakerInterest(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const awardInterest = createAsyncThunk(
  "offtaker/selectVendor",
  async (id, { rejectWithValue, getState }) => {
    try {
      const {
        offtakers: { interestSub },
      } = getState();
      const payload = { status: "awarded" };
      const resp = await axios.post(
        API.offtakerAwardInterest(interestSub),
        payload
      );
      toast.success(
        "Your selection has been sent and the vendor will be notified to connect."
      );
      return resp.data;
    } catch (error) {
      toast.error("An error occured while selecting the vendor.");
      return rejectWithValue("something went wrong");
    }
  }
);

// Prospects
const getAllProspects = createAsyncThunk(
  "offtakers/prospects",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.getAllProspects));

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchPaginatedProspects = createAsyncThunk(
  "offtaker/prospects/paginated",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedProspects(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getCreateProspectData = createAsyncThunk(
  "offtakers/prospect/create-data",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.getCreateProspectData));

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postFindProspects = createAsyncThunk(
  "offtakers/prospect/post-prospect",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(POST, API.postFindProspectsData, data)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getProspectInterests = createAsyncThunk(
  "offtakers/prospect/interests",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(GET, API.getProspectInterests(payload.id,payload.status))
      );

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getProspectInterestDetail = createAsyncThunk(
  "offtakers/prospect/interests/detail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(GET, API.getProspectInterestDetail(id))
      );

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const acceptProspectInterest = createAsyncThunk(
  "offtakers/prospect/interests/accept",
  // eslint-disable-next-line no-unused-vars
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(POST, API.acceptProspectInterest(id), data)
      );

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getInvestments = createAsyncThunk(
  "offtakers/transactions/investments",
  // eslint-disable-next-line no-unused-vars
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(
          GET,
          API.getAllInvestments(payload?.page, payload?.limit, payload?.filter,payload?.search)
        )
      );

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getSingleInvestment = createAsyncThunk(
  "offtakers/transactions/investments/single",
  // eslint-disable-next-line no-unused-vars
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(GET, API.getAllInvestmentsById + `/${id}`)
      );

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postInterest = createAsyncThunk(
  "offtakers/investment/postInterest",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(POST, API.postIndicateInterest + "/" + data.uuid, data.formData)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getAllOfftakerAuctions = createAsyncThunk(
  "offtaker/getAuctions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.offTakerAuctions));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const searchAuctions = createAsyncThunk(
  "offtaker/searchAuctions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(GET, API.searchOfftakerAuctions(payload.search))
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getSingleOfftakerAuctions = createAsyncThunk(
  "offtaker/getSingleOfftakerAuctions",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.singleoffTakerAuctions(id)));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const placeBid = createAsyncThunk(
  "offtaker/placeBid",
  async ({ id, amount, shipmentOption }, { rejectWithValue }) => {
    const reqBody = { shipping_option: shipmentOption, amount };
    try {
      const response = await axios(config(POST, API.placeBid(id), reqBody));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const contactVendor = createAsyncThunk(
  "offtakers/contactVendor",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.contactOffTakerVendor(id), payload);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const requestService = createAsyncThunk(
  "offtakers/requestService",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.requestOffTakerService, payload);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getAllOfftakerTransactions = createAsyncThunk(
  "offtaker/interactions/transactions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios(config(GET, API.offTakerTransactions));

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPaginatedTransactions = createAsyncThunk(
  "offtaker/fetchPaginatedTransactions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedOfftakerTransactions(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProjects = createAsyncThunk(
  "offtaker/getProjects",
  async (name, thunkAPI) => {
    try {
      const resp = await axios.get(API.getOfftakerProjects);
      return resp;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getProject = createAsyncThunk(
  "offtaker/getProject",
  async (id, thunkAPI) => {
    try {
      const resp = await axios.get(API.getOfftakerProject(id));
      return resp;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const fetchPaginatedProjects = createAsyncThunk(
  "offtaker/fetchPaginatedProjects",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedOfftakerProjects(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getSingleTransaction = createAsyncThunk(
  "offtaker/getTransaction",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(GET, API.getSingleOffTakerTransaction(id))
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getVendors = createAsyncThunk(
  "offtaker/getVendors",
  async (name, thunkAPI) => {
    try {
      const resp = await axios(config(GET, API.fetchOffTakerVendors));
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const searchVendors = createAsyncThunk(
  "offtaker/searchVendors",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.searchOfftakerVendors(
          payload?.search ? payload.search : "",
          payload?.country ? payload.country : ""
        )
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const getVendor = createAsyncThunk(
  "offtaker/getVendor",
  async (id, thunkAPI) => {
    try {
      const resp = await axios(config(GET, API.fetchOffTakerVendor(id)));
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

const getCountries = createAsyncThunk(
  "offtaker/getCountries",
  async (name, thunkAPI) => {
    try {
      const resp = await axios(
        config(GET, API.fetchOfftakerCountries("anything"))
      );
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

const getBids = createAsyncThunk("offtaker/getBids", async (name, thunkAPI) => {
  try {
    const resp = await axios(config(GET, API.offTakerBids));
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue("something went wrong");
  }
});

export const fetchPaginatedBids = createAsyncThunk(
  "offtaker/fetchPaginatedBids",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedOfftakerBids(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const makePayment = createAsyncThunk(
  "offtaker/payment",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios(config(POST, API.auctionPayment(payload.id), payload.formdata));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const submitRatings = createAsyncThunk(
  "offtaker/ratings",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(POST, API.transactionRatings(payload.id, payload.type), payload.formdata)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const submitVendorRatingsFromOfftaker = createAsyncThunk(
  "offtaker/ratings",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios(
        config(POST, API.rateVendorFromOfftaker(payload.id, payload.type), payload.formdata)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const offtakerSlice = createSlice({
  name: "offtakers",
  initialState: {
    data: {},
    loading: false,
    error: null,
    createProspectData: [],
    auctions: [],
    investmentProposals: {
      data: [],
      counts: {},
      pagination: {},
    },
    proposalDetails: {},
    make_bid: {},
    transactions: {
      counts: {},
      data: [],
      pagination: null,
      search: "",
      filter: "",
    },
    singleTransaction: {},
    vendors: null,
    vendor: null,
    countries: [],
    service_options: {},
    bids: {
      data: [],
      counts: {},
      pagination: null,
      search: "",
      filter: "",
    },
    prospects: {
      data: [],
      counts: {},
      pagination: {},
      search: "",
      filter: "",
    },
    prospectInterests: {
      data: [],
      pagination: {},
    },
    prospectInterestDetail: {},
    awarded:"",
    investments: {
      data: [],
      counts: {},
      pagination: {},
    },
    investmentDetails: {},
    offering: null,
    projects: {
      counts: {},
      data: [],
      pagination: null,
      search: "",
      filter: "",
    },
    project: null,
    offers: {
      counts: {},
      data: [],
      pagination: null,
      search: "",
      filter: "",
    },
    offer: null,
    interests: {
      counts: {},
      data: [],
      pagination: null,
      search: "",
      filter: "",
    },
    interest: null,
    interestSub: null,
    deletedOfferingSub: null,
  },
  reducers: {
    setOffering: (state, action) => {
      state.offering = action.payload;
    },
    setTransactionSearchValue: (state, action) => {
      state.transactions.search = action.payload;
    },
    setBidSearchValue: (state, action) => {
      state.bids.search = action.payload;
    },
    setProjectsSearchValue: (state, action) => {
      state.projects.search = action.payload;
    },
    setOfftakerOffersSearchValue: (state, action) => {
      state.offers.search = action.payload;
    },
    setAwardedInterestSub: (state, action) => {
      state.interestSub = action.payload;
    },
    setDeletedInterestSub: (state, action) => {
      state.deletedInterestSub = action.payload;
    },
    setProspectsSearchValue: (state, action) => {
      state.prospects.search = action.payload;
    },
  },
  extraReducers: {
    //fetch all investment proposal
    [fetchAllProposals.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllProposals.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.investmentProposals.data = payload.data.proposals;
      state.investmentProposals.pagination = payload.data.pagination;
    },
    [fetchAllProposals.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    //fetch proposal details
    [getSingleProposal.pending]: (state) => {
      state.loading = true;
    },
    [getSingleProposal.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.proposalDetails = payload.data.proposal;
      toast.success(payload.message);
    },
    [getSingleProposal.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    //Post proposal interest data
    [postProposalInterest.pending]: (state) => {
      state.loading = true;
    },
    [postProposalInterest.fulfilled]: (state, { payload }) => {
      state.loading = false;
      toast.success(payload.message);
    },
    [postProposalInterest.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [getAllOfftakerAuctions.pending]: (state) => {
      state.loading = true;
    },
    [getAllOfftakerAuctions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const transformedProducts = payload?.data?.products.map((product) =>
        transformProductObject(product)
      );
      state.auctions = transformedProducts;
    },
    [getAllOfftakerAuctions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [searchAuctions.pending]: (state) => {
      state.loading = true;
    },
    [searchAuctions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const transformedProducts = payload?.data?.products.map((product) =>
        transformProductObject(product)
      );
      state.auctions = transformedProducts;
    },
    [searchAuctions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    //get Prospects Create Data
    [getCreateProspectData.pending]: (state) => {
      state.loading = true;
    },
    [getCreateProspectData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.createProspectData = payload.data;
    },
    [getCreateProspectData.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    //create Find Prospects
    [postFindProspects.pending]: (state) => {
      state.loading = true;
    },
    [postFindProspects.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    },
    [postFindProspects.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },

    //Prospects
    [getAllProspects.pending]: (state) => {
      state.loading = true;
    },
    [getAllProspects.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.status = payload.status;
      state.prospects.data = payload.data.prospects;
      state.prospects.pagination = payload.data.pagination;
      state.prospects.counts = payload.data.counts;
    },

    [getAllProspects.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },

    //Prospects paginated
    [fetchPaginatedProspects.pending]: (state) => {
      state.loading = true;
    },
    [fetchPaginatedProspects.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.status = payload.status;
      state.prospects.data = payload.data.prospects;
      state.prospects.pagination = payload.data.pagination;
      state.prospects.counts = payload.data.counts;
    },

    [fetchPaginatedProspects.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },

    // Prospect Interests
    [getProspectInterests.pending]: (state) => {
      state.loading = true;
      state.prospectInterests = {
        data: [],
        pagination: {},
      };
    },
    [getProspectInterests.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.status = payload.status;
      state.prospectInterests.data = payload.data.interest;
      state.prospectInterests.pagination = payload.data.pagination;
    },

    [getProspectInterests.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },

    // Prospect Interest Detail
    [getProspectInterestDetail.pending]: (state) => {
      state.loading = true;
      state.prospectInterestDetail = {};
    },
    [getProspectInterestDetail.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.status = payload.status;
      state.prospectInterestDetail = payload.data.interest;
      state.awarded = payload.data.awarded;
      toast.success(payload.message);
    },

    [getProspectInterestDetail.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },

    // Accept Prospect Interest
    [acceptProspectInterest.pending]: (state) => {
      state.loading = true;
    },
    [acceptProspectInterest.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.status = payload.status;

      toast.success(payload.message);
    },

    [acceptProspectInterest.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },

    //Investments
    [getInvestments.pending]: (state) => {
      state.loading = true;
    },
    [getInvestments.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.status = payload.status;
      state.investments.data = payload.data.investments;
      state.investments.pagination = payload.data.pagination;
      state.investments.counts = payload.data.counts;
    },

    [getInvestments.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },

    //Single Investment
    [getSingleInvestment.pending]: (state) => {
      state.loading = true;
      state.investmentDetails = {};
    },
    [getSingleInvestment.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.status = payload.status;
      state.investmentDetails = payload.data.investment;
    },

    [getSingleInvestment.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },

    //Indicate interest
    [postInterest.pending]: (state) => {
      state.loading = true;
    },
    [postInterest.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    },
    [postInterest.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [getSingleOfftakerAuctions.pending]: (state) => {
      state.loading = true;
    },
    [getSingleOfftakerAuctions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.make_bid = payload.data;
    },
    [getSingleOfftakerAuctions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      state.make_bid = payload.data;

      toast.error(payload.message);
    },
    [placeBid.pending]: (state) => {
      state.loading = true;
    },
    [placeBid.fulfilled]: (state) => {
      state.loading = false;
    },
    [placeBid.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;

      toast.error(payload.message);
    },

    //get offtaker transactions.
    [getAllOfftakerTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getAllOfftakerTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.transactions.data = payload.data.transactions;
      state.transactions.counts = payload.data.counts;
      state.transactions.pagination = payload.data.pagination;
    },
    [getAllOfftakerTransactions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    }, // eslint-disable-next-line no-unused-vars
    [fetchPaginatedTransactions.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPaginatedTransactions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.transactions.data = payload.data.transactions;
      state.transactions.counts = payload.data.counts;
      state.transactions.pagination = payload.data.pagination;
    },
    [fetchPaginatedTransactions.rejected]: (state, action) => {
      state.error = true;
      console.log(action);
    },
    //get single offtaker transactions.
    [getSingleTransaction.pending]: (state) => {
      state.loading = true;
    },
    [getSingleTransaction.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.singleTransaction = payload.transaction;
    },
    [getSingleTransaction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [getProjects.pending]: (state) => {
      state.loading = true;
    },
    [getProjects.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projects.data = payload.data.projects;
      state.projects.counts = payload.data.counts;
      state.projects.pagination = payload.data.pagination;
    },
    // eslint-disable-next-line no-unused-vars
    [getProjects.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchPaginatedProjects.pending]: (state) => {
      state.loading = true;
    },
    [fetchPaginatedProjects.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projects.data = payload.data.projects;
      state.projects.counts = payload.data.counts;
      state.projects.pagination = payload.data.pagination;
    },
    [fetchPaginatedProjects.rejected]: (state, action) => {
      state.error = true;
      console.log(action);
    },
    [getProject.pending]: (state) => {
      state.loading = true;
    },
    [getProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.project = payload.data.project[0];
    },
    // eslint-disable-next-line no-unused-vars
    [getProject.rejected]: (state, action) => {
      state.loading = false;
    },

    [getOffers.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getOffers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.offers.data = payload.data.offers;
      state.offers.counts = payload.data.counts;
      state.offers.pagination = payload.data.pagination;
    },
    [getOffers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    }, // eslint-disable-next-line no-unused-vars
    [fetchPaginatedOffers.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPaginatedOffers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.offers.data = payload.data.offers;
      state.offers.counts = payload.data.counts;
      state.offers.pagination = payload.data.pagination;
    },
    [fetchPaginatedOffers.rejected]: (state, action) => {
      state.error = true;
      console.log(action);
    },
    // fetch transaction data
    [getOffer.pending]: (state) => {
      state.loading = true;
    },
    [getOffer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.offer = payload.data.offer[0];
    },
    [getOffer.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [getInterests.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getInterests.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.interests.data = payload.data.interest[0]?.docs;
      state.interests.pagination = payload.data.interest[0].pagination;
    },
    [getInterests.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    }, // eslint-disable-next-line no-unused-vars
    [getInterest.pending]: (state) => {
      state.loading = true;
    },
    [getInterest.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.interest = payload.data.interest;
    },
    [getInterest.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [getVendors.pending]: (state) => {
      state.loading = true;
    },
    [getVendors.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.vendors) state.vendors = action.payload.vendors.docs;
    },
    // eslint-disable-next-line no-unused-vars
    [getVendors.rejected]: (state, action) => {
      state.loading = false;
    },

    [searchVendors.pending]: (state) => {
      state.loading = true;
    },
    [searchVendors.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.vendors) state.vendors = action.payload.vendors.docs;
    },
    // eslint-disable-next-line no-unused-vars
    [searchVendors.rejected]: (state, action) => {
      state.loading = false;
    },
    [getVendor.pending]: (state) => {
      state.loading = true;
    },
    [getVendor.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.vendor) state.vendor = action.payload;
    },
    [getCountries.pending]: (state) => {
      state.isLoading = true;
    },
    [getCountries.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload.countries) state.countries = action.payload.countries;
      if (action.payload.service_options)
        state.service_options = action.payload.service_options;
    },
    // eslint-disable-next-line no-unused-vars
    [getCountries.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // Get All Bids
    [getBids.pending]: (state) => {
      state.loading = true;
    },
    [getBids.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.status = payload.status;
      state.bids.data = payload.bids;
      state.bids.counts = payload.counts;
      state.bids.pagination = payload.pagination;
    },
    [getBids.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },
    [fetchPaginatedBids.pending]: (state) => {
      state.loading = true;
    },
    [fetchPaginatedBids.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.bids.data = payload.data.bids;
      state.bids.counts = payload.data.counts;
      state.bids.pagination = payload.data.pagination;
    },
    [fetchPaginatedBids.rejected]: (state, action) => {
      state.error = true;
      console.log(action);
    },

    // Make payment
    [makePayment.pending]: (state) => {
      state.loading = true;
    },
    [makePayment.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.status = payload.status;
     
    },
    [makePayment.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },

    // Submit ratings
    [submitRatings.pending]: (state) => {
      state.loading = true;
    },
    [submitRatings.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.status = payload.status;
      toast.success(payload?.data.message);
    },
    [submitRatings.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },
    [submitVendorRatingsFromOfftaker.pending]: (state) => {
      state.loading = true;
    },
    [submitVendorRatingsFromOfftaker.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.status = payload.status;
      toast.success(payload?.data.message);
    },
    [submitVendorRatingsFromOfftaker.rejected]: (state, { payload }) => {
      state.message = payload.message;
      state.error = true;
      state.loading = false;
      toast.error(payload.message);
    },
    [contactVendor.pending]: (state) => {
      state.loading = true;
    },
    [contactVendor.fulfilled]: (state, { payload }) => {
      //state.data = payload.data.files
      state.status = payload.status;
      state.loading = false;
    },
    [contactVendor.rejected]: (state, { payload }) => {
      console.log(payload);
      state.error = true;
      state.loading = false;
    },
    [requestService.pending]: (state) => {
      state.loading = true;
    },
    [requestService.fulfilled]: (state, { payload }) => {
      //state.data = payload.data.files
      state.status = payload.status;
      state.loading = false;
    },
    [requestService.rejected]: (state, { payload }) => {
      console.log(payload);
      state.error = true;
      state.loading = false;
    },
  },
});

export const {
  setOffering,
  setTransactionSearchValue,
  setBidSearchValue,
  setProjectsSearchValue,
  setOfftakerOffersSearchValue,
  setAwardedInterestSub,
  setProspectsSearchValue,
  setDeletedInterestSub,
} = offtakerSlice.actions;

export {
  fetchAllProposals,
  getAllOfftakerAuctions,
  searchAuctions,
  getSingleOfftakerAuctions,
  getSingleProposal,
  postProposalInterest,
  getCreateProspectData,
  postInterest,
  postFindProspects,
  submitVendorRatingsFromOfftaker,
  getAllProspects,
  fetchPaginatedProspects,
  getProspectInterests,
  getProspectInterestDetail,
  acceptProspectInterest,
  placeBid,
  getAllOfftakerTransactions,
  getSingleTransaction,
  getVendors,
  getVendor,
  getCountries,
  getBids,
  makePayment,
  getInvestments,
  getSingleInvestment,
  submitRatings,
  contactVendor,
  requestService,
};

export default offtakerSlice.reducer;
