const Money = ({ width, height, fill }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "25"}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 8.5V16.5H5V8.5H19ZM20 7.5H4V17.5H20V7.5Z"
        fill={fill || "#D9E8FC"}
        fillOpacity="0.7"
      />
      <path
        d="M12 9.5C13.7 9.5 15 10.8 15 12.5C15 14.2 13.7 15.5 12 15.5H17V14.5H18V10.5H17V9.5H12Z"
        fill={fill || "#D9E8FC"}
        fillOpacity="0.7"
      />
      <path
        d="M9 12.5C9 10.8 10.3 9.5 12 9.5H7V10.5H6V14.5H7V15.5H12C10.3 15.5 9 14.2 9 12.5Z"
        fill={fill || "#D9E8FC"}
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default Money;
