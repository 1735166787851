import React, { useState } from "react";
import { Button, ControlledModal, TextArea } from "@ui";

const ProposalDetails = () => {
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleOpenFeedback = () => {
    setOpened(true), setOpen(false);
  };
  const handleClosed = () => setOpened(false);

  const handleClose = () => setOpen(false);
  return (
    <div>
      <ControlledModal
        isOpen={open}
        handleClose={handleClose}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
      >
        <p className="text-head text-center font-semibold text-[22px]">
          Archive Auction?
        </p>
        <p className="text-lightgray text-center font-light text-base mt-2">
          Are you sure you want to archive this investment proposal?
        </p>
        <div className="flex flex-row justify-center gap-4 mt-6">
          <Button type="button" variant="text" color="neutral">
            Cancel
          </Button>
          <Button
            type="button"
            variant="contained"
            color="danger"
            onClick={handleOpenFeedback}
          >
            Yes, archive
          </Button>
        </div>
      </ControlledModal>
      <ControlledModal
        isOpen={opened}
        handleClose={handleClosed}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
      >
        <div className="flex flex-col justify-center gap-4 mt-6">
          <p className="text-head text-center font-semibold text-[22px]">
            Feedback to user
          </p>
          <p className="text-lightgray text-center font-light text-base mt-2">
            Kindly provide additional notes to explain to user the reason for
            archiving the investment offer.
          </p>
          <TextArea />
          <div className="flex justify-end ">
            <Button type="button" variant="contained" color="primary">
              Send
            </Button>
          </div>
        </div>
      </ControlledModal>
      <div className="text-default  bg-white px-[34px] py-[54px]">
        <div className="flex justify-between h-[42px] mb-6">
          <p className="text-[22px] font-normal">Proposal details</p>
          <Button onClick={handleOpen}>Archive</Button>
        </div>
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 w-[206px] h-[66px]">
          <div className=" h-[60px] w-[60px] text-xs  rounded-full">
            <img
              className="h-full w-full object-cover"
              src="https//:www.image.com"
              alt="profile image"
            />
          </div>
          <div className="flex flex-col justify-start items-start flex-grow-0 ml-4 flex-shrink-0 ">
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-base text-left text-primary">
              Sirius Black
            </p>
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-sm text-left text-[#18273a]/[0.94]">
              Deniola Corporation
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-sm font-light text-left text-[#1b2b41]/[0.72]">
              United States
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start flex-grow-0 mt-6  flex-shrink-0 w-[307px]  ">
          <div className="flex flex-row justify-between mb-[15px]  w-[253px] h-[26px]">
            <p className=" left-0 top-[3.5px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
              Mineral:
            </p>
            <p className=" left-[159px] top-0 text-base font-light text-left text-[#18273a]/[0.94]">
              Copper
            </p>
          </div>
          <div className="flex flex-row justify-between mb-[15px]  w-[281px] h-[26px]">
            <p className=" left-0 top-[37.5px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
              Phase to fund:
            </p>
            <p className=" left-[159px] top-[34px] text-base font-light text-left text-[#18273a]/[0.94]">
              Exploration
            </p>
          </div>
          <div className="flex flex-row justify-between mb-[15px] w-[300px] h-[26px]">
            <p className=" left-0 top-[71.5px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
              Investment type:
            </p>
            <p className=" left-[159px] top-[68px] text-base font-light text-left text-[#18273a]/[0.94]">
              Private equity
            </p>
          </div>
          <div className="flex flex-row justify-between mb-[15px] w-[298px] h-[26px]">
            <p className=" left-0 top-[105.5px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
              Investment :
            </p>
            <p className=" left-[159px] top-[102px] text-base font-light text-left text-[#18273a]/[0.94]">
              200 000 USD
            </p>
          </div>

          <div className=" flex justify-between flex-grow-0 flex-shrink-0 w-[1045px] h-[222px]">
            <p className=" text-base font-light text-left text-[#1b2b41]/[0.72]">
              Funding terms:
            </p>
            <p className="w-[846px]  text-base font-light text-left text-[#18273a]/[0.94]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi quis
              non viverra elit sed etiam integer etiam. Pharetra massa vitae
              gravida lacus, rutrum tempor sed quam. At quam luctus blandit
              fames. Nisl, eu amet sed iaculis sagittis pretium neque integer
              tincidunt. Molestie rutrum pulvinar pharetra nunc, ac. Morbi
              platea magnis nulla etiam pretium sed placerat tempus. Ultrices
              aenean diam varius donec quam mattis quis pulvinar egestas. Odio
              enim, facilisis ac tristique sem risus lacus. <br />
              Facilisi tellus sit ut amet non. Nulla quis commodo tincidunt
              sapien elit donec. Amet urna feugiat at aenean nisl placerat
              lorem. Fusce euismod tristique at nec. Nibh facilisi varius cursus
              mauris. Felis vel enim vel amet quis. Feugiat cursus dictumst
              suspendisse non. Lectus accumsan nibh quis vestibulum nunc montes,
              ut diam. Sit lacus fames sed tempor ullamcorper bibendum nisl,
              nunc. Nulla elit pellentesque lectus non sed diam suspendisse
              lectus suspendisse.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposalDetails;
