/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button } from "@atoms/button/index";
import { Input, TextArea } from "@atoms/input";
import { EyeIcon,EyeOffIcon } from "@heroicons/react/outline";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postInterest } from "store/slices/offtaker-slice";

const schema = yup.object().shape({
  name: yup.string().required("Please add your name"),
  phoneInput: yup.string().required("Please add your phone number"),
  additional_notes: yup.string().required("Please add note"),
  email: yup
    .string()
    .email("Invalid email")
    .max(255)
    .required("Email is required"),
  // phone: yup.string().required().typeError("Phone number is required"),
  password: yup
    .string()
    .min(8)
    .max(16)
    .required("Enter a password of a minimum of 8 characters"),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
});

const Interest = () => {
  const [_number, setNumber] = useState();
  const [proposalDetails, setProposalDetails] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [passValue, setPassValue] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () =>
    setPassValue({ ...passValue, showPassword: !passValue.showPassword });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  useEffect(() => {
    if (location.state && location.state.proposal) {
      setProposalDetails(location.state.proposal);
    }
  }, []);


  const onSubmit = async (data) => {
    const { name, email, password, additional_notes } = data;
    const { code, number } = _number

    const formData = {
      name, email, password, additional_notes, code, number
    }
    try {
      const response = await dispatch(postInterest({ uuid: proposalDetails.uuid, formData }));
      if (response.payload.status === 200) {
        navigate("/offtaker-invest/details/complete");
      } else {
        console.log("error from then", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="w-[1185px] mt-10 font-default">
      <div className="bg-white rounded px-24 pt-10  pb-8">
        <div className=" my-[40px]">
          <p className="text-[22px] text-head">Indicate interest</p>

          <p className="text-lightgray my-2 text-base font-light text-start">
            The information provided will be accessed by the miner in order to
            contact you if selected.
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4">
              <label className="block text-sm mb-2  text-lightgray">Name</label>
              <Input
                type="text"
                name="name"
                placeholder="Sirius Black"
                {...register("name")}
              />
              <p className="h-2 flex justify-center align-center mt-1 text-primary text-xs">
                {errors.name?.message}
              </p>
            </div>

            <div className="mt-4">
              <label className="block text-sm  mb-2 text-lightgray">
                Business Email Address
              </label>
              <Input
                type="text"
                name="email"
                placeholder="siriusblack99@daniolacorp.com"
                {...register("email")}
              />
              <p className="h-2 flex justify-center align-center mt-1 text-primary text-xs">
                {" "}
                {errors.email?.message}
              </p>
            </div>

            <div className="mt-4">
              <label className="block text-sm mb-1 text-lightgray">
                Phone Number
              </label>
              <Controller
                name="phoneInput"
                control={control}
                {...register("phoneInput")}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      backgroundColor: "#1A38601A",
                      border: "none",
                      cursor: "text",
                      appearance: "none",
                      borderRadius: "4px",
                    }}
                    type="text"
                    country="us"
                    value={value}
                    onChange={(value, data, event, formattedValue, phone) => {
                      setNumber({
                        code: data.dialCode,
                        number: value.slice(data?.dialCode.length)
                      })
                      onChange(value);
                    }}
                    masks={{ fr: "(...) ..-..-..", at: "(....) ...-...." }}
                  />

                )}
              />
              <p className="h-2 flex justify-center align-center mt-1 text-primary text-xs">
                {errors.phoneInput?.message}
              </p>
            </div>


            {/* <div className="mt-4 ">
              <label className="block text-sm mb-1 text-lightgray">
                Additional phone number
              </label>
              <div className="flex flex-row">
                <div className="bg-fill border-none w-[88px] mr-1 focus:outline-none break-all focus:border-primary focus:ring-1 focus:ring-primary appearance-none rounded cursor-text text-input text-sm py-2 px-4">
                  {" "}
                  <span className=" flex justify-end items-end relative cursor-pointer ">
                    <Shape className="h-4 absolute top-2 right-0" />
                  </span>
                </div>

                <Input name="phone" type="number" {...register("phone")} /> */}
            {/* <p>{errors?.phone.message}</p> */}
            {/* </div>
            </div> */}

            <div className="mt-4">
              <label className="block text-sm  mb-2 text-lightgray">
                Additional notes
              </label>
              <TextArea
                name="additional_notes"
                type="text"
                {...register("additional_notes")}
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. A ut urna, non morbi convallis nec, et phasellus et. Cras a sed enim in aliquet nulla quis adipiscing imperdiet. In lectus in aliquam mauris ultricies. Cum et nunc porttitor sed sit."
              />
            </div>

            <div className="password_2 block mt-4 relative">
              <label className="block text-sm  mb-2 text-lightgray">
                Create unique password
              </label>
              <div className="eye_div">
                <Input
                  name="password"
                  type={passValue.showPassword ? "text" : "password"}
                  {...register("password")}
                />
                <p className="text[13px] font-light text-left text-xs text-[#1c304a]/50">
                  You are required to request this password upon being contacted
                  with the miner. Do not share this password with a third-party.
                </p>
                <p className="h-2 mt-1 flex justify-center align-center text-primary  text-xs">
                  {errors.password?.message}
                </p>
                <div
                  className="icon_button absolute right-4 top-9"
                  onClick={handleClickShowPassword}
                >
                  {passValue.showPassword ? (
                    <EyeIcon className="h-6 font-extralight cursor-pointer" />
                  ) : (
                    <EyeOffIcon className="h-6 font-extralight cursor-pointer" />
                  )}
                </div>
              </div>
            </div>

            <div className="password_2 block mt-4 relative">
              <label className="block text-sm  mb-1 text-lightgray">
                Verify unique password
              </label>
              <div className="eye_div">
                <Input
                  name="confirmPassword"
                  type={passValue.showPassword ? "text" : "password"}
                  {...register("confirmPassword")}
                />
                <p className="h-2 text-primary flex justify-center align-center  text-xs">
                  {errors.confirmPassword && "Passwords should match"}
                </p>
                <div
                  className="icon_button absolute right-4 top-8"
                  onClick={handleClickShowPassword}
                >
                  {passValue.showPassword ? (
                    <EyeIcon className="h-6 font-extralight cursor-pointer" />
                  ) : (
                    <EyeOffIcon className="h-6 font-extralight cursor-pointer" />
                  )}
                </div>
              </div>
            </div>

            <div className="mt-[80px] flex justify-between">
              <button className="bg-dark-gray-300 text-primary w-[fit-content] flex justify-center py-2 mb-3 mt-4 px-4 rounded">
                Cancel
              </button>

              <Button
                disabled={!isDirty || !isValid}
                size="small"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Interest;
