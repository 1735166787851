/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  MoneyIcon,
  SearchIcon,
  DownloadIcon,
  Sort,
  DropDownIcon,
} from "@ui/icons";
import DocumentIcon from "components/ui/icons/DocumentIcon";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import dayjs from "dayjs";
import { Modal } from "@mui/material";
import { Button, Search, TableSkeleton, TableEmptyState } from "@ui";
import { paymentHeaders, statusColorScheme } from "constants/index";
import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { fetchPayments } from "@slices/admin-slice";
import { useDispatch, useSelector } from "react-redux";

const Stat = ({ bgColor, children }) => {
  const backgroundColor = bgColor ? bgColor : "bg-white";
  const boxShadow = bgColor && "shadow-Stat";
  return (
    <div
      className={`flex items-center justify-between border border-solid border-dark-gray-100 px-6 py-5 rounded-lg ${backgroundColor} ${boxShadow}`}
    >
      {children}
    </div>
  );
};

const Payments = () => {
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const [searchParams, setParams] = useState("");
  const [payment, setPayment] = useState({});
  const { loading, payments, counts, pagination } = useSelector(
    (state) => state.admin
  );
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const handleViewDetails = (mine) => {
    setPayment(payments.find((m) => m._id === mine._id));
    setOpen(true);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchPayments({
        page: 1,
        limit: limit,
        search: searchParams,
      })
    );
  };

  const handleSetPerPage = (e) => {
    setLimit(e.target.value);
    dispatch(
      fetchPayments({
        page: 1,
        limit: e.target.value,
        search: searchParams,
      })
    );
  };

  function handleChangePage(page) {
    dispatch(
      fetchPayments({
        page: page,
        limit: limit,
        search: searchParams,
      })
    );
  }

  const handleSearch = (e) => {
    setParams(e.target.value);
  };

  const exportcsv = () => {
    let header = [
      "ID",
      "Transaction ID",
      "Amount",
      "Currency",
      "Name",
      "Email",
      "Status",
      "Receipt Url",
      "Payment Mode",
      "Platform Charges",
      "Remarks",
      "IsActive",
      "Created At",
    ];
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, payments, {
      origin: "A2",
      skipHeader: true,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "base64" });
    FileSaver.saveAs(finalData, "Payments.xlsx");
  };

  useEffect(() => {
    dispatch(
      fetchPayments({
        page: 1,
        limit: limit,
        search: searchParams,
      })
    );
  }, [dispatch]);

  return (
    <div>
      <div className="flex overflow-x-auto scrollbar-hide lg:grid grid-cols-5 gap-3">
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">
              TOTAL REVENUE
            </p>
            <p className="text-2xl text-[#374146]">
              {counts?.total_revenue} USD
            </p>
          </div>
          <div>
            <MoneyIcon />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">
              TOTAL PAYMENTS
            </p>
            <p className="text-2xl text-[#374146]">{counts?.total_payments}</p>
          </div>
          <div>
            <MoneyIcon />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              REVENUE TODAY
            </p>
            <p className="text-2xl text-[#374146]">
              {counts?.revenue_today} USD
            </p>
          </div>
          <div>
            <MoneyIcon />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              PAYMENTS TODAY
            </p>
            <p className="text-2xl text-[#374146]">{counts?.payments_today}</p>
          </div>
          <div>
            <MoneyIcon />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              TOTAL PROFIT
            </p>
            <p className="text-2xl text-[#374146]">
              {counts?.total_profit} USD
            </p>
          </div>
          <div>
            <MoneyIcon />
          </div>
        </Stat>
      </div>
      <div className="flex lg:flex flex-rols-1 gap-3">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6">
          <div className="overflow-hidden border border-dark-gray-100 sm:rounded-lg bg-white">
            {/* input field goes here */}
            <div className="flex justify-between p-4">
              <div className="flex gap-2">
                <form
                  onSubmit={(e) => handleSearchSubmit(e)}
                  className="flex gap-2"
                >
                  <div className="w-[250px] lg:w-[538px]">
                    <Search
                      placeholder="Search payments"
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                  <Button type="submit" color="primary">
                    <SearchIcon color="#ffff" opacity="1" />
                  </Button>
                </form>
              </div>
              <div className="w-[183px] h-[44px] ">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={() => {
                    exportcsv();
                  }}
                >
                  Export CSV
                </Button>
              </div>
            </div>
            {loading && <TableSkeleton />}
            {!loading && payments.length > 0 && (
              <>
                <table className="min-w-full divide-y divide-[#1A38601A] border-b border-[#1A38601A]">
                  <thead className="bg-[#EFF0FE]">
                    <tr>
                      {paymentHeaders.tableHeaders.map((header, index) => (
                        <th
                          key={index + 1}
                          scope="col"
                          className="px-6 py-3 text-left text-sm font-normal text-primary uppercase tracking-wider"
                        >
                          {!header.hasIcon && header.title}
                          {header.hasIcon && (
                            <div className="flex  items-center">
                              <span>{header.title}</span>
                              <Sort />
                            </div>
                          )}
                        </th>
                      ))}
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Delete</span>
                      </th>
                      {/* <th scope="col" className="px-3 py-3">
     <EclipseDotsIcon />
   </th> */}
                    </tr>
                  </thead>
                  <tbody className="bg-white font-light divide-y divide-[#1A38601A]">
                    {payments?.length > 0 &&
                      payments.map((record, index) => {
                        const statusObject = paymentHeaders.fields.find(
                          (field) => field.name === "status"
                        );
                        const statusValue = statusObject?.isFunction
                          ? statusObject.func(record)
                          : record.status;
                        return (
                          <tr key={index} className="text-[#18273AF0]">
                            {paymentHeaders.fields.map((field, index) =>
                              field.name === "status" ? (
                                <td
                                  key={index}
                                  className="px-6 py-4 whitespace-nowrap"
                                >
                                  <span
                                    className={`py-1 px-5 inline-flex capitalize text-sm leading-5 font-light rounded bg-[${statusColorScheme[statusValue].bg}] text-[${statusColorScheme[statusValue].text}] `}
                                  >
                                    {statusValue}
                                  </span>
                                </td>
                              ) : (
                                <td
                                  key={index}
                                  className={`px-6 py-4 whitespace-nowrap text-sm ${
                                    field.name === "location" && "text-center"
                                  }`}
                                >
                                  {field.isFunction
                                    ? field.func(record)
                                    : record[field.name]}
                                </td>
                              )
                            )}
                            <td
                              className="px-6 py-4 whitespace-nowrap text-sm text-[#6166B6] underline cursor-pointer"
                              onClick={() => {
                                handleViewDetails(record);
                              }}
                            >
                              View details
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="flex justify-start lg:justify-end items-center w-full py-4 px-8 text-[13px] uppercase text-[#18273AF0]">
                  <div className="flex justify-between gap-2 items-center">
                    <p>Rows per page: {pagination?.limit}</p>
                    <FormControl sx={{ height: "30px", border: 0 }}>
                      <Select
                        sx={{ height: "30px", border: 0 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => handleSetPerPage(e)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="mx-6">
                    1 - {Math.ceil(pagination?.totalDocs / pagination?.limit)}{" "}
                    of {pagination?.page}
                  </div>
                  <div className="flex justify-between gap-10">
                    {pagination?.prevPage && (
                      <div
                        className="rotate-90 cursor-pointer"
                        onClick={() => handleChangePage(pagination?.prevPage)}
                      >
                        <DropDownIcon />
                      </div>
                    )}
                    {pagination?.page >=
                    Math.ceil(pagination?.totalDocs / pagination?.limit)
                      ? null
                      : pagination?.page + 1 && (
                          <div
                            className="-rotate-90 cursor-pointer"
                            onClick={() =>
                              handleChangePage(pagination?.nextPage)
                            }
                          >
                            <DropDownIcon />
                          </div>
                        )}
                  </div>
                </div>
              </>
            )}
            {payments !== null && !payments?.length > 0 && !loading && (
              <TableEmptyState text="payments" />
            )}
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute top-2/4 left-2/4 -translate-y-1/2 -translate-x-1/2 w-auto outline-0 bg-white shadow-lg px-8 py-10 capitalize">
          <span
            className="icon-close absolute right-5 top-4"
            onClick={handleClose}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.92 8.42L15.5 7L11.96 10.55L8.42 7L7 8.42L10.55 11.96L7 15.5L8.42 16.92L11.96 13.37L15.5 16.92L16.92 15.5L13.37 11.96L16.92 8.42Z"
                fill="#1C2E45"
                fillOpacity="0.6"
              />
            </svg>
          </span>
          <div className="max-w-[796px] mx-auto">
            <h2 className="text-heading text-2xl my-2">Payment Details</h2>
            <div className="my-6">
              <div className="grid grid-cols-4 gap-y-4 mb-3">
                <div className="col-span-1 font-light text-ts">
                  <p>Transaction ID: </p>
                </div>
                <div className="col-span-3 font-light text-light capitalize ml-12">
                  <p>{payment?._id}</p>
                </div>
                <div className="col-span-1 font-light text-ts">
                  <p>Name: </p>
                </div>
                <div className="col-span-3 font-light text-light capitalize ml-12">
                  <p>{payment?.name}</p>
                </div>
                <div className="col-span-1 font-light text-ts">
                  <p>Email: </p>
                </div>
                <div className="col-span-3 font-light text-light capitalize ml-12">
                  <p>{payment?.email}</p>
                </div>

                <div className="col-span-1 font-light text-ts">
                  <p>Amount: </p>
                </div>
                <div className="col-span-3 font-light text-light capitalize ml-12">
                  <p>{payment?.amount - payment?.platform_charges} USD</p>
                </div>
                <div className="col-span-1 font-light text-ts">
                  <p>Platform Charges: </p>
                </div>
                <div className="col-span-3 font-light text-light capitalize ml-12">
                  <p>{parseFloat(payment?.platform_charges).toFixed(2)} USD</p>
                </div>
                <div className="col-span-1 font-light text-ts">
                  <p>Paid Amount: </p>
                </div>
                <div className="col-span-3 font-light text-light capitalize ml-12">
                  <p>{payment?.amount} USD</p>
                </div>
                <div className="col-span-1 font-light text-ts">
                  <p>Date: </p>
                </div>
                <div className="col-span-3 font-light text-light capitalize ml-12">
                  <p>{dayjs(payment?.created_at).format("DD/MM/YYYY")}</p>
                </div>
                <div className="col-span-1 font-light text-ts">
                  <p>Payment method: </p>
                </div>
                <div className="col-span-3 font-light text-light capitalize ml-12">
                  <p>{payment?.payment_mode}</p>
                </div>
                <div className="col-span-1 font-light text-ts">
                  <p>Reference No: </p>
                </div>
                <div className="col-span-3 font-light text-light capitalize ml-12">
                  <p>{payment?.payment_id}</p>
                </div>
                <div className="col-span-1 font-light text-ts">
                  <p>Receipt: </p>
                </div>
                <div className="col-span-3 font-light text-light capitalize ml-12">
                  <a
                    download
                    target="_blank"
                    rel="noreferrer"
                    href={payment?.receipt_url}
                    className=" flex items-center  text-xs mb-[16px]"
                  >
                    <DocumentIcon />
                    <span className="text-primary text-sm font-light ml-2">
                      Receipt
                    </span>
                  </a>
                </div>
                <div className="col-span-1 font-light text-ts">
                  <p>Status: </p>
                </div>
                <div
                  className={`font-light text-light capitalize ml-12 py-1 px-5 inline-flex capitalize text-sm leading-5 font-light rounded bg-[${
                    statusColorScheme[payment?.status]?.bg
                  }] text-[${statusColorScheme[payment?.status]?.text}]`}
                >
                  <p>{payment?.status}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Payments;
