/* eslint-disable react/prop-types */
import {
  BarChartIcon,
  DiamondIcon,
  MoneyIcon,
  PackageIcon,
  Megaphone,
} from "@ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminAnalytics } from "@slices/admin-slice";
// import {useAuth} from "@hooks"

const Stat = ({ bgColor, children }) => {
  const backgroundColor = bgColor ? bgColor : "bg-white";
  const boxShadow = bgColor && "shadow-Stat";

  return (
    <div
      className={`flex items-center justify-between border border-solid border-dark-gray-100 px-6 py-5 rounded-lg ${backgroundColor} ${boxShadow}`}
    >
      {children}
    </div>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const { analytics } = useSelector((state) => state.admin);
  
  useEffect(() => {
    dispatch(fetchAdminAnalytics());
  }, [dispatch]);

  return (
    <div>
      <div className="flex flex-col lg:grid grid-cols-4 gap-6">
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">
              TOTAL NO. OF USERS
            </p>
            <p className="text-2xl text-[#374146]">
              {analytics?.total_active_users}
            </p>
          </div>
          <div>
            <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">
              NO. OF MINERS
            </p>
            <p className="text-2xl text-[#374146]">{analytics?.total_miners}</p>
          </div>
          <div>
            <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>
        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              NO. OF OFFTAKERS
            </p>
            <p className="text-2xl text-[#374146]">
              {analytics?.total_off_taker}
            </p>
          </div>
          <div>
            <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>

        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              NO. OF VENDORS
            </p>
            <p className="text-2xl text-[#374146]">
              {analytics?.total_vendors}
            </p>
          </div>
          <div>
            <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>

        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              NO. OF NEW USERS
            </p>
            <p className="text-2xl text-[#374146]">{analytics?.new_users}</p>
          </div>
          <div>
            <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>

        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              VERIFICATION REQUESTS
            </p>
            <p className="text-2xl text-[#374146]">
              {analytics?.verification_request}
            </p>
          </div>
          <div>
            <BarChartIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>
      </div>

      <div className="flex flex-col border-t-2 border-t-primary lg:border-none lg:grid grid-cols-4 gap-6 mt-3 pt-3">
        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">
              ACTIVE AUCTIONS
            </p>
            <p className="text-2xl text-[#374146]">
              {analytics?.active_acutions}
            </p>
          </div>
          <div>
            <DiamondIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>

        <Stat>
          <div>
            <p className="text-sm mb-2 uppercase text-[#92A2AA]">
              ACTIVE PROPOSALS
            </p>
            <p className="text-2xl text-[#374146]">
              {analytics?.active_proposal}
            </p>
          </div>
          <div>
            <DiamondIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>

        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              ACTIVE OFFERINGS
            </p>
            <p className="text-2xl text-[#374146]">{analytics?.offerings}</p>
          </div>
          <div>
            <DiamondIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>

        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              SERVICE REQUESTS
            </p>
            <p className="text-2xl text-[#374146]">
              {analytics?.service_request}
            </p>
          </div>
          <div>
            <DiamondIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>

        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              TRANSACTIONS TODAY
            </p>
            <p className="text-2xl text-[#374146]">
              {analytics?.today_transactions}
            </p>
          </div>
          <div>
            <MoneyIcon />
          </div>
        </Stat>

        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              TOTAL NO. OF BIDS
            </p>
            <p className="text-2xl text-[#374146]">{analytics?.total_bids}</p>
          </div>
          <div>
            <MoneyIcon />
          </div>
        </Stat>

        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              BIDS AWARDED
            </p>
            <p className="text-2xl text-[#374146]">{analytics?.awarded_bids}</p>
          </div>
          <div>
            <MoneyIcon />
          </div>
        </Stat>

        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">OFFERS</p>
            <p className="text-2xl text-[#374146]">{analytics?.offers}</p>
          </div>
          <div>
            <Megaphone />
          </div>
        </Stat>

        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">MINES</p>
            <p className="text-2xl text-[#374146]">{analytics?.active_mines}</p>
          </div>
          <div>
            <DiamondIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>

        <Stat>
          <div>
            <p className="text-sm  mb-2 uppercase text-[#92A2AA]">
              items in inventory
            </p>
            <p className="text-2xl text-[#374146]">
              {analytics?.items_in_inventory}
            </p>
          </div>
          <div>
            <PackageIcon h="41" w="41" color="#6166B6" opacity="1" />
          </div>
        </Stat>
      </div>
    </div>
  );
};

export default Dashboard;
