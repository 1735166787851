import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as API from "../../config/url";
import { toast } from "react-toastify";
import { fetchToken } from "@config/firebase";

const signInUser = createAsyncThunk(
  "user/signinUser",
  async (data, { rejectWithValue }) => {
    /**
     * Temp fix
     * Kindly rewrite logic to always make call to get token from firebase
     *
     */
    let fcnToken = localStorage.getItem("firebaseToken");
    if (!fcnToken) {
      await fetchToken(false);
      fcnToken = localStorage.getItem("firebaseToken");
    }
    ///////////////////////////////////
    try {
      const response = await axios({
        method: "post",
        url: API.postSignIn,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: data.email,
          password: data.password,
          strategy: "local",
          notification: {
            token: fcnToken ?? null,
            device: "web",
          },
        },
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// const SignOut = createAsyncThunk(
//   "user/SignOut",
//   async (data, { rejectWithValue }) => {
//     try {
//       return await axios({
//         method: "post",
//         url: API.postSignOut,
//         headers: {
//           "Content-Type": "application/json",
//           "x-access-token": JSON.parse(localStorage.getItem("token")),
//         },
//         data: {},
//       });
//     } catch (err) {
//       if (err.response !== undefined) {
//         return rejectWithValue(err.response.data);
//       }
//       return rejectWithValue(err);
//     }
//   }
// );

const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (data, { rejectWithValue }) => {
    try {
      return await axios({
        method: "post",
        url: API.postForgotPassword,
        Headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: data.email,
        },
      });
    } catch (err) {
      if (err.response !== undefined) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    error: null,
    data: {},
    success: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    // signin user reducer
    [signInUser.pending]: (state) => {
      state.loading = true;
      localStorage.clear();
    },
    [signInUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.success = true;
      localStorage.setItem("token", JSON.stringify(payload.data.accessToken));
      // localStorage.setItem("notification_token", JSON.stringify(payload?.data?.token?.notification?.token));
      if(payload.data.code !== 400) {
        localStorage.setItem("user", JSON.stringify(payload.data.user));
        localStorage.setItem("account", JSON.stringify(payload.data.account));
      }
    
    },
    [signInUser.rejected]: (state, { payload }) => {
      state.loading = false;
      toast.error(payload.message);
    },

    // signout user reducer
    // [SignOut.pending]: (state) => {
    //   state.loading = true;
    // },
    // [SignOut.fulfilled]: (state, { payload }) => {
    //   state.loading = false;
    //   state.data = payload.data;
    //   state.success = true;
    //   localStorage.clear();
    // },
    // [SignOut.rejected]: (state, { payload }) => {
    //   state.loading = false;
    //   console.log(payload);
    //   // toast.error(payload.message);
    // },

    // forgot password reducer
    [forgotPassword.pending]: (state) => {
      state.loading = true;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      localStorage.setItem("data", JSON.stringify(payload.data));
      // toast.success(payload.message);
    },
    [forgotPassword.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      toast.error(error.message);
    },
  },
});

export { signInUser, forgotPassword };
export default slice.reducer;
