/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { Btn as Button } from "@atoms/button/button";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Input, TextArea, Card, Select } from "@ui";
import {
  getSupport,
  getSupportBySlug
} from "@slices/settings-slice";
import { toast } from "react-toastify";
import { TicketModal } from "components/ticket/TicketModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

const Support = () => {
  const { support } = useSelector((state) => state.settings);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSupport({role:"miner"}));
  }, [dispatch]);


  const openTicket = async () => {
    setOpen(true);
  };

  const supportBySlug = async (slug) => {
    let data = {
      slug: slug,
    };
    const response = await dispatch(getSupportBySlug(data));
    if (response.payload.status === 200 || response.payload.status === 201) {
      if (
        response.payload.data.code == 422 ||
        response.payload.data.code == 400
      ) {
        toast.error(response.payload.data.message);
      } else {
        window.open(response.payload?.data?.support?.video_link);
      }
    } else {
      toast.error(response.payload.msg);
    }
  };

  return (
    <>
      <TicketModal   open={open} setOpen={setOpen} role={"miner"} ></TicketModal>
      <div className="font-default overflow-hidden ">
        {" "}
        <div>
          <p className=" text-[22px] text-left text-black mb-[25px]">
            How can we help?
          </p>
          {/* import from Inspect plugin */}
          <div className="grid grid-cols-3 gap-8 sm:grid-cols-2">
            {support?.map((x, index) => (
              <Card
                key={index + 1}
                className="flex justify-center items-center px-[106px] py-10 rounded-md bg-white border border-[#1a3860]/10"
              >
                <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 ">
                  {x?.slug == "getting-started" ? (
                    <svg
                      width="82"
                      height="81"
                      viewBox="0 0 82 81"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-[81px] h-[81px]"
                      preserveAspectRatio="none"
                    >
                      <circle
                        cx="41"
                        cy="40.5"
                        r="40.5"
                        fill="#6166B6"
                        fillOpacity="0.1"
                      ></circle>
                      <path
                        d="M54.6836 26.6933C54.6836 26.6933 48.8503 26.0267 43.5169 31.36C40.8503 33.86 38.6836 37.1933 37.0169 40.1933L32.8503 39.1933L30.1836 41.86L34.8503 44.1933C34.3503 45.1933 34.1836 45.86 34.1836 45.86L35.5169 47.1933C35.5169 47.1933 36.1836 46.86 37.1836 46.5267L39.5169 51.1933L42.1836 48.5267L41.3503 44.36C44.1836 42.6933 47.6836 40.5267 50.1836 38.0267C55.3503 32.6933 54.6836 26.6933 54.6836 26.6933ZM49.3503 34.6933C48.6836 35.36 47.5169 35.36 46.6836 34.6933C46.0169 34.0267 46.0169 32.86 46.6836 32.0267C47.3503 31.36 48.5169 31.36 49.3503 32.0267C50.0169 32.6933 50.0169 33.86 49.3503 34.6933Z"
                        fill="#6166B6"
                      ></path>
                      <path
                        d="M34.683 50.36C33.3497 51.6933 30.3497 51.0266 30.3497 51.0266C30.3497 51.0266 29.683 48.0266 31.0164 46.6933C32.3497 45.36 33.5164 45.1933 33.5164 45.1933C33.5164 45.1933 31.3497 44.6933 30.0164 46.1933C27.3497 48.86 28.3497 53.1933 28.3497 53.1933C28.3497 53.1933 32.683 54.1933 35.3497 51.5266C36.8497 50.0266 36.3497 47.86 36.3497 47.86C36.3497 47.86 36.0164 49.0266 34.683 50.36Z"
                        fill="#6166B6"
                      ></path>
                    </svg>
                  ) : x?.slug == "walkthrough" ? (
                    <svg
                      width="81"
                      height="81"
                      viewBox="0 0 81 81"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-[81px] h-[81px]"
                      preserveAspectRatio="none"
                    >
                      <circle
                        cx="40.5"
                        cy="40.5"
                        r="40.5"
                        fill="#6166B6"
                        fillOpacity="0.1"
                      ></circle>
                      <path
                        d="M40.9993 26.6667C33.666 26.6667 27.666 32.6667 27.666 40C27.666 47.3333 33.666 53.3333 40.9993 53.3333C48.3327 53.3333 54.3327 47.3333 54.3327 40C54.3327 32.6667 48.3327 26.6667 40.9993 26.6667ZM37.666 46.6667V33.3333L47.666 40L37.666 46.6667Z"
                        fill="#6166B6"
                      ></path>
                    </svg>
                  ) : x?.slug == "dashboard" ? (
                    <svg
                      width="81"
                      height="81"
                      viewBox="0 0 81 81"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-[81px] h-[81px]"
                      preserveAspectRatio="none"
                    >
                      <circle
                        cx="40.5"
                        cy="40.5"
                        r="40.5"
                        fill="#6166B6"
                        fillOpacity="0.1"
                      ></circle>
                      <path
                        d="M54.3327 43.5C54.3327 36.1667 48.3327 30 40.9993 30C33.666 30 27.666 36.1667 27.666 43.5C27.666 45.8333 28.166 48.3333 29.166 50H37.3327C38.166 51 39.4993 51.6667 40.9993 51.6667C42.4993 51.6667 43.8327 51 44.666 50H52.8327C53.8327 48.3333 54.3327 45.8333 54.3327 43.5ZM50.9993 38.3333V40L44.166 45.8333C44.166 46 44.3327 46.3333 44.3327 46.6667C44.3327 48.5 42.8327 50 40.9993 50C39.166 50 37.666 48.5 37.666 46.6667C37.666 44.8333 39.166 43.3333 40.9993 43.3333C41.4993 43.3333 41.9993 43.5 42.3327 43.6667L49.3327 38.3333H50.9993ZM44.3327 33.3333H45.9993V35H44.3327V33.3333ZM35.9993 33.3333H37.666V35H35.9993V33.3333ZM30.9993 46.6667H29.3327V45H30.9993V46.6667ZM32.666 40H30.9993V38.3333H32.666V40ZM52.666 46.6667H50.9993V45H52.666V46.6667Z"
                        fill="#6166B6"
                      ></path>
                      <path
                        d="M42.6673 46.6667C42.6673 47.5867 41.9207 48.3333 41.0007 48.3333C40.0807 48.3333 39.334 47.5867 39.334 46.6667C39.334 45.7467 40.0807 45 41.0007 45C41.9207 45 42.6673 45.7467 42.6673 46.6667Z"
                        fill="#6166B6"
                      ></path>
                    </svg>
                  ) : x?.slug == "managing-teams" ? (
                    <svg
                      width="81"
                      height="81"
                      viewBox="0 0 81 81"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-[81px] h-[81px]"
                      preserveAspectRatio="none"
                    >
                      <circle
                        cx="40.5"
                        cy="40.5"
                        r="40.5"
                        fill="#6166B6"
                        fillOpacity="0.1"
                      ></circle>
                      <path
                        d="M36.4993 41.3332C35.8327 41.3332 34.9993 40.9999 34.9993 40.3332C34.9993 39.6665 35.8327 39.8332 36.4993 38.6665C36.4993 38.6665 39.4993 30.4999 33.4993 30.4999C27.4993 30.4999 30.4993 38.6665 30.4993 38.6665C31.166 39.8332 31.9993 39.6665 31.9993 40.3332C31.9993 40.9999 31.166 41.3332 30.4993 41.3332C29.4993 41.4999 28.666 41.3332 27.666 42.3332V51.8332H35.9993C36.3327 48.9999 37.166 43.1665 37.8327 41.6665L37.9993 41.4999C37.666 41.3332 37.166 41.3332 36.4993 41.3332Z"
                        fill="#6166B6"
                      ></path>
                      <path
                        d="M54.3327 41C53.166 39.6667 52.166 39.8333 50.9993 39.6667C50.166 39.5 49.166 39.3333 49.166 38.5C49.166 37.6667 50.166 38 50.9993 36.5C50.9993 36.5 54.4993 26.6667 47.3327 26.6667C39.9993 26.8333 43.4993 36.6667 43.4993 36.6667C44.3327 38 45.3327 37.8333 45.3327 38.5C45.3327 39.3333 44.3327 39.5 43.4993 39.6667C41.9993 39.8333 40.666 39.6667 39.3327 42.1667C38.666 43.6667 37.666 51.8333 37.666 51.8333H54.3327V41Z"
                        fill="#6166B6"
                      ></path>
                    </svg>
                  ) : x?.slug == "payment-and-billings" ? (
                    <svg
                      width="81"
                      height="81"
                      viewBox="0 0 81 81"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-[81px] h-[81px]"
                      preserveAspectRatio="none"
                    >
                      <circle
                        cx="40.5"
                        cy="40.5"
                        r="40.5"
                        fill="#6166B6"
                        fillOpacity="0.1"
                      ></circle>
                      <path
                        d="M27.666 30.4348V51.3043H54.3327V30.4348H27.666ZM52.666 49.5652H29.3327V40.8696H52.666V49.5652ZM52.666 35.6522H29.3327V32.1739H52.666V35.6522Z"
                        fill="#6166B6"
                      ></path>
                      <path
                        d="M44.334 46.087H49.334V47.8261H44.334V46.087Z"
                        fill="#6166B6"
                      ></path>
                      <path
                        d="M31 46.087H41V47.8261H31V46.087Z"
                        fill="#1C2E45"
                        fillOpacity="0.6"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      width="81"
                      height="81"
                      viewBox="0 0 81 81"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-[81px] h-[81px]"
                      preserveAspectRatio="none"
                    >
                      <circle
                        cx="40.5"
                        cy="40.5"
                        r="40.5"
                        fill="#6166B6"
                        fillOpacity="0.1"
                      ></circle>
                      <path
                        d="M54.3327 41.6667V38.3333L51.4993 37.3333C51.166 36.3333 50.8327 35.3333 50.3327 34.3333L51.666 31.6667L49.3327 29.3333L46.666 30.6667C45.8327 30.1667 44.8327 29.6667 43.666 29.5L42.666 26.6667H39.3327L38.3327 29.5C37.3327 29.8333 36.3327 30.1667 35.4993 30.6667L32.8327 29.3333L30.3327 31.8333L31.666 34.5C31.166 35.3333 30.8327 36.3333 30.4993 37.3333L27.666 38.3333V41.6667L30.4993 42.6667C30.8327 43.6667 31.166 44.6667 31.666 45.6667L30.3327 48.3333L32.666 50.6667L35.3327 49.3333C36.166 49.8333 37.166 50.3333 38.3327 50.5L39.3327 53.3333H42.666L43.666 50.5C44.666 50.1667 45.666 49.8333 46.666 49.3333L49.3327 50.6667L51.666 48.3333L50.3327 45.6667C50.8327 44.8333 51.3327 43.8333 51.4993 42.6667L54.3327 41.6667ZM40.9993 46.6667C37.3327 46.6667 34.3327 43.6667 34.3327 40C34.3327 36.3333 37.3327 33.3333 40.9993 33.3333C44.666 33.3333 47.666 36.3333 47.666 40C47.666 43.6667 44.666 46.6667 40.9993 46.6667Z"
                        fill="#6166B6"
                      ></path>
                      <path
                        d="M45.3333 39.8333C45.3333 42.135 43.4683 44 41.1667 44C38.865 44 37 42.135 37 39.8333C37 37.5317 38.865 35.6667 41.1667 35.6667C43.4683 35.6667 45.3333 37.5317 45.3333 39.8333Z"
                        fill="#6166B6"
                      ></path>
                    </svg>
                  )}

                  <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 ">
                    <p className="flex-grow-0 flex-shrink-0 text-lg text-left text-black">
                      {x?.title}
                    </p>
                    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 ">
                      <p
                        className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#6166B6] cursor-pointer"
                        onClick={() => {
                          supportBySlug(x?._id);
                        }}
                      >
                        Watch videos
                      </p>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M15.0056 11.5909L12.3176 8.5909C12.0056 8.2309 12.0296 7.6549 12.4136 7.3189C12.7736 7.0069 13.3496 7.0309 13.6616 7.4149L17.6696 11.9029C17.9816 12.2629 17.9816 12.7669 17.6696 13.1029L13.6616 17.5909C13.3496 17.9749 12.7736 17.9989 12.4136 17.6629C12.0296 17.3509 12.0056 16.7749 12.3176 16.4149L15.0056 13.3909H6.98956C6.50956 13.3909 6.10156 13.0069 6.10156 12.5029C6.10156 11.9989 6.50956 11.5909 6.98956 11.5909H15.0056Z"
                          fill="#6166B6"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
          <div className="mt-8">
            <Card className="flex justify-center items-center px-[106px] py-10 rounded-md bg-white border border-[#1a3860]/10">
              <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0">
                <svg
                  width="81"
                  height="81"
                  viewBox="0 0 81 81"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-grow-0 flex-shrink-0 w-[81px] h-[81px]"
                  preserveAspectRatio="none"
                >
                  <circle
                    cx="40.5"
                    cy="40.5"
                    r="40.5"
                    fill="#6166B6"
                    fillOpacity="0.1"
                  ></circle>
                  <path
                    d="M42.6667 43.3334H39.3333C39.3333 40 41.3333 39 42.6667 38.3334C43.1667 38.1667 43.5 38 43.8333 37.6667C44 37.5 44.3333 37.1667 44 36.5C43.6667 35.6667 42.6667 34.8334 41.1667 34.8334C38.8333 34.8334 38.5 36.8334 38.3333 37.3334L35 36.8334C35.1667 35 36.6667 31.5 41 31.5C43.6667 31.5 46 33 47 35.1667C47.6667 37 47.3333 38.8334 46 40.1667C45.3333 40.8334 44.6667 41.1667 44 41.3334C43 42 42.6667 41.6667 42.6667 43.3334Z"
                    fill="#6166B6"
                  ></path>
                  <path
                    d="M40.9993 28.3333C47.4993 28.3333 52.666 33.5 52.666 40C52.666 46.5 47.4993 51.6667 40.9993 51.6667C34.4993 51.6667 29.3327 46.5 29.3327 40C29.3327 33.5 34.4993 28.3333 40.9993 28.3333ZM40.9993 26.6667C33.666 26.6667 27.666 32.6667 27.666 40C27.666 47.3333 33.666 53.3333 40.9993 53.3333C48.3327 53.3333 54.3327 47.3333 54.3327 40C54.3327 32.6667 48.3327 26.6667 40.9993 26.6667Z"
                    fill="#6166B6"
                  ></path>
                  <path
                    d="M39.166 45H42.4993V48.3333H39.166V45Z"
                    fill="#6166B6"
                  ></path>
                </svg>
                <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                  <p className="flex-grow-0 flex-shrink-0 text-lg text-left text-black">
                    FAQs
                  </p>
                  <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-[3px]">
                    <Link to="faq">
                      <p className="flex-grow-0 flex-shrink-0 text-base font-light text-left text-[#6166B6]">
                        Browse FAQs
                      </p>
                    </Link>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path
                        d="M15.0056 11.5909L12.3176 8.59088C12.0056 8.23088 12.0296 7.65489 12.4136 7.31889C12.7736 7.00689 13.3496 7.03089 13.6616 7.41489L17.6696 11.9029C17.9816 12.2629 17.9816 12.7669 17.6696 13.1029L13.6616 17.5909C13.3496 17.9749 12.7736 17.9989 12.4136 17.6629C12.0296 17.3509 12.0056 16.7749 12.3176 16.4149L15.0056 13.3909H6.98956C6.50956 13.3909 6.10156 13.0069 6.10156 12.5029C6.10156 11.9989 6.50956 11.5909 6.98956 11.5909H15.0056Z"
                        fill="#6166B6"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="flex justify-start items-center mt-[37px] ">
            <p className="flex-grow-0 flex-shrink-0 text-[22px] text-left mr-6 text-black">
              Didn't find what you were looking for?
            </p>
            <Button
              size="small"
              onClick={() => {
                openTicket();
              }}
            >
              Submit a ticket
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
