/** @format */

import React from "react";
import { Link } from "react-router-dom";

import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import Success from "../../../assets/images/success.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 528,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

// eslint-disable-next-line react/prop-types
export const AwardBidSuccessModal = ({
  open = null,
  name = null,
  amount = null,
  actionType,
  handleShowSuccess,
  unit,
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {actionType == "Award" ? (
          <div className="text-center mb-4">
            <div className="grid justify-items-center">
              <img src={Success} />
            </div>
            <h1 className="text-xl mb-4">Bid Awarded successfully</h1>
            <p className="font-light">
              You have successfully awarded the bid to {name} at {amount}
              USD/{unit}. They will be equally notified of this progress. Go to
              transactions to review and sign the contract.
            </p>
            <div className="flex items-center justify-between mt-8">
              <button
                onClick={() => handleShowSuccess()}
                className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
              >
                Close
              </button>
              <Link to="/miner/interactions/transactions">
                <Button>Go to transactions</Button>
              </Link>
            </div>
          </div>
        ) : (
          <div className="text-center mb-4">
            <div className="grid justify-items-center">
              <img src={Success} />
            </div>
            <h1 className="text-xl mb-4">Bid archived successfully</h1>
            <p className="font-light">
              You have successfully archived this bid.
            </p>
            <div className="grid justify-items-center mt-8">
              <button
                onClick={() => handleShowSuccess()}
                className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
};
