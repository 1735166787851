/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Divider } from "@mui/material";

import CaretDown from "components/ui/icons/CaretDown";
import CaretUp from "components/ui/icons/CaretUp";
import {
  DashboardIcon,
  TeamIcons,
  VendorIcon,
} from "components/ui/icons/permissions/index";
import Role from "./Role";
import {
  AreaSelectIcon,
  BarChartIcon,
  CogIcon,
  DiamondIcon,
  PackageIcon,
  LocationIconWhite,
} from "@ui/icons";
import { useDispatch, useSelector } from "react-redux";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { fetchAdminResources } from "@slices/admin-slice";
import Checkbox from "@mui/material/Checkbox";
function Permissions() {
  const permissions = useSelector((state) => state.admin);
  const [updated_permissions, setState] = useState([]);
  const [toggledInfoSection, setToggledInfoSection] = useState(false);
  const [selected, setSelected] = useState([]);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#6166B6",
      },
    },
  });

  let routes = [
    "Directory",
    "Interaction",
    "Listings",
    "Users",
    "Verification",
    "Payment",
    "Suspension",
    "Carrier",
    "Settings",
    "Mineral",
    "Tariff"
  ];

  let all_permissions = [];

  if (permissions.resources.length > 0) {
    routes.forEach((x) => {
      permissions.resources.forEach((y) => {
        if (y.name === x) {
          all_permissions.push(y);
          selected.push("Read & Write");
        }
      });
    });
  }

  let newArr = [];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAdminResources({ type: "admin" }));
  }, [dispatch]);

  const handleRadioForRestriction = async (value, key, index) => {
    if (key == "Restricted") {
      selected[index] = "Restricted";
      if (updated_permissions?.length === 0) {
        newArr = permissions.resources.map((obj) => {
          if (obj._id === value._id) {
            return {
              ...obj,
              method: {
                view: false,
                edit: false,
                add: false,
                delete: false,
                search: false,
              },
            };
          }
          return obj;
        });
        checkedArr();
      } else {
        newArr = updated_permissions.map((obj) => {
          if (obj._id === value._id) {
            return {
              ...obj,
              method: {
                view: false,
                edit: false,
                add: false,
                delete: false,
                search: false,
              },
            };
          }
          return obj;
        });
        checkedArr();
      }
    } else if (key == "Read Only") {
      selected[index] = "Read Only";
      if (updated_permissions?.length === 0) {
        newArr = permissions.resources.map((obj) => {
          if (obj._id === value._id) {
            return {
              ...obj,
              method: {
                view: true,
                edit: false,
                add: false,
                delete: false,
                search: false,
              },
            };
          }
          return obj;
        });
        checkedArr();
      } else {
        newArr = updated_permissions.map((obj) => {
          if (obj._id === value._id) {
            return {
              ...obj,
              method: {
                view: true,
                edit: false,
                add: false,
                delete: false,
                search: false,
              },
            };
          }
          return obj;
        });
        checkedArr();
      }
    } else {
      selected[index] = "Read & Write";
      if (updated_permissions?.length === 0) {
        newArr = permissions.resources.map((obj) => {
          if (obj._id === value._id) {
            return {
              ...obj,
              method: {
                view: true,
                edit: true,
                add: true,
                delete: true,
                search: true,
              },
            };
          }
          return obj;
        });
        checkedArr();
      } else {
        newArr = updated_permissions.map((obj) => {
          if (obj._id === value._id) {
            return {
              ...obj,
              method: {
                view: true,
                edit: true,
                add: true,
                delete: true,
                search: true,
              },
            };
          }
          return obj;
        });
        checkedArr();
      }
    }
  };

  const checkedArr = () => {
    setState(newArr);
  };

  return (
    <>
      <Role updated_permissions={updated_permissions} />
      <ThemeProvider theme={theme}>
        <div className="bg-white rounded-md">
          <div className="flex justify-between p-6">
            <h1>Permissions</h1>
            <div className="flex text-[14px] text-vendorsecondary">
              <h1>{all_permissions.length} available</h1>
              {/* <h1>{permissions.permissions.length} available</h1> */}
              {/* <h1>5 read Only</h1>
  <h1>5 read & Write</h1> */}
            </div>
          </div>
          <Divider />
          <div className="flex justify-center">
            <div className="w-2/3">
              {all_permissions?.map((header, index) => (
                <>
                  <div className="p-5" key={index + 1}>
                    <div className="flex items-center">
                      {header?.name === "Dashboard" ? (
                        <div className="w-3 mr-4">
                          <DashboardIcon />
                        </div>
                      ) : header?.name === "Vendors" ? (
                        <div className="w-3 mr-4">
                          <VendorIcon />
                        </div>
                      ) : header?.name === "Auction" ? (
                        <div className="w-3 mr-4">
                          <BarChartIcon color="#1C304A" />
                        </div>
                      ) : header?.name === "Interactions" ? (
                        <div className="w-3 mr-4">
                          <AreaSelectIcon color="#1C304A" />
                        </div>
                      ) : header?.name === "Inventory" ? (
                        <div className="w-3 mr-4">
                          <PackageIcon color="#1C304A" />
                        </div>
                      ) : header?.name === "Tracking" ? (
                        <div className="w-3 mr-4">
                          <LocationIconWhite color="#1C304A" />
                        </div>
                      ) : header?.name == "Settings" ? (
                        <div className="w-3 mr-4">
                          <CogIcon color="#1C304A" />
                        </div>
                      ) : header?.name == "Mines" ? (
                        <div className="w-3 mr-4">
                          <DiamondIcon color="#1C304A" />
                        </div>
                      ) : (
                        <div className="w-3 mr-4">
                          <TeamIcons />
                        </div>
                      )}

                      <h1 className="text-[#192434]">{header?.name}</h1>
                    </div>
                    <div className="">
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormControlLabel
                          value="Restricted"
                          style={{ paddingLeft: "20px" }}
                          control={
                            <Radio
                              checked={selected[index] === "Restricted"}
                              color="primary"
                              onChange={(e) => {
                                handleRadioForRestriction(
                                  header,
                                  "Restricted",
                                  index
                                );
                              }}
                            />
                          }
                          label="Restricted"
                        />
                        <FormControlLabel
                          value="Read Only"
                          control={
                            <Radio
                              checked={selected[index] === "Read Only"}
                              color="primary"
                              onChange={(e) => {
                                handleRadioForRestriction(
                                  header,
                                  "Read Only",
                                  index
                                );
                              }}
                            />
                          }
                          label="Read Only"
                        />
                        <FormControlLabel
                          value="Read & Write"
                          control={
                            <Radio
                              checked={selected[index] === "Read & Write"}
                              onChange={(e) => {
                                handleRadioForRestriction(
                                  header,
                                  "Read & Write",
                                  index
                                );
                              }}
                              color="primary"
                            />
                          }
                          label="Read & Write"
                        />
                        <h3
                          className="px-6 py-4 whitespace-nowrap text-sm text-[#6166B6] underline col-start-8 col-end-9 cursor-pointer"
                          onClick={() => setToggledInfoSection(header?.name)}
                        >
                          {toggledInfoSection == header?.name ? (
                            <CaretUp />
                          ) : (
                            <CaretDown />
                          )}
                        </h3>
                      </RadioGroup>
                      {toggledInfoSection == header?.name && (
                        <div className=" w-full">
                          {/* for actions */}
                          {header?.actions?.map((y, index2) => (
                            <div className="pt-5" key={index2 + 1}>
                              <div className="flex items-center">
                                <FormControlLabel
                                  value="{y?.name}"
                                  style={{ paddingLeft: "9px", margin: "0px" }}
                                  control={<Checkbox />}
                                />
                                <h1>{y?.name}</h1>
                              </div>
                              {/* <div className="">
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <FormControlLabel
                            style={{ paddingLeft: "20px" }}
                            value="Restricted"
                            control={
                              <Radio
                                checked={checkPermissionForActionAllowed(y)}
                                color="primary"
                              />
                            }
                            label="Restricted"
                          />

                          <FormControlLabel
                            value="Read Only"
                            control={
                              <Radio
                                checked={checkPermissionForActionNotAllowed(y)}
                                color="primary"
                              />
                            }
                            label="Read Only"
                          />

                          <FormControlLabel
                            value="Read & Write"
                            control={
                              <Radio
                                checked={checkPermissionForActionNotAllowed(y)}
                                color="primary"
                              />
                            }
                            label="Read & Write"
                          />
                          <h3
                            className="px-6 py-4 whitespace-nowrap text-sm text-[#50AAA7] underline col-start-8 col-end-9 cursor-pointer"
                            onClick={() =>
                              setToggledInfoSection((prev) => !prev)
                            }
                          >
                            {toggledInfoSection ? <CaretUp /> : <CaretDown />}
                          </h3>
                        </RadioGroup>
                        {toggledInfoSection && (
                          <div className=" w-full">
                            <FormGroup
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <FormControlLabel
                                style={{ paddingLeft: "20px" }}
                                control={<Checkbox defaultChecked />}
                                label="Activities"
                              />

                              <FormControlLabel
                                style={{ paddingLeft: "20px" }}
                                control={<Checkbox defaultChecked />}
                                label="Analytics"
                              />
                            </FormGroup>
                          </div>
                        )}
                      </div> */}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <hr style={{ margin: "20px", color: "#d9dbde" }} />
                </>
              ))}
            </div>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
}

export default Permissions;
