/* eslint-disable no-unused-vars */
import Button from "components/ui/Button";
import React, { useEffect } from "react";
import { Rating } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVendor, setOffering } from "store/slices/miners/vendor-slice";
import { Link } from "react-router-dom";
import { Divider } from "@mui/material/index";
import ClockIcon from "components/ui/icons/ClockIcon";
import { useNavigate } from "react-router-dom";

function VendorDetails({ setActiveStep }) {
  const params = useParams();
  const { id } = params;
  const { vendor } = useSelector((store) => store.miner_vendors);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleViewOffering = (offering) => {
    dispatch(setOffering(offering));
    setActiveStep(1);
  };

  useEffect(() => {
    dispatch(getVendor(id));
  }, []);

  return (
    <div className="bg-white py-6 rounded-sm">
      {/* <pre>{JSON.stringify(vendor, null, 2)}</pre> */}

      {vendor && (
        <div>
          <div className="mb-4 px-10">
            <h2 className="text-[#192434] text-[22px] mb-2">Profile</h2>
            <div className="flex justify-between">
              <div className="flex">
                <img
                  className="rounded-circle w-[60px] h-[60px] mr-3"
                  src="https://cdn.smehost.net/2020sonymusiccouk-ukprod/wp-content/uploads/2019/10/Miley-Cyrus-03_MidnightSky_WhiteLight_2302.jpg"
                  alt=""
                />
                <div>
                  <h4 className="text-primary">{vendor.vendor.company_name}</h4>
                  <h4 className="text-[14px] text-vendor">
                    {vendor.vendor.industry_name}
                  </h4>
                </div>
              </div>
              <div>
                <Button>
                  {" "}
                  <Link
                    to={`/miner/vendors/find_a_vendor/contactvendor/${vendor.vendor.id}`}
                  >
                    Contact Vendor
                  </Link>
                </Button>
              </div>
            </div>
          </div>
          <Divider />
          <div className="mt-3 mb-7 px-10">
            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Industry:
              </h4>
              <h4 className="text-vendor col-start-2 col-end-4">
                {vendor.vendor.industry_name}
              </h4>
            </div>

            <div className="grid grid-cols-3 mb-8 font-light">
              <h4 className="text-vendorsecondary col-start-1 col-end-2">
                Tags:
              </h4>
              <div className="my-3 flex flex-wrap text-[#6166B6] font-light text-[14px] col-start-2 col-end-4">
                {vendor.vendor.keywords.map((keyword, index) => (
                  <div
                    key={index}
                    className="bg-vendoroptionbg flex justify-between px-2 mr-2 mt-2"
                  >
                    <span className="mr-2">{keyword}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Divider />
          <div className="px-10 my-3">
            <h2 className="text-[#192434] text-[18px] mb-2">Offerings</h2>
            <p className="text vendor font-light  text-[16px]">
              Get stated working immediately with Sirius quickly with these
              offerings
            </p>
          </div>
          {!!vendor.offerings.length &&
            vendor.offerings.map((offering, index) => (
              <div
                key={index}
                className="mx-10 flex justify-between items-start border border-offeringBorder rounded-md px-6 py-6"
              >
                <div>
                  {" "}
                  <h2 className="text-[#192434] text-[18px] mb-2">
                    {offering.job_title}
                  </h2>
                  <p className="text-vendor font-light  text-[16px]">
                    {" "}
                    {offering.description}
                  </p>
                  <div className="flex mt-4">
                    <div className="bg-[#F2F2F2] py-2.5 mr-3 px-5 text-vendorsecondary rounded-3xl">
                      {" "}
                      <span className="font-light">From </span> USD{" "}
                      {offering.price}
                    </div>
                    <div className="flex justify-between items-center px-4 py-2.5 bg-[#F2F2F2] rounded-3xl">
                      {" "}
                      <span className="mr-3">
                        {" "}
                        <ClockIcon />{" "}
                      </span>{" "}
                      <span className="text-vendorsecondary font-light">
                        {offering.duration}
                      </span>
                    </div>
                  </div>
                </div>
                <Button
                  onClick={() => handleViewOffering(offering)}
                  color="neutral"
                >
                  View offering
                </Button>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default VendorDetails;
