/* eslint-disable no-unused-vars */
import { FileUpload } from "pages/onboarding/index";
import PaperClip from "@ui/icons/PaperClip";
import ErrorMessage from "components/ErrorMessage";

const Documentation = ({
  handleFileChange,
  documents,
  setDocuments,
  errors,
  getValues,
  setValue,
}) => {

  const handleDocumentUpload = (file) => handleFileChange(file, "documents");
  const removeFile = (name) => {
    // alert("here");
    let prevDocs = getValues("documents");
    prevDocs = prevDocs.filter((item) => item[0].name !== name);
     setValue("documents", prevDocs);
     // setValue("documents", []);
     setDocuments((prevState) => prevState.filter((file) => file[0].name !== name));
  };

  return (
    <div className="text-default  w-[796px] h-[798px] bg-white px-[34px]">
      <div>
        <p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434]">
          Documentation
        </p>
        <p className="flex-grow-0 flex-shrink-0 w-[796px] text-base font-light text-left text-[#1b2b41]/[0.72]">
          Kindly upload any relevant documents to validate and support
          authenticity.
        </p>
      </div>
      <div className="mt-4">
        <FileUpload source="Documents" handleChange={handleDocumentUpload} />
        <div>
          {documents.map((file) => {
            return (
              <p
                key={file[0].name}
                className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] align-center"
              >
                <span>{<PaperClip />}</span>
                <span>{file[0] ? `${file[0].name}` : "no files uploaded"}</span>
                <button
                  className="text-red text-[16px] px-2"
                  onClick={() => removeFile(file[0].name)}
                >
                  {" "}
                  x{" "}
                </button>
              </p>
            );
          })}
        </div>
      </div>
      <div className="flex ">
        {errors.documents && (
          <ErrorMessage>{errors.documents?.message}</ErrorMessage>
        )}
      </div>
    </div>
  );
};
export default Documentation;
