import React from "react";

const DocumentIcon = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7418 2.22454C11.718 0.200708 8.42229 0.200708 6.40061 2.22454L0.793184 7.82766C0.756661 7.86418 0.737325 7.9136 0.737325 7.96516C0.737325 8.01672 0.756661 8.06614 0.793184 8.10266L1.58596 8.89543C1.62219 8.93151 1.67125 8.95177 1.72238 8.95177C1.77352 8.95177 1.82257 8.93151 1.85881 8.89543L7.46623 3.29231C8.16232 2.59622 9.0883 2.21379 10.0723 2.21379C11.0563 2.21379 11.9822 2.59622 12.6762 3.29231C13.3723 3.9884 13.7547 4.91438 13.7547 5.89622C13.7547 6.8802 13.3723 7.80403 12.6762 8.50012L6.96135 14.2128L6.03537 15.1388C5.16955 16.0046 3.76232 16.0046 2.8965 15.1388C2.47756 14.7198 2.24768 14.1634 2.24768 13.5704C2.24768 12.9775 2.47756 12.421 2.8965 12.0021L8.56623 6.3345C8.71018 6.1927 8.89924 6.11321 9.10119 6.11321H9.10334C9.30529 6.11321 9.49221 6.1927 9.634 6.3345C9.77795 6.47844 9.85529 6.6675 9.85529 6.86946C9.85529 7.06926 9.7758 7.25833 9.634 7.40012L4.99982 12.03C4.9633 12.0665 4.94396 12.1159 4.94396 12.1675C4.94396 12.2191 4.9633 12.2685 4.99982 12.305L5.7926 13.0978C5.82884 13.1339 5.87789 13.1541 5.92902 13.1541C5.98016 13.1541 6.02921 13.1339 6.06545 13.0978L10.6975 8.46575C11.125 8.03821 11.3592 7.47102 11.3592 6.86731C11.3592 6.2636 11.1229 5.69426 10.6975 5.26887C9.81447 4.38586 8.37932 4.38801 7.49631 5.26887L6.94631 5.82102L1.82873 10.9364C1.48139 11.2817 1.20607 11.6926 1.01872 12.1451C0.831366 12.5976 0.73572 13.0828 0.737325 13.5726C0.737325 14.5673 1.12619 15.5019 1.82873 16.2044C2.55705 16.9306 3.51096 17.2937 4.46486 17.2937C5.41877 17.2937 6.37268 16.9306 7.09885 16.2044L13.7418 9.56575C14.7194 8.58606 15.2608 7.28196 15.2608 5.89622C15.2629 4.50832 14.7215 3.20422 13.7418 2.22454Z"
      fill="#1C2E45"
      fillOpacity="0.6"
    />
  </svg>
);
export default DocumentIcon;
