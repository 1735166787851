import { VerifiedIcon } from "@ui/icons";

const Complete = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <VerifiedIcon />
      <div className="text-center mt-9">
        <h3 className="text-heading text-2xl">Select Vendor.</h3>
        <span className="text-ts font-light">
          You are about to select this vendor for the project. Kindly confirm
          this action.
        </span>
      </div>
    </div>
  );
};

export default Complete;
