/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { createContext, useState } from "react";

export const PaginationContext = createContext({
  currentItems: null,
  itemsPerPage: 10,
  setCurrentItems: (slicedItems) => {},
  setItemsPerPage: (option) => {},
});

const PaginationProvider = ({ children }) => {
  const [currentItems, setCurrentItems] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(4);

  const values = {
    currentItems,
    itemsPerPage,
    setCurrentItems,
    setItemsPerPage,
  };
  return (
    <PaginationContext.Provider value={values}>
      {children}
    </PaginationContext.Provider>
  );
};
export default PaginationProvider;
