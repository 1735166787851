/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

import { Modal, Box } from '@mui/material';
import { Button } from '@ui';
import Success from 'assets/images/success.svg';
import { Input } from '@atoms/input';
import { CopyIcon } from 'components/atoms/icons/index';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 739,
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: 3
};

export const AcceptProspectInterestSuccessModal = ({
	open,
	setOpenSubmitModal,
	password
}) => {
	const { prospectInterestDetail } = useSelector((store) => store?.offtakers);

	const copyToClipboard = () => {
		navigator.clipboard.writeText(password);
		toast.success('Password copied to clipboard');
	};
	return (
		<Modal
			open={open}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<div className="text-center mb-4 py-10 px-16">
					<div className="grid justify-items-center">
						<img src={Success} />
					</div>
					<h1 className="text-[22px] text-head my-4">Submission successful</h1>
					<p className="font-light text-vendorsecondary">
						You have accepted{' '}
						<span className="text-primary">{`${prospectInterestDetail?.account?.name}'s`}</span>{' '}
						interest in your investment proposal. You will be contacted by the
						party involved to proceed. Please, only respond to contacts that
						provide the unique password you created.
					</p>
					<div className="flex items-center gap-x-4 mt-8">
						<Input
							placeholder="* * * * * * * *"
							type="password"
							value={password}
						/>
						<button onClick={() => copyToClipboard()}>
							<CopyIcon />
						</button>
					</div>
					<div className="flex items-center justify-center mt-8">
						<Link to="/off-taker/invest/prospects">
							<Button onClick={() => setOpenSubmitModal(false)}>
								Continue
							</Button>
						</Link>
					</div>
				</div>
			</Box>
		</Modal>
	);
};
