/* eslint-disable no-unused-vars */
import React ,{ useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ControlledModal,AdminMinesTable } from "@ui";
import { AddIcon } from "@ui/icons";
import { checkPermission } from "shared/utility";
import {
  fetchAdminMines,
  fetchPaginatedAdminMines,
  suspendAdminMine,
} from "@slices/admin-slice";
import { useDispatch, useSelector } from "react-redux";
import Map from "./map";

import { adminmineTableInfo } from "constants/index";

const Listings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [mineID, setMineID] = useState();
  const [filterParams, setFilter] = useState("");
  const [searchParams, setParams] = useState();
  const { loading, mines, pagination, search } = useSelector(
    (state) => state.admin
  );

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    setFilter(tab);
    if (tab == "All") {
      dispatch(fetchAdminMines());
    } else {
      dispatch(
        fetchPaginatedAdminMines({
          page: 1,
          per_page: pagination.limit,
          search: "",
          filter: tab,
        })
      );
    }
  };

  const handleSearch = (e) => {
    if (!e.target.value) {
      dispatch(fetchAdminMines());
    }
    setParams(e.target.value);
  };

  const handleClose = () => setOpen(false);

  const suspendMine = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(suspendAdminMine(mineID));
      if (response.payload.status === 200) {
        setOpen(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log("error from then", response);
      }
    } catch (error) {
       console.log("error", error);
    }
  };

  const actions = {
    toggle: {
      fields: ["View Details", "Edit Mine", "Suspend"],
      action: (option, id, status) => {
        if (option === "View Details") {
          navigate("../mine-details", {
            state: { id: id, status: status.status },
          });
        } else if (option === "Edit Mine") {
          navigate("../edit-mine", {
            state: { id: id, status: status.status },
          });
        } else {
          setOpen(true);
          setMineID(id);
        }
      },
    },
    delete: (sub) => {
      // dispatch(setToggledOfferingSub(null));
      // dispatch(setDeletedOfferingSub(sub));
      // setOpen(true);
    },
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(fetchAdminMines());
    }
    dispatch(
      fetchPaginatedAdminMines({
        page: 1,
        per_page: pagination.limit,
        search: searchParams,
        filter: filterParams,
      })
    );
  };

  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedAdminMines({
        page: pagination[option + "Page"],
        per_page: pagination.limit,
        search: search,
        filter: "",
      })
    );
  };

  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedAdminMines({
        page: 1,
        per_page: e.target.value,
        search: search,
        filter: "",
      })
    );
  };

  useEffect(() => {
    dispatch(fetchAdminMines());
  }, [dispatch]);

  return (
    <>
      <ControlledModal
        isOpen={open}
        handleClose={handleClose}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
      >
        <p className="text-head text-center font-semibold text-[22px]">
          Suspend Mine?
        </p>
        <p className="text-lightgray text-center font-light text-base mt-2">
          Are you sure you want to Suspend this mine?
        </p>
        <div className="flex flex-row justify-center gap-4 mt-6">
          <Button
            type="button"
            variant="text"
            color="neutral"
            onClick={() => setOpen(!open)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            disabled={checkPermission("Listings")}
            variant="contained"
            color="danger"
            onClick={() => {
              suspendMine();
            }}
          >
            {isLoading ? "Processing..." : "Yes, suspend"}
          </Button>
        </div>
      </ControlledModal>
      <div className="mt-2">
        <div className="w-40">
          <Button
            onClick={() => navigate("../add")}
            variant="contained"
            disabled={checkPermission("Listings")}
            color="primary"
            startIcon={<AddIcon />}
            fullWidth
          >
            Add new mine
          </Button>
        </div>
        {/* maps goes here */}
        {mines && mines.length > 0 && (
          <div className="w-full mt-3">
            <div className="h-96 my-4 ">
              <Map
                location={{
                  address: mines[0].location.address,
                  lat: mines[0].location.latitude,
                  lng: mines[0].location.longitude,
                }}
                zoomLevel={1}
                mines={mines}
              />
            </div>
          </div>
        )}
        {/* maps end here */}
        <div className="mt-4 flex overflow-x-auto scrollbar-hide gap-3">
          <div className="min-w-[1024px] lg:w-full overflow-x-hidden ">
            <AdminMinesTable
              tableInfo={adminmineTableInfo}
              handleTabChange={handleTabChange}
              handleSetPerPage={handleSetPerPage}
              handleChangePage={handleChangePage}
              handleSearch={handleSearch}
              handleSearchSubmit={handleSearchSubmit}
              data={mines}
              pagination={pagination}
              loading={loading}
              isDelete={true}
              isToggle={true}
              actions={actions}
            />
          </div>
        </div>

        {/* <div className="mt-4 flex overflow-x-auto scrollbar-hide gap-3">
      <div className="flex gap-2">
        <div className="w-[250px] lg:w-[548px]">
          <Search placeholder="Search database" />
        </div>
        <div className="bg-primary flex items-center justify-center rounded py-1 px-2 h-[43px]">
          <SearchIcon color="#ffff" opacity="1" />
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <div>
          <FilterIcon />
        </div>
        
      </div>
    </div> */}
        {/* <ListingTabs /> */}
      </div>
    </>
  );
};

export default Listings;
