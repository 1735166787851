const Dashboard = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 14.6C20 10.2 16.4 6.5 12 6.5C7.6 6.5 4 10.2 4 14.6C4 16 4.3 17.5 4.9 18.5H9.8C10.3 19.1 11.1 19.5 12 19.5C12.9 19.5 13.7 19.1 14.2 18.5H19.1C19.7 17.5 20 16 20 14.6ZM18 11.5V12.5L13.9 16C13.9 16.1 14 16.3 14 16.5C14 17.6 13.1 18.5 12 18.5C10.9 18.5 10 17.6 10 16.5C10 15.4 10.9 14.5 12 14.5C12.3 14.5 12.6 14.6 12.8 14.7L17 11.5H18ZM14 8.5H15V9.5H14V8.5ZM9 8.5H10V9.5H9V8.5ZM6 16.5H5V15.5H6V16.5ZM7 12.5H6V11.5H7V12.5ZM19 16.5H18V15.5H19V16.5Z"
        fill="white"
      />
      <path
        d="M13 16.5C13 17.052 12.552 17.5 12 17.5C11.448 17.5 11 17.052 11 16.5C11 15.948 11.448 15.5 12 15.5C12.552 15.5 13 15.948 13 16.5Z"
        fill="white"
      />
    </svg>
  );
};

export default Dashboard;
