/* eslint-disable react/prop-types */
const PackageIcon = ({ color, opacity, h, w }) => {
  const iconColor = color ? color : "#D9E8FC";
  const fillOpacity = opacity ? opacity : "0.7";
  const height = h ? h : "25";
  const width = w ? w : "24";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4.5L4 6.5V16.5L12 20.5L20 16.5V6.5L12 4.5ZM12 5.5L14.1 6L8.2 7.9L5.9 7.1L12 5.5ZM12 19.4L5 15.9V7.8L8 8.8V12.2L9 12.5V9.2L12 10.2V19.4ZM12.5 9.3L9.8 8.4L16 6.5L18.4 7.1L12.5 9.3Z"
        fill={iconColor}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export default PackageIcon;
