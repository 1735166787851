/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button, ControlledModal, TextArea } from "@ui";
import { priceSplitter } from "shared/utility";
import { useDispatch, useSelector } from "react-redux";
import { getInitials } from "@shared/utility";
import { fetchAdminOfferByID } from "@slices/admin-slice";
import { useLocation } from "react-router-dom";
import SkeletonComponent from "components/investors/Skeleton";

const OfferingDetails = () => {
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const handleOpenFeedback = () => {
    setOpened(true);
    setOpen(false);
  };

  useEffect(() => {
    dispatch(fetchAdminOfferByID({ id: location?.state?._id }));
  }, [dispatch, location?.state?._id]);

  const { loading, offer } = useSelector((state) => state.admin);
  
  const handleClosed = () => setOpened(false);

  const handleClose = () => setOpen(false);
  return (
    <>
      <ControlledModal
        isOpen={open}
        handleClose={handleClose}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
      >
        <p className="text-head text-center font-semibold text-[22px]">
          Archive Auction?
        </p>
        <p className="text-lightgray text-center font-light text-base mt-2">
          Are you sure you want to archive this investment offer?
        </p>
        <div className="flex flex-row justify-center gap-4 mt-6">
          <Button type="button" variant="text" color="neutral">
            Cancel
          </Button>
          <Button
            type="button"
            variant="contained"
            color="danger"
            onClick={handleOpenFeedback}
          >
            Yes, archive
          </Button>
        </div>
      </ControlledModal>
      <ControlledModal
        isOpen={opened}
        handleClose={handleClosed}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
      >
        <div className="flex flex-col justify-center gap-4 mt-6">
          <p className="text-head text-center font-semibold text-[22px]">
            Feedback to user
          </p>
          <p className="text-lightgray text-center font-light text-base mt-2">
            Kindly provide additional notes to explain to user the reason for
            archiving the investment offer.
          </p>
          <TextArea />
          <div className="flex justify-end ">
            <Button type="button" variant="contained" color="primary">
              Send
            </Button>
          </div>
        </div>
      </ControlledModal>
      <>
        {loading ? (
          <SkeletonComponent />
        ) : (
          <div className="text-default  bg-white px-[34px] py-[54px]">
            <div className="flex justify-between h-[42px] mb-6">
              <p className="text-[22px] font-normal">Investment details</p>
              {/* <Button onClick={handleOpen}>Archive</Button> */}
            </div>
            <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 w-[206px] h-[66px]">
              <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
                {offer?.product_image?.length > 0 ? (
                  <img
                    className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                    src={`${offer?.product_image[0]?.url}`}
                    alt=""
                  />
                ) : (
                  `${getInitials(offer?.profile?.name)}`
                )}
              </div>
              <div className="flex flex-col justify-start items-start flex-grow-0 ml-4 flex-shrink-0 ">
                <p className="flex-grow-0 flex-shrink-0 mb-1 text-base text-left text-primary">
                  {offer?.profile?.name}
                </p>
                <p className="flex-grow-0 flex-shrink-0 mb-1 text-sm text-left text-[#18273a]/[0.94]">
                  {offer?.mine?.company_name}
                </p>
                <p className="flex-grow-0 flex-shrink-0 text-sm font-light text-left text-[#1b2b41]/[0.72]">
                  {offer?.mine?.country?.name}
                </p>
              </div>
            </div>

            <div className="flex flex-col justify-start items-start flex-grow-0 mt-6  flex-shrink-0">
              <div className="flex flex-row justify-between mb-[15px]">
                <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
                  Mineral:
                </p>
                <p className="text-base font-light text-left text-[#18273a]/[0.94]">
                  {offer?.mineral?.name}
                </p>
              </div>
              <div className="flex flex-row justify-between mb-[15px]">
                <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
                  Phase to fund:
                </p>
                <p className="text-base font-light text-left text-[#18273a]/[0.94]">
                  {offer?.production_phase?.slug}
                </p>
              </div>

              <div className="flex flex-row justify-between mb-[15px]">
                <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
                  Investment type:
                </p>
                <p className="text-base font-light text-left text-[#18273a]/[0.94]">
                  {offer?.investment_type?.slug}
                </p>
              </div>
              <div className="flex flex-row justify-between mb-[15px]">
                <p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
                  Investment amount:
                </p>
                <p className="text-base font-light text-left text-[#18273a]/[0.94]">
                  {`${priceSplitter(offer?.amount)} USD`}
                </p>
              </div>

              <div className="flex flex-row justify-between mb-[15px]">
                <p className="min-w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
                  Proposal:
                </p>
                <p className="text-base font-light text-left text-[#18273a]/[0.94]">
                  {offer?.proposal}
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default OfferingDetails;
