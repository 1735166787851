/* eslint-disable no-unused-vars */
import Button from "components/ui/Button";
import React, { useEffect, useState } from "react";
import { Rating } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  applyToServiceRequest,
  getServiceRequest,
} from "store/slices/vendors/service_request-slice";
import { Link, useNavigate } from "react-router-dom";
import Complete from "./Complete";

import { priceSplitter,checkPermission } from "shared/utility";
import dayjs from "dayjs";
import { getInitials } from "@shared/utility";

function RequestDetails() {
  const params = useParams();
  const { id } = params;
  const { request, isSuccess, isError, isLoading } = useSelector(
    (store) => store.vendorServiceRequests
  );
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleServiceRequestApplication = async (id) => {
   const request = await dispatch(applyToServiceRequest(id));
   if(request.payload.status == 201) {
    if(request.payload.data.code == 422 || request.payload.data.code == 400) {
      toast.error(request.payload.data.message);
    }else {
      toast.success(request.payload.data.message);
      navigate("/vendor/dashboard");
    }
   }
   
  };

 

  useEffect(() => {
    dispatch(getServiceRequest(id));
  }, []);

  return (
    <div>
      <div className="bg-white p-6 rounded-sm">
        {/* <pre>{JSON.stringify(vendor, null, 2)}</pre> */}
        {request && (
          <div>
            <div>
              <div className="flex justify-between">
                <div className="flex items-center">
                  <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill mr-3">
                    {request[0]?.user_image !== null ? (
                      <img
                        className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                        src={`${request[0]?.user_image?.url}`}
                        alt=""
                      />
                    ) : (
                      `${getInitials(request[0]?.account?.name)}`
                    )}
                  </div>
                  <div>
                    <h4 className="text-primary">
                      {request[0]?.account.name}
                    </h4>
                    {/* <h4 className="text-[14px] text-vendor">
                      {request.industry_name}
                    </h4> */}
                  </div>
                </div>
                <div>
                  <Button
                    onClick={() =>
                      handleServiceRequestApplication(request[0]?._id)
                    }
                    disabled={checkPermission("Servicerequest")}
                    loading={isLoading}
                  >
                    Apply to request
                  </Button>
                </div>
              </div>
            </div>
            <div className="mt-3 mb-7">
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Job title:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {request[0].title}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project type:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {request[0].type}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Category:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {request[0].category.name}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project description:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {request[0].description}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project duration:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {request[0].duration}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Country:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {request[0].country.name}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Budget:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {priceSplitter(request[0].budget) + " USD"}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project start date:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {dayjs(request[0].start_date).format("DD/MM/YYYY")}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Additional notes:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {request[0]?.additional_notes}
                </h4>
              </div>
            </div>
          </div>
        )}
      </div>
      {isOpen && (
        <div className="top-0 bg-black bg-opacity-20 absolute h-full w-full"></div>
      )}
      {isOpen && isSuccess && (
        <div className="absolute top-44 w-full flex justify-center">
          <div className="bg-white rounded-lg py-8 w-1/2 flex justify-center ">
            <div className="w-3/4">
              <Complete />
              <div className="flex justify-center gap-8 mt-8">
                <Button
                  onClick={() => navigate("/vendor/requests")}
                  variant="contained"
                >
                  Done
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RequestDetails;
