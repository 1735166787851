/* eslint-disable no-unused-vars */
import React from 'react';
import { Btn as Button } from '@atoms/button/button';
import ProductCardImage from '@assets/images/ProductCardImage.png';

const InterestDetails = () => {

	return (
		<>
			<div className="text-default w-full bg-white px-[34px] p-[30px]">
				<div className="flex justify-between items-start">
					<div className="flex justify-start items-start">
						<div className="w-[331px] h-[217px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
							<img
								src={ProductCardImage}
								alt="Product"
								className="w-full h-full"
							/>
						</div>
						<div className="flex flex-col justify-start items-start flex-grow-0 ml-4 flex-shrink-0 ">
							<h3 className="mb-1 text-2xl">Zinc (Zn) Concentrate</h3>
							<span className="mb-1 text-lg text-primary">Zinc</span>
							<span className="mb-1 text-base text-primary font-light text-lightgray/[0.72]">
								Bingham Canyon Mine
							</span>
						</div>
					</div>

					<Button
						// onClick={() =>
						// 	navigate(
						// 		`/off-taker/interactions/investments/${id}/manage_interests`
						// 	)
						// }

						size="small"
					>
						Accept proposal
					</Button>
				</div>

				<div className="flex flex-col justify-start items-start flex-grow-0 mt-6  flex-shrink-0">
					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Quantity:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94]">
							5000 dmT
						</p>
					</div>
					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Company name:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94]">
							Rio Tonto Group
						</p>
					</div>
					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Address:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94]">
							12732 Bacchus Highway, Herriman, Utah
						</p>
					</div>
					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Additional information:
						</p>
						<p className="flex-1 text-base font-light text-left text-[#18273a]/[0.94]">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. A ut
							urna, non morbi convallis nec, et phasellus et. Cras a sed enim in
							aliquet nulla quis adipiscing imperdiet. In lectus in aliquam
							mauris ultricies. Cum et nunc porttitor sed sit.
						</p>
					</div>

					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Documentation:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94]">
							12732 Bacchus Highway, Herriman, Utah
						</p>
					</div>

					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Quantity:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94]">
							5000 dmT
						</p>
					</div>

					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Price:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94]">
							USD 200 /dmT
						</p>
					</div>

					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Production phase to fund:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94]">
							Exploration
						</p>
					</div>

					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Investment type:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94]">
							private equity
						</p>
					</div>

					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Investment duration:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94]">
							2 years
						</p>
					</div>

					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Investment amount:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94]">
							USD 2 000 000
						</p>
					</div>

					<div className="flex flex-row justify-between mb-[15px]">
						<p className="w-[174px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
							Status:
						</p>
						<p className="text-base font-light text-left text-[#18273a]/[0.94]">
							Ongoing
						</p>
					</div>
				</div>
			</div>
			<div className="w-full bg-white px-[34px] p-[30px] mt-3">
				<h3 className="text-2xl mb-4">Proposal:</h3>
				<p className="text-base font-light text-[#18273a]/[0.94]">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi quis non
					viverra elit sed etiam integer etiam. Pharetra massa vitae gravida
					lacus, rutrum tempor sed quam. At quam luctus blandit fames. Nisl, eu
					amet sed iaculis sagittis pretium neque integer tincidunt. Molestie
					rutrum pulvinar pharetra nunc, ac. Morbi platea magnis nulla etiam
					pretium sed placerat tempus. Ultrices aenean diam varius donec quam
					mattis quis pulvinar egestas. Odio enim, facilisis ac tristique sem
					risus lacus. <br />
					Facilisi tellus sit ut amet non. Nulla quis commodo tincidunt sapien
					elit donec. Amet urna feugiat at aenean nisl placerat lorem. Fusce
					euismod tristique at nec. Nibh facilisi varius cursus mauris. Felis
					vel enim vel amet quis. Feugiat cursus dictumst suspendisse non.
					Lectus accumsan nibh quis vestibulum nunc montes, ut diam. Sit lacus
					fames sed tempor ullamcorper bibendum nisl, nunc. Nulla elit
					pellentesque lectus non sed diam suspendisse lectus suspendisse.
				</p>
			</div>
		</>
	);
};

export default InterestDetails;
