/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button } from "@atoms/button";
import { useForm } from "react-hook-form";
import { Formik } from "formik";
import { yupResolver } from "@hookform/resolvers/yup";
import { get_mineral_data } from "@slices/onboarding/miner.slice";
import { getServiceOptions } from "store/slices/miners/vendor-slice";
import { submit_offtaker_demand } from "store/slices/offtakers/demands-slice";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { SuccessResponse } from "@config/status-code";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "./demands.css";
import * as yup from "yup";
import SearchLocationInput from "pages/onboarding/select-category/shared/SearchLocationInput";
import { Modal, Box } from "@mui/material";
import Success from "assets/images/success.svg";
import { Select, Input } from "@ui";
import ErrorMessage from "components/ErrorMessage";

const schema = yup.object().shape({
  mineral: yup.string().required("Select mineral type"),
  contract: yup.string().required("Select contract type"),
  delivery_period: yup.string().required("Specify delivery period"),
  price: yup.string().required("Price is required"),
  shipping_type: yup.string().required("Select shipping type"),
  unit: yup.string().required("Select unit"),
  address: yup.string().required("Port name is required"),
  postal: yup.string().required().typeError("Postal code is required"),
  agree: yup.bool().required().oneOf([true], "Accept terms and conditions"),
  country: yup.string().required("Country name is required"),
  city: yup.string().required("City name is required"),
  state: yup.string().required("State name is required"),
});

const loadScript = (url) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
      }
    };
  }
  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const Demands = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [delivery_period, setDeliveryPeriod] = useState("");
  const { minerals } = useSelector((state) => state.miner_verification);
  const { measurements, shippings } = useSelector(
    (store) => store.miner_vendors
  );
  const [measurement, setMeasurement] = useState("dmt");

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [openSubmitModal, setOpenSubmitModal] = useState(false);

  useEffect(() => {
    dispatch(get_mineral_data());
    dispatch(getServiceOptions());
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const formatDate = (date_val) => {
    const start_date = new Date(date_val);
    const day =
      start_date.getDate() < 10
        ? `0${start_date.getDate()}`
        : start_date.getDate();
    const month =
      start_date.getMonth() + 1 < 10
        ? `0${start_date.getMonth() + 1}`
        : start_date.getMonth() + 1;
    return `${day}-${month}-${start_date.getFullYear()}`;
  };

  const handleDateRangeSelected = (date_val) => {
    const time_range =
      formatDate(date_val[0]) + " ~ " + formatDate(date_val[1]);

    setDateRange(date_val);
    setDeliveryPeriod(time_range);
    setValue("delivery_period", time_range, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onSubmit = async (data) => {
    const formRequest = {
      mineral: data.mineral,
      contract_type: data.contract,
      delivery_period: data.delivery_period,
      unit_of_measurement: data.unit,
      shipping_type: data.shipping_type,
      price: data.price,
      location: {
        longitude: data.long,
        latitude: data.lat,
        address: data.address,
        postal_code: `${data.postal}`,
      },
      country: data.country,
      state: data.state,
      city: data.city,
      terms: data.agree,
    };

    dispatch(submit_offtaker_demand(formRequest))
      .then((res) => {
        if (res.payload.status && SuccessResponse(res.payload.status)) {
          setOpenSubmitModal(true);
        }
      })
      .catch((err) => {
        return toast.error(err.message);
      });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 739,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
  };

  const handleSelectChange = (e, { name }) => {
    setValue(name, e.slug, {
      shouldValidate: true,
      shouldDirty: true,
    });
    if (name == "unit") {
      setMeasurement(e.name);
    }
  };

  return (
    <div className="text-default  w-[1125px] bg-white px-[164px] pt-[40px]">
      <p className="flex-grow-0 flex-shrink-0 text-[22px] mb-2 text-left text-[#192434]">
        Make a request
      </p>
      <p className="flex-grow-0 flex-shrink-0 w-[796px] text-base font-light text-left text-[#1b2b41]/[0.72]">
        You can make a requesition for a mineral you want or that is probably
        not listed available in auctions.
      </p>
      <Formik
        // initialValues={initialValues}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
      >
        {({ handleChange, isSubmitting }) => (
          <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4">
              <p className="flex-grow-0 flex-shrink-0 text-[18px] mb-2 text-left text-[#192434]">
                Product details
              </p>
              <Select
                name="mineral"
                placeholder="Select mineral"
                label="Mineral type"
                options={minerals}
                onChange={handleSelectChange}
              />
              {errors.mineral && (
                <ErrorMessage>{errors.mineral?.message}</ErrorMessage>
              )}
            </div>

            <div className="mt-8">
              <p className="flex-grow-0 flex-shrink-0 text-[18px] mb-2 text-left text-[#192434]">
                Contract details
              </p>
              <Select
                name="contract"
                placeholder="Select contract type"
                label="Contract type"
                options={[
                  { name: "One-time contract", slug: "One-time contract" },
                  { name: "Revolving contract", slug: "Revolving contract" },
                ]}
                onChange={handleSelectChange}
              />
              {errors.contract && (
                <ErrorMessage>{errors.contract?.message}</ErrorMessage>
              )}
            </div>

            <div className="mt-4 ">
              <label
                className="block text-xs mb-2  text-lightgray"
                htmlFor="delivery"
              >
                Delivery Period
              </label>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                minDate={new Date()}
                onChange={(update) => {
                  handleDateRangeSelected(update);
                }}
                isClearable={true}
              />
              {errors.delivery_period && (
                <ErrorMessage>{errors.delivery_period?.message}</ErrorMessage>
              )}
            </div>

            <div className="mt-8">
              <p className="flex-grow-0 flex-shrink-0 text-[18px] text-left text-[#192434]">
                Pricing details
              </p>
              <div className="grid grid-cols-2  gap-x-4">
                <div>
                  <Select
                    name="unit"
                    placeholder="Select Unit of Measurement"
                    label="Unit of measurement"
                    options={
                      measurements
                        ? Object.keys(measurements).map((key) => ({
                            name: measurements[key],
                            slug: key,
                            // eslint-disable-next-line no-mixed-spaces-and-tabs
                          }))
                        : []
                    }
                    onChange={handleSelectChange}
                  />
                  {errors.unit && (
                    <ErrorMessage>{errors.unit?.message}</ErrorMessage>
                  )}
                </div>

                <div className="">
                  <div className="flex flex-row relative ">
                    <Input
                      isMoneyInput
                      id="price"
                      label="Price"
                      type="number"
                      name="price"
                      {...register("price")}
                    />
                    <span className="absolute right-0 border-none appearance-none rounded-r cursor-text text-input py-2 px-2 text-left text-sm">
                      /{measurement}
                    </span>
                  </div>
                  {errors.price && (
                    <ErrorMessage>{errors.price?.message}</ErrorMessage>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-4">
              <p className="flex-grow-0 flex-shrink-0 text-[18px] mb-2 text-left text-[#192434]">
                Shipping Information
              </p>
              <Select
                name="shipping_type"
                placeholder="Select shipping type"
                label="Preferred shipping type"
                options={
                  shippings
                    ? Object.keys(shippings).map((key) => ({
                        name: shippings[key],
                        slug: key,
                        // eslint-disable-next-line no-mixed-spaces-and-tabs
                      }))
                    : []
                }
                onChange={handleSelectChange}
              />
              {errors.shipping_type && (
                <ErrorMessage>{errors.shipping_type?.message}</ErrorMessage>
              )}
            </div>

            <div className="mt-4">
              <label className="block text-xs mb-2  text-lightgray">
                Delivery port address
              </label>

              <SearchLocationInput
                setValue={setValue}
                id="port"
                name="port"
                {...register("port")}
              />
              <p className="h-2 paragraph text-primary text-xs mt-2">
                {errors.port?.message}
              </p>
              <Input
                id="long"
                type="hidden"
                name="long"
                {...register("long")}
              />
              <Input id="lat" type="hidden" name="lat" {...register("lat")} />
            </div>

            <div className="grid grid-cols-2 gap-x-4 -mt-16">
              <div className="">
                <Input
                  label="Country"
                  id="country"
                  name="country"
                  type="text"
                  {...register("country")}
                />
              </div>

              <div className="">
                <Input
                  label="Postal code"
                  id="postal"
                  type="text"
                  name="postal"
                  {...register("postal")}
                />
                {errors.postal && (
                  <ErrorMessage>{errors.postal?.message}</ErrorMessage>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-x-4">
              <div className="">
                <Input
                  label="State"
                  id="state"
                  name="state"
                  type="text"
                  {...register("state")}
                />
              </div>

              <div className=" ">
                <Input
                  label="City"
                  id="city"
                  name="city"
                  type="text"
                  {...register("city")}
                />
              </div>
            </div>

            <div className="text-checkbox flex flex-col   text-sm my-4">
              <div>
                <input
                  className="appearance-none checked:bg-primary checked:hover:bg-primary focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary rounded border-none h-5 w-5 mr-4 bg-check cursor-pointer accent-primary bg-primary"
                  type="checkbox"
                  id="agree"
                  name="agree"
                  {...register("agree")}
                />
                <label className="text-checkbox" htmlFor="agree">
                  I agree to the{" "}
                  <span className="text-primary cursor-pointer">
                    Terms and Conditions
                  </span>{" "}
                </label>
              </div>
              {errors.agree && (
                <ErrorMessage>{errors.agree?.message}</ErrorMessage>
              )}
            </div>

            <div className="mt-8 flex justify-between">
              <button
                onClick={() => navigate("/off-taker/demands")}
                className="bg-dark-gray-300 text-primary w-[fit-content] flex justify-center py-2 mb-3 mt-4 px-4 rounded"
              >
                Cancel
              </button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                loading={isSubmitting}
                // disabled={!isDirty || !isValid}
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
      <Modal
        open={openSubmitModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center mb-4 py-10 px-16">
            <div className="grid justify-items-center">
              <img src={Success} />
            </div>
            <h1 className="text-[22px] text-head my-4">
              Request Published successfully
            </h1>
            <p className="font-light text-vendorsecondary">
              {
                "Your request for a mineral has been published. You will be notified when someone confirms \
  it's availability.In the mean time, you can check for other minerals that are available for auction"
              }
            </p>
            <div className="grid grid-cols-2 gap-2 justify-center">
              <div>
                <Link to="/off-taker/demands/new">
                  <Button onClick={() => setOpenSubmitModal(false)}>
                    Back
                  </Button>
                </Link>
              </div>
              <div>
                <Link to="/off-taker/demands">
                  <Button onClick={() => setOpenSubmitModal(false)}>
                    Go to all demands
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Demands;
