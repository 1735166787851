/* eslint-disable react/no-unescaped-entities */
const Terms = () => {
	return (
		<div className="text-sm text-gray">
			<p className="text-sm text-gray">
				EFFECTIVE DATE: AUGUST 1, 2022 <br />
				For Customers using daniolacorp.com (the "Platform") the following
				"Terms of Service" govern your use of the Platform and the products and
				services made available by Daniola via the Platform and otherwise (the
				"Services" as more particularly defined below). These Terms of Service
				are a legal agreement and contract between you ("you", "your", or
				"Customer") and Daniola, and incorporates the Privacy Policy at
				https://daniolacorp.com/privacypolicy. <br />
				<b>
					BY REGISTERING FOR USE OF THE SERVICES, BY BEING ONBOARDED, OR BY
					EXECUTING THESE TERMS OF SERVICE BY DOCUSIGN OR BY CLICKING "ACCEPT",
					OR BY ACCESSING OR USING THE PLATFORM AND/OR THE SERVICES, CUSTOMER
					ENTERS INTO A BINDING LEGAL CONTRACT AND AGREEMENT WITH DANIOLA, AND
					CONFIRMS THAT CUSTOMER ACCEPTS AND AGREES TO ALL THE TERMS AND
					CONDITIONS OF THESE TERMS OF SERVICE. CUSTOMER ACKNOWLEDGES AND
					CONFIRMS (A) CUSTOMER HAS READ AND UNDERSTANDS THESE TERMS OF SERVICE;
					(B) CUSTOMER REPRESENTS AND WARRANTS THAT CUSTOMER HAS THE LEGAL
					CAPACITY, RIGHT, POWER, AND AUTHORITY TO ENTER INTO A BINDING LEGAL
					CONTRACT WITH DANIOLA AND ACCEPT AND AGREE TO THESE TERMS OF SERVICE;
					(C) WHERE CUSTOMER ACCEPTS THESE TERMS OF SERVICE FOR AN ORGANIZATION,
					CUSTOMER HAS LEGAL AUTHORITY TO CONTRACTUALLY BIND THAT ORGANIZATION;
					AND (D) CUSTOMER AND ANY ORGANIZATION THAT CUSTOMER CONTRACTS ON
					BEHALF OF ACCEPT THESE TERMS OF SERVICE, AND AGREE TO BE LEGALLY BOUND
					BY ALL ITS TERMS AND CONDITIONS.
				</b>
				<br />
			</p>
			<div className="">
				Definitions In these Terms of Service, the following terms shall have
				the respective meanings indicated below: "Acceptable Use Policy" has the
				meaning stated in Section 10. "Aggregated Statistics" has the meaning
				set forth in Section 21. "Applicable Laws" means any and all (i) laws,
				statutes, rules, regulations, by laws, codes, treaties, constitutions
				and ordinances, (ii) orders, directives, judgments, decisions, decided
				cases and precedents, common law rules or laws, decrees, awards or writs
				of any court (including a court of equity), arbitrator or arbitration
				panel, or any Governmental Authority or other body exercising
				adjudicative, regulatory, judicial or quasi-judicial powers, including
				any stock exchange, and (iii) policies, guidelines, standards,
				requirements, notices and protocols of any Governmental Authority which
				are applicable to or govern Customer, Daniola or the transactions
				contemplated by these Terms of Service. Without limitation, Applicable
				Laws include the laws of Canada and the laws of Alberta, including the
				Criminal Code (Canada), Corruption of Foreign Public Officials Act
				(Canada), and the United States Foreign Corrupt Practices Act.
				"Authentication ID" means a security mechanism by which an Authorized
				User identifies herself or himself to the Services and gains access
				thereto, which security mechanism may include user identification,
				passwords, digital certificates or any other similar process mechanism
				for 2 authentication and recognition as determined by Daniola from time
				to time, and which shall, without limitation, be in compliance with the
				requirements stated under Onboarding. "Authorized User" means a
				Permitted User who Customer has authorized to access and use the
				Services. "CASL" means Canada's Anti-Spam Legislation, namely An Act to
				promote the efficiency and adaptability of the Canadian economy by
				regulating certain activities that discourage reliance on electronic
				means of carrying out commercial activities, and to amend the Canadian
				Radiotelevision and Telecommunications Commission Act, the Competition
				Act, the Personal Information Protection and Electronic Documents Act
				and the Telecommunications Act (S.C. 2010, c. 23), as amended. "CASL
				Consent" means consent of Customer and its Authorized Users to receive
				commercial electronic messages (as the term "commercial electronic
				messages" is defined in CASL) from Daniola, and from Third-Parties
				sending electronic commercial messages on behalf of Daniola. "Claim"
				means any civil action, suit, lawsuit, claim, demand, proceeding, lien,
				or judgment for any loss, damages, liability, debt, costs, or other
				compensation or legal or equitable remedy of any kind (including
				reasonable attorneys’ or lawyers’ fees and other litigation expenses and
				disbursements). "Confidential Information" means and includes these
				Terms of Service, the Services, Customer Data, the Content of either
				Party, and all ideas, designs, business models, databases, drawings,
				documents, diagrams, formulas, test data, marketing, financial or
				personnel data, sales information, customer or supplier information,
				including information provided by such customers or suppliers, or any
				other information already furnished and to be furnished or made
				available by one Party to the other, whether in oral, written, graphic
				or electronic form including any such information exchanged during
				informational sessions designated as confidential, including, without
				limitation, information concerning a Party's actual and potential
				customers and other Intellectual Property Rights of such Party,
				provided, however, that Confidential Information shall not include any
				data or information: (i) that, at the time of disclosure, is in or,
				after disclosure, becomes part of the public domain, through no act or
				failure on the part of the receiving Party, whether through breach of
				these Terms of Service or otherwise; (ii) that, prior to disclosure by
				the disclosing Party, was already in the possession of the receiving
				Party, as evidenced by written records kept by the receiving Party in
				the ordinary course of its business, or as evidenced by proof of actual
				prior use by the receiving Party; (iii) independently developed by the
				receiving Party, by Persons having no direct or indirect access to the
				disclosing Party's Confidential Information provided that the receiving
				Party provides clear and convincing evidence of such independent
				development; (iv) which, subsequent to disclosure, is obtained from a
				third-party Person: (A) who is lawfully in possession of the such
				information; (B) who is not in violation of any contractual, legal, or
				fiduciary obligation to either Party, as applicable, with respect to
				such information; and (C) who does not prohibit either Party from
				disclosing such information to others; or (v) is further disclosed with
				the prior written consent of the disclosing Party, but only to the
				extent of such consent. "Content" means and includes text, trademarks,
				logos, HTML coding, domain names, links, graphics, images, photographs,
				audio, video, content, works of authorship, works subject to copyright,
				data, and databases. "Customer" means an entity that has been accepted
				and approved by Daniola for Onboarding, and that is granted a License to
				access and use the Services in compliance with these Terms of Service.
				Where the context requires, references to Customer may include
				Customer's Authorized Users. 3 "Customer Data" means collectively any
				data, files, documentation, or other information: (i) that Customer or
				any of its Authorized Users may upload to the Services when using the
				Services; and (ii) processed, used, or accessed through the use of the
				Services, but does not include Aggregated Statistics. "Daniola Platform"
				means the website located at daniola.com, and the software and services
				available through the Platform to Customer and Customer's Authorized
				Users. "Documentation" means Daniola's user manuals, handbooks,
				documentation, specifications, and guides relating to the operation,
				use, and function of the Services and provided or made available by
				Daniola to Customer either electronically or in hard copy form, and
				which may be amended or updated by Daniola from time to time. "Effective
				Date" means the date that Daniola accepts these Terms of Service with
				Customer and commences Onboarding with Customer. "Event of Default"
				means and includes (a) a Party filing a petition seeking to take
				advantage of any law relating to the bankruptcy or insolvency of that
				Party; (b) a Party being adjudicated to be bankrupt; (c) a Party being
				or becoming the subject of a petition seeking the liquidation,
				reorganization, winding-up, dissolution or adjustment of indebtedness of
				that Party; (d) a Party becoming insolvent or making an assignment for
				the benefit of creditors or if a receiver is appointed for that Party;
				and (e) only in the case of Customer, Customer committing a Suspension
				Event which remains uncured for more than seven (7) days following
				Daniola's written demand that Customer cure such Suspension Event.
				"Feedback" has the meaning stated in Section 22. "Fees" means the
				charges, as set out in these Terms of Service, or otherwise in writing
				by Daniola from time to time, to be paid by Customer to Daniola for the
				performance of, and access to, the Services. "Governmental Authority"
				means any domestic, foreign, or supranational government, whether
				federal, provincial, state, territorial or municipal; and any
				governmental agency, ministry, department, tribunal, commission, bureau,
				board, or other instrumentality, including international institutions,
				exercising, or purporting to exercise legislative, judicial, regulatory
				or administrative functions of, or pertaining to, government.
				"Intellectual Property" means any property, tangible or intangible, that
				may be subject to Intellectual Property Rights, including without
				limitation, ideas, formulae, algorithms, concepts, techniques,
				processes, procedures, approaches, methodologies, plans, systems,
				research, information, documentation, data, data compilations,
				specifications, requirements, designs, diagrams, programs, inventions,
				technologies, software (including its source code), tools, products
				knowledge, know-how, including without limitation, trade secrets, and
				other materials or things. "Intellectual Property Rights" means: (a) any
				and all proprietary rights anywhere in the world provided under: (i)
				patent law; (ii) copyright law, including moral rights; (iii) trademark
				law; (iv) design patent or industrial design law; (v) semiconductor chip
				or mask work law; (vi) trade secret law; (vii) privacy law; or (viii)
				any other statutory provision or common law principle applicable to
				these Terms of Service which may provide a right in either: (A)
				Intellectual Property; or (B) the expression or use of Intellectual
				Property; and (b) any and all applications, registrations, licenses,
				sub-licenses, franchises, agreements or any other evidence of a right in
				any of the foregoing. "Mine" means a hole or system of holes in the
				ground where Minerals are removed or extracted. "Miner" means a
				Customer: (a) that owns legal mining rights to at least one Mine
				anywhere in the world, (b) whose Mine is verified and whose practices
				are governed by applicable ethical 4 standards and Applicable Laws of
				the mining industry, and (c) that intends to use the Platform primarily
				for the sale, and auction of Minerals, and to seek investment or funding
				opportunities for mining, extraction, or production of Minerals.
				"Mineral" means a solid inorganic substance of natural occurrence,
				including coal, salt, metals, and metallic compounds, but excludes oil,
				petroleum, bitumen, and natural gas. "Objectionable Content" means
				content that infringes any Applicable Laws, regulations or thirdparty
				rights, including human rights legislation, content which is obscene,
				indecent, pornographic, violent, seditious, offensive, defamatory,
				threatening, discriminatory, racist, liable to incite racial hatred,
				menacing, blasphemous, misleading, deceptive, or in breach of any
				person's Intellectual Property Rights or rights of privacy or
				personality, or that constitutes a tort or other basis for civil action
				for damages or injunctive relief. "Off-Taker" means a Customer which
				purchases, seeks, or intends to purchase Minerals from one or more
				Miners using the Services. "Onboarding" has the meaning stated in
				Section 6. "Party" means either Daniola or Customer; and "Parties" means
				both Daniola and Customer. "Permitted User" means an employee of
				Customer or an independent contractor who is an individual that performs
				all or substantially all of his or her work for Customer. "Person" means
				any individual, estate, sole proprietorship, firm, partnership,
				unincorporated association, unincorporated syndicate, unincorporated
				organization, limited liability company, corporation, body corporate,
				trustee, trust, Governmental Authority or other entity or organization
				and includes any successor to any of the foregoing. "Suspension Event"
				means an event giving rise to Daniola's right to suspend access to the
				Services, including any of the following: (a) any failure of Customer to
				pay Fees upon same becoming due; (b) any breach by Customer or any of
				its Authorized Users of the Acceptable Use Policy; (c) any breach by
				Customer of its representations and warranties given in these Terms of
				Service; (d) any unlawful, unpermitted, or infringing use of Daniola's
				Intellectual Property, including in the Daniola Platform or the
				Services; (e) wrongful access to or disclosure or violation of
				Confidential Information; (f) failure by Customer to provide
				documentation and information requested by Daniola; and (g) Daniola
				reasonably determining that the conduct of Customer or any one or more
				of its Authorized Users constitutes a threat or attack on the Daniola
				Platform or the Services, or a disruption of the Services, or a security
				risk to the Services or the Daniola Platform. "Services" means
				collectively the services to be provided by Daniola to Customer as
				described and set out in these Terms of Service, or as stated on the
				Daniola Platform or in the Services, or otherwise in writing by Daniola,
				and the Services shall include access to the Daniola Platform and its
				tracking and bid management system for Minerals, and access to the
				Documentation. "Term" has the meaning ascribed to it in Section 16.
				"Third-Party" means any Person other than Daniola or Customer.
				"Third-Party Products" means software, Content, or other products or
				services provided under license or sold by third-party vendors ("Third
				Party Vendors"), with which the Services may interface, or which may
				provide certain functionality essential to the operation of the
				Services. Third Party Products may be licensed, provided, or sold to
				Daniola for incorporation and use in the Services as part of the
				Services. Customer may also directly license or purchase Third Party
				Products from a Third-Party Vendor for use in connection with the
				Services. 5 "Vendor" means a Customer that offers goods and services
				that support the operations of Miners, and the transport and movement of
				Minerals from extraction to the market and end-users for such Minerals.
				"Virus" means a piece of code usually (but not necessarily) disguised as
				something else that causes some unexpected and, for the victim, usually
				undesirable, event and which is designed so that it may automatically
				spread to other computer users; the term "Virus" will also be deemed to
				include worms, cancelbots, trojan horses, harmful contaminants (whether
				self-replicating or not), nuisance-causing or otherwise harmful applets,
				and any other malicious or deleterious computer code or content.
				Interpretation. Headings. The division of these Terms of Service into
				articles, sections, schedules and other subdivisions, and the inclusion
				of headings, are for convenience of reference only and shall not affect
				the construction or interpretation of these Terms of Service. The
				headings in these Terms of Service are not intended to be full or
				precise descriptions of the text to which they refer. Unless something
				in the subject matter or context is inconsistent therewith, references
				herein to Articles and Sections are to Articles and Sections of these
				Terms of Service. Currency. Unless otherwise specified, all references
				to monetary amounts, including the symbol "$", are in respect of
				Canadian currency. Entire Agreement. these Terms of Service, together
				with any other documents to be delivered pursuant hereto, constitutes
				the entire agreement between the Parties pertaining to the subject
				matter hereof and supersedes all prior agreements, negotiations,
				discussions, and understandings, written or oral, between the Parties.
				Except as expressly provided in these Terms of Service, there are no
				representations, warranties, conditions other agreements or
				acknowledgements, whether direct or collateral, express or implied, that
				form part of or affect these Terms of Service. The execution or
				acceptance of these Terms of Service has not been induced by, nor do
				either of the Parties rely upon or regard as material, any
				representations, warranties, conditions, other agreements, or
				acknowledgements not expressly made in these Terms of Service or in any
				other documents expressly stated to be delivered pursuant to these Terms
				of Service. Governing Law. these Terms of Service, and the contractual
				relationships created herein, shall be governed by, and construed and
				enforced in accordance with, the laws in force in the Province of
				Alberta (excluding any conflict of laws rule or principle which might
				refer such construction to the laws of another jurisdiction). These
				Terms of Service shall be deemed to be a contract made in Alberta. The
				Parties hereto agree to submit to the exclusive jurisdiction of the
				courts of the Province of Alberta, or the Federal Court of Canada, as
				applicable, sitting at Calgary, and waive any objection relating to
				improper venue or forum non conveniens to the conduct of any proceeding
				in any such court, provided that, notwithstanding the foregoing, Daniola
				shall retain the option of commencing action against Customer in
				Customer's home jurisdiction. Dispute Resolution. The Parties confirm
				their agreement in the foregoing to submit to the exclusive jurisdiction
				of the courts of the Province of Alberta, or the Federal Court of
				Canada, as applicable, sitting at Calgary, and waive any objection
				relating to improper venue or forum non conveniens to the conduct of any
				proceeding in any such court, provided that, notwithstanding the
				foregoing, Daniola shall retain the option of commencing action against
				Customer in Customer's home jurisdiction. 6 However, prior to commencing
				any litigation, unless commercially unreasonable to not be required to
				do so, each Party agrees to utilize reasonable efforts to resolve any
				dispute, whether arising during the Term of these Terms of Service, or
				at any time after the expiration of termination of these Terms of
				Service, which touches upon the validity, construction, meaning,
				performance or affects these Terms of Service or the rights and
				liabilities of the Parties or any matter arising out of or connected
				with these Terms of Service, promptly and in an amicable and good faith
				manner by negotiations between the Parties. Severability. In the event
				that any provision (or any portion of a provision) of these Terms of
				Service shall, for any reason, be held by a court of competent
				jurisdiction to be invalid, illegal, or unenforceable for any reason,
				such invalidity, illegality or unenforceability shall not affect any
				other provision hereof and these Terms of Service shall be construed as
				if such invalid, illegal or unenforceable provision (or portion of a
				provision) had never been contained herein in regards to that particular
				jurisdiction. Modifications. Customer acknowledges and agrees that
				Daniola has the right, in its sole discretion, to modify these Terms of
				Service from time to time, and that the Terms of Service so modified
				become effective on posting in the Services or on the Daniola Platform.
				Customer agrees that it shall be responsible for reviewing and becoming
				familiar with any such modifications. Customer's continued use of the
				Services after the effective date of all such modifications will be
				deemed acceptance of the modified Terms of Service. Consent to Contract
				Electronically. Customer consents to contract and communicate
				electronically, and the provisions of the Electronic Transactions Act
				(Alberta) as amended, shall apply to these Terms of Service. Notices.
				Every notice or other communication hereunder shall be deemed to have
				been duly given and made if (a) in the case of Customer, such notice or
				communication in writing is delivered by, at Daniola's option, posting
				in the Services or by email to Customer's email address designated as
				its user name, and shall be deemed delivered upon posting in the
				Services or sending via email, and (b) in the case of Daniola, delivered
				either in person, by certified or registered mail, return receipt
				requested and postage prepaid, or by recognized overnight courier
				service, to Daniola's corporate headquarters address available at
				www.daniolacorp.com and shall be deemed given upon receipt by Daniola.
				Further Assurances. Each Party shall take such action (including, but
				not limited to, the execution, acknowledgment, and delivery of
				documents) as may reasonably be requested by the other Party for the
				implementation or continuing performance of these Terms of Service.
				Relationship. The Parties are independent contractors, and no other
				relationship is intended. Nothing herein shall be deemed to constitute
				either Party as an agent, representative or employee of the other Party,
				or both Parties as joint venturers or partners for any purpose. Neither
				Party shall act in a manner that expresses or implies a relationship
				other than that of independent contractor. Each Party shall act solely
				as an independent contractor and shall not be responsible for the acts
				or omissions of 7 the other Party. Neither Party will have the authority
				or right to represent nor obligate the other Party in any way except as
				expressly authorized by these Terms of Service. Enurement. These Terms
				of Service shall enure to the benefit of and be binding upon each of the
				Parties hereto and their permitted successors and assigns. Assignment.
				Neither these Terms of Service, nor any rights or obligations hereunder
				shall be assignable by Customer without the prior written consent of
				Daniola. Waiver. The waiver by either Party of a breach or default of
				any provision of these Terms of Service by the other Party shall not be
				effective unless in writing and shall not be construed as a waiver of
				any succeeding breach of the same or of any other provision. Nor shall
				any delay or omission on the part of either Party to exercise or avail
				itself of any right, power or privilege by such Party constitute a
				waiver. Counterparts and Electronic Execution and Delivery. Daniola will
				determine the means by which these Terms of Service shall be executed.
				Subject to the forgoing, these Terms of Service may be executed
				electronically or digitally, or by way of paper copies signed by the
				Parties, in which case these Terms of Service may be executed in
				counterparts, each of which shall be deemed to be an original and both
				of which together shall constitute one and the same instrument. To
				evidence its execution of an original counterpart of these Terms of
				Service, a Party may send a copy of its original signature on the
				execution page hereof to the other Party as a scanned copy to PDF format
				by e-mail and such transmission shall constitute delivery of an executed
				copy of these Terms of Service to the receiving Party as of the date of
				receipt thereof by the receiving Party. Survival of Covenants.
				Notwithstanding the termination or expiration of these Terms of Service
				for any reason, the covenants set out in Section 2 (Interpretation);
				Section 4 (Ownership); Section 8 (Representations and Warranties of
				Customer); Section 11 (Customer Indemnities); 17 (Termination); 19
				(Customer Data); Section 20 (License to Customer Data; Section 21
				(Aggregated Statistics); Section 22 (Feedback); Section 31 (Audit
				Rights); Section 32 (Confidentiality); Section 34 (EXCLUSION OF ALL
				OTHER WARRANTIES AND CONDITIONS); Section 35 (LIMITATION OF LIABILITY);
				and Section 36 (CAP ON DAMAGES); and those provisions set out in Section
				1 (Definitions), as necessary to interpret the foregoing provisions, of
				these Terms of Service shall survive any such termination or expiration.
				Additional Rules of Interpretation. (i) In these Terms of Service,
				unless the context requires otherwise, words in one gender include all
				genders and words in the singular include the plural and vice versa.
				(ii) Wherever the words "include", "includes" or "including" are used in
				these Terms of Service, they shall be deemed to be followed by the words
				"without limitation" and the words following "include", "includes" or
				"including" shall not be considered to set forth an exhaustive list.
				(iii) The terms " these Terms of Service ", "this agreement", "hereof",
				"hereunder" and similar expressions refer to these Terms of Service and
				not to any particular article, section or other portion hereof and
				include any agreement supplemental hereto. (iv) Unless otherwise defined
				herein, words or abbreviations which have well-known trade meanings are
				used herein with those meanings. 8 Grant of License. Conditional always
				upon Customer's continued compliance with all the terms and conditions
				of these Terms of Service, including timely and up-to-date payment of
				all required Fees, and for the duration of the Term, Customer is granted
				a non-exclusive, non-transferable, non-sublicensable, limited, and
				revocable license permitting Customer and its Authorized Users to access
				and use the Services, only as permitted by the operation of the
				Services, for Customer's business operations. Any use by Customer and
				its Authorized Users of the Documentation will be limited to Customer's
				internal business purposes. Only Permitted Users may be authorized by
				Customer to become Authorized Users, and only to a maximum number of
				Authorized Users as may be directed by Daniola from time to time.
				Ownership. Customer acknowledges and agrees that, as between Customer
				and Daniola, Daniola owns all worldwide right, title, and interest,
				including all Intellectual Property Rights, in and to: (i) the Daniola
				Platform; (ii) the Services; (iii) the Documentation; and (iv) any
				modifications, enhancements, upgrades, updates or customization to the
				foregoing. Customer does not acquire any rights, title, or ownership
				interests of any kind whatsoever, express or implied, in any of the
				foregoing other than the license granted herein. All rights not granted
				by Daniola are expressly reserved. The Services consist of services,
				software, and Content, and are not a sale of goods. The Parties agree
				that the United Nations International Convention on Contracts for the
				International Sale of Goods shall not apply to these Terms of Service,
				the Services, the Daniola Platform, or the Documentation. Fees. Customer
				agrees to pay Daniola all applicable Fees related to Customer's use of
				the Services, commencing on the Effective Date, including the following:
				for each Customer, a subscription fee of $150.00 per month, paid in
				advance; for each Customer that is also a Miner, a listing fee of
				$500.00 for each product or service listed in the Services, paid in
				advance; and all applicable taxes, levies, and other charges imposed by
				any Governmental Authority with authority over the Parties, or either of
				them. Onboarding. Daniola has the right, in its sole and unfettered
				discretion, to carry out due diligence regarding Customer and any one or
				more of its Authorized Users, and has the right, but not the obligation,
				to reject any Customer or Authorized User being granted any rights to
				access the Services for any reason, or for no reason at all. Customer
				gains rights under these Terms of Service subject to, and conditional
				upon, Daniola's approval and acceptance. Without limitation of the
				foregoing, in order to be granted a license to access the Services,
				Customer must first complete the following requirements of Onboarding,
				as well as any other specific requirements or conditions communicated by
				Daniola: (i) Customer must accept and agree to these Terms of Service in
				the manner required by Daniola; (ii) Customer must pay any deposits,
				advance payments, or other payments required by Daniola; (iii) Customer
				must set up Customer's account by creating an Authentication ID
				including a username consisting of Customer's valid email address, which
				email address Customer agrees and undertakes to maintain fully and
				continuously operational as it will or may be used by Daniola to
				communicate with Customer regarding the Services, and may be necessary
				to recover any lost password; 9 (iv) As part of its Authentication ID,
				Customer must also create a password that complies with Daniola's
				requirements, namely the password must consist of at least ten
				characters that are a combination of letters (both uppercase and
				lowercase) and numbers or special characters that is not used by
				Customer for any other website or online service; (v) Customer will
				require its Authorized Users to create usernames and passwords in the
				same manner as the foregoing with respect to their Authentication ID's.
				Customer will fully comply with Daniola's due diligence and "know your
				customer" requirements, including providing Daniola with all requested
				documentation, which may include certified copies of government records,
				certificates, permissions, grants, orders, apostilles, and other
				official records, and may be required in digital form, or original hard
				copy/paper form. Customer will continue to completely and continually
				comply with Daniola's ongoing requests for such documentation.
				Customer's failure to comply with the foregoing may result in Daniola
				declining to accept these Terms of Service with respect to Customer, or
				in a Suspension Event, and to Customer being denied access to the
				Services. Mutual Representations of the Parties. Each Party represents
				to the other that: it is a legal entity duly organized, validly existing
				and in good standing under the laws of its incorporation and it has full
				power and authority to enter into these Terms of Service and to perform
				each and every covenant and agreement herein contained; these Terms of
				Service have been duly authorized, executed and delivered by it and
				constitutes a valid, binding and legally enforceable agreement of it;
				the execution and delivery of these Terms of Service, and the
				performance of the covenants and agreements herein contained, are not,
				in any manner or to any extent, limited or restricted by, and are not in
				conflict with, any commercial arrangements, obligations, contract,
				agreement or instrument to which it is either bound or subject; and the
				execution and delivery of these Terms of Service and the performance of
				its covenants and agreements herein contained shall comply in all
				respects with all Applicable Laws to which it or its business is
				subject. Representations and Warranties of Customer. Customer represents
				and warrants to Daniola as follows: Customer has fully read,
				understands, and freely accepts all the terms and conditions of these
				Terms of Service; where Customer accepts these Terms of Service on
				behalf of an organization, group, or other Third-Party Person, Customer
				does so with the lawful authority and capacity to contract on behalf of
				and legally bind such Third-Party Person to these Terms of Service;
				Customer has either obtained independent legal advice and counsel prior
				to accepting and entering into these Terms of Service, or Customer has
				freely waived same; all information provided by Customer to Daniola,
				whether pursuant to Onboarding, or otherwise at any time, and from time
				to time, will be complete, accurate, and truthful. Customer shall be
				responsible and fully liable for the accuracy, timeliness, truthfulness,
				and completeness of information provided to Daniola; 10 all documents
				and records furnished to Daniola, whether pursuant to Onboarding, or
				otherwise at any time, and from time to time, are and will be complete
				and accurate copies of the original documents and, where stated to be
				certified by a Governmental Authority, will in fact be so certified;
				Customer will fully comply with all of the terms and conditions of these
				Terms of Service, including but not limited to the Acceptable Use
				Policy; Customer will fully comply with all Applicable Laws, including
				without limitation laws and legislation applicable to the privacy of
				individuals and the collection, use, disclosure, retention, and
				destruction of personal information; All CASL Consents obtained by
				Customer and confirmed as being valid and in effect will in fact be
				valid and in effect, and Daniola will be entitled to rely on such CASL
				Consents until Customer advises that any one or more such consents are
				withdrawn, expired, or no longer valid; and Customer confirms and
				accepts that Customer shall be fully responsible and liable for the
				conduct of its Authorized Users; and for all activities that occur under
				or with respect to Customer's account with Daniola with respect to the
				Services. Customer Responsibilities. In addition to its other
				obligations contained in these Terms of Service, Customer will: be
				responsible for procuring, installing, operating, supporting, and
				maintaining Customer's systems, including computer hardware and
				software, including browsers, necessary for Customer to access the
				Services; be responsible for procuring and maintaining communication
				services, including high speed internet connections between Customer's
				systems and the Services and the Daniola Platform; assign, record and
				control the issuance and use of all Authentication IDs; be responsible
				for the accuracy, completeness, and adequacy of all Customer Data, for
				the management, manipulation and processing of Customer Data, and the
				back up and maintenance of all Customer Data; obtain and provide CASL
				Consents to Daniola with respect to all directors, officers, employees,
				and contractors of Customer receiving electronic communications from
				Daniola, including all Customer's Authorized Users and, where any CASL
				Consent expires, is withdrawn, or is no longer valid, immediately advise
				Daniola of such expiry, withdrawal, or loss of validity in writing; use
				the Services, including the Daniola Platform and the Documentation, by
				itself and by its Authorized Users, in accordance with all Applicable
				Laws, all the terms and conditions of these Terms of Service, and the
				Documentation; and comply, at all times, with all Applicable Laws, and
				the policies and directions of Daniola with respect to the use of the
				Service. Acceptable Use Policy. Customer and its Authorized Users will
				only access and use the Services for lawful purposes and only in
				compliance with these Terms of Service. Without limitation; Customer and
				its Authorized Users shall not: use the Services for improper or
				unlawful purposes, or contrary to Applicable Laws; use the Services in
				any manner contrary to Daniola's policies or procedures as published or
				provided from time to time; 11 include, or knowingly allow others to
				include, any Objectionable Content or introduce Viruses to the Services,
				and Customer shall institute such security procedures and safeguards,
				including contractual prohibitions and restricted levels of access, as
				necessary to prevent the posting, uploading or inclusion of any
				Objectionable Content or Viruses to the Services; intercept or attempt
				to intercept any messages transmitted to and from the Services that are
				not intended for Customer or any of its Authorized Users; take any
				action that imposes an unreasonable or disproportionately large load or
				demand on the Services; copy, modify, reverse engineer, decompile,
				translate, disassemble, or reverse compile the Services, or any
				component thereof, including the Documentation and any software used by
				Daniola, attempt to view, display, or print such software, including the
				source code thereto, or develop any functionally compatible or
				competitive products, services, or software to the Services; permit any
				Third-Party Person to access or use the Services; develop any derivative
				works from the Services, including the Documentation; rent, lease, lend,
				sell, license, sub-license, assign, distribute, disclose, publish,
				transfer, communicate, display, or otherwise make available the
				Services, including the Documentation, except as expressly permitted
				under these Terms of Service; copy or download any software used by
				Daniola to provide the Services; use the Services to infringe, violate,
				or misappropriate any Intellectual Property Right of any Third Party.
				use the Services to provide products or services to third-party Persons,
				or use the Services as a service bureau; or remove any copyright or
				other proprietary rights notice from the Services or the Documentation
				or any copies thereof. Customer Indemnities. Customer will indemnify,
				defend, and forever save and hold harmless Daniola, together with its
				directors, officers, shareholders, employees, contractors, and agents,
				from and against the following: Any breach by Customer of its
				representations and warranties under these Terms of Service; Any default
				by Customer of its obligations under these Terms of Service; Any Claim
				made against Daniola from a Third-Party Person as a result of any use or
				misuse of Customer's Authentication IDs, whether or not such use is by
				an Authorized User, or is otherwise authorized by Customer; and Any
				breach by Customer or its Authorized Users of the Acceptable Use Policy.
				Suspension. Where a Suspension Event occurs, Daniola may, without notice
				to Customer, and without any liability of any kind to Customer,
				immediately suspend access to the Services, and maintain such suspension
				until Customer has cured its default or conduct comprising a Suspension
				Event. Daniola shall have the right, but not the obligation, to demand,
				by written notice, that Customer cure its default or conduct comprising
				a Suspension Event, failing which Daniola shall have the right, but not
				the obligation, to deem that an Event of Default has occurred. 12
				Viruses and Objectionable Content. If Daniola, in its absolute
				discretion, forms the view that any Customer Data or any other
				information or files uploaded by Customer or any of its Authorized Users
				contains or includes a Virus or constitutes Objectionable Content,
				Daniola may remove such Customer Data, information or file from the
				Services and take such other action as Daniola deems necessary to
				protect the integrity and operation of the Services. Any costs
				associated with such removal may be charged by Daniola to Customer and
				Customer will pay such charges to Daniola promptly. Daniola shall notify
				Customer of its actions pursuant to the foregoing as soon as reasonably
				possible. Late Payment. Where Customer is in default of timely payment
				of Fees, in addition to Daniola's right to suspend access to the
				Services, declare a Suspension Event, or declare and Event of Default
				and terminate these Terms of Service, Customer agrees, both before and
				after judgment, to pay interest to Daniola at the rate of eighteen per
				cent (18%) per annum, compounded semi-annually, on all overdue Fees or
				other amounts, together with all costs of collection, including legal
				fees and disbursements on a solicitor (or lawyer or attorney) and own
				client basis. Event of Default. Where an Event of Default occurs,
				Daniola shall have the right, but not the obligation, upon written
				notice to Customer, to immediately terminate these Terms of Service
				without liability to Customer. Term. The term of these Terms of Service
				begins on the Effective Date and continues indefinitely until
				terminated. Termination. In addition to any other express termination
				right set forth in these Terms of Service: Daniola may terminate these
				Terms of Service for any reason, or no reason at all, upon thirty (30)
				days' written advance notice to Customer; Customer may terminate these
				Terms of Service for any reason, or no reason at all, upon sixty (60)
				days' advance notice to Daniola; except for an Event of Default, either
				Party may terminate these Terms of Service, effective on written notice
				to the other party, if the other Party materially breaches these Terms
				of Service and such breach: (i) is incapable of cure; or (i) being
				capable of cure, remains uncured thirty days after the non-breaching
				Party provides the breaching Party with written notice of such breach;
				and either Party may terminate these Terms of Service, effective
				immediately upon written notice to the other Party, if the other Party
				commits an Event of Default, or an Event of Default occurs. Effect of
				Termination. No termination will eliminate, diminish, or affect
				Customer's obligation to pay all Fees due as of the date of termination.
				Upon Termination, Customer and its Authorized Users will have no right
				or ability to access the Services, and all grants of licenses to
				Customer from Daniola will terminate. Subject to up-to-date payment of
				all Fees due to Daniola, Customer shall be granted the ability to access
				and download its Customer Data for a period of thirty days from the date
				of termination, following which Daniola will be entitled to delete all
				Customer Data from the Services, without any liability to Customer.
				Customer Data. Customer will ensure that Customer Data and any
				Authorized User's data or use of Customer Data will not violate any
				policy or terms referenced in or incorporated into these Terms of
				Service or any Applicable Laws. Customer acknowledges and agrees that
				Daniola: (a) will not be responsible for the accuracy, completeness or
				adequacy of any 13 Customer Data or the results generated from any
				Customer Data uploaded to the Services or the Daniola Platform and
				processed by the Services; (b) has no control over any Customer Data or
				the results threfrom; (c) does not purport to monitor Customer Data; and
				(d) shall not be responsible to back up or maintain any back up of the
				Customer Data or portion thereof. License to Customer Data. Customer
				hereby grants to Daniola: a non-exclusive, royalty-free, fully paid, and
				worldwide license for the Term of these Terms of Service, and during any
				period following termination where Customer Data remains in the
				Services, to reproduce, distribute, and otherwise use and display the
				Customer Data and perform all acts with respect to the Customer Data as
				may be necessary for Daniola to provide the Services to Customer; and a
				non-exclusive, perpetual, irrevocable, royalty-free, fully paid
				worldwide license to reproduce, distribute, modify, and otherwise use
				and display Customer Data incorporated within the Aggregated Statistics.
				Aggregated Statistics. Notwithstanding anything to the contrary in these
				Terms of Service, Daniola may monitor Customer's use of the Services,
				and collect and compile data and information related to Customer's use
				of the Services to be used by Daniola in an aggregated and anonymized
				manner, including to compile statistical and performance information
				related to the provision and operation of the Services ("Aggregated
				Statistics"). As between Daniola and Customer, all right, title, and
				interest in Aggregated Statistics, and all Intellectual Property Rights
				therein, will exclusively belong to Daniola. Customer acknowledges and
				agrees that Daniola may compile Aggregated Statistics based on Customer
				Data input into the Services. Customer further acknowledges and agrees
				that Daniola may (i) copy, display, communicate, publish, distribute,
				use, disclose, and make Aggregated Statistics publicly available in
				compliance with Applicable Laws, provided that such Aggregated
				Statistics do not identify Customer, disclose personal information in
				contravention of Applicable Laws, or disclose Customer's Confidential
				Information. Feedback. If Customer or any of its Authorized Users,
				employees, contractors, or agents provides any suggestions,
				recommendations, or requests for changes to the Services, including
				without limitation, new features or functionality relating thereto, or
				any comments, questions, suggestions, or the like ("Feedback"), all such
				Feedback is and will be treated as non-confidential and non-proprietary
				to Customer. Customer will assign, and hereby does assign to Daniola on
				Customer's behalf, and shall cause Customer's Authorized Users,
				employees, contractors, and agents to assign, all right, title, and
				interest in all such Feedback. Daniola shall have the right, but not the
				obligation, to use, without any attribution or compensation whatsoever
				to Customer or any Third-Party Person, any ideas, know-how, concepts,
				techniques, or other Intellectual Property Rights contained in such
				Feedback, for any purpose whatsoever, including incorporation into the
				Services. Third-Party Vendors and Third-Party Products. Customer
				acknowledges and agrees that certain aspects, components, or portions of
				the Services are, or may be, comprised of, include, interface with, or
				otherwise require Third-Party Products and are provided to Daniola by
				Third-Party Vendors for license, sublicense, delivery, resale, or
				provision to Customer, or inclusion in the Services. Daniola has no
				control over the actions of such Third-Party Vendors, or the
				performance, nature, quality, availability, or suitability of their
				Third-Party Products. Accordingly, notwithstanding any term or condition
				of these Terms of Service, in no event will the rights of Customer to
				assert or pursue any Claim against Daniola with 14 respect to any
				Third-Party Product exceed the obligations of the associated or
				applicable Third-Party Vendor (a) to fully compensate Customer directly,
				or (b) to fully indemnify, compensate, or reimburse Daniola for and
				against any such Claim by Customer. Further, Daniola shall have the
				right to modify or terminate, without liability to Customer, any portion
				of the Services where the actions, errors, or omissions of any one or
				more Third-Party Vendors, or changes to the performance, nature,
				quality, availability, or suitability of the applicable Third-Party
				Products of such Third-Party Vendors make such portion of the Services
				no longer available without modification, or at all, or otherwise
				commercially unreasonable to provide. Daniola will make commercially
				reasonable efforts to provide Customer with notice of any termination or
				change pursuant to the foregoing, but Customer acknowledges and agrees
				that Daniola may not receive notice from such Third-Party Vendors, and
				therefore may not be able to provide notice to Customer. Where Customer
				receives such a notice from any such Third-Party Vendor, Customer will
				notify Vendor. Service Levels. Subject to the terms and conditions of
				these Terms of Service, Daniola will use commercially reasonable efforts
				to make the Services available in accordance with any service levels
				posted from time to on the Platform or in the Services. Support. These
				Terms of Service do not entitle Customer, or any of its Authorized
				Users, to any support for the Services. Access to the Services and
				Daniola Platform. Customer acknowledges and agrees that Daniola will not
				responsible or liable with respect to any communication over the public
				Internet, or for the operations of any Third-Party network (including
				Daniola's own service providers) or any such network's failure to
				deliver communication or data to and from the Services, or the Daniola
				Platform on a timely basis. Security Requirements. Both Parties shall
				implement and maintain commercially reasonable safeguards and controls
				to deter and for the detection, prevention and correction of any
				unauthorized intrusion, access to, or use of the Services, the Daniola
				Platform, Customer Data and the networks, systems, computers, and other
				devices of Customer. Customer acknowledges and agrees that,
				notwithstanding Daniola taking such measures, such measures may not
				prevent unauthorized electronic intruders to access the Daniola Platform
				or the Services, including Customer Data, through the Internet or
				through other forms of electronic communication. If such unauthorized
				electronic intruders are able to bypass Daniola's security protocols and
				safeguards, such unauthorized electronic intruder may change, delete or
				otherwise corrupt the contents and data contained in the Daniola
				Platform, or in the Services, including the Customer Data. In any and
				all cases, Daniola shall not be liable to Customer, and hereby disclaims
				responsibility or liability, with respect to any action, destructive or
				otherwise, by any unauthorized electronic intruder. Maintenance. From
				time to time, it will be necessary for Daniola to perform maintenance on
				the Services and the Daniola Platform. Such maintenance includes routine
				maintenance to ensure the continued provision of the Services through
				the continued operation of the Daniola Platform or upgrading, updating,
				or enhancing the Services and the Daniola Platform. Daniola shall use
				its commercially reasonable efforts to perform such maintenance at such
				times to minimize the impact of any downtime of the Services or the
				Daniola Platform to Customer, having regard to the geographic location
				and time zone of Daniola. To the extent Daniola is reasonably able,
				Daniola shall notify Customer in advance of any scheduled maintenance by
				posting a message in the Services or on the Daniola 15 Platform of the
				scheduled maintenance time and the anticipated duration of such
				maintenance. Changes. Daniola may, at any time, with or without notice
				to Customer: (a) make changes that are necessary to comply with
				applicable safety, security or other statutory requirements or orders
				from applicable Governmental Authorities; (b) supplement or make changes
				to the Documentation and to Daniola's rules of operations, access
				procedures, security and privacy procedures and policies; and (c) change
				the components, type and location of the Services or the Daniola
				Platform. Authentication IDs. Customer shall control and maintain the
				security of all Authentication IDs. Customer shall be solely responsible
				for all instructions, commitments and other actions or communications
				taken under any of its Authentication IDs. Customer shall promptly
				report to Daniola any errors or irregularities in the Service or the
				Daniola Platform or any unauthorized use of any part thereof and inform
				Daniola immediately if any Authentication ID becomes known to any
				Third-Party Person who is not authorized to possess such Authentication
				ID. Audit Rights Daniola's Audit Rights (i) Daniola reserves the right
				to monitor and audit Customer's and its Authorized Users' usage of the
				Services for the purpose of (among others) ensuring compliance with the
				terms and conditions of these Terms of Service. Any such audit may be
				carried out by Daniola, or a third party authorized by Daniola, at
				Daniola's expense. (ii) If Daniola's monitoring activities or its audit
				pursuant to the foregoing reveals that Customer's or any Authorized
				User's use of the Services is in contravention of these Terms of Service
				including any Applicable Laws, then Daniola may immediately declare a
				Suspension Event, and suspend and discontinue the Services to Customer
				or to that specific Authorized User, at Daniola's sole discretion and
				without notice to Customer. Daniola shall notify Customer of such
				suspension as soon as reasonably possible, which notice shall set out
				the circumstances of the suspension. If Customer rectifies the situation
				to Daniola's satisfaction, then Daniola will reinstate the Services. If
				Customer does not rectify the situation within ten days, then it shall
				be deemed an Event of Default, and Daniola shall be free to terminate
				these Terms of Service and pursue any remedies available to it. (iii) If
				Daniola's audit reveals the use of the Services by Customer is in excess
				of any permitted level set by Daniola or (ii) any Authentication ID has
				been provided to a person who is not an Authorized User, or a Permitted
				User, or access to the Services was otherwise granted to a Person who is
				not an Authorized User, or the number of Authentication IDs granted by
				Customer exceeds any number of Authorized Users set by Daniola (where
				Daniola has set such number), Customer shall, without delay, pay Daniola
				the amount of Fees required for such level of use or number of
				Authorized Users based on Daniola's then current list price for the
				Services. In case of unauthorized use of the Services, whether by
				Customer, an Authorized User, or another person, Daniola reserves the
				right to deny access to the Services to Customer or such Authorized User
				or other person. Customer's Regulatory Audits 16 (i) Daniola shall
				provide to such auditors (including external auditors and Customer's
				internal audit staff or agents) as Customer may designate in writing,
				access to appropriate Daniola personnel and subcontractors, and to
				reasonable data, records and supporting documentation maintained by
				Daniola with respect to the Services, for the purpose of performing
				audits and inspections to enable Customer to satisfy applicable
				statutory and regulatory requirements or to certify compliance with
				Applicable Laws, and solely to the extent required to satisfy such
				requirements. The scope of such audits shall be limited solely to that
				which is necessary to enable Customer to satisfy its statutory or
				regulatory compliance obligations and may include, without limitation,
				and when applicable: (A) Daniola's practices and procedures; (B) any
				controls as set out and which forms part of Daniola's applicable
				security requirements and disaster recovery and back-up procedures. (ii)
				Customer and its auditors shall use commercially reasonable efforts to
				conduct such audits in a manner that will result in a minimum of
				inconvenience and disruption to Daniola's business operations. Audits
				may be conducted only during normal business hours. Customer will
				provide Daniola with reasonable prior written notice of each audit, but
				with at least thirty (30) calendar days. Customer and its auditors will
				not be entitled to audit: (A) data or information of other customers or
				clients of Daniola; or (B) any Confidential Information of Daniola that
				is not necessary for purposes of the audit. Daniola will use
				commercially reasonable efforts to cooperate in the audit, will to the
				extent applicable and reasonably practicable, convert any technical
				records maintained in an electronic format into a readily understandable
				format, or a format that can be readily understood without need for
				special equipment or specialized knowledge; will make available, on a
				timely basis, the information reasonably required to conduct the audit;
				and will assist the designated employees and agents of Customer or its
				auditors as reasonably necessary. To the maximum extent possible, audits
				shall be designed and conducted (in such manner and with such frequency)
				so as not to interfere with the provision of the Services. All
				information learned or exchanged in connection with the conduct of an
				audit, as well as the result of any audit, constitutes Daniola
				Confidential Information. Each Party shall maintain, during the Term and
				thereafter as required by Applicable Law, a complete and accurate set of
				files, records and books and accounts of their transactions hereunder.
				(iii) In addition to the audit referred to in the foregoing paragraph,
				Daniola acknowledges that Customer may be subject to statutory audits
				and other requests for information from taxation and other Governmental
				Authorities. Customer shall notify Daniola in a timely manner after
				being contacted by such Governmental Authority regarding such an audit.
				Daniola shall respond reasonably to any requests from such Governmental
				Authority regarding Customer according to Customer's direction, subject
				to Daniola's obligations under Applicable Law. Except as otherwise
				required by Applicable Law, if Customer is contacted by a Governmental
				Authority regarding such an audit, Daniola may provide information to
				such Governmental Authority only under the direction of Customer.
				Daniola shall provide such information in a timely manner either to
				Customer or, at Customer's request, directly to the applicable
				Governmental Authority. As part of this audit process, Daniola may be
				required to answer questions from Governmental Authorities with respect
				to its processing of certain transactions for Customer. Customer shall
				send a 17 representative to be present at all such discussions with such
				Governmental Authorities if and to the extent not prohibited by
				Applicable Law. (iv) Any Customer audits or provision of information by
				Daniola will be conducted at Customer's expense, and Customer will pay
				to Daniola all Fees or other charges or expenses associated with, or
				incurred by Daniola, with respect to Customer audits or provision of
				information by Daniola. Coordination of Regulatory Audits - Customer
				acknowledges that the audits set out in the foregoing are disruptive to
				the provision of the Services. In order to satisfy all audit requests
				from Customer and to Daniola's other customers; Customer hereby agrees
				that Daniola may hire an independent third-party auditor to conduct an
				audit in satisfaction of the foregoing, and to provide the results of
				such audit to Customer in lieu of Customer conducting its own audit
				pursuant to the foregoing. Alternatively, and if consented to by
				Daniola, Customer shall coordinate with Daniola regarding the timing,
				scope and processes of any audit conducted by Customer hereunder to
				minimize any disruption to the Services and duplication of effort with
				any other similar audit. Confidentiality Each Party acknowledges that
				all Confidential Information consists of confidential and proprietary
				information of the disclosing Party. Each Party shall, and shall cause
				its employees, agents and contractors and, in the case of Customer, its
				Authorized Users, to hold Confidential Information of the other Party in
				confidence, and shall use the same degree of care by instruction,
				agreement or otherwise, to maintain the confidentiality of the other
				Party's Confidential Information that it uses to maintain the
				confidentiality of its own Confidential Information, but with no less
				than a reasonable degree of care commensurate with the nature and
				importance of such Confidential Information. Each Party agrees not to
				make use of Confidential Information other than for the exercise of
				rights or the performance of obligations under these Terms of Service,
				and not to release, disclose, communicate, or make the other Party's
				Confidential Information available to any Third-Party Person other than
				employees, agents and contractors of the Party who reasonably need to
				know it in connection with the exercise of rights or the performance of
				obligations under these Terms of Service. In the event that either Party
				receives a request to disclose all or any part of the Confidential
				Information under the terms of a valid and effective subpoena or order
				issued by a court of competent jurisdiction or by a Governmental
				Authority, such Party agrees to, unless prohibited by Applicable Laws:
				(i) immediately notify the other Party of the existence, terms and
				circumstances surrounding such a request; (ii) consult with the other
				Party on the advisability of taking legally available steps to resist or
				narrow such request; and (iii) if disclosure of such Confidential
				Information is required, exercise its best commercially reasonable
				efforts to obtain an order or other reliable assurance that confidential
				treatment will be accorded to such portion of the disclosed Confidential
				Information which the other Party so designates. Each Party acknowledges
				and agrees that any unauthorized use or disclosure by it of any of the
				other Party's Confidential Information, in whole or part, will cause
				irreparable damage to the disclosing Party, that monetary damages would
				be an inadequate remedy and that the amount of such damages would be
				extremely difficult to measure. The receiving Party agrees that the
				disclosing Party shall be entitled to seek temporary and permanent
				injunctive relief to restrain the receiving Party from any unauthorized
				disclosure or use. Nothing in these Terms of Service shall be 18
				construed as preventing the disclosing Party from pursuing any and all
				remedies available to it for a breach or threatened breach of a covenant
				related to its Confidential Information, including the recovery of
				monetary damages from the receiving Party. Limited Warranty. Daniola
				warrants that it will perform and provide the Services on a commercially
				reasonable basis using a commercially reasonable level of care and
				skill. In the event of any breach of the foregoing express limited
				warranty, Customer's only and exclusive remedy is to terminate these
				Terms of Service and its contractual relationship with Daniola, and
				cease using the Services. EXCLUSION OF ALL OTHER WARRANTIES AND
				CONDITIONS. EXCEPT AS OTHERWISE EXPRESSLY STATED IN THESE TERMS OF
				SERVICE, THE SERVICES ARE PROVIDED "AS IS", "WHERE IS", AND "AS
				AVAILABLE", WITHOUT ANY WARRANTY, GUARANTEE, OR CONDITION WHATSOEVER.
				DANIOLA DOES NOT GIVE, AND CUSTOMER DOES NOT RECEIVE ANY
				REPRESENTATIONS, WARRANTIES, GUARANTEES, OR CONDITIONS, EXPRESS OR
				IMPLIED, WHETHER ARISING FROM STATUTE, COMMON LAW, EQUITY, OR A COURSE
				OR CUSTOM OF TRADE, OR OTHERWISE IN RELATION TO THE SERVICES, THE
				DANIOLA PLATFORM, OR THE DOCUMENTATION, INCLUDING WITHOUT LIMITATION
				IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABLE QUALITY, FITNESS FOR A
				PARTICULAR PURPOSE, COMPLIANCE WITH ANY DESCRIPTION, OR NONINFRINGEMENT,
				OR THAT THE SERVICES, INCLUDING THE DANIOLA PLATFORM, AND THE
				DOCUMENTATION, WILL MEET CUSTOMER'S NEEDS OR WILL BE AVAILABLE FOR USE
				AT ANY PARTICULAR TIME, OR WILL BE UNINTERUPTED, ACCURATE, TIMELY, OR
				ERROR FREE, OR WILL ACHIEVE ANY INTENDED RESULT, INCLUDING ANY
				ANTICIPATED REVENUE OR SAVING, OR BE SECURE, COMPLETE, FREE OF VIRUSES
				OR HARMFUL CODE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
				UNDER NO CIRCUMSTANCES WILL DANIOLA BE LIABLE FOR THE RESULTS OF USE OR
				MISUSE BY CUSTOMER OR ITS AUTHORIZED USERS OF THE SERVICES, INCLUDING
				ANY USE CONTRARY TO APPLICABLE LAW OR CONTRARY TO THE TERMS AND
				CONDITIONS OF THESE TERMS OF SERVICE. LIMITATION OF LIABILITY. IN NO
				EVENT WILL DANIOLA BE LIABLE UNDER OR IN CONNECTION WITH THESE TERMS OF
				SERVICE UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF
				CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE,
				FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL,
				AGGRAVATED, OR PUNITIVE DAMAGES. WITHOUT LIMITATION, DANIOLA SHALL HAVE
				NO LIABILITY TO CUSTOMER FOR (A) ECONOMIC LOSS, (B) INCREASED COSTS, (C)
				DIMINUTION IN VALUE, (D) LOST BUSINESS, PRODUCTION, REVENUES, OR
				PROFITS, (E) LOSS OF GOODWILL OR REPUTATION, (F) LOSS OF USE OF, OR
				INABILITY TO USE DATA OR SERVICES, (G) DOWNTIME, (H) INTERRUPTION OF, OR
				DELAY OF CUSTOMER'S BUSINESS, (I) OR LOSS OF DATA, OR BREACH OF DATA OR
				SYSTEM SECURITY; OR (J) COST OF REPLACEMENT GOODS OR SERVICES, IN EACH
				CASE REGARDLESS OF WHETHER DANIOLA WAS ADVISED OF THE POSSIBILITY OF
				SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE
				FORESEEABLE. CAP ON DAMAGES. IN NO EVENT WILL DANIOLA'S AGGREGATE
				LIABILITY ARISING OUT OF OR RELATED TO THE SERVICES OR THESE TERMS OF
				SERVICE, OR OTHERWISE, EXCEED THE TOTAL AMOUNTS PAID TO DANIOLA FOR OR
				IN CONSIDERATION OF THE SERVICES, OR OTHERWISE UNDER THESE TERMS OF 19
				SERVICE IN THE THREE-MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO THE
				CLAIM. Infringement of Third-Party Rights. If a Claim for infringement,
				violation, or misappropriation of the Intellectual Property Rights of a
				Third-Party Person is brought or threatened as a result of Customer's
				use of the Services, Daniola may, at its option and expense, either
				procure for Customer the right to continue using the Services, or modify
				the Services so that they become non-infringing without loss of
				functionality. If neither of the foregoing alternatives is reasonably
				available, Daniola may, at its sole discretion, discontinue the Services
				and/or terminate these Terms of Service, and refund to Customer any
				pre-paid and unused portion of the Fees paid by Customer in respect of
				use of the Services. The foregoing states the entire obligations of
				Daniola with respect to any infringement, violation, or misappropriation
				of Intellectual Property Rights of any Third-Party Person. Force
				Majeure. Except for any obligation to make payments, any delay or
				failure of either Party to perform its obligations under these Terms of
				Services shall be excused if, and to the extent, that the delay or
				failure is caused by an event or occurrence beyond the reasonable
				control of the Party and without its fault or negligence, such as, by
				way of example and not by way of limitation, acts of God, action by any
				Governmental Authority (whether valid or invalid), fires, flood, wind
				storms, explosions, riots, natural disasters, wars, terrorist acts,
				sabotage, labour problems (including lock-outs, strikes and slowdowns),
				or court order or injunction; provided that written notice of delay
				(including anticipated duration of the delay) shall be given by the
				affected Party to the other Party promptly upon the affected Party first
				becoming aware of such event. In the event that the force majeure event
				lasts for seven days or longer, either Party shall have the option to
				terminate these Terms of Service upon written notice to the other
				without liability.
			</div>
		</div>
	);
};

export default Terms;
