/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import Sidebar from "./sidebar/sidebar";
import SideBar from "./sidebar/sidebarResponsive";
import Navbar from "./navbar";
import Breadcrumb from "./breadcrumb";
import { getPermissions } from "shared/utility";
import Footer from "./footer";
import {
  AreaSelectIcon,
  CogIcon,
  DiamondIcon,
  HeadsetIcon,
  PieChartIcon,
  Money,
  ShopIcon,
} from "@ui/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SignInUrl } from "@config/app-url";

import { useSelector, useDispatch } from "react-redux";
import CampaignIcon from "@mui/icons-material/Campaign";
import { fetchAllPermissions } from "@slices/roles-permissions-slice";

var MENU_ITEMS = [];

const LayoutVendors = ({ children }) => {
  const roles = useSelector((state) => state.roles_permissions);
  const dispatch = useDispatch();
  const profiles = JSON.parse(localStorage.getItem("account"));
  useEffect(() => {
    dispatch(fetchAllPermissions({id:profiles[0]?.role?._id,role:"user"}));
  }, [dispatch]);

  getPermissions(roles);

  function checkPermission(page) {
    let Permission = "read & write";

    roles?.all_permissions?.forEach((x) => {
      if (x.resource.name == page) {
        if (
          x.method.add === true &&
          x.method.delete === true &&
          x.method.edit === true &&
          x.method.search === true &&
          x.method.view === true
        ) {
          Permission = "read & write";
        } else if (
          x.method.add === false &&
          x.method.delete === false &&
          x.method.edit === false &&
          x.method.search === false &&
          x.method.view === true
        ) {
          Permission = "read";
        } else {
          Permission = "restricted";
        }
      }
    });

    if (page == "Member") {
      if (profiles[0]?.parent_id) {
        Permission = "restricted";
      }
    }

    return Permission;
  }
  function checkSubListPermission(page, action) {
    let Permission = "read & write";

    roles?.all_permissions?.forEach((x) => {
      if (x.resource.name == page) {
        x.actions.forEach((y) => {
          if (y?.name == action) {
            if (y?.allowed == true) {
              Permission = "read & write";
            } else {
              Permission = "restricted";
            }
          }
        });
      }
    });

    return Permission;
  }

  MENU_ITEMS = [
    {
      label: "Dashboard",
      url: "/vendor/dashboard",
      icon: <PieChartIcon />,
    },
    {
      label: "My offerings",
      url: "/vendor/offerings",
      icon: <DiamondIcon />,
      permission: checkPermission("Offerings"),
    },
    {
      label: "Service requests",
      url: "/vendor/requests",
      icon: <CampaignIcon />,
      permission: checkPermission("Servicerequest"),
    },
    {
      label: "Interactions",
      url: "/vendor/vendor-interaction",
      icon: <AreaSelectIcon />,
      hasDot: true,
      permission: checkPermission("Interactions"),
      subList: [
        {
          label: "Offers",
          path: "/vendor/interactions/offers",
          permission: checkSubListPermission("Interactions", "Offer"),
        },
        {
          label: "Projects",
          path: "/vendor/interactions/projects",
          permission: checkSubListPermission("Interactions", "Project"),
        },
      ],
    },
    {
      label: "Teams",
      icon: <ShopIcon />,
      permission: checkPermission("Member"),
      subList: [
        {
          label: "Team Roles",
          path: "/vendor/teams/role",
          permission: checkSubListPermission("Member", "Role"),
        },
        {
          label: "Team Members",
          path: "/vendor/teams/member",
          permission: checkSubListPermission("Member", "Member"),
        },
      ],
    },
    {
      label: "Vendors",
      icon: <ShopIcon />,
      permission: checkPermission("Vendors"),
      subList: [
        {
          label: "Find a vendor",
          path: "/vendor/vendors/find_a_vendor",
          permission: checkSubListPermission("Vendors", "All"),
        },
        {
          label: "Request a service",
          path: "/vendor/vendors/request_a_service",
          permission: checkSubListPermission("Vendors", "Request"),
        },
      ],
    },
    {
      label: "Subscription",
      url: "/vendor/subscription",
      icon: <Money />,
      permission: checkPermission("Subscription"),
    },

    // {
    //   label: "Messages",
    //   url: "/vendor/messages",
    //   icon: <MailOutlineIcon />,
    //   permission: checkPermission("Messages"),
    // },
    {
      label: "Support",
      url: "/vendor/support",
      icon: <HeadsetIcon />,
      permission: checkPermission("Support"),
    },
    {
      label: "Settings",
      url: "/vendor/settings",
      icon: <CogIcon />,
      permission: checkPermission("Settings"),
    },
  ];
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setloading(true);
    if (!user) {
      return navigate(SignInUrl, { replace: true });
    }
    setloading(false);
  }, [navigate]);

  if (loading) {
    return null;
  }

  return (
    <div className="lg:grid lg:grid-cols-6">
      {/*side bar  */}
      <div
        className="7
        col-span-0 hidden xl:grid xl:col-span-1 h-full"
      >
        <Sidebar items={MENU_ITEMS} sliceRange={6} />
      </div>
      {/* main content */}
      <div className="col-span-6 lg:col-span-5 relative">
        <Navbar userType="Vendor" />
        {/* responsiveness */}
        <div className="lg:hidden">
          <SideBar items={MENU_ITEMS} sliceRange={6} />
        </div>
        <Breadcrumb />
        <main className="px-4 lg:px-4 lg:px-8 min-h-screen">{children}</main>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LayoutVendors;
