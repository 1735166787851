/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "@config/url";
import { toast } from "react-toastify";
import axios from "@config/axios-instance";

const GET = "get";
const DELETE = "delete";
const POST = "post";

const initialState = {
  activeStep: 0,

  loading: false,
  error: null,
  isError: false,
  isSuccess: false,
  projects: {
    counts: {},
    data: [],
    pagination: null,
    search: "",
    filter: "",
  },
  project: null,
  offers: {
    counts: {},
    data: [],
    pagination: null,
    search: "",
    filter: "",
  },
  offer: null,
  interests: {
    counts: {},
    data: [],
    pagination: null,
    search: "",
    filter: "",
  },
  interest: null,
  interestSub: null,
};

export const vendorConfig = (method, url, data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      "Authorization": "bearer "+ token
    },
    data,
  };
};

export const getProjects = createAsyncThunk(
  "vendor/getProjects",
  async (name, thunkAPI) => {
    try {
      // thunkAPI.dispatch(openModal());
      const resp = await axios.get(API.fetchProjects);
      return resp;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getProject = createAsyncThunk(
  "vendor/getProject",
  async (id, thunkAPI) => {
    try {
      // thunkAPI.dispatch(openModal());
      const resp = await axios.get(API.fetchSingleProject(id));
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const fetchPaginatedProjects = createAsyncThunk(
  "vendor/fetchPaginatedProjects",
  async (payload, { rejectWithValue }) => {

    try {
      const response = await axios.get(
        API.paginatedVendorProjects(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOffers = createAsyncThunk(
  "miner/getOffers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getVendorOffers);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPaginatedOffers = createAsyncThunk(
  "miner/fetchPaginatedOffers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.paginatedVendorOffers(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOffer = createAsyncThunk(
  "transaction/getOffer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getVendorOffer(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getInterests = createAsyncThunk(
  "miner/getInterests",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getVendorInterests(payload.id,payload.status));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getInterest = createAsyncThunk(
  "miner/getInterest",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getVendorInterest(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const awardInterest = createAsyncThunk(
  "miner/selectVendor",
  async (id, { rejectWithValue, getState }) => {
    try {
      const {
        vendorInteractions: { interestSub },
      } = getState();
      const payload = { status: "awarded" };
      const resp = await axios.post(
        API.vendorAwardInterest(interestSub),
        payload
      );
      toast.success(
        "Your selection has been sent and the vendor will be notified to connect."
      );
      return resp.data;
    } catch (error) {
      toast.error("An error occured while selecting the vendor.");
      return rejectWithValue("something went wrong");
    }
  }
);

export const connectToLead = createAsyncThunk(
  "vendor/connectToLead",
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await axios.post(API.connectToLead(payload.id));
      return resp;
    } catch (error) {
      toast.error("Error connecting to lead.");
      return rejectWithValue("something went wrong");
    }
  }
);

const interactionsSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setProjectsSearchValue: (state, action) => {
      state.projects.search = action.payload;
    },
    setVendorOffersSearchValue: (state, action) => {
      state.offers.search = action.payload;
    },
    setAwardedInterestSub: (state, action) => {
      state.interestSub = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjects.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.projects.data = payload.data.projects;
        state.projects.counts = payload.data.counts;
        state.projects.pagination = payload.data.pagination;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getProjects.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchPaginatedProjects.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchPaginatedProjects.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.projects.data = payload.data.projects;
        state.projects.counts = payload.data.counts;
        state.projects.pagination = payload.data.pagination;
      })
      .addCase(fetchPaginatedProjects.rejected, (state, action) => {
        state.error = true;
        // toast.error(payload.message);
      })
      .addCase(getProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProject.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.project)
          state.project = action.payload.project[0];
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getProject.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(connectToLead.pending, (state) => {
        state.loading = true;
      })
      .addCase(connectToLead.fulfilled, (state, action) => {
        state.loading = false;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(connectToLead.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getOffers.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getOffers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.offers.data = payload.data.offers;
        state.offers.counts = payload.data.counts;
        state.offers.pagination = payload.data.pagination;
        //toast.success(payload.data.message);
      })
      .addCase(getOffers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
        toast.error(payload.message);
      }) // eslint-disable-next-line no-unused-vars
      .addCase(fetchPaginatedOffers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchPaginatedOffers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.offers.data = payload.data.offers;
        state.offers.counts = payload.data.counts;
        state.offers.pagination = payload.data.pagination;
      })
      .addCase(fetchPaginatedOffers.rejected, (state, action) => {
        state.error = true;
        // toast.error(payload.message);
      })
      // fetch transaction data
      .addCase(getOffer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOffer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = false;
        state.offer = payload.data.offer;
        //  toast.success(payload.data.message);
      })
      .addCase(getOffer.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
        toast.error(payload.message);
      })
      .addCase(getInterests.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getInterests.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.interests.data = payload.data.interests;
        // state.interests.counts = payload.data.counts;
        state.interests.pagination = payload.data.pagination;
        //toast.success(payload.data.message);
      })
      .addCase(getInterests.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
        toast.error(payload.message);
      }) // eslint-disable-next-line no-unused-vars
      .addCase(getInterest.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInterest.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = false;
        state.interest = payload.data.interest;
        //  toast.success(payload.data.message);
      })
      .addCase(getInterest.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
        toast.error(payload.message);
      });
  },
});

export const {
  setProjectsSearchValue,
  setVendorOffersSearchValue,
  setAwardedInterestSub,
} = interactionsSlice.actions;

export default interactionsSlice.reducer;
