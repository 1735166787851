/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import { truncate } from "shared/utility";

const DeleteOffering = ({offering}) => {
  // const { deletedOfferingSub } = useSelector((store) => store.vendorOfferings);
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="text-center mt-9">
        <h3 className="text-heading text-2xl mb-4">Delete offering.</h3>
        <span className="text-ts font-light">
          You are about to delete this offering{" "}
          {offering}. Kindly confirm this action
        </span>
      </div>
    </div>
  );
};

export default DeleteOffering;
