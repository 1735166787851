/* eslint-disable no-unused-vars */
import { Input } from "components/ui/Inputs";
import React, { useState } from "react";
import { Select } from "@ui";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import Skeleton from "@components/auctions/Skeleton";
import { PhoneInputFormatter } from "@shared/formatter";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect } from "react";
import { useAuth } from "@hooks";
import { fetchRoles, getTeamMember } from "@slices/roles-permissions-slice";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik } from "formik";
import Button from "components/ui/Button";
import { toast } from "react-toastify";
import { updateMember } from "@slices/roles-permissions-slice";
import { useParams } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

function EditMemberOfftaker() {
  const profiles = useAuth();
  const navigate = useNavigate();
  let { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [selected, setSelected] = useState();
  const roles = useSelector((state) => state.roles_permissions);
  const [state, setState] = useState();
  const [number, setNumber] = useState();
  useEffect(() => {
    if (roles.select_member[0]?.docs?.length > 0) {
      setState(roles.select_member[0]?.docs[0]);
      setNumber(roles.select_member[0]?.docs[0]?.phone);
      setSelected(roles.select_member[0]?.docs[0]?.status);
    }
  }, [roles]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRoles({ id: profiles?.account?._id, page: 1, limit: 100 }));
    dispatch(getTeamMember({ id: profiles?.account?._id, _id: id }));
  }, [dispatch]);

  /** Format phone then set to state */
  const getFormattedNumber = (value, data) => {
    const input = PhoneInputFormatter(value, data);
    setNumber({
      ...state,
      code: input.phone_code,
      number: input.phone_number,
      iso_code: input.iso_code,
    });
  };

  const [valuese, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
    terms: true,
    notifications: false,
  });

  const handleChangee = (prop) => (event) => {
    setValues({ ...valuese, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...valuese,
      showPassword: !valuese.showPassword,
    });
  };

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const handleSelectChange = (e, { name, _id }) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: e._id,
      };
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const createMember = async () => {
    setLoader(true);
    let formData = {
      name: state.name,
      email: state.email,
      phone_code: number.code,
      phone_number: number.number,
      status: selected,
      // iso_code: state.iso_code,
      password: valuese.password,
      role: state.role?._id ? state.role._id : state.role,
    };
    try {
      const response = await dispatch(
        updateMember({ id: state._id, data: formData })
      );
      if (response.payload.status === 200) {
        // window.location.reload();
        navigate("/off-taker/teams/member");
        toast.success("Member Updated");
      } else {
        setLoader(false);
        toast.error(response.payload.msg);
        console.log("error from then", response);
      }
    } catch (error) {
      setLoader(false);
      toast.error(error);
      console.log("error", error);
    }
  };
  return (
    <>
      {loader ? (
        <>
          {Array(1)
            .fill(null)
            .map((_, index) => (
              <Skeleton key={index} />
            ))}
        </>
      ) : (
        <div className="flex justify-center">
          <div className="w-1/2 bg-white p-6 rounded-sm">
            <h1 className="mb-5">Edit Team member</h1>
            <Input
              label="Full name"
              name="name"
              defaultValue={state?.name || ""}
              onChange={handleInputChange}
            />
            <Input
              label="Business email address"
              name="email"
              defaultValue={state?.email || ""}
              onChange={handleInputChange}
            />
            <div className="password_2 block mt-4 relative">
              <div className="mt-4">
                <label className="block text-sm mb-1 text-lightgray">
                  {" "}
                  Phone Number{" "}
                </label>
                <PhoneInput
                  isValid={(value) => {
                    if (value.length < 7 && value.length > 1) {
                      return "* valid phone number is required";
                    } else if (value.length < 7) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  type="text"
                  country="us"
                  value={number?.code + number?.number || ""}
                  onChange={(value, data) => getFormattedNumber(value, data)}
                  inputProps={{ name: "phone", required: true }}
                  masks={{ fr: "(...) ..-..-..", at: "(....) ...-...." }}
                />
              </div>
              {/* password */}
              <div className="mt-6 w-full">
                <label className="block text-sm  mb-2 text-lightgray">
                  Password
                </label>

                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  {/* <InputLabel htmlFor="outlined-adornment-password">
    Password
  </InputLabel> */}
                  <OutlinedInput
                    sx={{
                      width: "100%",
                      backgroundColor: "#1A38601A",
                    }}
                    id="outlined-adornment-password"
                    type={valuese.showPassword ? "text" : "password"}
                    value={valuese.password}
                    onChange={handleChangee("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {valuese.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </div>
            </div>
            <div className="mt-3 w-full">
              <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                Category
              </p>
              {/* <SelectField
          items={[
            "Intern",
            "Accountant",
            "MineOfficer",
            "Technical Officer",
          ]}
          name="project_category"
          value={values.role}
          onChange={handleChangee}
        /> */}
              <Select
                name="role"
                options={roles.docs}
                value={state?.role?.name}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option._id}
                onChange={handleSelectChange}
                placeholder={state?.roles[0]?.name}
              />
            </div>
            <div className="mt-6 w-full">
              <p className="flex-grow-0 text-[14px] flex-shrink-0 text-vendorsecondary mb-1 text-left">
                Profile Status
              </p>
              <div className="">
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <FormControlLabel
                    value="Active"
                    style={{ paddingLeft: "20px" }}
                    control={
                      <Radio
                        color="primary"
                        checked={selected === "Active"}
                        onChange={handleChange}
                      />
                    }
                    label="Active"
                  />
                  <FormControlLabel
                    value="Inactive"
                    control={
                      <Radio
                        color="primary"
                        onChange={handleChange}
                        checked={selected === "Inactive"}
                      />
                    }
                    label="Inactive"
                  />
                </RadioGroup>
              </div>
            </div>
            <div className="mt-4">
              <Button
                onClick={() => {
                  createMember();
                  //   setOpen(!open);
                }}
                fullWidth={true}
              >
                Update account
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EditMemberOfftaker;
