/* eslint-disable no-undef */
/**
 * /* eslint-disable no-undef
 *
 * @format
 */

/** @format */
const { REACT_APP_BACKEND_URL } = process.env;

const baseURL = REACT_APP_BACKEND_URL;

// Shipment & Carrier
export const postShipment = baseURL + "/shipment";
export const getShipmentActive =
  baseURL + "/tracking-shipment?shipment_status=transit";
export const getShipmentArchive =
  baseURL + "/tracking-shipment?shipment_status=delivered";
export const getCarrier = baseURL + "/carrier?is_active=true";
export const getCarrierByID = (id) => baseURL + `/carrier/${id}`;
export const deleteCarrierByID = (id) => baseURL + `/carrier/${id}`;
export const updateCarrierByID = (id) => baseURL + `/carrier/${id}`;
export const postCarrier = baseURL + "/carrier";
export const getTrackingDetails = (id, _id) =>
  baseURL + `/tracking?trackingId=${id}&transactionId=${_id}`;

// Wallet
export const getWalletLog = baseURL + "/wallet";
export const fundWallet = baseURL + "/wallet/funding";

// Subscription
export const getSubscription = baseURL + "/subscription";
export const renewSubscription = baseURL + "/subscription/renew";
export const cancelSubscription = baseURL + "/subscription/cancel";

// Roles & Permissions
export const getRoleByProfileID = (id, page, limit) =>
  baseURL + `/member_role?account_id=${id}&$page=${page}&$limit=${limit}`;
export const getTeamMemberByID = (id, _id) =>
  baseURL + `/member_role?account_id=${id}&_id=${_id}`;
export const getTeamMmember = (id, _id) =>
  baseURL + `/member?account_id=${id}&_id=${_id}`;
export const searchRoles = (search, id) =>
  baseURL + `/member_role?search=${search}&account_id=${id}`;
export const searchMembers = (search, id) =>
  baseURL + `/member?search=${search}&account_id=${id}`;
export const getMembers = (id, page, limit) =>
  baseURL + `/member?account_id=${id}&$page=${page}&$limit=${limit}`;
export const getPermission = (id) => baseURL + `/permission?role=${id}&type=user`;
export const getPermissions = (id,role) => baseURL + `/permission?role=${id}&type=${role}`;
export const getAdminpermissions = (id) => baseURL + `/permission?role=${id}&type=admin`;
export const updatePermission = (id) => baseURL + `/permission/${id}`;

export const addRole = baseURL + "/member_role";
export const AddMember = baseURL + "/member";
export const Updatemember = (id) => baseURL + `/member/${id}`;
export const getResources = baseURL + "/resource?type=user";
export const DeleteMember = (id) => baseURL + `/member/${id}`;
export const deleteRoles = baseURL + "/member_role";

export const postSignup = baseURL + "/user_auth";
export const postRendOtp = baseURL + "/resend_otp";
export const postVerifyOtp = baseURL + "/otp_verify";
export const getCountries = baseURL + "/onboard";
export const getStates = baseURL + "/states";
export const getCities = baseURL + "/cities";
export const getProducts = baseURL + "/products";
export const getMineTypes = baseURL + "/mine_types";
export const uploadFile = baseURL + "/file_upload";

export const postSignIn = baseURL + "/auth/login";
export const postForgotPassword = baseURL + "/auth/forgot-password";
export const changePassword = baseURL + "/auth/change-password";

export const postUploadFile = baseURL + "/upload/file";

export const getRoles = baseURL + "/role";
export const postCreateProfile = (role,user_id) => baseURL + `/account?account=true&role=${role}&user=${user_id}`;
export const fetchProfiles = baseURL + "/profile";

export const getMinerVefiricationData = baseURL + "/profile/verification/miner";
export const getMineralData = baseURL + "/mineral?is_active=true";
export const getAdminMineralData = baseURL + "/mineral";
export const getsdgGoalData = baseURL + "/sdggoals";
export const getIndustry = baseURL + "/industry";
export const getMineralType = baseURL + "/mine-type";
export const postMinerVefiricationData =
  baseURL + "/profile/verification/miner";
export const createAccount = (id) => baseURL + `/account?id=${id}`;

export const getOffTakerVerificationData =
  baseURL + "/profile/verification/offtaker";
export const postOffTakerVefiricationData =
  baseURL + "/profile/verification/offtaker";

export const getVendorVerificationData =
  baseURL + "/profile/verification/vendor";
export const postVendorVerificationData =
  baseURL + "/profile/verification/vendor";
export const getVendorSubscription = baseURL + "/general";

/***************** MINER URLS STARTS HERE ******************************/

export const fetchDashboardActivities = "/activitylog";
export const fetchDashboardNotifications = "/miner/dashboard/notifications";
export const fetchDashboardRecordsCard = "/miner/dashboard/record-card-counts";
export const fetchMinerAnalyticsChart = "/miner/dashboard/analytics";
export const fetchMinerInventoryChart = "/miner/dashboard/inventories";

export const fetchMineData = "/miner/mines/create";
export const fetchMines = "/mine";
export const fetchActiveMines = "/mine?status=Active";
export const paginatedMine = (page, per_page, search, filter) =>
  `/mine?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const searchMine = (searchParam) =>
  `/miner/mines/?mine_name=${searchParam}`;

export const getMinerInventory = "/miner/inventory/create";
export const paginatedInventory = (page, per_page, search, filter) =>
  `/miner/inventory/create?$page=${page}&$limit=${per_page}&search=${search}&filter=${filter}`;
export const storeMinerInventory = "/miner/inventory/";
export const searchInventory = (searchParam) =>
  `/miner/inventory/?product_name=${searchParam}`;

// miners messaging urls
export const initializeMinersMessageUrl = baseURL + "/miner/message/initialize";
export const minersMessagesUrl = baseURL + "/miner/message/chat";

// Directory Search
export const searchDirectory = (search, type) =>
  `${baseURL}/directory_search?search=${search}&type=${type}`;
export const getDirectory = (page, limit) =>
  `${baseURL}/administrator/directory?$page=${page}&$limit=${limit}`;
export const searchAllDirectory = (search) =>
  `${baseURL}/administrator/directory?search=${search}`;
export const postDirectory = baseURL + `/administrator/directory`;
export const getMinerInventoryLog = "/miner/inventory";
export const paginatedInventoryLog = (page, per_page, search, filter) =>
  `/miner/inventory?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const postCreateMine = "/mine";
export const getAuctions = "/miner/auction";
export const searchAuctions = (search) => `/miner/auction?search=${search}`;
export const getSingleAuctions = (id) => `/miner/auction/${id}`;
export const minerFetchVendors = baseURL + "/miner/vendor";
export const searchminerVendors = (search,country) => baseURL + `/miner/vendor?search=${search}&country=${country}`;
export const minerFetchVendor = (id) => baseURL + `/miner/vendor/${id}`;
export const minerContactVendor = (id) =>
  baseURL + `/miner/vendor/?account_id=${id}`;
export const minerRequestService = baseURL + `/miner/vendor`;
export const minerFetchCountries = baseURL + "/miner/vendor/create";
export const fetchAllCountries = baseURL + "/country?$limit=300";
export const fetchServiceOptions = baseURL + "/general";
export const updateServiceOptions = (id) => baseURL + `/general/${id}`;
export const fetchInvestmentOffers = (page,limit) => baseURL + `/miner/investor/offering?$page=${page}&$limit=${limit}`;
export const getInvestmentOffers = baseURL + "/miner/investor/offering";
export const getIndicateInterestData =
  baseURL + "/miner/investor/offering/create/data";
export const getSeekFundingData =
  baseURL + "/miner/investor/funding/create/data";
export const postSeekFundingData = baseURL + "/miner/investor/funding";
export const getMinerInvestments = baseURL + "/miner/interactions/investment";
export const paginatedMinerInvestments = (page, per_page, search, filter) =>
  `/miner/interactions/investment?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const getMinerFundings = baseURL + "/miner/investor/funding";
export const paginatedMinerFundings = (page, per_page, search, filter) =>
  `/miner/investor/funding?$page=${page}&$limit=${per_page}&search=${search}&filter=${filter}`;
export const getFundingInterests = (id) =>
  baseURL + `/miner/investor/funding_interest/?funding_id=${id}`;
export const getFundingInterestDetails = (id) =>
  baseURL + `/miner/investor/funding_interest/${id}`;
export const awardFundingInterest = (id) =>
  baseURL + `/miner/investor/funding_interest/?interest_id=${id}`;
export const postDemandpayment = (id) => `${baseURL}/miner/demands/pay?demand_id=${id}`;
export const getMinerDemand = (id) => `${baseURL}/miner/demands/${id}`;
export const postMinerDemandSupply = (id) => `${baseURL}/miner/demands/?demand_id=${id}`;
export const getMinerDemands = (
  page,
  limit,
  search = "",
  filter = "all"
) =>
  `${baseURL}/miner/demands?$page=${page}&$limit=${limit}&search=${search}&filter=${filter}`;
export const searchMinerDemands = (search) => `${baseURL}/miner/demands?search=${search}`;
export const postTicket = (role) => baseURL + `/${role}/ticket`;
/***************** MINER URL ENDS HERE ******************************/

// Offtaker
export const offTakerAuctions = baseURL + "/offtaker/auction";
export const searchOfftakerAuctions = (search) => baseURL + `/offtaker/auction/?search=${search}`;
export const singleoffTakerAuctions = (id) =>
  baseURL + `/offtaker/auction/${id}`;
export const placeBid = (id) => baseURL + `/offtaker/auction/?productId=${id}`;
export const offTakerTransactions =
  baseURL + "/offtaker/interactions/transaction?per_page=2";
export const paginatedOfftakerTransactions = (page, per_page, search, filter) =>
  `/offtaker/interactions/transaction?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const getSingleOffTakerTransaction = (id) =>
  baseURL + `/offtaker/interactions/transaction/${id}`;
export const fetchOffTakerVendors = baseURL + "/offtaker/vendor";
export const searchOfftakerVendors = (search,country) => baseURL + `/offtaker/vendor?search=${search}&country=${country}`;
export const fetchOffTakerVendor = (id) => baseURL + `/offtaker/vendor/${id}`;
export const contactOffTakerVendor = (id) =>
  baseURL + `/offtaker/vendor?account_id=${id}`;
export const requestOffTakerService = baseURL + `/offtaker/vendor`;
export const fetchOfftakerCountries = (id) =>
  baseURL + `/offtaker/vendors/contact/${id}`;
export const offTakerBids = baseURL + "/offtaker/interactions/bids";
export const paginatedOfftakerBids = (page, per_page, search, filter) =>
  `/offtaker/interactions/bids?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const auctionPayment = (id) =>
  baseURL + `/offtaker/interactions/transaction/?transaction_id=${id}`;
export const rateVendorFromOfftaker = (id) => `offtaker/vendor/rating?account_id=${id}`;
export const getOfftakerProjects = baseURL + "/offtaker/interactions/project";
export const paginatedOfftakerProjects = (page, per_page, search, filter) =>
  `/offtaker/interactions/project?$page=${page}$limit=${per_page}&search=${search}&status=${filter}`;
export const getOfftakerProject = (id) =>
  baseURL + `/offtaker/interactions/project/${id}`;
export const getOfftakerOffers = baseURL + "/offtaker/interactions/offer";
export const paginatedOfftakerOffers = (page, per_page, search, filter) =>
  `/offtaker/interactions/offer?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const getOfftakerOffer = (id) =>
  baseURL + `/offtaker/interactions/offer/${id}`;

export const getOfftakerInterests = (id,status) =>
  baseURL + `/offtaker/interactions/interest?offer_id=${id}&status=${status}`;
export const getOfftakerInterest = (id) =>
  baseURL + `/offtaker/interactions/offer/${id}/interest`;
export const offtakerAwardInterest = (id) =>
  baseURL + `/offtaker/interactions/interest?interest_id=${id}`;

export const postOffTakerDemandData = baseURL + "/offtaker/demands";
export const fetchDemandsData = baseURL + "/offtaker/demands";
export const fetchAllDemandsData = baseURL + "/offtaker/demands/create";
export const fetchPaginatedDemandsData = (page, per_page, search, filter) =>
  baseURL +
  `/offtaker/demands?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
  export const getSingleOffTakerDemandDetails = (id) =>
  baseURL + `/offtaker/demands/${id}`; 
export const getSingleOffTakerDemand = (id,search) =>
  baseURL + `/offtaker/demands-interest/?demand_id=${id}&status=${search}`;
export const getSingleOffTakerDemandInterest = (id) =>
  baseURL + `/offtaker/demands-interest/${id}`;
export const postSingleOffTakerDemandInterest = (id) => 
baseURL + `/offtaker/demands-interest/?interest_id=${id}`;
export const transactionRatings = (id, type = "offtaker") =>
  baseURL + `/${type}/rating/?transaction_id=${id}`;

export const fetchOfftakerDashboardRecordsCard =
  "/offtaker/dashboard/record-card-counts";
export const fetchOfftakerDashboardActivities =
  "/offtaker/dashboard/activities";
export const fetchOfftakerAnalyticsChart = "/offtaker/dashboard/analytics";

//Vendors
export const vendorFetchDashboardRecordsCard =
  "/vendor/dashboard/record-card-counts";
export const vendorFetchDashboardActivities = "/activitylog";
export const vendorFetchDashboardNotifications =
  "/vendor/dashboard/notifications";
export const vendorFetchDashboardAnalyticsChart = "/vendor/dashboard/analytics";

export const fetchVendors = baseURL + "/vendor/vendor";
export const searchVendor = (search,country) => baseURL + `/vendor/vendor?search=${search}&country=${country}`;
export const fetchVendor = (id) => baseURL + `/vendor/vendor/${id}`;
export const contactVendor = (id) => baseURL + `/vendor/vendor?account_id=${id}`;
export const requestService = baseURL + `/vendor/vendor`;
export const fetchCountries = baseURL + "/vendor/vendor/create";
export const fetchOfferings = baseURL + "/vendor/offerings";
export const paginatedOfferings = (page, per_page, search, filter) =>
  `/vendor/offerings?$page=${page}&$limit=${per_page}&search=${search}&filter=${filter}`;
export const addOffering = baseURL + "/vendor/offerings";
export const fetchOffering = (id) => baseURL + `/vendor/offerings/${id}`;
export const fetchTransactions = baseURL + "/vendor/interactions/transaction";
export const fetchTransaction = (id) =>
  baseURL + `/vendor/interactions/transaction/${id}`;
export const fetchServiceRequests = baseURL + "/vendor/servicerequest/";
export const searchServiceRequests = (search,country) => baseURL + `/vendor/servicerequest?search=${search}&country=${country}`;
export const fetchServiceRequest = (id) =>
  baseURL + `/vendor/servicerequest/${id}`;
export const modifyOffering = (id) => baseURL + `/vendor/offerings/${id}`;
export const deleteOffering = (id) => baseURL + `/vendor/offerings/${id}`;
export const applyToRequest = (id) =>
  baseURL + `/vendor/servicerequest/?servicerequest_id=${id}`;
export const fetchProjects = baseURL + "/vendor/interactions/project";
export const paginatedVendorProjects = (page, per_page, search, filter) =>
  `/vendor/interactions/project?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const fetchSingleProject = (id) =>
  baseURL + `/vendor/interactions/project/${id}`;
export const getVendorOffers = baseURL + "/vendor/interactions/offer";
export const paginatedVendorOffers = (page, per_page, search, filter) =>
  `/vendor/interactions/offer?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const getVendorOffer = (id) =>
  baseURL + `/vendor/interactions/offer/${id}`;

export const getVendorInterests = (id,status) =>
  baseURL + `/vendor/interactions/interest?offer_id=${id}&status=${status}`;
export const getVendorInterest = (id) =>
  baseURL + `/vendor/interactions/offer/${id}/interest`;
export const vendorAwardInterest = (id) =>
  baseURL + `/vendor/interactions/interest?interest_id=${id}`;
export const connectToLead = (id) =>
  baseURL + `/vendor/interactions/project?project_id=${id}`;

// investment proposal
export const fetchAllInvestmentProposals =
  baseURL + "/offtaker/invest/proposal";
export const postProposalInterest = (id)=> baseURL + `offtaker/invest/proposal?proposal_id=${id}`;
export const getCreateProspectData =
  baseURL + "/offtaker/invest/prospect/create/data";
export const postFindProspectsData = baseURL + "/offtaker/invest/prospect";
export const getProspectInterests = (id,status) =>
  baseURL + `/offtaker/invest/interest?prospect_id=${id}&status=${status}`;
export const getProspectInterestDetail = (id) =>
  baseURL + `/offtaker/invest/interest/${id}`;
export const acceptProspectInterest = (id) =>
  baseURL + `/offtaker/invest/interest?interest_id=${id}`;
  export const archiveProspectInterest = (id) =>
  baseURL + `/offtaker/invest/interest/${id}`;
export const postIndicateInterest = baseURL + "/investment/interest";
export const getAllProspects = baseURL + "/offtaker/invest/prospect";
export const paginatedProspects = (page, per_page, search, filter) =>
  `/offtaker/invest/prospect?$page=${page}&$limit=${per_page}&search=${search}&filter=${filter}`;
export const getAllInvestments = (page,limit,filter,search) => baseURL + `/offtaker/interactions/investment?$page=${page}&$limit=${limit}&status=${filter}&search=${search}`;
export const getAllInvestmentsById = baseURL + "/offtaker/interactions/investment";

// settings
export const getProfile = baseURL + "/setting/profile";
export const updateProfile = (id) => baseURL + `/setting/profile/${id}`;
export const uploadImage = baseURL + "/setting/profile?image=true";
export const removeImage = baseURL + "/setting/profile?image=false";
export const changeUserPassword = baseURL + "/settings/change-password";
export const getNotifications = baseURL + "/setting/notification";
export const deleteAccount = (id) => baseURL + `/setting/profile/${id}`;

// Support
export const getSupport = (role) => baseURL + `/support?type=${role}`;
export const getSupportBySlug = (slug) => baseURL + `/support/${slug}`;

// Ticket
export const createTicket = (role) => baseURL + `/${role}/ticket`;

// Notifications
export const fetchNotifications = (limit) => baseURL + `/notification?$limit=${limit}`;
export const readNotification = (id) => baseURL + `/notification/read?notificationId=${id}`;
export const readAllNotifications = baseURL + "/notification/read_all";

//fetchAuthenticated
// export const fetchAuthenticatedUser = baseURL + "/auth/user-data";padmin

//admin
export const getAdminAnalytics = baseURL + "/administrator/dashboard/analytic";
export const getAdminRoles = (id, page, limit) =>
  baseURL +
  `/administrator/member_role?account_id=${id}&$page=${page}&$limit=${limit}`;
export const paginatedAdminMine = (page, per_page, search, filter) =>
  `/administrator/listings/mine?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const getAdminOffers = (filter) =>
  baseURL + `/administrator/listings/offer?status=${filter}`;
export const getAdminOfferByID = (id) =>
  baseURL + `/administrator/listings/offer/${id}`;
export const postAdminArchiveOffers = (id) =>
  baseURL + `/administrator/listings/offer?offer_id=${id}`;
export const getAdminVendors = (filter, search, country) =>
  baseURL +
  `/administrator/listings/vendor?status=${filter}&search=${search}&country=${country}`;
export const postAdminAuctionArchive = (id) =>
  baseURL + `/administrator/listings/auction?product_id=${id}`;
export const postAdminVendorArchive = (id) =>
  baseURL + `/administrator/listings/vendor?account_id=${id}`;
export const getAdminAuctions = (filter) =>
  baseURL + `/administrator/listings/auction?filter=${filter}`;
export const getAdminAuctionByID = (id) =>
  baseURL + `/administrator/listings/auction/${id}`;
export const getAdminVendorByID = (id) =>
  baseURL + `/administrator/listings/vendor/${id}`;
export const fetchAdminMineData =
  baseURL + "/administrator/listings/mine";
export const getMineByID = (id) =>
  baseURL + `/administrator/listings/mine/${id}`;
export const postAdminMines = baseURL + "/administrator/listings/mine";
export const suspendAdminMine = (id) =>
  baseURL + `/administrator/listings/mine_suspend?mine_id=${id}`;
export const updateAdminMines = (id) =>
  baseURL + `/administrator/listings/mine/${id}`;
export const getAdminMines = baseURL + "/administrator/listings/mine";
export const paginatedAdminMinerals = (page, per_page, search, filter) =>
  `/administrator/mineral?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const getAdminMinerals = baseURL + "/administrator/mineral";
export const addAdminMineral = baseURL + "/administrator/mineral";
export const deleteAdminMineral = (id) => baseURL + `/administrator/mineral/${id}`;
export const updateAdminMineral = (id) => baseURL + `/administrator/mineral/${id}`
export const getAdminTariff = baseURL + "/price";
export const postAdminRole = baseURL + "/administrator/member_role";
export const getAdminPermissions = (id) =>
  baseURL + `/permission?role=${id}&type=admin`;
export const updateAdminPermission = (id) => baseURL + `/permission/${id}`;
export const DeleteAdminMember = (id) =>
  baseURL + `/administrator/member/${id}`;
export const deleteAdminRoles = baseURL + '/member_role';
export const getAdminMmember = (id, _id) =>
  baseURL + `/administrator/member?account_id=${id}&_id=${_id}`;
export const getAdminTeam = (page, limit, id) =>
  baseURL +
  `/administrator/member?$page=${page}&$limit=${limit}&account_id=${id}`;
export const getAdminRole = (id, _id) =>
  baseURL + `/administrator/member_role?account_id=${id}&_id=${_id}`;
export const AddAdminMember = baseURL + "/administrator/member";
export const UpdateAdminMember = (id) =>
  baseURL + `/administrator/member/${id}`;
export const updateAdminStatus = (id,status) =>
  baseURL + `/administrator/users/${id}?status=${status}`;
export const sendmessageAdmin = (id) =>
  baseURL + `/administrator/users/${id}`;
export const updateSuspension = (id) => baseURL + `/administrator/suspension/${id}`;
export const deleteAdminuser = (id) => baseURL + `/administrator/users/${id}`;
export const fetchUnverifiedProfile = (page,limit,search) =>  `/administrator/verification?$page=${page}&$limit=${limit}&search=${search}`;
export const postVerifyProfile = (id) =>
  `/administrator/verification/?account_id=${id}`;
export const manageUser = (id) => `/administrator/verification/${id}`;
export const getPayments = (page,limit,search) => baseURL + `/administrator/payment?$page=${page}&$limit=${limit}&search=${search}`;
export const getSuspensions = (page,limit,search) => baseURL + `/administrator/suspension?$page=${page}&$limit=${limit}&search=${search}`;
export const getUsers = (page, limit, search) =>
  baseURL +
  `/administrator/users?$page=${page}&$limit=${limit}&search=${search}`;
export const addUser = baseURL + "/administrator/users";
export const findUser = (id) => `/administrator/users/${id}`;
export const getAdminResources = (type) => baseURL + `/resource?type=${type}`;

export const archiveProject = (id) => baseURL + `/administrator/interaction/project?project_id=${id}`; 
export const getAdminprojects = baseURL + "/administrator/interaction/project";
export const getProjectByID = (id) => baseURL + `/administrator/interaction/project/${id}`;
export const paginatedAdminprojects = (page,per_page, search,filter) => baseURL + `/administrator/interaction/project?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const archiveInvestment = (id) => baseURL + `/administrator/interaction/investment?investment_id=${id}`;
export const getAdminInvestments = baseURL + "/administrator/interaction/investment";
export const getAdminInvestmentByID = (id) => baseURL + `/administrator/interaction/investment/${id}`;
export const paginatedAdminInvestments = (page, per_page, search, filter) =>
  `/administrator/interaction/investment?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const getAdminTenders = baseURL + "/administrator/interaction/tender";
export const archiveAdmintender = (id) => baseURL + `/administrator/interaction/tender?product_id=${id}`;
export const getAdminTender = (id) =>
  baseURL + `/administrator/interaction/tender/${id}`;
export const searchAdminTender = (id, search) =>
  baseURL + `/administrator/interaction/tender/${id}?search=${search}`;
export const paginatedAdminTenders = (page, per_page, search, filter) =>
  `/administrator/interaction/tender?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const archiveAdmintransaction = (id) => baseURL + `/administrator/interaction/transaction?transaction_id=${id}`;
export const getAdminTransactions =
  baseURL + "/administrator/interaction/transaction";
export const paginatedAdminTransactions = (page, per_page, search, filter) =>
  `/administrator/interaction/transaction?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const getAdminTransaction = (id) =>
  baseURL + `/administrator/interaction/transaction/${id}`;

// admin end

/**
 * getAllTenders
 */
export const getMinerTenders = baseURL + "/miner/interactions/tender";
export const getMinerTender = (id) =>
  baseURL + `/miner/interactions/tender/${id}`;
export const searchMinerTender = (id,search) =>
  baseURL + `/miner/interactions/tender/${id}?search=${search}`; 
export const paginatedMinerTenders = (page, per_page, search) =>
  `/miner/interactions/tender?$page=${page}&$limit=${per_page}&search=${search}`;

export const awardBid = (productId, bidId) =>
  baseURL + `/miner/interactions/tender/?product_id=${productId}&bid_id=${bidId}`;

export const getMinerOffers = baseURL + "/miner/interactions/offer";
export const paginatedMinerOffers = (page, per_page, search, filter) =>
  `/miner/interactions/offer?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const getMinerOffer = (id) =>
  baseURL + `/miner/interactions/offer/${id}`;

export const getMinerInterests = (id,status) =>
  baseURL + `/miner/interactions/interest?offer_id=${id}&status=${status}`;
export const getMinerInterest = (id) =>
  baseURL + `/miner/interactions/offer/${id}/interest`;
export const minerAwardInterest = (id) =>
  baseURL + `/miner/interactions/interest?interest_id=${id}`;
export const getMinerTransactions =
  baseURL + "/miner/interactions/transaction";
export const paginatedMinerTransactions = (page, per_page, search, filter) =>
  `/miner/interactions/transaction?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const getMinerTransaction = (id) =>
  baseURL + `/miner/interactions/transaction/${id}`;
export const rateVendorFromMiner = (id) => `miner/vendor/rating?account_id=${id}`;
export const getMinerProjects = baseURL + "/miner/interactions/project";
export const paginatedMinerProjects = (page, per_page, search, filter) =>
  `/miner/interactions/project?$page=${page}&$limit=${per_page}&search=${search}&status=${filter}`;
export const getMinerProject = (id) =>
  baseURL + `/miner/interactions/project/${id}`;
export const postMinerTransaction = (id) =>
  baseURL + `/miner/interactions/transaction/?transaction_id=${id}`;

// messaging urls
export const getMessages = baseURL + "/messages";
