/** @format */

import dayjs from "dayjs";
let roles = [];
export const checkDayDifference = (dateOne, dateTwo) => {
  const dayDifference = dayjs(dateOne).diff(dateTwo, "day");
  return dayDifference;
};

export const checkNumberOfDaysLeft = (dateOne, dateTwo) => {
  const difference = checkDayDifference(dateOne, dateTwo);
  return `${difference} day${difference > 1 ? "s" : ""} left`;
};

export const getBox = (lat, long) => {
  //calculate the offset of 1km at a certain coordinate
  const dist = kmInDegree(lat, long);
  //calculate the bounds and make an object of them
  let bounds = {
    north: lat + dist.lat,
    south: lat - dist.lat,
    east: long + dist.long,
    west: long - dist.long,
  };
  return bounds;
};

export const truncate = (str, len) => {
  if (str.length > len) {
    if (len <= 3) {
      return str.slice(0, len - 3) + "...";
    } else {
      return str.slice(0, len) + "...";
    }
  } else {
    return str;
  }
};

export const capitalizeFirstLetter = (str) => {
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
};

export const tableDateFormat = (date) => {
  if (!date) {
    return "";
  }
  const month = dayjs(date).format("MMM");
  const day = dayjs(date).format("DD");
  const year = dayjs(date).format("YYYY");
  return `${day} ${month} ${year}`;
};

export const getPermissions = (permissions) => {
  roles = permissions;
};

export const checkPermission = (resource) => {
  let Permission = false;
  if (roles.all_permissions) {
    roles?.all_permissions?.forEach((x) => {
      if (x.resource.name == resource) {
        if (
          x.method.add === false &&
          x.method.delete === false &&
          x.method.edit === false &&
          x.method.search === false &&
          x.method.view === true
        ) {
          Permission = true;
        } else {
          Permission = false;
        }
      }
    });
  }

  return Permission;
};

const kmInDegree = (lat, long) => {
  const pi = Math.PI;
  const eSq = 0.00669437999014;
  const a = 6378137.0; //equatorial radius in metres
  lat = (lat * pi) / 180; //convert to radians
  long = (long * pi) / 180; //convert radians

  //I won't try to explain the calculations. All i know is that they are correct with the examples on wikipedia (see url above)
  const latLength =
    (pi * a * (1 - eSq)) /
    (180 * Math.pow(1 - eSq * Math.pow(Math.sin(lat), 2), 3 / 2));

  const longLength =
    (pi * a * Math.cos(long)) /
    (180 * Math.sqrt(1 - eSq * Math.pow(Math.sin(long), 2)));

  //If you want a greater offset, say 5km then change 1000 into 5000
  return {
    lat: 1000 / latLength,
    long: 1000 / longLength,
  };
};

export const apiConfig = (method, url, data = null) => {
  const token = JSON.parse(localStorage.getItem("token"));
  return {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    },
    data,
  };
};

export const transformProductObject = (product) => {
  return {
    id: product._id,
    bids: product.bids,
    description: product.description,
    endDate: product.end_date,
    productId: product.product_id,
    image: product.image,
    mine: product.mine,
    mineral: product.mineral,
    name: product.product_name,
    price: product.price,
    quantity: product.quantity,
    shipmentOptions: product.shipment_options,
    startDate: product.start_date,
    totalValue: product.total_value,
    treatmentCost: product.treatment_cost,
    unitOfMeasurement: product.unit_of_measurement,
    updated_at: product.updated_at,
  };
};

export const formatString = (value) => value.replace(/[^A-Za-z]+/g, " ");

export const formatObject = (n) => {
  let data;
  for (let i = 0; i < n?.length; i++) {
    data = n[i];
  }
  return data;
};

export const priceSplitter = (number) =>
  number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const getInitials = (string) => {
  if (!string) return "";
  const [firstname, lastname] = string.split(" ");
  return firstname?.charAt(0) + (lastname ? lastname?.charAt(0) : "");
};
