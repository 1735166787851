/* eslint-disable no-unused-vars */
/** @format */

import React from "react";
import { Link } from "react-router-dom";

import { Modal, Box } from "@mui/material";
import Button from "components/ui/Button";
import Success from "../../../../../assets/images/success.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 528,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

// eslint-disable-next-line react/prop-types
export const RoleSuccessModal = ({ setOpen, open = null, name = null }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="text-center mb-4">
          <div className="grid justify-items-center">
            <img src={Success} />
          </div>
          <h1 className="text-xl mb-4">Role creation successful</h1>
          <p className="font-light">The role {name} has been created.</p>
          <div className="flex items-center justify-between mt-8">
            <Link to="/miner/teams/role">
              <button className="px-4 py-2 rounded bg-[#EFF0FE] text-[#6166B6]">
                Close
              </button>
            </Link>
            <Link to="/miner/teams/member/add">
              <Button>Add Member</Button>
            </Link>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
