import { requestService } from "store/slices/offtaker-slice";
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import {
  getCountries,
  getServiceOptions
} from "store/slices/miners/vendor-slice";

import { useDispatch, useSelector } from "react-redux";
import { contactVendor } from "store/slices/offtaker-slice";
import { VendorInquiryForm } from "components/index";

function ContactVendor() {
  const { countries, service_options } = useSelector(
    (store) => store.miner_vendors
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getServiceOptions());
  }, []);

  return (
    <VendorInquiryForm
      countries={countries}
      service_options={service_options}
      dispatchFunction={requestService}
      action="request"
      role="off-taker"
    />
  );
}

export default ContactVendor;
