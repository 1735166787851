const AreaSelect = ({color}) => {
  const iconColor = color ? color : "#D9E8FC";
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9 11.4L14 18.9L15.7 16.3L18.9 19.5L20 18.4L16.7 15.2L19.4 13.6L11.9 11.4Z"
        fill={iconColor}
        fillOpacity="0.7"
      />
      <path
        d="M12 15.5H5V6.5H17V11.9L18 12.1V5.5H4V16.5H12.2L12 15.5Z"
        fill={iconColor}
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default AreaSelect;
