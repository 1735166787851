/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "components/Table";
import { adminProjectsTableInfo } from "constants/index";
import {
  getProjects,
  fetchPaginatedAdminProjects,
  setProjectsSearchValue,
  postArchiveproject
} from "@slices/admin-slice";
import { toast } from "react-toastify";
import { Button, ControlledModal } from "@ui";

const Projects = () => {
  const { loading, projects, pagination, search, filter } = useSelector(
    (state) => state.admin
  );
  const [projectID, setProjectID] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedAdminProjects({
        page: pagination[option + "Page"],
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };

  const archiveProject = async () => {
    setIsLoading(true);
    let body = {
      status: "Archived",
      content: "transaction archived",
    };
    try {
      const response = await dispatch(
        postArchiveproject({ id: projectID, body: body })
      );
      if (response.payload.status === 200 || response.payload.status == 201) {
        if (
          response?.payload?.data?.code == 422 ||
          response.payload.data.code == 400
        ) {
          setIsLoading(false);
          toast.error(response.payload.data.message);
        } else {
          setOpen(false);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        console.log("error from then", response);
      }
    } catch (error) {
       console.log("error", error);
    }
  };

  const handleSearch = (e) => {
    dispatch(setProjectsSearchValue(e.target.value));
  };

  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedAdminProjects({
        page: 1,
        per_page: e.target.value,
        search: search,
        filter: filter,
      })
    );
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(getProjects());
    }
    dispatch(setProjectsSearchValue(search));
    dispatch(
      fetchPaginatedAdminProjects({
        page: 1,
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    if (tab == "All") {
      if (search == "") {
        dispatch(getProjects());
      } else {
        dispatch(
          fetchPaginatedAdminProjects({
            page: 1,
            per_page: pagination.limit,
            search: search,
            filter: "",
          })
        );
      }
    } else {
      dispatch(
        fetchPaginatedAdminProjects({
          page: 1,
          per_page: pagination.limit,
          search: search,
          filter: tab,
        })
      );
    }
  };

  const actions = {
    toggle: {
      fields: ["View Details", "Archive"],
      action: (option, id) => {
        if (option === "View Details") {
          navigate("../../projects/details", {
            state: { id: id },
          });
        } else {
          setOpen(true);
          setProjectID(id);
        }
      },
    },
  };

  React.useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  return (
    <>
      <ControlledModal
        isOpen={open}
        handleClose={handleClose}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
      >
        <p className="text-head text-center font-semibold text-[22px]">
          Archive project ?
        </p>
        <p className="text-lightgray text-center font-light text-base mt-2">
          Are you sure you want to archive this project ?
        </p>
        <div className="flex flex-row justify-center gap-4 mt-6">
          <Button
            type="button"
            variant="text"
            color="neutral"
            onClick={() => setOpen(!open)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="contained"
            color="danger"
            onClick={() => {
              archiveProject();
            }}
          >
            {isLoading ? "Processing..." : "Yes, archive"}
          </Button>
        </div>
      </ControlledModal>
      <div>
        <div className="mt-4 sm:flex overflow-x-auto scrollbar-hide">
          <Table
            tableInfo={adminProjectsTableInfo}
            isStatus={true}
            navigate={navigate}
            data={projects}
            pagination={pagination}
            handleSetPerPage={handleSetPerPage}
            handleSearchSubmit={handleSearchSubmit}
            handleChangePage={handleChangePage}
            handleTabChange={handleTabChange}
            loading={loading}
            handleSearch={handleSearch}
            fetchDefaultData={getProjects}
            search={search}
            isToggle={true}
            actions={actions}
          />
        </div>
      </div>
    </>
  );
}

export default Projects;
