/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useLocation, Navigate } from "react-router-dom";
import { useAuth } from "@hooks";

export default function RequireAuth({ children, role }) {
  let { user, primaryRole, secondaryRole, profileStatus, account } = useAuth();
  let location = useLocation();
  // if user is not logged in
  if (!user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // if (primaryRole !== role) {
  //   return <Navigate to="/signup" state={{ from: location }} replace />;
  // }
  // if user does not have any of these permissions
  if (
    primaryRole !== "miner" &&
    primaryRole !== "off-taker" &&
    primaryRole !== "vendor" &&
    secondaryRole !== "miner" &&
    secondaryRole !== "off-taker" &&
    secondaryRole !== "vendor"
  )
    return <Navigate to="/signup" state={{ from: location }} replace />;

  // if user has not yet submit his/her form for verification
  if (profileStatus === "pending1") {
    // localStorage.clear();
    localStorage.setItem("profile_to_verify", JSON.stringify(account));

    return (
      <Navigate
        to={`/${primaryRole}-verification`}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}
