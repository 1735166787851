/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Skeleton from "@components/investors/Skeleton";
import { Search,Button } from "@ui";
import { DropDownIcon,SearchIcon } from "@ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { getDemands, searchDemands } from "@slices/miners/demands-slice";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { MinerDemandsUrl } from "@config/app-url";
import Demand from "./components/demand";

const Demands = () => {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [searchParams, setParams] = useState();
  React.useEffect(() => {
    dispatch(getDemands({ page: 1, limit: limit }));
  }, []);

  const handleSearch = (e) => {
    if (e.target.value == "") {
      dispatch(getDemands({ page: 1, limit: limit }));
    }

    setParams(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    dispatch(
      searchDemands({
        search: searchParams,
      })
    );
  };

  const handleSetPerPage = (e) => {
    setLimit(e.target.value);
    dispatch(
      getDemands({
        page: 1,
        limit: e.target.value,
      })
    );
  };

  function handleChangePage(page) {
    dispatch(
      getDemands({
        page: page,
        limit: limit,
      })
    );
  }

  const { loading, demands } = useSelector((store) => store.miner_demands);

  return (
    <div className="font-default h-full flex flex-col">
      {/* search bar and buttons */}
      <div className="flex justify-between mb-5">
        <div className="flex gap-2">
          <form
            onSubmit={(e) => handleSearchSubmit(e)}
            className="flex  gap-2 mt-3 rounded py-1 px-2 mb-3"
          >
            <div className="w-[250px] lg:w-[548px]">
              <Search
                placeholder="Search Demands"
                onChange={(e) => handleSearch(e)}
              />
            </div>
            <Button type="submit" color="primary">
              <SearchIcon color="#ffff" opacity="1" />
            </Button>
          </form>
          {/* <div className="w-[548px]">
                        <Search placeholder="Search Demands" />
                    </div>
                    <div className="bg-primary flex items-center justify-center rounded py-1 px-2">
                        <SearchIcon color="#ffff" opacity="1" />
                    </div> */}
        </div>
        <div className="flex items-center justify-between gap-[11px]"></div>
      </div>
      {/* end search bar */}
      <div className="flex-1 grid lg:grid-cols-3 sm:grid gap-[30px] font-default">
        {loading ? (
          <>
            {" "}
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <Skeleton key={index} />
              ))}{" "}
          </>
        ) : (
          demands?.docs?.map((demand) => (
            <Demand demand={demand} key={demand.uuid} url={MinerDemandsUrl} />
          ))
        )}
      </div>
      {demands?.pagination && (
        <div className="flex justify-start lg:justify-end items-center w-full py-4 px-8 text-[13px] uppercase text-[#18273AF0]">
          <div className="flex justify-between gap-2 items-center">
            <p>Rows per page: {demands?.pagination[0]?.limit}</p>
            <FormControl sx={{ height: "30px", border: 0 }}>
              <Select
                sx={{ height: "30px", border: 0 }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => handleSetPerPage(e)}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="mx-6">
            1 -{" "}
            {Math.ceil(
              demands?.pagination[0]?.total / demands?.pagination[0]?.limit
            )}{" "}
            of {demands?.pagination[0]?.page}
          </div>
          <div className="flex justify-between gap-10">
            {demands?.pagination[0]?.prevPage && (
              <div
                className="rotate-90 cursor-pointer"
                onClick={() =>
                  handleChangePage(demands?.pagination[0]?.prevPage)
                }
              >
                <DropDownIcon />
              </div>
            )}
            {demands?.pagination[0]?.page >=
            Math.ceil(
              demands?.pagination[0]?.total / demands?.pagination[0]?.limit
            )
              ? null
              : demands?.pagination[0]?.page + 1 && (
                  <div
                    className="-rotate-90 cursor-pointer"
                    onClick={() =>
                      handleChangePage(demands?.pagination[0]?.page + 1)
                    }
                  >
                    <DropDownIcon />
                  </div>
                )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Demands;
