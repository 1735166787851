/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Card, Stepper, Button } from "@ui";
import BusinessSummary from "@components/investors/BusinessSummary";
import Documentation from "@components/investors/Documentation";
import ProductInformation from "@components/investors/ProductInfo";
import Proposal from "@components/investors/Proposal";
import Complete from "@components/investors/Complete";
import {
	fetchAllMines
  } from "@slices/miners/mines-slice";
  import {
    getServiceOptions
    } from "store/slices/miners/vendor-slice";
import { useSelector, useDispatch } from "react-redux";
import {
  postOfferInterest,
} from "@slices/miners/investor-slice";
import { useForm } from "react-hook-form";
import { Formik } from "formik";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { file_uploader } from "@slices/file.slice";
import { SuccessResponse } from "config/status-code";

const schema = yup.object().shape({
  product_name: yup.string().required("Product name is required"),
  quantity: yup
    .number()
    .typeError("Quantity must be number")
    .required("Quantity is is required"),
  price: yup
    .number()
    .typeError("Price must be number")
    .required("Price is is required"),
  mineral: yup.string().required("Please select a mineral"),
  product_image: yup.array().required("Please upload a product image"),
  additional_information: yup.string(),
  documents: yup.array().min(1).required("Upload at least one document"),
  proposal: yup.string().required("Proposal is required"),
  terms: yup.boolean().oneOf([true], "Accept terms and conditions"),
});

const ApplyToOffering = ({ headingText = "Apply to offering" }) => {
  const location = useLocation();
  const { id } = location.state;
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const { investmentOfferDetails: offer } = useSelector(
    (store) => store?.investors
  );

  const [productImage, setProductImage] = useState([]);
  const [documents, setDocuments] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getIndicateInterestData());
    dispatch(fetchAllMines());
		dispatch(getServiceOptions());
  }, []);

  const { investment_type, production_phase } = useSelector((store) => store.miner_vendors);
  const { mines } = useSelector((state) => state.mines);
  const _FormData = new FormData();
  const [activeStep, setActiveStep] = useState(0);
  const [mine, setMine] = useState();
  const [mineral, setMineral] = useState();

  const {
    register,
    unregister,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    clearErrors,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const handleNext = () => {
    trigger();
    if (activeStep === 0) {
      const values = getValues();
      const { product_name, mineral, quantity, price, product_image, mine } =
        values;
      if (
        product_name &&
        mineral &&
        quantity &&
        price &&
        product_image &&
        mine
      ) {
        clearErrors();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 2) {
      const values = getValues();
      const { documents } = values;

      if (documents?.length > 0) {
        clearErrors();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 3) {
      const values = getValues();
      const { proposal, terms } = values;
      if (proposal?.length) {
        clearErrors();
      }
    } else {
      clearErrors();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // eslint-disable-next-line no-unused-vars
  const handleReset = () => {
    setActiveStep(0);
  };
  const handleSelectChange = (e, { name }) => {
    setValue(name, e.slug, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onSubmit = async (data) => {
    delete data.terms;
    for (const file of data.product_image[0]) {
			_FormData.append("product_files", file);
		}

		for (const file of data.documents[0]) {
			_FormData.append("document_files", file);
		}
		delete data.product_image;
		delete data.documents;
		_FormData.append("data",JSON.stringify(data));
    try {
      const response = await dispatch(postOfferInterest({ id:id,data: _FormData }));
      if (response.payload.status === 201) {
        if(response?.payload?.data?.code == 422 || response?.payload?.data?.code == 400) {
          toast.error(response.payload.data.message)
        }else {
          // toast.success(response.payload.data.message);
          setOpenSubmitModal(true);
        }
       
      } else {
        // console.log("error from then", response);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const uploadTypeSetter = (type, array) => {
    switch (type) {
      case "product_image":
        setProductImage(array);
        break;
      case "documents":
        setDocuments((prevState) => prevState.concat(array));
        break;

      default:
        break;
    }
  };
  const handleFileChange = async (FileList, type) => {
    /** check if filelist is empty  */

    if (FileList.length <= 0) {
      return toast.error("No file selected");
    }
    /** Declare form data */
    const _FormData = new FormData();
    for (const file of FileList) {
      _FormData.append("file", file);
    }
    /** set upload type */
    _FormData.append("type", type);
    let product_files = [];
		let docu_files = [];
		
		if (type === "product_image") {
			product_files.push(FileList);
			setValue("product_image",product_files);
			uploadTypeSetter('product_image', FileList);
		}else {
			docu_files.push(FileList);
			setValue("documents",docu_files);
			uploadTypeSetter('documents', FileList);
		}
    /** Dispatch files to backend */
    // dispatch(file_uploader(_FormData))
    //   .then((response) => {
    //     if (
    //       response.payload.status &&
    //       SuccessResponse(response.payload.status)
    //     ) {
    //       /** Set files to state */
    //       if (type === "product_image") {
    //         setValue("product_image", [
    //           { id: response.payload.data.files[0].id },
    //         ]);
    //         uploadTypeSetter("product_image", response.payload.data.files);
    //       } else {
    //         uploadTypeSetter(type, response.payload.data.files);
    //         const values = getValues();

    //         let doc = [];
    //         const { documents } = values;
    //         if (!documents) {
    //           doc = [{ id: response.payload.data.files[0].id }];
    //           setValue("documents", doc);
    //         } else {
    //           doc = [...documents, { id: response.payload.data.files[0].id }];
    //           setValue("documents", doc);
    //         }
    //       }
    //     }
    //     throw "error uploading file";
    //   })
    //   .catch((error) => toast.error(error.message));
  };
  const uploadProductImage = (file) => handleFileChange(file, "product_image");
  const steps = [
    {
      label: "Product information",
    },
    {
      label: "Business summary",
    },
    {
      label: "Documentation",
    },
    {
      label: "Proposal",
    },
  ];
  const initialValues = {
    product_name: "",
    mineral: "",
    quantity: "",
    price: "",
    product_image: "",
    mine: "",
    documents: [],
    production_phase: "",
    investment_type: "",
    investment_amount: "",
    funding_terms: "",
  };
  useEffect(() => {
    setMineral({
      slug: offer?.mineral?.id,
      name: offer?.mineral?.name,
    });
    setValue("mineral", offer?.mineral?.slug);
  }, offer);
  return (
    // <div>hello</div>
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
      >
        {({ handleChange, isSubmitting, values }) => (
          <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
            <Card noPadding>
              <div className="grid grid-cols-4 divide-x divide-disabled">
                <div className="col-span-1 py-10 mx-auto">
                  <h3 className="text-2xl font-normal text-heading text-center mb-8">
                    {headingText}
                  </h3>
                  {/* stepper starts here */}

                  <Stepper steps={steps} activeStep={activeStep} />
                </div>
                <div className="col-span-3 py-10 px-8">
                  {/* stepper contents here */}
                  {steps[activeStep] && (
                    <div>
                      {/* {steps[activeStep].component} */}
                      {activeStep === 0 && (
                        <ProductInformation
                          {...{
                            errors,
                            register,
                            handleSelectChange,
                            uploadProductImage,
                            productImage,
                            setProductImage,
                            handleChange,
                            values,
                            mines,
                            setMine,
                            setValue,
                            watch,
                            mineral,
                          }}
                          mineral={{
                            slug: offer?.mineral?.id,
                            name: offer?.mineral?.name,
                          }}
                        />
                      )}
                      {activeStep === 1 && (
                        <BusinessSummary
                          {...{
                            errors,
                            register,

                            mine,
                          }}
                        />
                      )}
                      {activeStep === 2 && (
                        <Documentation
                          {...{
                            errors,
                            register,
                            handleFileChange,
                            documents,
                            setDocuments,
                            getValues,
                            setValue,
                          }}
                        />
                      )}
                      {activeStep === 3 && (
                        <Proposal
                          {...{
                            errors,
                            register,
                          }}
                        />
                      )}

                      <div className="flex justify-between mt-8">
                        <Button
                          variant="contained"
                          onClick={handleBack}
                          color="neutral"
                        >
                          {activeStep > 0 ? "Back" : "Cancel"}
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          color="primary"
                          disabled={
                            activeStep < steps.length ? isSubmitting : isDirty
                          }
                        >
                          {activeStep === steps.length - 1 ? "Submit" : "Next"}
                        </Button>
                      </div>
                    </div>
                  )}
                  {/* stepper contents ends here */}
                </div>
              </div>
            </Card>
          </form>
        )}
      </Formik>
      <Complete {...{ openSubmitModal, setOpenSubmitModal }} />
    </>
  );
};

export default ApplyToOffering;
