const CaretDown = () => {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.103516H10L5 7.10352L0 0.103516Z"
        fill="#1C2E45"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default CaretDown;
