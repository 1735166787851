import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: false,
      text: "Miners Analytics Chart",
    },
  },
};

export default function MinerAnalyticsChart({ analytics }) {
  const formattedData = useMemo(() => {
    const salesData = analytics.map((x) => x.sales) || [];
    const transactionsData = analytics.map((x) => x.transaction) || [];
    const labels = analytics.map((x) => x.month) || [];
    const data = {
      labels,
      datasets: [
        {
          label: "Sales",
          data: salesData,
          borderColor: "rgb(97, 102, 182)",
          backgroundColor: "rgba(97, 102, 182, 0.5)",
        },
        {
          label: "Transactions",
          data: transactionsData,
          borderColor: "rgb(45, 156, 219)",
          backgroundColor: "rgba(45, 156, 219, 0.5)",
        },
      ],
    };

    return { data, labels };
  }, [analytics]);

  return <Line options={options} data={formattedData.data} />;
}
