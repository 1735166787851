/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import Sidebar from "./sidebar/sidebar";
import SideBar from "./sidebar/sidebarResponsive";
import Navbar from "./navbar";
import Breadcrumb from "./breadcrumb";
import { getPermissions } from "shared/utility";
import Footer from "./footer";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
  AreaSelectIcon,
  BarChartIcon,
  CogIcon,
  DiamondIcon,
  PackageIcon,
  PieChartIcon,
  ShopIcon,
  HeadsetIcon,
  UsersIcon,
  LocationIconWhite,
  DemandsIcon
} from "@ui/icons";
import { SignInUrl } from "@config/app-url";
import { useEffect, useState,useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ControlledModal, Button } from "@ui";
import { Slider } from "@components";
import {
  minerOnboardingGuides,
  minerOnboardingGuidesPointers,
} from "@constants/onboarding-guides";
import Robot from "@assets/images/robot.svg";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllPermissions } from "@slices/roles-permissions-slice";

let MENU_ITEMS = [];

const SliderContent = ({ heading, paragraph }) => (
  <div className="mb-10">
    <h2 className="text-2xl mb-2">{heading}</h2>
    <p className="font-light text-sm">{paragraph}</p>
  </div>
);

const LayoutMiners = ({ children }) => {
  const roles = useSelector((state) => state.roles_permissions);
  const dispatch = useDispatch();
  const profiles = JSON.parse(localStorage.getItem("account"));
  useEffect(() => {
    dispatch(fetchAllPermissions({id:profiles[0]?.role?._id,role:"user"}));
  }, [dispatch]);

  getPermissions(roles);

  function checkPermission(page) {
    let Permission = "read & write";

    roles?.all_permissions?.forEach((x) => {
      if (x.resource.name == page) {
        if (
          x.method.add === true &&
          x.method.delete === true &&
          x.method.edit === true &&
          x.method.search === true &&
          x.method.view === true
        ) {
          Permission = "read & write";
        } else if (
          x.method.add === false &&
          x.method.delete === false &&
          x.method.edit === false &&
          x.method.search === false &&
          x.method.view === true
        ) {
          Permission = "read";
        } else {
          Permission = "restricted";
        }
      }
    });

    if (page == "Member") {
      if (profiles[0]?.parent_id) {
        Permission = "restricted";
      }
    }

    return Permission;
  }

  function checkSubListPermission(page, action) {
    let Permission = "read & write";

    roles?.all_permissions?.forEach((x) => {
      if (x.resource.name == page) {
        x.actions.forEach((y) => {
          if (y?.name == action) {
            if (y?.allowed == true) {
              Permission = "read & write";
            } else {
              Permission = "restricted";
            }
          }
        });
      }
    });

    return Permission;
  }

  MENU_ITEMS = [
    {
      label: "Dashboard",
      url: "/miner/dashboard",
      icon: <PieChartIcon />,
    },

    {
      label: "Mines",
      url: "/miner/mines",
      icon: <DiamondIcon />,
      permission: checkPermission("Mines"),
    },
    {
      label: "Inventory",
      url: "/miner/inventory",
      icon: <PackageIcon />,
      permission: checkPermission("Inventory"),
    },
    {
      label: "Interactions",
      url: "/miner/interactions",
      icon: <AreaSelectIcon />,
      permission: checkPermission("Interactions"),
      // hasDot: true,
      subList: [
        {
          label: "Tenders",
          path: "/miner/interactions/tenders",
          permission: checkSubListPermission("Interactions", "Tenders"),
        },
        {
          label: "Transactions",
          path: "/miner/interactions/transactions",
          permission: checkSubListPermission("Interactions", "Transactions"),
        },
        {
          label: "Investments",
          path: "/miner/interactions/investments",
          permission: checkSubListPermission("Interactions", "Investment"),
        },
        {
          label: "Projects",
          path: "/miner/interactions/projects",
          permission: checkSubListPermission("Interactions", "Project"),
        },
        {
          label: "Offers",
          path: "/miner/interactions/offers",
          permission: checkSubListPermission("Interactions", "Offer"),
        },
      ],
    },
    {
      label: "Demands",
      url: "/miner/demands",
      icon: <DemandsIcon />,
      permission: checkPermission("Demands"),
    },
    {
      label: "Tracking",
      url: "/miner/tracking",
      icon: <LocationIconWhite />,
      permission: checkPermission("Tracking"),
    },
    {
      label: "Auctions",
      url: "/miner/auctions",
      icon: <BarChartIcon />,
      permission: checkPermission("Auction"),
      // hasDot: true,
    },
    {
      label: "Teams",
      icon: <ShopIcon />,
      permission: checkPermission("Member"),
      subList: [
        {
          label: "Team Roles",
          path: "/miner/teams/role",
          permission: checkSubListPermission("Member", "Role"),
        },
        {
          label: "Team Members",
          path: "/miner/teams/member",
          permission: checkSubListPermission("Member", "Member"),
        },
      ],
    },
    {
      label: "Investors",
      url: "/miner/investors",
      icon: <UsersIcon />,
      permission: checkPermission("Investor"),
      subList: [
        {
          label: "Funding",
          path: "/miner/investors/fundings",
          permission: checkSubListPermission("Investor", "Funding"),
        },
        {
          label: "Offerings",
          path: "/miner/investors/offerings",
          permission: checkSubListPermission("Investor", "Offering"),
        },
      ],
    },
    {
      label: "Vendors",
      icon: <ShopIcon />,
      permission: checkPermission("Vendors"),
      subList: [
        {
          label: "Find a vendor",
          path: "/miner/vendors/find_a_vendor",
          permission: checkSubListPermission("Vendors", "All"),
        },
        {
          label: "Request a service",
          path: "/miner/vendors/request_a_service",
          permission: checkSubListPermission("Vendors", "Request"),
        },
      ],
    },
    // {
    //   label: "Messages",
    //   url: "/miner/messages",
    //   icon: <MailOutlineIcon />,
    //   permission: checkPermission("Support"),
    // },
     {
      label: "Support",
      url: "/miner/support",
      icon: <HeadsetIcon />,
      permission: checkPermission("Support"),
    },
    {
      label: "Settings",
      url: "/miner/settings",
      icon: <CogIcon />,
      permission: checkPermission("Settings"),
    },
  ];
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const [loading, setloading] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  const [startOnboarding, setStartOnboarding] = useState(false);
  const [activeGuide, setActiveGuide] = useState(0);

  const sliderRef = useRef();

  const handleModalClose = () => setOpenModal(false);

  const skipOnboardingGuides = () => setOpenModal(false);
  const gotoNextGuide = () => {
    setActiveGuide((prevGuide) => prevGuide + 1);
    if (minerOnboardingGuides[activeGuide + 1].path)
      navigate(minerOnboardingGuides[activeGuide + 1].path);

    sliderRef.current.slickNext();
  };

  const isLastGuide = activeGuide === minerOnboardingGuides.length - 1;

  useEffect(() => {
    setloading(true);
    if (!user) {
      return navigate(SignInUrl, { replace: true });
    }
    setloading(false);
  }, [navigate]);

  if (loading) {
    return null;
  }

  return (
    <div className="xl:grid xl:grid-cols-6">
      {startOnboarding && openModal && (
        <div
          className={`fixed ${minerOnboardingGuidesPointers[activeGuide].pointerPositions.top} ${minerOnboardingGuidesPointers[activeGuide].pointerPositions.left} z-50`}
        >
          <img
            src={minerOnboardingGuidesPointers[activeGuide].imageSrc}
            alt="onboarding first poiner"
          />
        </div>
      )}
      {/* first time user modal */}
      <ControlledModal
        className={`fixed ${minerOnboardingGuidesPointers[activeGuide].modalPositions.top} ${minerOnboardingGuidesPointers[activeGuide].modalPositions.left} w-1/3 px-8 pb-3 min-h-[27vh]`}
        isOpen={openModal}
      >
        <div className="flex justify-end mb-3">
          <Button variant="text-1" onClick={skipOnboardingGuides}>
            Skip
          </Button>
        </div>
        {!startOnboarding ? (
          <div className="flex flex-col items-center h-full px-2 pb-8">
            {/* welcome message */}
            <img src={Robot} alt="Daniola robot" />
            <h4 className="text-2xl mt-6 mb-2 ">Welcome to Daniola Corp.</h4>
            <p className="font-light text-sm text-center mb-6">
              Thank you for signing up to our platform! To show you around, we
              have created a small onboarding for you.
            </p>
            <Button onClick={() => setStartOnboarding(true)}>Let’s go</Button>
          </div>
        ) : (
          <div className="mb-3">
            {/* slider */}
            <Slider ref={sliderRef}>
              {minerOnboardingGuides.map((guide, index) => (
                <SliderContent
                  key={index + 1}
                  heading={guide.heading}
                  paragraph={guide.paragraph}
                />
              ))}
            </Slider>
            <div className="flex justify-end mt-8">
              <Button onClick={!isLastGuide ? gotoNextGuide : handleModalClose}>
                {!isLastGuide ? "Next" : "Finish"}
              </Button>
            </div>
          </div>
        )}
      </ControlledModal>
      {/*side bar  */}
      <div className="hidden xl:grid xl:col-span-1 sticky top-0 h-full">
        <Sidebar items={MENU_ITEMS} sliceRange={MENU_ITEMS.length} />
      </div>
      {/* main content */}
      <div className="col-span-6 lg:col-span-5 relative">
        <Navbar userType="Miner" />
        {/* responsiveness */}
        <div className="xl:hidden">
          <SideBar items={MENU_ITEMS} sliceRange={MENU_ITEMS.length} />
        </div>
        <Breadcrumb />
        <main className="px-4 lg:px-8 min-h-screen">{children}</main>
        {/* footer */}
        <div className="relative">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LayoutMiners;
