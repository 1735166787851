/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from "react";
import { Button } from "@atoms/button";
import { useForm } from "react-hook-form";
import { get_mineral_data } from "@slices/onboarding/miner.slice";
import { getServiceOptions } from "store/slices/miners/vendor-slice";
import { Formik } from "formik";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  postFindProspects,
} from "store/slices/offtaker-slice";
import { Select, TextArea ,Input} from "@ui";
import { CreateProspectSuccessModal } from "./FindProspectSuccess";
import ErrorMessage from "components/ErrorMessage";

const schema = yup.object().shape({
  mineral: yup.string().required("Please select a mineral"),
  production_phase: yup.string().required("Please select a phase"),
  investment_type: yup.string().required("Please select a investment type"),
  investment_amount: yup
    .number()
    .typeError("Investment amount can only be a number")
    .min(1, "Investment amount cannot be zero"),
  investment_duration: yup.string().required("Investment duration is required"),
  funding_terms: yup.string().required("Funding terms is required"),
});

const Prospects = () => {
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const dispatch = useDispatch();

  const { production_phase, investment_type } = useSelector(
    (store) => store.miner_vendors
  );

  useEffect(() => {
    dispatch(get_mineral_data());
    dispatch(getServiceOptions());
  }, [dispatch]);

  const { minerals } = useSelector(
    (state) => state.miner_verification
  );

  const onSubmit = async (data) => {
    try {
      const response = await dispatch(postFindProspects(data));
      if (response.payload.status === 200 || response.payload.status === 201) {
        setOpenSubmitModal(true);
      } else {
        console.log("error from then", response);
      }
    } catch (error) {
        console.log("error", error);
    }
  };
  const initialValues = {
    mineral: "",
    production_phase: "",
    investment_type: "",
    investment_amount: "",
    investment_duration: "",
    funding_terms: "",
  };
  const handleSelectChange = (e, { name }) => {
    setValue(name, e.slug, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <div className="text-default  w-[1125px] h-[972px] bg-white px-[164px] pt-[40px]">
      <p className="flex-grow-0 flex-shrink-0 text-[22px] text-left text-[#192434]">
        Find prospects
      </p>
      <p className="flex-grow-0 flex-shrink-0 w-[796px] text-base font-light text-left text-[#1b2b41]/[0.72]">
        Fill in the details of the type pf investment you would like to fund.
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
      >
        {({ handleChange, isSubmitting }) => (
          <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4">
              <Select
                name="mineral"
                placeholder="Select mineral"
                label="Mineral"
                options={minerals}
                onChange={handleSelectChange}
              />
              {errors.mineral && (
                <ErrorMessage>{errors.mineral?.message}</ErrorMessage>
              )}
            </div>

            <div className="mt-4">
              <Select
                label="Production phase to fund"
                name="production_phase"
                options={
                  production_phase
                    ? Object.keys(production_phase).map((key) => {
                        return { name: production_phase[key], slug: key };
                      })
                    : []
                }
                onChange={handleSelectChange}
                placeholder="Select production phase"
              />
              {errors.production_phase && (
                <ErrorMessage>{errors.production_phase?.message}</ErrorMessage>
              )}
            </div>

            <div className="mt-4">
              <Select
                label="Investment type"
                name="investment_type"
                onChange={handleSelectChange}
                placeholder="Select investment type"
                options={
                  investment_type
                    ? Object.keys(investment_type).map((key) => {
                        return { name: investment_type[key], slug: key };
                      })
                    : []
                }
              />
              {errors.investment_type && (
                <ErrorMessage>{errors.investment_type?.message}</ErrorMessage>
              )}
            </div>

            <div className="flex items-center justify-between gap-x-4 mt-4">
              <div className="flex-1">
                <Input
                  label="Investment amount"
                  isMoneyInput
                  {...register("investment_amount")}
                  name="investment_amount"
                  onChange={handleChange}
                />
                {errors.investment_amount && (
                  <ErrorMessage>
                    {errors.investment_amount?.message}
                  </ErrorMessage>
                )}
              </div>

              <div className="flex-1">
                <Input
                  label="Duration"
                  {...register("investment_duration")}
                  name="investment_duration"
                  onChange={handleChange}
                />
                {errors.investment_duration && (
                  <ErrorMessage>
                    {errors.investment_duration?.message}
                  </ErrorMessage>
                )}
              </div>
            </div>

            <div className="mt-4">
              <label
                className="block text-xs mb-2  text-lightgray"
                htmlFor="funding_terms"
              >
                Funding terms (Present the terms and condition for
                investment/funding)
              </label>
              <TextArea
                {...register("funding_terms")}
                name="funding_terms"
                onChange={handleChange}
                size="mid"
                resizable
                maxHeight="300px"
              />
              {errors.funding_terms && (
                <ErrorMessage>{errors.funding_terms?.message}</ErrorMessage>
              )}
            </div>

            <div className="mt-8 flex justify-between">
              <button
                onClick={() => navigate("/off-taker/invest/prospects")}
                className="bg-dark-gray-300 text-primary w-[fit-content] flex justify-center py-2 mb-3 mt-4 px-4 rounded"
              >
                Cancel
              </button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                loading={isSubmitting}
                // disabled={!isDirty || !isValid}
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
      <CreateProspectSuccessModal
        open={openSubmitModal}
        setOpenSubmitModal={setOpenSubmitModal}
      />
    </div>
  );
};

export default Prospects;
