/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminMineByID } from "@slices/admin-slice";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Button } from "@ui";

const ViewMine = () => {
  const navigate = useNavigate();
  const { funding } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(
      fetchAdminMineByID({
        id: location?.state?.id
      })
    );
  }, [dispatch]);
  return (
    <>
      <Card>
        <div className="max-w-[796px] mx-auto">
          <h2 className="text-heading text-2xl my-2">Mine Details</h2>

          <div className="my-6">
            <div className="grid grid-cols-4 gap-y-4 mb-3">
              {/* <div className="col-span-1 font-light text-ts">
                <p>User ID</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{funding}</p>
              </div> */}
              <div className="col-span-1 font-light text-ts">
                <p>Mine Name</p>
              </div>
              <div className="col-span-3 font-light text-light capitalize">
                <p>{funding?.mine_name}</p>
              </div>
              <div className="col-span-1 font-light text-ts">
                <p>Mine type</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{funding?.mine_type}</p>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-y-4">
              <div className="col-span-1 font-light text-ts">
                <p>Description</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{funding?.description}</p>
              </div>
              <div className="col-span-1 font-light text-ts">
                <p>Company name</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{funding?.company_name}</p>
              </div>

              <div className="col-span-1 font-light text-ts">
                <p>Address</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{funding?.location?.address}</p>
              </div>
              <div className="col-span-1 font-light text-ts">
                <p>Country</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{funding?.country?.name}</p>
              </div>
              <div className="col-span-1 font-light text-ts">
                <p>City</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{funding?.city}</p>
              </div>
              <div className="col-span-1 font-light text-ts">
                <p>State</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{funding?.state}</p>
              </div>
              <div className="col-span-1 font-light text-ts">
                <p>Reserves</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{funding?.reserves} dmT</p>
              </div>
              <div className="col-span-1 font-light text-ts">
                <p>Minerals</p>
              </div>
              <div className="col-span-3 font-light text-light">
                {funding?.mineral_type?.map((x,index)=> (
                   <p className="flex flex-row flex-wrap gap-2" key={index + 1}>{x?.name}</p>
                ))}
               
              </div>
              <div className="col-span-1 font-light text-ts">
                <p>SDG Goals</p>
              </div>
              <div className="col-span-3 font-light text-light">
              {funding?.sdg_goals?.map((x,index)=> (
                   <p className="flex flex-row flex-wrap gap-2" key={index + 1}>{x?.name}</p>
                ))}
              </div>
            </div>

            <div className="flex justify-between  mt-20">
              <Button
                type="submit"
                variant="contained"
                color="neutral"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => navigate("../edit-mine",{
                  state: { id: funding.id, status: funding.status },
                })}
              >
                Edit mine
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ViewMine;
