/* eslint-disable react/prop-types */
import { InfoCircleIcon, CheckCircleOutline } from "@ui/icons";
import { Button } from "@ui";
import { Link } from "react-router-dom";

import { useAuth } from "@hooks";

const Hero = ({ close }) => {
  const { profileStatus } = useAuth();
  
  let message = null;

  if (profileStatus === "pending1") {
    message = (
      <>
        <p className="font-light text-2xl text-white mb-2">
          You are yet to submit your required details and documentation for
          verification
        </p>
        <div className="flex relative justify-between">
          <p className="font-extralight text-base text-white">
            Go to your profile to submit your details for verification
          </p>
          <Button color="white">
            <Link to="/miner-verification">Go to verification page</Link>
          </Button>
        </div>
      </>
    );
  }
  if (profileStatus === "pending2") {
    message = (
      <>
        <p className="font-light text-2xl text-white mb-2">
          Your verification submission has been received!
        </p>
        <p className="font-extralight text-base text-white">
          Thank you for successfully completing your KYC. It is currently being
          reviewed, and you will be notified once it has been approved.
        </p>
      </>
    );
  }
  if (profileStatus === "verified") {
    message = (
      <>
        <p className="font-light text-2xl text-white mb-2">
          Your profile has been successfully verified
        </p>
        <p className="font-extralight text-base text-white">
          You can now use the full functionalities of the platform. Thank you
          for your patience.
        </p>
      </>
    );
  }

  return (
    <div className="relative flex items-center gap-4 pl-8 pr-11 py-8 banner">
      {/* icon */}
      <div className="text-red-400 -ml-2">
        {profileStatus === "pending2" ? (
          <CheckCircleOutline color="#FFFFFF" opacity="0.9" />
        ) : (
          <InfoCircleIcon color="#FFFFFF" opacity={"0.9"} />
        )}
      </div>
      <div>{message}</div>
      {profileStatus === "verified" && (
        <div className="absolute top-3 right-3">
          <svg
            onClick={close}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.92 8.42L15.5 7L11.96 10.55L8.42 7L7 8.42L10.55 11.96L7 15.5L8.42 16.92L11.96 13.37L15.5 16.92L16.92 15.5L13.37 11.96L16.92 8.42Z"
              fill="white"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Hero;
