import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "@config/url";
import { toast } from "react-toastify";
import axios from "@config/axios-instance";

const fetchDashboardActivities = createAsyncThunk(
  "vendor/fetchDashboardActivities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.vendorFetchDashboardActivities);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchDashboardNotifications = createAsyncThunk(
  "vendor/fetchDashboardNotifications",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.vendorFetchDashboardNotifications);

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchDashboardRecordsCard = createAsyncThunk(
  "vendor/fetchDashboardRecordsCard",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.vendorFetchDashboardRecordsCard);

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchDashboardAnalyticsChart = createAsyncThunk(
  "vendor/fetchDashboardAnalyticsChart",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.vendorFetchDashboardAnalyticsChart);

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const vendorAnalyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    record_card: {
      total_projects: 0,
      total_offerings: 0,
      total_offers: 0,
    },
    activities: [],
    notifications: [],
    analytics: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    //fetch dashboard activities
    [fetchDashboardActivities.pending]: (state) => {
      state.loading = true;
    },
    [fetchDashboardActivities.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.activities = payload.data.activities;
    },
    [fetchDashboardActivities.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    //fetch dashboard notfications
    [fetchDashboardNotifications.pending]: (state) => {
      state.loading = true;
    },
    [fetchDashboardNotifications.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.notifications = payload.data.notifications;
    },
    [fetchDashboardNotifications.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    //fetch records card
    [fetchDashboardRecordsCard.pending]: (state) => {
      state.loading = true;
    },
    [fetchDashboardRecordsCard.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.record_card.total_projects = payload.data.total_projects;
      state.record_card.total_offerings = payload.data.total_offerings;
      state.record_card.total_offers = payload.data.total_offers;
    },
    [fetchDashboardRecordsCard.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    //fetch analytics
    [fetchDashboardAnalyticsChart.pending]: (state) => {
      state.loading = true;
    },
    [fetchDashboardAnalyticsChart.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.analytics = payload.data.data;
    },
    [fetchDashboardAnalyticsChart.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
  },
});

export {
  fetchDashboardActivities,
  fetchDashboardNotifications,
  fetchDashboardRecordsCard,
  fetchDashboardAnalyticsChart,
};

export default vendorAnalyticsSlice.reducer;
