/* eslint-disable no-undef */
import { useEffect } from "react";
import dayjs from "dayjs";
import { Card, Chips } from "@ui";
import BannerImage from "@images/banner-2.png";
import Countdown from "@components/auctions/Countdown";
import LoadingState from "@components/auctions/auction/LoadingState";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleAuctions } from "store/slices/miners/auctions-slice";

const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

const AuctionDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getSingleAuctions(id));
  }, [dispatch, id]);

  const { loading, auction } = useSelector((state) => state.auctions);

  return (
    <>
      {loading ? (
        // <div>Loading...</div>
        <LoadingState />
      ) : (
        <div className="grid grid-rows-2 lg:grid-rows-none lg:grid-cols-3 gap-[30px] mt-5 justify-center">
          <Card className="row-span-1 lg:col-span-2">
            <div className="flex flex-col lg:flex-row gap-8 pt-1">
              <div className="w-[285px] lg:w-[331px] h-[217px] rounded-lg overflow-hidden">
                <img
                  className="h-full w-full object-cover"
                  src={auction.product?.image[0]?.url}
                  alt="daniOla product card "
                />
              </div>
              <div>
                <h3 className="text-2xl text-heading">
                  {auction.product?.name}
                </h3>
                <p className="text-sm text-ts font-light mt-1">
                  {auction.product?.mine?.name}
                </p>
                <Countdown date={auction?.product?.endDate} />
              </div>
            </div>
            {/* card information */}
            {/* <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Token ID:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{data.product.token_id}</p>
              </div>
            </div> */}

            <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Description:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{auction.product?.description}</p>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Quantity:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>
                  {auction.product?.quantity}{" "}
                  {auction.product?.unitOfMeasurement.id}
                </p>
              </div>
            </div>

            {/* <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Quality:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p> nill</p>
              </div>
            </div> */}

            <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>TC:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>
                  {auction.product?.treatmentCost} USD/
                  {auction.product?.unitOfMeasurement.id}
                </p>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Price:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>
                  {auction.product?.price} USD/
                  {auction.product?.unitOfMeasurement.id}
                </p>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Total Value:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{auction.product?.totalValue} USD</p>
              </div>
            </div>

            {/* <div className="grid grid-cols-4 gap-y-4 mt-6">
              <div className="col-span-1 font-light text-ts text-sm">
                <p>Country:</p>
              </div>
              <div className="col-span-3 font-light text-light">
                <p>{auction.mine_country}</p>
              </div>
            </div> */}
            {/* auction's activity */}
            <h3 className="text-heading mt-[41px] mb-6">
              Activity on this auction
            </h3>
            <div className="flex gap-[51px] pb-[75px]">
              <div className="font-light text-ts">
                <p className="mb-4">Bids: </p>
                <p>Last Viewed:</p>
              </div>
              <div className="font-light text-light">
                <p className="mb-4">{auction.activity?.bids}</p>
                <p>{dayjs(auction.product?.updated_at).fromNow()}</p>
              </div>
            </div>
          </Card>
          <div className="row-span-1 lg:col-span-1">
            <Card className="py-[30px]">
              <h3 className="text-heading mb-[18px]">About the seller</h3>
              <div className="grid grid-cols-2 mb-4 font-light">
                <p className="text-base text-ts mb-4 whitespace-nowrap">
                  Member since:
                </p>
                <p className="text-light">
                  {dayjs(auction.member_since?.member_since)?.format("MMMM YYYY")}
                </p>
              </div>
              <div className="grid grid-cols-2 mb-4 font-light">
                <p className="text-base text-ts mb-4">Sells:</p>
                <span className="flex flex-wrap gap-2">
                  <Chips noIcons chips={auction.seller?.sells} />
                </span>
              </div>
              <div className="grid grid-cols-2 mb-4 font-light">
                <p className="text-base text-ts mb-4">Locaton:</p>
                <p className="text-light whitespace-nowrap">
                  {auction.seller?.country?.name}
                </p>
              </div>
              <div className="grid grid-cols-2 mb-4 font-light">
                <p className="text-base text-ts mb-4">Auctions:</p>
                <p className="text-light">{auction.seller?.auctions} active</p>
              </div>
              <div className="grid grid-cols-2 mb-4 font-light">
                <p className=" text-base text-ts mb-4">Deals:</p>
                <p className="text-light">{auction.seller?.deals}</p>
              </div>
              <div className="grid grid-cols-4 gap-2 mt-8">
                {auction.seller?.sdg_goals?.map((sdg_goal) => (
                  <img key={sdg_goal?.id} src={sdg_goal?.image_url} alt={sdg_goal?.name} />
                ))}
              </div>
            </Card>
            <img className="mt-6" src={BannerImage} alt="banner" />
          </div>
        </div>
      )}
    </>
  );
};

export default AuctionDetails;
