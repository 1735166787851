/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import { Header } from "@atoms/header";
import { Button } from "@atoms/button/index";
import { Input } from "@atoms/input";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "@slices/user-slice";
import { CheckEmailUrl } from "@config/app-url";
import { SuccessResponse } from "config/status-code";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .max(255)
    .required("Email is required"),
});

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.user);

  useEffect(() => {
    if (loading) {
      // console.log("loading button...");
    }
  }, [loading]);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const navigate = useNavigate();

  const onSubmit = (data) => {
    // console.log(data);
    dispatch(forgotPassword(data))
      .then((res) => {
        if (res.payload.status && SuccessResponse(res.payload.status)) {
          navigate(CheckEmailUrl);
        }
        throw 'error submitting form'
      }).catch((err) => {
        return toast.error(err.message);
      });


  };
  return (
    <div className="font-default">
      <Header />

      <div className=" my-4 bg-white p-8 rounded mt-[113px] lg:mt-0 lg:bg-none">
        <div
          onClick={() => navigate(-1)}
          className="text-primary border-none cursor-pointer flex justify-end"
        >
          {" "}
          Back
        </div>
        <h2 className="font-bold text-2xl text-head w-1/2 pt-4 ">
          Forgot password?
        </h2>

        <p className="text-lightgray pb-4 text-base font-light mt-1 ">
          Enter the business email address associated with your account and
          we'll send an email with instructions to reset your password.
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-4">
            <label className="block text-sm  mb-1 text-lightgray">
              Business Email Address
            </label>
            <Input type="text" name="email" {...register("email")} />
            <p className="h-2 alert text-xs"> {errors.email?.message}</p>
          </div>

          <Button
            disabled={loading === true ? isValid : !isDirty || !isValid}
            type="submit"
          >
            {loading ? "Loading..." : "Send instructions"}
          </Button>
        </form>
      </div>
    </div>
  );
};
export default ForgotPassword;
