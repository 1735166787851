/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Divider } from "@mui/material";
import Button from "components/ui/Button";
import CaretDown from "components/ui/icons/CaretDown";
import CaretUp from "components/ui/icons/CaretUp";
import {
  DashboardIcon,
  TeamIcons,
  VendorIcon,
} from "components/ui/icons/permissions/index";
import {
  AreaSelectIcon,
  BarChartIcon,
  CogIcon,
  DiamondIcon,
  PackageIcon,
  LocationIconWhite,
} from "@ui/icons";
import { useDispatch, useSelector } from "react-redux";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useParams, useNavigate } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import {
  fetchAdminPermissions,
  updateAdminPermissions,
} from "@slices/admin-slice";

function Permissions() {
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.admin);

  let routes = [
    "Directory",
    "Interaction",
    "Listings",
    "Users",
    "Verification",
    "Payment",
    "Suspension",
    "Carrier",
    "Mineral",
    "Tariff",
    "Settings"
  ];
  let all_permissions = [];

  if (Object.keys(permissions.data).length > 0) {
    routes.forEach((x) => {
      permissions?.data?.permission.forEach((y) => {
        if (y.resource.name === x) {
          all_permissions.push(y);
        }
      });
    });
  }

  const { id } = useParams();
  const [toggledInfoSection, setToggledInfoSection] = useState(false);
  const dispatch = useDispatch();
  const theme = createTheme({
    palette: {
      primary: {
        main: "#6166B6",
      },
    },
  });

  useEffect(() => {
    dispatch(fetchAdminPermissions(id));
  }, [dispatch]);
  const checkPermissionForRestricted = (event) => {
    return (
      !event?.method?.add &&
      !event?.method?.edit &&
      !event?.method?.view &&
      !event?.method?.delete &&
      !event?.method?.search
    );
  };

  const savePermissions = () => {
    navigate("/admin/teams/role");
    toast.success("Permissions Saved Successfully");
  };

  const checkPermissionForReadOnly = (event) => {
    return (
      !event?.method?.add &&
      !event?.method?.edit &&
      event?.method?.view &&
      !event?.method?.delete &&
      !event?.method?.search
    );
  };

  const checkPermissionForReadWriteOnly = (event) => {
    return (
      event?.method?.add &&
      event?.method?.edit &&
      event?.method?.view &&
      event?.method?.delete &&
      event?.method?.search
    );
  };

  const checkPermissionForActionAllowed = (event) => {
    return event.allowed;
  };

  let newArr = [];
  const handleCheckbox = async (event, actions, header) => {
    newArr = header.actions.map((obj) => {
      if (obj._id === actions._id) {
        return {
          ...obj,
          allowed: event.target.checked,
        };
      }
      return obj;
    });
    let formdata = {
      method: header.method,
      actions: newArr,
    };
    try {
      const response = await dispatch(
        updateAdminPermissions({ id: header._id, data: formdata })
      );
      if (response.payload.status === 200) {
        dispatch(fetchAdminPermissions(id));
        toast.success("Permission Updated");
      } else {
        toast.error(response.payload.msg);
        console.log("error from then", response);
      }
    } catch (error) {
      toast.error(error);
      console.log("error", error);
    }
  };

  const handleRadioForRestriction = async (value, key) => {
    if (key == "Restricted") {
      let formdata = {
        method: {
          view: false,
          edit: false,
          add: false,
          delete: false,
          search: false,
        },
      };
      try {
        const response = await dispatch(
          updateAdminPermissions({ id: value._id, data: formdata })
        );
        if (response.payload.status === 200) {
          dispatch(fetchAdminPermissions(id));
          toast.success("Permission Updated");
        } else {
          toast.error(response.payload.msg);
          console.log("error from then", response);
        }
      } catch (error) {
        toast.error(error);
        console.log("error", error);
      }
    } else if (key == "Read Only") {
      let formdata = {
        method: {
          view: true,
          edit: false,
          add: false,
          delete: false,
          search: false,
        },
      };
      try {
        const response = await dispatch(
          updateAdminPermissions({ id: value._id, data: formdata })
        );
        if (response.payload.status === 200) {
          dispatch(fetchAdminPermissions(id));
          toast.success("Permission Updated");
        } else {
          toast.error(response.payload.msg);
          console.log("error from then", response);
        }
      } catch (error) {
        toast.error(error);
        console.log("error", error);
      }
    } else {
      let formdata = {
        method: {
          view: true,
          edit: true,
          add: true,
          delete: true,
          search: true,
        },
      };
      try {
        const response = await dispatch(
          updateAdminPermissions({ id: value._id, data: formdata })
        );
        if (response.payload.status === 200) {
          dispatch(fetchAdminPermissions(id));
          toast.success("Permission Updated");
        } else {
          toast.error(response.payload.msg);
          console.log("error from then", response);
        }
      } catch (error) {
        toast.error(error);
        console.log("error", error);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="bg-white rounded-md">
        <div className="flex justify-between p-6">
          <h1>Permissions</h1>
          <div className="flex text-[14px] text-vendorsecondary">
            <h1>{all_permissions?.length} available</h1>
            {/* <h1>5 read Only</h1>
            <h1>5 read & Write</h1> */}
          </div>
        </div>
        <Divider />
        <div className="flex justify-center">
          <div className="w-2/3">
            {all_permissions?.map((header, index) => (
              <>
                <div className="p-5" key={index + 1}>
                  <div className="flex items-center">
                    {header?.resource?.name === "Dashboard" ? (
                      <div className="w-3 mr-4">
                        <DashboardIcon />
                      </div>
                    ) : header?.resource?.name === "Vendors" ? (
                      <div className="w-3 mr-4">
                        <VendorIcon />
                      </div>
                    ) : header?.resource?.name === "Auction" ? (
                      <div className="w-3 mr-4">
                        <BarChartIcon color="#1C304A" />
                      </div>
                    ) : header?.resource?.name === "Interactions" ? (
                      <div className="w-3 mr-4">
                        <AreaSelectIcon color="#1C304A" />
                      </div>
                    ) : header?.resource?.name === "Inventory" ? (
                      <div className="w-3 mr-4">
                        <PackageIcon color="#1C304A" />
                      </div>
                    ) : header?.resource?.name === "Tracking" ? (
                      <div className="w-3 mr-4">
                        <LocationIconWhite color="#1C304A" />
                      </div>
                    ) : header?.resource?.name == "Settings" ? (
                      <div className="w-3 mr-4">
                        <CogIcon color="#1C304A" />
                      </div>
                    ) : header?.resource?.name == "Mines" ? (
                      <div className="w-3 mr-4">
                        <DiamondIcon color="#1C304A" />
                      </div>
                    ) : (
                      <div className="w-3 mr-4">
                        <TeamIcons />
                      </div>
                    )}

                    <h1 className="text-[#192434]">{header?.resource?.name}</h1>
                  </div>
                  <div className="">
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <FormControlLabel
                        value="Restricted"
                        style={{ paddingLeft: "20px" }}
                        control={
                          <Radio
                            color="primary"
                            onChange={(e) => {
                              handleRadioForRestriction(header, "Restricted");
                            }}
                            checked={checkPermissionForRestricted(header)}
                          />
                        }
                        label="Restricted"
                      />
                      <FormControlLabel
                        value="Read Only"
                        control={
                          <Radio
                            color="primary"
                            checked={checkPermissionForReadOnly(header)}
                            onChange={(e) => {
                              handleRadioForRestriction(header, "Read Only");
                            }}
                          />
                        }
                        label="Read Only"
                      />
                      <FormControlLabel
                        value="Read & Write"
                        control={
                          <Radio
                            checked={checkPermissionForReadWriteOnly(header)}
                            onChange={(e) => {
                              handleRadioForRestriction(header, "Read & Write");
                            }}
                            color="primary"
                          />
                        }
                        label="Read & Write"
                      />
                      <h3
                        className="px-6 py-4 whitespace-nowrap text-sm text-[#6166B6] underline col-start-8 col-end-9 cursor-pointer"
                        onClick={() => setToggledInfoSection((prev) => !prev)}
                      >
                        {toggledInfoSection ? <CaretUp /> : <CaretDown />}
                      </h3>
                    </RadioGroup>
                    {toggledInfoSection && (
                      <div className=" w-full">
                        {header?.actions?.map((y, index2) => (
                          <div className="pt-5" key={index2 + 1}>
                            <div className="flex items-center">
                              <FormControlLabel
                                value="{y?.name}"
                                style={{ paddingLeft: "9px", margin: "0px" }}
                                control={
                                  <Checkbox
                                    checked={checkPermissionForActionAllowed(y)}
                                    onChange={(e) => {
                                      handleCheckbox(e, y, header);
                                    }}
                                  />
                                }
                              />
                              <h1 style={{ textTransform: "capitalize" }}>
                                {y?.name}
                              </h1>
                            </div>
                            {/* <div className="">
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <FormControlLabel
                            value="Restricted"
                            sx={{paddingLeft:"20px"}}
                            control={
                              <Radio
                                checked={checkPermissionForActionAllowed(y)}
                                color="primary"
                              />
                            }
                            label="Restricted"
                          />

                          <FormControlLabel
                            value="Read Only"
                            control={
                              <Radio
                                color="primary"
                                checked={checkPermissionForReadOnly(header)}
                              />
                            }
                            label="Read Only"
                          />

                          <FormControlLabel
                            value="Read & Write"
                            control={
                              <Radio
                                checked={checkPermissionForActionNotAllowed(y)}
                                color="primary"
                              />
                            }
                            label="Read & Write"
                          />
                          <h3
                            className="px-6 py-4 whitespace-nowrap text-sm text-[#50AAA7] underline col-start-8 col-end-9 cursor-pointer"
                            onClick={() =>
                              setToggledInfoSection((prev) => !prev)
                            }
                          >
                            {toggledInfoSection ? <CaretUp /> : <CaretDown />}
                          </h3>
                        </RadioGroup>
                        {toggledInfoSection && (
                          <div className=" w-full">
                            <FormGroup
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <FormControlLabel
                                style={{ paddingLeft: "20px" }}
                                control={<Checkbox defaultChecked />}
                                label="Activities"
                              />

                              <FormControlLabel
                                style={{ paddingLeft: "20px" }}
                                control={<Checkbox defaultChecked />}
                                label="Analytics"
                              />
                            </FormGroup>
                          </div>
                        )}
                      </div> */}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {/* for actions */}
                </div>
                <hr style={{ margin: "20px", color: "#d9dbde" }} />
              </>
            ))}
            <div className="flex justify-between px-5 mb-4">
              <Button
                onClick={() => {
                  savePermissions();
                }}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Permissions;
