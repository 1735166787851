/* eslint-disable react/prop-types */
const ProfileAvatar = ({ children, bgPrimary }) => {
    const backgroundColor = bgPrimary ? "bg-primary" : "bg-gray-100";
    return (
      <div
        className={`rounded-full h-[120px] w-[120px] flex items-center justify-center text-white ${backgroundColor}`} style={{fontSize:"45px"}}
      >
        {children}
      </div>
    );
  };
  
  export default ProfileAvatar;
  