import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVendors, searchVendors } from "store/slices/offtaker-slice";
import { FindVendor } from "components/index";

function OfftakerFindVendor() {
  const { vendors } = useSelector((store) => store.offtakers);
  const [searchParams, setSearchParams] = useState();
  const [countryParams, setCountryParams] = useState();
  const dispatch = useDispatch();
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(
      searchVendors({
        search: searchParams,
        country: countryParams,
      })
    );
  };
  const handleSearchWhat = (e) => {
    if (e.target.value == "") {
      dispatch(getVendors());
    }
    setSearchParams(e.target.value);
  };

  const handleSearchWhere = (e) => {
    if (e.target.value == "") {
      dispatch(getVendors());
    }
    setCountryParams(e.target.value);
  };

  useEffect(() => {
    dispatch(getVendors());
    // console.log(vendors);
  }, []);

  // const checkVendors = () => {
  //   console.log(vendors);
  // };
  return (
    <FindVendor
      vendors={vendors}
      userType={"offtaker"}
      handleSearchSubmit={handleSearchSubmit}
      handleSearchWhat={handleSearchWhat}
      handleSearchWhere={handleSearchWhere}
    />
  );
}

export default OfftakerFindVendor;
