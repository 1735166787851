/* eslint-disable react/prop-types */
import * as React from "react";

function SvgCheck(props) {
  const { transform, height } = props;
  return (
    <svg
      width={10}
      height={8}
      fill="none"
      transform="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.687.227h-.819a.373.373 0 0 0-.294.143L3.742 6.49 1.425 3.555a.375.375 0 0 0-.294-.143H.312a.094.094 0 0 0-.074.15l3.21 4.067c.15.19.438.19.59 0L9.76.377a.093.093 0 0 0-.074-.15Z"
        fill="#6166B6"
        transform={transform}
        height={height}
      />
    </svg>
  );
}

export default SvgCheck;
