/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { DropDownIcon } from "@ui/icons";

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [options] = useState(["Featured", "Lowest", "Highest"]);
  const [selected, setSelected] = useState(options[0]);
  const isSelected = (selected, option) => selected === option;

  const dropDownRef = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      const isCurrent = dropDownRef.current
      if (isCurrent && !isCurrent.contains(e.target)) {
        // outside click
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <div className="xl:w-full relative select-none">
      <div
        ref={dropDownRef}
        className="flex justify-between w-[164px] rounded px-4 py-2 bg-white border border-solid border-dark-gray-100 z-0 cursor-pointer"
        onClick={(e) => setIsOpen(!isOpen)}
      >
        <p className="text-sm text-light">
          <span className="font-light text-lightgray">Sort by: </span>
          {selected}
        </p>
        <div className="absolute right-2">
          <DropDownIcon />
        </div>
      </div>
      {isOpen && (
        <div className="options absolute w-full bg-white z-40 mt-1 font-light text-[#18273AF0] rounded-md shadow-[0_4px_24px_-4px_rgba(28,50,79,0.15)]">
          {options.map((option) => (
            <div
              key={option}
              className={`flex items-center gap-2 ${isSelected(selected, option) && "bg-primary-light px-2"
                }  px-6 py-2 rounded cursor-pointer transition-all duration-200 ${!isSelected(selected, option) && "hover:bg-[#f4f4f4]"
                }`}
              onClick={() => {
                setSelected(option);
                setIsOpen(false);
              }}
            >
              {isSelected(selected, option) && (
                <svg
                  width="11"
                  height="9"
                  viewBox="0 0 11 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.28033 4.77494C0.987437 4.48205 0.512563 4.48205 0.21967 4.77494C-0.0732233 5.06784 -0.0732233 5.54271 0.21967 5.8356L2.94202 8.55796C3.23491 8.85085 3.70979 8.85085 4.00268 8.55796L10.447 2.11364C10.7399 1.82075 10.7399 1.34588 10.447 1.05298C10.1541 0.76009 9.67923 0.76009 9.38634 1.05298L3.47235 6.96697L1.28033 4.77494Z"
                    fill="#6166B6"
                  />
                </svg>
              )}

              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
