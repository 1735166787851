/* eslint-disable no-unused-vars */
import CaretDown from "components/ui/icons/CaretDown";
import CaretUp from "components/ui/icons/CaretUp";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@ui";
import { getInitials } from "@shared/utility";

function ToggledRoleInfoSection({ props, handleChange }) {
  const navigate = useNavigate();
  let routes = [
    "Interactions",
    "Vendors",
    "Member",
    "Settings",
    "Tracking",
    "Mines",
    "Inventory",
    "Auction",
    "Servicerequest",
    "Demands",
    "Investment",
    "Invest",
    "Investor",
    "Messages",
    "Offerings",
  ];
  let all_permissions = [];

  routes.forEach((x) => {
    props?.permissions.forEach((y) => {
      if (y.resource[0].name === x) {
        all_permissions.push(y);
      }
    });
  });
  //eslint-disable-next-line
  const [toggledInfoSection, setToggledInfoSection] = useState(false);
  let permissionCount = [];
  function showpermissionCount(data) {
    data.forEach((x) => {
      if (
        x.method.add &&
        x.method.delete &&
        x.method.edit &&
        x.method.view &&
        x.method.search
      ) {
        permissionCount.push(x);
      }
    });
    return permissionCount.length;
  }

  function showrestrictions(event) {
    if (
      !event.method.add &&
      !event.method.delete &&
      !event.method.edit &&
      !event.method.view &&
      !event.method.search
    ) {
      return "(Restricted)";
    } else if (
      !event.method.add &&
      !event.method.delete &&
      !event.method.edit &&
      event.method.view &&
      !event.method.search
    ) {
      return "(Read Only)";
    } else {
      return "(Read & Write)";
    }
  }

  return (
    <div className="text-[#18273AF0] grid grid-cols-9 w-full">
      <h3 className="px-6 py-4 whitespace-nowrap text-sm col-start-1 col-end-2">
        <Checkbox name={props._id} onChange={handleChange} />
      </h3>

      <h3 className="px-6 py-4 whitespace-nowrap text-sm col-start-2 col-end-4">
        {props?.name}
      </h3>
      <h3 className="px-6 py-4 whitespace-nowrap text-sm col-start-4 col-end-6">
        {showpermissionCount(all_permissions)} Available
      </h3>
      <h3 className="px-6 py-4 whitespace-nowrap text-sm flex col-start-6 col-end-8">
        {props?.users.map((x, index) => (
          <div key={index + 1}>
            {x?.accounts[0]?.profile_img?.length > 0 ? (
              <img
                className="w-[25px] h-[25px] rounded-circle "
                key={index}
                src={`${x.accounts[0]?.profile_img[0]?.url}`}
                alt=""
              />
            ) : (
              <div className="min-h-[25px] min-w-[25px] flex justify-center align-center items-center text-1xl text-primary rounded-full bg-fill">
                {getInitials(x?.name)}
              </div>
            )}
          </div>
        ))}
      </h3>
      <h3
        className="px-6 py-4 whitespace-nowrap text-sm underline bhr col-start-8 col-end-9 text-primary"
        onClick={() => navigate("edit" + `/${props?._id}`)}
        style={{
          cursor: "pointer",
        }}
      >
        Edit role
      </h3>
      <h3
        className="px-6 py-4 whitespace-nowrap text-sm text-[#6166B6] underline col-start-9 col-end-10 cursor-pointer"
        onClick={() => setToggledInfoSection((prev) => !prev)}
      >
        {toggledInfoSection ? <CaretUp /> : <CaretDown />}
      </h3>
      {toggledInfoSection && (
        <div className="bg-inputbg col-start-1 col-end-10 grid grid-cols-9 w-full">
          <div className="col-start-4 col-end-6">
            {all_permissions.map((x, index) => (
              <h3 className="mb-2" key={index + 1}>
                {x?.resource[0].name} &nbsp;
                <span style={{ color: "#B5BDC6" }}>{showrestrictions(x)}</span>
              </h3>
            ))}

            {/* <h3 className="mb-2">Mines</h3>
            <div className="mb-2">
              <h3 className="mb-2">Interactions</h3>
              <div className="mb-2">
                <h3>Tender</h3>
                <h3>Transaction</h3>
                <h3>Funding</h3>
              </div>
              <h3 className="mb-2">Tracking </h3>
              <h3 className="mb-2">Investors</h3>
              <h3 className="mb-2">Messages</h3>
            </div> */}
          </div>
          <div className="col-start-6 col-end-8 pt-4">
            {props?.users.map((fake, index) => (
              <div className="flex mb-3" key={index + 1}>
                <>
                  {fake.accounts[0]?.profile_img?.length > 0 ? (
                    <img
                      className="w-[25px] h-[25px] rounded-circle "
                      key={index}
                      src={`${fake.accounts[0]?.profile_img[0]?.url}`}
                      alt=""
                    />
                  ) : (
                    <div className="min-h-[25px] min-w-[25px] flex justify-center align-center items-center text-1xl text-primary rounded-full bg-fill">
                      {getInitials(fake?.name)}
                    </div>
                  )}
                  &nbsp;&nbsp;
                  <h3>{fake.name}</h3>
                </>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ToggledRoleInfoSection;
