/* eslint-disable no-unused-vars */
import React from "react";
// import Skeleton from "@components/auctions/Skeleton";
import { VendorsTabs } from "@ui";
import { Button, Card, ControlledModal, TextArea, Chips } from "@ui";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchIcon } from "@ui/icons";
import { checkPermission } from "shared/utility";
import { fetchAdminVendors, postArchiveVendor } from "@slices/admin-slice";
import { adminVendors } from "constants/index";
import { toast } from "react-toastify";

const Vendors = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [prodID, setProductID] = useState();
  const [opened, setOpened] = useState(false);
  const [searchParams, setSearchParams] = useState("");
  const [countryParams, setCountryParams] = useState("");
  const { loading, vendors } = useSelector((state) => state.admin);
  const handleOpen = (vendor) => {
    setOpen(true);
    setProductID(vendor?._id);
  };

  let [data, setData] = useState({
    status: "archived",
    content: "",
  });

  const handleClosed = () => setOpened(false);

  const handleClose = () => setOpen(false);

  const handleOpenFeedback = () => {
    setOpened(true), setOpen(false);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchAdminVendors({
        filter: "",
        search: searchParams,
        country: countryParams,
      })
    );
  };

  const handleSearchWhat = (e) => {
    if (e.target.value == "") {
      dispatch(
        fetchAdminVendors({
          filter: "",
          search: "",
          country: countryParams,
        })
      );
    }
    setSearchParams(e.target.value);
  };

  const handleSearchWhere = (e) => {
    if (e.target.value == "") {
      dispatch(
        fetchAdminVendors({
          filter: "",
          search: searchParams,
          country: "",
        })
      );
    }
    setCountryParams(e.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const archiveVendor = async () => {
    try {
      setIsLoading(true);
      const response = await dispatch(
        postArchiveVendor({ id: prodID, data: data })
      );
      if (response.payload.status === 200 || response.payload.status === 201) {
        if (
          response.payload.data.code == 422 ||
          response.payload.data.code == 400
        ) {
          setIsLoading(false);
          toast.error(response.payload.data.message);
        } else {
          toast.success(response.payload.data.message);
          setOpened(false);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        // toast.error(response.payload.msg);
        console.log("error from then", response);
      }
    } catch (error) {
      //   toast.error(error);
      // console.log("error", error);
    }
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    // setFilter(tab);
    if (tab == "All") {
      dispatch(
        fetchAdminVendors({
          filter: "",
          search: searchParams,
          country: countryParams,
        })
      );
    } else {
      dispatch(
        fetchAdminVendors({
          filter: tab,
          search: searchParams,
          country: countryParams,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(fetchAdminVendors({ filter: "", search: "", country: "" }));
  }, [dispatch]);

  return (
    <div>
      {/* main */}
      <ControlledModal
        isOpen={open}
        handleClose={handleClose}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
      >
        <p className="text-head text-center font-semibold text-[22px]">
          Archive Vendor?
        </p>
        <p className="text-lightgray text-center font-light text-base mt-2">
          Are you sure you want to archive vendor for this user?
        </p>
        <div className="flex flex-row justify-center gap-4 mt-6">
          <Button
            type="button"
            variant="text"
            color="neutral"
            onClick={() => setOpen(!open)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            disabled={checkPermission("Listings")}
            variant="contained"
            color="danger"
            onClick={handleOpenFeedback}
          >
            Yes, archive
          </Button>
        </div>
      </ControlledModal>
      <ControlledModal
        isOpen={opened}
        handleClose={handleClosed}
        className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
      >
        <div className="flex flex-col justify-center gap-4 mt-6">
          <p className="text-head text-center font-semibold text-[22px]">
            Feedback to user
          </p>
          <p className="text-lightgray text-center font-light text-base mt-2">
            Kindly provide additional notes to explain to seller the reason for
            archiving the auction{" "}
          </p>
          <TextArea name="content" onChange={handleInputChange} />
          <div className="flex justify-end ">
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => {
                archiveVendor();
              }}
            >
              {isLoading ? "Processing..." : "Send"}
            </Button>
          </div>
        </div>
      </ControlledModal>
      <div className="mt-5 font-default">
        <VendorsTabs
          tableInfo={adminVendors}
          handleSearchSubmit={handleSearchSubmit}
          handleSearchWhat={handleSearchWhat}
          handleSearchWhere={handleSearchWhere}
          data={vendors}
          loading={loading}
          handleOpen={handleOpen}
          handleTabChange={handleTabChange}
        />
      </div>
    </div>
  );
};

export default Vendors;
