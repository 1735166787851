import React from "react";
import { Button } from "@ui";

const plans = [
  {
    id: "bank",
    name: "Pay via Bank Transfer",
    description: `You will get your transaction reference from your payment institution,
    it takes about 24 hrs to verify bank transfers.`,
  },
  {
    id: "card",
    name: "Pay via Stripe (Cards)",
    description: "",
  },
  {
    id: "wallet",
    name: "Pay via Wallet",
    description: "",
  },
];

export default function SelectPaymentMethod({
  paymentType,
  setPaymentType,
  onSubmit,
  data,
  setIsOpen,
  setIsCard,
}) {
  const handleProceed = () => {
    if (paymentType === "card") {
      setIsCard(true);
    } else {
      setIsCard(false);
    }
  };
  
  return (
    <div>
      <h1 className="text-center text-[22px] ">Make Payment</h1>
      <h1 className="text-center mt-3">
        Choose a payment method to complete your transaction.
      </h1>
      <fieldset className="mt-4">
        <legend className="sr-only">Payment type</legend>
        <div className="space-y-5">
          {plans.map((plan) => (
            <div key={plan.id} className="relative flex items-start mt-3">
              <div className="flex items-center h-5">
                <input
                  id={plan.id}
                  aria-describedby={`${plan.id}-description`}
                  name="plan"
                  type="radio"
                  defaultChecked={plan.id === "bank"}
                  value={paymentType}
                  className="focus:ring-[#6166B6]  h-4 w-4 text-[#6166B6] border-gray-300"
                  onChange={() => {
                    setPaymentType(plan.id);
                  }}
                />
              </div>
              <div className="ml-3 flex flex-col text-sm">
                <label
                  htmlFor={plan.id}
                  className="font-medium text-gray-700 text[16px]"
                >
                  {plan.name}{" "}
                  {plan.id == "wallet"
                    ? `(${parseFloat(data).toFixed(2)} USD)`
                    : ""}
                </label>
                <span
                  id={`${plan.id}-description`}
                  className="text-gray w-[445px]"
                >
                  {plan.description}
                </span>
              </div>
            </div>
          ))}
        </div>
      </fieldset>
      <div className="flex justify-between mt-12">
        <Button color="neutral" onClick={() => setIsOpen(!open)}>
          Cancel
        </Button>
        {paymentType == "wallet" ? (
          <Button
            onClick={() => {
              onSubmit();
            }}
          >
            Pay now
          </Button>
        ) : (
          <Button onClick={handleProceed}>Proceed to pay</Button>
        )}
      </div>
    </div>
  );
}
