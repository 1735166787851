/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "@config/axios-instance";
import axios2 from "axios";
import * as API from "@config/url";
import { toast } from "react-toastify";

const fetchRoles = createAsyncThunk(
  "fetchRoles",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.getRoleByProfileID(payload.id, payload.page, payload.limit)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getMembers = createAsyncThunk(
  "getMembers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.getMembers(payload.id, payload.page, payload.limit)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const searchMembers = createAsyncThunk(
  "searchMembers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.searchMembers(payload.search, payload.id)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const searchRoles = createAsyncThunk(
  "searchRoles",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.searchRoles(payload.search, payload.id)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchPermissions = createAsyncThunk(
  "fetchPermissions",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getPermission(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchAllPermissions = createAsyncThunk(
  "fetchAllPermissions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getPermissions(payload.id,payload.role));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);


const fetchResources = createAsyncThunk(
  "fetchResources",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getResources);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getTeamMemberBYID = createAsyncThunk(
  "getTeamMemberBYID",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.getTeamMemberByID(payload.id, payload._id)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getTeamMember = createAsyncThunk(
  "getTeamMember",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        API.getTeamMmember(payload.id, payload._id)
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const deletemember = createAsyncThunk(
  "deletemember",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(API.DeleteMember(id));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const DeleteRoles = createAsyncThunk(
  "DeleteRoles",
  async (data, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "patch",
      url: API.deleteRoles,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
      data: data,
    };
    try {
      const response = await axios2(config);
      return response;
    } catch (err) {
      // console.log(error.response);
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const updatePermission = createAsyncThunk(
  "updatePermission",
  async ({ id, data }, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "put",
      url: API.updatePermission(id),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
      data: data,
    };
    try {
      const response = await axios2(config);
      return response;
    } catch (err) {
      // console.log(error.response);
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const updateMember = createAsyncThunk(
  "updateMember",
  async ({ id, data }, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "put",
      url: API.Updatemember(id),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer "+ token
      },
      data: data,
    };
    try {
      const response = await axios2(config);
      return response;
    } catch (err) {
      // console.log(error.response);
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const addRole = createAsyncThunk(
  "addRole",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.addRole, data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const addMember = createAsyncThunk(
  "addMember",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.AddMember, data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const roles_permissionsSlice = createSlice({
  name: "roles_permissions",
  initialState: {
    docs: [],
    resources: [],
    delete_role: [],
    data: [],
    all_permissions: [],
    team_member: [],
    select_member: [],
    roles: [],
    add_member: [],
    members: [],
    pagination: {},
    loading: false,
    error: null,
    mines: null,
  },
  reducers: {},
  extraReducers: {
    //fetch roles
    [fetchRoles.pending]: (state) => {
      state.loading = true;
    },
    [fetchRoles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.docs = payload.data[0]?.docs;
      state.pagination = payload.data[0]?.pagination[0];
    },
    [fetchRoles.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    // fetch permissions
    [fetchPermissions.pending]: (state) => {
      state.loading = true;
    },
    [fetchPermissions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    },
    [fetchPermissions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchAllPermissions.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllPermissions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.all_permissions = payload.data.permission;
    },
    [fetchAllPermissions.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [fetchResources.pending]: (state) => {
      state.loading = true;
    },
    [fetchResources.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.resources = payload.data.resource;
    },
    [fetchResources.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [getTeamMemberBYID.pending]: (state) => {
      state.loading = true;
    },
    [getTeamMemberBYID.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.team_member = payload.data[0].docs;
    },
    [getTeamMemberBYID.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [getTeamMember.pending]: (state) => {
      state.loading = true;
    },
    [getTeamMember.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.select_member = payload.data;
    },
    [getTeamMember.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    // delete member
    [deletemember.pending]: (state) => {
      state.loading = true;
    },
    [deletemember.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // state.data = payload.data;
    },
    [deletemember.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [updatePermission.pending]: (state) => {
      state.loading = true;
    },
    [updatePermission.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // state.data = payload.data;
    },
    [updatePermission.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [updateMember.pending]: (state) => {
      state.loading = true;
    },
    [updateMember.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // state.data = payload.data;
    },
    [updateMember.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    // fetch memebers
    [getMembers.pending]: (state) => {
      state.loading = true;
    },
    [getMembers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.members = payload?.data[0]?.docs;
      state.pagination = payload?.data[0]?.pagination[0];
    },
    [getMembers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [searchMembers.pending]: (state) => {
      state.loading = true;
    },
    [searchMembers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.members = payload.data[0].docs;
    },
    [searchMembers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    [searchRoles.pending]: (state) => {
      state.loading = true;
    },
    [searchRoles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.docs = payload.data[0]?.docs;
    },
    [searchRoles.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    //create role
    [addRole.pending]: (state) => {
      state.loading = true;
    },
    [addRole.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.roles = payload.data;
    },
    [addRole.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [DeleteRoles.pending]: (state) => {
      state.loading = true;
    },
    [DeleteRoles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.delete_role = payload.data;
    },
    [DeleteRoles.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
    [addMember.pending]: (state) => {
      state.loading = true;
    },
    [addMember.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.add_member = payload.data;
    },
    [addMember.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      payload.message
        ? toast.error(payload.message)
        : toast.error("An error occured");
    },
  },
});

export {
  fetchRoles,
  fetchPermissions,
  DeleteRoles,
  fetchResources,
  fetchAllPermissions,
  getMembers,
  searchMembers,
  addRole,
  searchRoles,
  addMember,
  deletemember,
  updatePermission,
  updateMember,
  getTeamMemberBYID,
  getTeamMember,
};

export default roles_permissionsSlice.reducer;
