/* eslint-disable no-unused-vars */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "@ui";
import { priceSplitter,getInitials } from "@shared/utility";

const InvestmentItem = ({ offer, handleOpen }) => {
  const navigate = useNavigate();

  return (
    <>
      <Card>
        <div className="flex flex-col justify-start items-start  left-[23px] top-[23px] gap-4">
          <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 w-[206px] h-[66px]">
            <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
              {offer?.product_image?.length > 0 ? (
                <img
                  className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                  src={`${offer?.product_image[0]?.url}`}
                  alt=""
                />
              ) : (
                `${getInitials(offer?.account?.name)}`
              )}
            </div>
            <div className="flex flex-col justify-start items-start flex-grow-0 ml-4 flex-shrink-0 ">
              <p className="flex-grow-0 flex-shrink-0 mb-1 text-base text-left text-primary">
                {offer?.account?.name}
              </p>
              <p className="flex-grow-0 flex-shrink-0 mb-1 text-sm text-left text-[#18273a]/[0.94]">
                {offer?.mine?.company_name}
              </p>
              <p className="flex-grow-0 flex-shrink-0 text-sm font-light text-left text-[#1b2b41]/[0.72]">
                {offer?.account?.country?.name}
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[345px]  ">
            <div className="grid grid-cols-2 mb-[15px]  w-full h-[26px]">
              <p className=" left-0 top-[3.5px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
                Mineral:
              </p>
              <p className="top-0 text-base font-light text-left text-[#18273a]/[0.94]">
                {offer?.mineral?.name}
              </p>
            </div>
            <div className="grid grid-cols-2 mb-[15px]  w-full h-[26px]">
              <p className="text-sm font-light text-left text-[#1b2b41]/[0.72]">
                Production phase:
              </p>
              <p className="text-base font-light text-left text-[#18273a]/[0.94]">
                {offer?.production_phase?.slug}
              </p>
            </div>

            <div className="grid grid-cols-2 mb-[15px] w-full h-[26px]">
              <p className="text-sm font-light text-left text-[#1b2b41]/[0.72]">
                Investment type:
              </p>
              <p className="top-[68px] text-base font-light text-left text-[#18273a]/[0.94]">
                {offer?.investment_type?.slug}
              </p>
            </div>

            <div className="grid grid-cols-2 mb-[15px] w-full h-[26px]">
              <p className="text-sm font-light text-left text-[#1b2b41]/[0.72]">
                Investment Amount:
              </p>
              <p className="text-base font-light text-left text-[#18273a]/[0.94]">
                {`${priceSplitter(offer?.amount)} USD `}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6">
          {offer?.status == "Archived" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                navigate("../offering-details", {
                  state: offer,
                })
              }
              fullWidth
            >
              View offering
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate("../offering-details", {
                    state: offer,
                  })
                }
                fullWidth
              >
                View offering
              </Button>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  handleOpen(offer);
                }}
                fullWidth
              >
                Archive
              </Button>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default InvestmentItem;
