const DemandsIcon = ({ color }) => {
  const iconColor = color ? color : "white";
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4612 2.95C11.0612 2.55 10.4612 2.45 9.96122 2.65C9.96122 2.35 9.86122 2.05 9.56122 1.75C9.36122 1.55 8.96122 1.35 8.46122 1.35C8.16122 1.35 7.96122 1.45 7.76122 1.45C7.76122 1.25 7.66122 1.15 7.56122 0.95C7.06122 0.35 6.06122 0.35 5.56122 0.95C5.36122 1.15 5.16122 1.35 5.16122 1.55C4.96122 1.55 4.76122 1.45 4.56122 1.45C4.06122 1.45 3.76122 1.65 3.46122 1.95C2.96122 2.45 2.96122 3.25 2.96122 3.25V7.05C2.66122 6.75 2.16122 6.25 1.46122 6.25C1.26122 6.25 0.961225 6.35 0.761225 6.45C0.361225 6.65 0.161224 6.95 0.0612245 7.35C-0.238776 8.35 0.661225 9.75 0.661225 9.85C0.761225 9.95 1.86122 12.55 2.86122 13.65C3.86122 14.85 4.96122 15.55 7.76122 15.55C10.6612 15.55 11.9612 13.95 11.9612 10.45V4.95C11.9612 4.85 12.0612 3.65 11.4612 2.95V2.95ZM5.96122 2.55C5.96122 2.25 5.86122 1.55 6.46122 1.55C6.96122 1.55 6.96122 2.05 6.96122 2.55V6.55C6.96122 6.85 7.16122 7.05 7.46122 7.05C7.76122 7.05 7.96122 6.85 7.96122 6.55V2.75C7.96122 2.75 7.96122 2.35 8.46122 2.35C9.06122 2.35 8.96122 3.25 8.96122 3.25V6.55C8.96122 6.85 9.16122 7.05 9.46122 7.05C9.76122 7.05 9.96122 6.85 9.96122 6.55V4.15C9.96122 4.05 9.96122 3.55 10.4612 3.55C10.9612 3.55 10.9612 4.55 10.9612 4.55V10.45C10.9612 13.85 9.66122 14.55 7.76122 14.55C5.36122 14.55 4.46122 14.05 3.66122 12.95C2.76122 11.95 1.56122 9.35 1.56122 9.25C1.26122 8.95 0.861225 8.05 0.961225 7.65C0.961225 7.55 1.06122 7.45 1.16122 7.35C1.26122 7.35 1.36122 7.25 1.36122 7.25C1.76122 7.25 2.16122 7.75 2.26122 7.95L2.86122 8.85C2.96122 9.05 3.26122 9.15 3.46122 9.05C3.86122 9.05 3.96122 8.85 3.96122 8.65V3.45C3.96122 3.05 3.96122 2.45 4.46122 2.45C4.86122 2.45 4.96122 2.75 4.96122 3.25V6.55C4.96122 6.85 5.16122 7.05 5.46122 7.05C5.76122 7.05 5.96122 6.85 5.96122 6.55V2.55Z"
        fill={iconColor}
      />
    </svg>
  );

};

export default DemandsIcon;
