/* eslint-disable no-unused-vars */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { checkPermission } from "shared/utility";
import { offtakersDemandsTableInfo } from "@constants";
import Table from "components/Table";
import {
  getAllOfftakersDemands,
  setDemandSearchValue,
  fetchPaginatedDemands,
} from "store/slices/offtakers/demands-slice";
import { Button, DemandsStat } from "@ui";

const DemandsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    loading,
    demands: { data, pagination, search, filter, counts },
  } = useSelector((state) => state.offtaker_demands);

  const handleClick = () => {
    // console.log(counts)
    navigate("new");
  };
  const handleViewDetails = (record) => {
    navigate(`details`, { state: {id:record._id} });
  };
  const handleChangePage = (option) => {
    dispatch(
      fetchPaginatedDemands({
        page: pagination[option],
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSetPerPage = (e) => {
    dispatch(
      fetchPaginatedDemands({
        page: 1,
        per_page: e.target.value,
        search: search,
        filter: filter,
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(setDemandSearchValue(e.target.value));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!search) {
      dispatch(getAllOfftakersDemands());
    }
    dispatch(setDemandSearchValue(search));
    dispatch(
      fetchPaginatedDemands({
        page: 1,
        per_page: pagination.limit,
        search: search,
        filter: filter,
      })
    );
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    if (tab == "All") {
      if (search == "") {
        dispatch(getAllOfftakersDemands());
      } else {
        dispatch(
          fetchPaginatedDemands({
            page: 1,
            per_page: pagination.limit,
            search: search,
            filter: "",
          })
        );
      }
    } else {
      dispatch(
        fetchPaginatedDemands({
          page: 1,
          per_page: pagination.limit,
          search: search,
          filter: tab.toLowerCase(),
        })
      );
    }
  };

  return (
    <div>
      <div className="grid grid-cols-4 gap-6 request-button">
        <Button
          variant="contained"
          color="primary"
          disabled={checkPermission("Demands")}
          onClick={handleClick}
        >
          Make a request
        </Button>
      </div>
      <div className="grid grid-cols-3 lg:grid gap-2 sm:grid">
        <DemandsStat
          all_time_offers={counts?.all_time_offers}
          offers_today={counts?.offers_today}
          accepted_offers={counts?.accepted_offers}
        />
      </div>
      {/* tabs */}
      <div className="mt-4">
        <Table
          tableInfo={offtakersDemandsTableInfo}
          isToggle={false}
          isViewDetails={true}
          isStatus={true}
          handleViewDetails={handleViewDetails}
          navigate={navigate}
          data={data}
          pagination={pagination}
          handleSetPerPage={handleSetPerPage}
          handleSearchSubmit={handleSearchSubmit}
          handleChangePage={handleChangePage}
          handleTabChange={handleTabChange}
          loading={loading}
          handleSearch={handleSearch}
          fetchDefaultData={getAllOfftakersDemands}
          search={search}
        />
      </div>
    </div>
  );
};

export default DemandsList;
