/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as React from "react";
import PropTypes from "prop-types";

import { Tabs, Tab, Typography, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { setProjectPage } from "store/slices/vendors/vendor-slice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  tabData,
  handleTabChange,
  setActiveStep,
  total,
}) {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const handleClick = (e, tabValue) => {
  //   e.preventDefault();
  //   if (tabValue === "New") {
  //     dispatch(setProjectPage("New"));
  //   } else {
  //     dispatch(setProjectPage("General"));
  //   }
  // };
  return (
    <div className="w-full mb-5">
      <div className="border-b border-gray-light">
        <div className="flex items-center justify-between w-[33.33333%] mdx:w-[73%] lg:w-full">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="secondary"
          >
            {tabData.data.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  onClick={(e) => handleTabChange(e, tab)}
                  label={tab}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
          <p className="font-extralight text-sm text-heading">
            {tabData.summary} <span className="text-primary">{total}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
