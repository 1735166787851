/**
 * Onboarding URL
 *
 * @format
 */

// refactor url to use prefix for dynamic url path
// create protected route to wrap all routes from prefix
// Create Middleware to handle different route logic based on intending route
export const BaseUrl = "/";
export const SignInUrl = "/";
export const SignUpUrl = "/signup";
export const CheckEmailUrl = "/check-email";
export const ChangePasswordUrl = "/change-password";
export const ForgotPasswordUrl = "/forgot-password";
export const PasswordChangedUrl = "/password-change-success";
export const VerifyPhoneUrl = "/verify-phone";
export const VerifyPhoneSuccessUrl = "/verify-phone-success";
export const SelectCategoryUrl = "/select-category";
export const SuccessUrl = "/success";
export const MinerVerificationUrl = "/miner-verification";
export const VendorVerificationUrl = "/vendor-verification";
export const OfftakerVerificationUrl = "/off-taker-verification";
export const AccountVerificationSuccessUrl = "/success";
/** End Onboarding URL */

/** Miner URL */
export const MinerDashboardUrl = "/miner/dashboard";
export const MinerMinesUrl = "/miner/mines";
export const MinerInventoryUrl = "/miner/inventory";
export const MinerAuctionsUrl = "/miner/auctions";
export const MinerVendorsUrl = "/miner/vendors";
export const MinerSupportUrl = "/miner/support";
export const MinerSettingsUrl = "/miner/settings";
export const MinerAuctionDetailsUrl = (id) => `/miner/auctions/${id}`;
export const MinerDemandsUrl = (id = '') => `/miner/demands/${id}`
export const AddCourierUrl = "/admin/carrier/add";
export const EditCarrierUrl = "/admin/carrier/edit";
export const CourierUrl = "/admin/carrier";
export const TransactionInfoUrl =
  "/miner/interactions/transactions/information";
export const FundingUrl = "/miner/interactions/fundings";
export const FundingDetailsUrl = "/miner/interactions/funding/details";
export const ProjectUrl = "/miner/interactions/projects";
/** End Miner URL */

/** Offtaker URL */
export const OffTakerUrl = "/off-taker";
export const OffTakerTransactionInfoUrl =
  "/off-taker/interactions/transactions/information";
export const OffTakerInvestmentInfoUrl = `/off-taker/interactions/investments/view_details`;
export const OffTakerProspectInfoUrl = `/off-taker/invest/prospects/view_details`;
export const OfftakerAuctionsDetailsUrl = (id) => `/off-taker/auctions/${id}`;
export const OfftakerDemandDetailsUrl = (id) => `/off-taker/demands/${id}`
/** End Offtaker URL */

/** Messaging URL */
export const MessagingUrl = "/messages";

/**Admin URL */
export const AdminTransactionInfoUrl =
  "/admin/interactions/transactions/information";
/** End Admin Url */

/** Vendor URL */
export const VendorOfferingsUrl = "/vendor/offerings";
export const VendorDashboardUrl = "/vendor/dashboard";
/** End Vendor URL */
