/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "@config/axios-instance";
import * as API from "@config/url";
import { toast } from "react-toastify";

const getWalletLogs = createAsyncThunk(
  "getWalletLogs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getWalletLog);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchSubscription = createAsyncThunk(
  "fetchSubscription",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getSubscription);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const postFundWallet = createAsyncThunk(
  "postFundWallet",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.fundWallet, data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const renewSubscription = createAsyncThunk(
  "renewSubscription",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.renewSubscription, data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const cancelSubscription = createAsyncThunk(
  "cancelSubscription",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.cancelSubscription);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const WalletSlice = createSlice({
  name: "wallet",
  initialState: {
    loading: false,
    cancel:false,
    renew:false,
    error: null,
    wallet: [],
    subscription: {},
    counts: {},
    pagination: null,
    search: "",
    filter: "",
  },
  extraReducers: {
    //miner inventory
    [getWalletLogs.pending]: (state) => {
      state.loading = true;
    },
    [getWalletLogs.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.wallet = payload.data.wallet;
      state.pagination = payload.data.pagination;
      state.counts = payload.data.counts;
    },
    [getWalletLogs.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchSubscription.pending]: (state) => {
      state.loading = true;
    },
    [fetchSubscription.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.subscription = payload.data;

      state.pagination = payload.data.pagination;
    },
    [fetchSubscription.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    // store inventory
    [postFundWallet.pending]: (state) => {
      state.loading = true;
    },
    [postFundWallet.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postFundWallet.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [renewSubscription.pending]: (state) => {
      state.renew = true;
    },
    [renewSubscription.fulfilled]: (state, { payload }) => {
      state.renew = false;
    },
    [renewSubscription.rejected]: (state, { payload }) => {
      state.renew = false;
      state.error = true;
      toast.error(payload.message);
    },
    [cancelSubscription.pending]: (state) => {
      state.cancel = true;
    },
    [cancelSubscription.fulfilled]: (state, { payload }) => {
      state.cancel = false;
    },
    [cancelSubscription.rejected]: (state, { payload }) => {
      state.cancel = false;
      state.error = true;
      toast.error(payload.message);
    },
  },
});

export { getWalletLogs, postFundWallet, renewSubscription,cancelSubscription, fetchSubscription };

export default WalletSlice.reducer;
