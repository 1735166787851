import { useNavigate } from "react-router-dom";
import { Card } from "@ui";
import { priceSplitter } from "@shared/utility";
import { Btn as Button } from "@atoms/button/button";
import { getInitials } from "@shared/utility";

const Demand = ({ demand, url }) => {
  const navigate = useNavigate();
  return (
    <Card key={demand.uuid}>
      <div className="flex flex-col justify-start left-[23px] top-[23px] gap-4">
        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 w-[206px] h-[66px]">
          <div className="flex justify-between">
            <div className="flex">
              <div className="min-h-[60px] min-w-[60px] flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
                {demand?.accounts[0]?.profile_img?.length > 0 ? (
                  <img
                    className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                    src={`${demand?.accounts[0]?.profile_img[0]?.url}`}
                    alt=""
                  />
                ) : (
                  `${getInitials(demand?.account?.name)}`
                )}
              </div>
            </div>
            {/* <div>
              {demand?.status == "Active" ? (
                <div className="text-[#00A344] bg-activeBg rounded-sm flex items-center p-2 ">
                  <h4>{demand?.status}</h4>
                </div>
              ) : (
                <div className="text-[#F52419] bg-dangerBg rounded-sm p-2 ">
                  <h4>{demand?.status}</h4>
                </div>
              )}
            </div> */}
          </div>

          <div className="flex flex-col justify-start items-start flex-grow-0 ml-4 flex-shrink-0 ">
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-base text-left text-primary">
              {demand?.account?.name}
            </p>
            <p className="flex-grow-0 flex-shrink-0 mb-1 text-sm text-left text-[#18273a]/[0.94]">
              {demand?.account?.company}
            </p>
            <p className="flex-grow-0 flex-shrink-0 text-sm font-light text-left text-[#1b2b41]/[0.72]">
              {demand?.account?.country?.name}
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[345px]  ">
          <div className="grid grid-cols-2 mb-[15px]  w-full h-[26px]">
            <p className=" left-0 top-[3.5px] text-sm font-light text-left text-[#1b2b41]/[0.72]">
              Mineral:
            </p>
            <p className="top-0 text-base font-light text-left text-[#18273a]/[0.94]">
              {demand?.mineral?.name}
            </p>
          </div>
          <div className="grid grid-cols-2 mb-[15px]  w-full h-[26px]">
            <p className="text-sm font-light text-left text-[#1b2b41]/[0.72]">
              Contract:
            </p>
            <p className="text-base font-light text-left text-[#18273a]/[0.94]">
              {demand?.contract_type}
            </p>
          </div>
          <div className="grid grid-cols-2 mb-[15px] w-full h-[26px]">
            <p className="text-sm font-light text-left text-[#1b2b41]/[0.72]">
              Delivery Period:
            </p>
            <p className="top-[68px] text-base font-light text-left text-[#18273a]/[0.94]">
              {demand?.delivery_period}
            </p>
          </div>
          <div className="grid grid-cols-2 mb-[15px] w-full h-[26px]">
            <p className="text-sm font-light text-left text-[#1b2b41]/[0.72]">
              Price Range:
            </p>
            <p className="text-base font-light text-left text-[#18273a]/[0.94]">
              {`${priceSplitter(demand?.price)} USD `}
            </p>
          </div>
        </div>
      </div>
      <Button onClick={() => navigate(url(demand._id), { state: demand._id })}>
        View Demand
      </Button>
    </Card>
  );
};

export default Demand;
