import { Skeleton, Stack, Typography } from "@mui/material";
import { Card } from "@ui";

const variants = [
  "h1",
  "h2",
  "body1",
  "body1",
  "body1",
  "body1",
  "body1",
  "h3",
  "body1",
  "body1",
  "body1",
  "body1",
  "body1",
  "body1",
  "h2",
  "body1",
  "body1",
  "body1",
];

const LoadingState = () => {
  return (
    <div className="grid grid-rows-2 lg:grid-rows-none lg:grid-cols-3 gap-7 justify-center">
      <Card className="row-span-1 lg:col-span-2 min-h-[1145px]">
        <div className="flex flex-col lg:flex-row gap-8 pt-1">
          <div className="w-[285px] lg:w-[331px] h-[217px] rounded-lg overflow-hidden mb-4">
            <Skeleton
              variant="rectangular"
              sx={{ width: "100%", borderRadius: 1 }}
              height={217}
            />
          </div>
          <div className="w-2/4">
            <Skeleton variant="text" />
            <Skeleton variant="text" width={200} />
            {/* <Countdown date={auction?.product?.endDate} /> */}
            <div className="flex gap-2 w-full mt-2">
              {Array(4)
                .fill(null)
                .map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    width={53}
                    height={60.5}
                  />
                ))}
            </div>
          </div>
        </div>
        {/* card information */}
        <Stack>
          {variants.map((variant, index) => (
            <Typography component="div" key={index} variant={variant}>
              <Skeleton />
            </Typography>
          ))}
        </Stack>
      </Card>
      {/* second col */}
      <div className="row-span-1 lg:col-span-1">
        <Card className="py-[30px] min-h-[502px]">
          <Typography component="div" variant="h3">
            <Skeleton />
          </Typography>
          <Stack sx={{ gap: 1 }}>
            {Array(8)
              .fill(null)
              .map((_, index) => (
                <Typography key={index} component="div" variant="h6">
                  <Skeleton variant="text" />
                </Typography>
              ))}
          </Stack>
          <div className="flex gap-2 w-full mt-2">
            {Array(4)
              .fill(null)
              .map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  width={70}
                  height={70}
                />
              ))}
          </div>
        </Card>
        <div className="mt-6">
          <Skeleton height={124} variant="rectangular" />
        </div>
      </div>
    </div>
  );
};

export default LoadingState;
