import React from "react";
import Permissions from "./Permission";
import { Formik, Form } from "formik";

function AddOfftakerRole() {
  const initialValues = {
    role: "",
  };
  return (
    <div>
      <Formik initialValues={initialValues}>
        {({ values, handleChange }) => (
          <Form>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            {/* <Role values={values} handleChange={handleChange} /> */}
            <Permissions values={values} handleChange={handleChange} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddOfftakerRole;
