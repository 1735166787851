/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import profile_picture from '@images/profile-picture.png';
import { Button } from '@ui';

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

function TransactionsBasicTabs({ investments = 0 }) {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className="w-full mb-5">
			<div className="border-b border-gray-light">
				<div className="flex items-center justify-between">
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
						textColor="secondary"
					>
						<Tab label="Interests" {...a11yProps(0)} />
						<Tab label="Accepted" {...a11yProps(1)} />
						<Tab label="Archived" {...a11yProps(2)} />
					</Tabs>
					<p className="font-extralight text-sm text-heading">
						Total interests: <span className="text-primary">{investments}</span>
					</p>
				</div>
			</div>
		</div>
	);
}

const ManageInterests = () => {
	const navigate = useNavigate();
	const params = useParams();
	console.log('params==', params);
	const { id: investmentId } = params;
	// eslint-disable-next-line no-unused-vars
	const dummyInterests = [
		{
			id: 'FE234TY21',
			name: 'Tom Holland',
			role: 'Miner',
			location: 'United States',
			imgUrl: profile_picture
		},
		{
			id: 'FE234TY22',
			name: 'Fibonacci Seq',
			role: 'Miner',
			location: 'United States',
			imgUrl: profile_picture
		},
		{
			id: 'FE234TY23',
			name: 'Fibonacci Seq',
			role: 'Miner',
			location: 'United States',
			imgUrl: profile_picture
		},
		{
			id: 'FE234TY24',
			name: 'Leonard Caesar Seq',
			role: 'Miner',
			location: 'United States',
			imgUrl: profile_picture
		},
		{
			id: 'FE234TY25',
			name: 'Taribo West Seq',
			role: 'Miner',
			location: 'United States',
			imgUrl: profile_picture
		}
	];
	return (
		<div>
			{/* tabs */}
			<TransactionsBasicTabs investments={dummyInterests?.length} />
			<div className="grid grid-cols-3 gap-[30px]">
				{dummyInterests.map(({ id, name, role, location, imgUrl }) => (
					<div key={id} className="bg-white p-6 rounded-[6px]">
						<div className="flex">
							<img
								src={imgUrl}
								alt={name}
								className="w-[71px] h-[71px] rounded-full"
							/>
							<div className="ml-[30px] flex flex-col justify-between">
								<span className="text-head text-lg">{name}</span>
								<span className="text-vendor text-base font-light">{role}</span>
								<span className="text-inputtext text-base font-light">
									{location}
								</span>
							</div>
						</div>
						<div className="flex justify-between mt-8">
							<Button
								variant="contained"
								color="neutral"
								//   onClick={() => navigate("/investors")}
							>
								Archive
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={() =>
									navigate(
										`/off-taker/interactions/investments/${investmentId}/manage_interests/${id}`
									)
								}
							>
								View details
							</Button>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default ManageInterests;
