/* eslint-disable no-unused-vars */
import { Button } from "@atoms/button/index";
import { Header } from "@atoms/header";
import { Check, Shape, Vector } from "@atoms/icons";
import { UserContext } from "@context/index";
import { get_miners_verification_data,get_mineral_data,get_sdgGoal_data,get_mineType_data } from "@slices/onboarding/miner.slice";
import { file_uploader } from "@slices/file.slice"
import PaperClip from "@ui/icons/PaperClip";
import Open from "@assets/images/dashOpen.png";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FileUpload } from "../../index";
import "./index.css";
import MineForm from "./MineForm";
import { toast } from "react-toastify";
import { BaseUrl } from "@config/app-url";
import { SuccessResponse } from "config/status-code";

const MinerAccountVerification = () => {
    const dispatch = useDispatch();
    /** get miners verification data */
    useEffect(() => {
        dispatch(get_mineral_data());
        dispatch(get_sdgGoal_data());
        dispatch(get_mineType_data());
    }, [dispatch]);

    // set needed states 
    const [file_in_each_upload_section, setFileInEachUploadSection] = useState(false)
    const [company_registration_certificate, setCompanyRegistrationCertificate] = useState([]);
    const [tax_clearance_certificate, setTaxClearanceCertificate] = useState([]);
    const [export_license_certificate, setExportLicenseCertificate] = useState([]);
    const [mining_license_certificate, setMiningLicenseCertificate] = useState([]);
    const [geological_report_certificate, setGeologicalReportCertificate] = useState([]);

    const [number, setNumber] = useState(true);
    const [check, setCheck] = useState(false);
    const [nextButton, setNextButton] = useState(true);
    // set context
    const { numberTwo } = useContext(UserContext);
    const { checkTwo } = useContext(UserContext);
    const { form, setForm } = useContext(UserContext);

    /** Get Data from state set in get_miners_verification_data  */
    const { loading, minerals ,mine_types ,sdg_goals} = useSelector((state) => state.miner_verification);

    /** Used to set check and number based on uploaded files */
    useEffect(() => {
        // refactor
        if (((company_registration_certificate.length > 0) && (tax_clearance_certificate.length > 0) && (export_license_certificate.length > 0) && (mining_license_certificate.length > 0) && (geological_report_certificate.length > 0))) {
            setCheck(true);
            setNumber(false);
            setFileInEachUploadSection(true)
        } else {
            setNextButton(false);
            setNumber(true);
            setCheck(false);
            setFileInEachUploadSection(false)
            expandForm(false)
        }
    }, [company_registration_certificate, tax_clearance_certificate, export_license_certificate, mining_license_certificate, geological_report_certificate]);

    /** Handle File upload */
    const handleChange = async (FileList, type) => {
        /** check if filelist is empty  */
        if (FileList.length <= 0) {
            return toast.error("No file selected");
        }
        /** Declare form data */
        const _FormData = new FormData();
        for (const file of FileList) {
            _FormData.append("file", file);
            uploadTypeSetter(type, file);
            return expandForm();
        }
        /** set upload type */
        _FormData.append("type", type);
        
        /** Dispatch files to backend */
        // dispatch(file_uploader(_FormData))
        //     .then((response) => {
        //         if (response.payload.status && SuccessResponse(response.payload.status)) {
        //             /** Set files to state */
        //             uploadTypeSetter(type, response.payload.data.files)
        //             return expandForm();
        //         }
        //         throw 'error uploading file'
        //     }).catch((error) => toast.error(error.message));
    };

    const uploadTypeSetter = (type, array) => {
        switch (type) {
            case 'company_registration_certificate':
                setCompanyRegistrationCertificate((prevState) => prevState.concat(array));
                break;
            case 'tax_clearance_certificate':
                setTaxClearanceCertificate((prevState) => prevState.concat(array));
                break;
            case 'export_license_certificate':
                setExportLicenseCertificate((prevState) => prevState.concat(array));
                break;
            case 'mining_license_certificate':
                setMiningLicenseCertificate((prevState) => prevState.concat(array));
                break;
            case 'geological_report_certificate':
                setGeologicalReportCertificate((prevState) => prevState.concat(array));
                break;
            default:
                break;
        }
    }

    const uploadCompanyRegistration = (file) => handleChange(file, 'company_registration_certificate')
    const uploadTaxClearance = (file) => handleChange(file, 'tax_clearance_certificate')
    const uploadExportLicense = (file) => handleChange(file, 'export_license_certificate')
    const uploadMiningLicense = (file) => handleChange(file, 'mining_license_certificate')
    const uploadGeologicalReport = (file) => handleChange(file, 'geological_report_certificate')

    /** Used to remove a file after upload from state */
    const removeFile = (type, id) => {
        // refactor
        switch (type) {
            case 'company_registration_certificate':
                setCompanyRegistrationCertificate((prevState) => prevState.filter((file) => file.id !== id));
                break;
            case 'tax_clearance_certificate':
                setTaxClearanceCertificate((prevState) => prevState.filter((file) => file.id !== id));
                break;
            case 'export_license_certificate':
                setExportLicenseCertificate((prevState) => prevState.filter((file) => file.id !== id));
                break;
            case 'mining_license_certificate':
                setMiningLicenseCertificate((prevState) => prevState.filter((file) => file.id !== id));
                break;
            case 'geological_report_certificate':
                setGeologicalReportCertificate((prevState) => prevState.filter((file) => file.id !== id));
                break;
            default:
                break;
        }

    };
    
    /** Used to expand form to show step 2 */
    const expandForm = (expand = true) => {
        if (expand) {
            setForm(true);
            setNextButton(false);
        } else {
            setForm(false);
            setNextButton(true);
        }
    };

    /** Used to hide form */
    const hideForm = () => {
        if (file_in_each_upload_section)
            setForm(!form);
    };

    return (
        <div className="mt-12 font-default">
            <Header />
            <div className="bg-white rounded p-8 mt-[113px] lg:mt-0  lg:bg-none my-4">
                <div className="flex justify-between my-8">
                    <div className="text-primary border-none cursor-pointer flex justify-end"> {" "} </div>
                    <Link to={BaseUrl}
                        className="text-primary border-none cursor-pointer flex justify-end"> {" "} Skip </Link>
                </div>
                <h2 className="font-bold text-3xl text-head mb-3 ">
                    Account verification
                </h2>
                <h6 className="text-lightgray pb-4 text-base font-light lg:mb-2 ">
                    To use the platform, we have to verify the authenticity of your
                    account information. It takes 2 - 3 days to verify your account.
                </h6>

                <div className="hidden lg:flex flex-col">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            {number && (<div
                                className="rounded-full bg-primary text-white text-xs w-fit  my-0.5  py-1 px-[9.5px]"> 1 </div>)}
                            {check && (<div
                                className=" rounded-full outline-primary outline outline-1  w-fit   py-[6.5px] px-[5.5px]">
                                <Check /></div>)}
                            <p className="text-black font-thin ml-3"> Upload your mining license </p>
                        </div>
                        <Shape className={`${file_in_each_upload_section ? "none" : "rotate-180"} cursor-pointer`} />
                    </div>

                    {/* Desktop view file upload */}
                    <div className="w-full py-4 px-4 ml-2.5 my-2 border-l-2 border-[#1C345442]">
                        <FileUpload source="Company registration certificate" handleChange={uploadCompanyRegistration} />
                        <div>
                            {company_registration_certificate.map((file) => {
                                return (
                                    <p key={file.id}
                                        className="h-2 flex items-center text-primary my-2 text-xs mb-6 lg:mb-[16px] align-center">
                                        <span>{<PaperClip />}</span>
                                        <span>{file ? `${file.name}` : "no files uploaded"}</span>
                                        <button className="text-red text-[16px] px-2" onClick={() => removeFile('company_registration_certificate', file.id)}> {" "} x{" "} </button>
                                    </p>
                                );
                            })}
                        </div>
                        <FileUpload source="Tax clearance" handleChange={uploadTaxClearance} />
                        <div>
                            {tax_clearance_certificate.map((file) => {
                                return (
                                    <p key={file.id}
                                        className="h-2 flex items-center text-primary my-2 text-xs mb-6 lg:mb-[16px] align-center">
                                        <span>{<PaperClip />}</span>
                                        <span>{file ? `${file.name}` : "no files uploaded"}</span>
                                        <button className="text-red text-[16px] px-2" onClick={() => removeFile('tax_clearance_certificate', file.id)}> {" "} x{" "} </button>
                                    </p>
                                );
                            })}
                        </div>
                        <FileUpload source="Export License" handleChange={uploadExportLicense} />
                        <div>
                            {export_license_certificate.map((file) => {
                                return (
                                    <p key={file.id}
                                        className="h-2 flex items-center text-primary my-2 text-xs mb-6 lg:mb-[16px] align-center">
                                        <span>{<PaperClip />}</span>
                                        <span>{file ? `${file.name}` : "no files uploaded"}</span>
                                        <button className="text-red text-[16px] px-2" onClick={() => removeFile('export_license_certificate', file.id)}> {" "} x{" "} </button>
                                    </p>
                                );
                            })}
                        </div>
                        <FileUpload source="Mining License" handleChange={uploadMiningLicense} />
                        <div>
                            {mining_license_certificate.map((file) => {
                                return (
                                    <p key={file.id}
                                        className="h-2 flex items-center text-primary my-2 text-xs mb-6 lg:mb-[16px] align-center">
                                        <span>{<PaperClip />}</span>
                                        <span>{file ? `${file.name}` : "no files uploaded"}</span>
                                        <button className="text-red text-[16px] px-2" onClick={() => removeFile('mining_license_certificate', file.id)}> {" "} x{" "} </button>
                                    </p>
                                );
                            })}
                        </div>
                        <FileUpload source="Geological Report" handleChange={uploadGeologicalReport} />
                        <div>
                            {geological_report_certificate.map((file) => {
                                return (
                                    <p key={file.id}
                                        className="h-2 flex items-center text-primary my-2 text-xs mb-6 lg:mb-[16px] align-center">
                                        <span>{<PaperClip />}</span>
                                        <span>{file ? `${file.name}` : "no files uploaded"}</span>
                                        <button className="text-red text-[16px] px-2" onClick={() => removeFile('geological_report_certificate', file.id)}> {" "} x{" "} </button>
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                    {/* Desktop view file upload */}

                    <div className="flex justify-between items-center mb-5">
                        <div className="flex items-center">
                            {numberTwo && (<div className="rounded-full bg-primary text-white text-xs w-fit my-0.5 py-1 px-[9.5px]"> 2 </div>)}
                            {checkTwo && (<div className=" rounded-full outline-primary outline outline-1 w-fit py-[6.5px] px-[5.5px]"> <Check /></div>)}
                            <p className="text-black font-thin ml-3">Fill mine information</p>
                        </div>
                        <Shape className={`${form === false ? "none" : "rotate-180"} cursor-pointer`} onClick={hideForm} />
                    </div>
                </div>

                <div
                    className="text-center lg:hidden lg:my-6 2xl:ml-20 flex justify-center flex-row text-primary  h-fit md:items-center ">
                    <div>
                        {number && (<div
                            className="rounded-full bg-primary text-white text-xs mt-1 my-0.5 mx-5 py-1 px-[9.5px]"> 1 </div>)}
                        {check && (<div
                            className=" rounded-full outline-primary outline outline-1  flex justify-center align-center mx-5 py-2 mb-1 px-[7px] ">
                            <Check /></div>)}
                    </div>
                    <div className="mx-20 lg:mx-auto py-1 "><img className="rotate-90 lg:rotate-0" alt="" src={Open} />
                    </div>
                    <div>
                        {numberTwo && (<div
                            className="rounded-full bg-primary text-white text-xs mt-1 my-0.5 mx-5 py-1 px-2"> 2 </div>)}
                        {checkTwo && (<div
                            className=" rounded-full outline-primary outline outline-1  flex justify-center align-center mx-5 py-2 mb-1 px-[7px]">
                            <Check /></div>)}
                    </div>
                </div>
                
                {/* Mobile view file upload */}
                <div className="w-full py-4 px-4 ml-2.5 my-2  lg:hidden">
                    <FileUpload source="Company registration certificate" handleChange={uploadCompanyRegistration} />
                    <div>
                        {company_registration_certificate.map((file) => {
                            return (
                                <p key={file.id}
                                    className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] align-center">
                                    <span>{<PaperClip />}</span>
                                    <span>{file ? `${file.name}` : "no files uploaded"}</span>
                                    <button className="text-red text-[16px] px-2" onClick={() => removeFile('company_registration_certificate', file.id)}> {" "} x{" "} </button>
                                </p>
                            );
                        })}
                    </div>
                </div>

                <div className="w-full py-4 px-4 ml-2.5 my-2  lg:hidden">
                    <FileUpload source="Tax clearance" handleChange={uploadTaxClearance} />
                    <div>
                        {tax_clearance_certificate.map((file) => {
                            return (
                                <p key={file.id}
                                    className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] align-center">
                                    <span>{<PaperClip />}</span>
                                    <span>{file ? `${file.name}` : "no files uploaded"}</span>
                                    <button className="text-red text-[16px] px-2" onClick={() => removeFile('tax_clearance_certificate', file.id)}> {" "} x{" "} </button>
                                </p>
                            );
                        })}
                    </div>
                </div>

                <div className="w-full py-4 px-4 ml-2.5 my-2  lg:hidden">
                    <FileUpload source="Export License" handleChange={uploadExportLicense} />
                    <div>
                        {export_license_certificate.map((file) => {
                            return (
                                <p key={file.id}
                                    className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] align-center">
                                    <span>{<PaperClip />}</span>
                                    <span>{file ? `${file.name}` : "no files uploaded"}</span>
                                    <button className="text-red text-[16px] px-2" onClick={() => removeFile('export_license_certificate', file.id)}> {" "} x{" "} </button>
                                </p>
                            );
                        })}
                    </div>
                </div>

                <div className="w-full py-4 px-4 ml-2.5 my-2  lg:hidden">
                    <FileUpload source="Mining License" handleChange={uploadMiningLicense} />
                    <div>
                        {mining_license_certificate.map((file) => {
                            return (
                                <p key={file.id}
                                    className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] align-center">
                                    <span>{<PaperClip />}</span>
                                    <span>{file ? `${file.name}` : "no files uploaded"}</span>
                                    <button className="text-red text-[16px] px-2" onClick={() => removeFile('mining_license_certificate', file.id)}> {" "} x{" "} </button>
                                </p>
                            );
                        })}
                    </div>
                </div>

                <div className="w-full py-4 px-4 ml-2.5 my-2  lg:hidden">
                    <FileUpload source="Geological Report" handleChange={uploadGeologicalReport} />
                    <div>
                        {geological_report_certificate.map((file) => {
                            return (
                                <p key={file.id}
                                    className="h-2 flex items-center text-primary my-2 text-xs mb-[16px] align-center">
                                    <span>{<PaperClip />}</span>
                                    <span>{file ? `${file.name}` : "no files uploaded"}</span>
                                    <button className="text-red text-[16px] px-2" onClick={() => removeFile('geological_report_certificate', file.id)}> {" "} x{" "} </button>
                                </p>
                            );
                        })}
                    </div>
                </div>
                {/* End Mobile view file upload */}

                <MineForm minerals={minerals} mine_types={mine_types}   sdg_goals={sdg_goals} company_registration_certificate={company_registration_certificate} tax_clearance_certificate={tax_clearance_certificate} export_license_certificate={export_license_certificate} mining_license_certificate={mining_license_certificate} geological_report_certificate={geological_report_certificate}
                     />
                {(!form || nextButton) && (
                    <div className="mt-8 flex justify-end">
                        <Button disabled={!file_in_each_upload_section} size="small" onClick={expandForm} type="submit"> {" "} Next{" "} </Button>
                    </div>
                )}
                {/* <div className="flex align-center pb-2 mt-20 font-thin">
                    <Vector />
                    <p className=" paragraph text-lightgray text-xs ml-2">
                        You can switch between multiple profiles later on
                    </p>
                </div> */}
            </div>
        </div>
    );
};
export default MinerAccountVerification;
