/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getProject,
  submitVendorRatingsFromOfftaker,
} from "store/slices/offtaker-slice";
import { Button } from "@ui";
import { Box, Modal, Rating } from "@mui/material";
import dayjs from "dayjs";
import { getInitials } from "shared/utility";

function ProjectDetails() {
  const params = useParams();
  const { id } = params;
  const [openConfirm, setOpenConfirm] = useState(false);
  const [ratings, setRatings] = useState(2);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState("");
  const { project } = useSelector((store) => store.offtakers);
  const dispatch = useDispatch();
  //eslint-disable-next-line

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 448,
    bgcolor: "background.paper",
    borderRadius: "0.25rem",
    boxShadow: 24,
    p: 4,
    outline: 0,
    padding: "22px",
  };

  const onSubmitRatings = async () => {
    try {
      setLoading(true);
      const body = {
        rating: ratings ?? 1,
        comment: comments,
        type: "seller",
      };
      const res = await dispatch(
        submitVendorRatingsFromOfftaker({
          id: project.vendor?.id,
          formdata: body,
        })
      );
      if (res.payload.status === 200 || res.payload.status === 201) {
        setLoading(false);
        setOpenConfirm(!openConfirm);
        return;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(getProject(id));
  }, []);

  return (
    <div>
      {/*Start Of Rating Modal */}
      <div>
        <Modal
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h1 className="text-center text-[22px] mt-5">Rate Vendor</h1>
            <div className="flex  items-center mt-3 flex-col gap-3">
              <img
                className="rounded-full w-10 h-10 flex items-center justify-center text-white bg-primary mr-4"
                src={`${project?.user_image?.url}`}
                alt=""
              />
              <h1 className="text-primary font-light text-base capitalize">
                {project?.vendor?.name}
              </h1>
              <div className="star">
                <p className="text-center font-light text-lightgray text-xs">
                  Click on star or type in rating
                </p>
                <div className="flex flex-col items-center">
                  <Rating
                    name="simple-controlled"
                    className="!text-primary mt-3 !text-[30px] "
                    value={ratings}
                    onChange={(_, newValue) => {
                      setRatings(newValue);
                    }}
                  />
                  <div className="py-1 rounded-sm w-20 text-center bg-lightgray500 mt-5">
                    <span className=" grid grid-cols-3  px-2">
                      <span>
                        <input
                          type="number"
                          max="5"
                          value={ratings}
                          className="w-full text-center bg-lightgray500"
                          onChange={({ target: { value } }) => {
                            if (value <= 5) {
                              setRatings(value);
                            }
                          }}
                        />
                      </span>
                      <span className="text-dark-gray-500">/</span>
                      <span className="text-primary">5</span>
                    </span>
                  </div>
                  <div className="mb-3 xl:w-full w-full mt-5">
                    <label
                      htmlFor={"Dispute details"}
                      className="form-label inline-block mb-1 font-light text-xs text-[#1B2B41B8]"
                    >
                      Description
                    </label>
                    <textarea
                      className="form-control placeholder:text-xs resize-none block w-[400px] p-[10px] font-light bg-clip-padding bg-[#1A38601A] border-none z-0 focus:shadow focus:outline-none focus:ring-primary rounded transition ease-in-out"
                      id="exampleFormControlTextarea1"
                      rows="5"
                      placeholder="This field has limit of 160 characters"
                      onChange={({ target: { value } }) => setComments(value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-7 mb-3">
              <Button
                color="neutral"
                onClick={() => setOpenConfirm(!openConfirm)}
              >
                Skip
              </Button>
              <Button onClick={() => onSubmitRatings()}>
                {loading ? "Processing..." : "Submit ratings"}
              </Button>
            </div>
          </Box>
        </Modal>
      </div>

      {/* End of Rating Modal */}
      <div className="bg-white rounded-sm pl-24 py-6">
        {/* <pre>{JSON.stringify(vendor, null, 2)}</pre> */}
        {project && (
          <div>
            <div>
              <div className="flex justify-between">
                <div className="flex items-center mb-10">
                  <div className="min-h-[60px] min-w-[60px] mr-3 flex justify-center align-center items-center text-2xl text-primary rounded-full bg-fill">
                    {project?.user_image !== null ? (
                      <img
                        className="rounded-full h-[60px] w-[60px] flex items-center justify-center text-white bg-primary"
                        src={`${project?.user_image?.url}`}
                        alt=""
                      />
                    ) : (
                      `${getInitials(project?.vendor?.name)}`
                    )}
                  </div>
                  {/* <img
                    className="rounded-circle w-[60px] h-[60px] mr-3"
                    src="https://cdn.smehost.net/2020sonymusiccouk-ukprod/wp-content/uploads/2019/10/Miley-Cyrus-03_MidnightSky_WhiteLight_2302.jpg"
                    alt=""
                  /> */}
                  <div>
                    <h4 className="text-primary">{project?.vendor?.company}</h4>
                    <h4 className="text-[14px] text-vendor">
                      {project?.vendor?.name}
                    </h4>
                  </div>
                 
                </div>
                <div className="flex justify-center">
                    {project?.status === "Completed" && (
                      <div className="bg-[#6166B6] rounded-[4px] py-1 mr-[30px] h-[45px]">
                        <Button
                          variant="outlined"
                          color="text-[#ffffff]"
                          fontWeight="font-light"
                          onClick={() => {
                            setOpenConfirm(true);
                          }}
                        >
                          Rate Vendor
                        </Button>
                      </div>
                    )}
                  </div>
              </div>
            </div>
            <div className="mt-3 mb-7">
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project title:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {project.title}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project type:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {project.type}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Category:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {project.category.name}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project description:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4 break-words">
                  {project.description}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project duration:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {project.duration}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Country:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {project.country.name}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Budget:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {project.budget}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Project start date:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4">
                  {dayjs(project.start_date).format("DD/MM/YYYY")}
                </h4>
              </div>
              <div className="grid grid-cols-3 mb-8 font-light">
                <h4 className="text-vendorsecondary col-start-1 col-end-2">
                  Additional notes:
                </h4>
                <h4 className="text-vendor col-start-2 col-end-4 break-words">
                  {project.additional_notes}
                </h4>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProjectDetails;
