/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/** @format */

import React, { useState, useContext, useEffect, Fragment } from "react";
import Tooltip from "@mui/material/Tooltip";
import { Avatar, Button } from "@ui";
import { FundWallet } from "../wallet/fundwallet";
import { useDispatch, useSelector } from "react-redux";
import { ChevronDownIcon, BellIcon } from "@ui/icons";
import { Hamburger } from "@atoms/icons";
import { Notifications } from "@components";
import { getProfile } from "@slices/settings-slice";
import { usePath, useAuth } from "@hooks";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { UserContext } from "@context/index";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "@config/app-url";
import PropTypes from "prop-types";
import { Transition, Menu } from "@headlessui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// eslint-disable-next-line no-undef
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Navbar = ({ userType, image }) => {
  const { data,notificationcount } = useSelector((state) => state.settings);
  const [openNotify, setOpenNotify] = useState(false);
  const [openDisabled, setDisabledOpen] = useState(false);
  const { sidebar, setSideBar } = useContext(UserContext);
  const { pathnames } = usePath();
  const dispatch = useDispatch();
  let header = pathnames[1];
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const showSidebar = () => {
    setSideBar(!sidebar);
  };

  const openNotification = () => {
    setOpenNotify(true);
  };
  const handleOpen = () => setDisabledOpen(true);
  const handleClose = () => setDisabledOpen(false);
  const [open, setOpen] = useState(false);

  const { user } = useAuth();
  const profiles = useAuth();
  const { profileStatus } = useAuth();
  const [firstname, lastname] = user.name.split(" ");
  const initials = firstname?.charAt(0) + (lastname ? lastname?.charAt(0) : "");
  function logout() {
    localStorage.clear();
    navigate(BaseUrl);
  }

  const openfundWallet = async () => {
    setOpen(true);
  };

  return (
    <>
      <Elements stripe={stripePromise}>
        <FundWallet open={open} setOpen={setOpen} />
      </Elements>
      {/* Notifications */}
      <Notifications openNotify={openNotify} setOpenNotify={setOpenNotify} profiles={profiles}/>

      <header className="flex items-center justify-between text-heading bg-white px-7 py-4">
        <div className="flex w-1/4 justify-between">
          {" "}
          <div className="xl:hidden mt-2 mx-2 cursor-pointer">
            <Hamburger onClick={showSidebar} />
          </div>
          <div className="hidden xs:flex font-normal text-xl leading-7 capitalize">
            {header}
          </div>
        </div>
        {/* Check Whether Account is verified or not */}
        {profileStatus === "verified" ? (
           <div className="flex">
           {/* <Avatar>
             <InfoCircleSmall />
           </Avatar> */}
           
           <Avatar>
             <div
               className="relative"
               onClick={() => {
                 openNotification();
               }}
             >
               <BellIcon />
               <span className="absolute right-2 top-0 fa-stack rounded-full w-1.5 h-1.5" data-count={notificationcount?.unread  > 10 ? '10+' : notificationcount?.unread}></span>
             </div>
           </Avatar>
 
           {profiles?.account?.role?.slug !== "admin" &&
             profiles?.secondaryRole !== "admin" && (
               <Menu as="div" className="relative inline-block text-left">
                 <div className="mr-3">
                   <Menu.Button className="relative flex items-center select-none">
                     <div className="lg:flex items-center gap-2 select-none mt-2">
                       <div>
                         <span className="block font-medium text-[17px] leading-4 ">
                           {data?.wallet_balance
                             ? parseFloat(data?.wallet_balance).toFixed(2)
                             : 0}{" "}
                           USD
                         </span>
                       </div>
                       <ChevronDownIcon />
                     </div>
                   </Menu.Button>
                 </div>
 
                 <Transition
                   as={Fragment}
                   enter="transition ease-out duration-100"
                   enterFrom="transform opacity-0 scale-95"
                   enterTo="transform opacity-100 scale-100"
                   leave="transition ease-in duration-75"
                   leaveFrom="transform opacity-100 scale-100"
                   leaveTo="transform opacity-0 scale-95"
                 >
                   <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                     <div className=" absolute -bottom-50 right-0 z-20 w-[200px] bg-white py-6 px-[10px] custom-shadow rounded-md">
                       <Menu.Item>
                         <div className="mb-4">
                           <Button
                             variant="contained"
                             color="primary"
                             onClick={() => {
                               openfundWallet();
                             }}
                             fullWidth
                           >
                             Fund Wallet
                           </Button>
                         </div>
                       </Menu.Item>
                       <Menu.Item>
                         <div>
                           <Button
                             variant="contained"
                             color="primary"
                             onClick={() =>
                               navigate(
                                 `/${profiles.account?.role?.slug}/wallet`
                               )
                             }
                             fullWidth
                           >
                             Wallet Log
                           </Button>
                         </div>
                       </Menu.Item>
                     </div>
                   </Menu.Items>
                 </Transition>
               </Menu>
             )}
 
           <Menu as="div" className="relative inline-block text-left">
             <div>
               <Menu.Button className="relative flex items-center select-none">
                 {data?.profile_img?.length > 0 ? (
                   <img
                     className="rounded-full w-10 h-10 flex items-center justify-center text-white bg-primary mr-4"
                     src={`${
                       data?.profile_img?.length > 0
                         ? data?.profile_img[0]?.url
                         : "https://cdn.smehost.net/2020sonymusiccouk-ukprod/wp-content/uploads/2019/10/Miley-Cyrus-03_MidnightSky_WhiteLight_2302.jpg"
                     }`}
                     onError={({ currentTarget }) => {
                       currentTarget.onerror = null; // prevents looping
                       currentTarget.src =
                         "https://cdn.smehost.net/2020sonymusiccouk-ukprod/wp-content/uploads/2019/10/Miley-Cyrus-03_MidnightSky_WhiteLight_2302.jpg";
                     }}
                     alt=""
                   />
                 ) : (
                   <Avatar bgPrimary={true}>{initials}</Avatar>
                 )}
                 <div className="mr-3">
                   <Tooltip title="Click here to see available actions" arrow>
                     <div className="hidden lg:flex items-center gap-2 select-none">
                       <div>
                         <span className="block font-normal text-[13px] leading-4 text-left">
                           {data?.name ? data?.name : user?.name}
                         </span>
                         <span className="text-light font-extralight text-xs tab-left-heading">
                           {userType}{" "}
                           {profiles?.account?.role?.name == userType
                             ? ""
                             : `(${profiles?.account?.role.name})`}
                         </span>
                       </div>
                       <ChevronDownIcon />
                     </div>
                   </Tooltip>
                 </div>
               </Menu.Button>
             </div>
 
             <Transition
               as={Fragment}
               enter="transition ease-out duration-100"
               enterFrom="transform opacity-0 scale-95"
               enterTo="transform opacity-100 scale-100"
               leave="transition ease-in duration-75"
               leaveFrom="transform opacity-100 scale-100"
               leaveTo="transform opacity-0 scale-95"
             >
               <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                 <div className=" absolute -bottom-50 right-0 z-20 w-[200px] bg-white py-6 px-[10px] custom-shadow rounded-md">
                   <Menu.Item>
                     <div className="mb-4">
                       <Button
                         variant="contained"
                         color="neutral"
                         onClick={() =>
                           navigate(`/${profiles.account?.role?.slug}/settings`)
                         }
                         fullWidth
                       >
                         Profile
                       </Button>
                     </div>
                   </Menu.Item>
                   <Menu.Item>
                     <div>
                       <Button
                         onClick={logout}
                         variant="contained"
                         color="danger"
                         fullWidth
                       >
                         Log out
                       </Button>
                     </div>
                   </Menu.Item>
                 </div>
               </Menu.Items>
             </Transition>
           </Menu>
         </div>
        ): (
          <>
            <Modal
              open={openDisabled}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Alert!
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  You're not allowed to perform any action yet until your account has
                  been verified.
                </Typography>
              </Box>
            </Modal>
            <div className="flex">
              <Avatar>
                <div className="relative"   onClick={() => {handleOpen()}}>
                  <BellIcon />
                  <span className="absolute right-2 top-0 fa-stack rounded-full w-1.5 h-1.5" data-count={notificationcount?.unread > 10 ? '10+' : notificationcount?.unread}></span>
                </div>
              </Avatar>

              {profiles?.account?.role?.slug !== "admin" &&
                profiles?.secondaryRole !== "admin" && (
                  <Menu as="div" className="relative inline-block text-left">
                    <div className="mr-3"  onClick={() => {handleOpen()}}>
                      <Menu.Button className="relative flex items-center select-none">
                        <div className="lg:flex items-center gap-2 select-none mt-2">
                          <div>
                            <span className="block font-medium text-[17px] leading-4 ">
                              {data?.wallet_balance
                                ? parseFloat(data?.wallet_balance).toFixed(2)
                                : 0}{" "}
                              USD
                            </span>
                          </div>
                          <ChevronDownIcon />
                        </div>
                      </Menu.Button>
                    </div>
                  </Menu>
              )}

              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="relative flex items-center select-none">
                    {data?.profile_img?.length > 0 ? (
                      <img
                        className="rounded-full w-10 h-10 flex items-center justify-center text-white bg-primary mr-4"
                        src={`${data?.profile_img?.length > 0
                            ? data?.profile_img[0]?.url
                            : "https://cdn.smehost.net/2020sonymusiccouk-ukprod/wp-content/uploads/2019/10/Miley-Cyrus-03_MidnightSky_WhiteLight_2302.jpg"}`}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src =
                            "https://cdn.smehost.net/2020sonymusiccouk-ukprod/wp-content/uploads/2019/10/Miley-Cyrus-03_MidnightSky_WhiteLight_2302.jpg";
                        } }
                        alt="" />
                    ) : (
                      <Avatar bgPrimary={true}>{initials}</Avatar>
                    )}
                    <div className="mr-3">
                      <Tooltip title="Click here to see available actions" arrow>
                        <div className="hidden lg:flex items-center gap-2 select-none">
                          <div>
                            <span className="block font-normal text-[13px] leading-4 text-left">
                              {data?.name ? data?.name : user?.name}
                            </span>
                            <span className="text-light font-extralight text-xs tab-left-heading">
                              {userType}{" "}
                              {profiles?.account?.role?.name == userType
                                ? ""
                                : `(${profiles?.account?.role.name})`}
                            </span>
                          </div>
                          <ChevronDownIcon />
                        </div>
                      </Tooltip>
                    </div>
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className=" absolute -bottom-50 right-0 z-20 w-[200px] bg-white py-6 px-[10px] custom-shadow rounded-md">
                      <Menu.Item>
                        <div className="mb-4">
                          <Button
                            variant="contained"
                            color="neutral"
                            onClick={() => {handleOpen()}}
                            fullWidth
                          >
                            Profile
                          </Button>
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <div>
                          <Button
                            onClick={logout}
                            variant="contained"
                            color="danger"
                            fullWidth
                          >
                            Log out
                          </Button>
                        </div>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </>
        )}
       
      </header>
    </>
  );
};

Navbar.propTypes = {
  userType: PropTypes.string.isRequired,
};

export default Navbar;
