/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Button, ControlledModal, Select as SelectForm  } from "@ui";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  updateAdminStatus,
  deleteAdminUser,
  findUser,
} from "@slices/admin-slice";

const Actions = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [approvalstatus, setapprovalStatus] = useState(data?.profile[0]?.status.toLowerCase());
  const [suspensionstatus, setsuspensionstatus] = useState(data?.user?.status.toLowerCase());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const [loader, setLoader] = useState(false);
  const handleClose = () => setOpen(false);

  const handleApprovalStatus = (e) => {
    setapprovalStatus(e.slug);
  };

  const handleSuspensionStatus = (e) => {
    setsuspensionstatus(e.slug);
  };

  const deleteUser = async () => {
    try {
      const response = await dispatch(
        deleteAdminUser(data?.profile[0]?.user_id)
      );
      if (response.payload.status === 201 || response.payload.status === 200) {
        navigate("/admin/users");
        toast.success(response.payload.data.message);
        setOpen(false);
      } else {
        console.log("error from then", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const update_status = async () => {
    setLoader(true);
    let formData = {
      approval_status: approvalstatus,
      suspension_status: suspensionstatus,
    };
    try {
      const response = await dispatch(
        updateAdminStatus({
          id: data?.profile[0]?.user_id,
          status: true,
          data: formData,
        })
      );
      if (response.payload.status === 200 || response.payload.status === 201) {
        setLoader(false);
        dispatch(findUser({ id: data.user._id }));
        toast.success(response.payload.data.message);
      } else {
        setLoader(false);
        console.log("error from then", response);
      }
    } catch (error) {
      setLoader(false);
      console.log("error", error);
    }
  };

  return (
    <div>
      <div className="flex flex-row gap-6">
        <ControlledModal
          isOpen={open}
          handleClose={handleClose}
          className="absolute top-[50%] left-[50%]  -translate-y-2/4 -translate-x-2/4 w-[25%] px-[60px] py-10"
        >
          <p className="text-head text-center font-semibold text-[22px]">
            Delete user?
          </p>
          <p className="text-lightgray text-center font-light text-base mt-2">
            Are you sure you want to delete this user?
          </p>
          <div className="flex flex-row justify-center gap-4 mt-6">
            <Button
              type="button"
              variant="text"
              color="neutral"
              onClick={() => setOpen(!open)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="contained"
              color="danger"
              onClick={() => {
                deleteUser();
              }}
            >
              Yes, delete
            </Button>
          </div>
        </ControlledModal>
        {/* <Select
          label="Update approval status"
          placeholder="Approved"
          options={status}
        /> */}
        <div className=" w-[50%]">
          <SelectForm
            label="Update account status"
            placeholder={data?.profile[0]?.status}
            onChange={(e) => handleApprovalStatus(e)}
            options={[
              { name: "Verified", slug: "verified" },
              { name: "Pending", slug: "pending" },
              // { name: "Blocked", slug: "blocked" },
            ]}
            name="status"
          />
        </div>
        <div className=" w-[50%]">
          <SelectForm
            label="Update user status"
            placeholder={data?.user?.status}
            onChange={(e) => handleSuspensionStatus(e)}
            options={[
              { name: "Active", slug: "active" },
              { name: "Suspended", slug: "suspended" },
              { name: "Inactive", slug: "inactive" },
              { name: "Blocked", slug: "blocked" }
            ]}
            name="status"
          />
        </div>

        {/* <Select label="Update suspension status" placeholder="Active" /> */}
      </div>
      <div className="flex justify-between  mt-[47px]">
        <div className="flex flex-row gap-4">
          <Button
            type="button"
            variant="contained"
            color="decline"
            onClick={() => handleOpen()}
          >
            Delete user
          </Button>
          {/* <Button type="button" variant="contained" color="offwhite">
            Edit user
          </Button> */}
        </div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            update_status();
          }}
        >
          {loader ? "Processing..." : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default Actions;
