/* eslint-disable react/prop-types */
import { forwardRef } from "react";
import { Checkbox } from "@mui/material/";

// checkout mui official docs to see other props available for this component this incase you are planning on using this component
const ControlledCheckbox = forwardRef(
  ({ value, onChange, index, ...rest }, ref) => {
    return (
      <Checkbox
        disableRipple
        value={value}
        ref={ref}
        id={`checkbox ${index}`}
        onChange={onChange}
        inputProps={{ "aria-label": "controlled" }}
        sx={{
          padding: 0,
          color: "#6166B6",
          "&.Mui-checked": {
            color: "#6166B6",
          },
        }}
        {...rest}
      />
    );
  }
);

ControlledCheckbox.displayName = "ControlledCheckbox"

export default ControlledCheckbox;
