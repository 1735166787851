/* eslint-disable no-unused-vars */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Input, Button } from "@ui";

const ManageVendor = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userProfile = location.state.data;
  return (
    <Card>
      <div className="max-w-[796px] mx-auto">
        <h2 className="text-heading text-2xl my-2">Vendor profile</h2>
        <p>User ID: {userProfile?.user?._id}</p>

        <div className="my-6">
          <h3 className="my-2 text-lg text-heading">Basic Information</h3>
          {/* form */}
          <form>
            <div>
              <Input label="Name" type="text" value={userProfile?.user?.name} />
            </div>

            <div>
              <Input
                label="Business email address"
                type="text"
                value={userProfile?.user?.email}
              />
            </div>

            <div className="mt-4">
              <Input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                value={` +${userProfile?.user.phone?.code} ${userProfile?.user.phone?.number}`}
              />
            </div>

            <div className="flex justify-between  mt-[47px]">
              <Button
                type="button"
                variant="contained"
                color="offwhite"
                onClick={() => {
                  navigate(
                    "../account-information" + `/${userProfile.user._id}`
                  );
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Update
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Card>
  );
};

export default ManageVendor;
