const PieChartIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 5.5C16.2 5.7 18.7 8.3 19 11.5H13V5.5ZM12.5 4.5C12.3 4.5 12.2 4.5 12 4.5V12.5H20C20 12.3 20 12.2 20 12C20 7.9 16.6 4.5 12.5 4.5Z"
        fill="white"
      />
      <path
        d="M11 13.5V5.5C7.1 5.8 4 9 4 13C4 17.1 7.4 20.5 11.5 20.5C15.5 20.5 18.7 17.4 19 13.5H11Z"
        fill="white"
      />
    </svg>
  );
};

export default PieChartIcon;
