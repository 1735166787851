/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "@config/axios-instance";
import * as API from "@config/url";
import { toast } from "react-toastify";

const getMinerInventory = createAsyncThunk(
  "miner/getMinerInventory",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getMinerInventory);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const searchMinerInventoryByNameOrSlug = createAsyncThunk(
  "miner/searchMinerInventory",
  async (searchParam, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.searchInventory(searchParam));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const storeMinerInventory = createAsyncThunk(
  "miner/postInventory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.storeMinerInventory, data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getMinerInventoryLog = createAsyncThunk(
  "miner/getMinerInventoryLog",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.getMinerInventoryLog);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPaginatedInventory = createAsyncThunk(
  "miner/fetchPaginatedInventory",
  async (payload, { rejectWithValue }) => {
    // console.log(payload);
    try {
      const response = await axios.get(
        API.paginatedInventoryLog(
          payload.page,
          payload.per_page,
          payload.search,
          payload.filter
        )
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const InventorySlice = createSlice({
  name: "inventory",
  initialState: {
    loading: false,
    error: null,
    inventory: {},
    inventoryProduct: {},
    pagination: null,
    search: "",
    filter:""
  },
  reducers: {
    storeInventoryProduct: (state, { payload }) => {
      state.inventoryProduct = payload;
    },
    setSearchValue: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: {
    //miner inventory
    [getMinerInventory.pending]: (state) => {
      state.loading = true;
    },
    [getMinerInventory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.inventory = payload.data;
    },
    [getMinerInventory.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
    [fetchPaginatedInventory.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPaginatedInventory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.inventory = payload.data.products;
      state.pagination = payload.data.pagination;
    },
    [fetchPaginatedInventory.rejected]: (state, action) => {
      state.error = true;
      console.log(action);
      // toast.error(payload.message);
    },
    // search inventory
    [searchMinerInventoryByNameOrSlug.pending]: (state) => {
      state.loading = true;
    },
    [searchMinerInventoryByNameOrSlug.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.inventory = payload.data;
    },
    [searchMinerInventoryByNameOrSlug.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      // toast.error(payload.message);
    },

    //miner inventory
    [getMinerInventoryLog.pending]: (state) => {
      state.loading = true;
    },
    [getMinerInventoryLog.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.inventory = payload.data.products;
      state.pagination = payload.data.pagination;
    },
    [getMinerInventoryLog.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },

    // store inventory
    [storeMinerInventory.pending]: (state) => {
      state.loading = true;
    },
    [storeMinerInventory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.inventoryProduct = {};
    },
    [storeMinerInventory.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      toast.error(payload.message);
    },
  },
});

export const { storeInventoryProduct, setSearchValue } = InventorySlice.actions;
export {
  getMinerInventory,
  searchMinerInventoryByNameOrSlug,
  getMinerInventoryLog,
  storeMinerInventory,
};

export default InventorySlice.reducer;
